import React from "react";
import * as dfn from "date-fns";
import { FiCopy } from "react-icons/fi";
import { RiLoader5Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import { PopoverButton, PopoverMain } from "../../components/popovers/Popover";
import { TextSkeleton } from "../../components/skeletons";
import {
	copyTextToClipboard,
	statusEnum,
	truncateRef,
} from "../../helpers/helper";
import useTransactions from "../../hooks/useTransactions";

import {
	getSpecificTransaction,
	revertSpecificTransferTransactions,
	verifySpecificTransferTransactions,
} from "../../redux/transactions/transactionsActions";

export default function SpecificTransfersTransactionDetail() {
	const { id } = useParams();
	const dispatch = useDispatch();

	const {
		isLoadingSpecificTransaction: loading,
		specificTransaction: details,
		isRevertingSpecificTransferTransactions,
		isVerifyingSpecificTransferTransactions,
	} = useTransactions();

	React.useEffect(() => {
		dispatch(getSpecificTransaction(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<div className="p-3 flex flex-col">
			<div className="mb-6">
				<BreadCrumbs title={"Transactions"} goBack />
			</div>

			<div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">Account Details</p>
				</div>
				<div className="grid grid-cols-4 gap-4 py-3">
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Name
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.account_name}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Email
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<a
								href={`mailto:${details?.account_email}`}
								style={{ width: "fit-content" }}
							>
								<h6
									className="glade-normal-text-three glade-blue-darker hover:underline py-1"
									style={{ width: "fit-content" }}
								>
									{details?.account_email}
								</h6>
							</a>
						)}
					</div>
					{details?.account_number && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Account Number
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{details?.account_number}
								</h6>
							)}
						</div>
					)}
					{details?.bank_name && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Bank Name
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{details?.bank_name}
								</h6>
							)}
						</div>
					)}
					{details?.phone && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Phone Number
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{details?.phone}
								</h6>
							)}
						</div>
					)}
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Type
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1 capitalize">
								{details?.account_type}
							</h6>
						)}
					</div>
				</div>
			</div>

			<div className="flex justify-between mt-4">
				{loading ? (
					<TextSkeleton width="100px" />
				) : (
					<BasicIndicator
						className="px-5 py-1 capitalize"
						type={statusEnum[details?.status]}
						title={details?.status}
					/>
				)}
				<div className="flex items-center space-x-3">
					{["failed"].includes(details?.status) && (
						<PopoverMain
							button={<Button.Dark title="Revert" disabled={loading} />}
							disabled={loading}
							popoverClassName="p-6 w-80 right-0 bg-white shadow-md top-12 rounded"
						>
							<div className="py-4 px-1">
								<p className=" glade-black text-center mb-8">
									Are you sure you want to revert this transaction?
								</p>
								<Button.Dark
									title="Revert"
									onClick={() => {
										dispatch(
											revertSpecificTransferTransactions(details.txn_ref)
										);
									}}
									disabled={isRevertingSpecificTransferTransactions}
									className={`glade-normal-text-three glade-button-blue ${
										isRevertingSpecificTransferTransactions
											? " cursor-not-allowed"
											: ""
									} text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
								>
									{isRevertingSpecificTransferTransactions
										? "Reverting"
										: "Revert"}
									{isRevertingSpecificTransferTransactions && (
										<RiLoader5Fill size={24} className="animate-spin ml-4" />
									)}
								</Button.Dark>
								<PopoverButton>
									<p className="glade-black mx-1 cursor-pointer text-center">
										Cancel
									</p>
								</PopoverButton>
							</div>
						</PopoverMain>
					)}
					{["failed", "pending"].includes(details?.status) && (
						<PopoverMain
							button={<Button.Dark title="Requery" disabled={loading} />}
							disabled={loading}
							popoverClassName="p-6 w-80 right-0 bg-white shadow-md top-12 rounded"
						>
							<div className="py-4 px-1">
								<p className=" glade-black text-center mb-8">
									Are you sure you want to requery this transaction?
								</p>
								<Button.Dark
									title="Requery"
									onClick={() => {
										dispatch(
											verifySpecificTransferTransactions(details.txn_ref)
										);
									}}
									disabled={isVerifyingSpecificTransferTransactions}
									className={`glade-normal-text-three glade-button-blue ${
										isVerifyingSpecificTransferTransactions
											? " cursor-not-allowed"
											: ""
									} text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
								>
									{isVerifyingSpecificTransferTransactions
										? "Requerying"
										: "Requery"}
									{isVerifyingSpecificTransferTransactions && (
										<RiLoader5Fill size={24} className="animate-spin ml-4" />
									)}
								</Button.Dark>
								<PopoverButton>
									<p className="glade-black mx-1 cursor-pointer text-center">
										Cancel
									</p>
								</PopoverButton>
							</div>
						</PopoverMain>
					)}
				</div>
			</div>

			<div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">
						Transaction Details
					</p>
				</div>

				<div className="grid grid-cols-4 gap-4 py-3">
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Type</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1 capitalize">
								{details?.type}
							</h6>
						)}
					</div>

					{details?.txn_ref && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Transaction Reference
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<div className="flex items-center">
									<h6 className="glade-normal-text-three glade-black py-1">
										{truncateRef(details?.txn_ref)}
									</h6>
									<FiCopy
										size={16}
										color="#AFAFAF"
										className="ml-3 cursor-pointer"
										onClick={() => copyTextToClipboard(details?.txn_ref ?? "")}
									/>
								</div>
							)}
						</div>
					)}

					{details?.amount && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{details?.currency ?? "NGN"}{" "}
									{Number(details?.amount)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
								</h6>
							)}
						</div>
					)}

					{details?.transaction_date && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">Date</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<>
									<h6 className="glade-normal-text-three glade-black">
										{dfn.format(
											new Date(details?.transaction_date),
											"MMM d, yyyy"
										)}
									</h6>
									<h6 className="glade-normal-text-three glade-black">
										{dfn.format(
											new Date(details?.transaction_date),
											"hh:mm:ss aa"
										)}
									</h6>
								</>
							)}
						</div>
					)}

					{details?.order_reference && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Order Ref
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<div className="flex items-center">
									<h6 className="glade-normal-text-three glade-black py-1">
										{truncateRef(details?.order_reference)}
									</h6>
									<FiCopy
										size={16}
										color="#AFAFAF"
										className="ml-3 cursor-pointer"
										onClick={() =>
											copyTextToClipboard(details?.order_reference ?? "")
										}
									/>
								</div>
							)}
						</div>
					)}

					{details?.receipient_or_creditor && details?.type === "debit" && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Recipient Name
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1 break-word w-52">
									{details?.receipient_or_creditor}
								</h6>
							)}
						</div>
					)}

					{(details?.receipient_bank ||
						details?.receipient_bank_code ||
						details?.receipient_or_creditor_institution) &&
						details?.type === "debit" && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Recipient Bank
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1 capitalize break-word w-52">
										{details?.receipient_bank ||
											details?.receipient_bank_code ||
											details?.receipient_or_creditor_institution}
									</h6>
								)}
							</div>
						)}

					{details?.receipient_nunmber && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Recipient Number
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{details?.receipient_nunmber}
								</h6>
							)}
						</div>
					)}

					{details?.destination && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Destination
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1 capitalize">
									{details?.destination}
								</h6>
							)}
						</div>
					)}

					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Transaction Channel
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1 capitalize">
								{details?.channel}
							</h6>
						)}
					</div>

					{details?.details && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">Details</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1 pr-4 w-52 break-word">
									{details?.details}
								</h6>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

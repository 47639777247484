import { useSelector } from "react-redux";

const useWallets = () => {
  const wallets = useSelector((state) => state.wallets);

  const {
    isLoadingWalletRequests,
    walletRequestsData,
    isLoadingWalletDetails,
    walletDetailsData,
    isLoadingWalletStatusUpdate,
    accountRequestsData,
    accountDetailsData,
    isLoadingAccountRequests,
    isLoadingAccountDetails
  } = wallets;

  return {
    isLoadingWalletRequests,
    walletRequestsData,
    isLoadingWalletDetails,
    walletDetailsData,
    isLoadingWalletStatusUpdate,
    accountRequestsData,
    accountDetailsData,
    isLoadingAccountRequests,
    isLoadingAccountDetails
  };
};

export default useWallets;


import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import { updateBusinessAccountDetails } from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";

const EditBusinessDescriptionModal = ({
	modalStates,
	setModalStates,
	showEditBusinessDescriptionModal,
}) => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { isUpdatingBusinessDescription, specificBusinessAccountCompliance } =
		useAccounts();

	const onUpdateBusinessDescription = (data) => {
		dispatch(updateBusinessAccountDetails(id, data.description));
	};

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm();

	return (
		<BasicModal
			visibility={showEditBusinessDescriptionModal}
			onClose={() => {
				reset();
				setModalStates({
					...modalStates,
					showEditBusinessDescriptionModal: false,
				});
			}}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditBusinessDescriptionModal: true,
				})
			}
			primaryButton=""
			modalTitle="Update Description"
			secondaryButton=""
			modelContentSection={
				<form onSubmit={handleSubmit(onUpdateBusinessDescription)} className="">
					<div className="">
						<label htmlFor="description" className="block glade-small-text-two">
							Description
						</label>
						<textarea
							type="text"
							id="description"
							name="description"
							{...register("description", {
								required: "Enter a valid description",
								minLength: {
									value: 5,
									message: "Enter a valid description",
								},
							})}
							rows={4}
							defaultValue={
								specificBusinessAccountCompliance?.business_details?.[0]
									?.business_description
							}
							placeholder="New Description"
							className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
						/>
						<FormError errors={errors} name="description" />
					</div>

					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={() => {
								reset();
								setModalStates({
									...modalStates,
									showEditBusinessDescriptionModal: false,
								});
							}}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Save"
								className="block flex-grow-0"
								loading={isUpdatingBusinessDescription}
							/>
						</div>
					</div>
				</form>
			}
		/>
	);
};

export default EditBusinessDescriptionModal;

import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

import { TextSkeleton } from "../../components/skeletons";
import styled from "styled-components";
// import MostUsedServicesFilter from "./MostUsedServicesFilter";
import useHome from "../../hooks/useHome";

const TooltipStyle = styled.div`
  background: #717485;
  border: 1px solid #717485;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;

  .label {
    color: #fbfbfb60;
  }

  .value {
    color: #f3f6f7;
  }
`;

const COLORS = [
  "#77C4E5",
  "#E5DAFB",
  "#3EA32E",
  "#A61C1C",
  "#BE3F7C",
  "#F6FB07",
  "#A8FFDA",
  "#341F88",
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipStyle>
        <p className="glade-small-text-one value text-center capitalize">
          {payload[0].payload.payload.service}
        </p>
        <p className="glade-small-text-one value text-center">
          {payload[0].payload.payload.transactions}
        </p>
      </TooltipStyle>
    );
  }
  return null;
};

const MostUsedServices = () => {
  const { isLoadingMostUsedServices, mostUsedServices } = useHome();
  const { report } = mostUsedServices;

  const calculatePercent = (service) => {
    const transactions_total = report?.reduce(
      (acc, { transactions }) => acc + transactions,
      0,
    );

    const percent = ((service / transactions_total) * 100).toFixed(1);
    return percent;
  };

  return (
    <div className="flex flex-col p-5 bg-white rounded mt-6 pb-10">
      <div className="flex row justify-between mb-6 pb-1 border-b">
        <h6 className="glade-normal-text-two glade-black">Most Used Services</h6>

        {/* <MostUsedServicesFilter /> */}
      </div>

      <div className="h-96 w-full pt-5">
        {isLoadingMostUsedServices ? (
          <TextSkeleton className="h-full" />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie
                data={report}
                cx="50%"
                cy="50%"
                outerRadius={"100%"}
                fill="#8884d8"
                dataKey="transactions"
              >
                {report?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    // onMouseOver={() => (tooltip = entry)}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} cursor={false} />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>

      {!isLoadingMostUsedServices && report?.length && (
        <div className="grid grid-cols-4 gap-5 mt-10">
          {report.map((item, idx) => (
            <div className="flex items-start">
              <span
                className="inline-block w-3 h-3 mr-2 rounded"
                style={{
                  background: `${COLORS[idx % COLORS.length]}`,
                }}
              ></span>
              <div>
                <h5 className="glade-small-text-one glade-black capitalize">
                  {item.service}
                </h5>
                <h5 className="glade-small-text-three glade-ash">
                  {calculatePercent(item.transactions)}%
                </h5>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MostUsedServices;

import React, { useEffect, useState } from "react";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import TabNav from "../../components/navbars/TabNav";
import { PaginatedTable } from "../../components/table/Table";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import EditCurrencyRateModal from "./EditCurrencyRateModal";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCountriesByContinent, getCurrencyRates, getSupportedCurrencies, updateCurrencyStatus } from "../../redux/cross-border/crossBorderActions";
import useCrossBorder from "../../hooks/useCrossBorder";
import Button from "../../components/buttons/Button";
import { TextSkeleton } from "../../components/skeletons";
import BasicModal from "../../components/modals/BasicModal";
import { RiLoader5Fill } from "react-icons/ri";

const Currencies = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [source_continent, set_source_continent] = useState('Africa');
	const [searchParams, setSearchParams] = useState('');
	const [clearSearchInput, setClearSearchInput] = useState(false);
	const [showStatusModal, setShowStatusModal] = useState(false);
	const [selectedCurrency, setSelectedCurrency] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const defaultFilterParams = {
		search: "",
		page: currentPage,
		source: source_continent ?? 'africa'
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});




	const { handleChange } = filterInstance;

	const {
		isLoadingCountries,
		isLoadingCurrencyStatusUpdate,
		countriesByContinent,
	} = useCrossBorder();

	const resetReferenceSearch = (event) => {
		setSearchParams('');
		setCurrentPage(1);
	};

	const onSubmitOfStatus = () => {
		const onClose = () => {
			dispatch(getCountriesByContinent(currentPage, source_continent, searchParams))
			setShowStatusModal(false);
		}
		dispatch(updateCurrencyStatus({
			id: selectedCurrency?.id,
			status: selectedCurrency?.status ? 'disable' : 'enable'
		},
			onClose
		));
	};


	useEffect(() => {
		return qs.parse(location.search)?.continent !== source_continent.toLocaleLowerCase() ? setClearSearchInput(true) & resetReferenceSearch() & set_source_continent(qs.parse(location.search)?.continent) : setClearSearchInput(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [qs.parse(location.search)?.continent]);

	useEffect(() => {
		dispatch(getCountriesByContinent(currentPage, source_continent, searchParams))
		setClearSearchInput(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, searchParams, source_continent]);

	const columns = [
		{
			accessor: "currency_slug",
			Header: "Currency Name",
			Cell: ({ value, row }) => (
				<div className="glade-normal-text-two capitalize">
					{
						isLoadingCountries ?
							<TextSkeleton /> :
							value
					}
				</div>
			),
		},
		{
			accessor: "added_value",
			Header: "",
			Cell: ({ value, row }) => (
				<div className="flex items-center">
					{value ? (
						<span className="glade-normal-text-two">
							{Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})} {row?.original?.added_value_type === 'percentage' ? '%' : ''}
						</span>
					) : (
						<span className="glade-normal-text-two"></span>
					)}
				</div>
			),
		},
		{
			accessor: "value",
			Header: "",
			Cell: ({ value, row }) => (
				<div className="flex items-center">
					{!isLoadingCountries ? (
						<span className="glade-normal-text-two">

						</span>
					) : (
						<span className="glade-normal-text-two"></span>
					)}
				</div>
			),
		},
		{
			accessor: "status",
			Header: "",
			Cell: ({ value, row }) => (
				<div className="flex w-2/3 flex-col items-center justify-center">

					{!isLoadingCountries ?
						<div>
							{
								value ?
									<Button.Danger
										title={'Disable'}
										onClick={() => { setSelectedCurrency({ ...row?.original }); setShowStatusModal(true) }}
									/>
									:
									<Button.Dark
										title={'Enable'}
										className={'rounded'}
										onClick={() => { setSelectedCurrency({ ...row?.original }); setShowStatusModal(true) }}
									/>}
						</div>
						:
						<TextSkeleton />
					}
				</div>
			),
		},
	];

	const tabsList = () => {
		let continents = ['africa', 'asia', 'europe', 'north america', 'south america', 'oceania'];
		if (continents?.length > 0) {
			return continents.map((name) => { return { 'title': name.replaceAll('_', ' '), key: name, path: `/dashboard/currencies?continent=${name}` }; }
			);
		}
	};

	return (
		<div className="p-3">
			<TabNav tabs={tabsList()}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						// handleChange({ search: value.trim() });						
						setSearchParams(value);
					} else {
						resetReferenceSearch();
					}
				}}
				filterButton=""
				defaultFilterParams={defaultFilterParams}
				filterCallback={handleChange}
				disabled={isLoadingCountries}
				title={`Currencies`}
				searchBarPlaceholder={"Currency"}
				clearSearchInput={clearSearchInput}
			/>
			<PaginatedTable
				columns={columns}
				data={
					isLoadingCountries?
					[{}, {}, {}, {}, {}]
					:
					countriesByContinent?.data 
						? countriesByContinent?.data
						: []
				}
				empty_message="No Data"
				empty_sub_message=""
				current_page={currentPage}
				loading={false}
				setCurrentPage={setCurrentPage}
				totalPages={countriesByContinent?.total ?? 1}
			/>
			<BasicModal
				visibility={showStatusModal}
				onClose={() => setShowStatusModal(false)}
				onOpen={() => setShowStatusModal(true)}
				primaryButton=""
				modalTitleSection=""
				secondaryButton=""
				modelContentSection={
					<div className="flex items-center justify-center space-x-3">
						<div className="py-4 px-1">
							<div className="w-full flex justify-center">
								<p className="w-2/3 glade-black flex items-center text-center mb-8">
									Are you sure you want to {selectedCurrency.status ? 'Disable' : 'Enable'} {selectedCurrency.currency_slug} for all users?
								</p>
							</div>
							<div>
								<Button.Dark
									onClick={() => {
										onSubmitOfStatus();
									}}
									disabled={isLoadingCurrencyStatusUpdate}
									title={selectedCurrency.status ? 'Disable ' + selectedCurrency.currency_code : 'Enable ' + selectedCurrency.currency_code}
									className={`glade-normal-text-three glade-button-blue ${selectedCurrency.status ? 'glade-button-red' : ''} ${isLoadingCurrencyStatusUpdate
										? " cursor-not-allowed"
										: ""
										} text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
								>
									{isLoadingCurrencyStatusUpdate
										? "Approving"
										: "Approve"}
									{isLoadingCurrencyStatusUpdate && (
										<RiLoader5Fill
											size={24}
											className="animate-spin ml-4"
										/>
									)}
								</Button.Dark>
							</div>
							<div>
								<Button.Gray
									className="glade-black mx-1 cursor-pointer text-center"
									title="Cancel"
									onClick={() => setShowStatusModal(false)}
									disabled={isLoadingCurrencyStatusUpdate}
								/>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Currencies;
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import { useDispatch } from "react-redux";
import qs from 'query-string';
import { useLocation } from "react-router-dom";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import { PaginatedTable } from "../../components/table/Table";
import EditChargeModal from "./EditChargeModal";
import { getSupportedTransferCurrencies } from "../../redux/cross-border/crossBorderActions";
import useCrossBorder from "../../hooks/useCrossBorder";
import TabNav from "../../components/navbars/TabNav";

const CrossBorderCharges = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [currentPage, setCurrentPage] = useState(qs.parse(location.search)?.page ?? 1);
	const [searchParams, setSearchParams] = useState('');

	const [transferMethods, setTransferMethods] = useState({
		selectedMethod: {},
		all: [
			{
				title: 'Cross Border Outflow',
				code: 'bank'
			},
			{
				title: 'African countries(Account)',
				code: 'account'
			},
			{
				title: 'African countries(Mobile Money)',
				code: 'mobilemoney'
			},
			{
				title: 'Receivable Charge',
				code: 'account_receivable'
			},
		]
	});
	const [selectedMethod, setSelectedMethod] = useState('bank');
	const [modalStates, setModalStates] = useState({ showEditChargeModal: false });
	const [clearSearchInput, setClearSearchInput] = useState(false);
	const [selectedCharge, setSelectedCharge] = useState(null);

	const [data, setData] = useState([]);
	const { supportedCurrenciesTransfer, isLoadingSupportedCurrencies } = useCrossBorder();

	const defaultFilterParams = {
		search: "",
		page: currentPage,
		method: selectedMethod ?? "bank"
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams
	});

	useEffect(() => {
		return qs.parse(location.search)?.method !== selectedMethod ? setClearSearchInput(true) & resetReferenceSearch() & setSelectedMethod(qs.parse(location.search)?.method) : setClearSearchInput(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [qs.parse(location.search)?.method]);

	useEffect(() => {
		if (!isLoadingSupportedCurrencies) {
			setData(supportedCurrenciesTransfer);
		}
	}, [supportedCurrenciesTransfer, isLoadingSupportedCurrencies]);

	const { filters, handleChange } = filterInstance;

	useEffect(() => {
		dispatch(getSupportedTransferCurrencies({
			params: {
				...(searchParams
					? {
						search: searchParams,
						paginate: 1,
						current_page: currentPage,
						per_page: 10,
						method: selectedMethod
					}
					: {
						paginate: 1,
						current_page: currentPage,
						per_page: 10,
						method: selectedMethod,
						search: searchParams,
					})
			}
		}));
		setClearSearchInput(false);

	}, [currentPage, searchParams, selectedMethod]);

	const resetReferenceSearch = event => {
		setSearchParams('');
		setCurrentPage(1);
	};


	const openEditChargeModal = charge => {
		setSelectedCharge(charge);
		setModalStates(modalStates => ({
			...modalStates,
			showEditChargeModal: true
		}));
	};

	const columns = [
		{
			accessor: "currency_code",
			Header: "Name",
			Cell: ({ value, row }) => (
				<span className="glade-normal-text-two capitalize">
					{value}
				</span>
			),
		},
		{
			accessor: "fee_fixed",
			Header: "Fixed Fee",
			Cell: ({ value, row }) => (
				<span className="glade-normal-text-two capitalize">
					{value || "-"}
				</span>
			),
		},
		{
			accessor: "fee_percentage",
			Header: "Percentage Fee",
			Cell: ({ value, row }) => (
				<span className="glade-normal-text-two capitalize">
					{value || "-"}
				</span>
			),
		},
		{
			accessor: "fee_cap",
			Header: "Capped Fee",
			Cell: ({ value, row }) => (
				<span className="glade-normal-text-two capitalize">
					{value || "-"}
				</span>
			),
		},
		{
			accessor: "",
			Header: " ",
			Cell: ({ value, row }) => (
				<div className="flex items-center justify-center">
					<span
						className="glade-blue-normal glade-normal-text-two cursor-pointer"
						onClick={() => openEditChargeModal(row.original)}
					>
						Edit
					</span>
				</div>
			),
		}
	];

	const smartTabs = () => {
		if (transferMethods?.all.length > 0) {
			return transferMethods.all.map(method => {
				return {
					title: method.title,
					path: `/dashboard/cross-border/charges?method=${method.code.split(" ").join("").toLowerCase()}`,
					code: method.code
				};
			});
		}
	};

	return (
		<div className="p-3">
			<TabNav tabs={smartTabs()} />
			<CardTableHeader
				title={"Cross-border Transfer Charges"}
				filterButton=""
				onChange={e => {
					const value = e.target.value;
					if (value) {
						setSearchParams(value);
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				searchBarPlaceholder={"Fee Name"}
				clearSearchInput={clearSearchInput}
				filterCallback={handleChange}
			/>

			<PaginatedTable
				columns={columns}
				data={data?.data || data}
				empty_message={"No supported currencies"}
				empty_sub_message=""
				loading={isLoadingSupportedCurrencies}
				current_page={currentPage}
				setCurrentPage={setCurrentPage}
				totalPages={supportedCurrenciesTransfer?.total ?? 1}
			/>

			<EditChargeModal
				modalStates={modalStates}
				setModalStates={setModalStates}
				selectedCharge={selectedCharge}
				setSelectedCharge={setSelectedCharge}
				showEditChargeModal={modalStates?.showEditChargeModal}
				setData={setData}
				filterValue={filters}
			/>
		</div>
	);
};

export default CrossBorderCharges;
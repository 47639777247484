import React from "react";
import { Link } from "react-router-dom";

export default function SidebarLink({
	path,
	name,
	icon,
	linkClassName,
	linkStyle,
	listClassName,
	listStyle,
}) {
	// Check if link is the current page
	const [current, setCurrent] = React.useState(false);
	const pageLink = window.location.href;

	React.useEffect(() => {
		pageLink.includes(path) ? setCurrent(true) : setCurrent(false);
	}, [path, pageLink]);

	return (
		<li className={"items-center list-none " + listClassName} style={listStyle}>
			<Link
				className={"glade-normal-text-two py-1 mb-4 block " + linkClassName}
				style={linkStyle}
				to={path}
			>
				{!current ? (
					<span className="flex items-center text-white opacity-60 hover:opacity-100">
						<span className={"glade-normal-text-two inline-flex"}>
							{icon && React.cloneElement(icon)}
						</span>
						<span
							className={
								"glade-normal-text-two inline-flex ml-3 whitespace-nowrap"
							}
						>
							{name}
						</span>
					</span>
				) : (
					<span className="flex items-center">
						<span className={"inline-flex text-white"}>
							{icon && React.cloneElement(icon, { color: "#FFFFFF" })}
						</span>
						<span className={"inline-flex text-white ml-3 whitespace-nowrap"}>
							{name}
						</span>
					</span>
				)}
			</Link>
		</li>
	);
}

import { Switch } from "@headlessui/react";
import React, { useState } from "react";

const CustomSwitch = ({ className, label, status, onChange = () => {} }) => {
	const [enabled, setEnabled] = useState(!!status);

	const triggerChange = () => {
		setEnabled(!enabled);
		onChange();
	};

	return (
		<Switch.Group>
			<div className={"flex items-center " + className}>
				{label && (
					<Switch.Label className="mr-4 glade-normal-text-two cursor-pointer">
						{label}
					</Switch.Label>
				)}
				<Switch
					checked={enabled}
					onChange={triggerChange}
					className={`${
						enabled ? "glade-button-dark" : "bg-gray-200"
					} relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
				>
					<span
						className={`${
							enabled ? "translate-x-6" : "translate-x-1"
						} inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
					/>
				</Switch>
			</div>
		</Switch.Group>
	);
};

export default CustomSwitch;

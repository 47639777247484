import React from 'react'
import { TextSkeleton } from '../../components/skeletons'

const CacRegDetailsBreakdown = ({ title, isLoadingSingleCAC, cacSingleData, type, capitalize=false }) => {
  return (
    <div className="flex flex-col">
      <h5 className="glade-normal-text-two glade-ash py-1">
        {title}
      </h5>
      {isLoadingSingleCAC ? (
        <TextSkeleton width="100px" />
      ) : (
        type === 'file' ?
          <>
            {cacSingleData ?
              <a
                href={cacSingleData}
                target="_blank"
                rel="noreferrer noopener"
              >
                <h6 className="glade-normal-text-three glade-new-blue py-1 cursor-pointer capitalize">
                  view document
                </h6>
              </a>
              : <h6 className="glade-normal-text-three glade-black py-1">Not available</h6>
            }
          </>
          :
          <h6 className={`${capitalize ? "capitalize" : ""} glade-normal-text-three glade-black py-1`}>
            {cacSingleData ?? "Not available"}
          </h6>
      )}
    </div>
  )
}

export default CacRegDetailsBreakdown
import styled from "styled-components";

export const Contents = styled.div`
  padding: 1rem 0;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 3fr);
  row-gap: 3rem;
  column-gap: 1rem; 
`

export const SCBox = styled.main`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  .segment-title {
  	color: #9CA3AF;
  }
`
export const Content = styled.div`
  display: flex;
  gap: .5rem;
  
  .name {
    color: #9CA3AF;
    text-transform: capitalize;
  } 
`

export const Control = styled.div`
  
  .title {
    border-bottom: 2px solid ${({ isOpen }) => isOpen ? '#E4E4F3' : 'transparent'};
  }
`

export const SCAccordion = styled.div`
  margin: 1rem 0;
  outline: 2px solid #E4E4F3;
  overflow: hidden;
  background: #FCFCFC;
  padding: .8rem 2.5rem;
  border-radius: 5px;
  0px 0px 2px rgba(0, 0, 0, 0.25);
`

import React, { Fragment } from "react";

const BusinessDocumentCard = ({ details }) => {
    return (
        <div className="flex flex-col bg-white rounded-sm px-8 py-4 mb-6">
            <div className="py-3 border-b-2 border-solid border-gray-100">
                <p className="glade-black font-bold">Business Documents</p>
            </div>

            <div className="grid grid-cols-4 gap-4 py-3">
                {details?.details?.map((detail, key) => (
                    <Fragment key={key}>
                        {detail?.type === 'text' &&
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                                    {detail?.title?.split("_").join(" ")}
                                </h5>
                                <h6 className="glade-normal-text-three glade-black py-1">
                                    {detail?.title !== 'business_name' ? detail?.compliance_data : details?.submission?.business_name}
                                </h6>
                            </div>}
                        {detail.file_url && (
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                                    {detail?.title?.split("_").join(" ")}
                                </h5>
                                <a
                                    href={detail.file_url}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <h6 className="glade-normal-text-three glade-blue py-1 cursor-pointer capitalize">
                                        view document
                                    </h6>
                                </a>
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    )
}

export default BusinessDocumentCard;
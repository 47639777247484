import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import { updateBusinessSettlementType } from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";

const EditBusinessSettlementTypeModal = ({
	modalStates,
	setModalStates,
	showEditBusinessSettlementTypeModal,
}) => {
	const dispatch = useDispatch();
	const { id, user_uuid } = useParams();

	const { isUpdatingBusinessSettlementType, specificBusinessSettlementType } =
		useAccounts();

	const onUpdateBusinessSettlementType = (data) => {
		dispatch(
			updateBusinessSettlementType(id, user_uuid, data.type, () => {
				setModalStates({
					...modalStates,
					showEditBusinessSettlementTypeModal: false,
				});
			})
		);
	};

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm({});

	return (
		<BasicModal
			visibility={showEditBusinessSettlementTypeModal}
			onClose={() => {
				reset();
				setModalStates({
					...modalStates,
					showEditBusinessSettlementTypeModal: false,
				});
			}}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditBusinessSettlementTypeModal: true,
				})
			}
			primaryButton=""
			modalTitle="Edit Business Settlement Type"
			secondaryButton=""
			modelContentSection={
				<form
					onSubmit={handleSubmit(onUpdateBusinessSettlementType)}
					className=""
				>
					<div className="w-full my-3">
						<label htmlFor="type" className="block glade-small-text-two">
							Select Type
						</label>
						<select
							id="type"
							name="type"
							{...register("type", { required: "Select a type" })}
							defaultValue={specificBusinessSettlementType?.type ?? ""}
							className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
						>
							<option value="" disabled>
								-- Select a type --
							</option>
							<option value="wallet">Glade Wallet</option>
							<option value="account">External Account</option>
						</select>
						<FormError errors={errors} name="type" />
					</div>
					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={() => {
								reset();
								// setSelectedAccount(null);
								setModalStates({
									...modalStates,
									showEditBusinessSettlementTypeModal: false,
								});
							}}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Save"
								className="block flex-grow-0"
								loading={isUpdatingBusinessSettlementType}
							/>
						</div>
					</div>
				</form>
			}
		/>
	);
};

export default EditBusinessSettlementTypeModal;

import React from "react";
import { Contents } from "./styled";
import AccordionContent from "./AccordionContent";

const AccordionContents = ({ contents }) => {

	return (
		<Contents>
			<AccordionContent fieldName={"First Name"} data={contents?.first_name} />
			<AccordionContent fieldName={"Last Name"} data={contents?.last_name} />
			<AccordionContent fieldName={"Email"} data={contents?.email} />
			{contents?.address &&
				<AccordionContent fieldName={"Address"} data={contents?.address} />
			}
			<AccordionContent fieldName={"Phone Number"} data={contents?.phone} />
			{contents?.documents?.length > 0 && contents?.documents.map((content, key) => (
				<AccordionContent
					fieldName={content?.title?.split("_").join(" ")}
					data={content?.compliance_data}
					fileUrl={content?.file_url}
					key={key}
					dynamic
				/>
			))}
			{contents?.state &&
				<AccordionContent fieldName={"State"} data={contents?.state} />
			}
			{contents?.lga &&
				<AccordionContent fieldName={"LGA"} data={contents?.lga} />
			}

		</Contents>
	)
}
export default AccordionContents

import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import {
	updateBusinessAccountComplianceDocument,
	updateBusinessAccountComplianceText,
} from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";
import { IoMdCloseCircle } from "react-icons/io";

const EditBusinessComplianceModal = ({
	modalStates,
	setModalStates,
	showEditBusinessComplianceModal,
	selectedCompliance,
	setSelectedCompliance,
}) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	let initialFocusRef = useRef(null);
	// const [selectedFile, setSelectedFile] = useState(null)

	const { isUpdatingBusinessCompliance } = useAccounts();

	const onUpdateCompliance = (data) => {
		if (selectedCompliance?.type === "file") {
			let formData = new FormData();
			formData.append("compliance_document", data.file);
			formData.append(
				"compliance_details_reference",
				selectedCompliance?.compliance_details_reference
			);
			formData.append("compliance_id", selectedCompliance?.compliance_id);
			formData.append("title", selectedCompliance?.title);

			dispatch(updateBusinessAccountComplianceDocument(id, formData));
		} else {
			const payload = {
				compliance_data: data.compliance_data,
				compliance_details_id: selectedCompliance?.compliance_details_id,
				title: selectedCompliance?.title
			};
			dispatch(updateBusinessAccountComplianceText(id, payload));
		}
	};

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			// compliance_data: "",
			file: "",
		},
	});

	const selectedFile = watch("file");

	useEffect(() => {
		register("file", {
			required: "Select a new file",
		});
	}, [register]);

	return (
		<BasicModal
			visibility={showEditBusinessComplianceModal}
			initialFocus={initialFocusRef}
			onClose={() => {
				reset();
				setModalStates({
					...modalStates,
					showEditBusinessComplianceModal: false,
				});
				setSelectedCompliance({});
			}}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditBusinessComplianceModal: true,
				})
			}
			primaryButton=""
			modalTitle={`Edit ${
				selectedCompliance?.display_name ??
				selectedCompliance?.title?.replaceAll("_", " ")
			}`}
			secondaryButton=""
			modelContentSection={
				<form onSubmit={handleSubmit(onUpdateCompliance)} className="">
					<div className="">
						<p className="block glade-small-text-two capitalize">
							{selectedCompliance?.display_name ??
								`${selectedCompliance?.title?.replaceAll("_", " ")}`}
						</p>
						{/* text */}
						{selectedCompliance?.type === "text" && (
							<>
								<input
									type="text"
									id="compliance_data"
									name="compliance_data"
									{...register("compliance_data", {
										required: "Enter a new value",
									})}
									placeholder="Enter a value"
									defaultValue={selectedCompliance?.compliance_data}
									className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
								/>
								<FormError errors={errors} name="compliance_data" />
							</>
						)}

						{/* file */}
						{selectedCompliance?.type === "file" && (
							<>
								<div className="mt-1 block w-full p-2 py-3 border-gray-300 border rounded-sm glade-normal-text-two">
									<div className="relative w-fit-content">
										<label
											htmlFor="file"
											className="flex items-center glade-bg-grey p-2 h-6 w-fit-content rounded-full "
										>
											{selectedFile ? (
												<>
													<p className="glade-normal-text-two mr-3">
														{selectedFile?.name}
													</p>
													<IoMdCloseCircle />
												</>
											) : (
												"Select a new file"
											)}
										</label>
										<input
											type="file"
											id="file"
											name="file"
											accept=".png, .jpg, .jpeg, .pdf"
											onChange={(e) => {
												setValue("file", e?.target?.files?.[0]);
											}}
											className="absolute w-full h-full opacity-0 inset-0 cursor-pointer"
										/>
									</div>
								</div>
								<FormError errors={errors} name="file" />
							</>
						)}
					</div>

					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={() => {
								reset();
								setSelectedCompliance({});
								setModalStates({
									...modalStates,
									showEditBusinessComplianceModal: false,
								});
							}}
							autoFocus={true}
							ref={initialFocusRef}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Update"
								className="block flex-grow-0"
								loading={isUpdatingBusinessCompliance}
							/>
						</div>
					</div>
				</form>
			}
		/>
	);
};

export default EditBusinessComplianceModal;

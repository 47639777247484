import Axios from "../../connection/defaultClient";
import errorHandler from "../../handlers/errorHandler";
import {
  persistLastLogin,
  updatePersistedLastLogin,
  destroyLastLogin,
  retrievePersistedLastLogin,
} from "../../helpers/session";
import {
  LOADING,
  LOGIN_USER,
  LOGOUT_USER,
  REFRESHED_TOKEN,
  REGISTER_USER,
  TOGGLE_ACCOUNT,
} from "./types";

export const loginUserAction = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payLoad: true });
    Axios.post(`aaa/authenticate`, { ...payLoad })
      .then((data) => {
        dispatch({ type: LOADING, payLoad: false });
        dispatch({ type: LOGIN_USER, payLoad: data });
        persistLastLogin(data);
      })
      .catch((error) => {
        dispatch({ type: LOADING, payLoad: false });
        errorHandler(error, true);
      });
  };
};

export const logoutUserAction = () => {
  return (dispatch) => {
    destroyLastLogin();
    dispatch({ type: LOGOUT_USER, payLoad: {} });
  };
};

export const toggleAccountAction = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_ACCOUNT });
    window.location.pathname = "/dashboard/home";
  };
};

export const registerUserAction = (payLoad, redirectToLogin) => {
  return (dispatch) => {
    Axios.post(`auth/register`, { ...payLoad })
      .then((data) => {
        dispatch({ type: REGISTER_USER, payLoad: data });
        redirectToLogin();
      })
      .catch((error) => {
        errorHandler(error);
      });
  };
};

export const refreshUserTokenAction = (payLoad) => {
  return (dispatch) => {
    updatePersistedLastLogin(payLoad);
    dispatch({ type: REFRESHED_TOKEN, payLoad: payLoad });
  };
};

export const setAuthFromCache = () => {
  return async (dispatch) => {
    const data = await retrievePersistedLastLogin();
    if (data) {
      dispatch({ type: LOGIN_USER, payLoad: data });
    }
  };
};

import React from 'react'

const AccordionTitle = ({ title }) => {
  return (
    <div>
      <p className={'segment-title'}>{title}</p>
    </div>
  )
}

export default AccordionTitle
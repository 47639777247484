import React, { useEffect, useState } from "react";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import { PaginatedTable } from "../../components/table/Table";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import EditCurrencyRateModal from "./EditCurrencyRateModal";
import qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCurrencyRates, getSupportedCurrencies } from "../../redux/cross-border/crossBorderActions";
import useCrossBorder from "../../hooks/useCrossBorder";

const CrossBorderRates = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const [source_currency, set_source_currency] = useState('ngn');
	const [searchParams, setSearchParams] = useState('');
	const [clearSearchInput, setClearSearchInput] = useState(false);
	const [selectedFee, setSelectedFee] = useState(null);
	const [modalStates, setModalStates] = useState({
		showEditModal: false,
	});
	const [currentPage, setCurrentPage] = useState(1);
	const defaultFilterParams = {
		search: "",
		page: currentPage,
		source: source_currency ?? 'ngn'
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});



	const openEditModal = (fee) => {
		setSelectedFee(fee);
		setModalStates({
			...modalStates,
			showEditModal: true,
		});
	};

	const { handleChange } = filterInstance;

	const { isLoadingSupportedCurrenciesRates, supportedCurrencies, supportedCurrenciesRates } = useCrossBorder();

	const resetReferenceSearch = (event) => {
		setSearchParams('');
		setCurrentPage(1);
	};


	useEffect(() => {
		return qs.parse(location.search)?.source !== source_currency ? setClearSearchInput(true) & resetReferenceSearch() & set_source_currency(qs.parse(location.search)?.source) : setClearSearchInput(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [qs.parse(location.search)?.source]);

	useEffect(() => {
		dispatch(getSupportedCurrencies());
		dispatch(getCurrencyRates(source_currency, currentPage, searchParams));
		setClearSearchInput(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, searchParams, source_currency]);

	const columns = [
		{
			accessor: "destination_currency",
			Header: "Name",
			Cell: ({ value, row }) => (
				<span className="glade-normal-text-two capitalize">
					{value?.replaceAll("_", " ").toLocaleUpperCase()}
				</span>
			),
		},
		{
			accessor: "value",
			Header: "Glade Rate",
			Cell: ({ value, row }) => (
				<div className="flex items-center">
					{value ? (
						<span className="glade-normal-text-two">
							{Number(value)}
						</span>
					) : (
						<span className="glade-normal-text-two">-</span>
					)}
				</div>
			),
		},
		{
			accessor: "new",
			Header: "",
			Cell: ({ value, row }) => (
				<div className="flex items-center justify-center">
					<span
						className="glade-blue-normal glade-normal-text-two cursor-pointer"
						onClick={() => openEditModal({ ...row.original, source_currency, currentPage })}
					>
						Edit
					</span>
				</div>
			),
		},
	];

	const tabsList = () => {
		if (supportedCurrencies?.length > 0) {
			return supportedCurrencies.map((currency) => { return { 'title': currency.toLocaleUpperCase(), path: `/dashboard/cross-border/rates?source=${currency}` }; }
			);
		}
	};

	return (
		<div className="p-3">
			<select
				defaultValue={'/dashboard/cross-border/rates?source=ngn'}
				onChange={(e) => history.push(e.target.value)}
				className="glade-blue-darker glade-normal-text-two border-solid border-gray-100 mb-4 rounded-md"
			>
				{tabsList()?.length > 0 ?
					tabsList()?.map((currency, index) =>
						<option key={index} value={currency?.path}>
							{currency?.title}
						</option>)
					: <option>Currencies List is empty </option>}
			</select>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						// handleChange({ search: value.trim() });						
						setSearchParams(value);
					} else {
						resetReferenceSearch();
					}
				}}
				filterButton=""
				defaultFilterParams={defaultFilterParams}
				filterCallback={handleChange}
				disabled={isLoadingSupportedCurrenciesRates}
				title={
					<div className="block flex justify-between items-center">
						{`${source_currency?.toLocaleUpperCase()} Rates`}
					</div>
				}
				searchBarPlaceholder={"Currency Name"}
				clearSearchInput={clearSearchInput}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof supportedCurrenciesRates?.data === "object" && !Array.isArray(supportedCurrenciesRates?.data)
						? supportedCurrenciesRates?.data
						: supportedCurrenciesRates?.data
				}
				empty_message="No Rates"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingSupportedCurrenciesRates}
				setCurrentPage={setCurrentPage}
				totalPages={supportedCurrenciesRates?.total ?? 1}
			/>
			{modalStates?.showEditModal &&
				<EditCurrencyRateModal
					modalStates={modalStates}
					setModalStates={setModalStates}
					selectedFee={selectedFee}
					setSelectedFee={setSelectedFee}
					showEditModal={modalStates?.showEditModal}
				/>}
		</div>
	);
};

export default CrossBorderRates;
import styled from 'styled-components';
import { RiCloseFill } from "react-icons/ri";
import { logger } from '../../helpers/helper';
// import { logger } from '../../helpers/helper';

const SMultiSelectItem = styled.span`
  border-radius: 10px;
  display: inline-flex;
  padding: .2rem .5rem;
  display: flex;
  align-items: center;
  background-color: #eee;
  gap: .3rem;
  color: #1a1a1a;
  
  .remove-icon {
    border-radius: 100%;
    min-height: 20px;
    cursor: pointer;
    min-width: 20px;
    background-color: #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 250ms ease-in;
    &:hover {
      color: crimson;
    }
  }
`;

const MultiSelectItem = ({ option, removeRecipient }) => {
  return (
    <SMultiSelectItem key={option.business_uuid || option.user_uuid}>
      <span className="value">{option.business_name || option.account_name || `${option.firstname} ${option.lastname}`}</span>
      <span
        onClick={() => removeRecipient(option.user_uuid)}
        className="remove-icon"
      >
        <RiCloseFill />
      </span>
    </SMultiSelectItem>
  );
};

export default MultiSelectItem;
export const GET_ALL_CAC_REGISTRATION_START = "GET_ALL_CAC_REGISTRATION_START";
export const GET_ALL_CAC_REGISTRATION_SUCCESS = "GET_ALL_CAC_REGISTRATION_SUCCESS";
export const GET_ALL_CAC_REGISTRATION_FAILURE = "GET_ALL_CAC_REGISTRATION_FAIURE";

export const GET_SINGLE_CAC_REGISTRATION_START = "GET_SINGLE_CAC_REGISTRATION_START";
export const GET_SINGLE_CAC_REGISTRATION_SUCCESS = "GET_SINGLE_CAC_REGISTRATION_SUCCESS";
export const GET_SINGLE_CAC_REGISTRATION_FAILURE = "GET_SINGLE_CAC_REGISTRATION_FAIURE";

export const MARK_CAC_REGISTRATION_START = "MARK_CAC_REGISTRATION_START";
export const MARK_CAC_REGISTRATION_SUCCESS = "MARK_CAC_REGISTRATION_SUCCESS";
export const MARK_CAC_REGISTRATION_FAILURE = "MARK_CAC_REGISTRATION_FAIURE";

export const GET_ALL_SETTLED_NGN_SETTLEMENTS_START = "GET_ALL_SETTLED_NGN_SETTLEMENTS_START";
export const GET_ALL_SETTLED_NGN_SETTLEMENTS_SUCCESS = "GET_ALL_SETTLED_NGN_SETTLEMENTS_SUCCESS";
export const GET_ALL_SETTLED_NGN_SETTLEMENTS_FAILURE = "GET_ALL_SETTLED_NGN_SETTLEMENTS_FAILURE";

export const GET_SPECIFIC_SETTLED_SETTLEMENT_START = "GET_SPECIFIC_SETTLED_SETTLEMENT_START";
export const GET_SPECIFIC_SETTLED_SETTLEMENT_SUCCESS = "GET_SPECIFIC_SETTLED_SETTLEMENT_SUCCESS";
export const GET_SPECIFIC_SETTLED_SETTLEMENT_FAILURE = "GET_SPECIFIC_SETTLED_SETTLEMENT_FAILURE";

export const GET_ALL_SETTLED_USD_SETTLEMENTS_START = "GET_ALL_SETTLED_USD_SETTLEMENTS_START";
export const GET_ALL_SETTLED_USD_SETTLEMENTS_SUCCESS = "GET_ALL_SETTLED_USD_SETTLEMENTS_SUCCESS";
export const GET_ALL_SETTLED_USD_SETTLEMENTS_FAILURE = "GET_ALL_SETTLED_USD_SETTLEMENTS_FAILURE";

export const GET_ALL_UNSETTLED_NGN_SETTLEMENTS_START = "GET_ALL_UNSETTLED_NGN_SETTLEMENTS_START";
export const GET_ALL_UNSETTLED_NGN_SETTLEMENTS_SUCCESS = "GET_ALL_UNSETTLED_NGN_SETTLEMENTS_SUCCESS";
export const GET_ALL_UNSETTLED_NGN_SETTLEMENTS_FAILURE = "GET_ALL_UNSETTLED_NGN_SETTLEMENTS_FAILURE";

export const GET_ALL_UNSETTLED_USD_SETTLEMENTS_START = "GET_ALL_UNSETTLED_USD_SETTLEMENTS_START";
export const GET_ALL_UNSETTLED_USD_SETTLEMENTS_SUCCESS = "GET_ALL_UNSETTLED_USD_SETTLEMENTS_SUCCESS";
export const GET_ALL_UNSETTLED_USD_SETTLEMENTS_FAILURE = "GET_ALL_UNSETTLED_USD_SETTLEMENTS_FAILURE";

export const GET_SPECIFIC_UNSETTLED_SETTLEMENT_START = "GET_SPECIFIC_UNSETTLED_SETTLEMENT_START";
export const GET_SPECIFIC_UNSETTLED_SETTLEMENT_SUCCESS = "GET_SPECIFIC_UNSETTLED_SETTLEMENT_SUCCESS";
export const GET_SPECIFIC_UNSETTLED_SETTLEMENT_FAILURE = "GET_SPECIFIC_UNSETTLED_SETTLEMENT_FAILURE";

export const PROCESS_UNSETTLED_SETTLEMENTS_START = "PROCESS_UNSETTLED_SETTLEMENTS_START";
export const PROCESS_UNSETTLED_SETTLEMENTS_SUCCESS = "PROCESS_UNSETTLED_SETTLEMENTS_SUCCESS";
export const PROCESS_UNSETTLED_SETTLEMENTS_FAILURE = "PROCESS_UNSETTLED_SETTLEMENTS_FAILURE";

export const GET_ALL_NOTIFICATION_CATEGORIES_START = "GET_ALL_NOTIFICATION_CATEGORIES_START";
export const GET_ALL_NOTIFICATION_CATEGORIES_SUCCESS = "GET_ALL_NOTIFICATION_CATEGORIES_SUCCESS";
export const GET_ALL_NOTIFICATION_CATEGORIES_FAILURE = "GET_ALL_NOTIFICATION_CATEGORIES_FAILURE";

export const GET_ALL_SENT_NOTIFICATIONS_START = "GET_ALL_SENT_NOTIFICATIONS_START";
export const GET_ALL_SENT_NOTIFICATIONS_SUCCESS = "GET_ALL_SENT_NOTIFICATIONS_SUCCESS";
export const GET_ALL_SENT_NOTIFICATIONS_FAILURE = "GET_ALL_SENT_NOTIFICATIONS_FAILURE";

export const GET_SPECIFIC_SENT_NOTIFICATION_START = "GET_SPECIFIC_SENT_NOTIFICATION_START";
export const GET_SPECIFIC_SENT_NOTIFICATION_SUCCESS = "GET_SPECIFIC_SENT_NOTIFICATION_SUCCESS";
export const GET_SPECIFIC_SENT_NOTIFICATION_FAILURE = "GET_SPECIFIC_SENT_NOTIFICATION_FAILURE";

export const SENDING_NOTIFICATION_START = "SENDING_NOTIFICATION_START";
export const SENDING_NOTIFICATION_SUCCESS = "SENDING_NOTIFICATION_SUCCESS";
export const SENDING_NOTIFICATION_FAILURE = "SENDING_NOTIFICATION_FAILURE";

export const SENDING_MULTIPLE_NOTIFICATIONS_START = "SENDING_MULTIPLE_NOTIFICATIONS_START";
export const SENDING_MULTIPLE_NOTIFICATIONS_SUCCESS = "SENDING_MULTIPLE_NOTIFICATIONS_SUCCESS";
export const SENDING_MULTIPLE_NOTIFICATIONS_FAILURE = "SENDING_MULTIPLE_NOTIFICATIONS_FAILURE";

export const GET_ALL_DRAFT_NOTIFICATIONS_START = "GET_ALL_DRAFT_NOTIFICATIONS_START";
export const GET_ALL_DRAFT_NOTIFICATIONS_SUCCESS = "GET_ALL_DRAFT_NOTIFICATIONS_SUCCESS";
export const GET_ALL_DRAFT_NOTIFICATIONS_FAILURE = "GET_ALL_DRAFT_NOTIFICATIONS_FAILURE";

export const GET_SPECIFIC_DRAFT_NOTIFICATION_START = "GET_SPECIFIC_DRAFT_NOTIFICATION_START";
export const GET_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS = "GET_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS";
export const GET_SPECIFIC_DRAFT_NOTIFICATION_FAILURE = "GET_SPECIFIC_DRAFT_NOTIFICATION_FAILURE";

export const EDIT_SPECIFIC_DRAFT_NOTIFICATION_START = "EDIT_SPECIFIC_DRAFT_NOTIFICATION_START";
export const EDIT_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS = "EDIT_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS";
export const EDIT_SPECIFIC_DRAFT_NOTIFICATION_FAILURE = "EDIT_SPECIFIC_DRAFT_NOTIFICATION_FAILURE";

export const DELETE_SPECIFIC_DRAFT_NOTIFICATION_START = "DELETE_SPECIFIC_DRAFT_NOTIFICATION_START";
export const DELETE_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS = "DELETE_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS";
export const DELETE_SPECIFIC_DRAFT_NOTIFICATION_FAILURE = "DELETE_SPECIFIC_DRAFT_NOTIFICATION_FAILURE";

export const CREATE_DRAFT_NOTIFICATION_START = "CREATE_DRAFT_NOTIFICATION_START";
export const CREATE_DRAFT_NOTIFICATION_SUCCESS = "CREATE_DRAFT_NOTIFICATION_SUCCESS";
export const CREATE_DRAFT_NOTIFICATION_FAILURE = "CREATE_DRAFT_NOTIFICATION_FAILURE";

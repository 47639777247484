import React from "react";
import { RiLoader5Fill } from "react-icons/ri";
import styled from "styled-components";

export const ButtonStyle = styled.button`
  cursor: ${(props) => props.disabled && "not-allowed"};
  background: ${(props) => props.disabled && "#EBEBF2"};

  :hover {
    background: ${(props) => props.disabled && "#EBEBF2"};
  }
`;

function Button({
  title,
  type = "button",
  style,
  className,
  disabled,
  loading,
  onClick = () => { },
}) {
  return (
    <ButtonStyle
      type={type}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={
        "glade-normal-text-three glade-button-blue w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
        className
      }
    >
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </ButtonStyle>
  );
}

Button.Green = ({
  title,
  type = "button",
  style,
  className,
  loading,
  disabled,
  onClick = () => { },
}) => {
  return (
    <ButtonStyle
      type={type}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      loading={loading}
      className={
        "glade-normal-text-three glade-button-green px-4 py-2 text-white capitalize flex justify-center items-center text-center rounded " +
        className
      }
    >
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </ButtonStyle>
  );
};

Button.Danger = ({
  title,
  type = "button",
  style,
  className,
  loading,
  disabled,
  onClick = () => { },
}) => {
  return (
    <ButtonStyle
      type={type}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      loading={loading}
      className={
        "glade-normal-text-three text-white glade-button-red py-2 px-4 flex justify-center items-center text-center rounded " +
        className
      }
    >
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </ButtonStyle>
  );
};

Button.Gray = ({
  title,
  type = "button",
  style,
  className,
  loading,
  disabled,
  onClick = () => { },
}) => {
  return (
    <ButtonStyle
      type={type}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      loading={loading}
      className={
        className +
        " glade-normal-text-three glade-button-gray w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm"
      }
    >
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </ButtonStyle>
  );
};

Button.Dark = ({
  title,
  type = "button",
  style,
  className,
  loading,
  disabled,
  onClick = () => { },
}) => {
  return (
    <ButtonStyle
      type={type}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      loading={loading || undefined}
      className={
        " glade-normal-text-three glade-button-dark w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " + className 
      }
    >
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </ButtonStyle>
  );
};

Button.GladeBlue = ({
  title,
  type = "button",
  style,
  className,
  loading,
  disabled,
  onClick = () => { },
}) => {
  return (
    <ButtonStyle
      type={type}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      loading={loading || undefined}
      className={
        " glade-normal-text-three glade-button-gladeblue w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-md " + className 
      }
    >
      {title ?? "Title"}{" "}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </ButtonStyle>
  );
};

export default Button;

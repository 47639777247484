import React, { useMemo, useState } from "react";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import TabNav from "../../components/navbars/TabNav";
import ReferralFilter from "./ReferralFilter";
import useReferral from "../../hooks/useReferral";
import {
  getAllUserReferral,
  getAllUserReferralForDownload,
} from "../../redux/referrals/referralActions";

export default function AllReferrals() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page || 1
  );


  const { isLoadingReferrals, allReferrals } = useReferral();

  const [parameters, setParameters] = useState({
    search: '',
    from: '',
    to: '',
    paginate: 1
  })

  useMemo(() => {
    dispatch(
      getAllUserReferral(parameters)
    );
    // dispatch(getReferralConfiguration())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  const resetReferenceSearch = () => {
    setParameters({ ...parameters, search: '' })
  };

  const handleChange = (event) => {
    dispatch(getAllUserReferral(event));
  }

  const columns = [
    {
      accessor: "account_name",
      Header: "Account Name",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two capitalize">
          {value}
        </span>
      ),
    },
    {
      accessor: "account_email",
      Header: "Account Email",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two capitalize">
          {value}
        </span>
      ),
    },
    {
      accessor: "referrals",
      Header: "Referrals",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two">{value}</span>
      ),
    },
    {
      accessor: "reward_accumulated",
      Header: "Rewards Accumulated",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two">{value}</span>
      ),
    },
    // {
    //   accessor: "reward_accumulated",
    //   Header: "Rewards Disbursed",
    //   Cell: ({ value, row }) => (
    //       <span className="glade-normal-text-two">
    //         {value}
    //       </span>
    //   ),
    // },
  ];


  return (
    <div className="p-3">
      <div className="flex justify-between items-center">
        <TabNav
          tabs={[
            { title: "Referrals", path: "/dashboard/referrals" },
            { title: "Referral Settings", path: "/dashboard/referrals/setup" },
          ]}
        />
        <ReferralFilter parameters={parameters} setParameters={setParameters} />
      </div>
      <CardTableHeader
        onChange={(e) => {
          const value = e.target.value;
          if (value !== '') {
            setParameters({ ...parameters, search: value.trim() })
            handleChange(parameters);
          } else {
            resetReferenceSearch();
          }
        }}
        filterButton={false}
        disabled={isLoadingReferrals}
        title={`Users ${!isLoadingReferrals && allReferrals?.data?.total
            ? "- " + allReferrals?.data?.total
            : ""
          }`}
        searchBarPlaceholder={"Search Account Email"}
        fileName="Referrals"
        downloadAsCSVDark
        CSVCallback={(handleDownload) =>
          dispatch(
            getAllUserReferralForDownload(
              parameters,
              (data) =>
                handleDownload({
                  data,
                  fileName: "User Referral",
                  format: (value, i) => ({
                    "S/N": `${i + 1}`,
                    "Account Name": value?.account_name,
                    "Account Email": value?.account_email,
                    "Referrals": value?.referrals,
                    "Rewards Accumulated": value?.reward_accumulated,
                  }),
                })
            )
          )
        }
      />
      <PaginatedTable
        columns={columns}
        data={
          typeof allReferrals?.data?.data === "object" &&
            !Array.isArray(allReferrals?.data?.data)
            ? allReferrals?.data?.data
            : allReferrals?.data?.data
        }
        empty_message="No Referrals"
        empty_sub_message=""
        current_page={currentPage}
        loading={isLoadingReferrals}
        setCurrentPage={setCurrentPage}
        totalPages={allReferrals?.data?.data?.length}
      />
    </div>
  );
}

import { useSelector } from "react-redux";

const useSettlements = () => {
	const settlements = useSelector((state) => state.settlements);

	const {
		isLoadingAllSettledNGNSettlements,
		isLoadingAllSettledUSDSettlements,
		isLoadingSpecificSettledSettlement,
		isLoadingAllUnsettledNGNSettlements,
		isLoadingAllUnsettledUSDSettlements,
		isLoadingSpecificUnsettledSettlement,
		isProcessingUnsettledSettlement,
		allSettledNGNSettlements,
		allUnsettledNGNSettlements,
		allSettledUSDSettlements,
		allUnsettledUSDSettlements,
		specificSettledSettlement,
		specificUnsettledSettlement,
	} = settlements;

	return {
		isLoadingAllSettledNGNSettlements,
		isLoadingAllSettledUSDSettlements,
		isLoadingSpecificSettledSettlement,
		isLoadingAllUnsettledNGNSettlements,
		isLoadingAllUnsettledUSDSettlements,
		isLoadingSpecificUnsettledSettlement,
		isProcessingUnsettledSettlement,
		allSettledNGNSettlements,
		allUnsettledNGNSettlements,
		allSettledUSDSettlements,
		allUnsettledUSDSettlements,
		specificSettledSettlement,
		specificUnsettledSettlement,
	};
};

export default useSettlements;

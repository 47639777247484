import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as dfn from "date-fns";
import { useParams } from "react-router";
import lodash from "lodash";

import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import {
	revertAccountMerchantFee,
	updateAccountMerchantFee,
} from "../../redux/accounts/accountsActions";
import AddConditionModal from "./AddConditionModal";
import { DeleteBin } from "../../assets/icons/Icons";
import MemoizedLoadingModal from "../../components/modals/LoadingModal";
import { useLocation } from "react-router-dom";

const EditPersonalFeeModal = ({
	modalStates,
	setModalStates,
	showEditPersonalFeeModal,
	selectedFee,
	setSelectedFee,
}) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const userType = useLocation().pathname.split('/')[3];

	const {
		handleSubmit,
		register,
		reset,
		unregister,
		setValue,
		formState: { errors },
	} = useForm();

	const {
		isUpdatingAccountMerchantFees,
		isRevertingAccountMerchantFees,
	} = useAccounts();

	const existing_fees_condtions = selectedFee?.conditions
		? JSON.parse(selectedFee?.conditions)
		: [];

	const [conditions, setConditions] = useState(existing_fees_condtions);
	const [newConditions, setNewConditions] = useState([]);

	useMemo(
		() => {
			setConditions(existing_fees_condtions);
			existing_fees_condtions?.map((condition) =>
				setValue(`condition-${condition.limit}-limit`, condition.limit)
			);
			// console.log(existing_fees_condtions);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedFee?.conditions]
	);

	const [showAddConditionModal, setShowAddConditionModal] = useState(false);

	const onUpdatePersonalFee = (data) => {
		const conditional =
			conditions.length > 0 || newConditions.length > 0 ? 1 : 0;

		let dataArray = Object.entries(data);

		const groupInput = (groupKey, inputArray = []) => {
			const groupArray = [];
			const _inputs = inputArray?.filter(([key]) => key.includes(groupKey));

			for (let index = 0; index < _inputs.length; index++) {
				const input = _inputs[index];
				let id = input[0].split("-")?.[1];
				let key_value_object = {};

				for (let i = 0; i < _inputs.length; i++) {
					const input = _inputs[i];

					if (input[0].includes(id)) {
						const key = input[0].split("-").pop();
						if (key === "limit") {
							key_value_object = {
								...key_value_object,
								[key]: input[1].value
									? input[1].value.toString()
									: input[1].toString(),
							};
						} else {
							key_value_object = {
								...key_value_object,
								[key]: input[1].value ? input[1].value : input[1],
							};
						}
					}
				}
				groupArray.push(key_value_object);
			}
			const jsonObject = groupArray.map(JSON.stringify);

			const uniqueSet = new Set(jsonObject);
			const uniqueArray = Array.from(uniqueSet).map(JSON.parse);

			const cleaned_conditions = uniqueArray.filter(
				(condition) =>
					condition.limit && condition.fee_fixed && condition.fee_percentage
			);

			return cleaned_conditions;
		};

		let _conditions = groupInput("condition", dataArray);

		const payload = {
			fee_id: selectedFee?.fee_id,
			fee_cap: data?.capped,
			fee_percentage: data.percent ?? null,
			fee_fixed: data?.amount ?? null,
			status: selectedFee?.status,
			conditional,
			conditions: _conditions,
		};

		dispatch(updateAccountMerchantFee(id, userType, payload));
	};

	const revertFeeToDefault = () => {
		dispatch(revertAccountMerchantFee(id, userType, selectedFee?.fee_id));
	};

	const addCondition = (condition) => {
		setValue(`condition-${condition.limit}-limit`, condition.limit);
		setNewConditions([...newConditions, condition]);
	};

	const deleteCondition = (condition, id) => {
		const newArray = newConditions.filter(
			(_c) => !lodash.isEqual(_c, condition)
		);
		unregister(`${id}-limit`);
		unregister(`${id}-fee_fixed`);
		unregister(`${id}-fee_percentage`);
		setNewConditions(newArray);
	};

	return (
		<BasicModal
			visibility={showEditPersonalFeeModal}
			onClose={() => {
				reset();
				setModalStates({ ...modalStates, showEditPersonalFeeModal: false });
				setSelectedFee({});
				setNewConditions([]);
			}}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditPersonalFeeModal: true,
				})
			}
			primaryButton=""
			modalTitle={`Edit ${selectedFee?.display_name ?? selectedFee?.fee_code?.replace("_", " ")
				} fee`}
			secondaryButton=""
			modelContentSection={
				<>
					<form onSubmit={handleSubmit(onUpdatePersonalFee)} className="">
						{conditions?.length ? (
							<>
								{/* Default */}
								<div className="mb-4">
									<label
										htmlFor="percent"
										className="block glade-small-text-two capitalize"
									>
										Default
									</label>
									<div className="flex items-center mt-1">
										{/* Percent */}
										{selectedFee?.fee_fixed && (
											<div className="w-1/2 pr-2">
												<div className="relative">
													<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
														<span className="glade-black sm:text-sm py-2">
															%
														</span>
													</div>
													<input
														type="number"
														id="percent"
														name="percent"
														{...register("percent", {
															required: "Enter a valid percent",
															min: {
																value: 0.0,
																message: "Enter a valid percent",
															},
															max: {
																value: 100,
																message: "Enter a valid percent",
															},
														})}
														step="0.1"
														defaultValue={selectedFee?.fee_percentage ?? 0}
														placeholder="Enter a percentage"
														className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
													/>
												</div>
											</div>
										)}
										{/* Fixed amount */}
										{selectedFee?.fee_fixed && (
											<div className="w-1/2 pl-2">
												<div className="relative">
													<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
														<span className="glade-black sm:text-sm py-2">
															NGN
														</span>
													</div>
													<input
														type="number"
														id="amount"
														name="amount"
														{...register("amount", {
															required: "Enter a valid amount",
															min: {
																value: 0,
																message: "Enter a valid amount",
															},
														})}
														defaultValue={
															Number(selectedFee?.fee_fixed).toFixed(2) ?? 0
														}
														placeholder="Enter a amount"
														className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
													/>
												</div>
											</div>
										)}
									</div>
									<div className="flex items-center">
										<div className="w-1/2 pr-2">
											<FormError errors={errors} name="percent" />
										</div>
										<div className="w-1/2 pl-2">
											<FormError errors={errors} name="amount" />
										</div>
									</div>
								</div>

								{/* Conditions */}
								{conditions?.map((condition, idx) => (
									<div className="mb-4" key={idx + condition?.limit}>
										<label className="glade-small-text-two capitalize flex items-center">
											Limit Amount:{" "}
											<h6 className="glade-small-text-two font-bold ml-1">
												NGN{Number(condition?.limit ?? 0).toLocaleString(undefined, {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</h6>
										</label>
										<div className="flex items-center mt-1">
											{/* Percent */}
											{condition?.limit && (
												<div className="w-1/2 pr-2">
													<div className="relative">
														<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
															<span className="glade-black sm:text-sm py-2">
																%
															</span>
														</div>
														<input
															type="number"
															id={`condition-${condition.limit}-fee_percentage`}
															name={`condition-${condition.limit}-fee_percentage`}
															{...register(
																`condition-${condition.limit}-fee_percentage`,
																{
																	required: "Enter a valid percent",
																	min: {
																		value: 0.0,
																		message: "Enter a valid percent",
																	},
																	max: {
																		value: 100,
																		message: "Enter a valid percent",
																	},
																}
															)}
															step="0.1"
															defaultValue={
																Number(condition?.fee_percentage).toFixed(2) ??
																0
															}
															placeholder="Enter a percentage"
															className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
														/>
													</div>
												</div>
											)}
											{/* Fixed amount */}
											{condition?.fee_fixed && (
												<div className="w-1/2 pl-2">
													<div className="relative">
														<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
															<span className="glade-black sm:text-sm py-2">
																NGN
															</span>
														</div>
														<input
															type="number"
															id={`condition-${condition.limit}-fee_fixed`}
															name={`condition-${condition.limit}-fee_fixed`}
															{...register(
																`condition-${condition.limit}-fee_fixed`,
																{
																	required: "Enter a valid amount",
																	min: {
																		value: 0,
																		message: "Enter a valid amount",
																	},
																}
															)}
															defaultValue={
																Number(condition?.fee_fixed).toFixed(2) ?? 0
															}
															placeholder="Enter a amount"
															className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
														/>
													</div>
												</div>
											)}
										</div>
										<div className="flex items-center">
											<div className="w-1/2 pr-2">
												<FormError
													errors={errors}
													name={`condition-${condition.limit}-fee_percentage`}
												/>
											</div>
											<div className="w-1/2 pl-2">
												<FormError
													errors={errors}
													name={`condition-${condition.limit}-fee_fixed`}
												/>
											</div>
										</div>
									</div>
								))}
							</>
						) : (
							<div className="mb-4">
								<label
									htmlFor="percent"
									className="block glade-small-text-two capitalize"
								>
									{selectedFee?.display_name ??
										`${selectedFee?.fee_code?.replace("_", " ")} fee`}
								</label>
								<div className="flex items-center mt-2">
									{/* Percent */}
									{selectedFee?.fee_fixed && (
										<div className="w-1/2 pr-2">
											<div className="relative">
												<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
													<span className="glade-black sm:text-sm py-2">%</span>
												</div>
												<input
													type="number"
													id="percent"
													name="percent"
													{...register("percent", {
														required: "Enter a valid percent",
														min: {
															value: 0.0,
															message: "Enter a valid percent",
														},
														max: {
															value: 100,
															message: "Enter a valid percent",
														},
													})}
													step="0.1"
													defaultValue={selectedFee?.fee_percentage ?? 0}
													placeholder="Enter a percentage"
													className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
												/>
											</div>
										</div>
									)}
									{/* Fixed amount */}
									{selectedFee?.fee_fixed && (
										<div className="w-1/2 pl-2">
											<div className="relative">
												<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
													<span className="glade-black sm:text-sm py-2">
														NGN
													</span>
												</div>
												<input
													type="number"
													id="amount"
													name="amount"
													{...register("amount", {
														required: "Enter a valid amount",
														min: {
															value: 0,
															message: "Enter a valid amount",
														},
													})}
													defaultValue={
														Number(selectedFee?.fee_fixed).toFixed(2) ?? 0
													}
													placeholder="Enter a amount"
													className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
												/>
											</div>
										</div>
									)}
								</div>
								<div className="flex items-center">
									<div className="w-1/2 pr-2">
										<FormError errors={errors} name="percent" />
									</div>
									<div className="w-1/2 pl-2">
										<FormError errors={errors} name="amount" />
									</div>
								</div>
							</div>
						)}

						{/* CAPPED FEE */}
						<div>
							<div className="mt-4">
								<label
									htmlFor="capped"
									className="block glade-small-text-two capitalize"
								>
									Capped Fee
								</label>
							</div>
							<div className="relative">
								<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
									<span className="glade-black sm:text-sm py-2">
										NGN
									</span>
								</div>
								<input
									type="number"
									id="capped"
									name="capped"
									{...register("capped", {
										required: "Enter a valid amount",
										min: {
											value: 0,
											message: "Enter a valid amount",
										},
									})}
									defaultValue={
										Number(selectedFee?.fee_cap).toFixed(2)
									}
									placeholder="Enter an amount"
									className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
								/>
							</div>
							<div>
								<div>
									<FormError errors={errors} name="capped" />
								</div>
							</div>
						</div>

						<div className="flex items-center justify-between mt-2 pb-8 border-b border-solid border-gray-200">
							<h6 className="glade-small-text-one opacity-50 ml-1">
								{selectedFee?.updated_at && (
									<>
										Last changed on{" "}
										{dfn.format(
											new Date(selectedFee?.updated_at),
											"MMM d, yyyy"
										)}
									</>
								)}
							</h6>
							<h6
								className="glade-small-text-two glade-blue-normal cursor-pointer"
								tabIndex={0}
								onClick={revertFeeToDefault}
							>
								Revert to default
							</h6>
						</div>

						{/* New Conditions */}
						{newConditions?.length > 0 && (
							<div className="mt-8 space-y-4">
								{newConditions?.map((condition, idx) => (
									<div className="" key={idx + condition?.limit}>
										<label className="glade-small-text-two capitalize flex items-center">
											Limit Amount:{" "}
											<h6 className="glade-small-text-two font-bold ml-1">
												NGN
												{Number(condition?.limit ?? 0).toLocaleString(undefined, {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</h6>
										</label>
										<div className="flex items-center mt-2">
											<div className="flex items-center">
												{/* Percent */}
												{condition?.fee_percentage && (
													<div className="w-1/2 pr-2">
														<div className="relative">
															<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
																<span className="glade-black sm:text-sm py-2">
																	%
																</span>
															</div>
															<input
																type="number"
																id={`condition-${condition.limit}-fee_percentage`}
																name={`condition-${condition.limit}-fee_percentage`}
																{...register(
																	`condition-${condition.limit}-fee_percentage`,
																	{
																		required: "Enter a valid percent",
																		min: {
																			value: 0.0,
																			message: "Enter a valid percent",
																		},
																		max: {
																			value: 100,
																			message: "Enter a valid percent",
																		},
																	}
																)}
																step="0.1"
																defaultValue={
																	Number(condition?.fee_percentage).toFixed(
																		2
																	) ?? 0
																}
																placeholder="Enter a percentage"
																className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
															/>
														</div>
													</div>
												)}
												{/* Fixed amount */}
												{condition?.fee_fixed && (
													<div className="w-1/2 pl-2">
														<div className="relative">
															<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
																<span className="glade-black sm:text-sm py-2">
																	NGN
																</span>
															</div>
															<input
																type="number"
																id={`condition-${condition.limit}-fee_fixed`}
																name={`condition-${condition.limit}-fee_fixed`}
																{...register(
																	`condition-${condition.limit}-fee_fixed`,
																	{
																		required: "Enter a valid amount",
																		min: {
																			value: 0,
																			message: "Enter a valid amount",
																		},
																	}
																)}
																defaultValue={
																	Number(condition?.fee_fixed).toFixed(2) ?? 0
																}
																placeholder="Enter a amount"
																className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
															/>
														</div>
													</div>
												)}
											</div>
											<DeleteBin
												className="ml-2.5 flex-shrink-0 cursor-pointer"
												title="Remove condition"
												onClick={() =>
													deleteCondition(
														condition,
														`condition-${condition.limit}`
													)
												}
											/>
										</div>
										<div className="flex items-center">
											<div className="w-1/2 pr-2">
												<FormError
													errors={errors}
													name={`condition-${condition.limit}-fee_percentage`}
												/>
											</div>
											<div className="w-1/2 pl-2">
												<FormError
													errors={errors}
													name={`condition-${condition.limit}-fee_fixed`}
												/>
											</div>
										</div>
									</div>
								))}
							</div>
						)}

						<div className="flex items-center justify-center mt-8">
							<h6
								className="glade-subtitle-two glade-blue-normal font-inter cursor-pointer"
								tabIndex={0}
								onClick={() => setShowAddConditionModal(true)}
							>
								+ Add Custom Fee
							</h6>
						</div>

						<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
							<button
								type="button"
								onClick={() => {
									reset();
									setModalStates({
										...modalStates,
										showEditPersonalFeeModal: false,
									});
									setSelectedFee({});
									setNewConditions([]);
								}}
								className="glade-normal-text-two glade-black text-center"
							>
								Cancel
							</button>
							<div>
								<Button.Dark
									type="submit"
									title="Update"
									className="block flex-grow-0"
									loading={isUpdatingAccountMerchantFees}
								/>
							</div>
						</div>
					</form>
					{/* Add condition modal */}
					<AddConditionModal
						setShowAddConditionModal={setShowAddConditionModal}
						showAddConditionModal={showAddConditionModal}
						addCondition={addCondition}
					/>

					<MemoizedLoadingModal
						primaryButton=""
						modalTitleSection=""
						secondaryButton=""
						visibility={
							isUpdatingAccountMerchantFees ||
							isRevertingAccountMerchantFees
						}
					/>
				</>
			}
		/>
	);
};

export default EditPersonalFeeModal;

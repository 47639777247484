import { destroyLastLogin, updatePersistedLastLogin } from "../../helpers/session";
import { LOGOUT_USER, REFRESHED_TOKEN } from "./types";
const _sessionName = process.env.REACT_APP_SLUG + "_session";

export const logoutUserLocallyAction = () => {
  return (dispatch) => {
    destroyLastLogin();
    localStorage.removeItem(_sessionName);
    dispatch({ type: LOGOUT_USER, payLoad: {} });
  };
};

export const refreshUserTokenLocallyAction = (payLoad) => {
  return (dispatch) => {
    updatePersistedLastLogin(payLoad);
    dispatch({ type: REFRESHED_TOKEN, payLoad: payLoad });
  };
};

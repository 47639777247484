import { useSelector } from "react-redux";

const useMultiCurrency = () => {
  const { multiCurrency } = useSelector(state => state);
  const {
    isLoadingMultiCurrencyCharges,
    isUpdatingMultiCurrencyCharges,
    multiCurrencyCharges
  } = multiCurrency;

  return {
    isLoadingMultiCurrencyCharges,
    isUpdatingMultiCurrencyCharges,
    multiCurrencyCharges
  };
};

export default useMultiCurrency;
import { useSelector } from "react-redux";

const useNotifications = () => {
  const notifications = useSelector((state) => state.notifications);

  const {
    isLoadingAllNotificationCategories,
    isLoadingAllSentNotifications,
    isLoadingAllDraftNotifications,
    isLoadingSpecificSentNotification,
    isLoadingSpecificDraftNotification,
    isSendingNotification,
    isSendingMultipleNotifications,
    isCreatingDraftNotification,
    isEditingDraftNotification,
    isDeletingDraftNotification,
    allNotificationCategories,
    allSentNotifications,
    allDraftNotifications,
    specificSentNotification,
    specificDraftNotification,
  } = notifications;

  const findNotificationCategory = (category_id) => {
    return allNotificationCategories.find((category) => category.id === category_id)
      ?.name;
  };

  return {
    isLoadingAllNotificationCategories,
    isLoadingAllSentNotifications,
    isLoadingAllDraftNotifications,
    isLoadingSpecificSentNotification,
    isLoadingSpecificDraftNotification,
    isSendingNotification,
    isSendingMultipleNotifications,
    isCreatingDraftNotification,
    isEditingDraftNotification,
    isDeletingDraftNotification,
    allNotificationCategories,
    allSentNotifications,
    allDraftNotifications,
    specificSentNotification,
    specificDraftNotification,
    findNotificationCategory,
  };
};

export default useNotifications;

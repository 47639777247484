import { useSelector } from "react-redux";

const useTransactions = () => {
	const transactions = useSelector((state) => state.transactions);

	const {
		isLoadingAllTransactions,
		isLoadingAllTransferTransactions,
		isLoadingAllBillTransactions,
		isLoadingAllPayrollTransactions,
		isLoadingAllUserTransactions,
		isLoadingSpecificTransaction,
		isLoadingSpecificTransferTransactions,
		isLoadingSpecificBillTransactions,
		isLoadingSpecificPayrollTransaction,
		isLoadingSpecificUserTransaction,
		isLoadingAdvanceDetails,
		isVerifyingSpecificTransferTransactions,
		isVerifyingSpecificBillTransactions,
		isVerifyingSpecificPayrollTransactions,
		isRevertingSpecificTransferTransactions,
		isRevertingSpecificBillTransactions,
		isRevertingSpecificPayrollTransactions,
		isLoadingCrossBorderManualTransfer,
		isUpdatingSingleManualTransfer,
		isLoadingPayrollSalaryAdvance,
		allTransactions,
		allTransferTransactions,
		allBillTransactions,
		allPayrollTransactions,
		payrollSalaryAdvance,
		allUserTransactions,
		specificTransaction,
		specificTransferTransactions,
		specificBillTransactions,
		specificPayrollTransactions,
		specificUserTransactions,
		specificAdvanceDetails,
		isUpdatingSpecificAdvanceDetails,
		crossBorderManualTransfer,
		internationalTransfersTransactions
	} = transactions;

	return {
		isLoadingAllTransactions,
		isLoadingAllTransferTransactions,
		isLoadingAllBillTransactions,
		isLoadingAllPayrollTransactions,
		isLoadingAllUserTransactions,
		isLoadingSpecificTransaction,
		isLoadingSpecificTransferTransactions,
		isLoadingSpecificBillTransactions,
		isLoadingSpecificPayrollTransaction,
		isLoadingSpecificUserTransaction,
		isLoadingAdvanceDetails,
		isVerifyingSpecificTransferTransactions,
		isVerifyingSpecificBillTransactions,
		isVerifyingSpecificPayrollTransactions,
		isRevertingSpecificTransferTransactions,
		isRevertingSpecificBillTransactions,
		isRevertingSpecificPayrollTransactions,
		isLoadingCrossBorderManualTransfer,
		isUpdatingSingleManualTransfer,
		isLoadingPayrollSalaryAdvance,
		allTransactions,
		allTransferTransactions,
		allBillTransactions,
		allPayrollTransactions,
		payrollSalaryAdvance,
		allUserTransactions,
		specificTransaction,
		specificTransferTransactions,
		specificBillTransactions,
		specificPayrollTransactions,
		specificUserTransactions,
		specificAdvanceDetails,
		isUpdatingSpecificAdvanceDetails,
		crossBorderManualTransfer,
		internationalTransfersTransactions
	};
};

export default useTransactions;

import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastError, toastSuccess } from "../../helpers/utils.toast";

import {
	GET_ALL_BUSINESS_COMPLIANCE_FAILURE,
	GET_ALL_BUSINESS_COMPLIANCE_START,
	GET_ALL_BUSINESS_COMPLIANCE_SUCCESS,
	GET_ALL_PERSONAL_COMPLIANCE_FAILURE,
	GET_ALL_PERSONAL_COMPLIANCE_START,
	GET_ALL_PERSONAL_COMPLIANCE_SUCCESS,
	GET_BUSINESS_DIRECTORS_FAILURE,
	GET_BUSINESS_DIRECTORS_START,
	GET_BUSINESS_DIRECTORS_SUCCESS,
	GET_BUSINESS_PRIMARY_OWNER_FAILURE,
	GET_BUSINESS_PRIMARY_OWNER_START,
	GET_BUSINESS_PRIMARY_OWNER_SUCCESS,
	GET_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE,
	GET_SPECIFIC_BUSINESS_COMPLIANCE_START,
	GET_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS,
	GET_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE,
	GET_SPECIFIC_PERSONAL_COMPLIANCE_START,
	GET_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS,
	UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE,
	UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_START,
	UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS,
	UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE,
	UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_START,
	UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS,
} from "./types";

export const getAllBusinessCompliance = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_BUSINESS_COMPLIANCE_START });
		Axios.get(`compliance/business`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_BUSINESS_COMPLIANCE_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_BUSINESS_COMPLIANCE_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getAllPersonalCompliance = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_PERSONAL_COMPLIANCE_START });
		Axios.get(`compliance/personal`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_PERSONAL_COMPLIANCE_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_PERSONAL_COMPLIANCE_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getSpecificPersonalCompliance = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_PERSONAL_COMPLIANCE_START });
		Axios.get(`compliance/personal/${id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getSpecificBusinessCompliance = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_BUSINESS_COMPLIANCE_START });
		Axios.get(`compliance/business/${id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const updateSpecificBusinessCompliance = (id, data) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_START });
		Axios.put(`compliance/business/update/${id}`, data)
			.then((res) => {
				dispatch({
					type: UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS,
					payLoad: res.data.data,
				});
				if (res?.data?.message?.includes("approved")) {
					toastSuccess(res.data.message);
				} else {
					toastError(res.data.message);
				}
				window.history.back();
				getSpecificBusinessCompliance(id);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateSpecificPersonalCompliance = (id, data) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_START });
		Axios.put(`compliance/personal/update/${id}`, data)
			.then((res) => {
				dispatch({
					type: UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS,
					payLoad: res.data.data,
				});
				if (res?.data?.message?.includes("approved")) {
					toastSuccess(res.data.message);
				} else {
					toastError(res.data.message);
				}
				window.history.back();
				getSpecificPersonalCompliance(id);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const getBusinessDirectors = (id) => {
	return dispatch => {
		dispatch({ type: GET_BUSINESS_DIRECTORS_START })
		Axios.get(`compliance/business/${id}/directors`)
			.then(res => {
				dispatch({ type: GET_BUSINESS_DIRECTORS_SUCCESS, payLoad: res.data.data })
			})
			.catch(error => {
				dispatch({ type: GET_BUSINESS_DIRECTORS_FAILURE })
				handler(error, true)
			})
	}
}

export const getBusinessPrimaryOwner = (id) => {
	return dispatch => {
		dispatch({ type: GET_BUSINESS_PRIMARY_OWNER_START })
		Axios.get(`compliance/business/${id}/primary-owner`)
			.then(res => {
				dispatch({ type: GET_BUSINESS_PRIMARY_OWNER_SUCCESS, payLoad: res.data.data })
			})
			.catch(error => {
				dispatch({ type: GET_BUSINESS_PRIMARY_OWNER_FAILURE })
				handler(error, true)
			})
	}
}
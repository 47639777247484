import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import {
  GET_ADMIN_USERS_FAILURE,
  GET_ADMIN_USERS_START,
  GET_ADMIN_USERS_SUCCESS,
} from "./types";

export const getAllAdminUsers = params => {
  return dispatch => {
    dispatch({ type: GET_ADMIN_USERS_START });
    Axios.get(`settings/users?paginate=1`, params)
      .then(res => {
        dispatch({
          type: GET_ADMIN_USERS_SUCCESS,
          payLoad: res.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_ADMIN_USERS_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
// import NavbarAuth from "../components/navbars/NavbarAuth";
// import FooterAuth from "../components/footers/FooterAuth";

// pages
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import RestPassword from "../pages/auth/RestPassword";
import Logout from "../pages/auth/Logout";

export default function AuthLayout() {
  return (
    <React.Fragment>
      <main className="relative w-full h-full min-h-screen">
        {/* Navbar */}
        {/* <NavbarAuth /> */}

        {/* Body */}
        <section>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/forgot-password" exact component={ForgotPassword} />
            <Route path="/auth/reset-password" exact component={RestPassword} />
            <Route path="/auth/logout" exact component={Logout} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>

          {/* Footer */}
          {/* <FooterAuth /> */}
        </section>
      </main>
    </React.Fragment>
  );
}

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import { updateBusinessSettlementAccount } from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";
import { PopoverMain } from "../../components/popovers/Popover";

const AccountsHolder = styled.div`
	max-height: 300px;
`;

const EditBusinessSettlementAccountModal = ({
	modalStates,
	setModalStates,
	showEditBusinessSettlementAccountModal,
}) => {
	const dispatch = useDispatch();
	const { id, user_uuid } = useParams();

	const { isUpdatingBusinessSettlementAccount, specificBusinessAccounts } =
		useAccounts();

	const onUpdateBusinessSettlementAccount = () => {
		dispatch(
			updateBusinessSettlementAccount({
				user_uuid,
				business_uuid: id,
				account_id: selectedAccount.id,
				callback: () => {
					setModalStates({
						...modalStates,
						showEditBusinessSettlementAccountModal: false,
					});
				},
			})
		);
	};

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			account: null,
		},
	});

	const selectedAccount = watch("account");

	useEffect(() => {
		register("account", {
			required: "Select an account",
		});
	}, [register]);

	return (
		<BasicModal
			visibility={showEditBusinessSettlementAccountModal}
			onClose={() => {
				reset();
				// setSelectedAccount(null);
				setModalStates({
					...modalStates,
					showEditBusinessSettlementAccountModal: false,
				});
			}}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditBusinessSettlementAccountModal: true,
				})
			}
			primaryButton=""
			modalTitle="Edit Business Settlement Account"
			secondaryButton=""
			modelContentSection={
				<form
					onSubmit={handleSubmit(onUpdateBusinessSettlementAccount)}
					className=""
				>
					<div className="">
						<label
							htmlFor="account"
							className="block glade-small-text-two mb-2"
						>
							Select Settlement Account
						</label>
						<PopoverMain
							button={
								<div
									type="button"
									className={
										"p-4 py-2 glade-bg-black-100 rounded-md cursor-pointer"
									}
								>
									{selectedAccount ? (
										<>
											<h5 className="glade-normal-text-one glade-black">
												{selectedAccount?.account_name}
											</h5>
											<h6 className="glade-normal-text-two glade-black">
												{selectedAccount?.bank_name}
											</h6>
											<h6 className="glade-normal-text-two glade-black">
												{selectedAccount?.account_number}
											</h6>
										</>
									) : (
										<h5 className="glade-normal-text-two glade-black py-1">
											Select an account
										</h5>
									)}
								</div>
							}
							popoverClassName="right-0 left-0 top-14"
						>
							{({ close }) => (
								<AccountsHolder className="px-2 py-2 border-gray-300 bg-white shadow rounded-md w-full overflow-y-auto">
									{specificBusinessAccounts?.business_details?.business_settlement_accounts?.map(
										(account) => (
											<div
												key={account?.id}
												className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"
												onClick={() => {
													setValue("account", account);
													close();
												}}
											>
												<h5 className="glade-normal-text-three glade-black">
													{account?.account_name}
												</h5>
												<h6 className="glade-normal-text-two glade-black">
													{account?.bank_name}
												</h6>
												<h6 className="glade-normal-text-two glade-black">
													{account?.account_number}
												</h6>
											</div>
										)
									)}
								</AccountsHolder>
							)}
						</PopoverMain>
						<FormError errors={errors} name="account" />
					</div>

					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={() => {
								reset();
								// setSelectedAccount(null);
								setModalStates({
									...modalStates,
									showEditBusinessSettlementAccountModal: false,
								});
							}}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Save"
								className="block flex-grow-0"
								loading={isUpdatingBusinessSettlementAccount}
							/>
						</div>
					</div>
				</form>
			}
		/>
	);
};

export default EditBusinessSettlementAccountModal;

import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { Table } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import PillIndicator from "../../components/indicators/PillIndicator";
import { fixDateForAllBrowsers, statusEnum } from "../../helpers/helper";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import useCompliance from "../../hooks/useCompliance";
import { getAllPersonalCompliance } from "../../redux/compliance/complianceActions";
import TabNav from "../../components/navbars/TabNav";

export default function PersonalCompliance() {
	const dispatch = useDispatch();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const { isLoadingAllPersonalCompliance, allPersonalCompliance } =
		useCompliance();

	const defaultFilterParams = {
		search: "",
		page: currentPage,
		// status: "all",
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, status, search, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		date_from,
		date_to,
		// isReferenceSearch,
		search,
		status,
	]);

	React.useMemo(() => {
		dispatch(
			getAllPersonalCompliance({
				params: {
					...(search
						? { search, paginate: 1, page, per_page: 10 }
						: {
								// currency,
								date_from,
								date_to,
								status,
								paginate: 1,
								page,
								per_page: 10,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		// isReferenceSearch,
		page,
		// per_page,
		search,
		status,
	]);

	React.useMemo(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);
	// Clear query param on render
	// useEffect(() => {
	//   urlSearchParamsHistory.replace({});
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		// setIsReferenceSearch(false);
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	return (
		<div className="p-3">
			<TabNav
				tabs={[
					{ title: "Personal", path: "/dashboard/compliance" },
					{ title: "Business", path: "/dashboard/compliance/business" },
				]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				filterFields={["date"]}
				filterCallback={handleChange}
				disabled={isLoadingAllPersonalCompliance}
				title={`Compliance ${
					!isLoadingAllPersonalCompliance && allPersonalCompliance?.length
						? "- " + allPersonalCompliance?.length
						: ""
				}`}
				defaultFilterParams={defaultFilterParams}
				searchBarPlaceholder={"Account name or Email"}
			/>
			<Table
				columns={columns}
				data={
					typeof allPersonalCompliance === "object" &&
					!Array.isArray(allPersonalCompliance)
						? allPersonalCompliance?.data
						: allPersonalCompliance
				}
				empty_message="No compliance"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllPersonalCompliance}
				setCurrentPage={setCurrentPage}
			/>
		</div>
	);
}

const columns = [
	{
		accessor: "status",
		Header: "Status",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/compliance/personal/detail/${row.original.id}`}>
				<PillIndicator
					className="px-5 py-1 capitalize"
					type={statusEnum[value]}
					title={value}
				>
					{value}
				</PillIndicator>
			</Link>
		),
	},
	{
		accessor: "firstname",
		Header: "Name",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/compliance/personal/detail/${row.original.id}`}>
				<span className="glade-normal-text-two capitalize">
					{value} {row.original.lastname}
				</span>
			</Link>
		),
	},
	{
		accessor: "contact_email",
		Header: "Email",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/compliance/personal/detail/${row.original.id}`}>
				<span className="glade-normal-text-two">{value}</span>
			</Link>
		),
	},
	{
		accessor: "created_at",
		Header: "Date Submitted",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/compliance/personal/detail/${row.original.id}`}>
				<span className="glade-normal-text-two">
					{dfn.format(new Date(fixDateForAllBrowsers(value)), "MMM d, yyyy hh:mm:ss a")}
				</span>
			</Link>
		),
	},
];

import React from "react";
import ButtonWithIconRight from "../buttons/ButtonWithIconRight";
import DownloadAsPDFButton from "../../helpers/DownloadAsPDF";
import { Popover } from "@headlessui/react";
import DownloadAsCSVButton from "../../helpers/DownloadAsCSV";

export default function DownloadDropdown({
  style,
  className,
  pdfTitle = "Glade",
  details,
  pdfHeaders,
  pdfData,
  fileName,
  disabled,
  downloadAs = ["csv", "pdf"],
  getWorksheets,
}) {
  return (
    <Popover className="relative">
      <Popover.Button>
        <ButtonWithIconRight title="Download" />
      </Popover.Button>
      <Popover.Panel className="absolute z-10 right-0">
        <div className="w-40 flex flex-col py-6 px-2 bg-white shadow rounded-sm space-y-2">
          {downloadAs.map((type) => {
            switch (type) {
              case "pdf":
                return (
                  <DownloadAsPDFButton
                    fileName={fileName}
                    details={details}
                    pdfHeaders={pdfHeaders}
                    pdfData={pdfData}
                    disabled={disabled}
                    key={type}
                    // loading
                    // error
                  />
                );
              case "csv":
                return (
                  <DownloadAsCSVButton
                    key={type}
                    fileName={fileName}
                    getWorksheets={getWorksheets}
                    disabled={disabled}
                    style={{
                      backgroundColor: "#006686",
                    }}
                  />
                );
              default:
                return <React.Fragment></React.Fragment>;
            }
          })}
        </div>
      </Popover.Panel>
    </Popover>
  );
}

import { useState, createContext } from "react";

const contextType = {
  fieldUpdated: false,
  setFieldUpdated: () => { }
};

export const SettingsContext = createContext(contextType);

const SettingsContextProvider = ({ children }) => {
  const [fieldUpdated, setFieldUpdated] = useState(true);


  return (
    <SettingsContext.Provider value={{ fieldUpdated, setFieldUpdated }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;


import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { FiCopy } from "react-icons/fi";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import {
	copyTextToClipboard,
	fixDateForAllBrowsers,
	statusEnum,
	truncateRef,
} from "../../helpers/helper";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import useTransactions from "../../hooks/useTransactions";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import {
	getAllUserTransactions,
	getAllUserTransactionsForDownload,
} from "../../redux/transactions/transactionsActions";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";

export default function UserTransactions() {
	const dispatch = useDispatch();
	const location = useLocation();
	const { id } = useParams();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const { isLoadingAllUserTransactions, allUserTransactions } =
		useTransactions();

	const defaultFilterParams = {
		search: "",
		status: qs.parse(location.search)?.status ?? "",
		page: currentPage,
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, status, search, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		date_from,
		date_to,
		// isReferenceSearch,
		search,
		status,
	]);

	React.useMemo(() => {
		dispatch(
			getAllUserTransactions(id, {
				params: {
					...(search
						? { search, paginate: 1, page, per_page: 10 }
						: {
								// currency,
								date_from,
								date_to,
								paginate: 1,
								page,
								per_page: 10,
								status,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		// isReferenceSearch,
		page,
		// per_page,
		search,
		status,
	]);

	React.useMemo(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	return (
		<div className="p-3 flex flex-col">
			<div className="mb-5">
				<BreadCrumbs title={"Accounts"} goBack />
			</div>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				filterFields={["statusWithAll", "date"]}
				filterCallback={handleChange}
				disabled={isLoadingAllUserTransactions}
				fileName="Transactions History"
				downloadAsCSV
				CSVCallback={(handleDownload) =>
					dispatch(
						getAllUserTransactionsForDownload(
							id,
							{
								params: {
									...(search
										? { search, paginate: 0, page, per_page: 10 }
										: {
												// currency,
												date_from,
												date_to,
												paginate: 0,
												page,
												per_page: 10,
												status,
										  }),
								},
							},
							(data) =>
								handleDownload({
									data,
									fileName: "User Transactions History",
									format: (value, i) => ({
										"S/N": `${i + 1}`,
										"Transaction Status": value?.status,
										"Transaction Ref": value?.txn_ref,
										Amount: value?.amount,
										Channel: value?.channel,
										"Transaction Date": value?.transaction_date,
										Details: value?.details,
									}),
								})
						)
					)
				}
				getWorksheets={({ xlsx }) => {
					return {
						data: xlsx.utils.json_to_sheet(
							allUserTransactions?.data?.map((value, i) => ({
								"S/N": `${i + 1}`,
								"Transaction Status": value?.status,
								"Transaction Ref": value?.txn_ref,
								Amount: value?.amount,
								Channel: value?.channel,
								"Transaction Date": value?.transaction_date,
								Details: value?.details,
							}))
						),
					};
				}}
				title={`Transactions ${
					!isLoadingAllUserTransactions && allUserTransactions?.total
						? "- " + allUserTransactions?.total
						: ""
				}`}
				searchBarPlaceholder={"Search"}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof allUserTransactions === "object" &&
					!Array.isArray(allUserTransactions)
						? allUserTransactions?.data
						: allUserTransactions?.data?.reverse()
				}
				empty_message="No transactions"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllUserTransactions}
				setCurrentPage={setCurrentPage}
				totalPages={allUserTransactions?.total}
			/>
		</div>
	);
}

const columns = [
	{
		accessor: "status",
		Header: "",
		style: {
			paddingLeft: 0,
			paddingRight: "10px",
			width: "50px",
		},
		Cell: ({ value }) => (
			<React.Fragment>
				<CircleIndicator
					className="py-1 mx-auto capitalize"
					type={statusEnum[value]}
					style={{
						marginLeft: "auto",
						marginRight: "auto",
						display: "block",
					}}
				/>
			</React.Fragment>
		),
	},
	{
		accessor: "txn_ref",
		Header: "Transaction Reference",
		Cell: ({ value }) => (
			<div className="flex items-center">
				<span className="glade-normal-text-two">{truncateRef(value)}</span>
				<FiCopy
					size={16}
					color="#AFAFAF"
					className="ml-3 cursor-pointer"
					onClick={() => copyTextToClipboard(value)}
				/>
			</div>
		),
	},
	{
		accessor: "amount",
		Header: "Amount",
		Cell: ({ value, row }) => (
			// <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
			<span className="glade-normal-text-two">
				{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
			</span>
			// </Link>
		),
	},
	{
		accessor: "type",
		Header: "Transaction Type",
		Cell: ({ value, row }) => (
			// <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
			<span className="glade-normal-text-two capitalize">{value}</span>
			// </Link>
		),
	},
	{
		accessor: "channel",
		Header: "Channel",
		Cell: ({ value, row }) => (
			// <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
			<span className="glade-normal-text-two capitalize">{value}</span>
			// </Link>
		),
	},
	{
		accessor: "transaction_date",
		Header: "Transaction Date",
		Cell: ({ value, row }) => (
			// <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
			<span className="glade-normal-text-two">
				{dfn.format(
					new Date(fixDateForAllBrowsers(value)),
					"MMM d, yyyy hh:mm:ss a"
				)}
			</span>
			// </Link>
		),
	},
	{
		accessor: "details",
		Header: "Details",
		Cell: ({ value, row }) => (
			// <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
			<div className="glade-normal-text-two capitalize block w-52 whitespace-normal">
				{value}
			</div>
			// </Link>
		),
	},
];

import React from "react";
import { useDispatch } from "react-redux";

import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import useCACRegistration from "../../hooks/useCACRegstration";
import { markCACStatus } from "../../redux/cacRegisstration/cacRegistrationActions";

const MarkCACAsDeliveredModal = ({
    modalStates,
    setShowModal,
    showModal,
    selectedCAC,
    setSelectedCAC,
    currentParams,
    status
}) => {
    const dispatch = useDispatch();
    const {isloadingCACStatusUpdate} = useCACRegistration();

    const onSuccess = () => {
        setSelectedCAC({});
        setShowModal(false);
    }

    const setCACAsDelivered = () => {
        dispatch(markCACStatus({ ...currentParams }, { status: status, id: selectedCAC.id }, onSuccess))
    }

    return (
        <BasicModal
            visibility={showModal}
            onClose={() => {
                setShowModal(false);
                setSelectedCAC({});
            }}
            onOpen={() =>
                setShowModal({
                    ...modalStates,
                    showModal: true,
                })
            }
            primaryButton=""
            modalTitleSection=""
            secondaryButton=""
            modelContentSection={
                <div className="p-6 px-0">
                    <div className="">
                        <h3 className="flex justify-center items-center glade-black mb-8 p-4 text-center">
                            Are you sure you want to set the status as {`${status}`}?
                        </h3>
                    </div>
                    {
                        status === 'delivered' ?
                        <Button.Dark
                            onClick={setCACAsDelivered}
                            loading={isloadingCACStatusUpdate}
                            title="Set as Delivered"
                        >
                            {isloadingCACStatusUpdate ? "Delivering" : "'"}
                        </Button.Dark>
                    :
                    <Button.Danger
                        onClick={setCACAsDelivered}
                        loading={isloadingCACStatusUpdate}
                        title="Set as Rejected"
                        className={"w-full"}
                    >
                        {isloadingCACStatusUpdate ? "Rejecting" : ""}
                    </Button.Danger>}
                    <button
                        onClick={() => setShowModal(false)}
                        className="glade-normal-text-three glade-grey-dark w-full mx-auto text-center mt-6"
                    >
                        Cancel
                    </button>
                </div>
            }
        />
    );
};

export default MarkCACAsDeliveredModal;

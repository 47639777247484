import { useSelector } from "react-redux";

const useAuditLogs = () => {
  const auditLogs = useSelector(state => state.auditLogs)
  const adminUsers = useSelector(state => state.filters)
  
  const { allAuditLogs, isLoadingAuditLogs, isLoadingSpecificAuditLog } = auditLogs
  const { isLoadingAdminUsers, allAdmins } = adminUsers

  return { 
    allAuditLogs, 
    isLoadingAuditLogs,
    isLoadingSpecificAuditLog,
    isLoadingAdminUsers,
    allAdmins
  }
}

export default useAuditLogs
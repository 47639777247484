import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as dfn from "date-fns";

import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { CardPlain, CardPlainSection } from "../../components/cards/CardPlain";
import useNotifications from "../../hooks/useNotifications";
import { FormError } from "../../validation";
import Button from "../../components/buttons/Button";
import {
	editSpecificDraftNotification,
	sendNotification,
} from "../../redux/notifications/notificationsActions";
import { useLocation } from "react-router";
import { fixDateForAllBrowsers } from "../../helpers/helper";

export default function EditNotification() {
	const dispatch = useDispatch();
	const location = useLocation();

	const notification = location.state;

	const previous_date = notification?.start_time?.split(" ")?.[0];
	const previous_time = notification?.start_time?.split(" ")?.[1];

	React.useLayoutEffect(() => {
		if (notification?.notification_type) {
			setValue("type", notification?.notification_type);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notification]);

	const {
		allNotificationCategories,
		isSendingNotification,
		isEditingDraftNotification,
	} = useNotifications();

	const onSendNotification = ({
		title,
		message,
		category,
		type,
		date,
		time,
	}) => {
		let send_time = null;

		if (type === "scheduled") {
			send_time = date + " " + time;
		}
		const formData = {
			title,
			body: message,
			notification_category_id: category,
			notification_type: type,
			is_draft: false,
			start_time: send_time,
		};

		dispatch(sendNotification(formData));
	};

	const editDraft = ({ title, message, category, type, date, time }) => {
		let send_time = null;

		if (type === "scheduled") {
			send_time = date + " " + time;
		}

		const formData = {
			title,
			body: message,
			notification_category_id: category,
			notification_type: type,
			is_draft: true,
			start_time: send_time,
		};

		dispatch(editSpecificDraftNotification(notification?.id, formData));
	};

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
	} = useForm({
		criteriaMode: "all",
		shouldUseNativeValidation: true,
	});

	const notificationType = watch("type");

	return (
		<div className="p-3">
			<div className="flex flex-col">
				<div className="mb-24">
					<BreadCrumbs title={"Notifications"} goBack />
				</div>
				<form onSubmit={handleSubmit(editDraft)} noValidate>
					<CardPlain type="single">
						<CardPlainSection title="Edit Notification" type="single">
							<section className="pt-16 px-16">
								<div className="mb-4">
									<label htmlFor="title" className="block glade-small-text-two">
										Title
									</label>
									<input
										type="text"
										id="title"
										name="title"
										{...register("title", {
											required: "Enter a title",
										})}
										defaultValue={notification?.title}
										placeholder="Enter notification title"
										className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
									/>
									<FormError errors={errors} name="title" />
								</div>
								<div className="mb-4">
									<label htmlFor="title" className="block glade-small-text-two">
										Message
									</label>
									<textarea
										id="message"
										name="message"
										rows={4}
										{...register("message", {
											required: "Enter a message",
										})}
										defaultValue={notification?.body}
										placeholder="Enter notification message"
										className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
									/>
									<FormError errors={errors} name="message" />
								</div>
								<div className="w-full my-3">
									<label
										htmlFor="category"
										className="block glade-small-text-two"
									>
										Recipients
									</label>
									<select
										id="category"
										name="category"
										{...register("category", {
											required: "Select a category",
										})}
										defaultValue={notification?.notification_category_id}
										className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
									>
										{allNotificationCategories?.map((category) => (
											<option
												key={category.id}
												value={category.id}
												className="capitalize"
											>
												{category.name}
											</option>
										))}
									</select>
									<FormError errors={errors} name="category" />
								</div>

								<div className="w-full my-3">
									<label htmlFor="type" className="block glade-small-text-two">
										Select Notification Type
									</label>
									<select
										id="type"
										name="type"
										{...register("type", { required: "Select a type" })}
										defaultValue={notification?.notification_type}
										className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
									>
										<option value="instant">Instant</option>
										<option value="scheduled">Scheduled</option>
									</select>
									<FormError errors={errors} name="type" />
								</div>

								{notificationType === "scheduled" && (
									<>
										<div className="">
											<label
												htmlFor="date"
												className="block glade-small-text-two"
											>
												Date
											</label>
											<input
												type="date"
												id="date"
												name="date"
												{...register("date", {
													required: "Select date",
													validate: (value) => {
														if (value === "") {
															return "Select a valid date";
														} else if (
															dfn.isBefore(
																new Date(fixDateForAllBrowsers(value)),
																dfn.subDays(new Date(), 1)
															)
														) {
															return "Date should be today or later";
														}
													},
												})}
												defaultValue={previous_date}
												className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
											/>
											<FormError errors={errors} name="date" />
										</div>
										<div className="mt-4">
											<label
												htmlFor="time"
												className="block glade-small-text-two"
											>
												Time
											</label>
											<input
												type="time"
												id="time"
												name="time"
												{...register("time", {
													required: "Select time",
												})}
												defaultValue={previous_time}
												className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
											/>
											<FormError errors={errors} name="time" />
										</div>
									</>
								)}
							</section>
						</CardPlainSection>
					</CardPlain>
					<div className="flex justify-center mt-6 space-x-4">
						<Button.Gray
							type="submit"
							title="Save Draft"
							loading={isEditingDraftNotification}
							style={{ width: "fit-content" }}
						/>
						<Button.Dark
							type="button"
							title="Send Notification"
							onClick={handleSubmit((data) => {
								onSendNotification(data);
							})}
							loading={isSendingNotification}
							style={{ width: "fit-content" }}
						/>
					</div>
				</form>
			</div>
		</div>
	);
}

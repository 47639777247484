import React, { useEffect, useState } from 'react';
import Button from '../../components/buttons/Button';
import { useDispatch } from 'react-redux';
import BasicModal from '../../components/modals/BasicModal';
import { getSupportedTransferCurrencies, updateTransferCharge } from '../../redux/cross-border/crossBorderActions';
import useMultiCurrency from '../../hooks/useMultiCurrency';
import { getMultiCurrencyCharges, updateMultiCurrencyCharge } from '../../redux/multi-currency/multiCurrencyActions';

const EditChargeModal = ({
  setData,
  modalStates,
  setModalStates,
  selectedCharge,
  setSelectedCharge,
  showEditChargeModal,
  filterValue
}) => {

  const [formPayload, setFormPayload] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormPayload(form => ({ ...form, [e.target.name]: e.target.value }));
  };

  const { isUpdatingMultiCurrencyCharges } = useMultiCurrency();

  useEffect(() => {
    setFormPayload(form => ({
      ...form,
      fee_cap: selectedCharge?.fee_cap ?? 0,
      fee_fixed: selectedCharge?.fee_fixed ?? 0,
      fee_percentage: selectedCharge?.fee_percentage ?? 0
    }));
  }, [selectedCharge]);



  const onSuccess = () => {
    setSelectedCharge({});
    dispatch(getMultiCurrencyCharges({ params: filterValue }, (charges) => {
      setData(charges);
    }));
    setModalStates({
      ...modalStates,
      showEditChargeModal: false
    });
  };

  const clearForm = () => setFormPayload({});

  const onChargeUpdate = (e) => {
    e.preventDefault();
    const payload = {
      value: formPayload?.fee_fixed,
      currency: selectedCharge?.destination_currency
    };
    dispatch(updateMultiCurrencyCharge(payload, onSuccess));
  };

  return (
    <BasicModal
      visibility={showEditChargeModal}
      onClose={() => {
        setModalStates(modalStates => ({
          ...modalStates,
          showEditChargeModal: false
        }));
        setSelectedCharge({});
        clearForm();
      }}
      onOpen={() => {
        setModalStates(modalStates => ({
          ...modalStates,
          showEditChargeModal: true
        }));
      }}
      primaryButton=""
      secondaryButton=""
      modalTitle={`Edit ${selectedCharge.destination_currency ? selectedCharge.destination_currency.toUpperCase() : "Guest"} Account Creation Charge`}
      modelContentSection={
        <React.Fragment>
          <form onSubmit={(e) => onChargeUpdate(e)}>
            <div className="mb-4 pt-8 flex flex-col gap-6">
              <div>
                <label
                  htmlFor='amount'
                  className='block glade-small-text-two capitalize'
                >
                  Fixed fee
                </label>
                <div className="mt-2">
                  <div>
                    <div className="relative">
                      <div className="absolute inset-y-0 pl-3 left-0 flex items-center pointer-events-none">
                        <span className="glade-black glade-uppercase sm:text-sm py-2">{selectedCharge?.destination_currency}</span>
                      </div>
                      <input
                        type="number"
                        name="fee_fixed"
                        id="fee_fixed"
                        value={formPayload?.value}
                        defaultValue={selectedCharge?.value}
                        onChange={handleChange}
                        className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* SECOND INPUT */}
              <section>
                <label
                  htmlFor='amount'
                  className='block glade-small-text-two capitalize'
                >
                  Percentage fee
                </label>
                <div className="mt-2">
                  <div>
                    <div className="relative">
                      <div className="absolute inset-y-0 pr-4 right-0 flex items-center pointer-events-none">
                        <span className="glade-black sm:text-sm py-2">%</span>
                      </div>
                      <input
                        type="number"
                        disabled
                        name="fee_percentage"
                        id="fee_percentage"
                        value={formPayload?.fee_percentage}
                        onChange={handleChange}
                        // placeholder={1}
                        className="mt-1 block w-full pl-4 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                      />
                    </div>
                  </div>
                </div>
              </section>
              {/* THIRD INPUT */}
              <section>
                <label
                  htmlFor='amount'
                  className='block glade-small-text-two capitalize'
                >
                  Capped
                </label>
                <div className="mt-2">
                  <div>
                    <div className="relative">
                      <div className="absolute inset-y-0 pl-3 left-0 flex items-center pointer-events-none">
                        <span className="glade-black sm:text-sm glade-uppercase py-2">{selectedCharge?.destination_currency}</span>
                      </div>
                      <input
                        type="number"
                        disabled
                        name="fee_cap"
                        id="fee_cap"
                        value={formPayload?.fee_cap}
                        onChange={handleChange}
                        // placeholder={1}
                        className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                      />
                    </div>
                  </div>
                </div>
              </section>
              {/* MODAL FOOTER */}
              <div className="flex -mb-8 items-center justify-between p-4  -mx-5">
                <button
                  type="button"
                  onClick={() => {
                    setModalStates(modalStates => ({
                      ...modalStates,
                      showEditChargeModal: false,
                    }));
                    setSelectedCharge({});
                    clearForm();
                  }}
                  className="glade-normal-text-two glade-black text-center"
                >
                  Cancel
                </button>
                <div>
                  <Button.Dark
                    type="submit"
                    title="Update"
                    className="block flex-grow-0"
                    loading={isUpdatingMultiCurrencyCharges}
                  />
                </div>
              </div>
            </div>
          </form>
        </React.Fragment>
      }
    />
  );
};

export default EditChargeModal;
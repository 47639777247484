import { 
  GET_ALL_AUDIT_LOGS_START, 
  GET_ALL_AUDIT_LOGS_SUCCESS,
  GET_ALL_AUDIT_LOGS_FAILURE,
  GET_SPECIFIC_AUDIT_LOG_START,
  GET_SPECIFIC_AUDIT_LOG_SUCCESS,
  GET_SPECIFIC_AUDIT_LOG_FAILURE
} from "./types"

const initialState = {
  isLoadingAuditLogs: true,
  isLoadingSpecificAuditLog: true,
  allAuditLogs: [],
  specificAuditLog: []
}

export default function auditReducer (state = initialState, action) {
  switch(action.type) {
    case GET_ALL_AUDIT_LOGS_START:
      return {
        ...state,
        isLoadingAuditLogs: true
      }

    case GET_ALL_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        isLoadingAuditLogs: false
      }

    case GET_ALL_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        isLoadingAuditLogs: false,
        allAuditLogs: action.payLoad
      }

    case GET_SPECIFIC_AUDIT_LOG_START:
      return {
        ...state,
        isLoadingSpecificAuditLog: true,
        allAuditLogs: [],
        isLoadingAuditLogs:true
      }

    case GET_SPECIFIC_AUDIT_LOG_FAILURE:
      return {
        ...state,
        isLoadingSpecificAuditLog: false,
        isLoadingAuditLogs:false
      }

    case GET_SPECIFIC_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        isLoadingSpecificAuditLog: false,
        isLoadingAuditLogs:false,
        allAuditLogs: action.payLoad
      }

    default: 
      return state;
  }
}

import { useSelector } from "react-redux";

const useDiscounts = () => {
  const { discounts, accounts } = useSelector(state => state);
  const {
    isLoadingDiscounts,
    allDiscounts,
    isCreatingNewDiscount,
    isEditingDiscount,
    singleDiscount,
    isLoadingSingleDiscount
  } = discounts;
  const { allPersonalAccounts, allBusinessAccounts } = accounts;
  return {
    isLoadingDiscounts,
    allDiscounts,
    isCreatingNewDiscount,
    isEditingDiscount,
    singleDiscount,
    isLoadingSingleDiscount,
    allPersonalAccounts,
    allBusinessAccounts
  };
};

export default useDiscounts;
import React from 'react';

const NoResult = () => {
  return (
    <h3
      style={{
        padding: "1rem 0 0.5rem",
        textAlign: 'center'
      }}>
      No Results
    </h3>
  );
};

export default NoResult;
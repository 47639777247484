import styled from "styled-components";
import React, { useContext } from "react";

import MultiSelectSuggestions from "./MultiSelectSuggestions";
import { MultiSelectContext } from "../../context/MultiSelectContext";
import { RiLoader5Fill, RiCloseFill } from "react-icons/ri";
import MultiSelectItems from "./MultiSelectItems";


const SMultiSelectSection = styled.div`
  border: 1px solid #afafaf80;
  padding: .25rem;
  border-radius: 2px;
  display: flex;
  flex-direction: column;

  input {
    outline: none;
    border: none;
    &:focus {
      outline: red;
      border: red;
    }
  }
`;

const SInputSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  // margin-bottom: .5rem;

  #selectInput {
      outline: 0;
      border: 1px solid transparent;
      height: 100%;
      width: 100%;
      width: 100%;
      &:focus {
        border: 1px solid #233972;
      }
  }

  .loading-icon, .clear-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
  }

  .clear-icon {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 250ms ease-in,
    background-color 250ms ease-in,
    box-shadow 200ms ease-in;
    min-height: 25px;
    min-width: 25px;
    box-shadow: 2px 2px 10px rgba(0, 175, 239, 0.2);
    background-color: #00afef;
    color: #fff;
    &:hover {
      background-color: transparent;
      color: #00afef;
      box-shadow: 2px 2px 10px rgba(0, 175, 239, 0.1);
    }
  }
`;

export default function MultiSelectInput() {
  const {
    setInput,
    input,
    inputRef,
    loading,
    isPersonal,
    selectOptions,
    setSuggestions,
    setNoResults
  } = useContext(MultiSelectContext);

  const onChange = e => setInput(e.target.value);

  function clearInput() {
    setInput('');
    setSuggestions([]);
    setNoResults(false);
    inputRef.current?.focus();
  }

  return (
    <SMultiSelectSection>
      {selectOptions?.length > 0 && <MultiSelectItems />}
      <SInputSection>
        <input
          type="text"
          value={input}
          ref={inputRef}
          id="selectInput"
          onChange={onChange}
          placeholder="Search Account Name or Email"
        />
        {loading
          ? (
            <span className="loading-icon">
              <RiLoader5Fill size={24} className="animate-spin ml-4" />
            </span>
          )
          : input ? (
            <span
              className="clear-icon"
              onClick={clearInput}
            >
              <RiCloseFill />
            </span>)
            : null
        }
      </SInputSection>
      <MultiSelectSuggestions />
    </SMultiSelectSection>
  );
}




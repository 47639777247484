export const GET_ALL_PERSONAL_COMPLIANCE_START = "GET_ALL_PERSONAL_COMPLIANCE_START";
export const GET_ALL_PERSONAL_COMPLIANCE_SUCCESS = "GET_ALL_PERSONAL_COMPLIANCE_SUCCESS";
export const GET_ALL_PERSONAL_COMPLIANCE_FAILURE = "GET_ALL_PERSONAL_COMPLIANCE_FAILURE";

export const GET_SPECIFIC_PERSONAL_COMPLIANCE_START = "GET_SPECIFIC_PERSONAL_COMPLIANCE_START";
export const GET_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS = "GET_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS";
export const GET_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE = "GET_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE";

export const UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_START = "UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_START";
export const UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS = "UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS";
export const UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE = "UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE";

export const GET_ALL_BUSINESS_COMPLIANCE_START = "GET_ALL_BUSINESS_COMPLIANCE_START";
export const GET_ALL_BUSINESS_COMPLIANCE_SUCCESS = "GET_ALL_BUSINESS_COMPLIANCE_SUCCESS";
export const GET_ALL_BUSINESS_COMPLIANCE_FAILURE = "GET_ALL_BUSINESS_COMPLIANCE_FAILURE";

export const GET_SPECIFIC_BUSINESS_COMPLIANCE_START = "GET_SPECIFIC_BUSINESS_COMPLIANCE_START";
export const GET_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS = "GET_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS";
export const GET_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE = "GET_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE";

export const UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_START = "UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_START";
export const UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS = "UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS";
export const UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE = "UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE";

// GET_BUSINESS_PRIMARY_OWNER_
export const GET_BUSINESS_PRIMARY_OWNER_START = "GET_BUSINESS_PRIMARY_OWNER_START"
export const GET_BUSINESS_PRIMARY_OWNER_SUCCESS = "GET_BUSINESS_PRIMARY_OWNER_SUCCESS"
export const GET_BUSINESS_PRIMARY_OWNER_FAILURE = "GET_BUSINESS_PRIMARY_OWNER_FAILURE"

export const GET_BUSINESS_DIRECTORS_START = "GET_BUSINESS_DIRECTORS_START"
export const GET_BUSINESS_DIRECTORS_SUCCESS = "GET_BUSINESS_DIRECTORS_SUCCESS"
export const GET_BUSINESS_DIRECTORS_FAILURE = "GET_BUSINESS_DIRECTORS_FAILURE"
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

export const email = {
	pattern: {
		value:
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
		message: "Please enter a valid email.",
	}
}
export const email_required = {
	...email,
	required: "Email is required",
}

export const password = {
	required: "Password is required",
	minLength: {
		value: 6,
		message: "Password must be at least 6 characters long."
	}
}

export const FormError = ({errors, name }) => (
	<ErrorMessage errors={errors} name={name}
		as={
			<p className="text-red-500 text-xs mt-1" />
		}
	>
		{({ messages }) =>
			messages &&
			Object.entries(messages).map(([type, message]) => (
				<p key={type}>{message}</p>
			))
		}
	</ErrorMessage>
)
import { useSelector } from "react-redux";

const useHome = () => {
  const home = useSelector((state) => state.home);

  const {
    isLoadingTransactionSummary,
    isLoadingTransactionStatus,
    isLoadingActiveUsersReport,
    isLoadingMostUsedServices,
    transactionSummary,
    transactionStatus,
    activeUsersReport,
    mostUsedServices,
  } = home;

  return {
    isLoadingTransactionSummary,
    isLoadingTransactionStatus,
    isLoadingActiveUsersReport,
    isLoadingMostUsedServices,
    transactionSummary,
    transactionStatus,
    activeUsersReport,
    mostUsedServices,
  };
};

export default useHome;

import * as dfn from "date-fns";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { CgSortAz } from "react-icons/cg";
import { useLocation, useHistory } from "react-router-dom";
// import qs from "query-string";
import Button from "../../components/buttons/Button";
import { PopoverButton, PopoverMain } from "../../components/popovers/Popover";
import { FormError } from "../../validation";
import { fixDateForAllBrowsers } from "../../helpers/helper";
import { useSelector } from "react-redux";

export function FilterItemCategory({ returnInput = () => { }, categories }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="category" className="block glade-small-text-two">
				Category
			</label>
			<select
				id="category"
				name="category"
				{...register("category")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				{categories.map((category) => (
					<option key={category.id} value={category.id} className="capitalize">
						{category.name}
					</option>
				))}
			</select>
			<FormError errors={errors} name="category" />
		</div>
	);
}

export function FilterItemChannel({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="channel" className="block glade-small-text-two">
				Channel
			</label>
			<select
				id="channel"
				name="channel"
				{...register("channel")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="all">All</option>
				<option value="min">Min</option>
			</select>
			<FormError errors={errors} name="channel" />
		</div>
	);
}

export function FilterItemTransactionChannel({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="channel" className="block glade-small-text-two">
				Channel
			</label>
			<select
				id="channel"
				name="channel"
				{...register("channel")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="">All</option>
				<option value="transfer">Transfer</option>
				<option value="pos">POS</option>
				<option value="checkout">Checkout</option>
				<option value="bills">Bills</option>
			</select>
			<FormError errors={errors} name="channel" />
		</div>
	);
}

export function FilterItemStatus({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="status" className="block glade-small-text-two">
				Status
			</label>
			<select
				id="status"
				name="status"
				{...register("status")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="successful">Successful</option>
				<option value="failed">Failed</option>
				<option value="pending">Pending</option>
			</select>
			<FormError errors={errors} name="status" />
		</div>
	);
}

export function FilterWalletStatus({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="status" className="block glade-small-text-two">
				Status
			</label>
			<select
				id="status"
				name="status"
				{...register("status")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="approved">Approved</option>
				<option value="pending">Pending</option>
				<option value="rejected">Rejected</option>
			</select>
			<FormError errors={errors} name="status" />
		</div>
	);
}

export function FilterItemStatusWithAll({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="status" className="block glade-small-text-two">
				Status
			</label>
			<select
				id="status"
				name="status"
				{...register("status")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="">All</option>
				<option value="successful">Successful</option>
				<option value="failed">Failed</option>
				<option value="pending">Pending</option>
			</select>
			<FormError errors={errors} name="status" />
		</div>
	);
}

export function FilterItemPaymentMethod({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="type" className="block glade-small-text-two">
				Payment Method
			</label>
			<select
				id="type"
				name="type"
				{...register("type")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="">All</option>
				<option value="card">Card</option>
				<option value="ussd">USSD</option>
				<option value="qr">QR Pay</option>
				<option value="bank">Pay with bank transfer</option>
			</select>
			<FormError errors={errors} name="type" />
		</div>
	);
}

export function FilterItemBlacklist({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="status" className="block glade-small-text-two">
				Status
			</label>
			<select
				id="status"
				name="status"
				{...register("status")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="all">All</option>
				<option value="active">Active</option>
				<option value="blacklisted">Blacklisted</option>
			</select>
			<FormError errors={errors} name="status" />
		</div>
	);
}

export function FilterItemAccount({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="status" className="block glade-small-text-two">
				Status
			</label>
			<select
				id="status"
				name="status"
				{...register("status")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="">All</option>
				<option value="active">Active</option>
				<option value="pending">Pending</option>
				<option value="blacklisted">Blacklisted</option>
			</select>
			<FormError errors={errors} name="status" />
		</div>
	);
}

export function FilterItemSettlement({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="status" className="block glade-small-text-two">
				Status
			</label>
			<select
				id="status"
				name="status"
				{...register("status")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="all">All</option>
				<option value="settled">Settled</option>
				<option value="unsettled">Unsettled</option>
			</select>
			<FormError errors={errors} name="status" />
		</div>
	);
}

export function FilterCardStatus({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="status" className="block glade-small-text-two">
				Status
			</label>
			<select
				id="status"
				name="status"
				{...register("status")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="all">All</option>
				<option value="1">Active</option>
				<option value="0">Blocked</option>
			</select>
			<FormError errors={errors} name="status" />
		</div>
	);
}

export function FilterTerminalStatus({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="status" className="block glade-small-text-two">
				Status
			</label>
			<select
				id="status"
				name="status"
				{...register("status")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="">All</option>
				<option value="1">Active</option>
				<option value="0">Disabled</option>
			</select>
			<FormError errors={errors} name="status" />
		</div>
	);
}

export function FilterItemType({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="type" className="block glade-small-text-two">
				Type
			</label>
			<select
				id="type"
				name="type"
				{...register("type")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="all">All</option>
				<option value="credit">Credit</option>
				<option value="debit">Debit</option>
			</select>
			<FormError errors={errors} name="type" />
		</div>
	);
}

export function FilterItemTransactionType({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="type" className="block glade-small-text-two">
				Type
			</label>
			<select
				id="type"
				name="type"
				{...register("type")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="">All</option>
				<option value="credit">Credit</option>
				<option value="debit">Debit</option>
			</select>
			<FormError errors={errors} name="type" />
		</div>
	);
}

export function FilterItemAccountType({ returnInput = () => { } }) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="w-full my-3">
			<label htmlFor="type" className="block glade-small-text-two">
				Account Type
			</label>
			<select
				id="type"
				name="type"
				{...register("type")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option value="">All</option>
				<option value="personal">Personal</option>
				<option value="business">Business</option>
			</select>
			<FormError errors={errors} name="type" />
		</div>
	);
}

export const FilterItemUser = ({ returnInput = () => { } }) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	const { allAdmins } = useSelector(state => state.filters);

	return (
		<div className="w-full my-3">
			<label htmlFor="type" className="block glade-small-text-two">User</label>
			<select
				name="user"
				id="user"{...register("user")}
				className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
			>
				<option key="all" value="all">All Users</option>
				{allAdmins.data.map(admin => (
					<option key={admin.id} value={admin.admin_uuid}>{admin.name}</option>
				))}
			</select>
			<FormError errors={errors} name="type" />
		</div>
	);
};

export const FilterItemDate = () => {
	const {
		register,
		watch,
		formState: { errors },
	} = useFormContext();

	const dateSelect = watch("date");
	const from = watch("from");

	return (
		<>
			<div className="w-full my-3">
				<label htmlFor="date" className="block glade-small-text-two">
					Date
				</label>
				<select
					id="date"
					name="date"
					defaultValue="all-time"
					{...register("date")}
					className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
				>
					<option value="today">Today</option>
					<option value="yesterday">Yesterday</option>
					<option value="last-7-days">Last 7 days</option>
					<option value="last-30-days">Last 30 days</option>
					<option value="all-time">All Time</option>
					<option value="custom-date">Custom Date</option>
				</select>
				<FormError errors={errors} name="date" />
			</div>

			{dateSelect === "custom-date" && (
				<>
					{/* <div className="grid grid-cols-2 gap-x-3"> */}
					<div className="">
						<label htmlFor="from" className="block glade-small-text-two">
							From
						</label>
						<input
							type="date"
							id="from"
							name="from"
							{...register("from", {
								required: "Select a start date",
								validate: (value) => {
									if (value === "") {
										return "Select a valid date";
									} else if (
										!dfn.isBefore(
											new Date(fixDateForAllBrowsers(value)),
											dfn.addDays(new Date(), 1)
										)
									) {
										return "Date should be today or earlier";
									}
								},
							})}
							className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
						/>
						<FormError errors={errors} name="from" />
					</div>
					<div className="mt-2">
						<label htmlFor="to" className="block glade-small-text-two">
							To
						</label>
						<input
							type="date"
							id="to"
							name="to"
							{...register("to", {
								required: "Select an end date",
								validate: (value) => {
									if (value === "") {
										return "Select a valid date";
									} else if (
										!dfn.isAfter(
											new Date(fixDateForAllBrowsers(value)),
											dfn.subDays(new Date(from), 1)
										)
									) {
										return "Date should be after from date";
									} else if (
										!dfn.isBefore(
											new Date(fixDateForAllBrowsers(value)),
											dfn.addDays(new Date(), 1)
										)
									) {
										return "Date should be today or earlier";
									}
								},
							})}
							className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
						/>
						<FormError errors={errors} name="to" />
					</div>
					{/* </div> */}
				</>
			)}
		</>
	);
};



export default function Filter({
	style,
	className,
	returnInput = () => { },
	items = ["channel", "status", "date"],
	customCategories,
	filterCallback = () => { },
	button,
}) {
	const location = useLocation();
	let history = useHistory();
	const [input, setInput] = React.useState({});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => returnInput(input), [input]);

	const formCtx = useForm({
		criteriaMode: "all",
	});
	const { reset, handleSubmit } = formCtx;

	const onFilter = (data) => {
		const filterData = {};
		const filterOptions = [];


		switch (data.date) {
			case "last-7-days":
				filterData.date_from = dfn.lightFormat(
					dfn.subWeeks(new Date(), 1),
					"yyyy-MM-dd"
				);
				filterData.date_to = dfn.lightFormat(new Date(), "yyyy-MM-dd");
				break;

			case "last-30-days":
				filterData.date_from = dfn.lightFormat(
					dfn.subDays(new Date(), 30),
					"yyyy-MM-dd"
				);
				filterData.date_to = dfn.lightFormat(new Date(), "yyyy-MM-dd");
				break;

			case "all-time":
				filterData.date_from = dfn.lightFormat(
					dfn.subWeeks(new Date(), 52),
					"yyyy-MM-dd"
				);
				filterData.date_to = dfn.lightFormat(new Date(), "yyyy-MM-dd");
				break;

			case "yesterday":
				filterData.date_from = dfn.lightFormat(
					dfn.subDays(new Date(), 1),
					"yyyy-MM-dd"
				);
				filterData.date_to = dfn.lightFormat(
					dfn.subDays(new Date(), 1),
					"yyyy-MM-dd"
				);
				break;

			case "today":
				filterData.date_from = dfn.lightFormat(new Date(), "yyyy-MM-dd");
				filterData.date_to = dfn.lightFormat(new Date(), "yyyy-MM-dd");
				break;

			default:
				filterData.date_from = dfn.lightFormat(
					dfn.subWeeks(new Date(), 52),
					"yyyy-MM-dd"
				);
				filterData.date_to = dfn.lightFormat(new Date(), "yyyy-MM-dd");
				break;
		}

		if (data.from) {
			filterData.date_from = data.from;

		}

		if (data.to) {
			filterData.date_to = data.to;

		}

		if (data.status) {
			filterData.status = data.status;
			filterOptions.push("Status");

		}

		if (data.type) {
			filterData.type = data.type;
			filterOptions.push("Type");
		}
		if (data.date) {
			filterOptions.push("Date");
		}
		if (data.channel) {
			filterData.channel = data.channel;
			filterOptions.push("Channel");

		}

		if (data.category) {
			filterData.category = data.category;
			filterOptions.push("Category");

		}

		if (data.user) {
			filterData.user = data.user;
			filterOptions.push("User");

		}
		setFilteredOptions(filterOptions);

		filterCallback(filterData);
	};

	const resetFilter = () => {
		reset();
		history.replace(location.pathname);
	};

	const [filteredOptions, setFilteredOptions] = useState([]);

	// const updateFilter = (params) => {
	// 	let filters = [];
	// 	params.forEach((param) => {
	// 		switch (param) {
	// 			// case "date_from" || "date_to":
	// 			// 	filters.push("Date");
	// 			// 	break;

	// 			case "status":
	// 				filters.push("Status");
	// 				break;

	// 			case "type":
	// 				filters.push("Type");
	// 				break;

	// 			case "channel":
	// 				filters.push("Channel");
	// 				break;

	// 			case "customCategory":
	// 				filters.push("Category");
	// 				break;

	// 			case "user":
	// 				filters.push("User");
	// 				break;

	// 			default:
	// 				break;
	// 		}
	// 	});

	// 	setFilteredOptions(filters);
	// };

	useEffect(() => {
		if (location.search) {
		} else {
			setFilteredOptions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<FormProvider {...formCtx}>
			<PopoverMain
				button={
					button ?? (
						<div
							type="button"
							style={style}
							className={"py-2 glade-ash flex-shrink-0 " + className}
						>
							{filteredOptions?.length > 0 ? (
								<div className="flex flex-shrink-0 items-center justify-items-center">
									<span className="text-2xl">
										<CgSortAz color="#032086" />
									</span>
									<span className="glade-subtitle-one glade-blue-darker ml-1 flex-shrink-0">
										Filtered by: {filteredOptions?.join(", ")}
									</span>
								</div>
							) : (
								<div className="flex items-center justify-items-center">
									<span className="text-2xl">
										<CgSortAz />
									</span>
									<span className="glade-subtitle-two ml-1">Filter</span>
								</div>
							)}
						</div>
					)
				}
			>
				{({ close }) => (
					<form
						onSubmit={formCtx.handleSubmit(onFilter)}
						className="flex flex-col p-6 bg-white shadow rounded-sm w-72"
					>
						{items.map((item, key) => {
							switch (item) {
								case "customCategory":
									return (
										<FilterItemCategory
											key={key}
											categories={customCategories}
											returnInput={(value) => setInput({ ...input, ...value })}
										/>
									);
								case "channel":
									return (
										<FilterItemChannel
											key={key}
											returnInput={(value) => setInput({ ...input, ...value })}
										/>
									);
								case "status":
									return (
										<FilterItemStatus
											key={key}
											returnInput={(value) => setInput({ ...input, ...value })}
										/>
									);
								case "blacklist":
									return <FilterItemBlacklist key={key} />;
								case "account":
									return <FilterItemAccount key={key} />;
								case "walletStatus":
									return <FilterWalletStatus key={key} />
								case "settlement":
									return <FilterItemSettlement key={key} />;
								case "cardStatus":
									return <FilterCardStatus key={key} />;
								case "terminalStatus":
									return <FilterTerminalStatus key={key} />;
								case "statusWithAll":
									return <FilterItemStatusWithAll key={key} />;
								case "paymentMethod":
									return <FilterItemPaymentMethod key={key} />;
								case "transactionChannel":
									return <FilterItemTransactionChannel key={key} />;
								case "transactionType":
									return <FilterItemTransactionType key={key} />;
								case "accountType":
									return <FilterItemAccountType key={key} />;
								case "type":
									return (
										<FilterItemType
											key={key}
											returnInput={(value) => setInput({ ...input, ...value })}
										/>
									);
								case "date":
									return (
										<FilterItemDate
											key={key}
											returnInput={(value) => setInput({ ...input, ...value })}
										/>
									);
								case "user":
									return (
										<FilterItemUser
											key={key}
											returnInput={(value) => setInput({ ...input, ...value })}
										/>
									);
								default:
									return <React.Fragment></React.Fragment>;
							}
						})}
						<div className="flex flex-row items-center justify-between mt-9">
							{filteredOptions?.length < 1 ? (
								<PopoverButton>
									<span className="glade-ash mx-1 cursor-pointer">Cancel</span>
								</PopoverButton>
							) : (
								<span
									className="glade-ash mx-1 cursor-pointer"
									onClick={() => {
										resetFilter();
										close();
									}}
								>
									Reset
								</span>
							)}
							<div className="mx-2">
								<Button.Dark
									type="submit"
									title={"Filter"}
									onClick={handleSubmit((data) => {
										onFilter(data);
										close();
									})}
								/>
							</div>
						</div>
					</form>
				)}
			</PopoverMain>
		</FormProvider>
	);
}
import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import {
  toastSuccess,
  // toastError,
  // toastWarning
} from "../../helpers/utils.toast";

import {
  GET_VIRTUAL_CARD_SETTINGS_FAILURE,
  GET_VIRTUAL_CARD_SETTINGS_START,
  GET_VIRTUAL_CARD_SETTINGS_SUCCESS,
  UPDATE_VIRTUAL_CARD_SETTINGS_FAILURE,
  UPDATE_VIRTUAL_CARD_SETTINGS_START,
  UPDATE_VIRTUAL_CARD_SETTINGS_SUCCESS
} from "./types";


export const getAllSettings = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_VIRTUAL_CARD_SETTINGS_START });
    Axios.get(`settings/set/virtual-card`)
      .then(res => {
        dispatch({
          type: GET_VIRTUAL_CARD_SETTINGS_SUCCESS,
          payLoad: res.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_VIRTUAL_CARD_SETTINGS_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};

export const updateVirtualCardSettings = data => {
  return dispatch => {
    dispatch({ type: UPDATE_VIRTUAL_CARD_SETTINGS_START });
    Axios.post(`settings/set/virtual-card`, data)
      .then(res => {
        dispatch({
          type: UPDATE_VIRTUAL_CARD_SETTINGS_SUCCESS,
          payLoad: res.data.data
        });
        toastSuccess('Virtual card settings updated');
        setTimeout(window.location.reload(), 500);
      })
      .catch(error => {
        dispatch({
          type: UPDATE_VIRTUAL_CARD_SETTINGS_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};

export const updateVirtualCardMonthlyFee = data => {
  return dispatch => {
    dispatch({ type: UPDATE_VIRTUAL_CARD_SETTINGS_START });
    Axios.post(`settings/set/virtual-card/monthly-fee`, data)
      .then(res => {
        dispatch({
          type: UPDATE_VIRTUAL_CARD_SETTINGS_SUCCESS,
          payLoad: res.data.data
        });
        toastSuccess('Virtual card monthly fee updated');
        // setTimeout(window.location.reload(), 500);
      })
      .catch(error => {
        dispatch({
          type: UPDATE_VIRTUAL_CARD_SETTINGS_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};
import {
  GET_ALL_DISCOUNTS_START,
  GET_ALL_DISCOUNTS_FAILURE,
  GET_ALL_DISCOUNTS_SUCCESS,
  GET_SINGLE_DISCOUNT_FAILURE,
  GET_SINGLE_DISCOUNT_SUCCESS,
  GET_SINGLE_DISCOUNT_START,
  NEW_DISCOUNT_START,
  NEW_DISCOUNT_SUCCESS,
  NEW_DISCOUNT_FAILURE,
  EDIT_DISCOUNT_START,
  EDIT_DISCOUNT_FAILURE,
  EDIT_DISCOUNT_SUCCESS,
  CLEAR_SINGLE_DISCOUNT_FAILURE,
  CLEAR_SINGLE_DISCOUNT_START,
  CLEAR_SINGLE_DISCOUNT_SUCCESS
} from "./types";

const initialState = {
  isLoadingDiscounts: true,
  isCreatingNewDiscount: false,
  isLoadingSingleDiscount: false,
  isEditingDiscount: false,
  allDiscounts: [],
  singleDiscount: {}
};

export default function discountReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DISCOUNTS_START:
      return {
        ...state,
        isLoadingDiscounts: true,
      };

    case GET_ALL_DISCOUNTS_FAILURE:
      return {
        ...state,
        isLoadingDiscounts: false
      };

    case GET_ALL_DISCOUNTS_SUCCESS:
      return {
        ...state,
        isLoadingDiscounts: false,
        allDiscounts: action.payLoad
      };

    case NEW_DISCOUNT_START:
      return {
        ...state,
        isCreatingNewDiscount: true
      };

    case NEW_DISCOUNT_FAILURE:
      return {
        ...state,
        isCreatingNewDiscount: false
      };

    case NEW_DISCOUNT_SUCCESS:
      return {
        ...state,
        isCreatingNewDiscount: false,
      };

    case EDIT_DISCOUNT_START:
      return {
        ...state,
        isEditingDiscount: true
      };

    case EDIT_DISCOUNT_FAILURE:
      return {
        ...state,
        isEditingDiscount: false
      };

    case EDIT_DISCOUNT_SUCCESS:
      return {
        ...state,
        isEditingDiscount: false,

      };
    case GET_SINGLE_DISCOUNT_START:
      return {
        ...state,
        isLoadingSingleDiscount: true
      };

    case GET_SINGLE_DISCOUNT_FAILURE:
      return {
        ...state,
        isLoadingSingleDiscount: false
      };

    case GET_SINGLE_DISCOUNT_SUCCESS:
      return {
        ...state,
        isLoadingSingleDiscount: false,
        singleDiscount: action.payLoad
      };

    case CLEAR_SINGLE_DISCOUNT_FAILURE:
      return {
        ...state,
        isLoadingSingleDiscount: false,
      };

    case CLEAR_SINGLE_DISCOUNT_START:
      return {
        ...state,
        isLoadingSingleDiscount: true,
      };

    case CLEAR_SINGLE_DISCOUNT_SUCCESS:
      return {
        ...state,
        isLoadingSingleDiscount: false,
        singleDiscount: action.payLoad
      };

    default:
      return state;
  }
}
import styled from "styled-components";

const CountryFlag = ({ flagCode = "NG" }) => {

  return (
    <SCFlag src={`https://flagcdn.com/96x72/${flagCode.toLowerCase()}.png`} alt="flag" />
  );
};

export default CountryFlag;

const SCFlag = styled.img`
    border-radius: 8px;
    height: 25px;
    width: 30px;
    object-fit: cover;
`;
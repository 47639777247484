export const selectStyle = {
	control: (styles) => ({
		...styles,
		// backgroundColor: "#F8F6FE",
		borderRadius: "2px",
		border: "1px solid #C2C9D1",
		color: "#47495c",
		fontSize: "14px",
	}),
	menu: (styles) => ({
		...styles,
		zIndex: 1000,
	}),
	option: (styles) => ({
		...styles,
		color: "#151515",
		fontSize: "14px",
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: "14px",
	}),
};

export const darkSelectStyle = {
	control: (styles) => ({
		...styles,
		backgroundColor: "#FBFBFB",
		borderRadius: "2px",
		border: "1px solid #E4E4F3",
		color: "#151515",
		// padding: "3px",
	}),
	menu: (styles) => ({
		...styles,
		zIndex: 1000,
	}),
	option: (styles, state) => ({
		...styles,
		backgroundColor: state.isSelected ? "#EEEEEE" : "#FFF",
		color: "#151515",
		fontSize: "14px",
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: "14px",
	}),
};

export const noBorderSelectStyle = {
	control: (styles) => ({
		...styles,
		// backgroundColor: "#F8F6FE",
		borderRadius: "0px",
		border: "1px solid #C2C9D1",
		borderLeft: "1px solid transparent",
		color: "#47495c",
		fontSize: "14px",
	}),
	menu: (styles) => ({
		...styles,
		zIndex: 1000,
	}),
	option: (styles) => ({
		...styles,
		color: "#151515",
		fontSize: "14px",
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: "14px",
	}),
};

export const noBorderDarkSelectStyle = {
	control: (styles) => ({
		...styles,
		backgroundColor: "#FBFBFB",
		borderRadius: "0px",
		// border: "1px solid #E4E4F3",
		color: "#151515",
		// padding: "3px",
	}),
	menu: (styles) => ({
		...styles,
		zIndex: 1000,
	}),
	option: (styles, state) => ({
		...styles,
		backgroundColor: state.isSelected ? "#EEEEEE" : "#FFF",
		color: "#151515",
		fontSize: "14px",
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: "14px",
	}),
};

export const noSeparatorDarkSelectStyle = {
	...noBorderDarkSelectStyle,
	indicatorSeparator: (styles) => ({
		...styles,
		backgroundColor: "none",
	}),
};

import { createContext, useState, useRef } from "react";

export const MultiSelectContext = createContext();

function MultiSelectContextProvider({ children }) {
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [selected, setSelected] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [isPersonal, setIsPersonal] = useState(false);
  const [searchRequest, setSearchRequest] = useState({});
  const inputRef = useRef();

  const contextActions = {
    input,
    isPersonal,
    inputRef,
    selected,
    searchRequest,
    suggestions,
    recipients,
    loading,
    selectOptions,
    noResults,
    setInput,
    setIsPersonal,
    setSuggestions,
    setSelected,
    setLoading,
    setSearchRequest,
    setRecipients,
    setNoResults,
    setSelectOptions
  };

  return (
    <MultiSelectContext.Provider value={contextActions}>
      {children}
    </MultiSelectContext.Provider>
  );
}

export default MultiSelectContextProvider;
import React, {useState, useEffect} from "react";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import {toTitleCase} from '../../helpers/helper';
import TabNav from "../../components/navbars/TabNav";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { PaginatedTable } from "../../components/table/Table";
import { getProvidersCategories, searchProvidersCategories } from "../../redux/providers/providersActions";


const SettlementsProvidersCategories = () => {
    // Definitions
    const location = useLocation();
    const dispatch = useDispatch();
    const { isLoadingProviderCategory, allCategory} = useSelector(state => state?.providers);
    const [currentPage, setCurrentPage] = useState(1);
    const [resetPage, setResetPage] = useState(false);
    
    // Hooks
    useEffect(() => {
        let type = location?.pathname?.split('/')[3];
        dispatch(getProvidersCategories(type));
        setResetPage(false);
    }, [dispatch, resetPage]);

    //Functions
    const handleSearchChange = (event) => {
        let type = location?.pathname?.split('/')[3]
        dispatch(searchProvidersCategories(type, event.search));
    }
    const columns = [
        {
            accessor: "provider",
            Header: "Settlements Category",
            Cell: ({ value, row }) => (
                <Link to={`/dashboard/providers/${row.original.provider_type}/categories/${row.original.provider_type_category}`}>
                    <span className="glade-normal-text-two">
                        {toTitleCase(row.original.provider_type_category ?? '')}
                    </span>
                </Link>
            ),
        },
        {
            accessor: "Code",
            Header: "Provider",
            Cell: ({ value, row }) => (
                <Link to={`/dashboard/providers/${row.original.provider_type}/categories/${row.original.provider_type_category}`}>
                    <span className="glade-normal-text-two">
                        {row.original.default}
                    </span>
                </Link>
            ),
        },
    ];
    
    return (
        <div className="p-3">
        <TabNav
            tabs={[
                { title: "Transfers", path: "/dashboard/providers/transfer/categories" },
                { title: "Settlements", path: "/dashboard/providers/settlement/categories" },
                { title: "Bills", path: "/dashboard/providers/bills/categories" },
                { title: "POS", path: "/dashboard/providers/pos/categories" },
                { title: "Virtual Cards", path: "/dashboard/providers/virtual_card/categories" },
                { title: "BVN", path: "/dashboard/providers/bvn/categories" },
                { title: "Payments", path: "/dashboard/providers/payment/categories" },

            ]}
        />
        <CardTableHeader
            title={'Categories'}
            filterButton={false}
            searchBarPlaceholder={"Search Categories"}
            onChange={(e) => {
                const value = e.target.value;
                if (value) {
                    handleSearchChange({ search: value.trim() });
                }else{
                    setResetPage(true);
                }
            }}
            defaultFilterParams={{ search: "" }}
        />
        <PaginatedTable
            columns={columns}
            data={
                typeof allCategory === "object" && !Array.isArray(allCategory)
                    ? allCategory
                    : allCategory
            }
            empty_message="No Providers to display"
            empty_sub_message=""
            current_page={currentPage}
            loading={isLoadingProviderCategory}
            setCurrentPage={setCurrentPage}
            totalPages={allCategory?.length}
        />
    </div>
    )
}

export default SettlementsProvidersCategories;
import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { Table } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FiRepeat } from "react-icons/fi";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import TabNav from "../../components/navbars/TabNav";
import {
	getAllSentNotifications,
	resendNotification,
} from "../../redux/notifications/notificationsActions";
import useNotifications from "../../hooks/useNotifications";
import Button from "../../components/buttons/Button";
import PillIndicator from "../../components/indicators/PillIndicator";
import BasicModal from "../../components/modals/BasicModal";
import { RiLoader5Fill } from "react-icons/ri";
import { TextSkeleton } from "../../components/skeletons";
import { fixDateForAllBrowsers } from "../../helpers/helper";

export default function SentNotifications() {
	const dispatch = useDispatch();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);
	const [selectedNotification, setSelectedNotification] = useState({});
	const [showResendModal, setShowResendModal] = useState(false);

	const {
		isLoadingAllSentNotifications,
		allSentNotifications,
		isSendingNotification,
		findNotificationCategory,
		isLoadingAllNotificationCategories,
		allNotificationCategories,
	} = useNotifications();

	const defaultFilterParams = {
		search: "",
		page: currentPage,
		category: "all",
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 150), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, category, search, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_from, date_to, search, category]);

	React.useMemo(() => {
		dispatch(
			getAllSentNotifications({
				params: {
					...(search
						? { search, paginate: 1, page, per_page: 10 }
						: {
								start_date: date_from,
								end_date: date_to,
								category,
								paginate: 1,
								page,
								per_page: 10,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		page,
		// per_page,
		search,
		category,
	]);

	// Clear query param on render
	// useEffect(() => {
	//   urlSearchParamsHistory.replace({});
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	React.useMemo(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	//  Resend notification
	const getNotificationInfo = (info) => {
		setSelectedNotification(info);
		setShowResendModal(true);
	};

	//  Resend notification
	const toResendNotification = ({
		title,
		body,
		notification_category_id,
		notification_type,
		start_time = null,
	}) => {
		dispatch(
			resendNotification({
				title,
				body,
				notification_category_id,
				notification_type,
				is_draft: false,
				start_time,
			})
		);
	};

	const columns = [
		{
			accessor: "title",
			Header: "Notification Title",
			Cell: ({ value, row }) => (
				// <Link to={`/dashboard/outflows/bills/detail/${row.original.id}`}>
				<span className="glade-normal-text-two capitalize">{value}</span>
				// </Link>
			),
		},
		{
			accessor: "body",
			Header: "Message",
			Cell: ({ value, row }) => (
				// <Link to={`/dashboard/outflows/bills/detail/${row.original.id}`}>
				<p className="glade-normal-text-two w-64 whitespace-normal">{value}</p>
				// </Link>
			),
		},
		{
			accessor: "notification_category_id",
			Header: "Category",
			Cell: ({ value, row }) => (
				<>
					{isLoadingAllNotificationCategories ? (
						<TextSkeleton />
					) : (
						// <Link to={`/dashboard/outflows/bills/detail/${row.original.id}`}>
						<span className="glade-normal-text-two capitalize">
							{findNotificationCategory(value)}
						</span>
						// </Link>
					)}
				</>
			),
		},
		{
			accessor: "start_time",
			Header: "Date Sent",
			Cell: ({ value, row }) => (
				// <Link to={`/dashboard/outflows/bills/detail/${row.original.id}`}>
				<span className="glade-normal-text-two">
					{dfn.format(new Date(fixDateForAllBrowsers(value)), "MMM d, yyyy hh:mm:ss a")}
				</span>
				// </Link>
			),
		},
		{
			accessor: "sent_by",
			Header: "Sent By",
			Cell: ({ value, row }) => (
				// <Link to={`/dashboard/outflows/bills/detail/${row.original.id}`}>
				<span className="glade-normal-text-two capitalize">{value ?? "-"}</span>
				// </Link>
			),
		},
		{
			accessor: "id",
			Header: "",
			Cell: ({ value, row }) => (
				<PillIndicator
					className="py-1 mx-auto capitalize cursor-pointer"
					type="neutral"
					onClick={
						isSendingNotification
							? () => {}
							: () => getNotificationInfo(row.original)
					}
					title={
						<span className="flex items-center">
							{selectedNotification?.id === value && isSendingNotification
								? "Sending"
								: "Send again"}
							{selectedNotification?.id === value && isSendingNotification ? (
								<RiLoader5Fill size={24} className="animate-spin ml-4" />
							) : (
								<FiRepeat className="ml-2" />
							)}
						</span>
					}
					// style={{
					//   marginLeft: "auto",
					//   marginRight: "auto",
					//   display: "block",
					// }}
				/>
			),
		},
	];

	return (
		<div className="p-3">
			<TabNav
				tabs={[
					{ title: "Sent Notifications", path: "/dashboard/notifications" },
					{ title: "Drafts", path: "/dashboard/notifications/drafts" },
				]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				filterFields={["customCategory", "date"]}
				customCategories={allNotificationCategories}
				filterCallback={handleChange}
				disabled={isLoadingAllSentNotifications}
				title={`Notifications ${
					!isLoadingAllSentNotifications && allSentNotifications?.length
						? "- " + allSentNotifications?.length
						: ""
				}`}
				actionButton={
					<Link to="/dashboard/notifications/new">
						<Button.Dark title={"+ New Notification"} />
					</Link>
				}
				searchBarPlaceholder={"Notification Title"}
			/>
			<Table
				columns={columns}
				data={
					typeof allSentNotifications === "object" &&
					!Array.isArray(allSentNotifications)
						? allSentNotifications?.data
						: allSentNotifications
				}
				empty_message="No notifications"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllSentNotifications}
				setCurrentPage={setCurrentPage}
			/>

			{/* Send again modal */}
			<BasicModal
				visibility={showResendModal}
				onClose={() => setShowResendModal(false)}
				onOpen={() => setShowResendModal(true)}
				primaryButton=""
				modalTitleSection=""
				secondaryButton=""
				modelContentSection={
					<div className="p-6 px-0">
						<h3 className="glade-black text-center mb-8">
							Are you sure you want to resend this notification?
						</h3>
						<Button.Dark
							onClick={() => toResendNotification(selectedNotification)}
							disabled={isSendingNotification}
							title="Send again"
							className={`glade-normal-text-three glade-bg-blue text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
						>
							{isSendingNotification ? "Sending" : "Send again"}
							{isSendingNotification && (
								<RiLoader5Fill size={24} className="animate-spin ml-4" />
							)}
						</Button.Dark>
						<button
							onClick={() => setShowResendModal(false)}
							className="glade-normal-text-three glade-black w-full mx-auto text-center"
						>
							Cancel
						</button>
					</div>
				}
			/>
		</div>
	);
}

import React, { useState } from 'react'
import AccordionContents from './AccordionContents'
import AccordionControl from './AccordionControl'
import { SCAccordion } from './styled'

const SingleAccordion = ({ contents }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => setIsOpen(open => !open)

  return (
    <SCAccordion>
      <AccordionControl
        firstName={contents?.first_name}
        lastName={contents?.last_name}
        isOpen={isOpen}
        toggleAccordion={toggleAccordion}
      />
      {isOpen && <AccordionContents contents={contents} />}
    </SCAccordion>
  )
}

export default SingleAccordion
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import { fundBusinessAccount } from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";

const FundBusinessAccountModal = ({
	modalStates,
	setModalStates,
	defaultCurrency,
	showFundAccountModal,
}) => {
	const dispatch = useDispatch();
	const { id, user_uuid } = useParams();
	const { isFundingBusinessAccount, specificBusinessAccounts, multiCurrencies } = useAccounts();
	const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency)

	const {
		handleSubmit,
		register,
		reset,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			amount: 1,
		},
	});

	const onClose = () => {
		setSelectedCurrency({})
		setModalStates({ ...modalStates, showFundAccountModal: false });
		reset({ amount: 1 })
	}

	const onOpen = () => {
		setModalStates({
			...modalStates,
			showFundAccountModal: true,
		})
	}

	const onFundBusinessAccount = (data) => {

		const payload = {
			value: data.amount,
			narration: data.remark,
			fund_type: data.fund_type,
			currency: data.currency_account.substring(0, 3),
			owner_type: "business",
			owner_uuid: id,
		};
		dispatch(fundBusinessAccount(user_uuid, id, payload, onClose));
	};

	const selectCurrency = (currency) => {
		const _currency = multiCurrencies?.accounts?.filter(multiCurrency => multiCurrency.currency === currency)[0]
		setSelectedCurrency(_currency)
	}

	const displayCurrentBalance = (pickedCurrency, defaultCurrency) => {
		if (typeof pickedCurrency?.balance === 'string') {
			if (Number(pickedCurrency?.balance) > 0) {
				return pickedCurrency?.balance
			}
		} else if (typeof pickedCurrency?.balance === 'undefined') {
			return defaultCurrency?.balance
		} else {
			return 0.00
		}
	}

	return (
		<BasicModal
			visibility={showFundAccountModal}
			onClose={onClose}
			onOpen={onOpen}
			primaryButton=""
			modalTitle="Fund Account"
			secondaryButton=""
			modelContentSection={
				<form onSubmit={handleSubmit(onFundBusinessAccount)}>
					<h4 className="glade-normal-text-two glade-black capitalize mb-3">
						Account Balance
					</h4>
					<p className="glade-blue-normal glade-heading-four">
						{selectedCurrency?.currency ?? defaultCurrency?.currency} {Number(displayCurrentBalance(selectedCurrency, defaultCurrency)).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}		
					</p>
					<div className="w-full mt-6">
						<label htmlFor="category" className="block glade-small-text-two">
							Select Balance to Fund
						</label>
						<select
							id="currency_account"
							name="currency_account"
							{...register("currency_account")}
							onChange={e => selectCurrency(e.target.value)}
							className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
						>
							 {multiCurrencies?.accounts?.map((currency, index) => {
                if (index === 1 && currency.currency === 'NGN') return null;
                return (
                  <option value={currency.currency} key={currency.currency}>
                    {currency.currency} BALANCE - {currency.currency}{" "}
                    {Number(currency.balance).toLocaleString("en-US") ?? 0}
                  </option>
                );
              })}
						</select>
						<FormError errors={errors} name="fund_type" />
					</div>

					<div className="w-full my-3">
						<label htmlFor="category" className="block glade-small-text-two">
							Transaction Type
						</label>
						<select
							id="fund_type"
							name="fund_type"
							{...register("fund_type")}
							className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
						>
							<option value="credit" className="capitalize">
								Credit
							</option>
							<option value="debit" className="capitalize">
								Debit
							</option>
						</select>
						<FormError errors={errors} name="fund_type" />
					</div>

					<div className="my-6">
						<label htmlFor="amount" className="block glade-small-text-two">
							Amount
						</label>
						<div className="relative">
							<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
								<span className="glade-black sm:text-sm py-2">
									{selectedCurrency?.currency ?? defaultCurrency?.currency}
								</span>
							</div>
							<Controller
								render={({ field: { onChange, value, ref } }) => (
									<NumberFormat
										thousandSeparator={true}
										onValueChange={(v) => {
											onChange(Number(v.value));
										}}
										variant="outlined"
										value={value}
										className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
									/>
								)}
								rules={{
									required: "Enter an amount to fund",
									min: {
										value: 1,
										message: `Minimum amount is ${selectedCurrency?.currency ?? defaultCurrency?.currency} 1`,
									},
								}}
								name="amount"
								control={control}
							/>
						</div>
						<FormError errors={errors} name="amount" />
					</div>

					<div className="">
						<label htmlFor="remark" className="block glade-small-text-two">
							Remarks
						</label>
						<textarea
							type="text"
							id="remark"
							name="remark"
							{...register("remark", {
								// required: "Enter a valid remark",
								minLength: {
									value: 2,
									message: "Enter a valid remark",
								},
							})}
							rows={4}
							placeholder=""
							className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
						/>
						<FormError errors={errors} name="remark" />
					</div>

					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={onClose}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Fund"
								className="block flex-grow-0"
								loading={isFundingBusinessAccount}
							/>
						</div>
					</div>
				</form>
			}
		/>
	);
};

export default FundBusinessAccountModal;

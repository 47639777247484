import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastSuccess } from "../../helpers/utils.toast";
import {
	TOGGLE_LOADIDNG_STATE_OFF,
	TOGGLE_LOADIDNG_STATE_ON,
} from "../shared/types";
import {
	GET_ALL_SETTLED_NGN_SETTLEMENTS_FAILURE,
	GET_ALL_SETTLED_NGN_SETTLEMENTS_START,
	GET_ALL_SETTLED_NGN_SETTLEMENTS_SUCCESS,
	GET_ALL_SETTLED_USD_SETTLEMENTS_FAILURE,
	GET_ALL_SETTLED_USD_SETTLEMENTS_START,
	GET_ALL_SETTLED_USD_SETTLEMENTS_SUCCESS,
	GET_ALL_UNSETTLED_NGN_SETTLEMENTS_FAILURE,
	GET_ALL_UNSETTLED_NGN_SETTLEMENTS_START,
	GET_ALL_UNSETTLED_NGN_SETTLEMENTS_SUCCESS,
	GET_ALL_UNSETTLED_USD_SETTLEMENTS_FAILURE,
	GET_ALL_UNSETTLED_USD_SETTLEMENTS_START,
	GET_ALL_UNSETTLED_USD_SETTLEMENTS_SUCCESS,
	GET_SPECIFIC_SETTLED_SETTLEMENT_FAILURE,
	GET_SPECIFIC_SETTLED_SETTLEMENT_START,
	GET_SPECIFIC_SETTLED_SETTLEMENT_SUCCESS,
	GET_SPECIFIC_UNSETTLED_SETTLEMENT_FAILURE,
	GET_SPECIFIC_UNSETTLED_SETTLEMENT_START,
	GET_SPECIFIC_UNSETTLED_SETTLEMENT_SUCCESS,
	PROCESS_UNSETTLED_SETTLEMENTS_FAILURE,
	PROCESS_UNSETTLED_SETTLEMENTS_START,
	PROCESS_UNSETTLED_SETTLEMENTS_SUCCESS,
} from "./types";

export const getAllSettledNGNSettlements = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_SETTLED_NGN_SETTLEMENTS_START });
		Axios.get(`settlements/settled?currency=NGN`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_SETTLED_NGN_SETTLEMENTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ALL_SETTLED_NGN_SETTLEMENTS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getAllSettledNGNSettlementsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`settlements/settled?currency=NGN`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getAllSettledUSDSettlements = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_SETTLED_USD_SETTLEMENTS_START });
		Axios.get(`settlements/settled?currency=USD`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_SETTLED_USD_SETTLEMENTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ALL_SETTLED_USD_SETTLEMENTS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getAllSettledUSDSettlementsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`settlements/settled?currency=USD`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getAllUnsettledNGNSettlements = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_UNSETTLED_NGN_SETTLEMENTS_START });
		Axios.get(`settlements/unsettled?currency=NGN`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_UNSETTLED_NGN_SETTLEMENTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ALL_UNSETTLED_NGN_SETTLEMENTS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getAllUnsettledNGNSettlementsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`settlements/unsettled?currency=NGN`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getAllUnsettledUSDSettlements = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_UNSETTLED_USD_SETTLEMENTS_START });
		Axios.get(`settlements/unsettled?currency=USD`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_UNSETTLED_USD_SETTLEMENTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ALL_UNSETTLED_USD_SETTLEMENTS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getAllUnsettledUSDSettlementsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`settlements/unsettled?currency=USD`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

/**
 *
 * @param {string} ref settlement ref
 * @param {object} params filter params
 * @returns object - settlement details
 */

export const getSpecificSettledSettlement = (ref, params) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_SETTLED_SETTLEMENT_START });
		Axios.get(`settlements/settled/${ref}`, params)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_SETTLED_SETTLEMENT_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_SETTLED_SETTLEMENT_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

/**
 *
 * @param {object} payload Object containing settlement_date,owner_uuid, type and currency
 * @param {object} params filter params
 * @returns object - settlement details
 */

export const getSpecificUnsettledSettlement = (payload, params) => {
	console.log(payload, params);
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_UNSETTLED_SETTLEMENT_START });
		Axios.post(`settlements/unsettled/details`, payload, params)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_UNSETTLED_SETTLEMENT_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_UNSETTLED_SETTLEMENT_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const processSingleUnsettledNGNSettlement = (payload) => {
	return (dispatch) => {
		dispatch({ type: PROCESS_UNSETTLED_SETTLEMENTS_START });
		Axios.post(`settlements/process`, payload)
			.then((res) => {
				dispatch({
					type: PROCESS_UNSETTLED_SETTLEMENTS_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.data);
				window.history.back();
			})
			.catch((error) => {
				dispatch({
					type: PROCESS_UNSETTLED_SETTLEMENTS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const processMultipleUnsettledNGNSettlements = (payload) => {
	return (dispatch) => {
		dispatch({ type: PROCESS_UNSETTLED_SETTLEMENTS_START });
		Axios.post(`settlements/process`, payload)
			.then((res) => {
				dispatch({
					type: PROCESS_UNSETTLED_SETTLEMENTS_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.data);
				window.location.reload();
			})
			.catch((error) => {
				dispatch({
					type: PROCESS_UNSETTLED_SETTLEMENTS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const processSingleUnsettledUSDSettlement = (payload) => {
	return (dispatch) => {
		dispatch({ type: PROCESS_UNSETTLED_SETTLEMENTS_START });
		Axios.post(`settlements/process/usd`, payload)
			.then((res) => {
				dispatch({
					type: PROCESS_UNSETTLED_SETTLEMENTS_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.data);
				window.history.back();
			})
			.catch((error) => {
				dispatch({
					type: PROCESS_UNSETTLED_SETTLEMENTS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const processMultipleUnsettledUSDSettlements = (payload) => {
	return (dispatch) => {
		dispatch({ type: PROCESS_UNSETTLED_SETTLEMENTS_START });
		Axios.post(`settlements/process/usd`, payload)
			.then((res) => {
				dispatch({
					type: PROCESS_UNSETTLED_SETTLEMENTS_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.data);
				window.location.reload();
			})
			.catch((error) => {
				dispatch({
					type: PROCESS_UNSETTLED_SETTLEMENTS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

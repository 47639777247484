import {
  GET_SUPPORTED_TRANSFER_CURRENCIES_FAILURE,
  GET_SUPPORTED_TRANSFER_CURRENCIES_START,
  GET_SUPPORTED_TRANSFER_CURRENCIES_SUCCESS,
  UPDATE_TRANSFER_CHARGE_FAILURE,
  UPDATE_TRANSFER_CHARGE_START,
  UPDATE_TRANSFER_CHARGE_SUCCESS,
  LIVE_RATE_FAILURE,
  LIVE_RATE_START,
  LIVE_RATE_SUCCESS,
  SUPPORTED_CURRENCIES_FAILURE,
  SUPPORTED_CURRENCIES_RATE_START,
  SUPPORTED_CURRENCIES_RATE_SUCCESS,
  SUPPORTED_CURRENCIES_START,
  SUPPORTED_CURRENCIES_SUCCESS,
  UPDATE_CURRENCY_RATE_FAILURE,
  UPDATE_CURRENCY_RATE_START,
  UPDATE_CURRENCY_RATE_SUCCESS,
  COUNTRIES_BY_CONTINENT_START,
  COUNTRIES_BY_CONTINENT_SUCCESS,
  COUNTRIES_BY_CONTINENT_FAILURE,
  UPDATE_CURRENCY_STATUS_START,
  UPDATE_CURRENCY_STATUS_SUCCESS,
  UPDATE_CURRENCY_STATUS_FAILURE
} from "./types";

const initialState = {
  isLoadingSupportedCurrencies: false,
  isUpdatingTransferCharge: false,
  isLoadingLiveRate: true,
  isUpdatingCurrencyRate: false,
  isRateUpdated: false,
  isLoadingCountries: false,
  isLoadingCurrencyStatusUpdate: false,
  supportedCurrencies: [],
  supportedCurrenciesRates: [],
  supportedCurrenciesTransfer: [],
  countriesByContinent: [],
  liveRate: null
};

export default function crossBorderReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUPPORTED_TRANSFER_CURRENCIES_FAILURE:
      return {
        ...state,
        isLoadingSupportedCurrencies: false
      };
    case GET_SUPPORTED_TRANSFER_CURRENCIES_START:
      return {
        ...state,
        isLoadingSupportedCurrencies: true,
      };

    case GET_SUPPORTED_TRANSFER_CURRENCIES_SUCCESS:
      return {
        ...state,
        isLoadingSupportedCurrencies: false,
        supportedCurrenciesTransfer: action.payLoad
      };

    case UPDATE_TRANSFER_CHARGE_FAILURE:
      return {
        ...state,
        isUpdatingTransferCharge: false
      };

    case UPDATE_TRANSFER_CHARGE_START:
      return {
        ...state,
        isUpdatingTransferCharge: true,
      };

    case UPDATE_TRANSFER_CHARGE_SUCCESS:
      return {
        ...state,
        isUpdatingTransferCharge: false,
      };
    case SUPPORTED_CURRENCIES_START:
      return {
        ...state,
        isLoadingSupportedCurrencies: true,
      };

    case SUPPORTED_CURRENCIES_SUCCESS:
      return {
        ...state,
        isLoadingSupportedCurrencies: false,
        supportedCurrencies: action.payLoad.data
      };

    case SUPPORTED_CURRENCIES_FAILURE:
      return {
        ...state,
        isLoadingSupportedCurrencies: false,
        supportedCurrencies: []
      };

    case SUPPORTED_CURRENCIES_RATE_START:
      return {
        ...state,
        isLoadingSupportedCurrenciesRates: true,
      };

    case SUPPORTED_CURRENCIES_RATE_SUCCESS:
      return {
        ...state,
        isLoadingSupportedCurrenciesRates: false,
        supportedCurrenciesRates: action.payLoad.data
      };

    case LIVE_RATE_START:
      return {
        ...state,
        isLoadingLiveRate: true,
      };

    case LIVE_RATE_SUCCESS:
      return {
        ...state,
        liveRate: action.payLoad.data
      };

    case LIVE_RATE_FAILURE:
      return {
        ...state,
        liveRate: null
      };

    case UPDATE_CURRENCY_RATE_START:
      return {
        ...state,
        isUpdatingCurrencyRate: true,
        isRateUpdated: false,
      };

    case UPDATE_CURRENCY_RATE_SUCCESS:
      return {
        ...state,
        isUpdatingCurrencyRate: false,
        isRateUpdated: true,
      };

    case UPDATE_CURRENCY_RATE_FAILURE:
      return {
        ...state,
        isUpdatingCurrencyRate: false,
        isRateUpdated: false,
      };

    case COUNTRIES_BY_CONTINENT_START:
      return {
        ...state,
        isLoadingCountries: true,
        countriesByContinent: []
      };

    case COUNTRIES_BY_CONTINENT_SUCCESS:
      return {
        ...state,
        isLoadingCountries: false,
        countriesByContinent: action.payLoad.data
      };

    case COUNTRIES_BY_CONTINENT_FAILURE:
      return {
        ...state,
        isLoadingCountries: false,
        countriesByContinent: []
      };

    case UPDATE_CURRENCY_STATUS_START:
      return {
        ...state,
        isLoadingCurrencyStatusUpdate: true,
      }

    case UPDATE_CURRENCY_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingCurrencyStatusUpdate: false,
      }

    case UPDATE_CURRENCY_STATUS_FAILURE:
      return {
        ...state,
        isLoadingCurrencyStatusUpdate: false,
      }

    default:
      return state;

  }
}
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

export default function BreadCrumbs({ title, icon, path, goBack }) {
  let history = useHistory();
  return (
    <>
      {goBack ? (
        <div
          className="inline-flex items-center justify-start glade-subtitle-two glade-black cursor-pointer"
          onClick={() => history.goBack()}
        >
          {icon ?? <IoIosArrowBack />}{" "}
          <span className="ml-2 capitalize">{title ?? "Title"}</span>
        </div>
      ) : (
        <Link
          className="inline-flex items-center justify-start glade-subtitle-two glade-black"
          to={path}
        >
          {icon ?? <IoIosArrowBack />}{" "}
          <span className="ml-2">{title ?? "Title"}</span>
        </Link>
      )}
    </>
  );
}

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export function jsonToCSVDownload(
  data,
  fileName,
  {
    fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    extension = ".xlsx",
  } = {}
) {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const file = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(file, fileName + extension);
}

export function csvWorkbookDownload(
  wb,
  fileName,
  {
    fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    extension = ".xlsx",
  } = {}
) {
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const file = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(file, fileName + extension);
}

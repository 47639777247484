import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as dfn from "date-fns";
import Button from "../../components/buttons/Button";
import { PopoverButton, PopoverMain } from "../../components/popovers/Popover";
import { FormError } from "../../validation";
import { useDispatch } from "react-redux";
import { getAllUserReferral } from "../../redux/referrals/referralActions";
import { MdArrowDropDown } from "react-icons/md";

const ReferralFilter = ({ parameters, setParameters }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("all-time");
  const closeModal = useRef('');

  useEffect(() => {
    dispatch(
      getAllUserReferral(parameters),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters])


  const getDateRange = (filter) => {
    switch (filter.date) {
      case "all-time":
        setParameters({
          ...parameters,
          from: '',
          to: ''
        });
        break;

      case "last-7-days":
        setParameters({
          ...parameters,
          from: dfn.lightFormat(
            dfn.subWeeks(new Date(), 1),
            "yyyy-MM-dd",
          ),
          to: dfn.lightFormat(new Date(), "yyyy-MM-dd")
        });
        break;

      case "last-30-days":
        setParameters({
          ...parameters,
          from: dfn.lightFormat(
            dfn.subDays(new Date(), 30),
            "yyyy-MM-dd",
          ),
          to: dfn.lightFormat(new Date(), "yyyy-MM-dd")
        });
        break;

      case "last-90-days":
        setParameters({
          ...parameters,
          from: dfn.lightFormat(
            dfn.subDays(new Date(), 90),
            "yyyy-MM-dd",
          ),
          to: dfn.lightFormat(new Date(), "yyyy-MM-dd")
        });
        break;

      case "last-year":
        setParameters({
          ...parameters,
          from: dfn.lightFormat(
            dfn.subYears(new Date(), 1),
            "yyyy-MM-dd",
          ),
          to: dfn.lightFormat(new Date(), "yyyy-MM-dd")
        });
        break;

      case "custom date":
        setParameters({
          ...parameters,
          from: filter.from,
          to: filter.to
        });
        break;

      default:
        setParameters({
          ...parameters,
          from: dfn.lightFormat(
            dfn.subWeeks(new Date(), 1),
            "yyyy-MM-dd",
          ),
          to: dfn.lightFormat(new Date(), "yyyy-MM-dd")
        });
        break;
    }

    dispatch(
      getAllUserReferral(parameters),
    );
    closeModal.current.click();
  };

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dateSelect = watch("date");

  const onFilter = (data) => {
    setFilter(data.date);
    getDateRange(data);
  };

  return (
    <PopoverMain
      button={
        <>
          <span className="flex items-center glade-normal-text-two glade-ash cursor-pointer capitalize" ref={closeModal}>
            {filter !== 'custom date' ? filter.split('-').join(' ') : filter}
            <MdArrowDropDown className="inline-block text-2xl" />
          </span>
        </>
      }
      popoverClassName="right-0"
    >
      <form
        onSubmit={handleSubmit(onFilter)}
        className="flex flex-col p-6 bg-white shadow rounded-sm w-72 right-0"
      >
        <div className="w-full my-3">
          <label htmlFor="date" className="block glade-small-text-two">
            Date
          </label>
          <select
            id="date"
            name="date"
            defaultValue={filter}
            {...register("date")}
            className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
          >
            <option value="all-time">All Time</option>
            <option value="last-7-days">Last 7 days</option>
            <option value="last-30-days">Last 30 days</option>
            <option value="last-90-days">Last 90 days</option>
            <option value="last-year">Last Year</option>
            <option value="custom date">Custom Date</option>
          </select>
          <FormError errors={errors} name="date" />
        </div>
        {dateSelect === "custom date" && (
          <div className="grid grid-cols-2 gap-x-3">
            <div className="">
              <label htmlFor="from" className="block glade-small-text-two">
                From
              </label>
              <input
                type="date"
                id="from"
                name="from"
                {...register("from", {
                  required: "Select a start date",
                })}
                className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
              />
              <FormError errors={errors} name="from" />
            </div>
            <div className="">
              <label htmlFor="to" className="block glade-small-text-two">
                To
              </label>
              <input
                type="date"
                id="to"
                name="to"
                {...register("to", {
                  required: "Select an end date",
                })}
                className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
              />
              <FormError errors={errors} name="to" />
            </div>
          </div>
        )}
        <div className="flex flex-row items-center justify-between mt-9">
          <PopoverButton>
            <span className="glade-ash mx-1 cursor-pointer">Cancel</span>
          </PopoverButton>
          <div className="mx-2">
            <Button.Dark type="submit" title={"Filter"} />
          </div>
        </div>
      </form>
    </PopoverMain>
  );
};

export default ReferralFilter;

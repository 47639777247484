import React from 'react'
import { formatTitle } from '../../helpers/helper';
import { Content } from "./styled";

const TextContent = ({ data, fieldName, dynamic, formatTitle }) => {
	return (
		<Content className={"flex flex-col"}>
			<h2 className={"name"}>
				{dynamic ? formatTitle(fieldName) : fieldName}
			</h2>
			<p className='capitalize'>{data ?? 'No Data'}</p>
		</Content>
	)
}

const DocumentContent = ({ fileUrl, fieldName, formatTitle }) => {
	return (
		<Content className={"flex flex-col"}>
			{/* <h2 className={"name"}>{formatTitle(data.title)}</h2> */}
			<h2 className={"name"}>
				{fieldName.split("_").join(" ") ?? '-'}
			</h2>
			<a
				href={fileUrl ?? ''}
				target="_blank"
				rel="noreferrer noopener"
			>
				<h6 className="glade-normal-text-three glade-blue py-1 cursor-pointer capitalize">
					view document
				</h6>
			</a>
		</Content>
	)
}

const AccordionContent = ({ data, fieldName, fileUrl, dynamic }) => {
	return (
		<React.Fragment>
			{
				!fileUrl ? (
					<TextContent
						data={data}
						fieldName={fieldName}
						formatTitle={formatTitle}
						dynamic
					/>
				) : (
					<DocumentContent
						fileUrl={fileUrl}
						fieldName={fieldName}
						formatTitle={formatTitle}
					/>
				)
			}
		</React.Fragment>
	)
}

export default AccordionContent
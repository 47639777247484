import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import {
  GET_ALL_DISCOUNTS_START,
  GET_ALL_DISCOUNTS_FAILURE,
  GET_ALL_DISCOUNTS_SUCCESS,
  GET_SINGLE_DISCOUNT_FAILURE,
  GET_SINGLE_DISCOUNT_SUCCESS,
  GET_SINGLE_DISCOUNT_START,
  NEW_DISCOUNT_START,
  NEW_DISCOUNT_FAILURE,
  NEW_DISCOUNT_SUCCESS,
  EDIT_DISCOUNT_START,
  EDIT_DISCOUNT_SUCCESS,
  EDIT_DISCOUNT_FAILURE,
  TERMINATE_DISCOUNT_START,
  TERMINATE_DISCOUNT_SUCCESS,
  TERMINATE_DISCOUNT_FAILURE,
  ACTIVATE_DISCOUNT_START,
  ACTIVATE_DISCOUNT_FAILURE,
  ACTIVATE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_START,
  DELETE_DISCOUNT_FAILURE,
  DELETE_DISCOUNT_SUCCESS,
  CLEAR_SINGLE_DISCOUNT_SUCCESS,
  // CLEAR_SINGLE_DISCOUNT_FAILURE,
  // CLEAR_SINGLE_DISCOUNT_START
} from './types';
import { toastSuccess, toastWarning, toastError } from "../../helpers/utils.toast";

export const getAllDiscounts = params => {
  return (dispatch) => {
    dispatch({ type: GET_ALL_DISCOUNTS_START });
    Axios.get(`transfer-discount`)
      .then(res => {
        dispatch({
          type: GET_ALL_DISCOUNTS_SUCCESS,
          payLoad: res.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_ALL_DISCOUNTS_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};

export const newDiscount = (data) => {
  return (dispatch) => {
    dispatch({ type: NEW_DISCOUNT_START });
    Axios.post(`transfer-discount`, data)
      .then(res => {
        dispatch({
          type: NEW_DISCOUNT_SUCCESS,
          payLoad: res.data.data
        });
        toastSuccess("New discount created");
        window.location.replace("/dashboard/discounts");
      })
      .catch(error => {
        dispatch({
          type: NEW_DISCOUNT_FAILURE,
          payLoad: error
        });
        handler(error);
        toastError(error.response.data.error);
      });
  };
};

export const editDiscount = (id, data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_DISCOUNT_START });
    toastWarning("Updating discount");
    Axios.put(`transfer-discount/${id}`, data)
      .then(res => {
        dispatch({
          type: EDIT_DISCOUNT_SUCCESS,
          payLoad: res.data.data
        });
        // PLACE TOAST HERE
        toastSuccess("Discount updated");
        window.history.back();
        dispatch(clearSingleDiscount());
      })
      .catch(error => {
        dispatch({
          type: EDIT_DISCOUNT_FAILURE,
          payLoad: error
        });
        handler(error);
        toastError(error.response.data.error);
      });
  };
};

export const terminateDiscount = (id) => {
  return (dispatch) => {
    dispatch({ type: TERMINATE_DISCOUNT_START });
    toastWarning("Discount being terminated");
    Axios.get(`transfer-discount/${id}/terminate`)
      .then(res => {
        dispatch({
          type: TERMINATE_DISCOUNT_SUCCESS,
          payLoad: res.data.data
        });
        // fix in TODO
        toastSuccess("Discount terminated");
        window.location.reload();

      })
      .catch(error => {
        dispatch({
          type: TERMINATE_DISCOUNT_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};

export const activateDiscount = (id) => {
  return (dispatch) => {
    dispatch({ type: ACTIVATE_DISCOUNT_START });
    toastWarning("Discount being activated...");
    Axios.get(`transfer-discount/${id}/activate`)
      .then(res => {
        dispatch({
          type: ACTIVATE_DISCOUNT_SUCCESS,
          payLoad: res.data.data
        });
        // fix in TODO
        toastSuccess("Discount activated");
        window.location.reload();
      })
      .catch(error => {
        dispatch({
          type: ACTIVATE_DISCOUNT_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};

export const deleteDiscount = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_DISCOUNT_START });
    toastWarning("Discount being deleted...");
    Axios.get(`transfer-discount/${id}/delete`)
      .then(res => {
        dispatch({
          type: DELETE_DISCOUNT_SUCCESS,
          payLoad: res.data.data
        });
        // PLACE TOAST HERE
        toastSuccess("Discount deleted");
        window.location.replace("/dashboard/discounts");
        // window.location.reload();
      })
      .catch(error => {
        dispatch({
          type: DELETE_DISCOUNT_FAILURE,
          payLoad: error
        });
        toastError("Discount not deleted");
        handler(error);
      });
  };
};

export const getSingleDiscount = (id) => {
  return dispatch => {
    dispatch({ type: GET_SINGLE_DISCOUNT_START });
    Axios.get(`transfer-discount/${id}`)
      .then(res => {
        dispatch({
          type: GET_SINGLE_DISCOUNT_SUCCESS,
          payLoad: res.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_SINGLE_DISCOUNT_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};

export const clearSingleDiscount = () => {
  return dispatch => {
    dispatch({ type: CLEAR_SINGLE_DISCOUNT_SUCCESS, payLoad: {} });
  };
};
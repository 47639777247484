import React, { Fragment } from 'react';

export default function BasicIndicator({ title, type, style = {} }) {
    return (
        <Fragment>
            {type === 'success' ?
                <div className="p-3 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-green-100 glade-green capitalize " style={style}>
                    <span className="glade-green-light">{title}</span>
                </div>
                : null}
            {type === 'warning' ?
                <div className="p-3 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-yellow-100 glade-yellow capitalize " style={style}>
                    <span className="glade-yellow">{title}</span>
                </div>
                : null}
            {type === 'inactive' ?
                <div className="p-4 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-black-100 glade-black capitalize " style={style}>
                    <span className="glade-black">{title}</span>
                </div>
                : null}
            {type === 'neutral' ?
                <div className="p-3 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-black-100 glade-black capitalize " style={style}>
                    <span className="glade-black">{title}</span>
                </div>
                : null}
            {type === 'danger' ?
                <div className="p-3 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-red-100 glade-red capitalize " style={style}>
                    <span className="glade-red">{title}</span>
                </div>
                : null}
        </Fragment>
    );
}

import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { TextSkeleton } from "../../components/skeletons";

import useSettlements from "../../hooks/useSettlements";
import { getSpecificSettledSettlement } from "../../redux/settlements/settlementsActions";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import { FiCopy } from "react-icons/fi";
import { copyTextToClipboard, fixDateForAllBrowsers, truncateRef } from "../../helpers/helper";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import { Table } from "../../components/table/Table";

export default function SettledSettlementDetail() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(0);

	const {
		isLoadingSpecificSettledSettlement: loading,
		specificSettledSettlement: details,
	} = useSettlements();

	const filterInstance = usePaginationFilterParams({
		initialFilters: {
			search: "",
			date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
			date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
		},
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, search } = filters;

	const [, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_from, date_to, search]);

	React.useMemo(() => {
		dispatch(
			getSpecificSettledSettlement(id, {
				params: {
					...(search
						? { search }
						: {
								date_from,
								date_to,
								// paginate: 1,
								// page,
								// per_page,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		// page,
		// per_page,
		search,
	]);

	// // Clear query param on render
	// useEffect(() => {
	//   urlSearchParamsHistory.replace({});
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const resetReferenceSearch = (event) => {
		event?.preventDefault();
		urlSearchParamsHistory.replace({});
	};

	return (
		<div className="p-3 flex flex-col">
			<div className="mb-6">
				<BreadCrumbs title={"Settlements"} goBack />
			</div>

			<div className="flex flex-col bg-white rounded-sm p-8 py-6 mt-6 mb-8">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">
						Settlement Details
					</p>
				</div>
				<div className="grid grid-cols-4 gap-4 py-3">
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Name
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.payout_info?.[0]?.account_name}
							</h6>
						)}
					</div>
					{details?.payout_info?.[0]?.payout_reference && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Payout Reference
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<div className="flex items-center">
									<h6 className="glade-normal-text-three glade-black py-1">
										{truncateRef(details?.payout_info?.[0]?.payout_reference)}
									</h6>
									<FiCopy
										size={16}
										color="#AFAFAF"
										className="ml-3 cursor-pointer"
										onClick={() =>
											copyTextToClipboard(
												details?.payout_info?.[0]?.payout_reference
											)
										}
									/>
								</div>
							)}
						</div>
					)}
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.payout_info?.[0]?.currency ?? "NGN"}{" "}
								{Number(
									details?.payout_info?.[0]?.amount ?? 0
								)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
							</h6>
						)}
					</div>
					{details?.payout_info?.[0]?.no_of_txns && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Number of Txns
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{details?.payout_info?.[0]?.no_of_txns}
								</h6>
							)}
						</div>
					)}
					{details?.payout_info?.[0]?.settlement_date && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Settlement Date
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1 capitalize">
									{dfn.format(
										new Date(details?.payout_info?.[0]?.settlement_date),
										"MMM d, yyyy"
									)}
								</h6>
							)}
						</div>
					)}
				</div>
			</div>

			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				filterFields={["date"]}
				filterCallback={handleChange}
				disabled={loading}
				title={`Settlements ${
					!loading && details?.transactions?.length
						? "- " + details?.transactions?.length
						: ""
				}`}
				fileName="Settled Settlements"
				downloadAsCSV
				getWorksheets={({ xlsx }) => {
					return {
						data: xlsx.utils.json_to_sheet(
							details?.transactions?.map((value, i) => ({
								"S/N": `${i + 1}`,
								"Account Name": value?.account_name,
								"Payout Ref": value?.payout_reference,
								Amount: value?.total_amount,
								"Number of Txns": value?.no_of_txns,
								"Settlement Date": value?.settlement_date,
							}))
						),
					};
				}}
				searchBarPlaceholder={"Account Name or Payout Reference"}
			/>
			<Table
				columns={columns}
				data={
					typeof details?.transactions === "object" &&
					!Array.isArray(details?.transactions)
						? details?.transactions?.data
						: details?.transactions
				}
				empty_message="No transactions"
				empty_sub_message=""
				current_page={currentPage}
				loading={loading}
				setCurrentPage={setCurrentPage}
			/>
		</div>
	);
}

const columns = [
	{
		accessor: "txn_ref",
		Header: "Transaction Reference",
		Cell: ({ value, row }) => (
			<div className="flex items-center">
				<span className="glade-normal-text-two">{truncateRef(value)}</span>
				<FiCopy
					size={16}
					color="#AFAFAF"
					className="ml-3 cursor-pointer"
					onClick={() => copyTextToClipboard(value)}
				/>
			</div>
		),
	},
	{
		accessor: "total_amount",
		Header: "Amount",
		Cell: ({ value, row }) => (
			<span className="glade-normal-text-two">
				{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
			</span>
		),
	},
	{
		accessor: "fee",
		Header: "Fees",
		Cell: ({ value, row }) => (
			<span className="glade-normal-text-two">
				{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
			</span>
		),
	},
	{
		accessor: "amount_paid",
		Header: "Amount Paid Out",
		Cell: ({ value, row }) => (
			<span className="glade-normal-text-two">
				{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
			</span>
		),
	},
	{
		accessor: "transaction_date",
		Header: "Transaction Date",
		Cell: ({ value, row }) => (
			<span className="glade-normal-text-two">
				{dfn.format(new Date(fixDateForAllBrowsers(value)), "MMM d, yyyy hh:mm:ss a")}
			</span>
		),
	},
];

import { useSelector } from "react-redux";

const useReferral = () => {
	const referrals = useSelector((state) => state.referrals);

	const {
		isLoadingReferrals,
		isLoadingReferralConfiguration,
		allReferrals,
		referralConfiguration,
    isLoadingSubmitRegConfig
	} = referrals;

	return {
		isLoadingReferrals,
		isLoadingReferralConfiguration,
		allReferrals,
		referralConfiguration,
    isLoadingSubmitRegConfig
	};
};

export default useReferral;


import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import {
  GET_ALL_AUDIT_LOGS_START,
  GET_ALL_AUDIT_LOGS_SUCCESS,
  GET_SPECIFIC_AUDIT_LOG_FAILURE,
  GET_ALL_AUDIT_LOGS_FAILURE,
  GET_SPECIFIC_AUDIT_LOG_START,
  GET_SPECIFIC_AUDIT_LOG_SUCCESS
} from "./types";

export const getAllAuditLogs = params => {
  return dispatch => {
    dispatch({ type: GET_ALL_AUDIT_LOGS_START });
    Axios.get(`audit-log`, params)
      .then(res => {
        dispatch({
          type: GET_ALL_AUDIT_LOGS_SUCCESS,
          payLoad: res.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_ALL_AUDIT_LOGS_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};

// Now we can add individual audit logs!
export const getSpecificAuditLog = (uuid, params) => {
  return dispatch => {
    dispatch({ type: GET_SPECIFIC_AUDIT_LOG_START });
    Axios.get(`audit-log/uuid/${uuid}`, params)
      .then(res => {
        dispatch({
          type: GET_SPECIFIC_AUDIT_LOG_SUCCESS,
          payLoad: res.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_SPECIFIC_AUDIT_LOG_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};
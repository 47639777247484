import {
  GET_MULTI_CURRENCY_CHARGES_FAILURE,
  GET_MULTI_CURRENCY_CHARGES_START,
  GET_MULTI_CURRENCY_CHARGES_SUCCESS,
  UPDATE_MULTI_CURRENCY_CHARGE_FAILURE,
  UPDATE_MULTI_CURRENCY_CHARGE_START,
  UPDATE_MULTI_CURRENCY_CHARGE_SUCCESS
} from './types';

const initialState = {
  isLoadingMultiCurrencyCharges: false,
  isUpdatingMultiCurrencyCharges: false,
  multiCurrencyCharges: [{}]
};

export default function multiCurrencyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MULTI_CURRENCY_CHARGES_START:
      return {
        isLoadingMultiCurrencyCharges: true
      };

    case GET_MULTI_CURRENCY_CHARGES_SUCCESS:
      return {
        isLoadingMultiCurrencyCharges: false,
        multiCurrencyCharges: action.payLoad
      };

    case GET_MULTI_CURRENCY_CHARGES_FAILURE:
      return {
        isLoadingMultiCurrencyCharges: false
      };

    case UPDATE_MULTI_CURRENCY_CHARGE_START:
      return {
        isUpdatingMultiCurrencyCharges: true
      };

    case UPDATE_MULTI_CURRENCY_CHARGE_SUCCESS:
      return {
        isUpdatingMultiCurrencyCharges: false
      };

    case UPDATE_MULTI_CURRENCY_CHARGE_FAILURE:
      return {
        isUpdatingMultiCurrencyCharges: false
      };
    default:
      return state;
  }
}
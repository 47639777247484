import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useCrossBorder from "../../hooks/useCrossBorder";
import { getSingleLiveRate, updateCurrencyRate } from "../../redux/cross-border/crossBorderActions";

const EditCurrencyRateModal = ({
	modalStates,
	setModalStates,
	showEditModal,
	selectedFee,
	setSelectedFee,
}) => {
	const dispatch = useDispatch();
	const [added_fee_type, set_added_fee_type] = useState('fixedFee');
	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm();

	const { isUpdatingCurrencyRate, isRateUpdated } = useCrossBorder();
	const { liveRate } = useCrossBorder();

	useEffect(() => {
		dispatch(getSingleLiveRate(selectedFee?.source_currency, selectedFee?.destination_currency));
	}, [])

	const onUpdateFee = (data) => {

		const payload = {
			added_fee_type,
			source_currency: selectedFee?.source_currency,
			destination_currency: selectedFee?.destination_currency,
			added_fee: data.added_fee
		};
		dispatch(updateCurrencyRate(payload, selectedFee?.currentPage, onSuccess));
	};

	const onSuccess = () => {
		reset();
		setModalStates({
			...modalStates,
			showEditModal: false,
		});
		setSelectedFee({});
	}

	const onChange = (e) => set_added_fee_type(e.target.value);

	return (
		<BasicModal
			visibility={showEditModal}
			onClose={() => {
				reset();
				setModalStates({ ...modalStates, showEditModal: false });
				setSelectedFee({});
			}}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditModal: true,
				})
			}
			primaryButton=""
			modalTitle={`Edit Multi currency Rate (${selectedFee?.source_currency?.toLocaleUpperCase()})`}
			secondaryButton=""
			modelContentSection={
				<>
					<form onSubmit={handleSubmit(onUpdateFee)} className="">
						<div className="mb-4">
							{/* Fixed amount */}
							{(
								<>
									<label
										htmlFor="amount"
										className="block glade-small-text-two capitalize"
									>
										Live Rate ({`${selectedFee?.destination_currency?.toLocaleUpperCase()}`})
									</label>
									<div className="mt-2">
										<div className="">
											<div className="relative">

												{liveRate && <input
													type="number"
													id="amount"
													name="live_rate"
													disabled
													value={
														liveRate?.currencies[`${selectedFee?.destination_currency?.toLocaleUpperCase()}`] ?
															liveRate?.currencies[`${selectedFee?.destination_currency?.toLocaleUpperCase()}`]
															: 'Please wait'
													}
													className="mt-1 block w-full py-2 border-gray-300 rounded-sm glade-normal-text-two"
												/>
												}
											</div>
										</div>
									</div>
								</>
							)}

							{/* Percent */}

							<>
								<label
									htmlFor="added_fee_type"
									className="block glade-small-text-two capitalize mt-4"
								>
									Glade Rate
								</label>
								<div className="flex items-center mt-2">
									<div className="w-1/2">
										<div className="relative">
											<select
												id="added_fee_type"
												name="added_fee_type"
												value={added_fee_type}
												onChange={onChange}
												defaultValue={
													added_fee_type
												}
												placeholder="Enter an amount"
												className="mt-1 block w-full py-2 border-gray-300 rounded-sm glade-normal-text-two"
											>
												<option value={'fixedFee'}>Fixed Fee</option>
											</select>
										</div>
									</div>
									<div className="w-1/2">
										<div className="relative">
											{added_fee_type === 'percentage' &&
												<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
													<span className="glade-black sm:text-sm py-2">%</span>
												</div>
											}
											<input
												type="number"
												id="added_fee"
												name="added_fee"
												{...register("added_fee", {
													required: `Enter a valid ${added_fee_type === 'percentage' ? 'percentage' : 'number'}`,
													min: {
														value: 0.000000000000000,
														message: `Enter a valid ${added_fee_type === 'percentage' ? 'percentage' : 'number'}`,
													},
													max: {
														value: added_fee_type === 'percentage' ? 100 : 100000,
														message: `Enter a valid number`,
													},
												})}
												step="0.000000000000001"
												defaultValue={selectedFee?.value ?? ''}
												placeholder={`Enter a number`}
												className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
											/>
										</div>
									</div>
								</div>
							</>


							<div className="flex flex-row-reverse">
								<div className="w-1/2">
									<FormError errors={errors} name="added_fee" />
								</div>
							</div>
						</div>

						<div className="flex items-center justify-between -mt-2 pb-8 border-b border-solid border-gray-200">
						</div>

						<div className="flex -mb-8 items-center justify-between p-4  -mx-5">
							<button
								type="button"
								onClick={() => {
									reset();
									setModalStates({
										...modalStates,
										showEditModal: false,
									});
									setSelectedFee({});
								}}
								className="glade-normal-text-two glade-black text-center"
							>
								Cancel
							</button>
							<div>
								<Button.Dark
									type="submit"
									title="Update"
									className="block flex-grow-0"
									disabled={!liveRate}
									loading={(isUpdatingCurrencyRate) ? isUpdatingCurrencyRate : undefined}
								/>
							</div>
						</div>
					</form>
				</>
			}
		/>
	);
};

export default EditCurrencyRateModal;

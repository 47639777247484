import React from 'react';
import {FaCaretDown} from 'react-icons/fa';

export default function ButtonWithIconRight({title, style, className, onClick=()=>{}, icon}) {
    return (
        <button 
            type={"button"} 
            style={style??{}}
            onClick={()=>onClick()}
            className={"glade-normal-text-three glade-button-blue w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm "+className}>
            {title??'Title'}
            <span className="ml-1">{icon ?? <FaCaretDown/>}</span>
        </button>
    )
}

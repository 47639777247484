export const GET_ALL_TRANSACTIONS_START = "GET_ALL_TRANSACTIONS_START";
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";
export const GET_ALL_TRANSACTIONS_FAILURE = "GET_ALL_TRANSACTIONS_FAILURE";
export const GET_PAYROLL_SALARY_ADVANCE_START = "GET_PAYROLL_SALARY_ADVANCE_START";
export const GET_PAYROLL_SALARY_ADVANCE_SUCCESS = "GET_PAYROLL_SALARY_ADVANCE_SUCCESS";
export const GET_PAYROLL_SALARY_ADVANCE_FAILURE = "GET_PAYROLL_SALARY_ADVANCE_FAILURE";

export const GET_ADVANCE_PAYROLL_DETAIL_START = "GET_ADVANCE_PAYROLL_DETAIL_START";
export const GET_ADVANCE_PAYROLL_DETAIL_SUCCESS = "GET_ADVANCE_PAYROLL_DETAIL_SUCCESS";
export const GET_ADVANCE_PAYROLL_DETAIL_FAILURE = "GET_ADVANCE_PAYROLL_DETAIL_FAILURE";

export const  REJECT_ADVANCE_DETAIL_START = " REJECT_ADVANCE_DETAIL_START";
export const  REJECT_ADVANCE_DETAIL_SUCCESS = " REJECT_ADVANCE_DETAIL_SUCCESS";
export const  REJECT_ADVANCE_DETAIL_FAILURE = " REJECT_ADVANCE_DETAIL_FAILURE";

export const  APPROVED_ADVANCE_DETAIL_START = " APPROVED_ADVANCE_DETAIL_START";
export const  APPROVED_ADVANCE_DETAIL_SUCCESS = " APPROVED_ADVANCE_DETAIL_SUCCESS";
export const  APPROVED_ADVANCE_DETAIL_FAILURE = " APPROVED_ADVANCE_DETAIL_FAILURE";

export const GET_SPECIFIC_TRANSACTIONS_START =
	"GET_SPECIFIC_TRANSACTIONS_START";
export const GET_SPECIFIC_TRANSACTIONS_SUCCESS =
	"GET_SPECIFIC_TRANSACTIONS_SUCCESS";
export const GET_SPECIFIC_TRANSACTIONS_FAILURE =
	"GET_SPECIFIC_TRANSACTIONS_FAILURE";

export const GET_ALL_TRANSFER_TRANSACTIONS_START =
	"GET_ALL_TRANSFER_TRANSACTIONS_START";
export const GET_ALL_TRANSFER_TRANSACTIONS_SUCCESS =
	"GET_ALL_TRANSFER_TRANSACTIONS_SUCCESS";
export const GET_ALL_TRANSFER_TRANSACTIONS_FAILURE =
	"GET_ALL_TRANSFER_TRANSACTIONS_FAILURE";

export const GET_SPECIFIC_TRANSFER_TRANSACTIONS_START =
	"GET_SPECIFIC_TRANSFER_TRANSACTIONS_START";
export const GET_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS =
	"GET_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS";
export const GET_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE =
	"GET_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE";

export const VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_START =
	"VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_START";
export const VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS =
	"VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS";
export const VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE =
	"VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE";

export const REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_START =
	"REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_START";
export const REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS =
	"REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS";
export const REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE =
	"REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE";

export const GET_ALL_BILLS_TRANSACTIONS_START =
	"GET_ALL_BILLS_TRANSACTIONS_START";
export const GET_ALL_BILLS_TRANSACTIONS_SUCCESS =
	"GET_ALL_BILLS_TRANSACTIONS_SUCCESS";
export const GET_ALL_BILLS_TRANSACTIONS_FAILURE =
	"GET_ALL_BILLS_TRANSACTIONS_FAILURE";

export const GET_SPECIFIC_BILLS_TRANSACTIONS_START =
	"GET_SPECIFIC_BILLS_TRANSACTIONS_START";
export const GET_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS =
	"GET_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS";
export const GET_SPECIFIC_BILLS_TRANSACTIONS_FAILURE =
	"GET_SPECIFIC_BILLS_TRANSACTIONS_FAILURE";

export const VERIFY_SPECIFIC_BILLS_TRANSACTIONS_START =
	"VERIFY_SPECIFIC_BILLS_TRANSACTIONS_START";
export const VERIFY_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS =
	"VERIFY_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS";
export const VERIFY_SPECIFIC_BILLS_TRANSACTIONS_FAILURE =
	"VERIFY_SPECIFIC_BILLS_TRANSACTIONS_FAILURE";

export const REVERT_SPECIFIC_BILLS_TRANSACTIONS_START =
	"REVERT_SPECIFIC_BILLS_TRANSACTIONS_START";
export const REVERT_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS =
	"REVERT_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS";
export const REVERT_SPECIFIC_BILLS_TRANSACTIONS_FAILURE =
	"REVERT_SPECIFIC_BILLS_TRANSACTIONS_FAILURE";

export const GET_ALL_PAYROLL_TRANSACTIONS_START =
	"GET_ALL_PAYROLL_TRANSACTIONS_START";
export const GET_ALL_PAYROLL_TRANSACTIONS_SUCCESS =
	"GET_ALL_PAYROLL_TRANSACTIONS_SUCCESS";
export const GET_ALL_PAYROLL_TRANSACTIONS_FAILURE =
	"GET_ALL_PAYROLL_TRANSACTIONS_FAILURE";

export const GET_SPECIFIC_PAYROLL_TRANSACTIONS_START =
	"GET_SPECIFIC_PAYROLL_TRANSACTIONS_START";
export const GET_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS =
	"GET_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS";
export const GET_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE =
	"GET_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE";

export const VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_START =
	"VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_START";
export const VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS =
	"VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS";
export const VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE =
	"VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE";

export const REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_START =
	"REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_START";
export const REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS =
	"REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS";
export const REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE =
	"REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE";

export const GET_ALL_USER_TRANSACTIONS_START =
	"GET_ALL_USER_TRANSACTIONS_START";
export const GET_ALL_USER_TRANSACTIONS_SUCCESS =
	"GET_ALL_USER_TRANSACTIONS_SUCCESS";
export const GET_ALL_USER_TRANSACTIONS_FAILURE =
	"GET_ALL_USER_TRANSACTIONS_FAILURE";

export const GET_SPECIFIC_USER_TRANSACTIONS_START =
	"GET_SPECIFIC_USER_TRANSACTIONS_START";
export const GET_SPECIFIC_USER_TRANSACTIONS_SUCCESS =
	"GET_SPECIFIC_USER_TRANSACTIONS_SUCCESS";
export const GET_SPECIFIC_USER_TRANSACTIONS_FAILURE =
	"GET_SPECIFIC_USER_TRANSACTIONS_FAILURE";

export const CROSS_BORDER_MANUAL_TRANSFERS_START =
	"CROSS_BORDER_MANUAL_TRANSFERS_START";
export const CROSS_BORDER_MANUAL_TRANSFERS_SUCCESS =
	"CROSS_BORDER_MANUAL_TRANSFERS_SUCCESS";
export const CROSS_BORDER_MANUAL_TRANSFERS_FAILURE =
	"CROSS_BORDER_MANUAL_TRANSFERS_FAILURE";

export const UPDATE_MANUAL_TRANSFERS_START =
	"UPDATE_MANUAL_TRANSFERS_START";
export const UPDATE_MANUAL_TRANSFERS_SUCCESS =
	"UPDATE_MANUAL_TRANSFERS_SUCCESS";
export const UPDATE_MANUAL_TRANSFERS_FAILURE =
	"UPDATE_MANUAL_TRANSFERS_FAILURE";

export const GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_START =
	"GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_START";
export const GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_SUCCESS =
	"GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_SUCCESS";
export const GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_FAILURE =
	"GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_FAILURE";
import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastSuccess } from "../../helpers/utils.toast";
import {
	TOGGLE_LOADIDNG_STATE_OFF,
	TOGGLE_LOADIDNG_STATE_ON,
} from "../shared/types";
import {
	APPROVE_AGENT_REQUEST_FAILURE,
	APPROVE_AGENT_REQUEST_START,
	APPROVE_AGENT_REQUEST_SUCCESS,
	APPROVE_POS_REQUEST_FAILURE,
	APPROVE_POS_REQUEST_START,
	APPROVE_POS_REQUEST_SUCCESS,
	ASSIGN_POS_TERMINAL_FAILURE,
	ASSIGN_POS_TERMINAL_START,
	ASSIGN_POS_TERMINAL_SUCCESS,
	DECLINE_AGENT_REQUEST_FAILURE,
	DECLINE_AGENT_REQUEST_START,
	DECLINE_AGENT_REQUEST_SUCCESS,
	DECLINE_POS_REQUEST_FAILURE,
	DECLINE_POS_REQUEST_START,
	DECLINE_POS_REQUEST_SUCCESS,
	DISABLE_POS_TERMINAL_FAILURE,
	DISABLE_POS_TERMINAL_START,
	DISABLE_POS_TERMINAL_SUCCESS,
	ENABLE_POS_TERMINAL_FAILURE,
	ENABLE_POS_TERMINAL_START,
	ENABLE_POS_TERMINAL_SUCCESS,
	GET_ALL_AGENT_REQUESTS_FAILURE,
	GET_ALL_AGENT_REQUESTS_START,
	GET_ALL_AGENT_REQUESTS_SUCCESS,
	GET_ALL_POS_REQUESTS_FAILURE,
	GET_ALL_POS_REQUESTS_START,
	GET_ALL_POS_REQUESTS_SUCCESS,
	GET_ALL_POS_TERMINALS_FAILURE,
	GET_ALL_POS_TERMINALS_START,
	GET_ALL_POS_TERMINALS_SUCCESS,
	GET_ALL_USERS_FAILURE,
	GET_ALL_USERS_START,
	GET_ALL_USERS_SUCCESS,
	GET_BUSINESS_BY_UUID_FAILURE,
	GET_BUSINESS_BY_UUID_START,
	GET_BUSINESS_BY_UUID_SUCCESS,
	GET_SPECIFIC_AGENT_REQUEST_FAILURE,
	GET_SPECIFIC_AGENT_REQUEST_START,
	GET_SPECIFIC_AGENT_REQUEST_SUCCESS,
	GET_SPECIFIC_POS_REQUEST_FAILURE,
	GET_SPECIFIC_POS_REQUEST_START,
	GET_SPECIFIC_POS_REQUEST_SUCCESS,
	GET_SPECIFIC_POS_TERMINAL_FAILURE,
	GET_SPECIFIC_POS_TERMINAL_START,
	GET_SPECIFIC_POS_TERMINAL_SUCCESS,
	GET_USER_BY_UUID_FAILURE,
	GET_USER_BY_UUID_START,
	GET_USER_BY_UUID_SUCCESS,
	UNASSIGN_POS_TERMINAL_FAILURE,
	UNASSIGN_POS_TERMINAL_START,
	UNASSIGN_POS_TERMINAL_SUCCESS,
} from "./types";

export const getUserbyUUID = (uuid) => {
	return (dispatch) => {
		dispatch({ type: GET_USER_BY_UUID_START });
		Axios.get(`/merchants/personal-with-uuid/${uuid}`)
			.then((res) => {
				dispatch({
					type: GET_USER_BY_UUID_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_USER_BY_UUID_FAILURE, payLoad: error });
				handler(error, true);
			});
	};
};

export const getBusinessbyUUID = (uuid) => {
	return (dispatch) => {
		dispatch({ type: GET_BUSINESS_BY_UUID_START });
		Axios.get(`/merchants/business-with-uuid/${uuid}`)
			.then((res) => {
				dispatch({
					type: GET_BUSINESS_BY_UUID_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_BUSINESS_BY_UUID_FAILURE, payLoad: error });
				handler(error, true);
			});
	};
};

export const getAllPosAccounts = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_USERS_START });
		Axios.get(`/pos/terminal-requests/accounts`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_USERS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_USERS_FAILURE, payLoad: error });
				// toastWarning("Please try again later, or refresh");
				handler(error);
			});
	};
};

export const getAllPosTerminals = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_POS_TERMINALS_START });
		Axios.get(`pos/terminals`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_POS_TERMINALS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_POS_TERMINALS_FAILURE, payLoad: error });
				// toastWarning("Please try again later, or refresh");
				handler(error);
			});
	};
};

export const getAllPosTerminalsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`pos/terminals`, params)
			.then((res) => {
				let assigned = res.data?.data?.filter((pos) => pos.assigned);
				console.log(assigned);
				callback(assigned);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getAllPosRequests = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_POS_REQUESTS_START });
		Axios.get(`pos/terminal-requests`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_POS_REQUESTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_POS_REQUESTS_FAILURE, payLoad: error });
				// toastWarning("Please try again later, or refresh");
				handler(error);
			});
	};
};

export const getAllAgentRequests = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_AGENT_REQUESTS_START });
		Axios.get(`/banking-agents/requests`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_AGENT_REQUESTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_AGENT_REQUESTS_FAILURE, payLoad: error });
				// toastWarning("Please try again later, or refresh");
				handler(error);
			});
	};
};

/**
 *
 * @param {string} id pos id
 * @returns object - pos terminal details
 */

export const getSpecificPosTerminal = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_POS_TERMINAL_START });
		Axios.get(`pos/terminals/${id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_POS_TERMINAL_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_POS_TERMINAL_FAILURE,
					payLoad: error,
				});
				// toastWarning("Please try again later, or refresh");
				handler(error, true);
			});
	};
};

/**
 *
 * @param {string} id request id
 * @returns object - request details
 */

export const getSpecificPosRequest = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_POS_REQUEST_START });
		Axios.get(`pos/terminal-requests/${id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_POS_REQUEST_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_POS_REQUEST_FAILURE,
					payLoad: error,
				});
				// toastWarning("Please try again later, or refresh");
				handler(error, true);
			});
	};
};

/**
 *
 * @param {string} id request id
 * @returns object - request details
 */

export const getSpecificAgentRequest = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_AGENT_REQUEST_START });
		Axios.get(`banking-agents/requests/${id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_AGENT_REQUEST_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_AGENT_REQUEST_FAILURE,
					payLoad: error,
				});
				// toastWarning("Please try again later, or refresh");
				handler(error, true);
			});
	};
};

export const approveAgentRequest = (id) => {
	return (dispatch) => {
		dispatch({ type: APPROVE_AGENT_REQUEST_START });
		Axios.post(`banking-agents/requests/${id}/approve`, {
			comments: "approved",
		})
			.then((res) => {
				dispatch({
					type: APPROVE_AGENT_REQUEST_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				window.history.back();
			})
			.catch((error) => {
				dispatch({
					type: APPROVE_AGENT_REQUEST_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const declineAgentRequest = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: DECLINE_AGENT_REQUEST_START });
		Axios.post(`banking-agents/requests/${id}/reject`, payload)
			.then((res) => {
				dispatch({
					type: DECLINE_AGENT_REQUEST_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				window.history.back();
			})
			.catch((error) => {
				dispatch({
					type: DECLINE_AGENT_REQUEST_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const approvePosRequest = (id) => {
	return (dispatch) => {
		dispatch({ type: APPROVE_POS_REQUEST_START });
		Axios.put(`pos/terminal-requests/${id}`, {
			status: "approved",
		})
			.then((res) => {
				dispatch({
					type: APPROVE_POS_REQUEST_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				window.history.back();
			})
			.catch((error) => {
				dispatch({
					type: APPROVE_POS_REQUEST_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const declinePosRequest = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: DECLINE_POS_REQUEST_START });
		Axios.put(`pos/terminal-requests/${id}/reject`, payload)
			.then((res) => {
				dispatch({
					type: DECLINE_POS_REQUEST_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				window.history.back();
			})
			.catch((error) => {
				dispatch({
					type: DECLINE_POS_REQUEST_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const assignPosTerminal = (payload) => {
	return (dispatch) => {
		dispatch({ type: ASSIGN_POS_TERMINAL_START });
		Axios.post(`pos/terminals`, payload)
			.then((res) => {
				dispatch({
					type: ASSIGN_POS_TERMINAL_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				window.location.reload();
			})
			.catch((error) => {
				dispatch({
					type: ASSIGN_POS_TERMINAL_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const unAssignPosTerminal = (id) => {
	return (dispatch) => {
		dispatch({ type: UNASSIGN_POS_TERMINAL_START });
		Axios.get(`pos/terminals/${id}/unassign`)
			.then((res) => {
				toastSuccess(res.data.data);
				dispatch({
					type: UNASSIGN_POS_TERMINAL_SUCCESS,
					payLoad: res.data.data,
				});
				window.history.back();
			})
			.catch((error) => {
				dispatch({
					type: UNASSIGN_POS_TERMINAL_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const disablePosTerminal = (id) => {
	return (dispatch) => {
		dispatch({ type: DISABLE_POS_TERMINAL_START });
		Axios.put(`pos/terminals/${id}/disable`)
			.then((res) => {
				dispatch({
					type: DISABLE_POS_TERMINAL_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.data);
				window.location.reload();
			})
			.catch((error) => {
				dispatch({
					type: DISABLE_POS_TERMINAL_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const enablePosTerminal = (id) => {
	return (dispatch) => {
		dispatch({ type: ENABLE_POS_TERMINAL_START });
		Axios.put(`pos/terminals/${id}/enable`)
			.then((res) => {
				dispatch({
					type: ENABLE_POS_TERMINAL_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.data);
				window.location.reload();
			})
			.catch((error) => {
				dispatch({
					type: ENABLE_POS_TERMINAL_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUserLocallyAction } from "../../redux/auth/unAuthActions";

export default function Logout() {
	let location = useLocation();

	const dispatch = useDispatch();
	React.useLayoutEffect(() => {
		dispatch(logoutUserLocallyAction());

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<Redirect to={{ pathname: "/auth/login", state: location?.state }} push />
		</React.Fragment>
	);
}

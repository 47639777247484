import React from 'react';
import { Redirect } from 'react-router-dom';

export default function Landing() {
    return (
        <div>
            <Redirect to="/auth/login" push />
        </div>
    )
}

export const GET_SUPPORTED_TRANSFER_CURRENCIES_START = "GET_SUPPORTED_TRANSFER_CURRENCIES_START";
export const GET_SUPPORTED_TRANSFER_CURRENCIES_SUCCESS = "GET_SUPPORTED_TRANSFER_CURRENCIES_SUCCESS";
export const GET_SUPPORTED_TRANSFER_CURRENCIES_FAILURE = "GET_SUPPORTED_TRANSFER_CURRENCIES_FAILURE";

export const UPDATE_TRANSFER_CHARGE_START = "UPDATE_TRANSFER_CHARGE_START";
export const UPDATE_TRANSFER_CHARGE_SUCCESS = "UPDATE_TRANSFER_CHARGE_SUCCESS";
export const UPDATE_TRANSFER_CHARGE_FAILURE = "UPDATE_TRANSFER_CHARGE_FAILURE";
export const SUPPORTED_CURRENCIES_START = "SUPPORTED_CURRENCIES_START";
export const SUPPORTED_CURRENCIES_SUCCESS = "SUPPORTED_CURRENCIES_SUCCESS";
export const SUPPORTED_CURRENCIES_FAILURE = "SUPPORTED_CURRENCIES_FAILURE";

export const SUPPORTED_CURRENCIES_RATE_START = "SUPPORTED_CURRENCIES_RATE_START";
export const SUPPORTED_CURRENCIES_RATE_SUCCESS = "SUPPORTED_CURRENCIES_RATE_SUCCESS";
export const SUPPORTED_CURRENCIES_RATE_FAILURE = "SUPPORTED_CURRENCIES_RATE_FAILURE";

export const LIVE_RATE_START = "LIVE_RATE_START";
export const LIVE_RATE_SUCCESS = "LIVE_RATE_SUCCESS";
export const LIVE_RATE_FAILURE = "LIVE_RATE_FAILURE";

export const UPDATE_CURRENCY_RATE_START = "UPDATE_CURRENCY_RATE_START";
export const UPDATE_CURRENCY_RATE_SUCCESS = "UPDATE_CURRENCY_RATE_SUCCESS";
export const UPDATE_CURRENCY_RATE_FAILURE = "UPDATE_CURRENCY_RATE_FAILURE";

export const COUNTRIES_BY_CONTINENT_START = "COUNTRIES_BY_CONTINENT_START";
export const COUNTRIES_BY_CONTINENT_SUCCESS = "COUNTRIES_BY_CONTINENT_SUCCESS";
export const COUNTRIES_BY_CONTINENT_FAILURE = "COUNTRIES_BY_CONTINENT_FAILURE";

export const UPDATE_CURRENCY_STATUS_START = "UPDATE_CURRENCY_STATUS_START";
export const UPDATE_CURRENCY_STATUS_SUCCESS = "UPDATE_CURRENCY_STATUS_SUCCESS";
export const UPDATE_CURRENCY_STATUS_FAILURE = "UPDATE_CURRENCY_STATUS_FAILURE";
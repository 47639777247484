import {
    GET_ALL_CAC_REGISTRATION_FAILURE,
    GET_ALL_CAC_REGISTRATION_START,
    GET_ALL_CAC_REGISTRATION_SUCCESS,
    GET_SINGLE_CAC_REGISTRATION_FAILURE,
    GET_SINGLE_CAC_REGISTRATION_START,
    GET_SINGLE_CAC_REGISTRATION_SUCCESS,
    MARK_CAC_REGISTRATION_FAILURE,
    MARK_CAC_REGISTRATION_START,
    MARK_CAC_REGISTRATION_SUCCESS
} from "./types.js";

const initialState = {
    isLoadingCACRegistration: false,
    isLoadingSingleCAC: false,
    isloadingCACStatusUpdate: false,
    cacRequestsData: {},
    cacSingleData: {}
};

export default function cacRegistrationReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_CAC_REGISTRATION_START:
            return {
                ...state,
                isLoadingCACRegistration: true,
                cacRequestsData: {}
            };

        case GET_ALL_CAC_REGISTRATION_SUCCESS:
            return {
                ...state,
                isLoadingCACRegistration: false,
                cacRequestsData: action.payLoad
            };

        case GET_ALL_CAC_REGISTRATION_FAILURE:
            return {
                ...state,
                isLoadingCACRegistration: false,
                cacRequestsData: {}
            };
        case GET_SINGLE_CAC_REGISTRATION_START:
            return {
                ...state,
                isLoadingSingleCAC: true
            }
        case GET_SINGLE_CAC_REGISTRATION_SUCCESS:
            return {
                ...state,
                isLoadingSingleCAC: false,
                cacSingleData: action?.payLoad
            }
        case GET_SINGLE_CAC_REGISTRATION_FAILURE:
            return {
                ...state,
                isLoadingSingleCAC: false
            }
        case MARK_CAC_REGISTRATION_START:
            return {
                ...state,
                isloadingCACStatusUpdate: true
            }
        case MARK_CAC_REGISTRATION_SUCCESS:
            return {
                ...state,
                isloadingCACStatusUpdate: false
            }
        case MARK_CAC_REGISTRATION_FAILURE:
            return {
                ...state,
                isloadingCACStatusUpdate: false
            }
        default:
            return {
                ...state
            }
    }
}
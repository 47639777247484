import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { TextSkeleton } from "../../components/skeletons";

import { FiCopy, FiMoreVertical } from "react-icons/fi";
import {
  convertObjectToArray,
  copyTextToClipboard,
  isArray,
  isEmptyObject,
  isObject,
  statusEnum,
} from "../../helpers/helper";
import PillIndicator from "../../components/indicators/PillIndicator";
import useAccounts from "../../hooks/useAccounts";
import {
  blacklistPersonalAccount,
  getSpecificLimitRequest,
  getSpecificPersonalAccount,
  getSpecificPersonalAccountCompliance,
  getSpecificPersonalAccountFees,
  getSpecificPersonalAccountSecurity,
  getSpecificPersonalAccountServices,
  unBlacklistPersonalAccount,
  updatePersonalAccount2FA,
  updatePersonalAccountIpProtection,
} from "../../redux/accounts/accountsActions";
import { PopoverMain } from "../../components/popovers/Popover";
import FunAccountModal from "./EditPersonalBvnModal";
import EditPersonalAddressModal from "./EditPersonalAddressModal";
import EditPersonalServicesModal from "./EditPersonalServicesModal";
import MemoizedLoadingModal from "../../components/modals/LoadingModal";
import CheckboxInput from "../../components/inputs/CheckBoxInput";
import EditPersonalFeeModal from "./EditPersonalFeeModal";
import BasicModal from "../../components/modals/BasicModal";
import { MdOutlineClose } from "react-icons/md";
import EditPersonalComplianceModal from "./EditPersonalComplianceModal";
import FundPersonalAccountModal from "./FundPersonalAccountModal";
import EditPosFeeModal from "./EditPosFeeModal";
import Button from "../../components/buttons/Button";
import EditTransferLimitModal from "./EditTransferLimitModal";

export default function PersonalAccountDetails() {
  const { id, user_uuid} = useParams();
  const dispatch = useDispatch();

  const [showBlockAccountModal, setShowBlockAccountModal] = useState(false);
  const [showUnblockAccountModal, setShowUnblockAccountModal] = useState(false);
  const [selectedFee, setSelectedFee] = useState({});
  const [selectedCompliance, setSelectedCompliance] = useState({});
  const [showComplianceModal, setShowComplianceModal] = useState(false);
  const [dataToShow, setDataToShow] = useState("");
  const [fees, setFees] = useState([]);

  // Modal States
  const [modalStates, setModalStates] = useState({
    showEditDailyTransferLimit: false,
    showPosFeeModal: false,
    showEditPersonalBvnModal: false,
    showEditPersonalAddressModal: false,
    showEditPersonalServicesModal: false,
    showEditPersonalComplianceModal: false,
    showFundAccountModal: false,
  });

  const {
    isLoadingSpecificPersonalAccount: loading,
    specificPersonalAccount: details,
    isLoadingPersonalMerchantServices,
    isLoadingPersonalMerchantFees,
    isLoadingPersonalSecuritySettings,
    isUpdatingPersonalAccountMerchantServices,
    isUpdatingPersonalAccount2FA,
    isUpdatingPersonalAccountIP,
    isBlacklistingPersonalAccount,
    allPersonalMerchantFees,
    specificPersonalAccountSecuritySettings,
    allPersonalMerchantServices,
    specificPersonalAccountCompliance,
    isLoadingSpecificLimitRequest,
    specificLimitRequest,
    isPosFeeUpdated,
    isOtherFeesUpdated,
  } = useAccounts();

  
  const owner_type = "personal";
  React.useMemo(() => {
    dispatch(getSpecificPersonalAccount(id));
    dispatch(getSpecificLimitRequest(owner_type, id));
    dispatch(getSpecificPersonalAccountServices(id));
    dispatch(getSpecificPersonalAccountFees(id));
    dispatch(getSpecificPersonalAccountSecurity(id));
    dispatch(getSpecificPersonalAccountCompliance(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onUpdateIpProtection = (setting) => {
    dispatch(
      updatePersonalAccountIpProtection(id, {
        ip_protected: !!setting ? 0 : 1,
      })
    );
  };

  const onUpdate2faSetting = (setting) => {
    dispatch(
      updatePersonalAccount2FA(id, {
        "2fa_enabled": !!setting ? 0 : 1,
      })
    );
  };

  const onBlockAccount = () => {
    dispatch(
      blacklistPersonalAccount(id, () => setShowBlockAccountModal(false))
    );
  };

  const onUnblockAccount = () => {
    dispatch(
      unBlacklistPersonalAccount(id, () => setShowUnblockAccountModal(false))
    );
  };

  // const previewCompliance = (compliance) => {
  // 	setDataToShow(compliance);
  // 	setShowComplianceModal(true);
  // };

  const closePreviewCompliance = () => {
    setShowComplianceModal(false);
    setDataToShow("");
  };

  useEffect(() => {
    if (isEmptyObject(allPersonalMerchantFees)) {
      setFees([]);
    } else {
      setFees(convertObjectToArray(allPersonalMerchantFees));
    }
    if (isPosFeeUpdated) {
      setModalStates({ ...modalStates, showPosFeeModal: false });
    }
    if (isOtherFeesUpdated) {
      setModalStates({ ...modalStates, showEditPersonalFeeModal: false });
    }
    return () => {
      setFees([]);
    };
  }, [allPersonalMerchantFees]);

  return (
    <div className="p-3 flex flex-col">
      <div className="mb-5">
        <BreadCrumbs title={"Accounts"} goBack />
      </div>

      {/* <div className="flex items-center justify-between">
				<PillIndicator
					className="px-5 py-1 capitalize"
					type={statusEnum[details?.user?.status]}
					title={details?.user?.status ? "Active" : "Disabled"}
				/>
			</div> */}

      <div className="flex flex-col bg-white shadow rounded-sm p-8 py-2 mb-8">
        <div className="py-3 border-b border-solid border-glade-gray-50 flex items-center justify-between">
          <div className="flex items-center">
            <p className="glade-black glade-normal-text-two mr-1">
              Account Balance:
            </p>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <p className="glade-blue-darker glade-normal-text-two">
                {details?.bank_detail[0]?.currency ?? "NGN"}{" "}
                {Number(details?.bank_detail[0]?.balance ?? 0)?.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </p>
            )}
          </div>
          <PopoverMain
            button={
              <div type="button" className={"py-2 glade-ash "}>
                <div className="inline-flex items-center justify-items-center text-center text-2xl">
                  <FiMoreVertical
                    color="#151515"
                    className="mx-auto cursor-pointer"
                  />
                </div>
              </div>
            }
            popoverClassName="right-0"
          >
            <ul className="px-2 py-5 bg-white shadow rounded-md w-56">
              <li
                className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"
                onClick={() => {
                  setModalStates({
                    ...modalStates,
                    showFundAccountModal: true,
                  });
                }}
              >
                Fund Account
              </li>
              {/* <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
								Change Account Type
							</li>
							<li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
								Change Settlement Type
							</li> */}
              <Link
                to={`/dashboard/outflows/bills?search=${details?.bank_detail?.account_name}`}
              >
                <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
                  View Bills
                </li>
              </Link>
              <Link
                to={`/dashboard/outflows?search=${details?.bank_detail?.account_name}`}
              >
                <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
                  View Transfers
                </li>
              </Link>
              <Link to={`/dashboard/accounts/transaction/history/${id}`}>
                <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
                  All Transactions
                </li>
              </Link>
              {/* <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
								View Payments
						</li> */}
              {details?.user?.status === "active" && (
                <li
                  className="p-3 glade-normal-text-two glade-red glade-hover-bg-red-100 cursor-pointer"
                  onClick={() => {
                    setShowBlockAccountModal(true);
                  }}
                >
                  Block account
                </li>
              )} 
              {details?.user?.status === "blocked" && (
                <li
                  className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"
                  onClick={() => {
                    setShowUnblockAccountModal(true);
                  }}
                >
                  Unblock Account
                </li>
              )}
            </ul>
          </PopoverMain>
        </div>

        <div className="mt-3 space-y-5 border-b border-glade-gray-50 mb-3 pb-2">
          {/* User has single account */}
          {isObject(details?.bank_detail) &&
            <div className="grid grid-cols-4 gap-4 py-3">
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Account Name
                </h5>
                {loading ? (
                  <TextSkeleton width="100px" />
                ) : (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {details?.bank_detail?.account_name}
                  </h6>
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Account Number
                </h5>
                {loading ? (
                  <TextSkeleton width="100px" />
                ) : (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {details?.bank_detail?.account_number || "Not available"}
                  </h6>
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Bank Name
                </h5>
                {loading ? (
                  <TextSkeleton width="100px" />
                ) : (
                  <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                    {details?.bank_detail?.bank_name}
                  </h6>
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Email
                </h5>
                {loading ? (
                  <TextSkeleton width="100px" />
                ) : (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {details?.user?.email}
                  </h6>
                )}
              </div>
            </div>}
            {/* If user has dual account */}
          {isArray(details?.bank_detail) && details?.bank_detail?.map((accountDetail, index) => (
            <div className="">
              <div className="border-b border-glade-gray-50 py-2">
                <h3 className="text-glade-black-300 font-bold">
                  Account {index + 1}
                </h3>
              </div>
              <div className="grid grid-cols-4 gap-4 py-3">
                <div className="flex flex-col">
                  <h5 className="glade-normal-text-two glade-ash py-1">
                    Account Name
                  </h5>
                  {loading ? (
                    <TextSkeleton width="100px" />
                  ) : (
                    <h6 className="glade-normal-text-three glade-black py-1">
                      {accountDetail?.account_name}
                    </h6>
                  )}
                </div>
                <div className="flex flex-col">
                  <h5 className="glade-normal-text-two glade-ash py-1">
                    Account Number
                  </h5>
                  {loading ? (
                    <TextSkeleton width="100px" />
                  ) : (
                    <h6 className="glade-normal-text-three glade-black py-1">
                      {accountDetail?.account_number || "Not available"}
                    </h6>
                  )}
                </div>
                <div className="flex flex-col">
                  <h5 className="glade-normal-text-two glade-ash py-1">
                    Bank Name
                  </h5>
                  {loading ? (
                    <TextSkeleton width="100px" />
                  ) : (
                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                      {accountDetail?.bank_name}
                    </h6>
                  )}
                </div>
                <div className="flex flex-col">
                  <h5 className="glade-normal-text-two glade-ash py-1">
                    Email
                  </h5>
                  {loading ? (
                    <TextSkeleton width="100px" />
                  ) : (
                    <h6 className="glade-normal-text-three glade-black py-1">
                      {details?.user?.email}
                    </h6>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-4 gap-4 py-3">
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Phone Number
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.user?.phone ? details?.user?.phone : "N/A"}
              </h6>
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Email</h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.user?.email}
              </h6>
            )}
          </div>
          {details?.user?.created_at && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Date Created
              </h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                  {dfn.format(
                    new Date(details?.user?.created_at),
                    "MMM d, yyyy"
                  )}
                </h6>
              )}
            </div>
          )}
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Business Type
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                Individual
              </h6>
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Account Status
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <PillIndicator
                className="px-5 py-1 capitalize rounded-md"
                type={statusEnum[details?.user?.status]}
                title={details?.user?.status}
              />
            )}
          </div>
        </div>
      </div>

      <section className="grid grid-cols-3 gap-x-5">
        {/* Account Settings */}
        <div className="flex flex-col bg-white shadow rounded-md p-8 pt-4 h-fit-content">
          <div className="py-3 border-b-2 border-solid border-gray-100 mb-5">
            <p className="glade-black glade-normal-text-two">
              Account Settings
            </p>
          </div>

          {/* Fees */}
          {isLoadingPersonalMerchantFees ? (
            <TextSkeleton />
          ) : (
            <div className="space-y-4">
              {details?.user?.user_uuid && (
                <div className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4">
                  <div className="flex flex-col justify-between">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                      User UUID
                    </h5>
                    <h6 className="glade-small-text-two glade-black py-1">
                      {details?.user?.user_uuid}
                    </h6>
                  </div>
                  <h6 className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer flex-shrink-0">
                    <FiCopy
                      size={20}
                      className="ml-3 cursor-pointer glade-blue-darker"
                      onClick={() =>
                        copyTextToClipboard(details?.user?.user_uuid)
                      }
                    />
                  </h6>
                </div>
              )}
              {/* Daily Transfer Limit */}
              {isLoadingSpecificLimitRequest ? (
                <TextSkeleton />
              ) : (
                <div className="flex items-center justify-between rounded p-3 border border-gray-300">
                  <div className="flex flex-col justify-between">
                    <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                      Daily Transfer Limit
                    </h5>
                    <div className="flex items-center">
                      <h5 className="glade-normal-text-two glade-black py-1 capitalize">
                        NGN{" "}
                        {Number(
                          specificLimitRequest?.transfer_limit
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h5>
                    </div>
                  </div>
                  <h6
                    className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer"
                    onClick={() => {
                      setModalStates({
                        ...modalStates,
                        showEditDailyTransferLimit: true,
                      });
                    }}
                  >
                    Edit
                  </h6>
                </div>
              )}

              {/* Fees */}
              {fees?.map((fee) => (
                <div
                  key={fee.fee_code}
                  className="flex items-center justify-between rounded p-3 border border-gray-300"
                >
                  <div className="flex flex-col justify-between">
                    <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                      {fee?.fee_code === "ga_pos"
                        ? "POS Fee"
                        : fee?.display_name?.replaceAll("_", " ") ||
                        fee?.fee_code?.replaceAll("_", " ")}
                    </h5>
                    {fee?.conditional && JSON.parse(fee?.conditions).length ? (
                      <>
                        <div className="flex items-center">
                          <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                            Default:
                          </h5>
                          <h6 className="glade-normal-text-three glade-black py-1 capitalize ml-2">
                            {fee?.fee_percentage}% + {fee?.fee_fixed}
                          </h6>
                        </div>
                        {JSON.parse(fee?.conditions)?.map((condition) => (
                          <div className="flex items-center">
                            <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                              NGN{" "}
                              {Number(condition?.limit).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}{" "}
                              Limit:
                            </h5>
                            <h6 className="glade-normal-text-three glade-black py-1 capitalize ml-2">
                              {condition?.fee_percentage}% +{" "}
                              {condition?.fee_fixed}
                            </h6>
                          </div>
                        ))}
                      </>
                    ) : fee?.fee_code.includes("pos") ? (
                      <div>
                        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                          Default:{" "}
                          <span className="glade-normal-text-two glade-black py-1">
                            {fee?.fee_percentage}% + NGN {fee?.fee_fixed}
                          </span>
                        </h5>
                        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                          Cap:{" "}
                          <span className="glade-normal-text-two glade-black py-1">
                            NGN {fee?.fee_cap || "0.00"}
                          </span>
                        </h5>
                      </div>
                    ) : (
                      <div>
                        <h5 className="glade-normal-text-two glade-black py-1 capitalize">
                          {fee?.fee_percentage}% + NGN {fee?.fee_fixed}
                        </h5>
                        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                          Cap:{" "}
                          <span className="glade-normal-text-two glade-black py-1">
                            NGN {fee?.fee_cap || "0.00"}
                          </span>
                        </h5>
                      </div>
                    )}
                    {/* )} */}
                  </div>
                  <h6
                    className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer"
                    onClick={() => {
                      setSelectedFee(fee);
                      if (fee?.fee_code.includes("pos")) {
                        setSelectedFee(fee);
                        setModalStates({
                          ...modalStates,
                          showPosFeeModal: true,
                        });
                      } else {
                        setModalStates({
                          ...modalStates,
                          showEditPersonalFeeModal: true,
                        });
                      }
                    }}
                  >
                    Edit
                  </h6>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Compliance Card*/}
        <div className="flex flex-col bg-white shadow rounded-md p-8 pt-4 h-fit-content">
          <div className="py-3 border-b-2 border-solid border-gray-100 mb-5">
            <p className="glade-black glade-normal-text-two">Compliance</p>
          </div>

          {/* Compliance Array */}

          {loading ? (
            <TextSkeleton />
          ) : (
            <>
              {details?.user?.bvn && (
                <div className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4">
                  <div className="flex flex-col justify-between">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                      BVN
                    </h5>
                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                      {details?.user?.bvn}
                    </h6>
                  </div>
                  <h6
                    className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer"
                    onClick={() => {
                      setModalStates({
                        ...modalStates,
                        showEditPersonalBvnModal: true,
                      });
                    }}
                  >
                    Edit
                  </h6>
                </div>
              )}

              {specificPersonalAccountCompliance?.details?.map((detail) => (
                <div className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4">
                  <div className="flex flex-col justify-between">
                    <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                      {detail?.title?.split("_").join(" ")}
                    </h5>
                    {detail.compliance_data || detail.file_url ? (
                      <>
                        {detail.type === "file" ? (
                          <a
                            href={detail.file_url}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <h6
                              className="glade-normal-text-three glade-blue py-1 cursor-pointer capitalize"
                            // onClick={() => previewCompliance(detail.file_url)}
                            >
                              view document
                            </h6>
                          </a>
                        ) : (
                          <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            {detail?.compliance_data}
                          </h6>
                        )}
                      </>
                    ) : (
                      <TextSkeleton />
                    )}
                  </div>
                  <h6
                    className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer"
                    onClick={() => {
                      setSelectedCompliance(detail);
                      setModalStates({
                        ...modalStates,
                        showEditPersonalComplianceModal: true,
                      });
                    }}
                  >
                    Edit
                  </h6>
                </div>
              ))}
              {(details?.user?.contact_address || details?.user?.address) && (
                <div className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4">
                  <div className="flex flex-col justify-between">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                      Address
                    </h5>
                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                      {details?.user?.contact_address || details?.user?.address}
                    </h6>
                  </div>
                  <h6
                    className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer"
                    onClick={() => {
                      setModalStates({
                        ...modalStates,
                        showEditPersonalAddressModal: true,
                      });
                    }}
                  >
                    Edit
                  </h6>
                </div>
              )}
              {/* 
							{details?.user?.identity_card && (
								<div className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4">
									<div className="flex flex-col justify-between">
										<h5 className="glade-normal-text-two glade-ash py-1">
											Identity Card
										</h5>
										<h6 className="glade-normal-text-three glade-black py-1 capitalize">
											{details?.user?.identity_card}
										</h6>
									</div>
									<h6 className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer">
										Edit
									</h6>
								</div>
							)}
							{details?.user?.id_number && (
								<div className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4">
									<div className="flex flex-col justify-between">
										<h5 className="glade-normal-text-two glade-ash py-1">
											ID Number
										</h5>
										<h6 className="glade-normal-text-three glade-black py-1 capitalize">
											{details?.user?.id_number}
										</h6>
									</div>
									<h6 className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer">
										Edit
									</h6>
								</div>
							)} */}
            </>
          )}
        </div>

        {/* Services and Security */}
        <div>
          {/* Services Card */}
          <div className="flex flex-col bg-white shadow rounded-md p-8 py-4">
            <div className="py-3 border-b-2 border-solid border-gray-100 mb-5 flex items-center justify-between">
              <p className="glade-black glade-normal-text-two">Services</p>
              {isLoadingPersonalMerchantServices ? (
                <TextSkeleton width="40px" />
              ) : (
                <h6
                  className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer"
                  onClick={() => {
                    setModalStates({
                      ...modalStates,
                      showEditPersonalServicesModal: true,
                    });
                  }}
                >
                  Edit
                </h6>
              )}
            </div>

            {/* Services */}
            {isLoadingPersonalMerchantServices ? (
              <TextSkeleton />
            ) : (
              <>
                {allPersonalMerchantServices?.map((service) => (
                  <div
                    className="flex items-center justify-between mb-8"
                    key={service.id}
                  >
                    <h5 className="glade-normal-text-two glade-black py-1">
                      {service.name}
                    </h5>
                    <PillIndicator
                      className="px-5 py-1 capitalize"
                      type={statusEnum[service?.status]}
                      title={service?.status ? "Approved" : "Disabled"}
                    />
                  </div>
                ))}
              </>
            )}
          </div>

          {/* Security Card */}
          <div className="flex flex-col bg-white shadow rounded-md p-8 pt-4 mt-5">
            <div className="py-3 border-b-2 border-solid border-gray-100 mb-5 flex items-center justify-between">
              <p className="glade-black glade-normal-text-two">
                Security/Settlement Configuration
              </p>
            </div>

            {/* Services */}
            {isLoadingPersonalSecuritySettings ? (
              <TextSkeleton />
            ) : (
              <div className="px-4 space-y-8">
                <CheckboxInput
                  label="IP Protection"
                  checked={
                    specificPersonalAccountSecuritySettings?.user
                      ?.is_ip_protection_enabled
                  }
                  onChange={() =>
                    onUpdateIpProtection(
                      specificPersonalAccountSecuritySettings?.user
                        ?.is_ip_protection_enabled
                    )
                  }
                />
                <CheckboxInput
                  label="Two Factor Authentication"
                  checked={
                    specificPersonalAccountSecuritySettings?.user
                      ?.is_2fa_enabled
                  }
                  onChange={() =>
                    onUpdate2faSetting(
                      specificPersonalAccountSecuritySettings?.user
                        ?.is_2fa_enabled
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Modals */}

      <FunAccountModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditPersonalBvnModal={modalStates?.showEditPersonalBvnModal}
      />

      <EditPersonalAddressModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditPersonalAddressModal={modalStates?.showEditPersonalAddressModal}
      />

      <EditPersonalServicesModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        services={allPersonalMerchantServices}
        showEditPersonalServicesModal={
          modalStates?.showEditPersonalServicesModal
        }
      />

      {modalStates?.showEditPersonalFeeModal ? (
        <EditPersonalFeeModal
          modalStates={modalStates}
          setModalStates={setModalStates}
          selectedFee={selectedFee}
          setSelectedFee={setSelectedFee}
          showEditPersonalFeeModal={modalStates?.showEditPersonalFeeModal}
        />
      ) : null}

      <EditPersonalComplianceModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        selectedCompliance={selectedCompliance}
        setSelectedCompliance={setSelectedCompliance}
        showEditPersonalComplianceModal={
          modalStates?.showEditPersonalComplianceModal
        }
      />

      <FundPersonalAccountModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showFundAccountModal={modalStates?.showFundAccountModal}
      />

      <EditTransferLimitModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditDailyTransferLimit={modalStates?.showEditDailyTransferLimit}
        owner_type="personal"
        owner_uuid={id}
      />

      {modalStates.showPosFeeModal ? (
        <EditPosFeeModal
          modalStates={modalStates}
          setModalStates={setModalStates}
          selectedFee={selectedFee}
          setSelectedFee={setSelectedFee}
          showPosFeeModal={modalStates?.showPosFeeModal}
        />
      ) : null}

      <MemoizedLoadingModal
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        visibility={
          isUpdatingPersonalAccountMerchantServices ||
          isUpdatingPersonalAccountIP ||
          isUpdatingPersonalAccount2FA
        }
      />

      {/* Show Compliance Modal */}
      <BasicModal
        visibility={showComplianceModal}
        onClose={closePreviewCompliance}
        onOpen={() => setShowComplianceModal(true)}
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        modelContentSection={
          <div>
            <div className="-mx-6 -mt-2 flex justify-between px-5 pb-3 border-b-2 border-solid border-gray-200 mb-4">
              <h4 className="glade-normal-text-two glade-black">Document</h4>
              <MdOutlineClose
                size={16}
                className="glade-black cursor-pointer"
                onClick={closePreviewCompliance}
              />
            </div>
            <img src={dataToShow} className="h-96 w-full" alt="" />
          </div>
        }
      />

      {/* BLock Account Modal */}
      {showBlockAccountModal && (
        <BasicModal
          visibility={showBlockAccountModal}
          onClose={() => setShowBlockAccountModal(false)}
          onOpen={() => setShowBlockAccountModal(true)}
          primaryButton=""
          modalTitleSection=""
          secondaryButton=""
          modelContentSection={
            <div className="p-6 px-0">
              <h3 className="glade-black text-center mb-8">
                Are you sure you want to block this account?
              </h3>
              <Button.Danger
                className={"w-full"}
                onClick={onBlockAccount}
                loading={isBlacklistingPersonalAccount}
                title="Block"
              >
                {isBlacklistingPersonalAccount ? "Blacklisting" : "Blacklist"}
              </Button.Danger>
              <button
                onClick={() => setShowBlockAccountModal(false)}
                className="glade-normal-text-three glade-grey-dark w-full mx-auto text-center mt-6"
              >
                Cancel
              </button>
            </div>
          }
        />
      )}

      {/* Unblock Account Modal */}
      {showUnblockAccountModal && (
        <BasicModal
          visibility={showUnblockAccountModal}
          onClose={() => setShowUnblockAccountModal(false)}
          onOpen={() => setShowUnblockAccountModal(true)}
          primaryButton=""
          modalTitleSection=""
          secondaryButton=""
          modelContentSection={
            <div className="p-6 px-0">
              <h3 className="glade-black text-center mb-8">
                Are you sure you want to unblock this account?
              </h3>
              <Button.Dark
                onClick={onUnblockAccount}
                loading={isBlacklistingPersonalAccount}
                title="Unblock"
              >
                {isBlacklistingPersonalAccount ? "Blacklisting" : "Blacklist"}
              </Button.Dark>
              <button
                onClick={() => setShowUnblockAccountModal(false)}
                className="glade-normal-text-three glade-grey-dark w-full mx-auto text-center mt-6"
              >
                Cancel
              </button>
            </div>
          }
        />
      )}
    </div>
  );
}

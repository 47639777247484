import React from 'react';
import { Transition } from '@headlessui/react';

export function FormWizardSection({position, currentStep, children}) {

    const [show, setShow] = React.useState(false);
    React.useEffect(() => {
        if (position === currentStep) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [position,currentStep]);

    return (
        <React.Fragment>
            <Transition
                enter="transition ease-in-out duration-1000 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"

                leave="transition-opacity duration-1000"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"

                // leave="transition ease-in-out duration-100 transform"
                // leaveFrom="translate-x-0"
                // leaveTo="-translate-x-full"

                appear={true}
                show={show}>
                {children}
            </Transition>
        </React.Fragment>
    )
}

export const FormWizard = React.forwardRef( ({step=0, setStep=()=>{}, children}, ref) => {

    const numberOfSections = React.Children.count(children);

    React.useImperativeHandle(ref, () => ({
        prev: () => { step-1 <= 0 ? setStep(0) : setStep(step-1); },
        next: () => { step+1 >= numberOfSections-1 ? setStep(numberOfSections-1) : setStep(step+1); },
        getCurrentStep: () => {return step;}
    }));

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
});

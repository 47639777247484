import React, { useState, useEffect } from "react";
import * as dfn from "date-fns";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { TextSkeleton } from "../../components/skeletons";
import Button from "../../components/buttons/Button";
import useAccounts from "../../hooks/useAccounts";
import { getLimitRequestDetails } from "../../redux/accounts/accountsActions";
import RejectLimitModal from "./RejectLimitModal";
import ApproveLimitModal from "./ApproveLimitModal";
import { DisplayMedal } from "../../assets/icons/Icons";

export default function LimitRequestDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  // Modal States
  const [modalStates, setModalStates] = useState({
    showApproveRequestModal: false,
    showRejectRequestModal: false,
  });
  const [limitRequest, setLimitRequest] = useState(null);
  const [accountInformation, setAccountInformation] = useState(null);

  const {
    isLoadingSpecificLimitRequest: loading,
    specificLimitRequest: details,
    isRejectingLimitRequest,
    isApprovingLimitRequest,
  } = useAccounts();
  React.useMemo(() => {
    dispatch(getLimitRequestDetails(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    if (details) {
      setLimitRequest(details[0]);
      if (details[0]?.account_information) {
        setAccountInformation(details[0]?.account_information[0]);
      }
    }
    // return () => {
    // 	cleanup
    // };
  }, [details]);
  return (
    <div className="p-3 flex flex-col">
      <div className="mb-6">
        <BreadCrumbs title={"Limit Requests"} goBack />
      </div>

      <div className="flex items-center justify-end">
        {/* <PillIndicator
					className="px-5 py-1 capitalize"
					type={statusEnum[limitRequest?.status]}
					title={limitRequest?.status ? "Active" : "Disabled"}
				/> */}

        <div className="flex items-center">
          <Button.Green
            onClick={() => {
              setModalStates({
                ...modalStates,
                showApproveRequestModal: true,
              });
            }}
            disabled={loading}
            title="Approve"
          />
          <div className="ml-4">
            <Button.Danger
              onClick={() => {
                setModalStates({
                  ...modalStates,
                  showRejectRequestModal: true,
                });
              }}
              disabled={isRejectingLimitRequest || loading}
              title="Decline"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-white rounded-sm p-8 pt-5 mt-4 mb-8">
        <div className="py-3 border-b-2 border-solid border-gray-100">
          <p className="text-glade-black-800 text-base font-bold">
            Account Details
          </p>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4 py-3">
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two text-glade-gray-400 py-1">
              Account Name
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three text-glade-black-800 py-1 capitalize">
                {limitRequest?.account_name ?? "Not available"}
              </h6>
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two text-glade-gray-400 py-1">
              Account Email
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three text-glade-black-800 py-1">
                {limitRequest?.account_email ?? "Not available"}
              </h6>
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two text-glade-gray-400 py-1">
              Phone Number
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three text-glade-black-800 py-1">
                {limitRequest?.account_phone ?? "Not available"}
              </h6>
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two text-glade-gray-400 py-1">
              Account Type
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three text-glade-black-800 py-1 capitalize">
                {limitRequest?.owner_type ?? "Not available"}
              </h6>
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two text-glade-gray-400 py-1">
              Account Balance
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three text-glade-black-800 py-1">
                {accountInformation?.currency ?? "NGN"}{" "}
                {Number(accountInformation?.balance ?? 0)?.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </h6>
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two text-glade-gray-400 py-1">
              Current Tier
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three text-glade-black-800 py-1 flex">
                <DisplayMedal tierName={limitRequest?.current_tier} />{" "}
                <span>{limitRequest?.current_tier ?? "Not available"}</span>
              </h6>
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two text-glade-gray-400 py-1">
              Requested Tier
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three text-text-glade-black-800-800 py-1 flex">
                <DisplayMedal tierName={limitRequest?.tier_requested} />{" "}
                <span>{limitRequest?.tier_requested ?? "Not available"}</span>
              </h6>
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two text-glade-gray-400 py-1">
              Number of Transactions
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three text-glade-black-800 py-1">
                {limitRequest?.transactions} Transactions
              </h6>
            )}
          </div>

          {accountInformation?.date_opened && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two text-glade-gray-400 py-1">
                Date Opened
              </h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three text-glade-black-800 py-1 capitalize">
                  {dfn.format(new Date(accountInformation?.date_opened), "MMM d, yyyy")}
                </h6>
              )}
            </div>
          )}
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two text-glade-gray-400 py-1">
              Reason
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three text-glade-black-800 py-1">
                {limitRequest?.reason ?? "Not available"}
              </h6>
            )}
          </div>
        </div>
      </div>

      {/* Approve modal */}
      {modalStates?.showApproveRequestModal &&
        <ApproveLimitModal
          modalStates={modalStates}
          setModalStates={setModalStates}
          showApproveRequestModal={modalStates?.showApproveRequestModal}
          requestedLimit={limitRequest}
          currency={limitRequest?.currency}
        />}

      {/* Decline modal */}
      {modalStates?.showRejectRequestModal &&
        <RejectLimitModal
          modalStates={modalStates}
          setModalStates={setModalStates}
          showRejectRequestModal={modalStates?.showRejectRequestModal}
          requestedLimit={limitRequest}
        />}
    </div>
  );
}

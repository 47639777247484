import { useState, useEffect, useContext } from "react";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import { CardPlain, CardPlainSection } from "../../components/cards/CardPlain";
import { useForm } from "react-hook-form";
import { FormError } from "../../validation";
import { useDispatch } from "react-redux";
import { newDiscount } from "../../redux/discounts/discountActions";
import MultiSelectInput from "../../components/multiselect/MultiSelectInput";
import { MultiSelectContext } from "../../context/MultiSelectContext";

const NewTransferDiscount = () => {
  const dispatch = useDispatch();
  const [, setAccountType] = useState("");
  const { selectOptions, setIsPersonal, setSelectOptions } = useContext(MultiSelectContext);
  const [recipientError, setRecipientError] = useState({});

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    criteriaMode: "all",
    shouldUseNativeValidation: true
  });

  const typeInput = watch("account_type");

  const onNewTransferDiscount = ({
    discount_name,
    account_type,
    no_of_free_transfers,
  }) => {
    if (selectOptions.length === 0) {
      setRecipientError({
        discount_recipients: { message: "Select at least one recipient" }
      });
      return;
    }
    const formData = {
      tag: discount_name,
      discount_type: "transfer_discount",
      account_type,
      number_of_transfer: no_of_free_transfers,
      account_uuid: selectOptions.map(option => account_type === 'business' ? option.business_uuid : option.user_uuid)
    };
    dispatch(newDiscount(formData));
  };

  useEffect(() => {
    setAccountType(typeInput);
    if (typeInput === 'personal')
      setIsPersonal(true);
    else
      setIsPersonal(false);
  }, [typeInput, setIsPersonal]);

  useEffect(() => {
    return () => {
      setSelectOptions([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-3 flex flex-col">
      <div className="mb-5">
        <BreadCrumbs title={"Discounts"} goBack />
      </div>

      <form onSubmit={handleSubmit(onNewTransferDiscount)}>
        <CardPlain type="single">
          <CardPlainSection
            title={"New Transfer Discount"}
            type="single"
          >
            <section className="pt-10 px-16 pb-10">
              <div className="mb-4">
                <label
                  className="block glade-small-text-two"
                  htmlFor="discount_name"
                >
                  Discount Name
                </label>
                <input
                  type="text"
                  name="discount_name"
                  {...register("discount_name", {
                    required: "Type a discount name"
                  })}
                  id="discount_name"
                  placeholder="Enter Discount Name"
                  className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                />
                <FormError errors={errors} name="discount_name" />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="account_type"
                  className="block glade-small-text-two"
                >
                  Account Type
                </label>
                <select
                  className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
                  name="account_type"
                  id="type"
                  {...register("account_type", {
                    required: "Enter an account type"
                  })}
                >
                  <option disabled value="" selected>Select account type</option>
                  <option value="personal">Personal</option>
                  <option value="business">Business</option>
                </select>
                <FormError errors={errors} name="account_type" />
              </div>
              <div className="mb-8">
                <label
                  className="block glade-small-text-two"
                  htmlFor="no_of_free_transfers"
                >
                  Number of Free Transfers
                </label>
                <input
                  type="number"
                  name="no_of_free_transfers"
                  id="no_of_free_transfers"
                  placeholder="Enter Number of Free Transfers"
                  className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                  {...register("no_of_free_transfers", {
                    required: "Enter amount of free transfers"
                  })}
                />
                <FormError errors={errors} name="no_of_free_transfers" />
              </div>
              <div className="mb-4">
                <label
                  className="block glade-small-text-two mb-1"
                  htmlFor="discount_recipients"
                >
                  Discount Recipients
                </label>
                <MultiSelectInput />
                <FormError errors={recipientError} name={"discount_recipients"} />
              </div>
            </section>
          </CardPlainSection>
        </CardPlain>
        <div className="flex justify-center mt-6 space-x-4">
          <Button.Dark
            type="submit"
            title={"Add Discount"}
            loading={false}
            style={{ width: "fit-content" }}
          />
        </div>
      </form>
    </div>
  );
};

export default NewTransferDiscount;


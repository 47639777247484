// Get discounts
export const GET_ALL_DISCOUNTS_START = "GET_ALL_DISCOUNTS_START";
export const GET_ALL_DISCOUNTS_SUCCESS = "GET_ALL_DISCOUNTS_SUCCESS";
export const GET_ALL_DISCOUNTS_FAILURE = "GET_ALL_DISCOUNTS_FAILURE";

// Get single discount
export const GET_SINGLE_DISCOUNT_START = "GET_SINGLE_DISCOUNT_START";
export const GET_SINGLE_DISCOUNT_SUCCESS = "GET_SINGLE_DISCOUNT_SUCCESS";
export const GET_SINGLE_DISCOUNT_FAILURE = "GET_SINGLE_DISCOUNT_FAILURE";

// Clear Single discount
export const CLEAR_SINGLE_DISCOUNT_START = "CLEAR_SINGLE_DISCOUNT_START";
export const CLEAR_SINGLE_DISCOUNT_SUCCESS = "CLEAR_SINGLE_DISCOUNT_SUCCESS";
export const CLEAR_SINGLE_DISCOUNT_FAILURE = "CLEAR_SINGLE_DISCOUNT_FAILURE";

// New transfer discount
export const NEW_DISCOUNT_START = "NEW_DISCOUNT_START";
export const NEW_DISCOUNT_SUCCESS = "NEW_DISCOUNT_SUCCESS";
export const NEW_DISCOUNT_FAILURE = "NEW_DISCOUNT_FAILURE";

// Edit discount
export const EDIT_DISCOUNT_START = "EDIT_DISCOUNT_START";
export const EDIT_DISCOUNT_SUCCESS = "EDIT_DISCOUNT_SUCCESS";
export const EDIT_DISCOUNT_FAILURE = "EDIT_DISCOUNT_FAILURE";

// Deactivate discount
export const TERMINATE_DISCOUNT_START = "TERMINATE_DISCOUNT_START";
export const TERMINATE_DISCOUNT_SUCCESS = "TERMINATE_DISCOUNT_SUCCESS";
export const TERMINATE_DISCOUNT_FAILURE = "TERMINATE_DISCOUNT_FAILURE";

// Activate discount
export const ACTIVATE_DISCOUNT_START = "ACTIVATE_DISCOUNT_START";
export const ACTIVATE_DISCOUNT_SUCCESS = "ACTIVATE_DISCOUNT_SUCCESS";
export const ACTIVATE_DISCOUNT_FAILURE = "ACTIVATE_DISCOUNT_FAILURE";

// Delete discount
export const DELETE_DISCOUNT_START = "DELETE_DISCOUNT_START";
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS";
export const DELETE_DISCOUNT_FAILURE = "DELETE_DISCOUNT_FAILURE";
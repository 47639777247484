import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastSuccess } from "../../helpers/utils.toast";
import {
	TOGGLE_LOADIDNG_STATE_OFF,
	TOGGLE_LOADIDNG_STATE_ON,
} from "../shared/types";
import {
	GET_REFERRAL_CONFIGURATION_FAILURE,
	GET_REFERRAL_CONFIGURATION_START,
	GET_REFERRAL_CONFIGURATION_SUCCESS,
	GET_ALL_USER_REFERRAL_FAILURE,
	GET_ALL_USER_REFERRAL_START,
  GET_ALL_USER_REFERRAL_SUCCESS,
  SUBMIT_REFERRAL_CONFIG_START,
  SUBMIT_REFERRAL_CONFIG_SUCCESS,
  SUBMIT_REFERRAL_CONFIG_FAILURE
} from "./types";

export const getAllUserReferral = (params) => {
	return (dispatch) => {
    dispatch({ type: GET_ALL_USER_REFERRAL_START });
		Axios.get(`referrals/get-referral-details?from=${params.from}&to=${params.to}&paginate=${params.paginate}&search=${params.search}`)
			.then((res) => {
				dispatch({
					type: GET_ALL_USER_REFERRAL_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ALL_USER_REFERRAL_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getAllUserReferralForDownload = (params, callback) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });
		Axios.get(`referrals/get-referral-details?from=${params.from}&to=${params.to}&paginate=${params.paginate}&search=${params.search}`)
      .then((res) => {
				callback(res.data.data?.data?.data);
        dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({type: TOGGLE_LOADIDNG_STATE_OFF});
				handler(error, true);
			});
	};
};

export const getReferralConfiguration = () => {
	return (dispatch) => {
		dispatch({ type: GET_REFERRAL_CONFIGURATION_START });

		Axios.get(`referrals/get-configuration`)
			.then((res) => {
				dispatch({
					type: GET_REFERRAL_CONFIGURATION_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_REFERRAL_CONFIGURATION_FAILURE });
				handler(error, true);
			});
	};
};

export const submitReferralConfiguration = (payload) => {
	return (dispatch) => {
		dispatch({ type: SUBMIT_REFERRAL_CONFIG_START });
		Axios.post(`referrals/configure-referral`, payload)
			.then((res) => {
				dispatch({
					type: SUBMIT_REFERRAL_CONFIG_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				// window.location.reload(true);
			})
			.catch((error) => {
				dispatch({
					type: SUBMIT_REFERRAL_CONFIG_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};



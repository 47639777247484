import {
  FAILURE_CURRENCIES_LIST,
  GET_ALL_FEES_FAILURE,
  GET_ALL_FEES_START,
  GET_ALL_FEES_SUCCESS,
  GET_CHECKOUT_FEES_FAILURE,
  GET_CHECKOUT_FEES_START,
  GET_CHECKOUT_FEES_SUCCESS,
  START_CURRENCIES_LIST,
  SUCCESS_CURRENCIES_LIST,
  UPDATE_FEE_FAILURE,
  UPDATE_FEE_START,
  UPDATE_FEE_SUCCESS,
} from "./types";

const initialState = {
  isLoadingAllFees: true,
  isLoadingCheckoutFees: false,
  isUpdatingFee: false,
  allFees: [],
  allCheckoutFees: [],
  allCheckoutCurrencies: []
};

export default function feesReducer(state = initialState, action) {
  switch (action.type) {
    //GET CHECKOUT FEES
    case GET_CHECKOUT_FEES_START:
      return { ...state, isLoadingCheckoutFees: true };
    case GET_CHECKOUT_FEES_SUCCESS:
      return {
        ...state,
        isLoadingCheckoutFees: false,
        allCheckoutFees: [...action.payLoad],
      };
    case GET_CHECKOUT_FEES_FAILURE:
      return {
        ...state,
        isLoadingCurrencies: false,
        allCheckoutCurrencies: [],
      };
    // GET CHECKOUT CURRENCIES
    case START_CURRENCIES_LIST:
      return { ...state, isLoadingCheckoutCurrencies: true };
    case SUCCESS_CURRENCIES_LIST:
      return {
        ...state,
        isLoadingCheckoutCurrencies: false,
        allCheckoutCurrencies: [...action.payLoad],
      };
    case FAILURE_CURRENCIES_LIST:
      return {
        ...state,
        isLoadingCurrencies: false,
        allCheckoutCurrencies: [],
      };
    // GET ALL FEES
    case GET_ALL_FEES_START:
      return {
        ...state,
        isLoadingAllFees: true,
      };

    case GET_ALL_FEES_SUCCESS:
      return {
        ...state,
        isLoadingAllFees: false,
        allFees: action.payLoad,
      };

    case GET_ALL_FEES_FAILURE:
      return {
        ...state,
        isLoadingAllFees: false,
      };

    // verify specific unsettled settlement
    case UPDATE_FEE_START:
      return {
        ...state,
        isUpdatingFee: true,
      };

    case UPDATE_FEE_SUCCESS:
      return {
        ...state,
        isUpdatingFee: false,
      };

    case UPDATE_FEE_FAILURE:
      return {
        ...state,
        isUpdatingFee: false,
      };

    default:
      return state;
  }
}

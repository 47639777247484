import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastSuccess } from "../../helpers/utils.toast";
import {
	TOGGLE_LOADIDNG_STATE_OFF,
	TOGGLE_LOADIDNG_STATE_ON,
} from "../shared/types";
import {
	APPROVE_SPECIFIC_LIMIT_REQUEST_FAILURE,
	APPROVE_SPECIFIC_LIMIT_REQUEST_START,
	APPROVE_SPECIFIC_LIMIT_REQUEST_SUCCESS,
	BLACKLIST_BUSINESS_ACCOUNT_FAILURE,
	BLACKLIST_BUSINESS_ACCOUNT_START,
	BLACKLIST_BUSINESS_ACCOUNT_SUCCESS,
	BLACKLIST_PERSONAL_ACCOUNT_FAILURE,
	BLACKLIST_PERSONAL_ACCOUNT_START,
	BLACKLIST_PERSONAL_ACCOUNT_SUCCESS,
	FUND_BUSINESS_ACCOUNT_FAILURE,
	FUND_BUSINESS_ACCOUNT_START,
	FUND_BUSINESS_ACCOUNT_SUCCESS,
	FUND_PERSONAL_ACCOUNT_FAILURE,
	FUND_PERSONAL_ACCOUNT_START,
	FUND_PERSONAL_ACCOUNT_SUCCESS,
	GET_ALL_AGENTS_ACCOUNTS_FAILURE,
	GET_ALL_AGENTS_ACCOUNTS_START,
	GET_ALL_AGENTS_ACCOUNTS_SUCCESS,
	GET_ALL_BLACKLISTED_ACCOUNTS_FAILURE,
	GET_ALL_BLACKLISTED_ACCOUNTS_START,
	GET_ALL_BLACKLISTED_ACCOUNTS_SUCCESS,
	GET_ALL_BUSINESS_ACCOUNTS_FAILURE,
	GET_ALL_BUSINESS_ACCOUNTS_START,
	GET_ALL_BUSINESS_ACCOUNTS_SUCCESS,
	GET_ALL_LIMIT_REQUESTS_FAILURE,
	GET_ALL_LIMIT_REQUESTS_START,
	GET_ALL_LIMIT_REQUESTS_SUCCESS,
	GET_ALL_PERSONAL_ACCOUNTS_FAILURE,
	GET_ALL_PERSONAL_ACCOUNTS_START,
	GET_ALL_PERSONAL_ACCOUNTS_SUCCESS,
	GET_BUSINESS_ACCOUNT_2FA_FAILURE,
	GET_BUSINESS_ACCOUNT_2FA_START,
	GET_BUSINESS_ACCOUNT_2FA_SUCCESS,

	GET_SYSTEM_LIMITS_FAILURE,
	GET_SYSTEM_LIMITS_START,
	GET_SYSTEM_LIMITS_SUCCESS,

	GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE,
	GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START,
	GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS,
	GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_FAILURE,
	GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_START,
	GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_SUCCESS,
	GET_BUSINESS_ACCOUNT_MERCHANT_FEES_FAILURE,
	GET_BUSINESS_ACCOUNT_MERCHANT_FEES_START,
	GET_BUSINESS_ACCOUNT_MERCHANT_FEES_SUCCESS,
	GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_FAILURE,
	GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_START,
	GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_SUCCESS,
	GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE,
	GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START,
	GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
	GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_FAILURE,
	GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_START,
	GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_SUCCESS,
	GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE,
	GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START,
	GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS,
	GET_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE,
	GET_PERSONAL_ACCOUNT_COMPLIANCE_START,
	GET_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS,
	GET_PERSONAL_ACCOUNT_MERCHANT_FEES_FAILURE,
	GET_PERSONAL_ACCOUNT_MERCHANT_FEES_START,
	GET_PERSONAL_ACCOUNT_MERCHANT_FEES_SUCCESS,
	GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE,
	GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START,
	GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
	GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_FAILURE,
	GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_START,
	GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_SUCCESS,
	GET_SPECIFIC_BUSINESS_ACCOUNT_FAILURE,
	GET_SPECIFIC_BUSINESS_ACCOUNT_START,
	GET_SPECIFIC_BUSINESS_ACCOUNT_SUCCESS,
	GET_SPECIFIC_LIMIT_REQUEST_FAILURE,
	GET_SPECIFIC_LIMIT_REQUEST_START,
	GET_SPECIFIC_LIMIT_REQUEST_SUCCESS,
	GET_SPECIFIC_PERSONAL_ACCOUNT_FAILURE,
	GET_SPECIFIC_PERSONAL_ACCOUNT_START,
	GET_SPECIFIC_PERSONAL_ACCOUNT_SUCCESS,
	REJECT_SPECIFIC_LIMIT_REQUEST_FAILURE,
	REJECT_SPECIFIC_LIMIT_REQUEST_START,
	REJECT_SPECIFIC_LIMIT_REQUEST_SUCCESS,
	REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE,
	REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_START,
	REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS,
	REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE,
	REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_START,
	REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS,

	REVERT_ACCOUNT_MERCHANT_FEE_FAILURE,
	REVERT_ACCOUNT_MERCHANT_FEE_START,
	REVERT_ACCOUNT_MERCHANT_FEE_SUCCESS,

	UPDATE_BUSINESS_ACCOUNT_ADDRESS_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_ADDRESS_START,
	UPDATE_BUSINESS_ACCOUNT_ADDRESS_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_BVN_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_BVN_START,
	UPDATE_BUSINESS_ACCOUNT_BVN_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_START,
	UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_DETAILS_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_DETAILS_START,
	UPDATE_BUSINESS_ACCOUNT_DETAILS_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_EMAIL_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_EMAIL_START,
	UPDATE_BUSINESS_ACCOUNT_EMAIL_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START,
	UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_START,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_START,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS,
	UPDATE_ACCOUNT_MERCHANT_FEE_FAILURE,
	UPDATE_ACCOUNT_MERCHANT_FEE_START,
	UPDATE_ACCOUNT_MERCHANT_FEE_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_START,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START,
	UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_NAME_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_NAME_START,
	UPDATE_BUSINESS_ACCOUNT_NAME_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_PHONE_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_PHONE_START,
	UPDATE_BUSINESS_ACCOUNT_PHONE_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_RC_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_RC_START,
	UPDATE_BUSINESS_ACCOUNT_RC_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_START,
	UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_SUCCESS,
	UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE,
	UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START,
	UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS,
	UPDATE_PERSONAL_ACCOUNT_ADDRESS_FAILURE,
	UPDATE_PERSONAL_ACCOUNT_ADDRESS_START,
	UPDATE_PERSONAL_ACCOUNT_ADDRESS_SUCCESS,
	UPDATE_PERSONAL_ACCOUNT_BVN_FAILURE,
	UPDATE_PERSONAL_ACCOUNT_BVN_START,
	UPDATE_PERSONAL_ACCOUNT_BVN_SUCCESS,
	UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE,
	UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_START,
	UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_FAILURE,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_START,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_SUCCESS,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_START,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_START,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START,
	UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
	UPDATE_TRANSFER_LIMIT_FAILURE,
	UPDATE_TRANSFER_LIMIT_START,
	UPDATE_TRANSFER_LIMIT_SUCCESS,
	GET_MULTI_CURRENCIES_START,
	GET_MULTI_CURRENCIES_SUCCESS,
	GET_MULTI_CURRENCIES_FAILURE,
} from "./types";

// PERSONAL ACCOUNTS ACTIONS
export const getAllPersonalAccounts = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_PERSONAL_ACCOUNTS_START });
		Axios.get(`/merchants`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_PERSONAL_ACCOUNTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_PERSONAL_ACCOUNTS_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getAllPersonalAccountsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`/merchants`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getSpecificPersonalAccount = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_PERSONAL_ACCOUNT_START });
		Axios.get(`/merchants/${id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_PERSONAL_ACCOUNT_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_PERSONAL_ACCOUNT_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getSpecificPersonalAccountServices = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START });
		Axios.get(`/merchants/personal/services/${id}`)
			.then((res) => {
				dispatch({
					type: GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getSpecificPersonalAccountFees = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_PERSONAL_ACCOUNT_MERCHANT_FEES_START });
		Axios.get(`/merchants/fees/custom/${id}`)
			.then((res) => {
				dispatch({
					type: GET_PERSONAL_ACCOUNT_MERCHANT_FEES_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_PERSONAL_ACCOUNT_MERCHANT_FEES_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getSpecificPersonalAccountSecurity = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_START });
		Axios.get(`/merchants/personal/two-factor-authentication/${id}`)
			.then((res) => {
				dispatch({
					type: GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getSpecificPersonalAccountCompliance = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_PERSONAL_ACCOUNT_COMPLIANCE_START });
		Axios.get(`/merchants/compliance/personal-approved-compliance/${id}`)
			.then((res) => {
				dispatch({
					type: GET_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const blacklistPersonalAccount = (id, callback) => {
	return (dispatch) => {
		dispatch({ type: BLACKLIST_PERSONAL_ACCOUNT_START });
		Axios.post(`/merchants/change-user-status/${id}`, {
			status: "blocked",
		})
			.then((res) => {
				dispatch({
					type: BLACKLIST_PERSONAL_ACCOUNT_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificPersonalAccount(id));
				toastSuccess(res.data.message);
				callback();
			})
			.catch((error) => {
				dispatch({
					type: BLACKLIST_PERSONAL_ACCOUNT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const unBlacklistPersonalAccount = (id, callback) => {
	return (dispatch) => {
		dispatch({ type: BLACKLIST_PERSONAL_ACCOUNT_START });
		Axios.post(`/merchants/change-user-status/${id}`, {
			status: "active",
		})
			.then((res) => {
				dispatch({
					type: BLACKLIST_PERSONAL_ACCOUNT_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificPersonalAccount(id));
				toastSuccess(res.data.message);
				callback();
			})
			.catch((error) => {
				dispatch({
					type: BLACKLIST_PERSONAL_ACCOUNT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const revertPersonalAccountMerchantFee = (user_uuid, fee_id) => {
	return (dispatch) => {
		dispatch({ type: REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_START });
		Axios.get(`/merchants/fees/revert/${user_uuid}/${fee_id}`)
			.then((res) => {
				dispatch({
					type: REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS,
					payLoad: res.data.data,
				});
				// window.location.reload();
				dispatch(getSpecificPersonalAccountFees(user_uuid));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const revertAccountMerchantFee = (user_uuid, userType, fee_id) => {
	return (dispatch) => {
		dispatch({ type: REVERT_ACCOUNT_MERCHANT_FEE_START });
		Axios.get(`/merchants/fees/revert/${user_uuid}/${fee_id}`)
			.then((res) => {
				dispatch({
					type: REVERT_ACCOUNT_MERCHANT_FEE_SUCCESS,
					payLoad: res.data.data,
				});
				// window.location.reload();
				if (userType === 'personal') {
					dispatch(getSpecificPersonalAccountFees(user_uuid));
				} else {
					dispatch(getBusinessAccountFees(user_uuid));
					toastSuccess(res.data.message);
				}

			})
			.catch((error) => {
				dispatch({
					type: REVERT_ACCOUNT_MERCHANT_FEE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updatePersonalAccountMerchantFee = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_START });
		Axios.post(`/merchants/fees/custom/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS,
					payLoad: res.data.data,
				});
				// window.location.reload();
				dispatch(getSpecificPersonalAccountFees(id));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updatePersonalAccountMerchantServices = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START });
		Axios.put(`/merchants/personal/services/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificPersonalAccount(id));
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updatePersonalAccountBvn = (id, bvn) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PERSONAL_ACCOUNT_BVN_START });
		Axios.post(`/merchants/edit/personal/bvn/${id}`, {
			bvn,
		})
			.then((res) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_BVN_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificPersonalAccount(id));
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_BVN_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updatePersonalAccountAddress = (id, contact_address) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PERSONAL_ACCOUNT_ADDRESS_START });
		Axios.post(`/merchants/edit/personal/address/${id}`, {
			contact_address,
		})
			.then((res) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_ADDRESS_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificPersonalAccount(id));
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_ADDRESS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updatePersonalAccountServices = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START });
		Axios.put(`/merchants/personal/services/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificPersonalAccountServices(id));
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updatePersonalAccount2FA = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_START });
		Axios.put(`/merchants/personal/two-factor-authentication/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificPersonalAccountSecurity(id));
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updatePersonalAccountIpProtection = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_START });
		Axios.put(`/merchants/personal/update-ip-protection/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificPersonalAccountSecurity(id));
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updatePersonalAccountCompliance = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_START });
		Axios.post(`/merchants/edit/business/update-document`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificPersonalAccountCompliance(id));
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updatePersonalAccountComplianceText = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_START });
		Axios.post(`/merchants/edit/business/update-compliance-text`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificPersonalAccountCompliance(id));
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const fundPersonalAccount = (id, payload, callback) => {
	return (dispatch) => {
		dispatch({ type: FUND_PERSONAL_ACCOUNT_START });
		Axios.post(`/merchants/fund`, payload)
			.then((res) => {
				dispatch({
					type: FUND_PERSONAL_ACCOUNT_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificPersonalAccount(id));
				callback();
			})
			.catch((error) => {
				dispatch({
					type: FUND_PERSONAL_ACCOUNT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

// AGENTS

export const getAllAgentsAccounts = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_AGENTS_ACCOUNTS_START });
		Axios.get(`/banking-agents/approved-agents`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_AGENTS_ACCOUNTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_AGENTS_ACCOUNTS_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getAllAgentsAccountsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`/banking-agents/approved-agents`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

// BLACKLISTED

export const getAllBlacklistedAccounts = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_BLACKLISTED_ACCOUNTS_START });
		Axios.get(`/merchants/blacklist`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_BLACKLISTED_ACCOUNTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ALL_BLACKLISTED_ACCOUNTS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

// LIMIT REQUESTS

export const getAllLimitRequests = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_LIMIT_REQUESTS_START });
		Axios.get(`/account-tier/requests`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_LIMIT_REQUESTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_LIMIT_REQUESTS_FAILURE, payLoad: error });
				handler(error);
			});
	};
};



export const getAllLimitRequestsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`/transfers/limits/requests`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getSpecificLimitRequest = (owner_type, owner_uuid) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_LIMIT_REQUEST_START });
		Axios.get(`/account-tier/${owner_type}/${owner_uuid}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_LIMIT_REQUEST_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_LIMIT_REQUEST_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getLimitRequestDetails = (request_id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_LIMIT_REQUEST_START });
		Axios.get(`/account-tier/requests/single/${request_id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_LIMIT_REQUEST_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_LIMIT_REQUEST_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getSystemLimits = (accountType) => {
	return (dispatch) => {
		dispatch({ type: GET_SYSTEM_LIMITS_START });
		Axios.get(`/account-tier/all/${accountType}`)
			.then((res) => {
				dispatch({
					type: GET_SYSTEM_LIMITS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SYSTEM_LIMITS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const approveLimitRequest = (request_id, tier) => {
	return (dispatch) => {
		dispatch({ type: APPROVE_SPECIFIC_LIMIT_REQUEST_START });
		Axios.put(`/account-tier/approve-request/${request_id}`, {
			tier_id: tier?.id,
		})
			.then((res) => {
				dispatch({
					type: APPROVE_SPECIFIC_LIMIT_REQUEST_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificLimitRequest(request_id));
				toastSuccess(res.data.message);
				window.history.back();
			})
			.catch((error) => {
				dispatch({
					type: APPROVE_SPECIFIC_LIMIT_REQUEST_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const rejectLimitRequest = (request_id, payload) => {
	return (dispatch) => {
		dispatch({ type: REJECT_SPECIFIC_LIMIT_REQUEST_START });
		Axios.put(`/account-tier/reject-request/${request_id}`, {
			...payload,
		})
			.then((res) => {
				dispatch({
					type: REJECT_SPECIFIC_LIMIT_REQUEST_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificLimitRequest(request_id));
				toastSuccess(res.data.message);
				window.history.back();
			})
			.catch((error) => {
				dispatch({
					type: REJECT_SPECIFIC_LIMIT_REQUEST_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateTransferLimit = (owner_uuid, payload, callback) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_TRANSFER_LIMIT_START });
		Axios.post(`/transfers/limits/update`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_TRANSFER_LIMIT_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				// window.location.reload();
				callback();
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_TRANSFER_LIMIT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const revertTransferLimit = (owner_uuid, callback) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_TRANSFER_LIMIT_START });
		Axios.put(`/transfers/limits/set-default/${owner_uuid}`)
			.then((res) => {
				dispatch({
					type: UPDATE_TRANSFER_LIMIT_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificLimitRequest(owner_uuid));
				callback();
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_TRANSFER_LIMIT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

// BUSINESS

export const getAllBusinessAccounts = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_BUSINESS_ACCOUNTS_START });
		Axios.get(`/merchants/business`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_BUSINESS_ACCOUNTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_BUSINESS_ACCOUNTS_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getAllBusinessAccountsWithSearch = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_BUSINESS_ACCOUNTS_START });
		Axios.get(`/merchants/search-business`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_BUSINESS_ACCOUNTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_BUSINESS_ACCOUNTS_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getAllBusinessAccountsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`/merchants/business`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getSpecificBusinessAccount = (business_uuid) => {

	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_BUSINESS_ACCOUNT_START });
		Axios.get(`/merchants/business-with-uuid/${business_uuid}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_BUSINESS_ACCOUNT_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_BUSINESS_ACCOUNT_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getBusinessAccountServices = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START });
		Axios.get(`/merchants/services/${id}`)
			.then((res) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getBusinessAccountFees = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_BUSINESS_ACCOUNT_MERCHANT_FEES_START });
		Axios.get(`/merchants/fees/all-fees/${id}`)
			.then((res) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_MERCHANT_FEES_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_MERCHANT_FEES_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getBusinessAccountSecurity = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_START });
		Axios.get(`/merchants/two-factor-authentication/${id}`)
			.then((res) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getBusinessAccountCompliance = (business_uuid) => {
	return (dispatch) => {
		dispatch({ type: GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_START });
		Axios.get(
			`/merchants/compliance/business-approved-compliance/${business_uuid}`
		)
			.then((res) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getBusinessSettlementAccounts = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_START });
		Axios.get(`/merchants/settlement-account/${id}`)
			.then((res) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getBusinessSettlementType = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START });
		Axios.get(`/merchants/settlement-type/${id}`)
			.then((res) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS,
					payLoad: res.data.data?.[0],
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getBusiness2FA = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_BUSINESS_ACCOUNT_2FA_START });
		Axios.get(`/merchants/two-factor-authentication/${id}`)
			.then((res) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_2FA_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_2FA_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getBusinessInstantSettlement = (business_uuid) => {
	return (dispatch) => {
		dispatch({ type: GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START });
		Axios.get(`/merchants/instant-settlement/${business_uuid}`)
			.then((res) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const blacklistBusinessAccount = (
	user_uuid,
	business_uuid,
	callback
) => {
	return (dispatch) => {
		dispatch({ type: BLACKLIST_BUSINESS_ACCOUNT_START });
		Axios.post(`/merchants/change-business-status/${business_uuid}`, {
			status: "blocked",
		})
			.then((res) => {
				dispatch({
					type: BLACKLIST_BUSINESS_ACCOUNT_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificBusinessAccount(business_uuid, user_uuid));
				toastSuccess(res.data.message);
				callback();
			})
			.catch((error) => {
				dispatch({
					type: BLACKLIST_BUSINESS_ACCOUNT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const unBlacklistBusinessAccount = (
	user_uuid,
	business_uuid,
	callback
) => {
	return (dispatch) => {
		dispatch({ type: BLACKLIST_BUSINESS_ACCOUNT_START });
		Axios.post(`/merchants/change-business-status/${business_uuid}`, {
			status: "active",
		})
			.then((res) => {
				dispatch({
					type: BLACKLIST_BUSINESS_ACCOUNT_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificBusinessAccount(business_uuid, user_uuid));
				toastSuccess(res.data.message);
				callback();
			})
			.catch((error) => {
				dispatch({
					type: BLACKLIST_BUSINESS_ACCOUNT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const revertBusinessAccountMerchantFee = (business_uuid, fee_id) => {
	return (dispatch) => {
		dispatch({ type: REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_START });
		Axios.get(`/merchants/fees/revert/${business_uuid}/${fee_id}`)
			.then((res) => {
				dispatch({
					type: REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS,
					payLoad: res.data.data,
				});
				// window.location.reload();
				dispatch(getBusinessAccountFees(business_uuid));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountMerchantFee = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_START });
		Axios.post(`/merchants/fees/custom/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS,
					payLoad: res.data.data,
				});
				// window.location.reload();
				dispatch(getBusinessAccountFees(id));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateAccountMerchantFee = (id, userType, payload, onSuccess=()=>{}) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_ACCOUNT_MERCHANT_FEE_START });
		Axios.post(`/merchants/fees/custom/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_ACCOUNT_MERCHANT_FEE_SUCCESS,
					payLoad: res.data.data,
				});
				// window.location.reload();
				if (userType !== 'personal') {
					dispatch(getBusinessAccountFees(id));
				} else {
					dispatch(getSpecificPersonalAccountFees(id));
				}
				toastSuccess(res.data.message);
				onSuccess();
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_ACCOUNT_MERCHANT_FEE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountMerchantServices = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START });
		Axios.put(`/merchants/services/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getBusinessAccountServices(id));
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountBvn = (id, bvn) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_BVN_START });
		Axios.post(`/merchants/edit/business/business-bvn/${id}`, {
			bvn,
		})
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_BVN_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificBusinessAccount(id));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_BVN_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountAddress = (
	user_uuid,
	business_uuid,
	business_address
) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_ADDRESS_START });
		Axios.post(`/merchants/edit/business/business-address/${user_uuid}`, {
			business_address,
		})
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_ADDRESS_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificBusinessAccount(business_uuid, user_uuid));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_ADDRESS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountName = (
	user_uuid,
	business_uuid,
	business_name
) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_NAME_START });
		Axios.post(`/merchants/edit/business/business-name/${business_uuid}`, {
			business_name,
		})
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_NAME_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificBusinessAccount(business_uuid, user_uuid));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_NAME_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountEmail = (
	user_uuid,
	business_uuid,
	business_email
) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_EMAIL_START });
		Axios.post(`/merchants/edit/business/business-email/${business_uuid}`, {
			business_email,
		})
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_EMAIL_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificBusinessAccount(business_uuid, user_uuid));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_EMAIL_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountPhone = (
	user_uuid,
	business_uuid,
	contact_phone
) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_PHONE_START });
		Axios.post(`/merchants/edit/business/business-phone/${business_uuid}`, {
			contact_phone,
		})
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_PHONE_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getSpecificBusinessAccount(business_uuid, user_uuid));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_PHONE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountDetails = (
	business_uuid,
	business_description
) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_DETAILS_START });
		Axios.post(
			`/merchants/edit/business/business-description/${business_uuid}`,
			{
				business_description,
			}
		)
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_DETAILS_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getBusinessAccountCompliance(business_uuid));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_DETAILS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountComplianceDocument = (
	business_uuid,
	payload
) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_START });
		Axios.post(`/merchants/edit/business/update-document`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getBusinessAccountCompliance(business_uuid));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountComplianceText = (business_uuid, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_START });
		Axios.post(`/merchants/edit/business/update-compliance-text`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getBusinessAccountCompliance(business_uuid));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountRC = (business_uuid, rc_number) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_RC_START });
		Axios.post(`/merchants/edit/business/rc-number/${business_uuid}`, {
			rc_number,
		})
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_RC_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getBusinessAccountCompliance(business_uuid));
				toastSuccess(res.data.message);
			})

			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_RC_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessSettlementAccount = ({
	user_uuid,
	business_uuid,
	account_id,
	callback,
}) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_START });
		Axios.put(`/merchants/settlement-account/${business_uuid}`, { account_id })
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getBusinessInstantSettlement(business_uuid));
				toastSuccess(res.data.message);
				callback();
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessSettlementType = (
	business_uuid,
	user_uuid,
	type,
	callback
) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START });
		Axios.put(`/merchants/settlement-type/${business_uuid}`, { type })
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getBusinessSettlementType(business_uuid));
				dispatch(getSpecificBusinessAccount(business_uuid, user_uuid));
				dispatch(getBusinessInstantSettlement(business_uuid));
				toastSuccess(res.data.message);
				callback();
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccount2FA = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_START });
		Axios.put(`/merchants/two-factor-authentication/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getBusinessAccountSecurity(id));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const updateBusinessAccountIpProtection = (id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_START });
		Axios.put(`/merchants/update-ip-protection/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getBusinessAccountSecurity(id));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

/**
 *
 * @param {string} business_uuid Uuid of given business
 * @param {string} id instant settlement id
 * @param {Object} payload request body : {instant: 0 | 1}
 * @returns
 */

export const updateBusinessAccountInstantSettlement = (
	business_uuid,
	instant_settlement_id,
	payload
) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START });
		Axios.put(
			`/merchants/instant-settlement/${business_uuid}/${instant_settlement_id}`,
			payload
		)
			.then((res) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS,
					payLoad: res.data.data,
				});
				dispatch(getBusinessInstantSettlement(business_uuid));
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const fundBusinessAccount = (
	user_uuid,
	business_uuid,
	payload,
	callback
) => {
	return (dispatch) => {
		dispatch({ type: FUND_BUSINESS_ACCOUNT_START });
		Axios.post(`/merchants/fund`, payload)
			.then((res) => {
				dispatch({
					type: FUND_BUSINESS_ACCOUNT_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				dispatch(getSpecificBusinessAccount(business_uuid, user_uuid));
				callback();
			})
			.catch((error) => {
				dispatch({
					type: FUND_BUSINESS_ACCOUNT_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const getMultiCurrencies = (param, callback = () => { }) => {
	return dispatch => {
		dispatch({ type: GET_MULTI_CURRENCIES_START })
		Axios
			.get(`merchants/business-multicurrency-accounts/${param.user_uuid}/${param.business_uuid}`)
			.then(res => {
				dispatch({
					type: GET_MULTI_CURRENCIES_SUCCESS,
					payLoad: res.data.data
				})
				callback(res.data.data)
			})
			.catch(error => {
				dispatch({ type: GET_MULTI_CURRENCIES_FAILURE })
				handler(error, true)
			})
	}
}

import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { TextSkeleton } from "../../components/skeletons";

import { FiCopy, FiMoreVertical } from "react-icons/fi";
import {
  convertObjectToArray,
  copyTextToClipboard,
  fixDateForAllBrowsers,
  isEmpty,
  isArray,
  isEmptyObject,
  statusEnum,
  truncateRef,
} from "../../helpers/helper";
import PillIndicator from "../../components/indicators/PillIndicator";
import useAccounts from "../../hooks/useAccounts";
import {
  blacklistBusinessAccount,
  getBusinessAccountCompliance,
  getBusinessAccountFees,
  getBusinessAccountSecurity,
  getBusinessAccountServices,
  getBusinessInstantSettlement,
  getBusinessSettlementType,
  getMultiCurrencies,
  getSpecificBusinessAccount,
  getSpecificLimitRequest,
  unBlacklistBusinessAccount,
  updateBusinessAccount2FA,
  updateBusinessAccountInstantSettlement,
  updateBusinessAccountIpProtection,
} from "../../redux/accounts/accountsActions";
import { PopoverMain } from "../../components/popovers/Popover";
import FunAccountModal from "./EditPersonalBvnModal";
import MemoizedLoadingModal from "../../components/modals/LoadingModal";
import CheckboxInput from "../../components/inputs/CheckBoxInput";
import BasicModal from "../../components/modals/BasicModal";
import { MdOutlineClose } from "react-icons/md";
import EditPosFeeModal from "./EditPosFeeModal";
import EditBusinessFeeModal from "./EditBusinessFeeModal";
import FundBusinessAccountModal from "./FundBusinessAccountModal";
import EditBusinessServicesModal from "./EditBusinessServicesModal";
import EditBusinessAddressModal from "./EditBusinessAddressModal";
import EditBusinessNameModal from "./EditBusinessNameModal";
import EditBusinessEmailModal from "./EditBusinessEmailModal";
import EditBusinessPhoneModal from "./EditBusinessPhoneModal";
import EditBusinessComplianceModal from "./EditBusinessComplianceModal";
import EditBusinessSettlementAccountModal from "./EditBusinessSettlementAccount";
import EditBusinessDescriptionModal from "./EditBusinessDescriptionModal";
import Button from "../../components/buttons/Button";
import EditBusinessSettlementTypeModal from "./EditBusinessSettlementTypeModal";
import EditTransferLimitModal from "./EditTransferLimitModal";
import BusinessComplianceCard from "./BusinessComplianceCard";

export default function BusinessAccountDetails() {
  const { id, user_uuid } = useParams();
  const dispatch = useDispatch();

  const [showBlockAccountModal, setShowBlockAccountModal] = useState(false);
  const [showUnblockAccountModal, setShowUnblockAccountModal] = useState(false);
  const [selectedFee, setSelectedFee] = useState({});
  const [showComplianceModal, setShowComplianceModal] = useState(false);
  const [dataToShow, setDataToShow] = useState("");
  const [fees, setFees] = useState([]);
  const [accountIndex, setAccountIndex] = useState(0);
  const [showKey, setShowKey] = useState(false);

  // Modal States
  const [modalStates, setModalStates] = useState({
    showPosFeeModal: false,
    showEditDailyTransferLimit: false,
    showEditBusinessBvnModal: false,
    showEditBusinessNameModal: false,
    showEditBusinessEmailModal: false,
    showEditBusinessPhoneModal: false,
    showEditBusinessAddressModal: false,
    showEditBusinessServicesModal: false,
    showEditBusinessComplianceModal: false,
    showEditBusinessSettlementAccountModal: false,
    showEditBusinessSettlementTypeModal: false,
    showEditBusinessDescriptionModal: false,
    showFundAccountModal: false,
  });
  const [defaultCurrency, setDefaultCurrency] = useState({});

  const {
    isLoadingSpecificBusinessAccounts: loading,
    specificBusinessAccounts: details,
    isLoadingBusinessMerchantServices,
    isLoadingBusinessMerchantFees,
    isLoadingBusinessSecuritySettings,
    isUpdatingBusinessAccountMerchantServices,
    isUpdatingBusinessAccount2FA,
    isUpdatingBusinessAccountIP,
    isUpdatingBusinessAccountInstantSettlement,
    allBusinessMerchantFees,
    specificBusinessAccountSecuritySettings,
    allBusinessMerchantServices,
    specificBusinessSettlementAccount,
    isLoadingBusinessAccountInstantSettlement,
    isBlacklistingBusinessAccount,
    specificBusinessSettlementType,
    isLoadingSpecificLimitRequest,
    isPosFeeUpdated,
    isOtherFeesUpdated,
    multiCurrencies,
  } = useAccounts();

  useEffect(() => {
    dispatch(getSpecificBusinessAccount(id, user_uuid));
    dispatch(getBusinessAccountServices(id));
    dispatch(getBusinessAccountFees(id));
    dispatch(getBusinessAccountSecurity(id));
    dispatch(getBusinessInstantSettlement(id));
    dispatch(getBusinessSettlementType(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onUpdateIpProtection = (setting) => {
    dispatch(
      updateBusinessAccountIpProtection(id, {
        ip_protected: !!setting ? 0 : 1,
      })
    );
  };

  const onUpdate2faSetting = (setting) => {
    dispatch(
      updateBusinessAccount2FA(id, {
        "2fa_enabled": !!setting ? 0 : 1,
      })
    );
  };

  const onUpdateInstantSettlementSetting = (setting) => {
    dispatch(
      updateBusinessAccountInstantSettlement(
        id,
        specificBusinessSettlementAccount?.id,
        {
          instant: !!setting ? 0 : 1,
        }
      )
    );
  };

  const onBlockAccount = () => {
    dispatch(
      blacklistBusinessAccount(user_uuid, id, () =>
        setShowBlockAccountModal(false)
      )
    );
  };

  const onUnblockAccount = () => {
    dispatch(
      unBlacklistBusinessAccount(user_uuid, id, () =>
        setShowUnblockAccountModal(false)
      )
    );
  };

  const closePreviewCompliance = () => {
    setShowComplianceModal(false);
    setDataToShow("");
  };

  useEffect(() => {
    dispatch(
      getMultiCurrencies(
        {
          user_uuid: user_uuid,
          business_uuid: id,
        },
        (data) => setDefaultCurrency(data?.accounts?.[0])
      )
    );
  }, [details]);

  const openFundModal = () => {
    setDefaultCurrency(multiCurrencies?.accounts?.[0]);
    setModalStates({
      ...modalStates,
      showFundAccountModal: true,
    });
  };

  useEffect(() => {
    if (isEmptyObject(allBusinessMerchantFees?.fees)) {
      setFees([]);
    } else {
      setFees(convertObjectToArray(allBusinessMerchantFees?.fees));
    }
    if (isPosFeeUpdated) {
      setModalStates({ ...modalStates, showPosFeeModal: false });
    }
    if (isOtherFeesUpdated) {
      setModalStates({ ...modalStates, showEditBusinessFeeModal: false });
    }
    return () => {
      setFees([]);
    };
  }, [allBusinessMerchantFees]);

  return (
    <div className="p-3 flex flex-col">
      <div className="mb-5">
        <BreadCrumbs title={"Accounts"} goBack />
      </div>

      <div className="flex flex-col bg-white shadow rounded-sm p-8 py-2 mb-8">
        <div className="py-3 border-b-2 border-solid border-gray-100 flex items-center justify-between">
          <div className="flex items-center">
            <p className="glade-black glade-normal-text-two mr-1">
              {(details?.account_information?.length > 1) 
                ? "Account Balances"
                : "Account Balance"}
              :
            </p>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <div className="flex">
                <select
                  onChange={(e) => setAccountIndex(e.target.value)}
                  className="glade-blue-darker glade-normal-text-two border-solid border-gray-100"
                >
                  {isArray(details?.account_information) ? (
                    details?.account_information.map((account, index) => (
                      <option
                        key={index}
                        value={index}
                        className="glade-blue-darker glade-normal-text-two"
                      >
                        {account?.currency ?? "NGN"}{" "}
                        {Number(account?.balance ?? 0)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </option>
                    ))
                  ) : (
                    <option>Not Available</option>
                  )}
                </select>
              </div>
            )}
          </div>
          {!loading && (
            <PopoverMain
              button={
                <div type="button" className={"py-2 glade-ash "}>
                  <div className="inline-flex items-center justify-items-center text-center text-2xl">
                    <FiMoreVertical
                      color="#151515"
                      className="mx-auto cursor-pointer"
                    />
                  </div>
                </div>
              }
              popoverClassName="right-0"
            >
              <ul className="px-2 py-5 bg-white shadow rounded-md w-56">
                <li
                  className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"
                  onClick={openFundModal}
                >
                  Fund Account
                </li>
                {details?.business_details?.business_settlement_accounts
                  ?.length > 0 && (
                  <li
                    className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"
                    onClick={() => {
                      setModalStates({
                        ...modalStates,
                        showEditBusinessSettlementAccountModal: true,
                      });
                    }}
                  >
                    Change Settlement Account
                  </li>
                )}
                {details?.business_details?.business_settlement_accounts
                  ?.length > 0 && (
                  <li
                    className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"
                    onClick={() => {
                      setModalStates({
                        ...modalStates,
                        showEditBusinessSettlementTypeModal: true,
                      });
                    }}
                  >
                    Change Settlement Type
                  </li>
                )}
                <Link
                  to={`/dashboard/outflows/bills?search=${details?.business_details?.business?.business_name}`}
                >
                  <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
                    View Bills
                  </li>
                </Link>
                <Link
                  to={`/dashboard/outflows?search=${details?.business_details?.business?.business_name}`}
                >
                  <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
                    View Transfers
                  </li>
                </Link>
                <Link to={`/dashboard/accounts/transaction/history/${id}`}>
                  <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
                    All Transactions
                  </li>
                </Link>
                {/* <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
								View Payments
							</li> */}
                {details?.status === "active" ? (
                  <li
                    className="p-3 glade-normal-text-two glade-red glade-hover-bg-red-100 cursor-pointer"
                    onClick={() => {
                      setShowBlockAccountModal(true);
                    }}
                  >
                    Block account
                  </li>
                ) : (
                  details?.status !== "active" && (
                    <li
                      className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"
                      onClick={() => {
                        setShowUnblockAccountModal(true);
                      }}
                    >
                      Unblock Account
                    </li>
                  )
                )}
              </ul>
            </PopoverMain>
          )}
        </div>
        {!isEmptyObject(details) && (
          <>
            <div className="mt-3 space-y-5 border-b border-glade-gray-50 mb-3 pb-2">
              {details?.account_information?.map((accountDetail, index) => (
                <div className="">
                  <div className="border-b border-glade-gray-50 py-2">
                    <h3 className="text-glade-black-300 font-bold">
                      Account {index + 1}
                    </h3>
                  </div>
                  <div className="grid grid-cols-4 gap-4 py-3">
                    <div className="flex flex-col">
                      <h5 className="glade-normal-text-two glade-ash py-1">
                        Account Name
                      </h5>
                      {loading ? (
                        <TextSkeleton width="100px" />
                      ) : (
                        <h6 className="glade-normal-text-three glade-black py-1">
                          {accountDetail?.account_name}
                        </h6>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <h5 className="glade-normal-text-two glade-ash py-1">
                        Account Number
                      </h5>
                      {loading ? (
                        <TextSkeleton width="100px" />
                      ) : (
                        <h6 className="glade-normal-text-three glade-black py-1">
                          {accountDetail?.account_number || "Not available"}
                        </h6>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <h5 className="glade-normal-text-two glade-ash py-1">
                        Bank Name
                      </h5>
                      {loading ? (
                        <TextSkeleton width="100px" />
                      ) : (
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                          {accountDetail?.bank_name || "N/A"}
                        </h6>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <h5 className="glade-normal-text-two glade-ash py-1">
                        Email
                      </h5>
                      {loading ? (
                        <TextSkeleton width="100px" />
                      ) : (
                        <h6 className="glade-normal-text-three glade-black py-1">
                          {details[0]?.bank_email || "N/A"}
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="grid grid-cols-4 gap-4 py-3">
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Phone Number
                </h5>
                {loading ? (
                  <TextSkeleton width="100px" />
                ) : (
                  <h6 className="glade-normal-text-three glade-black py-1">
                      {details?.account_phone || "N/A"}
                  </h6>
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">Email</h5>
                {loading ? (
                  <TextSkeleton width="100px" />
                ) : (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {details?.account_email}
                  </h6>
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Settlement Account
                </h5>
                {isLoadingBusinessAccountInstantSettlement ? (
                  <TextSkeleton width="100px" />
                ) : (
                  <>
                    {isEmpty(specificBusinessSettlementAccount) ? (
                      <h6 className="glade-normal-text-three glade-black py-1">
                        Not available
                      </h6>
                    ) : (
                      <>
                        {specificBusinessSettlementAccount?.bank_name && (
                          <h6 className="glade-normal-text-three glade-black py-1">
                            {specificBusinessSettlementAccount?.bank_name}
                          </h6>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              {details[0]?.created_at && (
                <div className="flex flex-col">
                  <h5 className="glade-normal-text-two glade-ash py-1">
                    Date Created
                  </h5>
                  {loading ? (
                    <TextSkeleton width="100px" />
                  ) : (
                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                      {dfn.format(
                        new Date(fixDateForAllBrowsers(details[0]?.created_at)),
                        "MMM d, yyyy"
                      )}
                    </h6>
                  )}
                </div>
              )}
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Account Status
                </h5>
                {loading ? (
                  <TextSkeleton width="100px" />
                ) : (
                  <PillIndicator
                    className="px-5 py-1 capitalize"
                    type={statusEnum[details?.status]}
                    title={details?.status}
                  />
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Business Type
                </h5>
                {loading ? (
                  <TextSkeleton width="100px" />
                ) : (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    Business
                  </h6>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {/* Account Settings */}
      <section className="grid grid-cols-3 gap-x-5">
        <div className="flex flex-col bg-white shadow rounded-md p-8 pt-4 h-fit-content">
          <div className="py-3 border-b-2 border-solid border-gray-100 mb-5">
            <p className="glade-black glade-normal-text-two">
              Account Settings
            </p>
          </div>

          {/* Business UUID */}
          {isLoadingBusinessMerchantFees ? (
            <TextSkeleton />
          ) : (
            <div className="space-y-4">
              {id && (
                <div className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4">
                  <div className="flex flex-col justify-between">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                      Business UUID
                    </h5>
                    <h6 className="glade-small-text-two glade-black py-1">
                      {id}
                    </h6>
                  </div>
                  <h6 className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer flex-shrink-0">
                    <FiCopy
                      size={20}
                      className="ml-3 cursor-pointer glade-blue-darker"
                      onClick={() => copyTextToClipboard(id)}
                    />
                  </h6>
                </div>
              )}

              {/* Merchant ID */}
              {allBusinessMerchantFees?.merchant_credentials && (
                <div className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4 max-w-full">
                  <div className="flex flex-col justify-between">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                      Merchant ID
                    </h5>
                    <h6 className="glade-small-text-two glade-black py-1 break-all">
                      {isArray(allBusinessMerchantFees?.merchant_credentials) &&
                      allBusinessMerchantFees?.merchant_credentials[0].mid
                        ? allBusinessMerchantFees?.merchant_credentials[0].mid
                        : "N/A"}
                    </h6>
                  </div>
                  <h6 className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer flex-shrink-0">
                    {isArray(allBusinessMerchantFees?.merchant_credentials) &&
                      allBusinessMerchantFees?.merchant_credentials[0].mid && (
                        <FiCopy
                          size={20}
                          className="ml-3 cursor-pointer glade-blue-darker"
                          onClick={() =>
                            copyTextToClipboard(
                              allBusinessMerchantFees?.merchant_credentials[0]
                                .mid
                            )
                          }
                        />
                      )}
                  </h6>
                </div>
              )}

              {/* Merchant Key */}
              {allBusinessMerchantFees?.merchant_credentials && (
                <div className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4 max-w-full">
                  <div className="flex flex-col justify-between">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                      Merchant Key
                    </h5>
                    <h6 className="glade-small-text-two glade-black py-1 break-all">
                      {isArray(allBusinessMerchantFees?.merchant_credentials) &&
                      allBusinessMerchantFees?.merchant_credentials[0]?.key
                        ? !showKey
                          ? truncateRef(
                              allBusinessMerchantFees?.merchant_credentials[0]
                                ?.key,
                              0,
                              "**********************************"
                            )
                          : allBusinessMerchantFees?.merchant_credentials[0]
                              ?.key
                        : "N/A"}
                    </h6>
                  </div>
                  {isArray(allBusinessMerchantFees?.merchant_credentials) &&
                    allBusinessMerchantFees?.merchant_credentials[0].key && (
                      <h6
                        className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer"
                        onClick={() => {
                          setShowKey(!showKey);
                        }}
                      >
                        {!showKey ? "Show" : "Hide"}
                      </h6>
                    )}
                </div>
              )}

              {/* Daily Transfer Limit */}
              <div className="flex items-center justify-between rounded p-3 border border-gray-300">
                {isLoadingSpecificLimitRequest ? (
                  <TextSkeleton />
                ) : (
                  <>
                    <div className="flex flex-col justify-between">
                      <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                        Daily Transfer Limit
                      </h5>
                      <div className="flex items-center">
                        <h5 className="glade-normal-text-two glade-black py-1 capitalize">
                          NGN{" "}
                          {details[0]?.transfer_limit
                            ? Number(details[0]?.transfer_limit).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "0.00"}
                        </h5>
                      </div>
                    </div>
                    <h6
                      className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer"
                      onClick={() => {
                        setModalStates({
                          ...modalStates,
                          showEditDailyTransferLimit: true,
                        });
                      }}
                    >
                      Edit
                    </h6>
                  </>
                )}
              </div>

              {/* Fees */}
              {fees?.map((fee, idex) => (
                <div
                  key={idex}
                  className="flex items-center justify-between rounded p-3 border border-gray-300"
                >
                  <div className="flex flex-col justify-between">
                    <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                      {fee?.fee_code === "ga_pos"
                        ? "POS Fee"
                        : fee?.display_name?.replaceAll("_", " ") ||
                          fee?.fee_code?.replaceAll("_", " ")}
                    </h5>

                    {fee?.conditional && JSON.parse(fee?.conditions).length ? (
                      <>
                        <div className="flex items-center">
                          <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                            Default:
                          </h5>
                          <h6 className="glade-normal-text-three glade-black py-1 capitalize ml-2">
                            {fee?.fee_percentage}% + {fee?.fee_fixed}
                          </h6>
                        </div>
                        {JSON.parse(fee?.conditions)?.map((condition, key) => (
                          <div className="flex items-center" key={key}>
                            <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                              NGN{" "}
                              {Number(condition?.limit).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}{" "}
                              Limit:
                            </h5>
                            <h6 className="glade-normal-text-three glade-black py-1 capitalize ml-2">
                              {condition?.fee_percentage}% +{" "}
                              {condition?.fee_fixed}
                            </h6>
                          </div>
                        ))}
                      </>
                    ) : fee?.fee_code.includes("pos") ? (
                      <div>
                        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                          Default:{" "}
                          <span className="glade-normal-text-two glade-black py-1">
                            {fee?.fee_percentage}% + NGN {fee?.fee_fixed}
                          </span>
                        </h5>
                        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                          Cap:{" "}
                          <span className="glade-normal-text-two glade-black py-1">
                            NGN {fee?.fee_cap || "0.00"}
                          </span>
                        </h5>
                      </div>
                    ) : (
                      <div>
                        <h5 className="glade-normal-text-two glade-black py-1 capitalize">
                          {fee?.fee_percentage}% + NGN {fee?.fee_fixed}
                        </h5>
                        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                          Cap:{" "}
                          <span className="glade-normal-text-two glade-black py-1">
                            NGN {fee?.fee_cap || "0.00"}
                          </span>
                        </h5>
                      </div>
                    )}
                  </div>
                  <h6
                    className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer flex-shrink-0"
                    onClick={() => {
                      setSelectedFee(fee);
                      if (fee?.fee_code.includes("pos")) {
                        setModalStates({
                          ...modalStates,
                          showPosFeeModal: true,
                        });
                      } else {
                        setModalStates({
                          ...modalStates,
                          showEditBusinessFeeModal: true,
                        });
                      }
                    }}
                  >
                    Edit
                  </h6>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Compliance Card*/}
        <BusinessComplianceCard id={id} details={details} />

        {/* Services and Security */}
        <div>
          {/* Services Card */}
          <div className="flex flex-col bg-white shadow rounded-md p-8 py-4">
            <div className="py-3 border-b-2 border-solid border-gray-100 mb-5 flex items-center justify-between">
              <p className="glade-black glade-normal-text-two">Services</p>
              {isLoadingBusinessMerchantServices ? (
                <TextSkeleton width="40px" />
              ) : (
                <h6
                  className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer flex-shrink-0"
                  onClick={() => {
                    setModalStates({
                      ...modalStates,
                      showEditBusinessServicesModal: true,
                    });
                  }}
                >
                  Edit
                </h6>
              )}
            </div>

            {/* Services */}
            {isLoadingBusinessMerchantServices ? (
              <TextSkeleton />
            ) : (
              <>
                {allBusinessMerchantServices?.map((service, inx) => (
                  <div
                    className="flex items-center justify-between mb-8"
                    key={inx}
                  >
                    <h5 className="glade-normal-text-two glade-black py-1">
                      {service.name}
                    </h5>
                    <PillIndicator
                      className="px-5 py-1 capitalize"
                      type={statusEnum[service?.status]}
                      title={service?.status ? "Approved" : "Disabled"}
                    />
                  </div>
                ))}
              </>
            )}
          </div>

          {/* Security Card */}
          <div className="flex flex-col bg-white shadow rounded-md p-8 pt-4 mt-5">
            <div className="py-3 border-b-2 border-solid border-gray-100 mb-5 flex items-center justify-between">
              <p className="glade-black glade-normal-text-two">
                Security/Settlement Configuration
              </p>
            </div>

            {/* Services */}
            {isLoadingBusinessSecuritySettings ? (
              <TextSkeleton />
            ) : (
              <div className="px-4 space-y-8">
                <CheckboxInput
                  label="IP Protection"
                  checked={
                    specificBusinessAccountSecuritySettings?.user
                      ?.is_ip_protection_enabled
                  }
                  onChange={() =>
                    onUpdateIpProtection(
                      specificBusinessAccountSecuritySettings?.user
                        ?.is_ip_protection_enabled
                    )
                  }
                />
                <CheckboxInput
                  label="Two Factor Authentication"
                  checked={
                    specificBusinessAccountSecuritySettings?.user
                      ?.is_2fa_enabled
                  }
                  onChange={() =>
                    onUpdate2faSetting(
                      specificBusinessAccountSecuritySettings?.user
                        ?.is_2fa_enabled
                    )
                  }
                />
                {!isEmpty(specificBusinessSettlementAccount) &&
                  specificBusinessSettlementType?.type !== "wallet" && (
                    <CheckboxInput
                      label="Instant Settlement"
                      checked={specificBusinessSettlementAccount?.instant}
                      onChange={() =>
                        onUpdateInstantSettlementSetting(
                          specificBusinessSettlementAccount?.instant
                        )
                      }
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Modals */}

      <FunAccountModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditBusinessBvnModal={modalStates?.showEditBusinessBvnModal}
      />

      <EditBusinessAddressModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditBusinessAddressModal={modalStates?.showEditBusinessAddressModal}
      />

      <EditBusinessNameModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditBusinessNameModal={modalStates?.showEditBusinessNameModal}
      />
      {modalStates.showPosFeeModal ? (
        <EditPosFeeModal
          modalStates={modalStates}
          setModalStates={setModalStates}
          selectedFee={selectedFee}
          setSelectedFee={setSelectedFee}
          showPosFeeModal={modalStates?.showPosFeeModal}
        />
      ) : null}

      <EditBusinessEmailModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditBusinessEmailModal={modalStates?.showEditBusinessEmailModal}
      />

      <EditBusinessPhoneModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditBusinessPhoneModal={modalStates?.showEditBusinessPhoneModal}
      />

      <EditBusinessSettlementAccountModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditBusinessSettlementAccountModal={
          modalStates?.showEditBusinessSettlementAccountModal
        }
      />

      <EditBusinessSettlementTypeModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditBusinessSettlementTypeModal={
          modalStates?.showEditBusinessSettlementTypeModal
        }
      />

      <EditBusinessServicesModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        services={allBusinessMerchantServices}
        showEditBusinessServicesModal={
          modalStates?.showEditBusinessServicesModal
        }
      />
      {modalStates.showEditBusinessFeeModal ? (
        <EditBusinessFeeModal
          modalStates={modalStates}
          setModalStates={setModalStates}
          selectedFee={selectedFee}
          setSelectedFee={setSelectedFee}
          showEditBusinessFeeModal={modalStates?.showEditBusinessFeeModal}
        />
      ) : null}

      <FundBusinessAccountModal
        modalStates={modalStates}
        defaultCurrency={defaultCurrency}
        setModalStates={setModalStates}
        showFundAccountModal={modalStates?.showFundAccountModal}
      />

      <EditTransferLimitModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        showEditDailyTransferLimit={modalStates?.showEditDailyTransferLimit}
        owner_type="business"
        owner_uuid={id}
      />

      <MemoizedLoadingModal
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        visibility={
          isUpdatingBusinessAccountMerchantServices ||
          isUpdatingBusinessAccountIP ||
          isUpdatingBusinessAccount2FA ||
          isUpdatingBusinessAccountInstantSettlement
        }
      />

      {/* Show Compliance Modal */}
      <BasicModal
        visibility={showComplianceModal}
        onClose={closePreviewCompliance}
        onOpen={() => setShowComplianceModal(true)}
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        modelContentSection={
          <div>
            <div className="-mx-6 -mt-2 flex justify-between px-5 pb-3 border-b-2 border-solid border-gray-200 mb-4">
              <h4 className="glade-normal-text-two glade-black">Document</h4>
              <MdOutlineClose
                size={16}
                className="glade-black cursor-pointer"
                onClick={closePreviewCompliance}
              />
            </div>
            <img src={dataToShow} className="h-96 w-full" alt="" />
          </div>
        }
      />

      {/* BLock Account Modal */}
      <BasicModal
        visibility={showBlockAccountModal}
        onClose={() => setShowBlockAccountModal(false)}
        onOpen={() => setShowBlockAccountModal(true)}
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        modelContentSection={
          <div className="p-6 px-0">
            <h3 className="glade-black text-center mb-8">
              Are you sure you want to block this business?
            </h3>
            <Button.Danger
              className={"w-full"}
              onClick={onBlockAccount}
              loading={isBlacklistingBusinessAccount}
              title="Block"
            >
              {isBlacklistingBusinessAccount ? "Blacklisting" : "Blacklist"}
            </Button.Danger>
            <button
              onClick={() => setShowBlockAccountModal(false)}
              className="glade-normal-text-three glade-grey-dark w-full mx-auto text-center mt-6"
            >
              Cancel
            </button>
          </div>
        }
      />

      {/* Unblock Account Modal */}
      <BasicModal
        visibility={showUnblockAccountModal}
        onClose={() => setShowUnblockAccountModal(false)}
        onOpen={() => setShowUnblockAccountModal(true)}
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        modelContentSection={
          <div className="p-6 px-0">
            <h3 className="glade-black text-center mb-8">
              Are you sure you want to unblock this business?
            </h3>
            <Button.Dark
              onClick={onUnblockAccount}
              loading={isBlacklistingBusinessAccount}
              title="Unblock"
            >
              {isBlacklistingBusinessAccount ? "Blacklisting" : "Blacklist"}
            </Button.Dark>
            <button
              onClick={() => setShowUnblockAccountModal(false)}
              className="glade-normal-text-three glade-grey-dark w-full mx-auto text-center mt-6"
            >
              Cancel
            </button>
          </div>
        }
      />
    </div>
  );
}

import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { RiLoader5Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Accordion from "../../components/accordion/Accordion";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import AccountDetailsCard from "../../components/compliance/AccountDetailsCard";
import BusinessDocumentCard from "../../components/compliance/BusinessDocumentCard";
import BusinessInformationCard from "../../components/compliance/BusinessInformationCard";
import PersonalComplianceCard from "../../components/compliance/PersonalComplianceCard";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import BasicModal from "../../components/modals/BasicModal";
import { PopoverButton, PopoverMain } from "../../components/popovers/Popover";
import { TextSkeleton } from "../../components/skeletons";
import { statusEnum } from "../../helpers/helper";
import useCompliance from "../../hooks/useCompliance";

import {
	getBusinessDirectors,
	getBusinessPrimaryOwner,
	getSpecificBusinessCompliance,
	getSpecificPersonalCompliance,
	updateSpecificBusinessCompliance,
	updateSpecificPersonalCompliance,
} from "../../redux/compliance/complianceActions";
import { FormError } from "../../validation";
import InfoSection from "./InfoSection";

export default function ComplianceDetail() {
	const { id, type } = useParams();
	const dispatch = useDispatch();

	const {
		isLoadingSpecificBusinessCompliance,
		isLoadingSpecificPersonalCompliance,
		specificBusinessCompliance,
		specificPersonalCompliance,
		isUpdatingSpecificBusinessCompliance,
		isUpdatingSpecificPersonalCompliance,
		businessDirectors,
		businessPrimaryOwner
	} = useCompliance();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (
			isLoadingSpecificBusinessCompliance ||
			isLoadingSpecificPersonalCompliance
		) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [
		isLoadingSpecificBusinessCompliance,
		isLoadingSpecificPersonalCompliance,
	]);

	const [details, setDetails] = useState({});
	const [showRejectModal, setShowRejectModal] = useState(false);
	const [showComplianceModal, setShowComplianceModal] = useState(false);
	const [dataToShow, setDataToShow] = useState("");

	// const previewCompliance = (compliance) => {
	// 	setDataToShow(compliance);
	// 	setShowComplianceModal(true);
	// };

	const closePreviewCompliance = () => {
		setShowComplianceModal(false);
		setDataToShow("");
	};

	useEffect(() => {
		dispatch(getBusinessDirectors(id))
		dispatch(getBusinessPrimaryOwner(id))
	}, [id])

	useMemo(() => {
		if (type === "personal") {
			setDetails(specificPersonalCompliance);
		} else {
			setDetails(specificBusinessCompliance);
		}
	}, [specificBusinessCompliance, specificPersonalCompliance, type]);

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({
		criteriaMode: "all",
	});

	React.useEffect(() => {
		if (type === "personal") {
			dispatch(getSpecificPersonalCompliance(id));
		} else if (type === "business") {
			dispatch(getSpecificBusinessCompliance(id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const onRejectCompliance = ({ reason }) => {
		if (type === "personal") {
			dispatch(
				updateSpecificPersonalCompliance(id, {
					status: "rejected",
					comment: reason,
				})
			);
		} else {
			dispatch(
				updateSpecificBusinessCompliance(id, {
					status: "rejected",
					comment: reason,
				})
			);
		}
	};


	return (
		<div className="p-3">
			<div className="flex flex-col">
				<div className="mb-6">
					{type === "personal" ? (
						<BreadCrumbs title={"Compliance"} path={"/dashboard/compliance"} />
					) : (
						<BreadCrumbs
							title={"Compliance"}
							path={"/dashboard/compliance/business"}
						/>
					)}
				</div>

				<div className="flex justify-between mt-2">
					{!loading && details?.submission?.status ? (
						<div className="flex items-center justify-between w-full">
							<BasicIndicator
								className="px-5 py-1 capitalize"
								type={statusEnum[details?.submission?.status]}
								title={details?.submission?.status}
							/>
							{/* HEADER ACTION */}
							{details?.details?.length > 0 && (
								<div className="flex justify-end mt-2">
									<div className="flex items-center space-x-3">
										<PopoverMain
											button={<Button.Green title="Accept" disabled={loading} />}
											disabled={loading}
											popoverClassName="p-6 w-80 right-0 bg-white shadow-md top-12 rounded"
										>
											<div className="py-4 px-1">
												<p className=" glade-black text-center mb-8">
													Are you sure you want to approve this compliance?
												</p>
												<Button.Dark
													onClick={() => {
														if (type === "personal") {
															dispatch(
																updateSpecificPersonalCompliance(id, {
																	status: "approved",
																})
															);
														} else {
															dispatch(
																updateSpecificBusinessCompliance(id, {
																	status: "approved",
																})
															);
														}
													}}
													disabled={
														isUpdatingSpecificPersonalCompliance ||
														isUpdatingSpecificBusinessCompliance
													}
													title="Approve"
													className={`glade-normal-text-three glade-button-blue ${isUpdatingSpecificPersonalCompliance ||
														isUpdatingSpecificBusinessCompliance
														? " cursor-not-allowed"
														: ""
														} text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
												>
													{isUpdatingSpecificPersonalCompliance ||
														isUpdatingSpecificBusinessCompliance
														? "Approving"
														: "Approve"}
													{isUpdatingSpecificPersonalCompliance ||
														(isUpdatingSpecificBusinessCompliance && (
															<RiLoader5Fill
																size={24}
																className="animate-spin ml-4"
															/>
														))}
												</Button.Dark>
												<PopoverButton>
													<p className="glade-black mx-1 cursor-pointer text-center">
														Cancel
													</p>
												</PopoverButton>
											</div>
										</PopoverMain>
										<Button.Danger
											title="Reject"
											disabled={loading}
											onClick={() => setShowRejectModal(true)}
										/>
									</div>
								</div>
							)}
						</div>
					) : (
						<TextSkeleton width="100px" />
					)}
				</div>

				{/* ACCOUNT DETAILS */}
				<AccountDetailsCard type={type} details={details} loading={loading} />

				{/* BUSINESS INFORMATION */}
				{type === 'business' && (
					<BusinessInformationCard details={details} loading={loading} />
				)}

				{/* OWNER INFORMATION */}
				{type === 'business' && businessPrimaryOwner?.length > 0 && (
					<div className="flex flex-col bg-white rounded-sm px-8 py-4 mb-6">
						<div className="py-3 border-b-2 border-solid border-gray-100">
							<p className="glade-black font-bold">Owner Information</p>
						</div>

						{details?.submission?.business_type !== 'partnership' ? (
							<Fragment>
								{type === 'business' && businessPrimaryOwner?.length > 0 && (
									<Accordion title={"Primary Owner"} contents={businessPrimaryOwner[0]} />
								)}
								{type === 'business' && businessDirectors?.length > 0 && (
									<Accordion title={details?.submission?.business_type === "NGO" ? "Other Trustees" : "Other business Owners"} multi contents={businessDirectors} />
								)}
							</Fragment>
						) : <InfoSection contents={businessPrimaryOwner[0]} />}
					</div>
				)}
				{/* END OF OWNER INFORMATION */}

				{/* PERSONAL COMPLIANCE DOCUMENT */}
				{type === 'personal' && (
					<PersonalComplianceCard details={details} loading={loading} />
				)}


				{/* BUSINESS DOCUMENTS */}
				{type === 'business' && (
					<BusinessDocumentCard details={details} loading={loading} /> 
				)}
				{/* END OF BUSINESS DOCUMENTS */}
			</div>

			{/* Reject Compliance Modal */}
			<BasicModal
				visibility={showRejectModal}
				onClose={() => setShowRejectModal(false)}
				onOpen={() => setShowRejectModal(true)}
				primaryButton=""
				modalTitleSection=""
				secondaryButton=""
				modelContentSection={
					<form onSubmit={handleSubmit(onRejectCompliance)}>
						<div className="-mx-6 -mt-2 flex justify-between px-5 pb-3 border-b-2 border-solid border-gray-200 mb-8">
							<h4 className="glade-normal-text-two glade-black">
								Reject Compliance
							</h4>
							<MdOutlineClose
								size={16}
								className="glade-black cursor-pointer"
								onClick={() => setShowRejectModal(false)}
							/>
						</div>
						<div className="mb-4">
							<label htmlFor="reason" className="block glade-small-text-two">
								Reason
							</label>
							<textarea
								rows={4}
								id="reason"
								name="reason"
								{...register("reason", {
									required: "Enter a reason",
								})}
								// required
								placeholder="Enter a reason"
								className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two resize-none"
							/>
							<div className="flex items-center justify-between flex-wrap">
								<FormError errors={errors} name="reason" />
							</div>
						</div>

						<div className="-mx-6 -mb-6 flex justify-between p-5 border-t-2 border-solid border-gray-200 mt-10">
							<button
								onClick={() => setShowRejectModal(false)}
								className="glade-normal-text-three glade-black text-center"
							>
								Cancel
							</button>
							<button
								disabled={
									isUpdatingSpecificPersonalCompliance ||
									isUpdatingSpecificBusinessCompliance
								}
								className={`glade-normal-text-three glade-button-red ${isUpdatingSpecificPersonalCompliance ||
									isUpdatingSpecificBusinessCompliance
									? "cursor-not-allowed"
									: ""
									} text-white py-2 px-4 flex justify-center items-center text-center rounded-sm`}
							>
								{isUpdatingSpecificPersonalCompliance ||
									isUpdatingSpecificBusinessCompliance
									? "Rejecting"
									: "Reject"}
								{isUpdatingSpecificPersonalCompliance ||
									(isUpdatingSpecificBusinessCompliance && (
										<RiLoader5Fill size={24} className="animate-spin ml-4" />
									))}
							</button>
						</div>
					</form>
				}
			/>
			{/* Show Compliance Modal */}
			<BasicModal
				visibility={showComplianceModal}
				onClose={closePreviewCompliance}
				onOpen={() => setShowComplianceModal(true)}
				primaryButton=""
				modalTitleSection=""
				secondaryButton=""
				modelContentSection={
					<div>
						<div className="-mx-6 -mt-2 flex justify-between px-5 pb-3 border-b-2 border-solid border-gray-200 mb-4">
							<h4 className="glade-normal-text-two glade-black">Document</h4>
							<MdOutlineClose
								size={16}
								className="glade-black cursor-pointer"
								onClick={closePreviewCompliance}
							/>
						</div>
						<img src={dataToShow} className="h-96 w-full" alt="" />
					</div>
				}
			/>
		</div >
	);
}

import { useState, useEffect } from 'react';
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import TabNav from "../../components/navbars/TabNav";
import { PaginatedTable } from "../../components/table/Table";
import qs from 'query-string';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import usePaginationFilterParams from '../../hooks/usePaginationFilterParams';
import { getMultiCurrencyCharges } from '../../redux/multi-currency/multiCurrencyActions';
import EditChargeModal from './EditChargeModal';
import useMultiCurrency from '../../hooks/useMultiCurrency';

const MultiCurrencyCharges = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoadingMultiCurrencyCharges, multiCurrencyCharges } = useMultiCurrency();

  const [modalStates, setModalStates] = useState({ showEditChargeModal: false });
  const [data, setData] = useState([]);
  const [selectedCharge, setSelectedCharge] = useState({});
  const [currentPage, setCurrentPage] = useState(qs.parse(location.search)?.page ?? 1);
  const [searchParams, setSearchParams] = useState('');
  const [clearSearchInput, setClearSearchInput] = useState(false);

  useEffect(() => {
    dispatch(getMultiCurrencyCharges({
      params: {
        ...(searchParams
          ? {
            destination_currency: searchParams,
            paginate: 1,
            current_page: currentPage,
            per_page: 10,
            method: selectedCharge
          } : {
            paginate: 1,
            current_page: currentPage,
            per_page: 10,
            method: selectedCharge,
            destination_currency: searchParams,
          })
      }
    }));
    setClearSearchInput(false);
  }, [currentPage, searchParams, selectedCharge]);

  useEffect(() => {
    if (!isLoadingMultiCurrencyCharges) {
      setData(multiCurrencyCharges);
    }
  }, [multiCurrencyCharges]);

  const resetReferenceSearch = event => {
    setSearchParams('');
    setCurrentPage(1);
  };

  const openEditChargeModal = charge => {
    setSelectedCharge(charge);
    setModalStates(modalStates => ({
      ...modalStates,
      showEditChargeModal: true
    }));
  };

  const defaultFilterParams = {
    search: "",
    page: currentPage,
  };

  const filterInstance = usePaginationFilterParams({
    initialFilters: defaultFilterParams
  });

  const columns = [
    {
      accessor: "destination_currency",
      Header: "Name",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two capitalize">
          {value?.toUpperCase()}
        </span>
      ),
    },
    {
      accessor: "value",
      Header: "Fixed Fee",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two capitalize">
          {value || "-"}
        </span>
      ),
    },
    {
      accessor: "fee_percentage",
      Header: "Percentage Fee",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two capitalize">
          {value || "-"}
        </span>
      ),
    },
    {
      accessor: "fee_cap",
      Header: "Capped Fee",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two capitalize">
          {value || "-"}
        </span>
      ),
    },
    {
      accessor: "",
      Header: " ",
      Cell: ({ value, row }) => (
        <div className="flex items-center justify-center">
          <span
            className="glade-blue-normal glade-normal-text-two cursor-pointer"
            onClick={() => openEditChargeModal(row.original)}
          >
            Edit
          </span>
        </div>
      ),
    }
  ];

  const { filters, handleChange } = filterInstance;

  const tabLinks = [{
    title: "Multi-currency Account Creation",
    path: `/dashboard/multi-currency`
  }];

  return (
    <div className="p-3">
      <TabNav tabs={tabLinks} />
      <CardTableHeader
        title={"Multi-currency Account Creation Charges"}
        filterButton=""
        onChange={e => {
          const value = e.target.value;
          if (value) {
            setSearchParams(value);
          } else {
            resetReferenceSearch();
          }
        }}
        defaultFilterParams={defaultFilterParams}
        searchBarPlaceholder={"Fee Name"}
        // clearSearchInput={clearSearchInput}
        filterCallback={handleChange}
      />
      <PaginatedTable
        columns={columns}
        loading={isLoadingMultiCurrencyCharges}
        data={data}
        empty_message={"No created currencies"}
        empty_sub_message={""}
        current_page={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <EditChargeModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        selectedCharge={selectedCharge}
        setSelectedCharge={setSelectedCharge}
        showEditChargeModal={modalStates?.showEditChargeModal}
        setData={setData}
        filterValue={filters}
      />
    </div>
  );
};

export default MultiCurrencyCharges;
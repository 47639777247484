import {
  LOADING,
  LOGOUT_USER,
  REGISTER_USER,
  REFRESHED_TOKEN,
  TOGGLE_ACCOUNT,
  LOGIN_USER,
} from "./types";

const initialState = {
  isLoading: false,
  user: {},
  isBusiness: true,
  token: "",
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case LOGIN_USER:
      return {
        ...state,
        user: action.payLoad?.data?.data,
        token: action.payLoad?.data?.data?.token,
      };

    case LOGOUT_USER:
      return {
        ...state,
        user: {},
        token: "",
      };

    case TOGGLE_ACCOUNT:
      return {
        ...state,
        isBusiness: !state.isBusiness,
      };

    case REGISTER_USER:
      return {
        ...state,
        user: {},
      };

    case REFRESHED_TOKEN:
      return {
        ...state,
        token: action.payLoad.data.data.token,
      };

    default:
      return state;
  }
}

import { useSelector } from "react-redux";

const useCACRegistration = () => {
  const cacRegistration = useSelector((state) => state.cacRegistration);

  const {
    isLoadingCACRegistration,
    isLoadingSingleCAC,
    cacRequestsData,
    cacSingleData,

  } = cacRegistration;

  return {
    isLoadingCACRegistration,
    isLoadingSingleCAC,
    cacRequestsData,
    cacSingleData
  };
};

export default useCACRegistration;

import React from "react";
import { TextSkeleton } from "../skeletons";

const BusinessInformationCard = ({ details, loading }) => {
    return (
        <div className="flex flex-col bg-white rounded-sm px-8 py-4 mb-6">
            <div className="py-3 border-b-2 border-solid border-gray-100">
                <p className="glade-black font-bold">Business Information</p>
            </div>
            <div className="grid grid-cols-4 gap-4 py-3">
                <div className="flex flex-col">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                        Business Type
                    </h5>
                    {!loading && details?.submission?.id ? (
                        <h6 className="glade-normal-text-three glade-black py-1">
                            {details?.submission?.business_type ?? 'N/A'}
                        </h6>
                    ) : (
                        <TextSkeleton />
                    )}
                </div>
                <div className="flex flex-col">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                        Industry
                    </h5>
                    {!loading && details?.submission?.id ? (
                        <h6 className="glade-normal-text-three glade-black py-1">
                            {details?.submission?.industry ?? 'N/A'}
                        </h6>
                    ) : (
                        <TextSkeleton />
                    )}
                </div>
                <div className="flex flex-col">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                        Business Phone Number
                    </h5>
                    {!loading && details?.submission?.id ? (
                        <h6 className="glade-normal-text-three glade-black py-1">
                            {details?.submission?.secondary_phone ?? 'N/A'}
                        </h6>
                    ) : (
                        <TextSkeleton />
                    )}
                </div>
                <div className="flex flex-col">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                        Business Email
                    </h5>
                    {!loading && details?.submission?.id ? (
                        <h6 className="glade-normal-text-three glade-black py-1">
                            {details?.submission?.business_email ?? 'N/A'}
                        </h6>
                    ) : (
                        <TextSkeleton />
                    )}
                </div>
                <div className="flex flex-col">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                        Business Address
                    </h5>
                    {!loading && details?.submission?.id ? (
                        <h6 className="glade-normal-text-three glade-black py-1">
                            {details?.submission?.address ?? 'N/A'}
                        </h6>
                    ) : (
                        <TextSkeleton />
                    )}
                </div>
                {details?.submission?.ein_number &&
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                            ID Number / EIN
                        </h5>
                        {!loading && details?.submission?.id ? (
                            <h6 className="glade-normal-text-three glade-black py-1">
                                {details?.submission?.ein_number ?? 'N/A'}
                            </h6>
                        ) : (
                            <TextSkeleton />
                        )}
                    </div>}
                <div className="flex flex-col">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                        State
                    </h5>
                    {!loading && details?.submission?.id ? (
                        <h6 className="glade-normal-text-three glade-black py-1">
                            {details?.submission?.state_name ?? 'N/A'}
                        </h6>
                    ) : (
                        <TextSkeleton />
                    )}
                </div>
                {details?.submission?.postal &&
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                            Postal Code
                        </h5>
                        {!loading && details?.submission?.id ? (
                            <h6 className="glade-normal-text-three glade-black py-1">
                                {details?.submission?.postal ?? 'N/A'}
                            </h6>
                        ) : (
                            <TextSkeleton />
                        )}
                    </div>}
                {details?.submission?.lga_name &&
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                            LGA
                        </h5>
                        {!loading && details?.submission?.id ? (
                            <h6 className="glade-normal-text-three glade-black py-1">
                                {details?.submission?.lga_name ?? 'N/A'}
                            </h6>
                        ) : (
                            <TextSkeleton />
                        )}
                    </div>}
                <div className="flex flex-col">
                    <h5 className="glade-normal-text-two glade-ash py-1">
                        Business Description
                    </h5>
                    {!loading && details?.submission?.id ? (
                        <h6 className="glade-normal-text-three glade-black py-1">
                            {details?.submission?.business_description ?? 'N/A'}
                        </h6>
                    ) : (
                        <TextSkeleton />
                    )}
                </div>
            </div>
        </div>)
}

export default BusinessInformationCard;
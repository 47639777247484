import React, { useEffect } from "react";
import { useTable, usePagination } from "react-table";
import styled from "styled-components";
import { GLADE_EMPTY_FOLDER_ICON } from "../../assets/images/images";
import GlassButton from "../buttons/GlassButton";
import { TextSkeleton } from "../skeletons";

const TableHolder = styled.table`
	max-height: 80vh;
`;

const TableCellStyle = styled.td`
	:first-of-type {
		padding-left: 60px !important;
	}
	:last-of-type {
		padding-right: 60px !important;
	}
`;

const TableHeadStyle = styled.th`
	:first-of-type {
		padding-left: 60px !important;
	}
	:last-of-type {
		padding-right: 30px !important;
	}
`;

export function CardTableFooter({
	pageNumber = 1,
	totalPages = 1,
	prevOnClick,
	nextOnClick,
	canPreviousPage,
	canNextPage,
}) {
	return (
		<div className="flex justify-between items-center mt-10 pb-6 px-6 bg-white rounded-b-md shadow-lg">
			<div className="flex">
				<span className="font-inter font-bold text-base leading-7 glade-ash">
					{"Page " + pageNumber + " of " + totalPages}
				</span>
			</div>
			<div className="flex">
				{canPreviousPage && (
					<GlassButton
						disabled={!canPreviousPage}
						title="Prev"
						className="font-inter font-bold text-base leading-7 glade-ash"
						onClick={prevOnClick}
					/>
				)}
				{canNextPage && (
					<GlassButton
						disabled={!canNextPage}
						title="Next"
						className="font-inter font-bold text-base leading-7 glade-ash"
						onClick={nextOnClick}
					/>
				)}
			</div>
		</div>
	);
}

export function TableCell({ children, className, style = {} }) {
	return (
		<TableCellStyle
			className={"px-6 py-6 glade-black " + className}
			style={style}
		>
			{children}
		</TableCellStyle>
	);
}

export function TableRow({ children, className, style = {} }) {
	return (
		<tr
			className={
				"border-t-0 px-6 align-middle border-b border-solid border-gray-100 border-l-0 border-r-0 text-xs whitespace-nowrap py-6 " +
				className
			}
			style={style}
		>
			{children}
		</tr>
	);
}

export const Table = ({
	columns,
	data,
	loading = true,
	empty_message = "No Transactions",
	empty_sub_message = "You do not have any transactions",
}) => {
	const tableData = React.useMemo(
		() => (loading ? Array(3).fill({}) : data?.length > 0 ? data : []),
		[loading, data]
	);

	const tableColumns = React.useMemo(
		() =>
			loading
				? columns?.map((column) => ({
					...column,
					Cell: <TextSkeleton />,
				}))
				: columns,
		[loading, columns]
	);

	// Use the state and functions returned from useTable to build your UI
	const {
		canPreviousPage,
		canNextPage,
		getTableProps,
		getTableBodyProps,
		headerGroups,
		nextPage,
		previousPage,
		page,
		pageCount,
		prepareRow,
		state: { pageIndex },
	} = useTable(
		{
			initialState: { pageIndex: 0, pageSize: 10 },
			columns: tableColumns,
			data: tableData,
		},
		usePagination
	);

	// Render the UI for your table
	return (
		<div
			className={"relative flex flex-col min-w-0 break-words w-full bg-white"}
		>
			{loading ? (
				<div className="block w-full overflow-x-auto">
					<TableHolder
						{...getTableProps()}
						className="items-center w-full bg-transparent border-collapse relative"
					>
						<thead className="sticky top-0">
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<TableHeadStyle
											{...column.getHeaderProps()}
											scope="col"
											className={
												"glade-normal-text-one glade-black px-6 align-middle border border-solid border-l-0 border-r-0 whitespace-nowrap text-left py-6"
											}
										>
											{column.render("Header")}
										</TableHeadStyle>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{page?.map((row) => {
								prepareRow(row);
								return (
									<TableRow {...row?.getRowProps()}>
										{row?.cells?.map((cell) => {
											return (
												<TableCell
													// style={{
													//   ...cell.column.style,
													// }}
													{...cell?.getCellProps({
														style: cell.column.style ?? {},
													})}
												>
													{cell.render("Cell")}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</tbody>
					</TableHolder>
				</div>
			) : (
				<>
					{tableData && data?.length > 0 ? (
						<>
							<div className="block w-full overflow-x-auto">
								<TableHolder
									{...getTableProps()}
									className="items-center w-full bg-transparent border-collapse relative"
								>
									<thead className="sticky top-0">
										{headerGroups.map((headerGroup) => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column) => (
													<TableHeadStyle
														{...column.getHeaderProps()}
														scope="col"
														className={
															"glade-normal-text-one glade-black px-6 align-middle border border-solid py-6 border-l-0 border-r-0 whitespace-nowrap text-left "
														}
													>
														{column.render("Header")}
													</TableHeadStyle>
												))}
											</tr>
										))}
									</thead>
									<tbody {...getTableBodyProps()}>
										{page?.map((row) => {
											prepareRow(row);
											return (
												<TableRow {...row?.getRowProps()}>
													{row?.cells?.map((cell) => {
														return (
															<TableCell
																{...cell?.getCellProps({
																	style: cell.column.style ?? {},
																})}
															>
																{cell.render("Cell")}
															</TableCell>
														);
													})}
												</TableRow>
											);
										})}
									</tbody>
								</TableHolder>
							</div>
							<CardTableFooter
								pageNumber={pageIndex + 1}
								totalPages={pageCount}
								prevOnClick={previousPage}
								nextOnClick={nextPage}
								canNextPage={canNextPage}
								canPreviousPage={canPreviousPage}
							/>
						</>
					) : (
						<div className="flex items-center justify-center flex-col py-20 lg:py-32">
							<img src={GLADE_EMPTY_FOLDER_ICON} alt="" className="h-12 w-14" />
							<p className="glade-normal-text-one glade-black">
								{empty_message}
							</p>
							<p className="glade-normal-text-two glade-black">
								{empty_sub_message}
							</p>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export const PaginatedTable = ({
	columns,
	data,
	current_page,
	setCurrentPage,
	totalPages,
	loading = true,
	empty_message = "No Transactions",
	empty_sub_message = "You do not have any transactions",
}) => {
	const tableData = React.useMemo(
		() => (loading ? Array(3).fill({}) : data?.length > 0 ? data : []),
		[loading, data]
	);

	const tableColumns = React.useMemo(
		() =>
			loading
				? columns?.map((column) => ({
					...column,
					Cell: <TextSkeleton />,
				}))
				: columns,
		[loading, columns]
	);

	// Use the state and functions returned from useTable to build your UI
	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
		useTable(
			{
				initialState: { pageIndex: 0, pageSize: 10 },
				columns: tableColumns,
				data: tableData,
			},
			usePagination
		);

	// Render the UI for your table
	return (
		<div className={" flex flex-col min-w-0 break-words w-full bg-white"}>
			{loading ? (
				<div className="block w-full overflow-x-auto">
					<TableHolder
						{...getTableProps()}
						className="items-center w-full bg-transparent border-collapse relative"
					>
						<thead className="sticky top-0">
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<TableHeadStyle
											{...column.getHeaderProps()}
											scope="col"
											className={
												"glade-normal-text-one glade-black px-6 align-middle border border-solid border-l-0 border-r-0 whitespace-nowrap text-left py-6"
											}
										>
											{column.render("Header")}
										</TableHeadStyle>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{page?.map((row) => {
								prepareRow(row);
								return (
									<TableRow {...row?.getRowProps()}>
										{row?.cells?.map((cell) => {
											return (
												<TableCell
													// style={{
													//   ...cell.column.style,
													// }}
													{...cell?.getCellProps({
														style: cell.column.style ?? {},
													})}
												>
													{cell.render("Cell")}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</tbody>
					</TableHolder>
				</div>
			) : (
				<>
					{tableData && data?.length > 0 ? (
						<>
							<div className="block w-full overflow-x-auto">
								<TableHolder
									{...getTableProps()}
									className="items-center w-full bg-transparent border-collapse relative"
								>
									<thead className="sticky top-0">
										{headerGroups.map((headerGroup) => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column) => (
													<TableHeadStyle
														{...column.getHeaderProps()}
														scope="col"
														className={
															"glade-normal-text-one glade-black px-6 align-middle border border-solid py-6 border-l-0 border-r-0 whitespace-nowrap text-left "
														}
													>
														{column.render("Header")}
													</TableHeadStyle>
												))}
											</tr>
										))}
									</thead>
									<tbody {...getTableBodyProps()}>
										{page?.map((row) => {
											prepareRow(row);
											return (
												<TableRow {...row?.getRowProps()}>
													{row?.cells?.map((cell) => {
														return (
															<TableCell
																{...cell?.getCellProps({
																	style: cell.column.style ?? {},
																})}
															>
																{cell.render("Cell")}
															</TableCell>
														);
													})}
												</TableRow>
											);
										})}
									</tbody>
								</TableHolder>
							</div>
							<CardTableFooter
								pageNumber={current_page}
								totalPages={Math.ceil(Number(totalPages ?? 10) / 10)}
								prevOnClick={() => setCurrentPage(Number(current_page) - 1)}
								nextOnClick={() => setCurrentPage(Number(current_page) + 1)}
								canNextPage={current_page < totalPages / 10}
								canPreviousPage={current_page > 1}
							/>
						</>
					) : (
						<>
							<div className="flex items-center justify-center flex-col py-20 lg:py-32">
								<img
									src={GLADE_EMPTY_FOLDER_ICON}
									alt=""
									className="h-12 w-14"
								/>
								<p className="glade-normal-text-one glade-black">
									{empty_message}
								</p>
								<p className="glade-normal-text-two glade-black">
									{empty_sub_message}
								</p>
							</div>
							<CardTableFooter
								pageNumber={current_page}
								totalPages={Math.ceil(Number(totalPages) / 10)}
								prevOnClick={() => setCurrentPage(Number(current_page) - 1)}
								nextOnClick={() => setCurrentPage(Number(current_page) + 1)}
								canNextPage={current_page < totalPages / 10}
								canPreviousPage={current_page > 1}
							/>
						</>
					)}
				</>
			)}
		</div>
	);
};

import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import {
	TOGGLE_LOADIDNG_STATE_OFF,
	TOGGLE_LOADIDNG_STATE_ON,
} from "../shared/types";
import {
	GET_ALL_CHECKOUT_INFLOW_FAILURE,
	GET_ALL_CHECKOUT_INFLOW_START,
	GET_ALL_CHECKOUT_INFLOW_SUCCESS,
	GET_ALL_POS_INFLOW_FAILURE,
	GET_ALL_POS_INFLOW_START,
	GET_ALL_POS_INFLOW_SUCCESS,
	GET_ALL_TRANSFER_INFLOW_FAILURE,
	GET_ALL_TRANSFER_INFLOW_START,
	GET_ALL_TRANSFER_INFLOW_SUCCESS,
	GET_SPECIFIC_CHECKOUT_INFLOW_FAILURE,
	GET_SPECIFIC_CHECKOUT_INFLOW_START,
	GET_SPECIFIC_CHECKOUT_INFLOW_SUCCESS,
	GET_SPECIFIC_POS_INFLOW_FAILURE,
	GET_SPECIFIC_POS_INFLOW_START,
	GET_SPECIFIC_POS_INFLOW_SUCCESS,
	GET_SPECIFIC_TRANSFER_INFLOW_FAILURE,
	GET_SPECIFIC_TRANSFER_INFLOW_START,
	GET_SPECIFIC_TRANSFER_INFLOW_SUCCESS,
} from "./types";

export const getAllPosInflows = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_POS_INFLOW_START });
		Axios.get(`inflow/pos`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_POS_INFLOW_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_POS_INFLOW_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getAllPosInflowsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });
		Axios.get(`inflow/pos`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getAllCheckoutInflows = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_CHECKOUT_INFLOW_START });
		Axios.get(`inflow/checkout`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_CHECKOUT_INFLOW_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_CHECKOUT_INFLOW_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getAllCheckoutInflowsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });
		Axios.get(`inflow/checkout`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getAllTransferInflows = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_TRANSFER_INFLOW_START });
		Axios.get(`inflow/transfer`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_TRANSFER_INFLOW_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_TRANSFER_INFLOW_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getAllTransferInflowsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });
		Axios.get(`inflow/transfer`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

/**
 *
 * @param {string} txn_ref inflow txn_ref
 * @returns object - inflow details
 */

export const getSpecificPosInflow = (txn_ref) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_POS_INFLOW_START });
		Axios.get(`inflow/pos/${txn_ref}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_POS_INFLOW_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_POS_INFLOW_FAILURE,
					payLoad: error,
				});
				// toastWarning("Please try again later, or refresh");
				handler(error, true);
			});
	};
};

/**
 *
 * @param {string} txn_ref inflow txn_ref
 * @returns object - inflow details
 */

export const getSpecificCheckoutInflow = (txn_ref) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_CHECKOUT_INFLOW_START });
		Axios.get(`inflow/checkout/${txn_ref}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_CHECKOUT_INFLOW_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_CHECKOUT_INFLOW_FAILURE,
					payLoad: error,
				});
				// toastWarning("Please try again later, or refresh");
				handler(error, true);
				// window.history.back();
			});
	};
};

/**
 *
 * @param {string} txn_ref inflow txn_ref
 * @returns object - inflow details
 */

export const getSpecificTransferInflow = (txn_ref) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_TRANSFER_INFLOW_START });
		Axios.get(`inflow/transfer/${txn_ref}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_TRANSFER_INFLOW_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_TRANSFER_INFLOW_FAILURE,
					payLoad: error,
				});
				// toastWarning("Please try again later, or refresh");
				handler(error, true);
			});
	};
};

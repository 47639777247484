import React, { useEffect, useState } from 'react';
import * as dfn from "date-fns";
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import { fixDateForAllBrowsers, statusEnum, toTitleCase } from '../../helpers/helper';
import { TextSkeleton } from "../../components/skeletons";
import { CgPlayButton } from 'react-icons/cg';
import { IoMdArrowDropdown } from 'react-icons/io';
import { getSingleCACRegistration } from '../../redux/cacRegisstration/cacRegistrationActions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useCACRegistration from '../../hooks/useCACRegstration';
import DownloadAsCSVButton from '../../helpers/DownloadAsCSV';
import BasicIndicatorWithOptions from '../../components/indicators/BasicIndicatorWithOptions';
import BasicIndicator from '../../components/indicators/BasicIndicator';
import MarkCACAsDeliveredModal from './MarkCACAsDeliveredModal';
import CacRegDetailsBreakdown from './CacRegDetailsBreakdown';
import DownloadAsCSVWithMultipleSheet from '../../helpers/DownloadAsCSVWithMultipleSheet';

const CacRegistrationDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location.search.split('=')[1];
  const [showDirectorDetails, setShowDirectorDetails] = useState(false);
  const [indexOfDirectorDetails, setIndexOfDirectorDetails] = useState('');
  const [showModal, setShowModal] = useState(false);

  const { isLoadingSingleCAC, cacSingleData } = useCACRegistration();
  const [status, setStatus] = useState('');

  useEffect(() => {
    dispatch(getSingleCACRegistration(id, (data) => data));
  }, []);

  const handleAction = (data, actionType) => {
    setShowModal(true);
    setStatus(actionType);

  };

  const accountInfo = [
    {
      name: "Account Name",
      value: cacSingleData?.account_name
    },
    {
      name: "Account Email",
      value: cacSingleData?.account_email
    },
    {
      name: "Registration Type",
      value: cacSingleData?.registration_type?.replaceAll("-", " ") + `${cacSingleData?.registration_type === 'sole-proprietorship' ? '/Partnership' : ''}`
    },
    {
      name: "Date Submitted",
      value: cacSingleData?.date_submitted ? dfn.format(new Date(fixDateForAllBrowsers(cacSingleData?.date_submitted)), "MMM d, yyyy") : null
    }
  ]

  const soleProprietorshipCompanyInfo = [
    {
      name: "Proposed Business Name 1",
      value: cacSingleData?.company?.proposed_name_one
    },
    {
      name: "Proposed Business Name 2",
      value: cacSingleData?.company?.proposed_name_two
    },
    {
      name: "Proposed Business Name 3",
      value: cacSingleData?.company?.proposed_name_three
    },
    {
      name: "Business Email",
      value: cacSingleData?.company?.email
    },
    {
      name: "Business Phone Number",
      value: cacSingleData?.company?.phone
    },
    {
      name: "Business Address",
      value: cacSingleData?.company?.address
    },
    {
      name: "Business Description",
      value: cacSingleData?.company?.description
    },
  ]

  const limitedLiabilityCompanyInfo = [
    {
      name: "Proposed Business Name 1",
      value: cacSingleData?.company?.proposed_name_one
    },
    {
      name: "Proposed Business Name 2",
      value: cacSingleData?.company?.proposed_name_two
    },
    {
      name: "Proposed Business Name 3",
      value: cacSingleData?.company?.proposed_name_three
    },
    {
      name: "Business Email",
      value: cacSingleData?.company?.email
    },
    {
      name: "Business Phone Number",
      value: cacSingleData?.company?.phone
    },
    {
      name: "Business Address",
      value: cacSingleData?.company?.address
    },
    {
      name: "Business Industry",
      value: cacSingleData?.company?.industry
    },
    {
      name: "Share Capital",
      value: cacSingleData?.company?.share_capital
    },
    {
      name: "Business Objective",
      value: cacSingleData?.company?.objective
    },
    {
      name: "Business Description",
      value: cacSingleData?.company?.description
    },
  ]

  const limitedLiabilityPartnershipCompanyInfo = [
    {
      name: "Proposed Business Name 1",
      value: cacSingleData?.company?.proposed_name_one
    },
    {
      name: "Proposed Business Name 2",
      value: cacSingleData?.company?.proposed_name_two
    },
    {
      name: "Proposed Business Name 3",
      value: cacSingleData?.company?.proposed_name_three
    },
    {
      name: "Business Address",
      value: cacSingleData?.company?.address
    },
    {
      name: "Partnership Agreement",
      value: cacSingleData?.company?.partnership_agreement,
      type: 'file'
    },
  ]

  const ngoCompanyInfo = [
    {
      name: "Proposed NGO Name 1",
      value: cacSingleData?.company?.proposed_name_one
    },
    {
      name: "Proposed NGO Name 2",
      value: cacSingleData?.company?.proposed_name_two
    },
    {
      name: "Proposed NGO Name 3",
      value: cacSingleData?.company?.proposed_name_three
    },
    {
      name: "Chairman of Trustees",
      value: cacSingleData?.company?.chairman
    },
    {
      name: "Tenureship of Trustees ",
      value: cacSingleData?.company?.tenure
    },
    {
      name: "Maximum Number of Trustees",
      value: cacSingleData?.company?.maximum_no_trustes
    },
    {
      name: "Organization Objective",
      value: cacSingleData?.company?.objective
    },
  ]
  const handleCSVDownload = (fxn) => {
    let companyData = [];
    let directorsData = [];
    dispatch(
      getSingleCACRegistration(id, (data) => {
        companyData = [{
          ...data, ...data.company, directors: data?.directors?.length
        }];
        directorsData = [
          ...(data && data.directors || [])
        ]
        fxn([{ name: 'company data', data: companyData }, { name: 'directors data', data: directorsData }]);
      }))

  }

  return (
    <div className="p-3 flex flex-col">
      {/* Breadcrumb and Status */}
      <div className="mb-5">
        <BreadCrumbs title={"Incorporation"} goBack />
      </div>
      <div className="flex items-center justify-between mb-4">
        {isLoadingSingleCAC ?
          <TextSkeleton width='100px' /> :
          <div>
            {cacSingleData?.status === "draft" || cacSingleData?.status === "deleted" || cacSingleData?.status === "completed" ?
              <BasicIndicator
                className="px-5 py-2 border-4 capitalize"
                type={cacSingleData?.status === 'completed' ? 'neutral' : statusEnum[cacSingleData?.status]}
                title={cacSingleData?.status === 'completed' ? 'delivered' : cacSingleData?.status}
              />
              :
              <BasicIndicatorWithOptions
                className="px-5 py-1 capitalize"
                type={cacSingleData?.status === 'created' ? 'not-delivered' : cacSingleData?.status === 'completed' ? 'delivered' : cacSingleData?.status}
                title={cacSingleData?.status === 'created' ? 'Pending' : cacSingleData?.status === 'completed' ? 'delivered' : cacSingleData?.status}
                data={cacSingleData}
                test={handleAction}
              />
            }
          </div>}
        <div>
          {isLoadingSingleCAC ?
            <TextSkeleton width={'100px'} />
            :
            <DownloadAsCSVWithMultipleSheet
              callback={handleCSVDownload}
              fileName="CAC Incorporation"
              buttonTitle = "Download as CSV"
            />}
        </div>
      </div>
      {/* Account Information */}
      <div className="flex flex-col bg-white shadow rounded-sm p-8 py-2 mb-8">
        <div className="py-3 border-b-2 border-solid border-gray-100">
          <p className="glade-black glade-normal-text-two"><span className=" font-bold">Account Details</span></p>
        </div>
        <div className="grid grid-cols-4 gap-4 py-3">
          {
            accountInfo.map((info, i) => (
              <CacRegDetailsBreakdown
                key={i}
                title={info?.name}
                isLoadingSingleCAC={isLoadingSingleCAC}
                cacSingleData={info?.value}
                capitalize={true}
              />
            ))
          }
        </div>
      </div>

      {/* Company Information */}
      <div className="flex flex-col bg-white shadow rounded-sm p-8 py-2 mb-8">
        <div className="py-3 border-b-2 border-solid border-gray-100">
          <p className="glade-black glade-normal-text-two capitalize"><span className="font-bold">
            {cacSingleData?.registration_type === 'sole-proprietorship' ? 'Business Information' : 'Company Information'}
          </span></p>
        </div>
        <div className="grid grid-cols-4 gap-4 py-3">
          {cacSingleData?.registration_type === 'sole-proprietorship' &&
            (
              soleProprietorshipCompanyInfo.map((info, i) => (
                <CacRegDetailsBreakdown
                  key={i}
                  title={info?.name}
                  isLoadingSingleCAC={isLoadingSingleCAC}
                  cacSingleData={info?.value}
                />
              ))
            )
          }
          {cacSingleData?.registration_type === 'limited-liability-company' &&
            (
              limitedLiabilityCompanyInfo.map((info, i) => (
                <CacRegDetailsBreakdown
                  key={i}
                  title={info?.name}
                  isLoadingSingleCAC={isLoadingSingleCAC}
                  cacSingleData={info?.value}
                />
              ))
            )
          }
          {cacSingleData?.registration_type === 'limited-liability-partnership' &&
            (
              limitedLiabilityPartnershipCompanyInfo.map((info, i) => (
                <CacRegDetailsBreakdown
                  key={i}
                  title={info?.name}
                  isLoadingSingleCAC={isLoadingSingleCAC}
                  cacSingleData={info?.value}
                  type={info?.type ?? ''}
                />
              ))
            )
          }
          {cacSingleData?.registration_type === 'non-governmental-organisation' &&
            (
              ngoCompanyInfo.map((info, i) => (
                <CacRegDetailsBreakdown
                  key={i}
                  title={info?.name}
                  isLoadingSingleCAC={isLoadingSingleCAC}
                  cacSingleData={info?.value}
                />
              ))
            )
          }
        </div>
      </div>

      {/* Director Information */}
      {cacSingleData?.directors?.length > 0 &&
        <div className="flex flex-col bg-white shadow rounded-sm p-8 py-2 mb-8">
          <div className="py-3 border-b-2 border-solid border-gray-100 mb-6">
            <p className="glade-black glade-normal-text-two"><span className="font-bold">{cacSingleData?.registration_type === 'sole-proprietorship' || cacSingleData?.registration_type === 'limited-liability-company' ? 'Director Information' : cacSingleData?.registration_type === 'limited-liability-partnership' ? 'Partner Information' : cacSingleData?.registration_type === 'non-governmental-organisation' ? 'Trustee Information' : ''}</span></p>
          </div>
          {cacSingleData?.directors.map((director, key) => <div className="gap-4 mb-4 py-3 glade-bg-grey-extra-light rounded-md" key={key}>
            <div className="w-full p-1 flex items-center gap-1">
              {!showDirectorDetails ? <CgPlayButton className='cursor-pointer' onClick={() => setIndexOfDirectorDetails(key) & setShowDirectorDetails(true)} /> : <IoMdArrowDropdown onClick={() => setIndexOfDirectorDetails() & setShowDirectorDetails(false)} className="cursor-pointer" />}
              <span className='capitalize'>{director?.full_name ?? 'Full Name'}</span>
            </div>
            {showDirectorDetails && indexOfDirectorDetails === key &&
              <div className='gap-4 py-3 border-t-2 border-solid border-gray-100 m-4'>
                <div className="grid grid-cols-4 gap-4 py-3">
                  <CacRegDetailsBreakdown
                    title={'Full Name'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.full_name}
                  />
                  <CacRegDetailsBreakdown
                    title={'Date of Birth'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.date_of_birth}
                  />
                  <CacRegDetailsBreakdown
                    title={'Email'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.email}
                  />
                  <CacRegDetailsBreakdown
                    title={'Phone Number'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.phone}
                  />
                  <CacRegDetailsBreakdown
                    title={'State'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.state}
                  />
                  <CacRegDetailsBreakdown
                    title={'LGA'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.lga}
                  />
                  <CacRegDetailsBreakdown
                    title={'City'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.city}
                  />
                  <CacRegDetailsBreakdown
                    title={'Occupation'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.occupation}
                  />
                  <CacRegDetailsBreakdown
                    title={'Country'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.nationality}
                  />
                  <CacRegDetailsBreakdown
                    title={'Residential Address'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.address}
                  />
                  <CacRegDetailsBreakdown
                    title={'Postal'}
                    isLoadingSingleCAC={isLoadingSingleCAC}
                    cacSingleData={director?.postal}
                  />
                  {(cacSingleData?.registration_type === 'sole-proprietorship' || cacSingleData?.registration_type === 'non-governmental-organisation') &&
                    <>
                      <CacRegDetailsBreakdown
                        title={'Government Issued ID'}
                        isLoadingSingleCAC={isLoadingSingleCAC}
                        cacSingleData={director?.govtid}
                        type={'file'}
                      />
                      <CacRegDetailsBreakdown
                        title={'Passport Photograpgh'}
                        isLoadingSingleCAC={isLoadingSingleCAC}
                        cacSingleData={director?.passportphoto}
                        type={'file'}
                      />
                      <CacRegDetailsBreakdown
                        title={'E-Signature '}
                        isLoadingSingleCAC={isLoadingSingleCAC}
                        cacSingleData={director?.esign}
                        type={'file'}
                      />
                    </>
                  }
                  {cacSingleData?.registration_type === 'limited-liability-company' &&
                    <>
                      <CacRegDetailsBreakdown
                        title={'Share Ownership'}
                        isLoadingSingleCAC={isLoadingSingleCAC}
                        cacSingleData={director?.share_ownership}
                      />
                      <CacRegDetailsBreakdown
                        title={'Government Issued ID'}
                        isLoadingSingleCAC={isLoadingSingleCAC}
                        cacSingleData={director?.govtid}
                        type={'file'}
                      />
                      <CacRegDetailsBreakdown
                        title={'Passport Photograpgh'}
                        isLoadingSingleCAC={isLoadingSingleCAC}
                        cacSingleData={director?.passportphoto}
                        type={'file'}
                      />
                      <CacRegDetailsBreakdown
                        title={'E-Signature '}
                        isLoadingSingleCAC={isLoadingSingleCAC}
                        cacSingleData={director?.esign}
                        type={'file'}
                      />
                    </>
                  }
                  {cacSingleData?.registration_type === 'limited-liability-partnership' &&
                    <>
                      <CacRegDetailsBreakdown
                        title={'Statement of Amount'}
                        isLoadingSingleCAC={isLoadingSingleCAC}
                        cacSingleData={director?.statement}
                        type={'file'}
                      />
                      <CacRegDetailsBreakdown
                        title={'Government Issued ID'}
                        isLoadingSingleCAC={isLoadingSingleCAC}
                        cacSingleData={director?.govtid}
                        type={'file'}
                      />
                      <CacRegDetailsBreakdown
                        title={'E-Signature '}
                        isLoadingSingleCAC={isLoadingSingleCAC}
                        cacSingleData={director?.esign}
                        type={'file'}
                      />
                    </>
                  }

                </div>
              </div>}
          </div>)}
        </div>}
      {showModal &&
        <MarkCACAsDeliveredModal
          selectedCAC={{ id }}
          setSelectedCAC={() => { }}
          showModal={showModal}
          setShowModal={setShowModal}
          currentParams={{}}
          status={status}
        />}
    </div>
  )
}

export default CacRegistrationDetails;
import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import TabNav from "../../components/navbars/TabNav";
import useTransactions from "../../hooks/useTransactions";
import {
	getAllPayrollTransactions,
	getAllPayrollTransactionsForDownload,
} from "../../redux/transactions/transactionsActions";
import {
	copyTextToClipboard,
	fixDateForAllBrowsers,
	truncateRef,
} from "../../helpers/helper";
import { FiCopy } from "react-icons/fi";

export default function PayrollTransactions() {
	const dispatch = useDispatch();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const { isLoadingAllPayrollTransactions, allPayrollTransactions } =
		useTransactions();

	const defaultFilterParams = {
		search: "",
		page: currentPage,
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, status, search, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_from, date_to, search]);

	React.useEffect(() => {
		dispatch(
			getAllPayrollTransactions({
				params: {
					...(search
						? { search, paginate: 1, page, per_page: 10 }
						: {
								date_from,
								date_to,
								paginate: 1,
								page,
								per_page: 10,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		// isReferenceSearch,
		// page,
		// per_page,
		search,
		status,
	]);

	React.useEffect(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	// Clear query param on render
	// useEffect(() => {
	//   urlSearchParamsHistory.replace({});
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		// setIsReferenceSearch(false);
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	return (
		<div className="p-3">
			<TabNav
				tabs={[
					{ title: "Transfers", path: "/dashboard/outflows" },
					{ title: "Bills", path: "/dashboard/outflows/bills" },
					{ title: "Payroll", path: "/dashboard/outflows/payroll" },
					{ title: "Interntional Transfers", path: "/dashboard/outflows/international-transfers" },
				]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				filterFields={["date"]}
				filterCallback={handleChange}
				disabled={isLoadingAllPayrollTransactions}
				fileName="Payroll Transactions"
				downloadAsCSV
				CSVCallback={(handleDownload) =>
					dispatch(
						getAllPayrollTransactionsForDownload(
							{
								params: {
									...(search
										? { search, paginate: 0, page, per_page: 10 }
										: {
												// currency,
												date_from,
												date_to,
												paginate: 0,
												page,
												per_page: 10,
												status,
										  }),
								},
							},
							(data) =>
								handleDownload({
									data,
									fileName: "Payroll Transactions History",
									format: (value, i) => ({
										"S/N": `${i + 1}`,
										"Payroll ID": value?.payroll_id,
										Description: value?.description,
										Amount: value?.total_amount,
										"Total Staff": value?.total_staff,
										"Transaction Date": value?.transaction_date,
									}),
								})
						)
					)
				}
				title={`Transactions ${
					allPayrollTransactions?.length
						? "- " + allPayrollTransactions?.length
						: ""
				}`}
				searchBarPlaceholder={"Payroll ID"}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof allPayrollTransactions === "object" &&
					!Array.isArray(allPayrollTransactions)
						? allPayrollTransactions?.data
						: allPayrollTransactions
				}
				empty_message="No transactions"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllPayrollTransactions}
				setCurrentPage={setCurrentPage}
				totalPages={allPayrollTransactions?.total}
			/>
		</div>
	);
}

const columns = [
	{
		accessor: "payroll_id",
		Header: "Payroll ID",
		Cell: ({ value, row }) => (
			<div className="flex items-center">
				<Link
					to={`/dashboard/outflows/payroll/detail/${row.original.payroll_id}`}
				>
					<span className="glade-normal-text-two">{truncateRef(value)}</span>
				</Link>
				<FiCopy
					size={16}
					color="#AFAFAF"
					className="ml-3 cursor-pointer"
					onClick={() => copyTextToClipboard(value)}
				/>
			</div>
		),
	},
	{
		accessor: "description",
		Header: "Description",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/outflows/payroll/detail/${row.original.payroll_id}`}
			>
				<span className="glade-normal-text-two capitalize">{value}</span>
			</Link>
		),
	},
	{
		accessor: "total_amount",
		Header: "Amount",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/outflows/payroll/detail/${row.original.payroll_id}`}
			>
				<span className="glade-normal-text-two">
					NGN {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			</Link>
		),
	},
	{
		accessor: "total_staff",
		Header: "Total Staff",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/outflows/payroll/detail/${row.original.payroll_id}`}
			>
				<span className="glade-normal-text-two block">{value}</span>
			</Link>
		),
	},
	{
		accessor: "transaction_date",
		Header: "Transaction Date",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/outflows/payroll/detail/${row.original.payroll_id}`}
			>
				<span className="glade-normal-text-two">
					{dfn.format(
						new Date(fixDateForAllBrowsers(value)),
						"MMM d, yyyy hh:mm:ss a"
					)}
				</span>
			</Link>
		),
	},
];

import React from "react";
import { GLADE_EMPTY_FOLDER_ICON } from "../../assets/images/images";
import { TextSkeleton } from "../../components/skeletons";

const PayrollAdvanceDetailsCard = ({ details, loading }) => {
    return (
        <div className="flex flex-col bg-white rounded-sm px-8 py-4 mb-6">
            <div className="py-3 border-b-2 border-solid border-gray-100">
                <p className="glade-black font-bold">Advance Details</p>
            </div>
            {loading ? (
                <div className="grid grid-cols-4 gap-4 py-3">
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                           Amount
                        </h5>
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            <TextSkeleton />
                        </h6>
                    </div>
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                            Reason for requesting
                        </h5>
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            <TextSkeleton />
                        </h6>
                    </div>
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                          Date requested
                        </h5>
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            <TextSkeleton />
                        </h6>
                    </div>
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                           Date due
                        </h5>
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            <TextSkeleton />
                        </h6>
                    </div>
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                           Repayment status
                        </h5>
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            <TextSkeleton />
                        </h6>
                    </div>
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                           Business description
                        </h5>
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            <TextSkeleton />
                        </h6>
                    </div>
                </div>
            ) : (
                <>
                   
                        <div className="grid grid-cols-4 gap-4 py-3">
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                   Amount
                                </h5>
                                {!loading && details?.id ? (
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {"NGN"} {Number(details?.amount).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
                                           
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                Reason for requesting
                                </h5>
                                {!loading && details?.id ? (
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {details?.reason}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                Date requested
                                </h5>
                                {!loading? (
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {details?.requested_at
}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1"> Due date</h5>
                                {!loading ? (
                                   
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {details?.due_date}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>

                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">  Repayment status</h5>
                                {!loading ? (
                                   
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {details?.repayment_status ?? "N/A"}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1"> Business description</h5>
                                {!loading ? (
                                   
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {details?.description}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                        </div>
                   
                       
                   
                </>
            )}
        </div>
    )
}

export default PayrollAdvanceDetailsCard;
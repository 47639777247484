import React from "react";
import * as dfn from "date-fns";
import { FiCopy } from "react-icons/fi";
import { RiLoader5Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import { PopoverButton, PopoverMain } from "../../components/popovers/Popover";
import { TextSkeleton } from "../../components/skeletons";
import { copyTextToClipboard, statusEnum } from "../../helpers/helper";
import useTransactions from "../../hooks/useTransactions";

import {
	getSpecificTransaction,
	revertSpecificBillTransactions,
	verifySpecificBillTransactions,
} from "../../redux/transactions/transactionsActions";

export default function SpecificPosTransactionDetail() {
	const { id } = useParams();
	const dispatch = useDispatch();

	const {
		isLoadingSpecificTransaction: loading,
		specificTransaction: details,
		isRevertingSpecificBillTransactions,
		isVerifyingSpecificBillTransactions,
	} = useTransactions();

	React.useEffect(() => {
		dispatch(getSpecificTransaction(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<div className="p-3 flex flex-col">
			<div className="mb-6">
				<BreadCrumbs title={"Transactions"} goBack />
			</div>

			<div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">Account Details</p>
				</div>
				<div className="grid grid-cols-4 gap-4 py-3">
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Name
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.account_name}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Email
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.account_email}
							</h6>
						)}
					</div>
					{details?.account_phone && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Phone Number
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{details?.account_phone}
								</h6>
							)}
						</div>
					)}
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Type
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1 capitalize">
								{details?.account_type}
							</h6>
						)}
					</div>
				</div>
			</div>

			<div className="flex justify-between mt-4">
				{loading ? (
					<TextSkeleton width="100px" />
				) : (
					<BasicIndicator
						className="px-5 py-1 capitalize"
						type={statusEnum[details?.status]}
						title={details?.status}
					/>
				)}
				<div className="flex items-center space-x-3">
					{["failed"].includes(details?.status) && (
						<PopoverMain
							button={<Button title="Revert" disabled={loading} />}
							disabled={loading}
							popoverClassName="p-6 w-80 right-0 bg-white shadow-md top-12 rounded"
						>
							<div className="py-4 px-1">
								<p className=" glade-black text-center mb-8">
									Are you sure you want to revert this transaction?
								</p>
								<button
									onClick={() => {
										dispatch(revertSpecificBillTransactions(details.txn_ref));
									}}
									disabled={isRevertingSpecificBillTransactions}
									className={`glade-normal-text-three glade-button-blue ${
										isRevertingSpecificBillTransactions
											? " cursor-not-allowed"
											: ""
									} text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
								>
									{isRevertingSpecificBillTransactions ? "Reverting" : "Revert"}
									{isRevertingSpecificBillTransactions && (
										<RiLoader5Fill size={24} className="animate-spin ml-4" />
									)}
								</button>
								<PopoverButton>
									<p className="glade-blue mx-1 cursor-pointer text-center">
										Cancel
									</p>
								</PopoverButton>
							</div>
						</PopoverMain>
					)}
					{["failed", "pending"].includes(details?.status) && (
						<PopoverMain
							button={<Button title="Requery" disabled={loading} />}
							disabled={loading}
							popoverClassName="p-6 w-80 right-0 bg-white shadow-md top-12 rounded"
						>
							<div className="py-4 px-1">
								<p className=" glade-black text-center mb-8">
									Are you sure you want to requery this transaction?
								</p>
								<button
									onClick={() => {
										dispatch(verifySpecificBillTransactions(details.txn_ref));
									}}
									disabled={isVerifyingSpecificBillTransactions}
									className={`glade-normal-text-three glade-button-blue ${
										isVerifyingSpecificBillTransactions
											? " cursor-not-allowed"
											: ""
									} text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
								>
									{isVerifyingSpecificBillTransactions
										? "Requerying"
										: "Requery"}
									{isVerifyingSpecificBillTransactions && (
										<RiLoader5Fill size={24} className="animate-spin ml-4" />
									)}
								</button>
								<PopoverButton>
									<p className="glade-blue mx-1 cursor-pointer text-center">
										Cancel
									</p>
								</PopoverButton>
							</div>
						</PopoverMain>
					)}
				</div>
			</div>

			<div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">
						Transaction Details
					</p>
				</div>

				<div className="grid grid-cols-4 gap-4 py-3">
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Type</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1 capitalize">
								{details?.type}
							</h6>
						)}
					</div>

					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">RRN</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<div className="flex items-center">
								<h6 className="glade-normal-text-three glade-black py-1">
									{details?.rrn}
								</h6>
								<FiCopy
									size={16}
									color="#AFAFAF"
									className="ml-3 cursor-pointer"
									onClick={() => copyTextToClipboard(details?.rrn ?? "")}
								/>
							</div>
						)}
					</div>

					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								NGN {Number(details?.amount)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
							</h6>
						)}
					</div>

					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Date</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{dfn.format(new Date(details?.transaction_date), "MMM d, yyyy")}
							</h6>
						)}
					</div>

					{details?.channel && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Transaction Channel
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1 capitalize">
									{details?.channel}
								</h6>
							)}
						</div>
					)}

					{details?.terminal_id && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Terminal ID
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1 capitalize pr-4">
									{details?.terminal_id}
								</h6>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

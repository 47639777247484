import React from 'react';
import { Carousel, CarouselSlide } from '../../components/carousel/Carousel';
import {GLADE_INFO_GRAPHICS_ONE,GLADE_INFO_GRAPHICS_TWO,GLADE_INFO_GRAPHICS_THREE,GLADE_INFO_GRAPHICS_FOUR} from '../../assets/images/images';

export default function CarouselAuth() {

    const IMAGES = [
        {
            imageUrl: GLADE_INFO_GRAPHICS_ONE,
            placeHolder: "Business Banking"
        },
        {
            imageUrl: GLADE_INFO_GRAPHICS_TWO,
            placeHolder: "Fund Transfer"
        },
        {
            imageUrl: GLADE_INFO_GRAPHICS_THREE,
            placeHolder: "Loans And Overdraft"
        },
        {
            imageUrl: GLADE_INFO_GRAPHICS_FOUR,
            placeHolder: "Debit And Virtual Cards"
        },
    ];

    return (
        <Carousel time={5000}>
            {IMAGES.map((item,key)=>{
                return (
                    <CarouselSlide key={key} imageUrl={item.imageUrl} placeHolder={item.placeHolder} />
                )
            })}
        </Carousel>
    )
}

import React from 'react';
import {FaBars} from 'react-icons/fa';

export default function SideBarOpenButton({onClick=()=>{}}) {
    return (
        <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={()=>onClick()}>
            <FaBars />
        </button>
    )
}

import React, { useEffect, useMemo, useState } from "react";
import * as dfn from "date-fns";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import { fixDateForAllBrowsers, truncateRef } from "../../helpers/helper";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import { PaginatedTable } from "../../components/table/Table";
import CountryFlag from "./CountryFlag";
import { getAllAccountRequests, getAllAccountRequestsForDownload, getCountries } from "../../redux/wallets/walletsActions";
import useWallets from "../../hooks/useWallets";
import TabNav from "../../components/navbars/TabNav";

export default function AllAccountRequests() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page || 1
  );


  const { accountRequestsData, isLoadingAccountRequests } = useWallets();

  const [countries, setCountries] = useState([]);

  const defaultFilterParams = {
    search: "",
    page: currentPage,
    status: "pending",
    date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 150), "yyyy-MM-dd"),
    date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
  };

  const filterInstance = usePaginationFilterParams({
    initialFilters: defaultFilterParams,
  });

  const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

  const { date_from, date_to, search, status, page } = filters;

  const [filterValue, setFilterValue] = React.useState(filters);

  useMemo(() => {
    // setCurrentPage(1);
    setFilterValue(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_from, date_to, search, status]);

  useMemo(() => {
    dispatch(getAllAccountRequests({
      params: {
        ...(search
          ? {
            search: search,
            status,
            paginate: 1,
            page,
            per_page: 10,
          }
          : {
            date_from,
            date_to,
            status,
            paginate: 1,
            page,
            per_page: 10,
          }),
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // currency,
    date_from,
    date_to,
    status,
    page,
    // per_page,
    search,
  ]);

  useMemo(() => {
    handleChange({ ...filterValue, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    countries?.length < 1 && dispatch(getCountries((res) => setCountries(res)));
  }, [])

  const resetReferenceSearch = (event) => {
    if (typeof event?.preventDefault === "function") {
      event?.preventDefault();
    }
    urlSearchParamsHistory.replace(defaultFilterParams);
  };

  const findCountryCode = (country) => {
    return countries.find(x => x.country_name?.toLowerCase() === country.toLowerCase())?.country_code;
  }

  const findCountryName = (country) => {
    return countries.find(x => x.country_code?.toLowerCase() === country.toLowerCase())?.country_name;
  }

  const columns = [

    {
      accessor: "business_name",
      Header: "Business Name",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/account-request/details?id=${row?.original?.id}`}
        >
          <span className="glade-normal-text-two capitalize">
            {value}
          </span>
        </Link>
      ),
    },
    {
      accessor: "business_email",
      Header: "Business Email",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/account-request/details?id=${row?.original?.id}`}
        >
          <span className="glade-normal-text-two">{truncateRef(value)}</span>
        </Link>
      ),
    },
    {
      accessor: "industry",
      Header: "Business Industry",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/account-request/details?id=${row?.original?.id}`}
        >
          <span className="glade-normal-text-two">{value}</span>
        </Link>
      ),
    },
    {
      accessor: "country",
      Header: "Country of Corporation",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/account-request/details?id=${row?.original?.id}`}
        >
          <span className="flex items-center gap-3 glade-normal-text-two">
            <CountryFlag flagCode={value.length === 2 ? value : findCountryCode(value)} /> {value.length === 2 ? findCountryName(value) : value}
          </span>
        </Link>
      ),
    },
    {
      accessor: "volume_processed",
      Header: "Volume Processed Monthly ($)",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/account-request/details?id=${row?.original?.id}`}
        >
          <span className="glade-normal-text-two">{value}</span>
        </Link>
      ),
    },
    {
      accessor: "date_requested",
      Header: "Date Requested",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/account-request/details?id=${row?.original?.id}`}
        >
          <span className="glade-normal-text-two">
            {value ? dfn.format(
              new Date(fixDateForAllBrowsers(value)),
              "MMM d, yyyy"
            ) : dfn.format(
              new Date(),
              "MMM d, yyyy")
            }
          </span>
        </Link>
      ),
    },
  ];

  return (
    <div className="p-3">
      <TabNav
        tabs={[
          { title: "Wallets", path: "/dashboard/accountrequests/wallets" },
          { title: "Accounts", path: "/dashboard/accountrequests/accounts" },
        ]}
      />
      <CardTableHeader
        onChange={(e) => {
          const value = e.target.value;
          if (value) {
            handleChange({ search: value.trim() });
          } else {
            resetReferenceSearch();
          }
        }}
        defaultFilterParams={defaultFilterParams}
        filterFields={["date"]}
        filterCallback={handleChange}
        disabled={isLoadingAccountRequests}
        title={`Accounts ${!isLoadingAccountRequests && accountRequestsData?.total
          ? "- " + accountRequestsData?.total
          : ""
          }`}
        searchBarPlaceholder={"Account Name or Email"}
        fileName="All AccountRequests"
        downloadAsCSV
        CSVCallback={(handleDownload) =>
          dispatch(
            getAllAccountRequestsForDownload(
              {
                params: {
                  ...(search
                    ? { search, paginate: 0, page, per_page: 10 }
                    : {
                      date_from,
                      date_to,
                      paginate: 0,
                      page,
                      per_page: 10,
                      // status,
                    }),
                },
              },
              (data) =>
                handleDownload({
                  data,
                  fileName: "Account Requests",
                  format: (value, i) => ({
                    "S/N": `${i + 1}`,
                    "Business Name": value?.business_name,
                    "Business Email": value?.business_email,
                    "Business Industry": value?.business_industry,
                    "Country of Corporation": value?.country,
                    "Volume Processed Monthly ($)": value?.volume_processed,
                    "Business Website": value?.business_website,
                    "Date Requested": value?.date_requested,
                  }),
                })
            )
          )
        }
        getWorksheets={({ xlsx }) => {
          return {
            data: xlsx.utils.json_to_sheet(
              AllAccountRequests?.data?.map((value, i) => ({
                "S/N": `${i + 1}`,
                "Business Name": value?.business_name,
                "Business Email": value?.business_email,
                "Business Industry": value?.business_industry,
                "Country of Corporation": value?.country,
                "Volume Processed Monthly ($)": value?.volume_processed,
                "Business Website": value?.business_website,
                "Date Requested": value?.date_requested,
              }))
            ),
          };
        }}
      />
      <PaginatedTable
        columns={columns}
        data={
          typeof accountRequestsData === "object" &&
            !Array.isArray(accountRequestsData)
            ? accountRequestsData.data
            : accountRequestsData
        }
        empty_message="No accounts"
        empty_sub_message=""
        current_page={currentPage}
        loading={isLoadingAccountRequests}
        setCurrentPage={setCurrentPage}
        totalPages={accountRequestsData?.total}
      />
    </div>
  );
}

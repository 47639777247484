import useURLFilterParams from "./useURLFilterParams";

/**
 *
 * @type {typeof useURLFilterParams}
 */
function usePaginationFilterParams(options) {
  const { initialFilters, ...rest } = options || {};
  const urlFilterParams = useURLFilterParams({
    initialFilters: {
      page: 1,
      per_page: 10,
      ...initialFilters,
    },
    ...rest,
  });

  return urlFilterParams;
}

export default usePaginationFilterParams;

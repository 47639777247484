import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import usePos from "../../hooks/usePos";
import {
	assignPosTerminal,
	getBusinessbyUUID,
	getUserbyUUID,
} from "../../redux/pos/posActions";
import { FormError } from "../../validation";
import Select from "react-select";
import { noBorderDarkSelectStyle } from "../../helpers/select_style";
import MemoizedLoadingModal from "../../components/modals/LoadingModal";
import { debounce, isArray, isObject } from "../../helpers/helper";
import * as lodash from "lodash";

const IndicatorSeparator = ({ innerProps }) => {
	return (
		<span
			style={{
				background: "transparent",
			}}
			{...innerProps}
		/>
	);
};

const primarySelectOptions = [
	{
		label: "User UUID",
		value: "personal",
	},
	{
		label: "Business UUID",
		value: "business",
	},
];

const AssignPosModal = ({ setShowAssignPosModal, showAssignPosModal }) => {
	const dispatch = useDispatch();

	const {
		isAssigningPosTerminal,
		allUsers,
		searchedAccount,
		isLoadingSearchedAccount,
	} = usePos();

	const onAssignTerminal = (data) => {
		dispatch(
			assignPosTerminal({
				terminal_id: data.terminal_id,
				request_id: 0,
				owner_uuid: selectedAccount.owner_uuid,
				owner_type: searchType.value,
			})
		);
	};

	const [selectedAccount, setSelectedAccount] = useState({});

	const {
		handleSubmit,
		register,
		control,
		reset,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			type: {
				label: "User UUID",
				value: "personal",
			},
		},
	});

	const searchType = watch("type");
	// const uuid = watch("uuid");

	// useEffect(() => {
	// 	if (searchType?.value === "name") {
	// 		setOptions(allUsers);
	// 	} else {
	// 		setOptions(arrangeUserOptionsById);
	// 	}

	// 	return () => {
	// 		setOptions([]);
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [searchType]);

	// useEffect(() => {
	// 	if (uuid) {
	// 		searchAccount(uuid);
	// 	} else {
	// 		setSelectedAccount({});
	// 	}

	// 	return () => {
	// 		setSelectedAccount({});
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [uuid]);

	const searchAccount = (value) => {
		if (searchType?.value === "personal") {
			dispatch(getUserbyUUID(value));
		} else {
			dispatch(getBusinessbyUUID(value));
		}
	};

	useMemo(() => {
		setValue("uuid", "");
		setSelectedAccount({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchType]);

	useMemo(() => {
		if(isArray(searchedAccount)){
			return setSelectedAccount(searchedAccount?.[0]);
		}else if(isObject){
			return setSelectedAccount(searchedAccount);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchedAccount]);

	return (
		<BasicModal
			visibility={showAssignPosModal}
			onClose={() => {
				reset();
				setShowAssignPosModal(false);
			}}
			onOpen={() => setShowAssignPosModal(true)}
			primaryButton=""
			modalTitle="Assign New Terminal"
			secondaryButton=""
			// modalStyle={{
			// 	width: "40%",
			// 	maxWidth: "none",
			// }}
			modelContentSection={
				<form onSubmit={handleSubmit(onAssignTerminal)} className="">
					<div className="w-full mt-2 mb-5">
						{/* Alterable select */}
						<div className="mt-2 w-full flex py-2 border-gray-500 rounded-sm glade-normal-text-two">
							<div className="w-40">
								<Controller
									control={control}
									id="type"
									name="type"
									render={({ field: { onChange, value, ref } }) => (
										<Select
											styles={noBorderDarkSelectStyle}
											inputRef={ref}
											autoComplete="true"
											options={primarySelectOptions}
											value={value}
											onChange={onChange}
											components={{ IndicatorSeparator }}
											isLoading={allUsers?.length < 1}
											isDisabled={allUsers?.length < 1}
											className="w-full"
											isSearchable={false}
										/>
									)}
									defaultValue={{
										label: "User UUID",
										value: "personal",
									}}
								/>
							</div>

							{searchType?.value === "personal" ? (
								<div className="flex-grow">
									<input
										type="text"
										id="uuid"
										name="uuid"
										{...register("uuid", {
											required: "Enter a user UUID",
										})}
										onChange={debounce(
											(event) => searchAccount(event?.target?.value),
											2000
										)}
										placeholder="Enter User UUID"
										className="block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two flex-grow"
									/>
									<FormError errors={errors} name="uuid" />
								</div>
							) : (
								<div className="flex-grow">
									<input
										type="text"
										id="uuid"
										name="uuid"
										{...register("uuid", {
											required: "Enter a business UUID",
										})}
										onChange={debounce(
											(event) => searchAccount(event?.target?.value),
											2000
										)}
										placeholder="Enter Business UUID"
										className="block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two flex-grow"
									/>
									<FormError errors={errors} name="uuid" />
								</div>
							)}
						</div>

						{errors.account && (
							<p className="text-red-500 text-xs mt-1">Select a user account</p>
						)}
					</div>
					
					{!lodash.isEmpty(selectedAccount) && (
						<>
							<label
								htmlFor="terminal_id"
								className="block glade-small-text-two mb-2"
							>
								Account Name
							</label>
							<div className="block w-full px-2.5 py-3 glade-bg-off-white border mb-4 border-gray-300 rounded-sm glade-normal-text-two flex-grow">
								<p className="block glade-small-text-two capitalize">
									{selectedAccount?.account_name}
								</p>
							</div>
						</>
					)}

					<div className="mt-5">
						<label htmlFor="terminal_id" className="block glade-small-text-two">
							Terminal ID
						</label>
						<input
							type="text"
							id="terminal_id"
							name="terminal_id"
							{...register("terminal_id", {
								required: "Enter a terminal id",
							})}
							placeholder="Enter Terminal ID"
							className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
						/>
						<FormError errors={errors} name="terminal_id" />
					</div>

					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={() => {
								reset();
								setShowAssignPosModal(false);
							}}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Assign Terminal"
								className="block flex-grow-0"
								disabled={lodash.isEmpty(selectedAccount)}
								loading={isAssigningPosTerminal}
							/>
						</div>
					</div>
					<MemoizedLoadingModal
						primaryButton=""
						modalTitleSection=""
						secondaryButton=""
						visibility={isLoadingSearchedAccount}
					/>
				</form>
			}
		/>
	);
};

export default AssignPosModal;

import {
  GET_VIRTUAL_CARD_SETTINGS_FAILURE,
  GET_VIRTUAL_CARD_SETTINGS_START,
  GET_VIRTUAL_CARD_SETTINGS_SUCCESS,
  UPDATE_VIRTUAL_CARD_SETTINGS_FAILURE,
  UPDATE_VIRTUAL_CARD_SETTINGS_START,
  UPDATE_VIRTUAL_CARD_SETTINGS_SUCCESS
} from "./types";

const initialState = {
  isLoadingVirtualCardSettings: false,
  isUpdatingVirtualCardSettings: false,
  virtualCardSettings: []
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VIRTUAL_CARD_SETTINGS_START:
      return {
        ...state,
        isLoadingVirtualCardSettings: true
      };

    case GET_VIRTUAL_CARD_SETTINGS_FAILURE:
      return {
        ...state,
        isLoadingVirtualCardSettings: false
      };

    case GET_VIRTUAL_CARD_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoadingVirtualCardSettings: false,
        virtualCardSettings: action.payLoad
      };

    case UPDATE_VIRTUAL_CARD_SETTINGS_START:
      return {
        ...state,
        isUpdatingVirtualCardSettings: true
      };

    case UPDATE_VIRTUAL_CARD_SETTINGS_FAILURE:
      return {
        ...state,
        isUpdatingVirtualCardSettings: false
      };

    case UPDATE_VIRTUAL_CARD_SETTINGS_SUCCESS:
      return {
        ...state,
        isUpdatingVirtualCardSettings: false
      };

    default:
      return state;
  }
}
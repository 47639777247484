import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import TabNav from "../../components/navbars/TabNav";
import useAccounts from "../../hooks/useAccounts";
import {
  getAllBusinessAccounts,
  getAllBusinessAccountsForDownload,
  getAllBusinessAccountsWithSearch
} from "../../redux/accounts/accountsActions";
import PillIndicator from "../../components/indicators/PillIndicator";
import { fixDateForAllBrowsers, statusEnum } from "../../helpers/helper";
import { PopoverMain } from "../../components/popovers/Popover";
import { FiMoreVertical } from "react-icons/fi";

export default function BusinessAccounts() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page ?? 1
  );

  const { isLoadingAllBusinessAccounts, allBusinessAccounts } = useAccounts();

  const defaultFilterParams = {
    search: "",
    page: currentPage,
    status: "all",
    date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 150), "yyyy-MM-dd"),
    date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
  };

  const filterInstance = usePaginationFilterParams({
    initialFilters: defaultFilterParams,
  });

  const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

  const { date_from, date_to, search, status, page } = filters;

  const [filterValue, setFilterValue] = React.useState(filters);

  useEffect(() => {
    setCurrentPage(1);
    setFilterValue(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_from, date_to, search, status]);

  React.useMemo(() => {
    dispatch(
      getAllBusinessAccounts({
        params: {
          ...(search
            ? {
                business_name: search,
                status,
                paginate: 1,
                page,
                per_page: 10,
              }
            : {
                date_from,
                date_to,
                status,
                paginate: 1,
                page,
                per_page: 10,
              }),
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // currency,
    date_from,
    date_to,
    status,
    page,
    // per_page,
    search,
  ]);

  React.useMemo(() => {
    handleChange({ ...filterValue, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // // Clear query param on render
  // useEffect(() => {
  //   urlSearchParamsHistory.replace({});
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleSearch = (search) => {
    if (search !== '') {
      dispatch(getAllBusinessAccountsWithSearch({
        params: {
          ...(search
            ? {
              business_name: search,
              status,
              paginate: 1,
              page,
              per_page: 10,
            }
            : {
              date_from,
              date_to,
              status,
              paginate: 1,
              page,
              per_page: 10,
            }),
        }
      }))
    }
  }

  const resetReferenceSearch = (event) => {
    if (typeof event?.preventDefault === "function") {
      event?.preventDefault();
    }
    urlSearchParamsHistory.replace(defaultFilterParams);
  };

  const columns = [
    // {
    // 	accessor: "business_status",
    // 	Header: "Account Status",
    // 	Cell: ({ value, row }) => (
    // 		<Link
    // 			to={`/dashboard/accounts/business/detail/${row.original.business_uuid}/${row.original.user_uuid}`}
    // 		>
    // 			<PillIndicator
    // 				className="px-5 py-1 capitalize"
    // 				type={statusEnum[value]}
    // 				title={value}
    // 			/>
    // 		</Link>
    // 	),
    // },
    {
      accessor: "compliance_status",
      Header: "Compliance Status",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/business/detail/${row.original.business_uuid}/${row.original.user_uuid}`}
        >
          <PillIndicator
            className="px-5 py-1 capitalize"
            type={statusEnum[value]}
            title={value}
          />
        </Link>
      ),
    },
    {
      accessor: "business_name",
      Header: "Business Name",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/business/detail/${row.original.business_uuid}/${row.original.user_uuid}`}
        >
          <span className="glade-normal-text-two capitalize">{value}</span>
        </Link>
      ),
    },
    {
      accessor: "user_name",
      Header: "Full Name",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/business/detail/${row.original.business_uuid}/${row.original.user_uuid}`}
        >
          <span className="glade-normal-text-two capitalize">{value}</span>
        </Link>
      ),
    },
    {
      accessor: "business_email",
      Header: "Email",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/business/detail/${row.original.business_uuid}/${row.original.user_uuid}`}
        >
          <span className="glade-normal-text-two">{value}</span>
        </Link>
      ),
    },
    {
      accessor: "contact_phone",
      Header: "Phone Number",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/business/detail/${row.original.business_uuid}/${row.original.user_uuid}`}
        >
          <span className="glade-normal-text-two">{value}</span>
        </Link>
      ),
    },
    {
      accessor: "created_at",
      Header: "Date Created",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/business/detail/${row.original.business_uuid}/${row.original.user_uuid}`}
        >
          <span className="glade-normal-text-two">
            {dfn.format(
              new Date(fixDateForAllBrowsers(value)),
              "MMM d, yyyy hh:mm:ss a"
            )}
          </span>
        </Link>
      ),
    },
    {
      accessor: "id",
      Header: "Actions",
      Cell: ({ value, row }) => (
        <PopoverMain
          button={
            <div type="button" className={"py-2 glade-ash "}>
              <div className="inline-flex items-center justify-items-center text-center text-2xl">
                <FiMoreVertical
                  color="#151515"
                  className="mx-auto cursor-pointer"
                />
              </div>
            </div>
          }
          popoverClassName="right-0"
        >
          <ul className="px-2 py-5 bg-white shadow rounded-md w-56">
            <Link
              to={`/dashboard/outflows/bills?search=${row?.original?.business_name}`}
            >
              <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
                View Bills
              </li>
            </Link>
            <Link
              to={`/dashboard/outflows?search=${row?.original?.business_name}`}
            >
              <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
                View Transfers
              </li>
            </Link>
            {/* <li className="p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer">
							View Payments
						</li> */}
            {/* <li className="p-3 glade-normal-text-two glade-red glade-hover-bg-red-100 cursor-pointer">
							Blacklist
						</li> */}
          </ul>
        </PopoverMain>
      ),
    },
  ];

  return (
    <div className="p-3">
      <TabNav
        tabs={[
          { title: "Personal", path: "/dashboard/accounts" },
          { title: "Business", path: "/dashboard/accounts/business" },
          { title: "Agent", path: "/dashboard/accounts/agents" },
          { title: "Tier Requests", path: "/dashboard/accounts/limit" },
          // { title: "Blacklist", path: "/dashboard/accounts/blacklist" },
        ]}
      />
      <CardTableHeader
        onChange={(e) => {
          const value = e.target.value;
          if (value) {
            // handleChange({ search: value.trim() });
            handleSearch(value.trim())
          } else {
            resetReferenceSearch();
          }
        }}
        filterFields={["account", "date"]}
        filterCallback={handleChange}
        disabled={isLoadingAllBusinessAccounts}
        title={`Accounts ${
          !isLoadingAllBusinessAccounts && allBusinessAccounts?.total
            ? "- " + allBusinessAccounts?.total
            : ""
        }`}
        defaultFilterParams={defaultFilterParams}
        searchBarPlaceholder={""}
        fileName="Business Acounts"
        downloadAsCSVDark
        CSVCallback={(handleDownload) =>
          dispatch(
            getAllBusinessAccountsForDownload(
              {
                params: {
                  ...(search
                    ? {
                        business_name: search,
                        date_from,
                        date_to,
                        status,
                        paginate: 0,
                        page,
                        per_page: 10,
                      }
                    : {
                        date_from,
                        date_to,
                        status,
                        paginate: 0,
                        page,
                        per_page: 10,
                      }),
                },
              },
              (data) =>
                handleDownload({
                  data,
                  fileName: "Business Accounts",
                  format: (value, i) => ({
                    "S/N": `${i + 1}`,
                    Status: value?.business_status,
                    Compliance: value?.compliance_status,
                    "Account Name":
                      value?.business_name + " " + value?.lastname,
                    Email: value?.contact_business_email,
                    "Phone Number": value?.contact_phone,
                    "Date Created": value?.created_at,
                  }),
                })
            )
          )
        }
      />
      <PaginatedTable
        columns={columns}
        data={
          typeof allBusinessAccounts === "object" &&
          !Array.isArray(allBusinessAccounts)
            ? allBusinessAccounts?.data
            : allBusinessAccounts
        }
        empty_message="No accounts"
        empty_sub_message=""
        current_page={currentPage}
        loading={isLoadingAllBusinessAccounts}
        setCurrentPage={setCurrentPage}
        totalPages={allBusinessAccounts?.total}
      />
    </div>
  );
}

import { useSelector } from "react-redux";

const useSettings = () => {
  const { settings } = useSelector(state => state);
  const {
    isLoadingVirtualCardSettings,
    isUpdatingVirtualCardSettings,
    virtualCardSettings
  } = settings;

  return {
    isLoadingVirtualCardSettings,
    isUpdatingVirtualCardSettings,
    virtualCardSettings
  };
};

export default useSettings;
import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastSuccess } from "../../helpers/utils.toast";
import {
	FAILURE_CURRENCIES_LIST,
	GET_ALL_FEES_FAILURE,
	GET_ALL_FEES_START,
	GET_ALL_FEES_SUCCESS,
	GET_CHECKOUT_FEES_FAILURE,
	GET_CHECKOUT_FEES_START,
	GET_CHECKOUT_FEES_SUCCESS,
	START_CURRENCIES_LIST,
	SUCCESS_CURRENCIES_LIST,
	UPDATE_FEE_FAILURE,
	UPDATE_FEE_START,
	UPDATE_FEE_SUCCESS,
} from "./types";

export const getAllFees = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_FEES_START });
		Axios.get(`settings/fees`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_FEES_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ALL_FEES_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getCheckoutFees = (currency_code) => {
	return (dispatch) => {
		dispatch({ type: GET_CHECKOUT_FEES_START });
		Axios.get(`settings/fees/checkout/${currency_code}`)
			.then((res) => {
				dispatch({
					type: GET_CHECKOUT_FEES_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_CHECKOUT_FEES_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};
export const addCheckoutCurrency = (payload, onSuccess=()=>{}) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_FEE_START });
		Axios.post(`settings/fees/checkout`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_FEE_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				onSuccess();
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_FEE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};
export const updateCheckoutCurrency = (payload, id, onSuccess=()=>{}) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_FEE_START });
		Axios.put(`settings/fees/checkout/${id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_FEE_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				onSuccess();
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_FEE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};
export const getCheckoutCurrencies = () => {
	return (dispatch) => {
		dispatch({ type: START_CURRENCIES_LIST });
		Axios.get(`currency/supported`)
			.then((res) => {
				dispatch({
					type: SUCCESS_CURRENCIES_LIST,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
			})
			.catch((error) => {
				dispatch({
					type: FAILURE_CURRENCIES_LIST,
					payLoad: error,
				});
				handler(error, true);
			});
	};
}

export const updateFee = (fee_id, payload) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_FEE_START });
		Axios.put(`settings/fees/fees/${fee_id}`, payload)
			.then((res) => {
				dispatch({
					type: UPDATE_FEE_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				window.location.reload();
			})
			.catch((error) => {
				dispatch({
					type: UPDATE_FEE_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import { updateBusinessAccountPhone } from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";

const EditBusinessPhoneModal = ({
	modalStates,
	setModalStates,
	showEditBusinessPhoneModal,
}) => {
	const dispatch = useDispatch();
	const { id, user_uuid } = useParams();

	const { isUpdatingBusinessAccountPhone, specificBusinessAccounts } =
		useAccounts();

	const onUpdateBusinessPhone = (data) => {
		dispatch(updateBusinessAccountPhone(user_uuid, id, data.phone));
	};

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm();

	return (
		<BasicModal
			visibility={showEditBusinessPhoneModal}
			onClose={() => {
				reset();
				setModalStates({ ...modalStates, showEditBusinessPhoneModal: false });
			}}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditBusinessPhoneModal: true,
				})
			}
			primaryButton=""
			modalTitle="Edit Business Phone"
			secondaryButton=""
			modelContentSection={
				<form onSubmit={handleSubmit(onUpdateBusinessPhone)} className="">
					<div className="">
						<label htmlFor="phone" className="block glade-small-text-two">
							Business Name
						</label>
						<input
							type="tel"
							id="phone"
							name="phone"
							{...register("phone", {
								required: "Enter a valid phone number",
								minLength: {
									value: 11,
									message: "Enter a valid phone number",
								},
							})}
							placeholder="Enter new phone number"
							defaultValue={
								specificBusinessAccounts?.business_details?.business
									?.contact_phone
							}
							className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
						/>
						<FormError errors={errors} name="phone" />
					</div>

					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={() => {
								reset();
								setModalStates({
									...modalStates,
									showEditBusinessPhoneModal: false,
								});
							}}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Save"
								className="block flex-grow-0"
								loading={isUpdatingBusinessAccountPhone}
							/>
						</div>
					</div>
				</form>
			}
		/>
	);
};

export default EditBusinessPhoneModal;

import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastSuccess } from "../../helpers/utils.toast";
import {
    GET_ALL_CAC_REGISTRATION_FAILURE,
    GET_ALL_CAC_REGISTRATION_START,
    GET_ALL_CAC_REGISTRATION_SUCCESS,
    GET_SINGLE_CAC_REGISTRATION_FAILURE,
    GET_SINGLE_CAC_REGISTRATION_START,
    GET_SINGLE_CAC_REGISTRATION_SUCCESS,
    MARK_CAC_REGISTRATION_FAILURE,
    MARK_CAC_REGISTRATION_START,
    MARK_CAC_REGISTRATION_SUCCESS,
} from "./types.js";

export const getAllCACRegistration = (params) => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_CAC_REGISTRATION_START });
        Axios.get(`merchants/business-registration/cac`, params)
            .then((res) => {
                dispatch({
                    type: GET_ALL_CAC_REGISTRATION_SUCCESS,
                    payLoad: res.data.data,
                });
            })
            .catch((error) => {
                dispatch({ type: GET_ALL_CAC_REGISTRATION_FAILURE, payLoad: error });
                handler(error, true);
            });
    };
};

export const getSingleCACRegistration = (id, onSuccess) => {
    return (dispatch) => {
        dispatch({ type: GET_SINGLE_CAC_REGISTRATION_START });
        Axios.get(`merchants/business-registration/cac/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_SINGLE_CAC_REGISTRATION_SUCCESS,
                    payLoad: res?.data?.data,
                });
                onSuccess(res?.data?.data);
            })
            .catch((error) => {
                dispatch({ type: GET_SINGLE_CAC_REGISTRATION_FAILURE, payLoad: error });
                handler(error, true);
            });
    };
};

export const markCACStatus = (params, payload ,onSuccess=()=> {}) => {
    return (dispatch) => {
        dispatch({ type: MARK_CAC_REGISTRATION_START });
        Axios.put(`merchants/business-registration/update/cac/${payload.id}`, {status: payload.status})
            .then((res) => {
                dispatch({
                    type: MARK_CAC_REGISTRATION_SUCCESS,
                    payLoad: res.data.data,
                });
                onSuccess();
                getAllCACRegistration();
                toastSuccess(res.data.message);
                window.location.reload();
            })
            .catch((error) => {
                dispatch({ type: MARK_CAC_REGISTRATION_FAILURE, payLoad: error });
                handler(error, true);
            });
    };
};

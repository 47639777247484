import React, { useState } from 'react'
import AccordionTitle from './AccordionTitle';
import MultiAccordion from './MultiAccordion';
import SingleAccordion from './SingleAccordion';
import { SCBox } from './styled'

const Accordion = ({ title, contents, multi }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => setIsOpen(!isOpen)

  return (
    <SCBox>
      <AccordionTitle title={title} />
      {multi
        ? <MultiAccordion contents={contents} />
        : (
          <SingleAccordion
            isOpen={isOpen}
            contents={contents}
            toggleAccordion={toggleAccordion}
          />
        )}
    </SCBox >
  );
};

export default Accordion;
import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastSuccess } from "../../helpers/utils.toast";
import {
	TOGGLE_LOADIDNG_STATE_OFF,
	TOGGLE_LOADIDNG_STATE_ON,
} from "../shared/types";
import {
  GET_WALLETS_FAILURE,
  GET_WALLETS_START,
  GET_WALLETS_SUCCESS,
  GET_WALLET_DETAILS_FAILURE,
  GET_WALLET_DETAILS_START,
  GET_WALLET_DETAILS_SUCCESS,
  UPDATE_WALLET_STATUS_FAILURE,
  UPDATE_WALLET_STATUS_START,
  UPDATE_WALLET_STATUS_SUCCESS,
  GET_ACCOUNT_DETAILS_START,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_FAILURE,
  GET_ACCOUNTS_START,
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_SUCCESS,
} from "./types";

export const getAllWallets = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_WALLETS_START });
    Axios.get(`/multicurrency-wallet/pending-wallet-approval`, params)
      .then((res) => {
        dispatch({
          type: GET_WALLETS_SUCCESS,
          payLoad: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_WALLETS_FAILURE,
          payLoad: error,
        });
        handler(error, true);
      });
  };
};

export const getWalletDetails = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_WALLET_DETAILS_START });
    Axios.get(`/multicurrency-wallet/wallet-currency-details/${id}`)
      .then((res) => {
        dispatch({
          type: GET_WALLET_DETAILS_SUCCESS,
          payLoad: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_WALLET_DETAILS_FAILURE,
          payLoad: error,
        });
        handler(error);
      });
  };
};

export const updateWalletRequestStatus = (params, onClose = () => { }) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_WALLET_STATUS_START });
    Axios.put(`/multicurrency-wallet/${params?.type}-wallet-currency/${params?.id}`)
      .then((res) => {
        dispatch({
          type: UPDATE_WALLET_STATUS_SUCCESS,
          payLoad: res?.data?.data,
        });
        toastSuccess(res.data.message);
        onClose();
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_WALLET_STATUS_FAILURE,
          payLoad: error,
        });
        handler(error, true);
      });
  };
};


export const getAllAccountRequests = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_ACCOUNTS_START });
    Axios.get(`/currency/if/waitlist`, params)
      .then((res) => {
        dispatch({
          type: GET_ACCOUNTS_SUCCESS,
          payLoad: res?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ACCOUNTS_FAILURE,
          payLoad: error,
        });
        handler(error, true);
      });
  };
};

export const getAllAccountRequestsForDownload = (params, callback) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });
    Axios.get(`/currency/if/waitlist`, params)
      .then((res) => {
        callback(res.data.data.data);
        dispatch({
          type: TOGGLE_LOADIDNG_STATE_OFF,
          payLoad: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TOGGLE_LOADIDNG_STATE_OFF,
        });
        handler(error, true);
      });
  };
};


export const getAccountRequestDetails = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_ACCOUNT_DETAILS_START });
    Axios.get(`/currency/if/waitlist/${id}`)
      .then((res) => {
        dispatch({
          type: GET_ACCOUNT_DETAILS_SUCCESS,
          payLoad: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ACCOUNT_DETAILS_FAILURE,
          payLoad: error,
        });
        handler(error);
      });
  };
};


/**
 * Get Countries
 * 
 * @returns {void}
*/
export const getCountries = (callBack = () => { }) => {
  return (dispatch) => {
    Axios.get(`foreign-countries/index`)
      .then((res) => {
        callBack(res.data);
      })
      .catch((error) => {
        handler(error, true);
      });
  }
}
import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import isEqual from "lodash/isEqual";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import TabNav from "../../components/navbars/TabNav";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import useSettlements from "../../hooks/useSettlements";
import {
	getAllUnsettledNGNSettlements,
	getAllUnsettledNGNSettlementsForDownload,
	processMultipleUnsettledNGNSettlements,
} from "../../redux/settlements/settlementsActions";
import DownloadAsCSVButton from "../../helpers/DownloadAsCSV";
import { fixDateForAllBrowsers } from "../../helpers/helper";

export default function NGNUnsettledSettlements() {
	const dispatch = useDispatch();
	let history = useHistory();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const [showSettleModal, setShowSettleModal] = useState(false);
	const [selectedSettlements, setSelectedSettlements] = useState([]);
	const [showCannotSettleModal, setShowCannotSettleModal] = useState(false);
	const [canSettle, setCanSettle] = useState(false);
	const [cannotSettleCount] = useState(0);
	const [totalSettlement, setTotalSettlement] = useState(0);

	const {
		isLoadingAllUnsettledNGNSettlements,
		allUnsettledNGNSettlements,
		isProcessingUnsettledSettlement,
	} = useSettlements();

	const defaultFilterParams = {
		search: "",
		page: currentPage,
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, search, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_from, date_to, search]);

	React.useMemo(() => {
		dispatch(
			getAllUnsettledNGNSettlements({
				params: {
					...(search
						? { search, paginate: 1, current_page: page, per_page: 10 }
						: {
								date_from,
								date_to,
								paginate: 1,
								current_page: page,
								per_page: 10,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		page,
		// per_page,
		search,
	]);

	React.useMemo(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	//  Process Settlements
	const onProcessSettlements = () => {
		dispatch(processMultipleUnsettledNGNSettlements(selectedSettlements));
	};

	// Update selected draft notifications
	const updateSelectedSettlements = (settlement) => {
		if (checkIfSettlementIsSelected(settlement)) {
			const newList = selectedSettlements.filter(
				(_settlement) => !isEqual(_settlement, settlement)
			);
			setSelectedSettlements(newList);
		} else {
			const oldList = [...selectedSettlements];
			oldList.push(settlement);
			setSelectedSettlements(oldList);
		}
	};

	const checkIfAllSettlementsAreEligible = () => {
		let total = 0;
		for (let index = 0; index < selectedSettlements.length; index++) {
			const settlement = selectedSettlements[index];

			if (settlement?.amount_to_pay) {
				total += Number(settlement?.amount_to_pay);
			}

			// disabling check for settlement

			// if (
			// 	dfn.isAfter(
			// 		new Date(),
			// 		dfn.addDays(new Date(settlement?.txn_date), 670)
			// 	)
			// ) {
			// 	setCanSettle(true);
			// } else {
			// 	setCanSettle(false);
			// 	setCannotSettleCount(cannotSettleCount + 1);
			// }
		}
		setCanSettle(true);
		setTotalSettlement(total);
	};

	useEffect(() => {
		if (selectedSettlements?.length > 0) {
			checkIfAllSettlementsAreEligible();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSettlements]);

	const checkIfSettlementIsSelected = (settlement) => {
		const settlementInArray = selectedSettlements.find((_settlement) =>
			isEqual(_settlement, settlement)
		);
		return !!settlementInArray;
	};

	const onClickOpen = (settlement) => {
		localStorage.setItem(
			"glade_selected_settlement",
			JSON.stringify({
				owner_uuid: settlement.owner_uuid,
				currency: settlement.currency,
				settlement_type: settlement.settlement_type,
				settlement_date: settlement.settlement_date,
				txn_date: settlement.txn_date,
			})
		);
		history.push({
			pathname: "/dashboard/settlements/unsettled/detail",
			state: {
				owner_uuid: settlement.owner_uuid,
				currency: settlement.currency,
				settlement_type: settlement.settlement_type,
				settlement_date: settlement.settlement_date,
				txn_date: settlement.txn_date,
			},
		});
	};

	const columns = [
		{
			accessor: "settlement_type",
			Header: "",
			Cell: ({ value, row }) => (
				<span className="glade-normal-text-two capitalize">
					<input
						type="checkbox"
						checked={checkIfSettlementIsSelected({
							owner_uuid: row.original.owner_uuid,
							currency: row.original.currency,
							settlement_type: row.original.settlement_type,
							settlement_date: row.original.settlement_date,
							amount_to_pay: row.original.amount_to_pay,
							txn_date: row.original.txn_date,
						})}
						onChange={() =>
							updateSelectedSettlements({
								owner_uuid: row.original.owner_uuid,
								currency: row.original.currency,
								settlement_type: row.original.settlement_type,
								settlement_date: row.original.settlement_date,
								amount_to_pay: row.original.amount_to_pay,
								txn_date: row.original.txn_date,
							})
						}
					/>
				</span>
			),
		},
		{
			accessor: "account_name",
			Header: "Account Name",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two capitalize cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{value}
				</span>
			),
		},
		{
			accessor: "currency",
			Header: "Currency",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two capitalize cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{value}
				</span>
			),
		},
		{
			accessor: "no_of_txns",
			Header: "Number of Txns",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two capitalize cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{value}
				</span>
			),
		},
		{
			accessor: "total_amount",
			Header: "Total Amount",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			),
		},
		{
			accessor: "fees",
			Header: "Fees",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			),
		},
		{
			accessor: "amount_to_pay",
			Header: "Amount to pay",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			),
		},
		{
			accessor: "settlement_date",
			Header: "Settlement Date",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{dfn.format(
						new Date(fixDateForAllBrowsers(value)),
						"MMM d, yyyy hh:mm:ss a"
					)}
				</span>
			),
		},
		{
			accessor: "txn_date",
			Header: "Transaction Date",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{dfn.format(
						new Date(fixDateForAllBrowsers(value)),
						"MMM d, yyyy hh:mm:ss a"
					)}
				</span>
			),
		},
	];

	return (
		<div className="p-3">
			<TabNav
				tabs={[
					{ title: "Settled", path: "/dashboard/settlements/ngn" },
					{ title: "Unsettled", path: "/dashboard/settlements/ngn/unsettled" },
				]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				filterFields={["date"]}
				filterCallback={handleChange}
				disabled={isLoadingAllUnsettledNGNSettlements}
				title={`Settlements ${
					!isLoadingAllUnsettledNGNSettlements &&
					allUnsettledNGNSettlements?.total
						? "- " + allUnsettledNGNSettlements?.total
						: ""
				}`}
				actionButton={
					<div className="flex items-center">
						<Button.Dark
							title={"Settle Transactions"}
							disabled={selectedSettlements?.length === 0}
							onClick={() =>
								canSettle
									? setShowSettleModal(true)
									: setShowCannotSettleModal(true)
							}
						/>
						<div>
							<DownloadAsCSVButton
								fileName="Unsettled Settlements"
								CSVCallback={(handleDownload) =>
									dispatch(
										getAllUnsettledNGNSettlementsForDownload(
											{
												params: {
													...(search
														? {
																search,
																paginate: 0,
																current_page: page,
																per_page: 10,
														  }
														: {
																date_from,
																date_to,
																paginate: 0,
																current_page: page,
																per_page: 10,
														  }),
												},
											},
											(data) =>
												handleDownload({
													data,
													fileName: "NGN Unsettled Settlements",
													format: (value, i) => ({
														"S/N": `${i + 1}`,
														"Account Name": value?.account_name,
														Currency: value?.currency,
														"Number of Txns": value?.no_of_txns,
														"Total Amount": value?.total_amount,
														Fees: value?.fees,
														"Amount to pay": value?.amount_to_pay,
														"Settlement Date": value?.settlement_date,
														"Transaction Date": value?.txn_date,
													}),
												})
										)
									)
								}
								disabled={isLoadingAllUnsettledNGNSettlements}
								className="whitespace-pre ml-4"
							/>
						</div>
					</div>
				}
				searchBarPlaceholder={"Account Name"}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof allUnsettledNGNSettlements?.data === "object" &&
					!Array.isArray(allUnsettledNGNSettlements?.data)
						? allUnsettledNGNSettlements?.data?.data
						: allUnsettledNGNSettlements?.data
				}
				empty_message="No settlement"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllUnsettledNGNSettlements}
				setCurrentPage={setCurrentPage}
				totalPages={allUnsettledNGNSettlements?.total}
			/>

			{/* Process modal */}
			<BasicModal
				visibility={showSettleModal}
				onClose={() => setShowSettleModal(false)}
				onOpen={() => setShowSettleModal(true)}
				primaryButton=""
				modalTitle="Payout Confirmation"
				secondaryButton=""
				modelContentSection={
					<>
						<div className="py-4 px-0">
							<h4 className="text-base font-inter glade-black mb-6 text-center">
								Are you sure you want to settle{" "}
								<p className="font-bold text-lg">
									{selectedSettlements?.length} Settlement(s)
								</p>
							</h4>
							<h4 className="text-base font-inter glade-black mb-2 text-center">
								with
								<p className="font-bold text-lg">
									NGN {Number(totalSettlement).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
								</p>
							</h4>
							<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
								<div>
									<Button.Gray
										type="button"
										title="Cancel"
										onClick={() => setShowSettleModal(false)}
										className="glade-normal-text-two glade-black text-center block flex-grow-0"
									/>
								</div>
								<div>
									<Button.Dark
										type="button"
										title="Payout"
										className="block flex-grow-0"
										onClick={onProcessSettlements}
										loading={isProcessingUnsettledSettlement}
									/>
								</div>
							</div>
						</div>
					</>
				}
			/>

			{/* Can't payout modal */}
			<BasicModal
				visibility={showCannotSettleModal}
				onClose={() => setShowCannotSettleModal(false)}
				onOpen={() => setShowCannotSettleModal(true)}
				primaryButton=""
				modalTitle="Payout Warning"
				secondaryButton=""
				modalStyle={{
					width: "357px",
				}}
				modelContentSection={
					<div
						className="p-2.5"
						style={{
							background: "#F9C9001F",
							border: "1px solid #F9C900",
						}}
					>
						<h3
							className="text-sm font-bold font-inter text-center mb-2.5"
							style={{
								color: "#F9C900",
							}}
						>
							Not up to a week
						</h3>
						<p className="glade-black text-sm font-inter text-center">
							Sorry, you can’t settle {cannotSettleCount} transaction(s) as
							naira transactions can only be settled after 7 days.
						</p>
					</div>
				}
			/>
		</div>
	);
}

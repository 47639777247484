import {
  GET_ACTIVE_USERS_REPORT_FAILURE,
  GET_ACTIVE_USERS_REPORT_START,
  GET_ACTIVE_USERS_REPORT_SUCCESS,
  GET_MOST_USED_SERVICES_FAILURE,
  GET_MOST_USED_SERVICES_START,
  GET_MOST_USED_SERVICES_SUCCESS,
  GET_TRANSACTION_STATUS_FAILURE,
  GET_TRANSACTION_STATUS_START,
  GET_TRANSACTION_STATUS_SUCCESS,
  GET_TRANSACTION_SUMMARY_FAILURE,
  GET_TRANSACTION_SUMMARY_START,
  GET_TRANSACTION_SUMMARY_SUCCESS,
} from "./types";

const initialState = {
  isLoadingTransactionSummary: true,
  isLoadingTransactionStatus: true,
  isLoadingActiveUsersReport: true,
  isLoadingMostUsedServices: true,
  transactionSummary: {},
  transactionStatus: {},
  activeUsersReport: {},
  mostUsedServices: {},
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTION_SUMMARY_START:
      return {
        ...state,
        isLoadingTransactionSummary: true,
      };

    case GET_TRANSACTION_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoadingTransactionSummary: false,
        transactionSummary: action.payLoad,
      };

    case GET_TRANSACTION_SUMMARY_FAILURE:
      return {
        ...state,
        isLoadingTransactionSummary: false,
      };

    case GET_TRANSACTION_STATUS_START:
      return {
        ...state,
        isLoadingTransactionStatus: true,
      };

    case GET_TRANSACTION_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingTransactionStatus: false,
        transactionStatus: action.payLoad,
      };

    case GET_TRANSACTION_STATUS_FAILURE:
      return {
        ...state,
        isLoadingTransactionStatus: false,
      };

    case GET_ACTIVE_USERS_REPORT_START:
      return {
        ...state,
        isLoadingActiveUsersReport: true,
      };

    case GET_ACTIVE_USERS_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingActiveUsersReport: false,
        activeUsersReport: action.payLoad,
      };

    case GET_ACTIVE_USERS_REPORT_FAILURE:
      return {
        ...state,
        isLoadingActiveUsersReport: false,
      };

    case GET_MOST_USED_SERVICES_START:
      return {
        ...state,
        isLoadingMostUsedServices: true,
      };

    case GET_MOST_USED_SERVICES_SUCCESS:
      return {
        ...state,
        isLoadingMostUsedServices: false,
        mostUsedServices: action.payLoad,
      };

    case GET_MOST_USED_SERVICES_FAILURE:
      return {
        ...state,
        isLoadingMostUsedServices: false,
      };

    default:
      return state;
  }
}

import React, { useState } from "react";
import * as dfn from "date-fns";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { TextSkeleton } from "../../components/skeletons";

import usePos from "../../hooks/usePos";
import {
  approvePosRequest,
  declinePosRequest,
  getSpecificPosRequest,
} from "../../redux/pos/posActions";
import BasicModal from "../../components/modals/BasicModal";
import Button from "../../components/buttons/Button";
import { FormError } from "../../validation";
import { useForm } from "react-hook-form";
import { fixDateForAllBrowsers } from "../../helpers/helper";

export default function PosRequestDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);

  const {
    isLoadingSpecificPosRequest: loading,
    specificPosRequest: details,
    isDecliningPosRequest,
    isApprovingPosRequest,
  } = usePos();

  React.useMemo(() => {
    dispatch(getSpecificPosRequest(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onDeclineTerminal = (data) => {
    dispatch(declinePosRequest(id, { comments: data.reason }));
  };

  const onApproveTerminal = () => {
    dispatch(approvePosRequest(id));
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  return (
    <div className="p-3 flex flex-col">
      <div className="mb-6">
        <BreadCrumbs title={"POS"} goBack />
      </div>

      <div className="flex flex-col bg-white rounded-sm p-8 pt-5 mt-4 mb-8">
        <div className="py-3 border-b-2 border-solid border-gray-100">
          <p className="glade-black glade-normal-text-two">Account Details</p>
        </div>
        <div className="grid grid-cols-4 gap-4 py-3">
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Account Name</h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.account_name}
              </h6>
            )}
          </div>
          {details?.account_email && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Account Email</h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1">
                  {details?.account_email}
                </h6>
              )}
            </div>
          )}
          {details?.account_phone && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Phone Number</h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1">
                  {details?.account_phone}
                </h6>
              )}
            </div>
          )}
          {details?.account_type && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Account Type</h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1">
                  {details?.account_type}
                </h6>
              )}
            </div>
          )}
          {details?.account_balance && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Account Balance
              </h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1">
                  {details?.currency ?? "NGN"}{" "}
                  {Number(details?.account_balance ?? 0)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
                </h6>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center justify-end">
        <Button.Green
          onClick={() => setShowApproveModal(true)}
          disabled={isApprovingPosRequest || loading}
          title="Approve"
          className="whitespace-nowrap"
        />
        <div className="ml-4">
          <Button.Danger
            onClick={() => setShowDeclineModal(true)}
            disabled={isDecliningPosRequest || loading}
            title="Decline"
          />
        </div>
      </div>

      <div className="flex flex-col bg-white rounded-sm p-8 pt-5 mt-4 mb-8">
        <div className="py-3 border-b-2 border-solid border-gray-100">
          <p className="glade-black glade-normal-text-two">Request Details</p>
        </div>
        <div className="grid grid-cols-4 gap-4 py-3">
          {details?.revenue && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Monthly Revenue
              </h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1">
                  {details?.revenue}
                </h6>
              )}
            </div>
          )}
          {details?.sales && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Number of Daily Sales
              </h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1">
                  {details?.sales}
                </h6>
              )}
            </div>
          )}
          {details?.number && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Number of Terminals
              </h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1">
                  {details?.number}
                </h6>
              )}
            </div>
          )}

          {details?.created_at && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Date Requested
              </h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                  {dfn.format(new Date(fixDateForAllBrowsers(details?.created_at)), "MMM d, yyyy hh:mm:ss a")}
                </h6>
              )}
            </div>
          )}
          {details?.address && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Address</h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1">
                  {details?.address}
                </h6>
              )}
            </div>
          )}
          {details?.city && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">LGA</h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1">
                  {details?.city}
                </h6>
              )}
            </div>
          )}
          {details?.state && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">State</h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1">
                  {details?.state}
                </h6>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Approve modal */}
      <BasicModal
        visibility={showApproveModal}
        onClose={() => setShowApproveModal(false)}
        onOpen={() => setShowApproveModal(true)}
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        modelContentSection={
          <div className="p-6 px-0">
            <h3 className="glade-black text-center mb-8">
              Are you sure you want to approve this POS request?
            </h3>
            <Button.Dark
              onClick={onApproveTerminal}
              loading={isApprovingPosRequest}
              title="Approve"
            >
              {isApprovingPosRequest ? "Approving" : "Approve"}
            </Button.Dark>
            <button
              onClick={() => setShowApproveModal(false)}
              className="glade-normal-text-three glade-grey-dark w-full mx-auto text-center mt-6"
            >
              Cancel
            </button>
          </div>
        }
      />

      {/* Decline modal */}
      <BasicModal
        visibility={showDeclineModal}
        onClose={() => setShowDeclineModal(false)}
        onOpen={() => setShowDeclineModal(true)}
        primaryButton=""
        modalTitle="Decline POS Request"
        secondaryButton=""
        modelContentSection={
          <form onSubmit={handleSubmit(onDeclineTerminal)} className="pt-4">
            <div className="w-full mt-2 mb-3">
              <label htmlFor="reason" className="block glade-small-text-two">
                Reason
              </label>
              <textarea
                id="reason"
                name="reason"
                {...register("reason", {
                  required: "Enter a reason",
                })}
                placeholder="Enter a reason"
                className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
                rows={4}
              />

              <FormError errors={errors} name="reason" />
            </div>

            <div className="flex items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
              <button
                type="button"
                onClick={() => setShowDeclineModal(false)}
                className="glade-normal-text-two glade-black text-center"
              >
                Cancel
              </button>
              <div>
                <Button.Danger
                  type="submit"
                  title="Decline"
                  className="block flex-grow-0"
                  loading={isDecliningPosRequest}
                />
              </div>
            </div>
          </form>
        }
      />
    </div>
  );
}

export const GET_WALLETS_START = "GET_WALLETS_START";
export const GET_WALLETS_SUCCESS = "GET_WALLETS_SUCCESS";
export const GET_WALLETS_FAILURE = "GET_WALLETS_FAILURE";

export const GET_WALLET_DETAILS_START = "GET_WALLETS_START";
export const GET_WALLET_DETAILS_SUCCESS = "GET_WALLET_DETAILS_SUCCESS";
export const GET_WALLET_DETAILS_FAILURE = "GET_WALLET_DETAILS_FAILURE";

export const UPDATE_WALLET_STATUS_START = "UPDATE_WALLET_STATUS_START";
export const UPDATE_WALLET_STATUS_SUCCESS = "UPDATE_WALLET_STATUS_SUCCESS";
export const UPDATE_WALLET_STATUS_FAILURE = "UPDATE_WALLET_STATUS_FAILURE";


export const GET_ACCOUNT_DETAILS_START = "GET_ACCOUNT_DETAILS_START";
export const GET_ACCOUNT_DETAILS_SUCCESS = "GET_ACCOUNT_DETAILS_SUCCESS";
export const GET_ACCOUNT_DETAILS_FAILURE = "GET_ACCOUNT_DETAILS_FAILURE";

export const GET_ACCOUNTS_START = "GET_ACCOUNTS_START";
export const GET_ACCOUNTS_FAILURE = "GET_ACCOUNTS_FAILURE";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";

import React from "react";
import SideBarOpenButton from "./SideBarOpenButton";
import SideBarCloseButton from "./SideBarCloseButton";
import SidebarLink from "./SidebarLink";
import SidebarCard from "./SidebarCard";
import GladeLogo from "../logos/GladeLogo";
import { RiFileTextLine, RiRefund2Line, RiSuitcaseLine } from "react-icons/ri";
import { FiUsers, FiHome, FiBell, FiLogOut } from "react-icons/fi";
import {
	Pos,
	Compliance,
	Inflow,
	Outflow,
	Fees,
	Providers,
	AuditTrail,
	Refresh,
	AccountCheck,
	Incorporation,
	PayrollAdvance
} from "../../assets/icons/Icons";
import SidebarCollapsibleGroup from "./SideBarCollapsibleGroup";
import { CgFolderRemove } from "react-icons/cg";
export default function Sidebar() {
	const [collapseShow, setCollapseShow] = React.useState("hidden");

	return (
		<>
			<nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-52 z-10 py-4 px-4 glade-bg-black">
				<div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
					{/* Toggler */}
					<SideBarOpenButton
						onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
					/>

					{/* User */}
					<ul className="md:hidden items-center flex flex-wrap list-none">
						<li className="inline-block relative">
							<div className="items-center flex">
								<span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
									<img
										alt="..."
										className="w-full rounded-full align-middle border-none shadow-lg"
										src={require("../../assets/images/team-1.jpg").default}
									/>
								</span>
							</div>
						</li>
					</ul>

					{/* Collapse */}
					<div
						className={
							"md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded glade-bg-blue-dark" +
							collapseShow
						}
					>
						{/* Collapse header */}
						<div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
							<div className="flex flex-wrap">
								<div className="w-6/12 flex justify-start">
									<GladeLogo />
								</div>
								<div className="w-6/12 flex justify-end">
									<SideBarCloseButton
										onClick={() => setCollapseShow("hidden")}
									/>
								</div>
							</div>
						</div>

						{/* Side bar */}
						<SidebarCard />

						<div className="flex flex-col justify-between flex-grow px-5">
							<div className="">
								<SidebarLink
									name={"Home"}
									path={"/dashboard/home"}
									icon={<FiHome />}
									listStyle={{ listStyle: "none" }}
								/>
								<SidebarLink
									name={"Notifications"}
									path={"/dashboard/notifications"}
									icon={<FiBell />}
								/>
								<SidebarLink
									name={"Compliance"}
									path={"/dashboard/compliance"}
									icon={<Compliance />}
								/>
								<SidebarLink
									name={"Accounts"}
									path={"/dashboard/accounts"}
									icon={<FiUsers />}
								/>
								 <SidebarLink
                  name={"Account Request"}
                  path={"/dashboard/accountrequests/wallets"}
                  icon={<AccountCheck title={"Account Request"} />}
                />
								<SidebarLink
									name={"All Transactions"}
									path={"/dashboard/all-transactions"}
									icon={<RiFileTextLine />}
								/>
								<SidebarLink
									name={"Outflow"}
									path={"/dashboard/outflows"}
									icon={<Outflow color={"#FFFFFF"} />}
								/>
								<SidebarLink
									name={"Incorporation"}
									path={"/dashboard/cac/registrations"}
									icon={<Incorporation color={"#FFFFFF"} />}
								/>
								<SidebarLink
									name={"Inflow"}
									path={"/dashboard/inflow"}
									icon={<Inflow color={"#FFFFFF"} />}
								/>
								<SidebarLink
									name={"Audit Logs"}
									path={"/dashboard/audit-logs"}
									icon={<AuditTrail color={"#FFFFFF"} />}
								/>
								{/* <SidebarLink
                name={"Loans & Overdrafts"}
                path={"/dashboard/loans-and-overdrafts"}
                icon={<BsBank2 />}
              /> */}
								{/* <SidebarLink
                name={"Chargebacks"}
                path={"/dashboard/charge-backs"}
                icon={<RiFlagLine />}
              /> */}
								<SidebarLink
									name={"POS"}
									path={"/dashboard/pos"}
									icon={<Pos color={"#FFFFFF"} />}
								/>
								<SidebarCollapsibleGroup
									title={"Settlements"}
									path={"/dashboard/settlements"}
									icon={<RiRefund2Line />}
								>
									<SidebarLink
										name={"Naira"}
										path={"/dashboard/settlements/ngn"}
										icon={null}
										linkStyle={{
											marginBottom: "5px",
										}}
									/>
									<SidebarLink
										name={"USD"}
										path={"/dashboard/settlements/usd"}
										icon={null}
									/>
								</SidebarCollapsibleGroup>
								{/* <SidebarLink
                name={"Store"}
                path={"/dashboard/store"}
                icon={<BiStore size={16} />}
              /> */}
								{/* <SidebarLink
                name={"Administrators"}
                path={"/dashboard/administrators"}
                icon={<FiUser />}
              /> */}
								{/* <SidebarLink
                name={"Account Statement"}
                path={"/dashboard/statement"}
                icon={<RiFileTextLine />}
              /> */}
								{/* <SidebarLink
                name={"Audit Log"}
                path={"/dashboard/audit-log"}
                icon={<Timeline />}
              /> */}
								<SidebarLink
									name={"Fees"}
									path={"/dashboard/fees"}
									icon={<Fees />}
								/>
								<SidebarCollapsibleGroup
									title={"Cross-border"}
									path={"/dashboard/cross-border"}
									icon={<Fees />}
								>
									<SidebarLink
										name={"Rates"}
										path={"/dashboard/cross-border/rates?source=ngn"}
										icon={null}
										linkStyle={{
											marginBottom: "5px",
										}}
									/>
									<SidebarLink
										name={"Charges"}
										path={"/dashboard/cross-border/charges?method=bank"}
										icon={null}
									/>
									<SidebarLink
										name={"Multi-currency"}
										path={"/dashboard/multi-currency"}
										icon={null}
									/>
									<SidebarLink
										name={"Currency"}
										path={"/dashboard/currencies?continent=africa"}
										icon={null}
									/>
								</SidebarCollapsibleGroup>
								<SidebarLink
									name={"Providers"}
									path={"/dashboard/providers/transfer/categories"}
									icon={<Providers color={"#FFFFFF"} />}
								/>

								<SidebarLink
									name={"Referrals"}
									path={"/dashboard/referrals"}
									icon={<Refresh color={"#FFFFFF"} />}
								/>

									<SidebarLink
                name={"Payroll Advance Request"}
                path={"/dashboard/payroll/loan/requests"}
                icon={<PayrollAdvance />}
              />

								{/* <SidebarLink
                name={"Providers"}
                path={"/dashboard/providers"}
                icon={<Providers />}
              /> */}
								{/* <SidebarLink
                name={"Payments"}
                path={"/dashboard/payments"}
                icon={<PayBills color={"#FFFFFF"} />}
              /> */}
								{/* <SidebarLink
                name={"Transfer Discounts"}
                path={"/dashboard/transfer-discounts"}
                icon={<Discount />}
              /> */}
							</div>
							<div className="mb-3">
								<SidebarLink
									name={"Logout"}
									path={"/auth/logout"}
									icon={<FiLogOut />}
									color="#FFFFFF"
									listClassName="mt-10"
								// listStyle={{ listStyle: "none" }}
								/>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
}
import React, { useMemo, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import TabNav from "../../components/navbars/TabNav";
import useAccounts from "../../hooks/useAccounts";
import { getAllLimitRequests } from "../../redux/accounts/accountsActions";
import { fixDateForAllBrowsers } from "../../helpers/helper";
import {
  MedalIconBronze,
  MedalIconSilver,
  MedalIconGold,
} from "../../assets/icons/Icons";
export default function LimitRequests() {
  const dispatch = useDispatch();
  const location = useLocation();

  // const pageQuery = qs.parse(location.search)?.page

  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page || 1
  );


  const { isLoadingAllLimitRequests, allLimitRequests } = useAccounts();

  const defaultFilterParams = {
    search: "",
    current_page: currentPage,
    type: "",
    // date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 150), "yyyy-MM-dd"),
    // date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
  };

  const DisplayMedal = ({ tierName }) => {
    let index = tierName?.split("Tier ")[1];
    if (parseInt(index, 0) < 4) {
      return <MedalIconBronze />;
    } else if (parseInt(index, 0) === 4) {
      return <MedalIconSilver />;
    } else if (parseInt(index, 0) > 4) {
      return <MedalIconGold />;
    } else {
      return <MedalIconGold />;
    }
  };

  const filterInstance = usePaginationFilterParams({
    initialFilters: defaultFilterParams,
  });

  const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

  const { date_from, date_to, search, type, current_page } = filters;

  const [filterValue, setFilterValue] = React.useState(filters);

  useMemo(() => {
    // setCurrentPage(1);
    setFilterValue(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_from, date_to, search, type]);

  React.useMemo(() => {
    dispatch(
      getAllLimitRequests({
        params: {
          ...(search
            ? {
                search,
                date_from,
                date_to,
                type,
                paginate: 1,
                current_page,
                per_page: 10,
              }
            : {
                date_from,
                date_to,
                type,
                paginate: 1,
                current_page,
                per_page: 10,
              }),
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // currency,
    date_from,
    date_to,
    type,
    current_page,
    search,
  ]);

  React.useMemo(() => {
    handleChange({ ...filterValue, current_page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // // Clear query param on render
  // useEffect(() => {
  //   urlSearchParamsHistory.replace({});
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const resetReferenceSearch = (event) => {
    if (typeof event?.preventDefault === "function") {
      event?.preventDefault();
    }
    urlSearchParamsHistory.replace(defaultFilterParams);
  };

  const columns = [
    // {
    // 	accessor: "compliance_status",
    // 	Header: "Compliance Status",
    // 	Cell: ({ value, row }) => (
    // 		<Link
    // 			to={`/dashboard/accounts/limit/detail/${row.original.owner_uuid}`}
    // 		>
    // 			<PillIndicator
    // 				className="px-5 py-1 capitalize"
    // 				type={statusEnum[value]}
    // 				title={value}
    // 			/>
    // 		</Link>
    // 	),
    // },
    {
      accessor: "account_name",
      Header: "Name",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/limit/detail/${row.original.owner_uuid}/${row.original.request_id}`}
        >
          <span className="glade-normal-text-two capitalize">
            {value} {row.original.othernames} {row.original.lastname}
          </span>
        </Link>
      ),
    },
    {
      accessor: "account_email",
      Header: "Email",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/limit/detail/${row.original.owner_uuid}/${row.original.request_id}`}
        >
          <span className="glade-normal-text-two">{value}</span>
        </Link>
      ),
    },
    {
      accessor: "owner_type",
      Header: "Account Type",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/limit/detail/${row.original.owner_uuid}/${row.original.request_id}`}
        >
          <span className="glade-normal-text-two capitalize">{value}</span>
        </Link>
      ),
    },
    {
      accessor: "current_tier",
      Header: "Current Tier",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/limit/detail/${row.original.owner_uuid}/${row.original.request_id}`}
        >
          <div className="flex">
            <DisplayMedal tierName={value} />
            <span className="ml-1">{value}</span>
          </div>
        </Link>
      ),
    },
    {
      accessor: "tier_requested",
      Header: "Tier Requested",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/limit/detail/${row.original.owner_uuid}/${row.original.request_id}`}
        >
          <div className="flex">
            <DisplayMedal tierName={value} />
            <span className="ml-1">{value}</span>
          </div>
        </Link>
      ),
    },
    {
      accessor: "updated_at",
      Header: "Date",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/accounts/limit/detail/${row.original.owner_uuid}/${row.original.request_id}`}
        >
          <span className="glade-normal-text-two">
            {dfn.format(
              new Date(fixDateForAllBrowsers(value)),
              "MMM d, yyyy hh:mm:ss a"
            )}
          </span>
        </Link>
      ),
    },
  ];

  return (
    <div className="p-3">
      <TabNav
        tabs={[
          { title: "Personal", path: "/dashboard/accounts" },
          { title: "Business", path: "/dashboard/accounts/business" },
          { title: "Agent", path: "/dashboard/accounts/agents" },
          {
            title: "Tier Requests",
            path: "/dashboard/accounts/limit",
            tierNo:
              !isLoadingAllLimitRequests && allLimitRequests?.total
                ? allLimitRequests?.total
                : "",
          },
          // { title: "Blacklist", path: "/dashboard/accounts/blacklist" },
        ]}
      />
      <CardTableHeader
        onChange={(e) => {
          const value = e.target.value;
          if (value) {
            handleChange({ search: value.trim() });
          } else {
            resetReferenceSearch();
          }
        }}
        defaultFilterParams={defaultFilterParams}
        filterFields={["accountType", "date"]}
        filterCallback={handleChange}
        disabled={isLoadingAllLimitRequests}
        title={`Tier Requests ${
          !isLoadingAllLimitRequests && allLimitRequests?.total
            ? "- " + allLimitRequests?.total
            : ""
        }`}
        searchBarPlaceholder={"Account Name or Email"}
      />
      <PaginatedTable
        columns={columns}
        data={
          typeof allLimitRequests === "object" &&
          !Array.isArray(allLimitRequests)
            ? allLimitRequests?.data
            : allLimitRequests
        }
        empty_message="No limit requests"
        empty_sub_message=""
        current_page={currentPage}
        loading={isLoadingAllLimitRequests}
        setCurrentPage={setCurrentPage}
        totalPages={allLimitRequests?.total}
        // for styling component to suit the new design
      />
    </div>
  );
}

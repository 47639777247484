import React from "react";
import ButtonWithIconRight from "../../components/buttons/ButtonWithIconRight";
import ThreeDotButton from "../../components/buttons/ThreeDotButton";
import Filter from "../../components/filter/Filter";
import SearchInput from "../../components/inputs/SearchInput";
import DownloadDropdown from "../../components/dropdowns/DownloadDropdown";
import DownloadAsCSVButton from "../../helpers/DownloadAsCSV";
// import DownloadAsPDFButton from "../../helpers/DownloadAsPDF";

export function CardTableHeader({
	title,
	searchBarPlaceholder,
	onChange,
	filterButton,
	filterFields,
	filterCallback,
	downloadAs,
	pdfTitle,
	details,
	pdfHeaders,
	pdfData,
	fileName,
	disabled,
	getWorksheets,
	noSearch,
	actionButton,
	downloadAsCSV,
	customCategories,
	downloadAsCSVDark,
	customSearchInput,
	resetReferenceSearch,
	clearSearchInput,
	defaultFilterParams,
	CSVCallback,
	// downloadAsPDF,
}) {
	return (
		<div className="py-5 px-10 bg-white rounded-t-md shadow-lg">
			<div className="flex flex-wrap justify-between mb-6">
				<span className="glade-heading-four capitalize glade-black">
					{title ?? "Title"}
				</span>
				<span className="block sm:hidden">
					<ThreeDotButton />
				</span>
			</div>
			<div className="hidden sm:flex flex-wrap justify-between">
				<div className="flex items-center gap-9">
					{filterButton ?? (
						<Filter
							className="cursor-pointer"
							items={filterFields}
							filterCallback={filterCallback}
							customCategories={customCategories}
						/>
					)}
					{!noSearch && (
						<SearchInput
							onChange={onChange}
							placeholder={searchBarPlaceholder}
							defaultFilterParams={defaultFilterParams}
							clearSearchInput={clearSearchInput}
						/>
					)}
					{customSearchInput ?? null}
				</div>
				<div className="flex-initial">
					{downloadAs && (
						<DownloadDropdown
							pdfTitle={pdfTitle}
							pdfData={pdfData}
							pdfHeaders={pdfHeaders}
							details={details}
							fileName={fileName}
							disabled={disabled}
							getWorksheets={getWorksheets}
							downloadAs={downloadAs}
						/>
					)}
					{downloadAsCSV && (
						<DownloadAsCSVButton
							fileName={fileName}
							getWorksheets={getWorksheets}
							disabled={disabled}
							CSVCallback={CSVCallback}
						/>
					)}
					{downloadAsCSVDark && (
						<DownloadAsCSVButton
							fileName={fileName}
							getWorksheets={getWorksheets}
							disabled={disabled}
							buttonVariant="dark"
							CSVCallback={CSVCallback}
						/>
					)}
					{/* {downloadAsPDF && (
            <DownloadAsPDFButton
              fileName={fileName}
              details={details}
              pdfTitle="Glade"
              pdfHeaders={pdfHeaders}
              pdfData={pdfData}
              disabled={disabled}
            />
          )} */}
					{actionButton && actionButton}
				</div>
			</div>
		</div>
	);
}

export function SettingsTableHeaderPlain({ title, rightButton, noShadow }) {
	return (
		<div className={`w-full inline-flex justify-between items-center border border-3 p-6 bg-white rounded-t-md ${!noShadow && "shadow-lg"}`}>
			<div className="flex flex-wrap justify-between">
				<span className="glade-heading-four glade-black">
					{title ?? "Title"}
				</span>
				<span className="block sm:hidden">
					<ThreeDotButton />
				</span>
			</div>
			<div>{rightButton ?? null}</div>
		</div>
	);
};

export function CardTableHeaderPlain({ title, rightButton, noShadow }) {
	return (
		<div className={`w-full inline-flex justify-between p-9 bg-white rounded-t-md ${!noShadow && "shadow-lg"}`}>
			<div className="flex flex-wrap justify-between mb-6">
				<span className="glade-heading-four glade-black">
					{title ?? "Title"}
				</span>
				<span className="block sm:hidden">
					<ThreeDotButton />
				</span>
			</div>
			<div>{rightButton ?? null}</div>
		</div>
	);
}

export function CardTableHeaderComplex({
	title,
	filter,
	searchBar,
	searchBarPlaceholder,
	rightButton,
	rightButtonTitle,
}) {
	return (
		<div className="p-9 bg-white rounded-t-md shadow-lg">
			<div className="flex flex-wrap justify-between mb-6">
				<span className="glade-heading-four glade-black">
					{title ?? "Title"}
				</span>
				<span className="block sm:hidden">
					<ThreeDotButton />
				</span>
			</div>
			<div className="hidden sm:flex flex-row items-center justify-between">
				<div className="flex items-center gap-9">
					{filter ?? <Filter />}
					{searchBar ?? (
						<SearchInput placeholder={searchBarPlaceholder ?? "search"} />
					)}
				</div>
				<div className="gap-9">
					{rightButton ?? (
						<ButtonWithIconRight title={rightButtonTitle ?? "Download"} />
					)}
				</div>
			</div>
		</div>
	);
}

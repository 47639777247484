import React from "react";
import * as dfn from "date-fns";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { TextSkeleton } from "../../components/skeletons";

import { FiCopy } from "react-icons/fi";
import { copyTextToClipboard, fixDateForAllBrowsers, truncateRef } from "../../helpers/helper";
import useInflow from "../../hooks/useInflow";
import { getSpecificTransferInflow } from "../../redux/inflow/inflowActions";

export default function TransferInflowDetails() {
	const { txn_ref } = useParams();
	const dispatch = useDispatch();

	const {
		isLoadingSpecificTransferInflow: loading,
		specificTransferInflow: details,
	} = useInflow();

	React.useMemo(() => {
		dispatch(getSpecificTransferInflow(txn_ref));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [txn_ref]);

	return (
		<div className="p-3 flex flex-col">
			<div className="mb-4">
				<BreadCrumbs title={"Bank Transfers"} goBack />
			</div>

			{/* Account Details */}
			<div className="flex flex-col bg-white rounded-sm p-8 pt-5 mt-4">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">Account Details</p>
				</div>
				<div className="grid grid-cols-4 gap-4 py-3">
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Name
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.account_name}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Email
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.account_email}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Phone Number
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.account_phone}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Type
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1 capitalize">
								{details?.owner_type}
							</h6>
						)}
					</div>
				</div>
			</div>

			{/* Payment Details */}
			<div className="flex flex-col bg-white rounded-sm p-8 pt-5 mt-6">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">Payment Details</p>
				</div>
				<div className="grid grid-cols-4 gap-4 py-3">
					{details?.txn_ref && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Transaction Reference
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<div className="flex items-center">
									<h6 className="glade-normal-text-three glade-black py-1">
										{truncateRef(details?.txn_ref)}
									</h6>
									<FiCopy
										size={16}
										color="#AFAFAF"
										className="ml-3 cursor-pointer"
										onClick={() => copyTextToClipboard(details?.txn_ref)}
									/>
								</div>
							)}
						</div>
					)}
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.currency ?? "NGN"}{" "}
								{Number(details?.amount ?? 0)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Narration</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1 w-60 break-all">
								{details?.narration}
							</h6>
						)}
					</div>
					{details?.created_at && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">Date</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1 capitalize">
									{dfn.format(new Date(fixDateForAllBrowsers(details?.created_at)), "MMM d, yyyy hh:mm:ss a")}
								</h6>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import useAccounts from "../../hooks/useAccounts";
import { updateBusinessAccountMerchantServices } from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";
import CustomSwitch from "../../components/switch/CustomSwitch";

const EditBusinessServicesModal = ({
	modalStates,
	services,
	setModalStates,
	showEditBusinessServicesModal,
}) => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { isUpdatingBusinessAccountMerchantServices } = useAccounts();

	const onUpdateBusinessService = (service) => {
		dispatch(
			updateBusinessAccountMerchantServices(id, {
				[service.service]: service.status === 0 ? 1 : 0,
			})
		);
	};

	return (
		<BasicModal
			visibility={showEditBusinessServicesModal}
			onClose={() =>
				setModalStates({ ...modalStates, showEditBusinessServicesModal: false })
			}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditBusinessServicesModal: true,
				})
			}
			primaryButton=""
			modalTitle="Services"
			secondaryButton=""
			modelContentSection={
				<>
					{services?.map((service) => (
						<div className="mb-8" key={service.id}>
							<CustomSwitch
								className="justify-between"
								label={service.name}
								status={service.status}
								onChange={() => onUpdateBusinessService(service)}
							/>
						</div>
					))}
					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={() => {
								setModalStates({
									...modalStates,
									showEditBusinessServicesModal: false,
								});
							}}
							disabled={isUpdatingBusinessAccountMerchantServices}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Done"
								onClick={() => {
									setModalStates({
										...modalStates,
										showEditBusinessServicesModal: false,
									});
								}}
								className="block flex-grow-0"
								loading={isUpdatingBusinessAccountMerchantServices}
							/>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default EditBusinessServicesModal;

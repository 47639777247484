export const GET_ALL_CHECKOUT_INFLOW_START = "GET_ALL_CHECKOUT_INFLOW_START";
export const GET_ALL_CHECKOUT_INFLOW_SUCCESS = "GET_ALL_CHECKOUT_INFLOW_SUCCESS";
export const GET_ALL_CHECKOUT_INFLOW_FAILURE = "GET_ALL_CHECKOUT_INFLOW_FAILURE";

export const GET_ALL_CHECKOUT_INFLOW_FOR_DOWNLOAD_START = "GET_ALL_CHECKOUT_INFLOW_FOR_DOWNLOAD_START";
export const GET_ALL_CHECKOUT_INFLOW_FOR_DOWNLOAD_SUCCESS = "GET_ALL_CHECKOUT_INFLOW_FOR_DOWNLOAD_SUCCESS";
export const GET_ALL_CHECKOUT_INFLOW_FOR_DOWNLOAD_FAILURE = "GET_ALL_CHECKOUT_INFLOW_FOR_DOWNLOAD_FAILURE";

export const GET_SPECIFIC_CHECKOUT_INFLOW_START = "GET_SPECIFIC_CHECKOUT_INFLOW_START";
export const GET_SPECIFIC_CHECKOUT_INFLOW_SUCCESS = "GET_SPECIFIC_CHECKOUT_INFLOW_SUCCESS";
export const GET_SPECIFIC_CHECKOUT_INFLOW_FAILURE = "GET_SPECIFIC_CHECKOUT_INFLOW_FAILURE";

export const GET_ALL_POS_INFLOW_START = "GET_ALL_POS_INFLOW_START";
export const GET_ALL_POS_INFLOW_SUCCESS = "GET_ALL_POS_INFLOW_SUCCESS";
export const GET_ALL_POS_INFLOW_FAILURE = "GET_ALL_POS_INFLOW_FAILURE";

export const GET_ALL_POS_INFLOW_FOR_DOWNLOAD_START = "GET_ALL_POS_INFLOW_FOR_DOWNLOAD_START";
export const GET_ALL_POS_INFLOW_FOR_DOWNLOAD_SUCCESS = "GET_ALL_POS_INFLOW_FOR_DOWNLOAD_SUCCESS";
export const GET_ALL_POS_INFLOW_FOR_DOWNLOAD_FAILURE = "GET_ALL_POS_INFLOW_FOR_DOWNLOAD_FAILURE";

export const GET_SPECIFIC_POS_INFLOW_START = "GET_SPECIFIC_POS_INFLOW_START";
export const GET_SPECIFIC_POS_INFLOW_SUCCESS = "GET_SPECIFIC_POS_INFLOW_SUCCESS";
export const GET_SPECIFIC_POS_INFLOW_FAILURE = "GET_SPECIFIC_POS_INFLOW_FAILURE";

export const GET_ALL_TRANSFER_INFLOW_START = "GET_ALL_TRANSFER_INFLOW_START";
export const GET_ALL_TRANSFER_INFLOW_SUCCESS = "GET_ALL_TRANSFER_INFLOW_SUCCESS";
export const GET_ALL_TRANSFER_INFLOW_FAILURE = "GET_ALL_TRANSFER_INFLOW_FAILURE";

export const GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_START = "GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_START";
export const GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_SUCCESS = "GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_SUCCESS";
export const GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_FAILURE = "GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_FAILURE";

export const GET_SPECIFIC_TRANSFER_INFLOW_START = "GET_SPECIFIC_TRANSFER_INFLOW_START";
export const GET_SPECIFIC_TRANSFER_INFLOW_SUCCESS = "GET_SPECIFIC_TRANSFER_INFLOW_SUCCESS";
export const GET_SPECIFIC_TRANSFER_INFLOW_FAILURE = "GET_SPECIFIC_TRANSFER_INFLOW_FAILURE";

import React, { useState } from "react";
import * as dfn from "date-fns";
import { FiCopy } from "react-icons/fi";
import { RiLoader5Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import { TextSkeleton } from "../../components/skeletons";
import {
	copyTextToClipboard,
	statusEnum,
	truncateRef,
} from "../../helpers/helper";
import useTransactions from "../../hooks/useTransactions";

import {
	getSingleTransferTransactions,
	revertSpecificTransferTransactions,
	updateManualInternationalTransfer,
	verifySpecificTransferTransactions,
} from "../../redux/transactions/transactionsActions";
import BasicIndicatorWithOptions from "../../components/indicators/BasicIndicatorWithOptions";
import BasicModal from "../../components/modals/BasicModal";

export default function TransfersDetail() {
	const { id } = useParams();
	const dispatch = useDispatch();

	const {
		isLoadingSpecificTransferTransactions: loading,
		internationalTransfersTransactions: details,
		isRevertingSpecificTransferTransactions,
		isVerifyingSpecificTransferTransactions,
	} = useTransactions();
	const { isUpdatingSingleManualTransfer } =
		useTransactions();

	const [selectedData, setSelectedData] = useState();
	const [selectedVerb, setSelectedVerb] = useState('');

	React.useEffect(() => {
		dispatch(getSingleTransferTransactions(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	const handleAction = (data, actionType) => {
		setSelectedData(id);
		setSelectedVerb(actionType);
	};
	const onSuccess = () => {
		dispatch(getSingleTransferTransactions(id));
		setSelectedData();
		setSelectedVerb('');
	}
	return (
		<div className="p-3 flex flex-col">
			<div className="mb-6">
				<BreadCrumbs title={"Transactions"} goBack />
			</div>

			<div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">Account Details</p>
				</div>
				{details?.map((data) =>
					<div className="grid grid-cols-4 gap-4 py-3">
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Account Name
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{data.account_name}
								</h6>
							)}
						</div>
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Account Email
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{data?.account_email}
								</h6>
							)}
						</div>
						{data?.account_phone && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Phone Number
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{data?.account_phone}
									</h6>
								)}
							</div>
						)}
						{data?.currency && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Account Currency
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{data?.currency}
									</h6>
								)}
							</div>
						)}
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Account Type
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1 capitalize">
									{data?.owner_type}
								</h6>
							)}
						</div>
					</div>)}
			</div>

			{details?.map((data) =>
				<div className="flex justify-between mt-4">
					{loading ? (
						<TextSkeleton width="100px" />
					) : (
						["failed", "successful"].includes(data?.status) ?
							<BasicIndicator
								className="px-5 py-1 capitalize"
								type={statusEnum[data?.status]}
								title={data?.status}
							/>
							:
							<BasicIndicatorWithOptions
								className="px-5 py-1 capitalize"
								type={statusEnum[data?.status]}
								title={'Processing'}
								data={data?.id}
								test={handleAction}
							/>
					)}

				</div>)}

			{details?.map((transaction) => (
				<div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
					<div className="py-3 border-b-2 border-solid border-gray-100">
						<p className="glade-black glade-normal-text-two">
							Transaction Details
						</p>
					</div>

					<div className="grid grid-cols-4 gap-4 py-3">
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Transaction Channel
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{transaction?.channel ?? 'Bank'}
								</h6>
							)}
						</div>

						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Transaction Reference
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<div className="flex items-center">
									<h6 className="glade-normal-text-three glade-black py-1">
										{truncateRef(transaction?.txn_ref)}
									</h6>
									<FiCopy
										size={16}
										color="#AFAFAF"
										className="ml-3 cursor-pointer"
										onClick={() =>
											copyTextToClipboard(
												transaction.txn_ref ?? ""
											)
										}
									/>
								</div>
							)}
						</div>

						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{transaction.beneficiary_currency_code ?? "NGN"}{" "}
									{Number(transaction.beneficary_value)}
								</h6>
							)}
						</div>

						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">Fee</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{transaction.currency ?? "NGN"}{" "}
									{transaction?.calculatedfee}
								</h6>
							)}
						</div>

						{transaction?.beneficiary_institution && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">Recipient's Bank Name</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_institution}
									</h6>
								)}
							</div>
						)}

						{transaction?.beneficiary_country && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">Recipient's Country</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_country}
									</h6>
								)}
							</div>
						)}

						{transaction?.beneficiary_address && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">Recipient's Address</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_address}
									</h6>
								)}
							</div>
						)}

						{transaction?.beneficiary_state && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">Recipient's State</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_state}
									</h6>
								)}
							</div>
						)}

						{transaction?.beneficiary_city && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">Recipient's City</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_city}
									</h6>
								)}
							</div>
						)}

						{transaction?.beneficiary_zip_code && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">Recipient's Postal/Zip code</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_zip_code}
									</h6>
								)}
							</div>
						)}

						{transaction.order_ref && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Order Ref
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<div className="flex items-center">
										<h6 className="glade-normal-text-three glade-black py-1">
											{truncateRef(transaction.order_ref)}
										</h6>
										<FiCopy
											size={16}
											color="#AFAFAF"
											className="ml-3 cursor-pointer"
											onClick={() =>
												copyTextToClipboard(transaction.order_ref ?? "")
											}
										/>
									</div>
								)}
							</div>
						)}

						{transaction?.beneficiary_swift_code && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">Swift Code</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_swift_code}
									</h6>
								)}
							</div>
						)}

						{transaction?.beneficiary_sort_code && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">Sort Code</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_sort_code}
									</h6>
								)}
							</div>
						)}

						{transaction.beneficiary_name && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Recipient's Name
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_name}
									</h6>
								)}
							</div>
						)}

						{transaction.beneficiary_phone && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Recipient's Phone
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_phone}
									</h6>
								)}
							</div>
						)}

						{transaction.beneficiary_account_type && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Recipient's Account Type
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_account_type}
									</h6>
								)}
							</div>
						)}

						{transaction?.beneficiary_email && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Recipient's Email
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_email}
									</h6>
								)}
							</div>
						)}

						{transaction.receipient_name && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Recipient Name
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.receipient_name}
									</h6>
								)}
							</div>
						)}

						{transaction.beneficiary_account && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Recipient's Account Number
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										{transaction.beneficiary_account}
									</h6>
								)}
							</div>
						)}

						{/* transfer method here	 */}

						{(transaction.receipient_bank ||
							transaction.receipient_bank_code) && (
								<div className="flex flex-col">
									<h5 className="glade-normal-text-two glade-ash py-1">
										Recipient's Bank
									</h5>
									{loading ? (
										<TextSkeleton width="100px" />
									) : (
										<h6 className="glade-normal-text-three glade-black py-1 capitalize">
											{transaction.receipient_bank ||
												transaction.receipient_bank_code}
										</h6>
									)}
								</div>
							)}

						{transaction.destination && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Destination
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1 capitalize">
										{transaction.destination}
									</h6>
								)}
							</div>
						)}

						{transaction.narration && (
							<div className="flex flex-col">
								<h5 className="glade-normal-text-two glade-ash py-1">
									Narration
								</h5>
								{loading ? (
									<TextSkeleton width="100px" />
								) : (
									<h6 className="glade-normal-text-three glade-black py-1 capitalize">
										{transaction.narration}
									</h6>
								)}
							</div>
						)}

						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">Date</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{dfn.format(new Date(transaction?.created_at), "MMM d, yyyy")}
								</h6>
							)}
						</div>

					</div>
				</div>
			))}
			{
				selectedVerb && selectedData &&
				<BasicModal
					visibility={true}
					onClose={() => { setSelectedVerb(''); setSelectedData() }}
					modalTitleSection=""
					secondaryButton=""
					modelContentSection={
						<div className="py-8 px-8 ">
							<p className=" glade-black text-center mb-8">
								Are you sure you want to update this status to {selectedVerb}?
							</p>
							<Button.Dark
								onClick={() => {
									dispatch(updateManualInternationalTransfer({ ids: [selectedData] }, selectedVerb, onSuccess))
								}}
								disabled={isUpdatingSingleManualTransfer}
								title="Yes"
								className={`glade-normal-text-three glade-button-blue ${isUpdatingSingleManualTransfer
									? " cursor-not-allowed"
									: ""
									} text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
							>
								{isUpdatingSingleManualTransfer &&
									<RiLoader5Fill
										size={24}
										className="animate-spin ml-4"
									/>
								}
							</Button.Dark>
							<p className="glade-black mx-1 mt-8 cursor-pointer text-center" onClick={() => { setSelectedVerb(''); setSelectedData() }}>
								Cancel
							</p>
						</div>
					}
				/>
			}
		</div>
	);
}

import React, { useMemo, useState } from "react";
import * as dfn from "date-fns";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { TextSkeleton } from "../../components/skeletons";
import useHome from "../../hooks/useHome";
// import TransactionStatusReportFilter from "./TransactionStatusReportFilter";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import styled from "styled-components";

const TooltipStyle = styled.div`
  background: #717485;
  border: 1px solid #717485;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;

  .label {
    color: #fbfbfb60;
  }

  .value {
    color: #f3f6f7;
  }
`;

let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (!active || !tooltip) return null;
  for (const bar of payload) {
    if (bar.dataKey === tooltip)
      return (
        <TooltipStyle>
          <p className="glade-small-text-one value text-center">
            {bar.payload.transaction_date}
          </p>
          <p className="glade-small-text-one label text-center capitalize">
            {bar.name}
          </p>
          <p className="glade-small-text-one value text-center">{bar.value}</p>
        </TooltipStyle>
      );
  }
  return null;
};

const TransactionStatusReport = () => {
  const { isLoadingTransactionStatus, transactionStatus } = useHome();
  const { report } = transactionStatus;

  const [allStatuses, setAllStatuses] = useState([]);

  useMemo(() => {
    let data = [];
    let filtered_data = [];

    if (report) {
      Object.entries(report).forEach((entry) => {
        const [, value] = entry;
        data.push(...value);
      });
      data.forEach((item) => {
        let date = item?.transaction_date;
        let group = {};
        data.forEach((_item) => {
          if (_item.transaction_date === date) {
            group.transaction_date = dfn.lightFormat(new Date(date), "MMM d, yyyy");
            group[_item.status] = _item.volume;
          }
        });
        const parsedDate = dfn.parse(date, "yyyy-MM-dd", new Date());
        if (
          !filtered_data.find(
            (item) => item.transaction_date === dfn.format(parsedDate, "MMM d"),
          )
        ) {
          group.transaction_date = dfn.format(parsedDate, "MMM d");
          filtered_data.push(group);
        }
      });
    }
    setAllStatuses(filtered_data);
  }, [report]);

  return (
    <div className="flex flex-col p-6 bg-white rounded mt-6 pb-10">
      <div className="flex row justify-between mb-6 pb-1 border-b">
        <h6 className="glade-normal-text-two glade-black">
          Transaction Status Report
        </h6>

        {/* <TransactionStatusReportFilter /> */}
      </div>

      <div className="flex flex-col justify-center items-end pr-10">
        <div className="flex items-center w-40 text-left mb-3">
          <CircleIndicator
            type="danger"
            style={{ height: "12px", width: "12px", borderRadius: "50%" }}
          />
          <p className="glade-small-text-one">Failed Transactions</p>
        </div>
        <div className="flex items-center w-40 text-left">
          <CircleIndicator
            type="success"
            style={{ height: "12px", width: "12px", borderRadius: "50%" }}
          />
          <p className="glade-small-text-one" style={{ color: "#263238" }}>
            Successful Transactions
          </p>
        </div>
      </div>

      <div className="h-96 w-full pt-5">
        {isLoadingTransactionStatus ? (
          <TextSkeleton className="h-full" />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={allStatuses}
              margin={{
                top: 5,
                right: 50,
                left: 10,
                bottom: 5,
              }}
              barCategoryGap={35}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="transaction_date"
                axisLine={false}
                tickLine={false}
                stroke="#9CA3AF"
                fontSize={12}
                dy={10}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                stroke="#9CA3AF"
                dx={-10}
                tick={{ fontSize: 5 }}
              />
              {/* <Tooltip /> */}
              <Tooltip content={<CustomTooltip />} cursor={false} />
              <Bar
                dataKey="successful"
                fill="#5CDB5C"
                width={15}
                barSize={15}
                radius={1}
                onMouseOver={() => (tooltip = "successful")}
              />
              <Bar
                dataKey="failed"
                fill="#FF0021"
                width={15}
                barSize={15}
                onMouseOver={() => (tooltip = "failed")}
                radius={1}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default TransactionStatusReport;

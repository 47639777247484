import React from 'react';

export default function TextInput({
    label, 
    id,
    name, 
    type,
    value, 
    onChange=()=>{}, 
    placeholder, 
    onFocus=()=>{},
    onBlur=()=>{},
    autoComplete,
    disabled=false, 
    className, 
    leftSection, 
    rightSection,
    errorState
}) {
    return (
        <div className="space-y-1 col-span-6 sm:col-span-3">
            <label htmlFor={name??'name'} className="block glade-small-text-two glade-black">
                {label??''}
            </label>
            <div className="mt-1 flex rounded-sm shadow-sm">
                {leftSection??<React.Fragment></React.Fragment>}
                <input
                    type={type??"text"}
                    name={name??'name'}
                    id={id??name??'id'}
                    value={value??''}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={disabled}
                    className={`glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm placeholder-gray-400 ${!errorState? 'border-gray-300' : ' border-2 border-red-500 focus:ring-red-500'} ` + className}
                    placeholder={placeholder??''}
                    autoComplete={autoComplete??''}
                />
                {rightSection??<React.Fragment></React.Fragment>}
            </div>
        </div>
    );
}

import * as dfn from "date-fns";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { FiCopy } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Button from "../../components/buttons/Button";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import PillIndicator from "../../components/indicators/PillIndicator";
import TabNav from "../../components/navbars/TabNav";
import { PaginatedTable } from "../../components/table/Table";
import DownloadAsCSVButton from "../../helpers/DownloadAsCSV";
import {
	copyTextToClipboard,
	fixDateForAllBrowsers,
	statusEnum,
} from "../../helpers/helper";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import usePos from "../../hooks/usePos";
import {
	getAllPosTerminals,
	getAllPosTerminalsForDownload,
} from "../../redux/pos/posActions";
// import { useForm } from "react-hook-form";
import AssignPosModal from "./AssignPosModal";

const PosTerminals = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const [showAssignTerminalModal, setShowAssignTerminalModal] = useState(false);

	const { isLoadingAllPosTerminals, allPosTerminals, assignedPos } = usePos();

	const defaultFilterParams = {
		status: "",
		page: currentPage,
		search: "",
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, search, status, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_from, date_to, search]);

	React.useMemo(() => {
		dispatch(
			getAllPosTerminals({
				params: {
					...(search
						? { search, paginate: 1, current_page: page, per_page: 10 }
						: {
								date_from,
								date_to,
								status,
								paginate: 1,
								current_page: page,
								per_page: 10,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		status,
		page,
		// per_page,
		search,
	]);

	// // Clear query param on render
	// useEffect(() => {
	//   urlSearchParamsHistory.replace({});
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	React.useMemo(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	return (
		<div className="p-3">
			<TabNav
				tabs={[
					{ title: "Terminals", path: "/dashboard/pos" },
					{ title: "POS Requests", path: "/dashboard/pos/requests" },
					{ title: "Agent Requests", path: "/dashboard/pos/agent-requests" },
				]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				filterFields={["terminalStatus", "date"]}
				filterCallback={handleChange}
				disabled={isLoadingAllPosTerminals}
				title={`Terminals ${
					!isLoadingAllPosTerminals && assignedPos?.length
						? "- " + assignedPos?.length
						: ""
				}`}
				actionButton={
					<div className="flex items-center">
						<Button.Dark
							title={"Assign Terminal"}
							disabled={isLoadingAllPosTerminals}
							onClick={() => setShowAssignTerminalModal(true)}
						/>
						<div>
							<DownloadAsCSVButton
								fileName="Pos Terminals"
								CSVCallback={(handleDownload) =>
									dispatch(
										getAllPosTerminalsForDownload(
											{
												params: {
													...(search
														? {
																search,
																paginate: 0,
																current_page: page,
																per_page: 10,
														  }
														: {
																date_from,
																date_to,
																status,
																paginate: 0,
																current_page: page,
																per_page: 10,
														  }),
												},
											},
											(data) =>
												handleDownload({
													data,
													fileName: "POS Terminals",
													format: (value, i) => ({
														"S/N": `${i + 1}`,
														Status: value?.status,
														"Account Name": value?.account_name,
														"Terminal ID": value?.terminal_id,
														"Account type": value?.owner_type,
														"Number of Transactions": value?.total_transactions,
														Revenue: value?.revenue ?? 0,
														"Date Assigned":
															value?.date_assigned ?? "Not Available",
													}),
												})
										)
									)
								}
								disabled={isLoadingAllPosTerminals}
								className="whitespace-pre ml-4"
							/>
						</div>
					</div>
				}
				searchBarPlaceholder={"Account Name or Terminal ID"}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof assignedPos === "object" && !Array.isArray(assignedPos)
						? assignedPos
						: assignedPos
				}
				empty_message="No Pos Terminals"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllPosTerminals}
				setCurrentPage={setCurrentPage}
				totalPages={allPosTerminals?.total}
			/>

			{/* AssignTerminal modal */}
			<AssignPosModal
				setShowAssignPosModal={setShowAssignTerminalModal}
				showAssignPosModal={showAssignTerminalModal}
			/>
		</div>
	);
};

const columns = [
	{
		accessor: "status",
		Header: "Status",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/pos/terminal/detail/${row.original.id}`}>
				<PillIndicator
					className="px-5 py-1 capitalize"
					type={statusEnum[value]}
					title={value ? "Active" : "Disabled"}
				/>
				{/* {value}
        </PillIndicator> */}
			</Link>
		),
	},
	{
		accessor: "account_name",
		Header: "Account Name",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/pos/terminal/detail/${row.original.id}`}>
				<span className="glade-normal-text-two capitalize">{value}</span>
			</Link>
		),
	},
	{
		accessor: "terminal_id",
		Header: "Terminal ID",
		Cell: ({ value, row }) => (
			<div className="flex items-center">
				<Link to={`/dashboard/pos/terminal/detail/${row.original.id}`}>
					<span className="glade-normal-text-two">{value}</span>
				</Link>
				<FiCopy
					size={16}
					color="#AFAFAF"
					className="ml-3 cursor-pointer"
					onClick={() => copyTextToClipboard(value)}
				/>
			</div>
		),
	},
	{
		accessor: "owner_type",
		Header: "Account type",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/pos/terminal/detail/${row.original.id}`}>
				<span className="glade-normal-text-two capitalize">{value}</span>
			</Link>
		),
	},
	{
		accessor: "total_transactions",
		Header: "Number of Transactions",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/pos/terminal/detail/${row.original.id}`}>
				<span className="glade-normal-text-two">{value ?? 0}</span>
			</Link>
		),
	},
	{
		accessor: "revenue",
		Header: "Revenue",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/pos/terminal/detail/${row.original.id}`}>
				<span className="glade-normal-text-two">
					{row.original.currency ?? "NGN"}{" "}
					{Number(value ?? 0)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			</Link>
		),
	},
	{
		accessor: "date_assigned",
		Header: "Date Assigned",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/pos/terminal/detail/${row.original.id}`}>
				<span className="glade-normal-text-two">
					{value ? (
						<>
							{dfn.format(
								new Date(fixDateForAllBrowsers(value)),
								"MMM d, yyyy hh:mm:ss a"
							)}
						</>
					) : (
						"Not Available"
					)}
				</span>
			</Link>
		),
	},
];

export default PosTerminals;

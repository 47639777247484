import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import {
	fixDateForAllBrowsers,
	statusEnum,
} from "../../helpers/helper";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import useTransactions from "../../hooks/useTransactions";
import {
	getPayrollSalaryAdvance,
	getPayrollSalaryAdvanceForDownload,
} from "../../redux/transactions/transactionsActions";
import PillIndicator from "../../components/indicators/PillIndicator";

export default function AllTransactions() {
	const dispatch = useDispatch();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const { isLoadingPayrollSalaryAdvance, payrollSalaryAdvance } = useTransactions();

	const defaultFilterParams = {
		search: "",
		business_name: "",
		business_email: '',
		status: qs.parse(location.search)?.status ?? "",
		page: currentPage,
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 4), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const {
		date_from,
		date_to,
		status,
		search,
		page,
	} = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		date_from,
		date_to,
		search,
		status
	]);

	React.useEffect(() => {
		dispatch(
			getPayrollSalaryAdvance({
				params: {
					...(search ? { search, paginate: 1, page, per_page: 10 }
						: {
								date_from,
								date_to,
								paginate: 1,
								page,
								per_page: 10,
								status,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		date_from,
		date_to,
		page,
		search,
		status
	]);

	React.useEffect(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	return (
		<div className="p-3 flex flex-col">
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				filterFields={[
					"date",
				]}
				filterCallback={handleChange}
				disabled={isLoadingPayrollSalaryAdvance}
				fileName="Payroll Salary Advance"
				downloadAsCSV
				CSVCallback={(handleDownload) =>
					dispatch(
						getPayrollSalaryAdvanceForDownload(
							{
								params: {
									...(search
										? {
												search,
												paginate: 1,
												page,
												per_page: 10,
										  }
										: {
												date_from,
												date_to,
												paginate: 0,
												page,
												per_page: 10,
												status
										  }),
								},
							},
							(data) =>
								handleDownload({
									data,
									fileName: "Payroll Salary Advance",
									format: (value, i) => ({
										"S/N": `${i + 1}`,
										"Status": value?.status,
										"Business Name": value?.business_name,
										'Full Name': value?.firstname + ' ' + value?.lastname,
										Email: value?.business_email,
										"Date Created": value?.created_at
									}),
								})
						)
					)
				}
				getWorksheets={({ xlsx }) => {
					return {
						data: xlsx.utils.json_to_sheet(
							payrollSalaryAdvance?.data?.map((value, i) => ({
								"S/N": `${i + 1}`,
								"Status": value?.status,
								"Business Name": value?.business_name,
								'Full Name': value?.firstname + ' ' + value?.lastname,
								Email: value?.business_email,
								"Date Created": value?.created_at
							}))
						),
					};
				}}
				searchBarPlaceholder={search || "Account Name or Email"}

				title={`Payroll Advance Request`}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof payrollSalaryAdvance === "object" && !Array.isArray(payrollSalaryAdvance)
						? payrollSalaryAdvance?.data
						: payrollSalaryAdvance?.data?.reverse()
				}
				empty_message="No Salary Advance Requests"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingPayrollSalaryAdvance}
				setCurrentPage={setCurrentPage}
				totalPages={payrollSalaryAdvance?.total}
			/>
		</div>
	);
}

const columns = [
	{
		accessor: "status",
		Header: "Status",
		style: {
			paddingLeft: 0,
			paddingRight: "10px",
			width: "50px",
		},
		Cell: ({ value }) => (
			<React.Fragment>
			 <PillIndicator
            className="px-5 py-1 capitalize"
            type={statusEnum[value]}
            title={value}
          />
			</React.Fragment>
		),
	},
	{
		accessor: "business_name",
		Header: "Business Name",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/payroll/loan/requests/detail/${row.original.id}`}
			>
				<span className="glade-normal-text-two capitalize">{value}</span>
			</Link>
		),
	},
	{
		accessor: "full_name",
		Header: "Full Name",
		Cell: ({ row }) => (
			<Link
				to={`/dashboard/payroll/loan/requests/detail/${row.original.id}`}
			>
				<span className="glade-normal-text-two capitalize">{row?.original?.firstname + ' ' + row?.original?.lastname}</span>
			</Link>
		),
	},
	{
		accessor: "business_email",
		Header: "Email",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/payroll/loan/requests/detail/${row.original.id}`}
			>
				<span className="glade-normal-text-two">{value}</span>
			</Link>
		),
	},

	{
		accessor: "created_at",
		Header: "Date Created",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/payroll/loan/requests/detail/${row.original.id}`}
			>
				<>
					<span className="glade-normal-text-two">
						{dfn.format(new Date(fixDateForAllBrowsers(value)), "dd-MM-yy") + " "}
					</span>
					<span className="glade-normal-text-two"> 
						{dfn.format(new Date(fixDateForAllBrowsers(value)), "hh:mm:ss a").toLowerCase()}
					</span>
				</>
			</Link>
		),
	}
];

import React from "react";
import { Link } from "react-router-dom";
import { TextSkeleton } from "../../components/skeletons";
import useHome from "../../hooks/useHome";

import ActiveUsersReport from "./ActiveUsers";
import HomeFilter from "./HomeFilter";
import MostUsedServices from "./MostUsedServices";
import TransactionStatusReport from "./TransactionStatusReport";
import TransactionSummary from "./TransactionSummary";
import TransactionSummaryFilter from "./TransactionSummaryFilter";

export default function Home() {
  const { isLoadingTransactionSummary, transactionSummary } = useHome();
  const { report } = transactionSummary;


  return (
    <>
      <div className="flex justify-end mb-4">
        <HomeFilter />
      </div>
      <main className="flex">
        {/* Left column */}
        <section className="w-3/5 pr-4 flex flex-col">
          <div className="grid grid-cols-3 gap-x-3 mb-6">
            {/* Registered accounts card */}
            <div className="flex items-center justify-between bg-white rounded-sm py-3 px-3">
              <div className="mr-5">
                <h5 className="glade-normal-text-two glade-ash mb-2">
                  Registered Accounts
                </h5>
                {isLoadingTransactionSummary ? (
                  <TextSkeleton className="mt-2" width="80px" />
                ) : (
                  <h6 className="glade-heading-three glade-black mt-0">
                    {report?.entity_count?.registered_accounts}
                  </h6>
                )}
              </div>
              <Link to="/dashboard/accounts">
                <h6 className="glade-small-text-one glade-blue-darker cursor-pointer capitalize">
                  view all
                </h6>
              </Link>
            </div>

            {/* Active POS Terminals card */}
            <div className="flex items-center justify-between bg-white rounded-sm py-3 px-3">
              <div className="mr-5">
                <h5 className="glade-normal-text-two glade-ash mb-2">
                  Active POS Terminals
                </h5>
                {isLoadingTransactionSummary ? (
                  <TextSkeleton className="mt-2" width="80px" />
                ) : (
                  <h6 className="glade-heading-three glade-black mt-0">
                    {report?.entity_count?.active_terminals}
                  </h6>
                )}
              </div>
              <Link to="/dashboard/pos">
                <h6 className="glade-small-text-one glade-blue-darker cursor-pointer capitalize">
                  view all
                </h6>
              </Link>
            </div>

            {/* Active Loans card */}
            <div className="flex items-center justify-between bg-white rounded-sm py-3 px-3">
              <div className="mr-5">
                <h5 className="glade-normal-text-two glade-ash mb-2">Active Loans</h5>
                {isLoadingTransactionSummary ? (
                  <TextSkeleton className="mt-2" width="80px" />
                ) : (
                  <h6 className="glade-heading-three glade-black mt-0">
                    {report?.entity_count?.active_loans}
                  </h6>
                )}
              </div>
              {/* <Link to="">
              <h6 className="glade-small-text-one glade-blue cursor-pointer capitalize">
                view all
              </h6>
            </Link> */}
            </div>
          </div>

          <TransactionSummary />

          <TransactionStatusReport />
        </section>

        {/* Right column */}
        <section className="w-2/5">
          <ActiveUsersReport />

          <MostUsedServices />
        </section>
      </main>
    </>
  );
}

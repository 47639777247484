export const GET_ALL_USERS_START = "GET_ALL_USERS_START";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

export const GET_USER_BY_UUID_START = "GET_USER_BY_UUID_START";
export const GET_USER_BY_UUID_SUCCESS = "GET_USER_BY_UUID_SUCCESS";
export const GET_USER_BY_UUID_FAILURE = "GET_USER_BY_UUID_FAILURE";

export const GET_BUSINESS_BY_UUID_START = "GET_BUSINESS_BY_UUID_START";
export const GET_BUSINESS_BY_UUID_SUCCESS = "GET_BUSINESS_BY_UUID_SUCCESS";
export const GET_BUSINESS_BY_UUID_FAILURE = "GET_BUSINESS_BY_UUID_FAILURE";

export const GET_ALL_POS_TERMINALS_START = "GET_ALL_POS_TERMINALS_START";
export const GET_ALL_POS_TERMINALS_SUCCESS = "GET_ALL_POS_TERMINALS_SUCCESS";
export const GET_ALL_POS_TERMINALS_FAILURE = "GET_ALL_POS_TERMINALS_FAILURE";

export const GET_SPECIFIC_POS_TERMINAL_START = "GET_SPECIFIC_POS_TERMINAL_START";
export const GET_SPECIFIC_POS_TERMINAL_SUCCESS = "GET_SPECIFIC_POS_TERMINAL_SUCCESS";
export const GET_SPECIFIC_POS_TERMINAL_FAILURE = "GET_SPECIFIC_POS_TERMINAL_FAILURE";

export const GET_ALL_POS_REQUESTS_START = "GET_ALL_POS_REQUESTS_START";
export const GET_ALL_POS_REQUESTS_SUCCESS = "GET_ALL_POS_REQUESTS_SUCCESS";
export const GET_ALL_POS_REQUESTS_FAILURE = "GET_ALL_POS_REQUESTS_FAILURE";

export const GET_SPECIFIC_POS_REQUEST_START = "GET_SPECIFIC_POS_REQUEST_START";
export const GET_SPECIFIC_POS_REQUEST_SUCCESS = "GET_SPECIFIC_POS_REQUEST_SUCCESS";
export const GET_SPECIFIC_POS_REQUEST_FAILURE = "GET_SPECIFIC_POS_REQUEST_FAILURE";

export const ASSIGN_POS_TERMINAL_START = "ASSIGN_POS_TERMINAL_START";
export const ASSIGN_POS_TERMINAL_SUCCESS = "ASSIGN_POS_TERMINAL_SUCCESS";
export const ASSIGN_POS_TERMINAL_FAILURE = "ASSIGN_POS_TERMINAL_FAILURE";

export const UNASSIGN_POS_TERMINAL_START = "UNASSIGN_POS_TERMINAL_START";
export const UNASSIGN_POS_TERMINAL_SUCCESS = "UNASSIGN_POS_TERMINAL_SUCCESS";
export const UNASSIGN_POS_TERMINAL_FAILURE = "UNASSIGN_POS_TERMINAL_FAILURE";

export const DISABLE_POS_TERMINAL_START = "DISABLE_POS_TERMINAL_START";
export const DISABLE_POS_TERMINAL_SUCCESS = "DISABLE_POS_TERMINAL_SUCCESS";
export const DISABLE_POS_TERMINAL_FAILURE = "DISABLE_POS_TERMINAL_FAILURE";

export const ENABLE_POS_TERMINAL_START = "ENABLE_POS_TERMINAL_START";
export const ENABLE_POS_TERMINAL_SUCCESS = "ENABLE_POS_TERMINAL_SUCCESS";
export const ENABLE_POS_TERMINAL_FAILURE = "ENABLE_POS_TERMINAL_FAILURE";

export const APPROVE_POS_REQUEST_START = "APPROVE_POS_REQUEST_START";
export const APPROVE_POS_REQUEST_SUCCESS = "APPROVE_POS_REQUEST_SUCCESS";
export const APPROVE_POS_REQUEST_FAILURE = "APPROVE_POS_REQUEST_FAILURE";

export const DECLINE_POS_REQUEST_START = "DECLINE_POS_REQUEST_START";
export const DECLINE_POS_REQUEST_SUCCESS = "DECLINE_POS_REQUEST_SUCCESS";
export const DECLINE_POS_REQUEST_FAILURE = "DECLINE_POS_REQUEST_FAILURE";

export const GET_ALL_AGENT_REQUESTS_START = "GET_ALL_AGENT_REQUESTS_START";
export const GET_ALL_AGENT_REQUESTS_SUCCESS = "GET_ALL_AGENT_REQUESTS_SUCCESS";
export const GET_ALL_AGENT_REQUESTS_FAILURE = "GET_ALL_AGENT_REQUESTS_FAILURE";

export const GET_SPECIFIC_AGENT_REQUEST_START = "GET_SPECIFIC_AGENT_REQUEST_START";
export const GET_SPECIFIC_AGENT_REQUEST_SUCCESS = "GET_SPECIFIC_AGENT_REQUEST_SUCCESS";
export const GET_SPECIFIC_AGENT_REQUEST_FAILURE = "GET_SPECIFIC_AGENT_REQUEST_FAILURE";

export const APPROVE_AGENT_REQUEST_START = "APPROVE_AGENT_REQUEST_START";
export const APPROVE_AGENT_REQUEST_SUCCESS = "APPROVE_AGENT_REQUEST_SUCCESS";
export const APPROVE_AGENT_REQUEST_FAILURE = "APPROVE_AGENT_REQUEST_FAILURE";

export const DECLINE_AGENT_REQUEST_START = "DECLINE_AGENT_REQUEST_START";
export const DECLINE_AGENT_REQUEST_SUCCESS = "DECLINE_AGENT_REQUEST_SUCCESS";
export const DECLINE_AGENT_REQUEST_FAILURE = "DECLINE_AGENT_REQUEST_FAILURE";

export const GET_ALL_PROVIDERS_START = "GET_ALL_PROVIDERS_START";
export const GET_ALL_PROVIDERS_SUCCESS = "GET_ALL_PROVIDERS_SUCCESS";
export const GET_ALL_PROVIDERS_FAILURE = "GET_ALL_PROVIDERS_FAILURE";

export const UPDATE_PROVIDER_STATUS_START = "UPDATE_PROVIDER_STATUS_START";
export const UPDATE_PROVIDER_STATUS_SUCCESS = "UPDATE_PROVIDER_STATUS_SUCCESS";
export const UPDATE_PROVIDER_STATUS_FAILURE = "UPDATE_PROVIDER_STATUS_FAILURE"

export const SEARCH_PROVIDER_STATUS_START = "SEARCH_PROVIDER_STATUS_START";
export const SEARCH_PROVIDER_STATUS_SUCCESS = "SEARCH_PROVIDER_STATUS_SUCCESS";
export const SEARCH_PROVIDER_STATUS_FAILURE = "SEARCH_PROVIDER_STATUS_FAILURE"

export const GET_PROVIDERS_CATEGORY_SUCCESS = "GET_PROVIDERS_CATEGORY_SUCCESS";
export const GET_PROVIDERS_CATEGORY_START = "GET_PROVIDERS_CATEGORY_START";
export const GET_PROVIDERS_CATEGORY_FAILURE = "GET_PROVIDERS_CATEGORY_FAILURE";

export const SEARCH_PROVIDERS_CATEGORY_SUCCESS = "SEARCH_PROVIDERS_CATEGORY_SUCCESS";
export const SEARCH_PROVIDERS_CATEGORY_START = "SEARCH_PROVIDERS_CATEGORY_START";
export const SEARCH_PROVIDERS_CATEGORY_FAILURE = "SEARCH_PROVIDERS_CATEGORY_FAILURE";
import React, { useEffect, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { MultiSelectContext } from "../../context/MultiSelectContext";
import Axios from "../../connection/defaultClient";
import NoResult from "./NoResult";


const SMultiSelectSuggestions = styled.div`
  min-height: auto;
  max-height: 200px;
  overflow-y: scroll;
  list-style-type: none;

  li {
    padding: .5rem;
    transition: color 200ms ease-in, 
    background-color 200ms ease-in;
    border-radius: 1px;
    color: #afafaf;
    
    &:hover {
      cursor: pointer;
      color: #333;
      // background: rgb(59, 130, 246);
    }
  }
`;

const MultiSelectSuggestions = () => {
  const {
    setLoading,
    input,
    inputRef,
    suggestions,
    setNoResults,
    setInput,
    setSuggestions,
    setSelectOptions,
    noResults,
    isPersonal
  } = useContext(MultiSelectContext);

  const fetchSuggestions = useCallback(async (personal) => {
    try {
      setLoading(true);
      const requestConfig = {
        params: {
          [personal ? "email" : "business_name"]: input
        }
      };
      const url = `merchants${personal ? "" : "/business"}`;
      const res = await Axios.get(url, requestConfig);

      if (res?.data?.data.length > 0) {
        setNoResults(false);
        setSuggestions([]);
        setSuggestions(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        setNoResults(true);
      }
    } catch (e) {
      setLoading(false);
      setNoResults(true);
    }

  }, [
    input,
    setLoading,
    setNoResults,
    setSuggestions
  ]);

  useEffect(() => {
    if (input) {
      // console.log("Switching Endpoints");
      fetchSuggestions(isPersonal);
    }
  }, [isPersonal, input, fetchSuggestions]);

  function addRecipient(recipient) {
    setSelectOptions(options => [...options, recipient]);
    // abstract to clearInput function
    setInput('');
    setSuggestions([]);
    setNoResults(false);
    inputRef.current?.focus();
  }

  return (
    <React.Fragment>
      {noResults
        ? (
          <NoResult />
        )
        :
        (
          <SMultiSelectSuggestions>
            {suggestions.map(suggestion => {

              return (
                <li
                  onClick={() => addRecipient(suggestion)}
                  key={suggestion.user_uuid || suggestion.business_uuid}
                >
                  <span>
                    {suggestion.business_name || `${suggestion.firstname} ${suggestion.lastname}`}
                  </span>
                  <p>{suggestion.email || suggestion.business_email}</p>
                </li>
              );
            })}
          </SMultiSelectSuggestions>
        )
      }
    </React.Fragment>
  );
};

export default MultiSelectSuggestions;

import { 
  GET_ADMIN_USERS_START,
  GET_ADMIN_USERS_FAILURE,
  GET_ADMIN_USERS_SUCCESS, 
  GET_SINGLE_ADMIN_USER_START, 
  GET_SINGLE_ADMIN_USER_FAILURE, 
  GET_SINGLE_ADMIN_USER_SUCCESS,

 } from "./types"

const initialState = {
  isLoadingAdminUsers: true,
  isLoadingSingleAdmin: true,
  allAdmins: [],
  singleAdmin: {}
}

export default function filtersReducer(state = initialState, action) {
  switch(action.type) {
    case GET_ADMIN_USERS_START:
      return {
        ...state,
        isLoadingAdminUsers: true
      }
    
    case GET_ADMIN_USERS_FAILURE:
      return {
        ...state,
        isLoadingAdminUsers: false
      }

    case GET_ADMIN_USERS_SUCCESS: 
      return {
        ...state,
        isLoadingAdminUsers: false,
        allAdmins: action.payLoad
      }
    
    case GET_SINGLE_ADMIN_USER_FAILURE:
      return {
        ...state,
        isLoadingSingleAdmin: false
      }

    case GET_SINGLE_ADMIN_USER_START:
      return {
        ...state,
        isLoadingSingleAdmin: true
      }

    case GET_SINGLE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        isLoadingSingleAdmin: false,
        singleAdmin: action.payLoad
      }
    default: 
      return state
  }
}
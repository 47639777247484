import React, { Fragment, useState } from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { PopoverButton, PopoverMain } from '../popovers/Popover';

const BasicIndicatorWithOptions = ({ title, type, style = {}, data, test }) => {
    const [upArrow, setUpArrow] = useState(false);
    return (
        <Fragment>
            {type === 'not-delivered' ?
                <div className="p-3 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-yellow-100 glade-yellow capitalize " style={style}>
                    <span className="glade-yellow">{title}</span>
                    <PopoverMain
                        button={!upArrow ? <RiArrowDropDownLine className="glade-yellow" size={28} /> : <RiArrowDropUpLine className="glade-yellow" size={28} onClick={() => setUpArrow(false)} />}
                        popoverClassName="p-4 w-40 -left-20 border bg-white shadow-md top-10 rounded"
                    >
                        <div className="py-6 px-1">
                            <PopoverButton><p className='glade-green mb-6 cursor-pointer' onClick={() => test(data, 'delivered')}>Delivered</p></PopoverButton>
                            <PopoverButton><p className='glade-red cursor-pointer' onClick={() => test(data, 'rejected')}>Rejected</p></PopoverButton>
                        </div>
                    </PopoverMain>
                </div>
                : null
            }
            {type === 'success' ?
                <div className="p-3 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-green-100 glade-green capitalize " style={style}>
                    <span className="glade-green-light">{title} </span>
                    <PopoverMain
                        button={!upArrow ? <RiArrowDropDownLine className="glade-green-light" size={28} /> : <RiArrowDropUpLine className="glade-yellow" size={28} onClick={() => setUpArrow(false)} />}
                        popoverClassName="p-4 w-40 -left-20 border bg-white shadow-md top-10 rounded"
                    >
                        <div className="py-6 px-1">
                            <PopoverButton><p className='glade-red cursor-pointer' onClick={() => test(data, 'failed')}>Failed</p></PopoverButton>
                        </div>
                    </PopoverMain>

                </div>
                : null}
            {type === 'warning' ?
                <div className="p-3 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-yellow-100 glade-yellow capitalize " style={style}>
                    <span className="glade-yellow">{title}</span>
                    <PopoverMain
                        button={!upArrow ? <RiArrowDropDownLine className="glade-yellow" size={28} /> : <RiArrowDropUpLine className="glade-yellow" size={28} onClick={() => setUpArrow(false)} />}
                        popoverClassName="p-4 w-40 -left-20 border bg-white shadow-md top-10 rounded"
                    >
                        <div className="py-6 px-1">
                            <PopoverButton><p className='glade-green mb-6 cursor-pointer' onClick={() => test(data, 'successful')}>Successful</p></PopoverButton>
                            <PopoverButton><p className='glade-red cursor-pointer' onClick={() => test(data, 'failed')}>Failed</p></PopoverButton>
                        </div>
                    </PopoverMain>
                </div>
                : null}
            {type === 'inactive' ?
                <div className="p-4 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-black-100 glade-black capitalize " style={style}>
                    <span className="glade-black">{title}</span>
                    <RiArrowDropDownLine className="glade-black" size={28} />
                </div>
                : null}
            {type === 'neutral' ?
                <div className="p-3 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-black-100 glade-black capitalize " style={style}>
                    <span className="glade-black">{title}</span>
                    <RiArrowDropDownLine className="glade-black" size={28} />
                </div>
                : null}
            {type === 'danger' ?
                <div className="p-3 h-4 inline-flex items-center rounded-full glade-normal-text-two glade-bg-red-100 glade-red capitalize " style={style}>
                    <span className="glade-red">{title}</span>
                    <PopoverMain
                        button={!upArrow ? <RiArrowDropDownLine className="glade-red" size={28} /> : <RiArrowDropUpLine className="glade-yellow" size={28} onClick={() => setUpArrow(false)} />}
                        popoverClassName="p-4 w-40 -left-20 border bg-white shadow-md top-10 rounded"
                    >
                        <div className="py-6 px-1">
                            <PopoverButton><p className='glade-green mb-6 cursor-pointer' onClick={() => test(data, 'successful')}>Successful</p></PopoverButton>
                        </div>
                    </PopoverMain>
                </div>
                : null}

        </Fragment>
    );
}
export default BasicIndicatorWithOptions;
import { useSelector } from "react-redux";

const useShared = () => {
	const shared = useSelector((state) => state.shared);

	const { isLoading } = shared;

	return {
		isLoading,
	};
};

export default useShared;

import React from "react";
import { csvWorkbookDownload } from "./XlsxUtils";
import * as xlsx from "xlsx";
import Button from "../components/buttons/Button";

const handleDownloads = ({ fileName, format, data }) => {
	const getWorksheets = ({ xlsx }) => {
		return {
			data: xlsx.utils.json_to_sheet(data?.map(format)),
		};
	};
	(async () => {
		if (getWorksheets) {
			try {
				const workbook = xlsx.utils.book_new();
				const worksheets = await getWorksheets({ workbook, xlsx });
				if (worksheets) {
					for (const key in worksheets) {
						xlsx.utils.book_append_sheet(workbook, worksheets[key], key);
					}
					if (workbook.SheetNames.length) {
						csvWorkbookDownload(workbook, fileName);
					}
				}
			} catch (error) {
				console.log(error);
			}
		}
	})();
};

/**
 *
 * @param {DownloadAsCSVButtonProps<[]>} props
 */
const DownloadAsCSVButton = (props) => {
	const {
		fileName,
		disabled,
		getWorksheets,
		buttonVariant,
		CSVCallback,
		...rest
	} = props;

	// const { enqueueSnackbar } = useSnackbar();
	const [{ isLoading }, setStatus] = React.useState({
		isLoading: false,
		isError: false,
	});

	return (
		<Button.GladeBlue
			title="Download CSV"
			disabled={isLoading || disabled}
			onClick={async () => {
				if (CSVCallback && typeof CSVCallback === "function") {
					CSVCallback(handleDownloads);
				} else {
					if (getWorksheets) {
						try {
							setStatus((p) => ({ ...p, isLoading: true, isError: false }));
							const workbook = xlsx.utils.book_new();
							const worksheets = await getWorksheets({ workbook, xlsx });
							if (worksheets) {
								for (const key in worksheets) {
									xlsx.utils.book_append_sheet(workbook, worksheets[key], key);
								}
								if (workbook.SheetNames.length) {
									csvWorkbookDownload(workbook, fileName);
								}
							}
						} catch (error) {
							console.log(error);
							setStatus((p) => ({ ...p, isError: true }));
							// enqueueSnackbar(error?.message, { variant: "error" });
						}
						setStatus((p) => ({ ...p, isLoading: false }));
					}
				}
			}}
			{...rest}
		/>
	);
};

DownloadAsCSVButton.defaultProps = {
	columns: [],
};

export default DownloadAsCSVButton;

/**
 * @template {any[]} T
 * @typedef {{
 * fileName: string;
 * getWorksheets: (props: {workbook: import("xlsx").WorkBook, xlsx: typeof xlsx }) => Object<import("xlsx").WorkSheet>
 * } & React.ComponentPropsWithoutRef<typeof DownloadButton> }  DownloadAsCSVButtonProps
 */

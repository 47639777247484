import React, { useEffect, useRef, useState } from "react";
// import lodash from "lodash";
import styled from "styled-components";
import { BsSearch } from "react-icons/bs";
import { debounce } from "../../helpers/helper";
import { IoMdClose } from "react-icons/io";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { noSeparatorDarkSelectStyle } from "../../helpers/select_style";
// import NumberFormat from "react-number-format";

const SearchInputStyle = styled.div`
	width: ${(props) => props.width || "320px"};
`;

const primarySelectOptions = [
	{
		label: "Account Name",
		value: "account_name",
	},
	{
		label: "Transaction Reference",
		value: "txn_ref",
	},
	{
		label: "RRN",
		value: "rrn",
	},
];

export default function POSInflowSearchInput({
	placeholder,
	onChange,
	defaultFilterParams = {},
}) {
	const inputRef = useRef(null);
	const amountInputRef = React.createRef();

	const [, setType] = useState("searchType");

	const { control, watch, setValue } = useForm({
		defaultValues: {
			type: {
				label: "Account Name",
				value: "account_name",
			},
		},
	});

	const clearInput = () => {
		if (inputRef?.current) {
			inputRef.current.value = "";
			setValue("search", "");
			resetReferenceSearch();
		}
		if (amountInputRef?.current) {
			amountInputRef.current.value = "";
			setValue("amount", "");
			resetReferenceSearch();
		}
	};

	const filterInstance = usePaginationFilterParams();

	const { urlSearchParamsHistory } = filterInstance;

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	const searchType = watch("type");

	useEffect(() => {
		setType(searchType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchType]);

	const submit = (_value) => {
		let param = searchType?.value;
		// console.log(_value, param, search, amount);
		let value = _value;

		if (param === "amount") {
			if (_value === 0) {
				param = "search";
				value = "";
			} else {
				value = _value;
			}
		} else {
			if (_value === "") {
				param = "search";
				value = "";
			} else {
				param = searchType?.value;
				value = _value.trim();
			}
		}
		// onChange({ [param]: value });
		onChange({ search: value });
		// debounce(onChange({ [param]: value }), 2000);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	// useMemo(() => debounce(submit(), 2000), [search, amount]);

	return (
		<div className=" flex border-r-0 border-gray-500 rounded-sm glade-normal-text-two">
			<div className="w-40">
				<Controller
					control={control}
					id="type"
					name="type"
					render={({ field: { onChange, value, ref } }) => (
						<Select
							styles={noSeparatorDarkSelectStyle}
							inputRef={ref}
							autoComplete="true"
							options={primarySelectOptions}
							value={value}
							onChange={onChange}
							className="w-full"
						// isSearchable
						/>
					)}
					defaultValue={{
						label: "Account Name",
						value: "account_name",
					}}
				/>
			</div>

			{/* {lodash.isEqual(type, {
				label: "Amount",
				value: "amount",
			}) ? (
				<div className="relative">
					{amountInputRef.current?.value?.length === 0 ? (
						<div className="absolute z-10 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
							<span className="glade-ash sm:text-sm pb-1 relative">
								<BsSearch className="pointer-events-none" />
							</span>
						</div>
					) : (
						<div className="absolute z-10 inset-y-0 right-0 pr-3 flex items-center">
							<span className="glade-ash sm:text-sm pb-1 relative">
								<IoMdClose
									className="mt-2 cursor-pointer relative z-50"
									onClick={clearInput}
								/>
							</span>
						</div>
					)}
					<NumberFormat
						thousandSeparator={true}
						onValueChange={debounce((v) => submit(v.value), 2000)}
						variant="outlined"
						defaultValue=""
						getInputRef={amountInputRef}
						placeholder="Enter amount"
						className="block w-80 py-2 border-gray-300 rounded-sm glade-normal-text-two"
					/>
				</div>
			) : ( */}
			<SearchInputStyle className="relative rounded-sm">
				{inputRef.current?.value?.length === 0 ? (
					<div className="absolute z-10 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<span className="glade-ash sm:text-sm pb-1 relative">
							<BsSearch className="pointer-events-none" />
						</span>
					</div>
				) : (
					<div className="absolute z-10 inset-y-0 right-0 pr-3 flex items-center">
						<span className="glade-ash sm:text-sm pb-1 relative">
							<IoMdClose
								className="mt-2 cursor-pointer relative z-50"
								onClick={clearInput}
							/>
						</span>
					</div>
				)}
				<input
					type="text"
					ref={inputRef}
					name="search"
					id="search"
					onChange={debounce((e) => submit(e.target.value), 2000)}
					placeholder={placeholder ?? "Search"}
					className="glade-normal-text-two search-input glade-black block w-full pr-7 focus:ring-blue-500 focus:border-blue-500 border-gray-300 border-l-0 rounded-sm"
				/>
			</SearchInputStyle>
			{/* )} */}
		</div>
	);
}

import { SET_ALERT, CLEAR_ALERT } from './types';

const initialState = {
    alerts: [],
}

export default function alertReducer (state=initialState, action) {

    switch (action.type) {
        case SET_ALERT:
            return {
                ...state,
                alerts:[...state.alerts,action.payLoad],
            }

        case CLEAR_ALERT:
            return {
                ...state,
                alerts:[]
            }

        default:
            return state;
    }
}
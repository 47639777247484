import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RiLoader5Fill } from "react-icons/ri";

function LoadingModal({
  visibility = false,
  primaryButton,
  primaryButtonStyle,
  primaryButtonClassName,
  primaryButtonTitle,
  onClose,
  onOpen,
}) {
  let [isOpen, setIsOpen] = React.useState(visibility);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ?? (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={onOpen || openModal}
            className={
              "glade-normal-text-three glade-button-blue w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle ?? "Title"}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={onClose || closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center flex justify-center items-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="flex items-center justify-center p-20">
                <RiLoader5Fill color="#FFFFFF" size={50} className="animate-spin ml-4" />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
}

const MemoizedLoadingModal = React.memo(LoadingModal);
export default MemoizedLoadingModal;

import React from "react";
import { GLADE_EMPTY_FOLDER_ICON } from "../../assets/images/images";
import { TextSkeleton } from "../skeletons";

const PersonalComplianceCard = ({ details, loading }) => {
    return (
        <div className="flex flex-col bg-white rounded-sm px-8 py-4 mb-6">
            <div className="py-3 border-b-2 border-solid border-gray-100">
                <p className="glade-black font-bold">Compliance Details</p>
            </div>
            {loading ? (
                <div className="grid grid-cols-4 gap-4 py-3">
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                            Date of Birth
                        </h5>
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            <TextSkeleton />
                        </h6>
                    </div>
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                            Residential Address
                        </h5>
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            <TextSkeleton />
                        </h6>
                    </div>
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                            State
                        </h5>
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            <TextSkeleton />
                        </h6>
                    </div>
                    <div className="flex flex-col">
                        <h5 className="glade-normal-text-two glade-ash py-1">
                            LGA
                        </h5>
                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                            <TextSkeleton />
                        </h6>
                    </div>
                </div>
            ) : (
                <>
                    {details?.details?.length > 0 ? (
                        <div className="grid grid-cols-4 gap-4 py-3">
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                    Date of Birth
                                </h5>
                                {!loading && details?.submission?.id ? (
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {(details?.submission?.dob || details?.details?.find(item => item.title?.toLowerCase() === "dob")?.compliance_data) ?? "N/A"}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                    Residential Address
                                </h5>
                                {!loading && details?.submission?.id ? (
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {details?.submission?.business_address ||
                                            details?.submission?.address}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                    State
                                </h5>
                                {!loading && details?.submission?.state ? (
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {details?.submission?.state}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">LGA</h5>
                                {!loading ? (
                                    // <>{details?.submission?.lga && (
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {details?.submission?.lga}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>

                            {details?.details?.filter(item => item.title?.toLowerCase() !== "dob").map((detail) => (
                                <div className="flex flex-col">
                                    <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                                        {detail?.title?.split("_").join(" ")}
                                    </h5>
                                    {detail.compliance_data || detail.file_url ? (
                                        <>
                                            {detail.type === "file" ? (
                                                <>
                                                    <a
                                                        href={detail.file_url}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        <h6 className="glade-normal-text-three glade-blue py-1 cursor-pointer capitalize">
                                                            view document
                                                        </h6>
                                                    </a>
                                                </>
                                            ) : (
                                                <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                                    {detail?.compliance_data?.split("_").join(" ")}
                                                </h6>
                                            )}
                                        </>
                                    ) : (
                                        <TextSkeleton />
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="flex items-center justify-center flex-col py-20 lg:py-32">
                            <img
                                src={GLADE_EMPTY_FOLDER_ICON}
                                alt=""
                                className="h-12 w-14"
                            />
                            <p className="glade-normal-text-one glade-black">
                                No compliance
                            </p>
                            <p className="glade-normal-text-two glade-black">
                                User has not submitted any compliance
                            </p>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default PersonalComplianceCard;
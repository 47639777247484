import React from 'react';

export default function IndividualInput({numberOfInputTags=6, returnInput=()=>{}}) {

    const [input, setInput] = React.useState('');

    /**
     * Handle user key inputs 
     * including copy and paste
     * @param {event} e 
     */
    const processInput = (e) => {
        // Ensure that the input length is not more than the available inputs tags
        if (input.length < numberOfInputTags) {

            // Check if input length is more than one, which means its either a copy and paste or a second entry
            if (e.target.value.length > 1) {

                // Check if input length is more than two, which confirm its a copy and paste
                if (e.target.value.length > 2) {
                    setInput(input+e.target.value);
                    returnInput(input+e.target.value.substring(0,numberOfInputTags));
                } else {
                    setInput(input+e.target.value.substring(1));
                    returnInput(input+e.target.value.substring(1).substring(0,numberOfInputTags));
                }

            } else {
                setInput(input+e.target.value);
                returnInput(input+e.target.value.substring(0,numberOfInputTags));
            }
        }
    }

    /**
     * Handle user clear/delete inputs as onchange
     * can not track key down inputs
     * @param {event} e 
     */
    const clearInput = (e) => {
        let key = e.keyCode || e.charCode || e.key;

        if (["Backspace", "Delete", 8, 46].includes(key)) {
            setInput(input.substring(0,numberOfInputTags).slice(0,-1));
            returnInput(input.substring(0,numberOfInputTags).slice(0,-1));
        }
    }

    const renderInputTags = () => {
        let inputs = [];
        for (let index = 0; index < numberOfInputTags; index++) {
            inputs.push(
                <input
                    key={index}
                    name="single-input"
                    type="number"
                    min="0"
                    value={input.charAt(index)}
                    onChange={(e)=>processInput(e)}
                    onKeyUp={(e)=>clearInput(e)}
                    className="appearance-none block py-2 pl-3.5 w-10 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
            );
        }
        return inputs;
    }

    return (
        <div className="flex space-x-4">
            {renderInputTags()}
        </div>
    )
}

import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import {
  GET_ACTIVE_USERS_REPORT_FAILURE,
  GET_ACTIVE_USERS_REPORT_START,
  GET_ACTIVE_USERS_REPORT_SUCCESS,
  GET_MOST_USED_SERVICES_FAILURE,
  GET_MOST_USED_SERVICES_START,
  GET_MOST_USED_SERVICES_SUCCESS,
  GET_TRANSACTION_STATUS_FAILURE,
  GET_TRANSACTION_STATUS_START,
  GET_TRANSACTION_STATUS_SUCCESS,
  GET_TRANSACTION_SUMMARY_FAILURE,
  GET_TRANSACTION_SUMMARY_START,
  GET_TRANSACTION_SUMMARY_SUCCESS,
} from "./types";

export const getTransactionSummary = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_TRANSACTION_SUMMARY_START });
    Axios.get(`reports/analytics`, params)
      .then((res) => {
        dispatch({
          type: GET_TRANSACTION_SUMMARY_SUCCESS,
          payLoad: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_TRANSACTION_SUMMARY_FAILURE, payLoad: error });
        handler(error);
      });
  };
};

export const getTransactionStatus = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_TRANSACTION_STATUS_START });
    Axios.get(`reports/status-report`, params)
      .then((res) => {
        dispatch({
          type: GET_TRANSACTION_STATUS_SUCCESS,
          payLoad: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_TRANSACTION_STATUS_FAILURE, payLoad: error });
        handler(error);
      });
  };
};

export const getActiveUsersReport = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_ACTIVE_USERS_REPORT_START });
    Axios.get(`reports/active-users`, params)
      .then((res) => {
        dispatch({
          type: GET_ACTIVE_USERS_REPORT_SUCCESS,
          payLoad: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_ACTIVE_USERS_REPORT_FAILURE, payLoad: error });
        handler(error);
      });
  };
};

export const getMostUsedServices = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_MOST_USED_SERVICES_START });
    Axios.get(`reports/service-report`, params)
      .then((res) => {
        dispatch({
          type: GET_MOST_USED_SERVICES_SUCCESS,
          payLoad: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_MOST_USED_SERVICES_FAILURE, payLoad: error });
        handler(error);
      });
  };
};

import React from "react";

import { TextSkeleton } from "../../components/skeletons";
import useHome from "../../hooks/useHome";
// import TransactionSummaryFilter from "./TransactionSummaryFilter";

const TransactionSummary = () => {
  const { isLoadingTransactionSummary, transactionSummary } = useHome();
  const {
    report,
  } = transactionSummary;

  const transaction_volume =
    Number(report?.transaction_summary.transactions?.bills?.volume) +
    Number(report?.transaction_summary.transactions?.others?.volume) +
    Number(report?.transaction_summary.transactions?.pos?.volume) +
    Number(report?.transaction_summary.transactions?.transfer?.volume);

  return (
    <div className="flex flex-col flex-1 p-6 bg-white rounded">
      <div className="flex row justify-between mb-6 pb-1 border-b">
        <h6 className="glade-normal-text-light glade-black">Transaction Summary</h6>

        {/* <TransactionSummaryFilter /> */}
      </div>
      <div className="text-center mb-3">
        {isLoadingTransactionSummary ? (
          <TextSkeleton className="mt-2 mx-auto" width="200px" />
        ) : (
          <>
            <h3 className="glade-heading-three glade-black">
              ₦{report?.transaction_summary?.total_value}
            </h3>
            <h5 className="glade-normal-text-two glade-ash py-1">
              {transaction_volume} transactions
            </h5>
          </>
        )}
      </div>
      <div className="flex row justify-between items-center my-2 pb-2 border-b">
        <div>
          <h6 className="glade-normal-text-two glade-black">Transfers</h6>
          {isLoadingTransactionSummary ? (
            <TextSkeleton className="mt-2" width="100px" />
          ) : (
            <h5 className="glade-normal-text-two glade-ash">
              {report?.transaction_summary?.transactions?.transfer?.volume ?? 0} Transactions
            </h5>
          )}
        </div>
        {isLoadingTransactionSummary ? (
          <TextSkeleton className="mt-2" width="100px" />
        ) : (
          <span className="glade-subtitle-three glade-black">
            ₦
            {Number(
              report?.transaction_summary?.transactions?.transfer?.value,
            ).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}) ?? 0}
          </span>
        )}
      </div>
      <div className="flex row justify-between items-center my-2 pb-2 border-b">
        <div>
          <h6 className="glade-normal-text-two glade-black">POS</h6>
          {isLoadingTransactionSummary ? (
            <TextSkeleton className="mt-2" width="100px" />
          ) : (
            <h5 className="glade-normal-text-two glade-ash">
              {report?.transaction_summary?.transactions?.pos?.volume ?? 0} Transactions
            </h5>
          )}
        </div>
        {isLoadingTransactionSummary ? (
          <TextSkeleton className="mt-2" width="100px" />
        ) : (
          <span className="glade-subtitle-three glade-black">
            ₦
            {Number(
              report?.transaction_summary?.transactions?.pos?.value,
            ).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}) ?? 0}
          </span>
        )}
      </div>
      <div className="flex row justify-between items-center my-2 pb-2 border-b">
        <div>
          <h6 className="glade-normal-text-two glade-black">Bills</h6>
          {isLoadingTransactionSummary ? (
            <TextSkeleton className="mt-2" width="100px" />
          ) : (
            <h5 className="glade-normal-text-two glade-ash">
              {report?.transaction_summary?.transactions?.bills?.volume ?? 0} Transactions
            </h5>
          )}
        </div>
        {isLoadingTransactionSummary ? (
          <TextSkeleton className="mt-2" width="100px" />
        ) : (
          <span className="glade-subtitle-three glade-black">
            ₦
            {Number(
              report?.transaction_summary?.transactions?.bills?.value,
            ).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}) ?? 0}
          </span>
        )}
      </div>
      <div className="flex row justify-between items-center my-2 pb-2">
        <div>
          <h6 className="glade-normal-text-two glade-black">Others</h6>
          {isLoadingTransactionSummary ? (
            <TextSkeleton className="mt-2" width="100px" />
          ) : (
            <h5 className="glade-normal-text-two glade-ash">
              {report?.transaction_summary?.transactions?.others?.volume ?? 0} Transactions
            </h5>
          )}
        </div>
        {isLoadingTransactionSummary ? (
          <TextSkeleton className="mt-2" width="100px" />
        ) : (
          <span className="glade-subtitle-three glade-black">
            ₦
            {Number(
              report?.transaction_summary?.transactions?.others?.value,
            ).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}) ?? 0}
          </span>
        )}
      </div>
    </div>
  );
};

export default TransactionSummary;

import React from "react";
import { Popover } from "@headlessui/react";

export function PopoverButton({ children }) {
  return <Popover.Button as={"span"}>{children}</Popover.Button>;
}

export function PopoverMain({ button, children, popoverClassName }) {
  return (
    <Popover className="relative">
      <Popover.Button as={"span"}>{button}</Popover.Button>
      <Popover.Panel className={"absolute z-10 " + popoverClassName}>
        {children}
      </Popover.Panel>
    </Popover>
  );
}

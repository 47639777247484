import React, { useState } from "react";
import * as dfn from "date-fns";
import { FiCopy } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { TextSkeleton } from "../../components/skeletons";
import {
	copyTextToClipboard,
	fixDateForAllBrowsers,
	statusEnum,
	truncateRef,
} from "../../helpers/helper";
import useTransactions from "../../hooks/useTransactions";

import { getSpecificPayrollTransactions } from "../../redux/transactions/transactionsActions";
import { Table } from "../../components/table/Table";
import CircleIndicator from "../../components/indicators/CircleIndicator";

export default function PayrollTransactionDetail() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);

	const {
		isLoadingSpecificPayrollTransaction: loading,
		specificPayrollTransactions: details,
	} = useTransactions();

	React.useEffect(() => {
		dispatch(getSpecificPayrollTransactions(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<div className="p-3 flex flex-col">
			<div className="mb-6">
				<BreadCrumbs title={"Transactions"} goBack />
			</div>

			{/* Account Details */}
			<div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">Account Details</p>
				</div>
				<div className="grid grid-cols-4 gap-4 py-3">
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Name
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.account_information?.account_name}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Email
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.account_information?.account_email}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Phone Number
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.account_information?.account_phone ?? "Not available"}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Type
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1 capitalize">
								{details?.account_information?.account_type}
							</h6>
						)}
					</div>
				</div>
			</div>

			{/* Payroll Details */}
			<div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">Payroll Details</p>
				</div>
				<div className="grid grid-cols-4 gap-4 py-3">
					{/* {details?.payroll_details?.payroll_id && ( */}
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Payroll ID</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<div className="flex items-center">
								{details?.payroll_details?.payroll_id ? (
									<>
										<h6 className="glade-normal-text-three glade-black py-1">
											{truncateRef(details?.payroll_details?.payroll_id)}
										</h6>
										<FiCopy
											size={16}
											color="#AFAFAF"
											className="ml-3 cursor-pointer"
											onClick={() =>
												copyTextToClipboard(
													details?.payroll_details?.payroll_id
												)
											}
										/>
									</>
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										Not available
									</h6>
								)}
							</div>
						)}
					</div>
					{/* )} */}
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Total Staff
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.payroll_details?.total_staff}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Amount</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<>
								{details?.payroll_details?.total_fee ? (
									<h6 className="glade-normal-text-three glade-black py-1">
										{details?.payroll_details?.currency ?? "NGN"}{" "}
										{Number(
											details?.payroll_details?.total_amount
										)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
									</h6>
								) : (
									<h6 className="glade-normal-text-three glade-black py-1">
										Not available
									</h6>
								)}
							</>
						)}
					</div>
					{/* {details?.payroll_details?.date && ( */}
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Date</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.payroll_details?.date ? (
									<>
										{dfn.format(
											new Date(details?.payroll_details?.date),
											"MMM d, yyyy"
										)}
									</>
								) : (
									"Not available"
								)}
							</h6>
						)}
					</div>
					{/* )} */}
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Description
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{details?.payroll_details?.description}
							</h6>
						)}
					</div>
					{details?.payroll_details?.total_fee && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">Fees</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<>
									{details?.payroll_details?.total_fee ? (
										<h6 className="glade-normal-text-three glade-black py-1">
											{details?.payroll_details?.currency ?? "NGN"}{" "}
											{Number(
												details?.payroll_details?.total_fee
											)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
										</h6>
									) : (
										<h6 className="glade-normal-text-three glade-black py-1">
											Not available
										</h6>
									)}
								</>
							)}
						</div>
					)}
				</div>
			</div>

			{/* Transactions Table */}
			<div className="py-5 px-10 bg-white rounded-t-md">
				<p className="glade-subtitle-two glade-black">{`Transactions ${
					!loading && details?.transaction_details
						? "- " + details?.transaction_details?.length
						: ""
				}`}</p>
			</div>
			<Table
				columns={columns}
				data={
					typeof details?.transaction_details === "object" &&
					!Array.isArray(details?.transaction_details)
						? details?.transaction_details
						: details?.transaction_details
				}
				empty_message="No transaction"
				empty_sub_message=""
				current_page={currentPage}
				loading={loading}
				setCurrentPage={setCurrentPage}
			/>
		</div>
	);
}

const columns = [
	{
		accessor: "status",
		Header: "",
		style: {
			paddingLeft: 0,
			paddingRight: "10px",
			width: "50px",
		},
		Cell: ({ value }) => (
			<React.Fragment>
				<CircleIndicator
					className="py-1 mx-auto capitalize"
					type={statusEnum[value]}
					style={{
						marginLeft: "auto",
						marginRight: "auto",
						display: "block",
					}}
				/>
			</React.Fragment>
		),
	},
	{
		accessor: "reference",
		Header: "Transaction Reference",
		Cell: ({ value, row }) => (
			<div className="flex items-center">
				<Link
					to={`/dashboard/outflows/transfers/detail/${row.original.transaction_id}`}
				>
					<span className="glade-normal-text-two">{truncateRef(value)}</span>
				</Link>
				<FiCopy
					size={16}
					color="#AFAFAF"
					className="ml-3 cursor-pointer"
					onClick={() => copyTextToClipboard(value)}
				/>
			</div>
		),
	},
	{
		accessor: "amount",
		Header: "Amount",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/outflows/transfers/detail/${row.original.transaction_id}`}
			>
				<span className="glade-normal-text-two">
					{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			</Link>
		),
	},
	{
		accessor: "receipient.account_number",
		Header: "Recipient",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/outflows/transfers/detail/${row.original.transaction_id}`}
			>
				<span className="glade-normal-text-two block">{value}</span>
				<span className="glade-normal-text-two block">
					{row.original.receipient?.bank_name}
				</span>
			</Link>
		),
	},
	{
		accessor: "transaction_date",
		Header: "Transaction Date",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/outflows/transfers/detail/${row.original.transaction_id}`}
			>
				<span className="glade-normal-text-two">
					{dfn.format(new Date(fixDateForAllBrowsers(value)), "MMM d, yyyy hh:mm:ss a")}
				</span>
			</Link>
		),
	},
];

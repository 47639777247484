import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function SidebarCollapsibleGroup({
	path,
	children,
	title,
	icon,
}) {
	const [current, setCurrent] = React.useState(false);
	const pageLink = window.location.href;

	React.useEffect(() => {
		pageLink.includes(path) ? setCurrent(true) : setCurrent(false);
	}, [path, pageLink]);
  
	const [isExpanded, setIsExpanded] = useState(current);

	React.useEffect(() => {
		setIsExpanded(current);
	}, [current]);

	const toggleExpanded = () => setIsExpanded(!isExpanded);

	return (
		<React.Fragment>
			{/* Heading */}
			<div
				className={`flex items-center text-white justify-between cursor-pointer`}
				onClick={toggleExpanded}
			>
				<div className="flex items-center">
					{!current ? (
						<span className="flex items-center text-white opacity-60 hover:opacity-100">
							<span className={"glade-normal-text-two inline-flex"}>
								{icon && React.cloneElement(icon)}
							</span>
							<span
								className={
									"glade-normal-text-two inline-flex ml-3 whitespace-nowrap"
								}
							>
								{title}
							</span>
						</span>
					) : (
						<span className="flex items-center text-white opacity-100">
							<span className={"glade-normal-text-two inline-flex"}>
								{icon && React.cloneElement(icon)}
							</span>
							<span
								className={
									"glade-normal-text-two inline-flex ml-3 whitespace-nowrap"
								}
							>
								{title}
							</span>
						</span>
					)}
				</div>
				{isExpanded ? (
					<span className={"glade-normal-text-two inline-flex text-white"}>
						<FiChevronUp color="#FFFFFF" />
					</span>
				) : (
					<span
						className={
							"glade-normal-text-two inline-flex text-white opacity-60 hover:opacity-100"
						}
					>
						<FiChevronDown />
					</span>
				)}
			</div>

			{/* Navigation */}
			{isExpanded && <div className="flex flex-col pl-4 pt-2 ">{children}</div>}

			{/* Divider */}
			<div className="my-4 md:min-w-full" />
		</React.Fragment>
	);
}

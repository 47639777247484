import useURLSearchParamsHistory from "./useURLSearchParamsHistory";

/**
 * @template {{[x: string]: any}} T
 * @param {UseURLFiltersOptions<T>} options
 */
function useURLFilterParams(options) {
  const { initialFilters = /** @type {T} */ ({}) } = options;
  const urlSearchParamsHistory = useURLSearchParamsHistory();

  const filters = getFilters();

  function getFilters() {
    /** @type {T} */
    const _result = {};
    for (const key in initialFilters) {
      _result[key] =
        urlSearchParamsHistory.searchParams.get(key) || initialFilters[key];
    }
    return _result;
  }

  function handleChange(params = {}) {
    const _result = {};
    for (const key in params) {
      _result[key] = params[key] || filters[key];
    }
    urlSearchParamsHistory.replace(_result);
  }


  return { filters, urlSearchParamsHistory, initialFilters, handleChange };
}

export default useURLFilterParams;

/**
 * @template T
 * @typedef {{initialFilters: T}} UseURLFiltersOptions<T>
 */

import React from 'react';
import {BsThreeDotsVertical} from 'react-icons/bs';

export default function ThreeDotButton({onClick=()=>{}}) {
    return (
        <button
            type="button"
            className="cursor-pointer glade-black"
            onClick={() => onClick()}>
            <BsThreeDotsVertical />
        </button>
    )
}

import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import isEqual from "lodash/isEqual";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import TabNav from "../../components/navbars/TabNav";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import useSettlements from "../../hooks/useSettlements";
import {
	getAllUnsettledUSDSettlements,
	getAllUnsettledUSDSettlementsForDownload,
	processMultipleUnsettledUSDSettlements,
} from "../../redux/settlements/settlementsActions";
import DownloadAsCSVButton from "../../helpers/DownloadAsCSV";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { FormError } from "../../validation";
import { fixDateForAllBrowsers } from "../../helpers/helper";

const ConfirmSettlementModal = ({
	setShowProcessSettlementModal,
	showProcessSettlementModal,
	selectedSettlements,
	totalSettlement,
	exchangeRate,
	isProcessingUnsettledSettlement,
}) => {
	const dispatch = useDispatch();

	const onProcessSettlements = () => {
		dispatch(
			processMultipleUnsettledUSDSettlements({
				rate: exchangeRate,
				data: selectedSettlements,
			})
		);
	};

	return (
		<BasicModal
			visibility={showProcessSettlementModal}
			onClose={() => setShowProcessSettlementModal(false)}
			onOpen={() => setShowProcessSettlementModal(true)}
			primaryButton=""
			modalTitle="Payout Confirmation"
			secondaryButton=""
			modelContentSection={
				<>
					<div className="py-4 px-0">
						<h4 className="text-base font-inter glade-black mb-6 text-center">
							Are you sure you want to settle{" "}
							<p className="font-bold text-lg">
								{selectedSettlements?.length} Settlement(s)
							</p>
						</h4>
						<h4 className="text-base font-inter glade-black mb-2 text-center">
							with
							<p className="font-bold text-lg">
								USD {Number(totalSettlement).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
							</p>
						</h4>
						<h4 className="text-base font-inter glade-black mb-6 text-center">
							as
							<p className="font-bold text-lg">
								USD{" "}
								{(
									Number(totalSettlement) * Number(exchangeRate)
								).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
							</p>
						</h4>

						<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
							<div>
								<Button.Gray
									type="button"
									title="Go Back"
									onClick={() => {
										setShowProcessSettlementModal(false);
									}}
									className="glade-normal-text-two glade-black text-center block flex-grow-0"
								/>
							</div>
							<div>
								<Button.Dark
									type="submit"
									title="Payout"
									className="block flex-grow-0"
									onClick={onProcessSettlements}
									loading={isProcessingUnsettledSettlement}
								/>
							</div>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default function USDUnsettledSettlements() {
	const dispatch = useDispatch();
	let history = useHistory();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const [showSettleModal, setShowSettleModal] = useState(false);
	const [showProcessSettlementModal, setShowProcessSettlementModal] =
		useState(false);
	const [showCannotSettleModal, setShowCannotSettleModal] = useState(false);
	const [selectedSettlements, setSelectedSettlements] = useState([]);
	const [canSettle, setCanSettle] = useState(false);
	const [cannotSettleCount, setCannotSettleCount] = useState(0);
	const [exchangeRate, setExchangeRate] = useState(null);
	const [totalSettlement, setTotalSettlement] = useState(0);

	const {
		isLoadingAllUnsettledUSDSettlements,
		allUnsettledUSDSettlements,
		isProcessingUnsettledSettlement,
	} = useSettlements();

	const defaultFilterParams = {
		search: "",
		page: currentPage,
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, search, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_from, date_to, search]);

	React.useMemo(() => {
		dispatch(
			getAllUnsettledUSDSettlements({
				params: {
					...(search
						? { search, paginate: 1, current_page: page, per_page: 10 }
						: {
								date_from,
								date_to,
								paginate: 1,
								current_page: page,
								per_page: 10,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		page,
		// per_page,
		search,
	]);

	React.useMemo(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	// Update selected draft notifications
	const updateSelectedSettlements = (settlement) => {
		if (checkIfSettlementIsSelected(settlement)) {
			const newList = selectedSettlements.filter(
				(_settlement) => !isEqual(_settlement, settlement)
			);
			setSelectedSettlements(newList);
		} else {
			const oldList = [...selectedSettlements];
			oldList.push(settlement);
			setSelectedSettlements(oldList);
		}
	};

	const checkIfAllSettlementsAreEligible = () => {
		let total = 0;
		for (let index = 0; index < selectedSettlements.length; index++) {
			const settlement = selectedSettlements[index];

			if (settlement?.amount_to_pay) {
				total += Number(settlement?.amount_to_pay);
			}

			if (
				dfn.isAfter(new Date(), dfn.addDays(new Date(settlement?.txn_date), 7))
			) {
				setCanSettle(true);
			} else {
				setCanSettle(false);
				setCannotSettleCount(cannotSettleCount + 1);
			}
		}
		setTotalSettlement(total);
	};

	useEffect(() => {
		if (selectedSettlements?.length > 0) {
			checkIfAllSettlementsAreEligible();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSettlements]);

	const checkIfSettlementIsSelected = (settlement) => {
		const settlementInArray = selectedSettlements.find((_settlement) =>
			isEqual(_settlement, settlement)
		);
		return !!settlementInArray;
	};

	const onClickOpen = (settlement) => {
		localStorage.setItem(
			"glade_selected_settlement",
			JSON.stringify({
				owner_uuid: settlement.owner_uuid,
				currency: settlement.currency,
				settlement_type: settlement.settlement_type,
				settlement_date: settlement.settlement_date,
				txn_date: settlement.txn_date,
			})
		);
		history.push({
			pathname: "/dashboard/settlements/unsettled/detail",
			state: {
				owner_uuid: settlement.owner_uuid,
				currency: settlement.currency,
				settlement_type: settlement.settlement_type,
				settlement_date: settlement.settlement_date,
				txn_date: settlement.txn_date,
			},
		});
	};

	const columns = [
		{
			accessor: "settlement_type",
			Header: "",
			Cell: ({ row }) => (
				<span className="glade-normal-text-two capitalize">
					<input
						type="checkbox"
						checked={checkIfSettlementIsSelected({
							owner_uuid: row.original.owner_uuid,
							currency: row.original.currency,
							settlement_type: row.original.settlement_type,
							settlement_date: row.original.settlement_date,
							amount_to_pay: row.original.amount_to_pay,
							txn_date: row.original.txn_date,
						})}
						onChange={() =>
							updateSelectedSettlements({
								owner_uuid: row.original.owner_uuid,
								currency: row.original.currency,
								settlement_type: row.original.settlement_type,
								settlement_date: row.original.settlement_date,
								amount_to_pay: row.original.amount_to_pay,
								txn_date: row.original.txn_date,
							})
						}
					/>
				</span>
			),
		},
		{
			accessor: "account_name",
			Header: "Account Name",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two capitalize cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{value}
				</span>
			),
		},
		{
			accessor: "no_of_txns",
			Header: "Number of Txns",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two capitalize cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{value}
				</span>
			),
		},
		{
			accessor: "total_amount",
			Header: "Total Amount",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{row.original.currency ?? "USD"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			),
		},
		{
			accessor: "fees",
			Header: "Fees",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{row.original.currency ?? "USD"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			),
		},
		{
			accessor: "amount_to_pay",
			Header: "Amount to pay",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{row.original.currency ?? "USD"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			),
		},
		// {
		// 	accessor: "currency",
		// 	Header: "Currency",
		// 	Cell: ({ value, row }) => (
		// 		<span
		// 			className="glade-normal-text-two capitalize cursor-pointer"
		// 			onClick={() => onClickOpen(row.original)}
		// 		>
		// 			{value}
		// 		</span>
		// 	),
		// },
		{
			accessor: "settlement_date",
			Header: "Settlement Date",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{dfn.format(
						new Date(fixDateForAllBrowsers(value)),
						"MMM d, yyyy hh:mm:ss a"
					)}
				</span>
			),
		},
		{
			accessor: "txn_date",
			Header: "Transaction Date",
			Cell: ({ value, row }) => (
				<span
					className="glade-normal-text-two cursor-pointer"
					onClick={() => onClickOpen(row.original)}
				>
					{dfn.format(
						new Date(fixDateForAllBrowsers(value)),
						"MMM d, yyyy hh:mm:ss a"
					)}
				</span>
			),
		},
	];

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			rate: "",
		},
	});

	const onSubmitExchangeRate = (data) => {
		setExchangeRate(data.rate);
		setShowProcessSettlementModal(true);
	};

	return (
		<div className="p-3">
			<TabNav
				tabs={[
					{ title: "Settled", path: "/dashboard/settlements/usd" },
					{ title: "Unsettled", path: "/dashboard/settlements/usd/unsettled" },
				]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				filterFields={["date"]}
				filterCallback={handleChange}
				disabled={isLoadingAllUnsettledUSDSettlements}
				title={`Settlements ${
					!isLoadingAllUnsettledUSDSettlements &&
					allUnsettledUSDSettlements?.total
						? "- " + allUnsettledUSDSettlements?.total
						: ""
				}`}
				actionButton={
					<div className="flex items-center">
						<Button.Dark
							title={"Settle Transactions"}
							disabled={selectedSettlements?.length === 0}
							onClick={() =>
								canSettle
									? setShowSettleModal(true)
									: setShowCannotSettleModal(true)
							}
						/>
						<div>
							<DownloadAsCSVButton
								fileName="Unsettled Settlements"
								CSVCallback={(handleDownload) =>
									dispatch(
										getAllUnsettledUSDSettlementsForDownload(
											{
												params: {
													...(search
														? {
																search,
																paginate: 0,
																current_page: page,
																per_page: 10,
														  }
														: {
																date_from,
																date_to,
																paginate: 0,
																current_page: page,
																per_page: 10,
														  }),
												},
											},
											(data) =>
												handleDownload({
													data,
													fileName: "USD Unsettled Settlements",
													format: (value, i) => ({
														"S/N": `${i + 1}`,
														"Account Name": value?.account_name,
														Currency: value?.currency,
														"Number of Txns": value?.no_of_txns,
														"Total Amount": value?.total_amount,
														Fees: value?.fees,
														"Amount to pay": value?.amount_to_pay,
														"Settlement Date": value?.settlement_date,
														"Transaction Date": value?.txn_date,
													}),
												})
										)
									)
								}
								disabled={isLoadingAllUnsettledUSDSettlements}
								className="whitespace-pre ml-4"
							/>
						</div>
					</div>
				}
				searchBarPlaceholder={"Account Name"}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof allUnsettledUSDSettlements?.data === "object" &&
					!Array.isArray(allUnsettledUSDSettlements?.data)
						? allUnsettledUSDSettlements?.data?.data
						: allUnsettledUSDSettlements?.data
				}
				empty_message="No settlement"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllUnsettledUSDSettlements}
				setCurrentPage={setCurrentPage}
				totalPages={allUnsettledUSDSettlements?.total}
			/>

			{/* Process modal */}
			<BasicModal
				visibility={showSettleModal}
				onClose={() => setShowSettleModal(false)}
				onOpen={() => setShowSettleModal(true)}
				primaryButton=""
				modalTitle="Payout Confirmation"
				secondaryButton=""
				modelContentSection={
					<>
						<form onSubmit={handleSubmit(onSubmitExchangeRate)} className="">
							<div className="py-4 px-0">
								<h4 className="text-base font-inter glade-black mb-6 text-center">
									Are you sure you want to settle{" "}
									<p className="font-bold text-lg">
										{selectedSettlements?.length} Settlement(s)
									</p>
								</h4>
								<h4 className="text-base font-inter glade-black mb-6 text-center">
									with
									<p className="font-bold text-lg">
										USD {Number(totalSettlement).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
									</p>
								</h4>

								<p className="text-xs font-inter glade-black mb-4 text-center">
									Enter the conversion value of 1 USD to NGN for this merchant
									settlement
								</p>

								<div className="relative">
									<div
										className="absolute inset-y-0 left-0 px-3 py-2.5 flex items-center pointer-events-none"
										style={{
											background: "#FBFBFB",
											border: "1px solid #E4E4F3",
										}}
									>
										<span className="glade-black text-sm py-2 font-inter">
											1 USD =
										</span>
									</div>
									<Controller
										render={({ field: { onChange, value } }) => (
											<NumberFormat
												thousandSeparator={true}
												onValueChange={(v) => {
													onChange(Number(v.value));
												}}
												variant="outlined"
												placeholder="Enter Amount"
												defaultValue=""
												value={value}
												className="mt-1 block w-full pl-24 py-2 border-gray-300 rounded-sm glade-normal-text-two"
											/>
										)}
										rules={{
											required: "Enter an amount",
											min: {
												value: 1,
												message: "Minimum amount is USD 1",
											},
										}}
										name="rate"
										control={control}
									/>
								</div>
								<FormError errors={errors} name="rate" />

								<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
									<button
										type="button"
										onClick={() => {
											reset();
											setShowSettleModal(false);
										}}
										className="glade-normal-text-two glade-black text-center"
									>
										Cancel
									</button>
									<div>
										<Button.Dark
											type="submit"
											title="Continue"
											className="block flex-grow-0"
											// onClick={onProcessSettlements}
											// loading={isProcessingUnsettledSettlement}
										/>
									</div>
								</div>
							</div>
						</form>

						{/* Confirm Process Modal */}
						<ConfirmSettlementModal
							setShowProcessSettlementModal={setShowProcessSettlementModal}
							showProcessSettlementModal={showProcessSettlementModal}
							selectedSettlements={selectedSettlements}
							totalSettlement={totalSettlement}
							exchangeRate={exchangeRate}
							isProcessingUnsettledSettlement={isProcessingUnsettledSettlement}
						/>
					</>
				}
			/>

			{/* Can't payout modal */}
			<BasicModal
				visibility={showCannotSettleModal}
				onClose={() => setShowCannotSettleModal(false)}
				onOpen={() => setShowCannotSettleModal(true)}
				primaryButton=""
				modalTitle="Payout Warning"
				secondaryButton=""
				modalStyle={{
					width: "357px",
				}}
				modelContentSection={
					<div
						className="p-2.5"
						style={{
							background: "#F9C9001F",
							border: "1px solid #F9C900",
						}}
					>
						<h3
							className="text-sm font-bold font-inter text-center mb-2.5"
							style={{
								color: "#F9C900",
							}}
						>
							Not up to a week
						</h3>
						<p className="glade-black text-sm font-inter text-center">
							Sorry, you can’t settle {cannotSettleCount} transaction(s) as
							dollar transactions can only be settled after 7 days.
						</p>
					</div>
				}
			/>
		</div>
	);
}

import {
  GET_REFERRAL_CONFIGURATION_FAILURE,
  GET_REFERRAL_CONFIGURATION_START,
  GET_REFERRAL_CONFIGURATION_SUCCESS,
  GET_ALL_USER_REFERRAL_FAILURE,
  GET_ALL_USER_REFERRAL_START,
  GET_ALL_USER_REFERRAL_SUCCESS,
  SUBMIT_REFERRAL_CONFIG_START,
  SUBMIT_REFERRAL_CONFIG_SUCCESS,
  SUBMIT_REFERRAL_CONFIG_FAILURE
} from "./types";

const initialState = {
  isLoadingReferrals: false,
  isLoadingReferralConfiguration: false,
  allReferrals: [],
  referralConfiguration: [],
  isLoadingSubmitRegConfig: false,
};

export default function referralsReducer(state = initialState, action) {
  switch (action.type) {
    // GET ALL TRANSACTIONS
    case GET_ALL_USER_REFERRAL_START:
      return {
        ...state,
        isLoadingReferrals: true,
      };

    case GET_ALL_USER_REFERRAL_SUCCESS:
      return {
        ...state,
        isLoadingReferrals: false,
        allReferrals: action.payLoad,
      };

    case GET_ALL_USER_REFERRAL_FAILURE:
      return {
        ...state,
        isLoadingReferrals: false,
      };

    case GET_REFERRAL_CONFIGURATION_START:
      return {
        ...state,
        isLoadingReferralConfiguration: true
      }

    case GET_REFERRAL_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isLoadingReferralConfiguration: false,
        referralConfiguration: action.payLoad
      }

    case GET_REFERRAL_CONFIGURATION_FAILURE:
      return {
        ...state,
        isLoadingReferralConfiguration: false,
        referralConfiguration: []
      }

    case SUBMIT_REFERRAL_CONFIG_START:
      return {
        ...state,
        isLoadingSubmitRegConfig: true
      }

    case SUBMIT_REFERRAL_CONFIG_SUCCESS:
      return {
        ...state,
        isLoadingSubmitRegConfig: false
      }

    case SUBMIT_REFERRAL_CONFIG_FAILURE:
      return {
        ...state,
        isLoadingSubmitRegConfig: false
      }

    default:
      return state;
  }
}
 
import { TOGGLE_LOADIDNG_STATE_OFF, TOGGLE_LOADIDNG_STATE_ON } from "./types";

const initialState = {
	isLoading: false,
};

export default function sharedReducer(state = initialState, action) {
	switch (action.type) {
		// GET ALL PERSONAL ACCOUNTS
		case TOGGLE_LOADIDNG_STATE_ON:
			return {
				...state,
				isLoading: true,
			};

		// GET ALL PERSONAL ACCOUNTS
		case TOGGLE_LOADIDNG_STATE_OFF:
			return {
				...state,
				isLoading: false,
			};

		default:
			return state;
	}
}

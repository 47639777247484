// PERSONAL

export const GET_ALL_PERSONAL_ACCOUNTS_START =
  "GET_ALL_PERSONAL_ACCOUNTS_START";
export const GET_ALL_PERSONAL_ACCOUNTS_SUCCESS =
  "GET_ALL_PERSONAL_ACCOUNTS_SUCCESS";
export const GET_ALL_PERSONAL_ACCOUNTS_FAILURE =
  "GET_ALL_PERSONAL_ACCOUNTS_FAILURE";

export const GET_SPECIFIC_PERSONAL_ACCOUNT_START =
  "GET_SPECIFIC_PERSONAL_ACCOUNT_START";
export const GET_SPECIFIC_PERSONAL_ACCOUNT_SUCCESS =
  "GET_SPECIFIC_PERSONAL_ACCOUNT_SUCCESS";
export const GET_SPECIFIC_PERSONAL_ACCOUNT_FAILURE =
  "GET_SPECIFIC_PERSONAL_ACCOUNT_FAILURE";

export const GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START =
  "GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START";
export const GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS =
  "GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS";
export const GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE =
  "GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE";

export const GET_PERSONAL_ACCOUNT_MERCHANT_FEES_START =
  "GET_PERSONAL_ACCOUNT_MERCHANT_FEES_START";
export const GET_PERSONAL_ACCOUNT_MERCHANT_FEES_SUCCESS =
  "GET_PERSONAL_ACCOUNT_MERCHANT_FEES_SUCCESS";
export const GET_PERSONAL_ACCOUNT_MERCHANT_FEES_FAILURE =
  "GET_PERSONAL_ACCOUNT_MERCHANT_FEES_FAILURE";

export const GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_START =
  "GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_START";
export const GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_SUCCESS =
  "GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_SUCCESS";
export const GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_FAILURE =
  "GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_FAILURE";

export const GET_PERSONAL_ACCOUNT_COMPLIANCE_START =
  "GET_PERSONAL_ACCOUNT_COMPLIANCE_START";
export const GET_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS =
  "GET_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS";
export const GET_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE =
  "GET_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE";

export const BLACKLIST_PERSONAL_ACCOUNT_START =
  "BLACKLIST_PERSONAL_ACCOUNT_START";
export const BLACKLIST_PERSONAL_ACCOUNT_SUCCESS =
  "BLACKLIST_PERSONAL_ACCOUNT_SUCCESS";
export const BLACKLIST_PERSONAL_ACCOUNT_FAILURE =
  "BLACKLIST_PERSONAL_ACCOUNT_FAILURE";

export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_START =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_START";
export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS";
export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE";

export const REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_START =
  "REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_START";
export const REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS =
  "REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS";
export const REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE =
  "REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE";

export const REVERT_ACCOUNT_MERCHANT_FEE_START =
  "REVERT_ACCOUNT_MERCHANT_FEE_START";
export const REVERT_ACCOUNT_MERCHANT_FEE_SUCCESS =
  "REVERT_ACCOUNT_MERCHANT_FEE_SUCCESS";
export const REVERT_ACCOUNT_MERCHANT_FEE_FAILURE =
  "REVERT_ACCOUNT_MERCHANT_FEE_FAILURE";

export const UPDATE_PERSONAL_ACCOUNT_BVN_START =
  "UPDATE_PERSONAL_ACCOUNT_BVN_START";
export const UPDATE_PERSONAL_ACCOUNT_BVN_SUCCESS =
  "UPDATE_PERSONAL_ACCOUNT_BVN_SUCCESS";
export const UPDATE_PERSONAL_ACCOUNT_BVN_FAILURE =
  "UPDATE_PERSONAL_ACCOUNT_BVN_FAILURE";

export const UPDATE_PERSONAL_ACCOUNT_ADDRESS_START =
  "UPDATE_PERSONAL_ACCOUNT_ADDRESS_START";
export const UPDATE_PERSONAL_ACCOUNT_ADDRESS_SUCCESS =
  "UPDATE_PERSONAL_ACCOUNT_ADDRESS_SUCCESS";
export const UPDATE_PERSONAL_ACCOUNT_ADDRESS_FAILURE =
  "UPDATE_PERSONAL_ACCOUNT_ADDRESS_FAILURE";

export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START";
export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS";
export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE";

export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_START =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_START";
export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_SUCCESS =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_SUCCESS";
export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_FAILURE =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_FAILURE";

export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_START =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_START";
export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS";
export const UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE =
  "UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE";

export const UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_START =
  "UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_START";
export const UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS =
  "UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS";
export const UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE =
  "UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE";

export const FUND_PERSONAL_ACCOUNT_START = "FUND_PERSONAL_ACCOUNT_START";
export const FUND_PERSONAL_ACCOUNT_SUCCESS = "FUND_PERSONAL_ACCOUNT_SUCCESS";
export const FUND_PERSONAL_ACCOUNT_FAILURE = "FUND_PERSONAL_ACCOUNT_FAILURE";

// AGENTS
export const GET_ALL_AGENTS_ACCOUNTS_START = "GET_ALL_AGENTS_ACCOUNTS_START";
export const GET_ALL_AGENTS_ACCOUNTS_SUCCESS =
  "GET_ALL_AGENTS_ACCOUNTS_SUCCESS";
export const GET_ALL_AGENTS_ACCOUNTS_FAILURE =
  "GET_ALL_AGENTS_ACCOUNTS_FAILURE";

// LIMITS REQUESTS
export const GET_ALL_LIMIT_REQUESTS_START = "GET_ALL_LIMIT_REQUESTS_START";
export const GET_ALL_LIMIT_REQUESTS_SUCCESS = "GET_ALL_LIMIT_REQUESTS_SUCCESS";
export const GET_ALL_LIMIT_REQUESTS_FAILURE = "GET_ALL_LIMIT_REQUESTS_FAILURE";

export const GET_SPECIFIC_LIMIT_REQUEST_START =
  "GET_SPECIFIC_LIMIT_REQUEST_START";
export const GET_SPECIFIC_LIMIT_REQUEST_SUCCESS =
  "GET_SPECIFIC_LIMIT_REQUEST_SUCCESS";
export const GET_SPECIFIC_LIMIT_REQUEST_FAILURE =
  "GET_SPECIFIC_LIMIT_REQUEST_FAILURE";

export const APPROVE_SPECIFIC_LIMIT_REQUEST_START =
  "APPROVE_SPECIFIC_LIMIT_REQUEST_START";
export const APPROVE_SPECIFIC_LIMIT_REQUEST_SUCCESS =
  "APPROVE_SPECIFIC_LIMIT_REQUEST_SUCCESS";
export const APPROVE_SPECIFIC_LIMIT_REQUEST_FAILURE =
  "APPROVE_SPECIFIC_LIMIT_REQUEST_FAILURE";

export const REJECT_SPECIFIC_LIMIT_REQUEST_START =
  "REJECT_SPECIFIC_LIMIT_REQUEST_START";
export const REJECT_SPECIFIC_LIMIT_REQUEST_SUCCESS =
  "REJECT_SPECIFIC_LIMIT_REQUEST_SUCCESS";
export const REJECT_SPECIFIC_LIMIT_REQUEST_FAILURE =
  "REJECT_SPECIFIC_LIMIT_REQUEST_FAILURE";

export const UPDATE_TRANSFER_LIMIT_START = "UPDATE_TRANSFER_LIMIT_START";
export const UPDATE_TRANSFER_LIMIT_SUCCESS = "UPDATE_TRANSFER_LIMIT_SUCCESS";
export const UPDATE_TRANSFER_LIMIT_FAILURE = "UPDATE_TRANSFER_LIMIT_FAILURE";

export const GET_SYSTEM_LIMITS_START = "GET_SYSTEM_LIMITS_START";
export const GET_SYSTEM_LIMITS_SUCCESS = "GET_SYSTEM_LIMITS_SUCCESS";
export const GET_SYSTEM_LIMITS_FAILURE = "GET_SYSTEM_LIMITS_FAILURE";

// BLACKLISTED
export const GET_ALL_BLACKLISTED_ACCOUNTS_START =
  "GET_ALL_BLACKLISTED_ACCOUNTS_START";
export const GET_ALL_BLACKLISTED_ACCOUNTS_SUCCESS =
  "GET_ALL_BLACKLISTED_ACCOUNTS_SUCCESS";
export const GET_ALL_BLACKLISTED_ACCOUNTS_FAILURE =
  "GET_ALL_BLACKLISTED_ACCOUNTS_FAILURE";

// BUSINESS

export const GET_ALL_BUSINESS_ACCOUNTS_START =
  "GET_ALL_BUSINESS_ACCOUNTS_START";
export const GET_ALL_BUSINESS_ACCOUNTS_SUCCESS =
  "GET_ALL_BUSINESS_ACCOUNTS_SUCCESS";
export const GET_ALL_BUSINESS_ACCOUNTS_FAILURE =
  "GET_ALL_BUSINESS_ACCOUNTS_FAILURE";

export const GET_SPECIFIC_BUSINESS_ACCOUNT_START =
  "GET_SPECIFIC_BUSINESS_ACCOUNT_START";
export const GET_SPECIFIC_BUSINESS_ACCOUNT_SUCCESS =
  "GET_SPECIFIC_BUSINESS_ACCOUNT_SUCCESS";
export const GET_SPECIFIC_BUSINESS_ACCOUNT_FAILURE =
  "GET_SPECIFIC_BUSINESS_ACCOUNT_FAILURE";

export const GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START =
  "GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START";
export const GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS =
  "GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS";
export const GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE =
  "GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE";

export const GET_BUSINESS_ACCOUNT_MERCHANT_FEES_START =
  "GET_BUSINESS_ACCOUNT_MERCHANT_FEES_START";
export const GET_BUSINESS_ACCOUNT_MERCHANT_FEES_SUCCESS =
  "GET_BUSINESS_ACCOUNT_MERCHANT_FEES_SUCCESS";
export const GET_BUSINESS_ACCOUNT_MERCHANT_FEES_FAILURE =
  "GET_BUSINESS_ACCOUNT_MERCHANT_FEES_FAILURE";

export const GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_START =
  "GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_START";
export const GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_SUCCESS =
  "GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_SUCCESS";
export const GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_FAILURE =
  "GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_FAILURE";

export const GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_START =
  "GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_START";
export const GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_SUCCESS =
  "GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_SUCCESS";
export const GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_FAILURE =
  "GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_FAILURE";

export const GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_START =
  "GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_START";
export const GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_SUCCESS =
  "GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_SUCCESS";
export const GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_FAILURE =
  "GET_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNTS_FAILURE";

export const GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START =
  "GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START";
export const GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS =
  "GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS";
export const GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE =
  "GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE";

export const GET_BUSINESS_ACCOUNT_2FA_START = "GET_BUSINESS_ACCOUNT_2FA_START";
export const GET_BUSINESS_ACCOUNT_2FA_SUCCESS =
  "GET_BUSINESS_ACCOUNT_2FA_SUCCESS";
export const GET_BUSINESS_ACCOUNT_2FA_FAILURE =
  "GET_BUSINESS_ACCOUNT_2FA_FAILURE";

export const GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START =
  "GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START";
export const GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS =
  "GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS";
export const GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE =
  "GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE";

export const BLACKLIST_BUSINESS_ACCOUNT_START =
  "BLACKLIST_BUSINESS_ACCOUNT_START";
export const BLACKLIST_BUSINESS_ACCOUNT_SUCCESS =
  "BLACKLIST_BUSINESS_ACCOUNT_SUCCESS";
export const BLACKLIST_BUSINESS_ACCOUNT_FAILURE =
  "BLACKLIST_BUSINESS_ACCOUNT_FAILURE";

export const REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_START =
  "REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_START";
export const REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS =
  "REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS";
export const REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE =
  "REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_START =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_START";
export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE";

export const UPDATE_ACCOUNT_MERCHANT_FEE_START =
  "UPDATE_ACCOUNT_MERCHANT_FEE_START";
export const UPDATE_ACCOUNT_MERCHANT_FEE_SUCCESS =
  "UPDATE_ACCOUNT_MERCHANT_FEE_SUCCESS";
export const UPDATE_ACCOUNT_MERCHANT_FEE_FAILURE =
  "UPDATEACCOUNT_MERCHANT_FEE_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_BVN_START =
  "UPDATE_BUSINESS_ACCOUNT_BVN_START";
export const UPDATE_BUSINESS_ACCOUNT_BVN_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_BVN_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_BVN_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_BVN_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_NAME_START =
  "UPDATE_BUSINESS_ACCOUNT_NAME_START";
export const UPDATE_BUSINESS_ACCOUNT_NAME_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_NAME_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_NAME_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_NAME_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_EMAIL_START =
  "UPDATE_BUSINESS_ACCOUNT_EMAIL_START";
export const UPDATE_BUSINESS_ACCOUNT_EMAIL_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_EMAIL_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_EMAIL_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_EMAIL_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_PHONE_START =
  "UPDATE_BUSINESS_ACCOUNT_PHONE_START";
export const UPDATE_BUSINESS_ACCOUNT_PHONE_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_PHONE_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_PHONE_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_PHONE_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_ADDRESS_START =
  "UPDATE_BUSINESS_ACCOUNT_ADDRESS_START";
export const UPDATE_BUSINESS_ACCOUNT_ADDRESS_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_ADDRESS_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_ADDRESS_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_ADDRESS_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_DETAILS_START =
  "UPDATE_BUSINESS_ACCOUNT_DETAILS_START";
export const UPDATE_BUSINESS_ACCOUNT_DETAILS_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_DETAILS_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_DETAILS_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_DETAILS_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_START =
  "UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_START";
export const UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_RC_START =
  "UPDATE_BUSINESS_ACCOUNT_RC_START";
export const UPDATE_BUSINESS_ACCOUNT_RC_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_RC_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_RC_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_RC_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_START =
  "UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_START";
export const UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START =
  "UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START";
export const UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START";
export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_START =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_START";
export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_START =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_START";
export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE";

export const UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START =
  "UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START";
export const UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS =
  "UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS";
export const UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE =
  "UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE";

export const FUND_BUSINESS_ACCOUNT_START = "FUND_BUSINESS_ACCOUNT_START";
export const FUND_BUSINESS_ACCOUNT_SUCCESS = "FUND_BUSINESS_ACCOUNT_SUCCESS";
export const FUND_BUSINESS_ACCOUNT_FAILURE = "FUND_BUSINESS_ACCOUNT_FAILURE";


// MULTI-CURRENCY 
export const GET_MULTI_CURRENCIES_START = "GET_MULTI_CURRENCIES_START"
export const GET_MULTI_CURRENCIES_SUCCESS = "GET_MULTI_CURRENCIES_SUCCESS"
export const GET_MULTI_CURRENCIES_FAILURE = "GET_MULTI_CURRENCIES_FAILURE"
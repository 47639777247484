import React, { useState, useMemo, useEffect } from "react";
import Button from "../../components/buttons/Button";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import PillIndicator from "../../components/indicators/PillIndicator";
import TabNav from "../../components/navbars/TabNav";
import { capitalizeString, fixDateForAllBrowsers, statusEnum } from "../../helpers/helper";
import { PopoverMain } from "../../components/popovers/Popover";
import { PaginatedTable } from "../../components/table/Table";
import { FiMoreVertical } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import useDiscounts from "../../hooks/useDiscounts";
import qs from 'query-string';
import * as dfn from "date-fns";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import { getAllDiscounts, terminateDiscount, activateDiscount, deleteDiscount } from "../../redux/discounts/discountActions";


const Discounts = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page ?? 1
  );

  const { allDiscounts, isLoadingDiscounts } = useDiscounts();

  const defaultFilterParams = {
    search: "",
    page: currentPage,
    date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 12), 'yyyy-MM-dd'),
    date_to: dfn.lightFormat(dfn.subWeeks(new Date(), 12), 'yyyy-MM-dd')
  };

  const filterInstance = usePaginationFilterParams({
    initialFilters: defaultFilterParams
  });

  const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

  const { date_from, date_to, status, search, page } = filters;

  const [filterValue, setFilterValue] = useState(filters);

  useEffect(() => {
    setCurrentPage(1);
    setFilterValue(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_from, date_to, search, status]);

  useMemo(() => {
    handleChange({ ...filterValue, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const resetReferenceSearch = event => {
    if (typeof event?.preventDefault === "function") {
      event?.preventDefault();
    }
    urlSearchParamsHistory.replace(defaultFilterParams);
  };

  useMemo(() => {
    dispatch(
      getAllDiscounts({
        params: {
          ...(search
            ? {
              accountname: search,
              paginate: 1,
              page,
              per_page: 10
            }
            : {
              start_date: date_from,
              end_date: date_to,
              paginate: 1,
              page,
              per_page: 10,
            }),
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date_from,
    date_to,
    page,
    search,
  ]);



  const columns = [
    {
      accessor: "status",
      Header: "Status",
      style: {
        paddingLeft: 0,
        paddingRight: "10px",
        width: "50px"
      },
      Cell: ({ value }) => (
        <React.Fragment>
          <PillIndicator
            className={"py-1 mx-auto capitalize"}
            type={statusEnum[value]}
            title={value}
          />
        </React.Fragment>
      )
    },
    {
      accessor: "discount_type",
      Header: "Discounts",
      Cell: ({ value, row }) => (
        row.values.discount_type === "transfer_discount"
          ? (
            <Link to={`/dashboard/discounts/${row.values.id}`}>
              <span className="glade-capitalize glade-normal-text-two">{row?.original?.tag}</span>
            </Link>
          ) : (
            <span className="glade-capitalize glade-normal-text-two">{value.split("_").join(" ")}</span>
          )
      )
    },
    {
      accessor: "account_type",
      Header: "Account Type",
      Cell: ({ value, row }) => (
        <span className="glade-capitalize glade-normal-text-two">{value}</span>
      )
    },
    {
      accessor: "no_of_accounts",
      Header: "Accounts Selected",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two">{capitalizeString(value)}</span>
      )
    },
    {
      accessor: "no_of_free_transfers",
      Header: "No. of Transfers",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two">{value}</span>
      )
    },
    {
      accessor: "date_added",
      Header: "Date Created",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two">
          {dfn.format(
            new Date(fixDateForAllBrowsers(value)),
            "MMM d, yyy"
          )}
        </span>
      )
    },
    {
      accessor: "id",
      Header: "Actions",
      Cell: ({ value, row }) => {
        return (
          <PopoverMain
            button={
              <div type="button" className={"py-2 glade-ash right-0"}>
                <div className="inline-flex items-center justify-items-center 
                text-center text-2xl">
                  <FiMoreVertical
                    color="#151515"
                    className="mx-auto cursor-pointer"
                  />
                </div>
              </div>
            }
            popoverClassName={`right-0`}
          >
            <ul className="px-2 py-5 bg-white shadow rounded-md w-56">
              <Link
                to={`/dashboard/discounts/${row.values.discount_type === "free_transfer" ? "edit-free-transfer" : "edit-transfer-discount"}/${row.values.id}`}>
                <li
                  className="p-3 glade-normal-text-two glade-hover-bg-blue-darker 
                glade-black cursor-pointer"
                >
                  Edit
                </li>
              </Link>
              {row.values.status === "active"
                ? <li
                  onClick={() => dispatch(terminateDiscount(row.values.id))}
                  className="p-3 glade-normal-text-two glade-hover-bg-blue-darker 
                    glade-black cursor-pointer">
                  Deactivate
                </li>
                : row.values.status === "inactive"
                  ? <li
                    onClick={() => dispatch(activateDiscount(row.values.id))}
                    className="p-3 glade-normal-text-two glade-hover-bg-blue-darker 
                      glade-black cursor-pointer">
                    Activate
                  </li>
                  : null
              }
              <li
                onClick={() => dispatch(deleteDiscount(row.values.id))}
                className="p-3 glade-normal-text-two glade-hover-bg-blue-darker 
                glade-black cursor-pointer"
              >
                Delete
              </li>
            </ul>
          </PopoverMain>
        );
      }
    }
  ];

  return (
    <div className="p-3">
      <TabNav
        tabs={[
          {
            title: "Fees",
            path: "/dashboard/fees"
          },
          {
            title: "Discounts",
            path: "/dashboard/discounts"
          },
          {
            title: "Virtual Card Settings",
            path: "/dashboard/virtual-card/settings",
          },
          {
            title: "Checkout",
            path: "/dashboard/fees/checkout",
          }
        ]}
      />
      <CardTableHeader
        title={"Transfer Discounts - " + allDiscounts.length}
        defaultFilterParams={defaultFilterParams}
        filterCallback={handleChange}
        filterFields={["date"]}
        searchBarPlaceholder={"Account Name"}
        onChange={e => {
          const value = e.target.value;
          if (value) {
            handleChange({ search: value.trim() });
          } else {
            resetReferenceSearch();
          }
        }}
        actionButton={
          <PopoverMain
            button={<Button.Dark
              title={"New Discount"}
              className="p-6 w-80 right-0 bg-white shadow-md top-12 rounded"
            />}
            popoverClassName="right-0"
          >
            <ul className="px-2 mt-5 py-5 bg-white shadow rounded-md w-56">
              <Link to={"/dashboard/discounts/new-free-transfer"}>
                <li
                  className={"p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"}
                >
                  New Free Transfer
                </li>
              </Link>
              <Link to={"/dashboard/discounts/new-transfer-discount"}>
                <li
                  className={"p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"}
                >
                  New Transfer Discount
                </li>
              </Link>
            </ul>
          </PopoverMain>
        }
      />
      <PaginatedTable
        columns={columns}
        data={allDiscounts}
        empty_message="No Discounts"
        empty_sub_message="You have not created any discounts"
        current_page={currentPage}
        loading={isLoadingDiscounts}
        setCurrentPage={setCurrentPage}
        totalPages={allDiscounts?.length}
      />
    </div>
  );
};

export default Discounts;
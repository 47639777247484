import React, { Fragment, useEffect, useState } from "react";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import {
  getCheckoutCurrencies,
  getCheckoutFees,
} from "../../redux/fees/feesActions";
import useFees from "../../hooks/useFees";
import TabNav from "../../components/navbars/TabNav";
import Button from "../../components/buttons/Button";
import SelectInput from "../../components/inputs/SelectInput";
import { isArray } from "../../helpers/helper";
import AddCheckoutModal from "./AddCheckoutModal";
import { TextSkeleton } from "../../components/skeletons";
import EditCheckoutFeeModal from "./EditCheckoutFeeModal";

const CheckoutFees = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedFee, setSelectedFee] = useState(null);
  const [modalStates, setModalStates] = useState({
    showAddCurrency: false,
    showAddCheckoutModal: false,
  });

  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page ?? 1
  );

  const {
    isLoadingCheckoutFees,
    allCheckoutFees,
    isLoadingCheckoutCurrencies,
    allCheckoutCurrencies,
  } = useFees();
  const [currency_code, set_currency_code] = useState("NGN");
  const [currency_name, set_currency_name] = useState("Nigerian Naira");

  useEffect(() => {
    dispatch(getCheckoutFees(currency_code));
    dispatch(getCheckoutCurrencies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency_code]);

  // Open Edit Modal
  const openCheckoutEditModal = (fee) => {
    setSelectedFee(fee);
    setModalStates({
      ...modalStates,
      showAddCheckoutModal: true,
    });
  };

  const columns = [
    {
      accessor: "display_name",
      Header: "Name",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two capitalize">{value}</span>
      ),
    },
    {
      accessor: "fee_added",
      Header: "Added Fee",
      Cell: ({ value, row }) => (
        <div className="flex items-center">
          {value ? (
            <span className="glade-normal-text-two">
              {currency_code ?? "NGN"}{" "}
              {Number(value)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            <span className="glade-normal-text-two">-</span>
          )}
        </div>
      ),
    },
    {
      accessor: "fee_cap",
      Header: "Capped Fee",
      Cell: ({ value, row }) => (
        <div className="flex items-center">
          {value ? (
            <span className="glade-normal-text-two">
              {currency_code ?? "NGN"}{" "}
              {Number(value)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            <span className="glade-normal-text-two">-</span>
          )}
        </div>
      ),
    },
    {
      accessor: "fee_percentage",
      Header: "Percentage Fee",
      Cell: ({ value, row }) => (
        <div className="flex items-center">
          {value ? (
            <span className="glade-normal-text-two">
              {Number(value)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            <span className="glade-normal-text-two">-</span>
          )}
        </div>
      ),
    },
    {
      accessor: "id",
      Header: "",
      Cell: ({ value, row }) => (
        <div className="flex items-center justify-center">
          <span
            className="glade-blue-normal glade-normal-text-two cursor-pointer"
            onClick={() => openCheckoutEditModal(row.original)}
          >
            Edit
          </span>
        </div>
      ),
    },
  ];
  const onChange = (e) => {
    let currencyHolder = allCheckoutCurrencies.find(
      (currency) => currency.currency_code === e.target.value
    );
    set_currency_code(currencyHolder?.currency_code);
    set_currency_name(currencyHolder?.currency_name);
  };

  return (
    <div className="p-3">
      <TabNav
        tabs={[
          {
            title: "Fees",
            path: "/dashboard/fees",
          },
          {
            title: "Discounts ",
            path: "/dashboard/discounts",
          },
          {
            title: "Virtual Card Settings",
            path: "/dashboard/virtual-card/settings",
          },
          {
            title: "Checkout",
            path: "/dashboard/fees/checkout",
          },
        ]}
      />
      <CardTableHeader
        filterButton={false}
        customSearchInput={
          <div>
            <Fragment>
              <p className="glade-small-text-two glade-black mb-3">
                Select Currency
              </p>
              {isLoadingCheckoutCurrencies ? (
                <TextSkeleton />
              ) : (
                <SelectInput
                  name={"currency_code"}
                  id={"currency_code"}
                  value={currency_code}
                  onChange={onChange}
                >
                  <option>Select</option>
                  {allCheckoutCurrencies?.map((currency, index) => (
                    <option key={index} value={currency?.currency_code}>
                      {currency?.currency_code}
                    </option>
                  ))}
                </SelectInput>
              )}
            </Fragment>
          </div>
        }
        disabled={isLoadingCheckoutFees}
        title="Checkout"
        noSearch={true}
        actionButton={
          <Button.GladeBlue
            title={"Configure Fees"}
            onClick={() =>
              setModalStates({
                ...modalStates,
                showAddCurrency: !modalStates?.showAddCurrency,
              })
            }
          />
        }
      />

      <PaginatedTable
        columns={columns}
        data={isArray(allCheckoutFees) ? allCheckoutFees : []}
        empty_message="No Checkout fees"
        empty_sub_message=""
        current_page={currentPage}
        loading={isLoadingCheckoutFees}
        setCurrentPage={setCurrentPage}
        totalPages={allCheckoutFees?.total}
      />

      {modalStates?.showAddCurrency &&
      <AddCheckoutModal
        modalStates={modalStates}
        setModalStates={setModalStates}
        selectedFee={selectedFee}
        setSelectedFee={setSelectedFee}
        currency_code={currency_code}
        currency_name={currency_name}
        />}

      {modalStates?.showAddCheckoutModal && (
        <EditCheckoutFeeModal
          modalStates={modalStates}
          setModalStates={setModalStates}
          selectedFee={selectedFee}
          setSelectedFee={setSelectedFee}
          currency_code={currency_code}
        />
      )}
    </div>
  );
};

export default CheckoutFees;

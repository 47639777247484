import {
	GET_ALL_SETTLED_NGN_SETTLEMENTS_FAILURE,
	GET_ALL_SETTLED_NGN_SETTLEMENTS_START,
	GET_ALL_SETTLED_NGN_SETTLEMENTS_SUCCESS,
	GET_ALL_SETTLED_USD_SETTLEMENTS_FAILURE,
	GET_ALL_SETTLED_USD_SETTLEMENTS_START,
	GET_ALL_SETTLED_USD_SETTLEMENTS_SUCCESS,
	GET_ALL_UNSETTLED_NGN_SETTLEMENTS_FAILURE,
	GET_ALL_UNSETTLED_NGN_SETTLEMENTS_START,
	GET_ALL_UNSETTLED_NGN_SETTLEMENTS_SUCCESS,
	GET_ALL_UNSETTLED_USD_SETTLEMENTS_FAILURE,
	GET_ALL_UNSETTLED_USD_SETTLEMENTS_START,
	GET_ALL_UNSETTLED_USD_SETTLEMENTS_SUCCESS,
	GET_SPECIFIC_SETTLED_SETTLEMENT_FAILURE,
	GET_SPECIFIC_SETTLED_SETTLEMENT_START,
	GET_SPECIFIC_SETTLED_SETTLEMENT_SUCCESS,
	GET_SPECIFIC_UNSETTLED_SETTLEMENT_FAILURE,
	GET_SPECIFIC_UNSETTLED_SETTLEMENT_START,
	GET_SPECIFIC_UNSETTLED_SETTLEMENT_SUCCESS,
	PROCESS_UNSETTLED_SETTLEMENTS_FAILURE,
	PROCESS_UNSETTLED_SETTLEMENTS_START,
	PROCESS_UNSETTLED_SETTLEMENTS_SUCCESS,
} from "./types";

const initialState = {
	isLoadingAllSettledNGNSettlements: true,
	isLoadingAllSettledUSDSettlements: true,
	isLoadingSpecificSettledSettlement: true,
	isLoadingAllUnsettledNGNSettlements: true,
	isLoadingAllUnsettledUSDSettlements: true,
	isLoadingSpecificUnsettledSettlement: true,
	isProcessingUnsettledSettlement: false,
	allSettledNGNSettlements: [],
	allUnsettledNGNSettlements: [],
	allSettledUSDSettlements: [],
	allUnsettledUSDSettlements: [],
	specificSettledSettlement: {},
	specificUnsettledSettlement: {},
};

export default function settlementsReducer(state = initialState, action) {
	switch (action.type) {
		// GET ALL SETTLED NGN SETTLEMENTS
		case GET_ALL_SETTLED_NGN_SETTLEMENTS_START:
			return {
				...state,
				isLoadingAllSettledNGNSettlements: true,
			};

		case GET_ALL_SETTLED_NGN_SETTLEMENTS_SUCCESS:
			return {
				...state,
				isLoadingAllSettledNGNSettlements: false,
				allSettledNGNSettlements: action.payLoad,
			};

		case GET_ALL_SETTLED_NGN_SETTLEMENTS_FAILURE:
			return {
				...state,
				isLoadingAllSettledNGNSettlements: false,
			};

		// GET ALL SETTLED USD SETTLEMENTS
		case GET_ALL_SETTLED_USD_SETTLEMENTS_START:
			return {
				...state,
				isLoadingAllSettledUSDSettlements: true,
			};

		case GET_ALL_SETTLED_USD_SETTLEMENTS_SUCCESS:
			return {
				...state,
				isLoadingAllSettledUSDSettlements: false,
				allSettledUSDSettlements: action.payLoad,
			};

		case GET_ALL_SETTLED_USD_SETTLEMENTS_FAILURE:
			return {
				...state,
				isLoadingAllSettledUSDSettlements: false,
			};

		// Get specific settled settlement
		case GET_SPECIFIC_SETTLED_SETTLEMENT_START:
			return {
				...state,
				isLoadingSpecificSettledSettlement: true,
			};

		case GET_SPECIFIC_SETTLED_SETTLEMENT_SUCCESS:
			return {
				...state,
				isLoadingSpecificSettledSettlement: false,
				specificSettledSettlement: action.payLoad,
			};

		case GET_SPECIFIC_SETTLED_SETTLEMENT_FAILURE:
			return {
				...state,
				isLoadingSpecificSettledSettlement: false,
				specificSettledSettlement: {},
			};

		// GET ALL UNSETTLED NGN SETTLEMENTS
		case GET_ALL_UNSETTLED_NGN_SETTLEMENTS_START:
			return {
				...state,
				isLoadingAllUnsettledNGNSettlements: true,
			};

		case GET_ALL_UNSETTLED_NGN_SETTLEMENTS_SUCCESS:
			return {
				...state,
				isLoadingAllUnsettledNGNSettlements: false,
				allUnsettledNGNSettlements: action.payLoad,
			};

		case GET_ALL_UNSETTLED_NGN_SETTLEMENTS_FAILURE:
			return {
				...state,
				isLoadingAllUnsettledNGNSettlements: false,
			};

		// GET ALL UNSETTLED USD SETTLEMENTS
		case GET_ALL_UNSETTLED_USD_SETTLEMENTS_START:
			return {
				...state,
				isLoadingAllUnsettledUSDSettlements: true,
			};

		case GET_ALL_UNSETTLED_USD_SETTLEMENTS_SUCCESS:
			return {
				...state,
				isLoadingAllUnsettledUSDSettlements: false,
				allUnsettledUSDSettlements: action.payLoad,
			};

		case GET_ALL_UNSETTLED_USD_SETTLEMENTS_FAILURE:
			return {
				...state,
				isLoadingAllUnsettledUSDSettlements: false,
			};

		// Get specific unsettled settlement
		case GET_SPECIFIC_UNSETTLED_SETTLEMENT_START:
			return {
				...state,
				isLoadingSpecificUnsettledSettlement: true,
			};

		case GET_SPECIFIC_UNSETTLED_SETTLEMENT_SUCCESS:
			return {
				...state,
				isLoadingSpecificUnsettledSettlement: false,
				specificUnsettledSettlement: action.payLoad,
			};

		case GET_SPECIFIC_UNSETTLED_SETTLEMENT_FAILURE:
			return {
				...state,
				isLoadingSpecificUnsettledSettlement: false,
				specificUnsettledSettlement: {},
			};

		// verify specific unsettled settlement
		case PROCESS_UNSETTLED_SETTLEMENTS_START:
			return {
				...state,
				isProcessingUnsettledSettlement: true,
			};

		case PROCESS_UNSETTLED_SETTLEMENTS_SUCCESS:
			return {
				...state,
				isProcessingUnsettledSettlement: false,
			};

		case PROCESS_UNSETTLED_SETTLEMENTS_FAILURE:
			return {
				...state,
				isProcessingUnsettledSettlement: false,
			};

		default:
			return state;
	}
}

import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { BsSearch } from "react-icons/bs";
import { debounce } from "../../helpers/helper";
import { IoMdClose } from "react-icons/io";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";

const SearchInputStyle = styled.div`
	width: ${(props) => props.width || "320px"};
`;

export default function SearchInput({
	placeholder,
	onChange,
	defaultFilterParams = {},
	clearSearchInput
}) {
	const inputRef = useRef(null);

	const clearInput = () => {
		if (inputRef?.current) {
			inputRef.current.value = "";
			resetReferenceSearch(defaultFilterParams);
			onChange({ target: { name: 'search', value: '' } });
		}
	};

	const filterInstance = usePaginationFilterParams();

	const { urlSearchParamsHistory } = filterInstance;

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};
	useEffect(() => {

		return clearSearchInput ? inputRef.current.value = "" : null;
	}, [clearSearchInput]);
	return (
		<SearchInputStyle className="mt-1 relative rounded-sm">
			{inputRef?.current?.value?.length === 0 ? (
				<div className="absolute z-10 inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
					<span className="glade-ash sm:text-sm pb-1 relative">
						<BsSearch className="pointer-events-none" />
					</span>
				</div>
			) : (
				<div className="absolute z-10 inset-y-0 right-0 pr-3 flex items-center">
					<span className="glade-ash sm:text-sm pb-1 relative">
						<IoMdClose
							className="mt-2 cursor-pointer relative z-50"
							onClick={clearInput}
						/>
					</span>
				</div>
			)}
			<input
				type="text"
				ref={inputRef}
				onChange={debounce(onChange, 1000)}
				name="search"
				placeholder={placeholder ?? "Search"}
				className="glade-normal-text-two search-input glade-black block w-full pr-7 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-sm"
			/>
		</SearchInputStyle>
	);
}

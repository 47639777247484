import React from 'react'

const InfoSection = ({ contents }) => {
  return (
    <div className="grid grid-cols-4 gap-4 py-3">
      <div className="flex flex-col">
        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
          First Name
        </h5>
        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
          {contents?.first_name || "N/A"}
        </h6>
      </div>

      <div className="flex flex-col">
        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
          Last Name
        </h5>
        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
          {contents?.last_name || "N/A"}
        </h6>
      </div>

      <div className="flex flex-col">
        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
          Email
        </h5>
        <h6 className="glade-normal-text-three glade-black py-1">
          {contents?.email || "N/A"}
        </h6>
      </div>

      <div className="flex flex-col">
        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
          Phone Number
        </h5>
        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
          {contents?.phone || "N/A"}
        </h6>
      </div>

      {contents?.documents.map(content => {
        return (
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
              {content?.title.split("_").join(" ")}
            </h5>
            {content?.file_url ? (
              <a
                href={content?.file_url}
                target="_blank"
                rel="noreferrer noopener"
              >
                <h6 className="glade-normal-text-three glade-blue py-1 cursor-pointer capitalize">
                  view document
                </h6>
              </a>
            ) : (
              <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                {content?.compliance_data}
              </h6>
            )}
          </div>
        )
      })}

      <div className="flex flex-col">
        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
          Address
        </h5>
        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
          {contents?.address || "N/A"}
        </h6>
      </div>

      <div className="flex flex-col">
        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">          
          State
        </h5>
        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
          {contents?.state || "N/A"}
        </h6>
      </div>

      <div className="flex flex-col">
        <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
          LGA
        </h5>
        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
          {contents?.lga || "N/A"}
        </h6>
      </div>

    </div>


  )
}

export default InfoSection
import React, { useMemo, useState } from "react";
import * as dfn from "date-fns";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import { fixDateForAllBrowsers, statusEnum, truncateRef } from "../../helpers/helper";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import PillIndicator from "../../components/indicators/PillIndicator";
import { PaginatedTable } from "../../components/table/Table";
import CountryFlag from "./CountryFlag";
import { getAllWallets } from "../../redux/wallets/walletsActions";
import useWallets from "../../hooks/useWallets";
import TabNav from "../../components/navbars/TabNav";

export default function AllWalletRequests() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page || 1
  );


  const { isLoadingWalletRequests, walletRequestsData } = useWallets();

  const defaultFilterParams = {
    search: "",
    page: currentPage,
    status: "pending",
    date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 150), "yyyy-MM-dd"),
    date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
  };

  const filterInstance = usePaginationFilterParams({
    initialFilters: defaultFilterParams,
  });

  const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

  const { date_from, date_to, search, status, page } = filters;

  const [filterValue, setFilterValue] = React.useState(filters);

  useMemo(() => {
    // setCurrentPage(1);
    setFilterValue(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_from, date_to, search, status]);

  useMemo(() => {
    dispatch(getAllWallets({
      params: {
        ...(search
          ? {
            search: search,
            status,
            paginate: 1,
            page,
            per_page: 10,
          }
          : {
            date_from,
            date_to,
            status,
            paginate: 1,
            page,
            per_page: 10,
          }),
      },
    }));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // currency,
    date_from,
    date_to,
    status,
    page,
    // per_page,
    search,
  ]);

  useMemo(() => {
    handleChange({ ...filterValue, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const resetReferenceSearch = (event) => {
    if (typeof event?.preventDefault === "function") {
      event?.preventDefault();
    }
    urlSearchParamsHistory.replace(defaultFilterParams);
  };

  const columns = [
    {
      accessor: "status",
      Header: "Status",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/wallet-request/details?id=${row?.original?.id}`}
        >
          <PillIndicator
            className="px-5 py-1 capitalize"
            type={statusEnum[value]}
            title={value}
          />
        </Link>
      ),
    },
    {
      accessor: "business_name",
      Header: "Name",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/wallet-request/details?id=${row?.original?.id}`}
        >
          <span className="glade-normal-text-two capitalize">
            {value} {row.original.othernames} {row.original.lastname}
          </span>
        </Link>
      ),
    },
    {
      accessor: "business_email",
      Header: "Email",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/wallet-request/details?id=${row?.original?.id}`}
        >
          <span className="glade-normal-text-two">{truncateRef(value)}</span>
        </Link>
      ),
    },
    {
      accessor: "phone",
      Header: "Account Type",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/wallet-request/details?id=${row?.original?.id}`}
        >
          <span className="glade-normal-text-two">{row?.original?.business_uuid ? 'Business' : 'Personal'}</span>
        </Link>
      ),
    },
    {
      accessor: "currency",
      Header: "Currency Requested",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/wallet-request/details?id=${row?.original?.id}`}
        >
          <span className="flex items-center gap-3 glade-normal-text-two">
            <CountryFlag flagCode={value?.slice(0, -1)} /> {value}
          </span>
        </Link>
      ),
    },
    {
      accessor: "created_at",
      Header: "Date Requested",
      Cell: ({ value, row }) => (
        <Link
          to={`/dashboard/wallet-request/details?id=${row?.original?.id}`}
        >
          <span className="glade-normal-text-two">
            {dfn.format(
              new Date(fixDateForAllBrowsers(value)),
              "MMM d, yyyy"
            )}
          </span>
        </Link>
      ),
    },
  ];

  return (
    <div className="p-3">
      <TabNav
        tabs={[
          { title: "Wallets", path: "/dashboard/accountrequests/wallets" },
          { title: "Accounts", path: "/dashboard/accountrequests/accounts" },
        ]}
      />
      <CardTableHeader
        onChange={(e) => {
          const value = e.target.value;
          if (value) {
            handleChange({ search: value.trim() });
          } else {
            resetReferenceSearch();
          }
        }}
        defaultFilterParams={defaultFilterParams}
        filterFields={["walletStatus", "date"]}
        filterCallback={handleChange}
        disabled={isLoadingWalletRequests}
        title={`Wallets ${!isLoadingWalletRequests && walletRequestsData?.total
            ? "- " + walletRequestsData?.total
            : ""
          }`}
        searchBarPlaceholder={"Account Name or Email"}
        fileName="Personal Acounts"
      />
      <PaginatedTable
        columns={columns}
        data={
          typeof walletRequestsData === "object" &&
            !Array.isArray(walletRequestsData)
            ? walletRequestsData.data
            : walletRequestsData
        }
        empty_message="No accounts"
        empty_sub_message=""
        current_page={currentPage}
        loading={isLoadingWalletRequests}
        setCurrentPage={setCurrentPage}
        totalPages={walletRequestsData?.total}
      />
    </div>
  );
}

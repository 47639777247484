import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import { updateBusinessAccountAddress } from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";

const EditBusinessAddressModal = ({
	modalStates,
	setModalStates,
	showEditBusinessAddressModal,
}) => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { isUpdatingBusinessAccountAddress, specificBusinessAccounts, specificBusinessAccountCompliance } =
		useAccounts();

	const onUpdateBusinessAddress = (data) => {
		dispatch(updateBusinessAccountAddress(id, specificBusinessAccounts?.business?.business_uuid, data.address));
	};

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm();

	return (
		<BasicModal
			visibility={showEditBusinessAddressModal}
			onClose={() => {
				reset();
				setModalStates({ ...modalStates, showEditBusinessAddressModal: false });
			}}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditBusinessAddressModal: true,
				})
			}
			primaryButton=""
			modalTitle="Update Address"
			secondaryButton=""
			modelContentSection={
				<form onSubmit={handleSubmit(onUpdateBusinessAddress)} className="">
					<div className="">
						<label htmlFor="address" className="block glade-small-text-two">
							Address
						</label>
						<textarea
							type="text"
							id="address"
							name="address"
							{...register("address", {
								required: "Enter a valid address",
								minLength: {
									value: 5,
									message: "Enter a valid address",
								},
							})}
							rows={4}
							defaultValue={
								specificBusinessAccountCompliance?.business_details?.[0]?.business_address
							}
							placeholder="New Address"
							className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
						/>
						<FormError errors={errors} name="address" />
					</div>

					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={() => {
								reset();
								setModalStates({
									...modalStates,
									showEditBusinessAddressModal: false,
								});
							}}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Save"
								className="block flex-grow-0"
								loading={isUpdatingBusinessAccountAddress}
							/>
						</div>
					</div>
				</form>
			}
		/>
	);
};

export default EditBusinessAddressModal;
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import {
  updateCheckoutCurrency,
  getCheckoutFees,
} from "../../redux/fees/feesActions";
import useFees from "../../hooks/useFees";

const EditCheckoutFeeModal = ({
  modalStates,
  setModalStates,
  setSelectedFee,
  selectedFee,
  currency_code,
}) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState({
    currency_name: "",
    currency_code: "",
    checkout_option: "",
    fee_type: "",
    amount: "",
  });
  const { register, reset, handleSubmit } = useForm();

  const { isUpdatingFee } = useFees();

  const onUpdateFee = (data) => {
    dispatch(
      updateCheckoutCurrency(data, selectedFee?.id, () => {
        dispatch(getCheckoutFees(currency_code));
        reset();
        setModalStates({ ...modalStates, showAddCheckoutModal: false });
        setSelectedFee({});
      })
    );
  };

  return (
    <BasicModal
      visibility={modalStates.showAddCheckoutModal}
      onClose={() => {
        reset();
        setModalStates({ ...modalStates, showAddCheckoutModal: false });
        setSelectedFee({});
      }}
      onOpen={() =>
        setModalStates({
          ...modalStates,
          showAddCheckoutModal: true,
        })
      }
      primaryButton=""
      modalTitle={`Edit ${selectedFee?.display_name} ${currency_code} Fees`}
      secondaryButton=""
      modelContentSection={
        <>
          <form className="" onSubmit={handleSubmit(onUpdateFee)}>
            <div className="mb-4 flex flex-col gap-4">
              <div>
                <label
                  htmlFor="fee_added"
                  className="block glade-small-text-two capitalize mb-1"
                >
                  Added Fee
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="glade-black sm:text-sm">
                      {currency_code}
                    </span>
                  </div>
                  <input
                    type="number"
                    id="fee_added"
                    name="fee_added"
                    placeholder="Added Fee"
                    className="block w-full pl-12 border-gray-300 rounded-sm glade-normal-text-two"
                    defaultValue={
                      Number(selectedFee?.fee_added).toFixed(2) ?? 0
                    }
                    {...register("fee_added", {
                      required: "Enter a valid amount",
                      min: {
                        value: 0,
                        message: "Enter a valid amount",
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="fee_cap"
                  className="block glade-small-text-two capitalize mb-1"
                >
                  Capped Fee
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="glade-black sm:text-sm">
                      {currency_code}
                    </span>
                  </div>
                  <input
                    type="number"
                    id="fee_cap"
                    name="fee_cap"
                    placeholder="Capped Fee"
                    className="block w-full pl-12 border-gray-300 rounded-sm glade-normal-text-two"
                    defaultValue={Number(selectedFee?.fee_cap).toFixed(2) ?? 0}
                    {...register("fee_cap", {
                      required: "Enter a valid amount",
                      min: {
                        value: 0,
                        message: "Enter a valid amount",
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="fee_percentage"
                  className="block glade-small-text-two capitalize mb-1"
                >
                  Percentage Fee
                </label>
                <div className="relative">
                  <input
                    type="number"
                    id="fee_percentage"
                    name="fee_percentage"
                    placeholder="Percentage Fee"
                    className="block w-full pl-3 border-gray-300 rounded-sm glade-normal-text-two"
                    defaultValue={
                      Number(selectedFee?.fee_percentage).toFixed(2) ?? 0
                    }
                    {...register("fee_percentage", {
                      required: "Enter a valid amount",
                      min: {
                        value: 0,
                        message: "Enter a valid amount",
                      },
                    })}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="glade-black sm:text-sm">%</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex -mb-8 items-center justify-between p-4 border-t -mx-5 mt-4">
              <button
                type="button"
                onClick={() => {
                  reset();
                  setModalStates({
                    ...modalStates,
                    showAddCheckoutModal: false,
                  });
                  setSelectedFee({});
                }}
                className="glade-normal-text-two glade-black text-center"
              >
                Cancel
              </button>
              <div>
                <Button.Dark
                  type="submit"
                  title="Update"
                  className="block flex-grow-0"
                  loading={isUpdatingFee}
                  onClick={handleSubmit(onUpdateFee)}
                />
              </div>
            </div>
          </form>
        </>
      }
    />
  );
};

export default EditCheckoutFeeModal;

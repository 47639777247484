import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
// import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import {
  approveLimitRequest,
  getSystemLimits,
} from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";
// import NumberFormat from "react-number-format";
import { AngleDownSelect, DisplayMedal } from "../../assets/icons/Icons";
const ApproveLimitModal = ({
  modalStates,
  setModalStates,
  showApproveRequestModal,
  requestedLimit,
  currency,
}) => {
  const dispatch = useDispatch();
  const { otherId } = useParams();
  const { isApprovingLimitRequest, systemLimits } = useAccounts();
  
  const onRejectRequest = ({ amount }) => {
    dispatch(
      approveLimitRequest(otherId, selectedTier, () => {
        reset();
        setModalStates({
          ...modalStates,
          showApproveRequestModal: false,
        });
      })
    );
  };
  const [selectedTier, setSelectedTier] = useState(null);
  const [systemTier, setSystemTier] = useState(null);
  const [showDropSel, setShowDropSel] = useState(false);

  const {
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      amount: "",
    },
  });

  useEffect(() => {
    if (requestedLimit) {
      setValue("amount", requestedLimit?.account_information[0]?.balance);
    }

    return () => {
      setValue("amount", "");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedLimit]);
  useEffect(() => {
    // dispatch(getAccountLimit());
    if (requestedLimit) {
      dispatch(getSystemLimits(requestedLimit.account_type));
    }
  }, [requestedLimit]);

  useEffect(() => {
    if (systemLimits) {
      setSystemTier(systemLimits[0]);
      let requestLimit = systemLimits[0]?.find(
        (item) => item.name === requestedLimit?.tier_requested
      );
      setSelectedTier(requestLimit);
    }
  }, [requestedLimit, systemLimits]);
  return (
    <BasicModal
      visibility={showApproveRequestModal}
      onClose={() => {
        // setValue("amount", "");
        setModalStates({ ...modalStates, showApproveRequestModal: false });
      }}
      onOpen={() =>
        setModalStates({
          ...modalStates,
          showApproveRequestModal: true,
        })
      }
      primaryButton=""
      titleClass={true}
      modalTitle="Approve Tier Upgrade"
      secondaryButton=""
      modelContentSection={
        <form onSubmit={handleSubmit(onRejectRequest)}>
          <div className="">
            <div className="text-sm space-x-1 text-glade-gray-500 mb-2">
              <span>Current Tier:</span>
              <span>{requestedLimit?.current_tier}</span>
            </div>
            <div className="text-sm space-x-1 text-glade-gray-500 mb-8">
              <span>Requested Tier:</span>
              <span>{requestedLimit?.tier_requested}</span>
            </div>

            <div className="space-y-2">
              <label className="text-glade-black-800">Tier</label>
              {/* Select field */}
              <div className="w-full">
                <div className="relative">
                  <div
                    className="w-full border border-glade-gray-300 flex p-3 content-center justify-between items-center rounded-md cursor-pointer"
                    onClick={() => setShowDropSel(!showDropSel)}
                  >
                    <div
                      className={`${
                        selectedTier
                          ? "text-glade-black-800"
                          : "text-glade-gray-400"
                      } text-sm`}
                    >
                      {selectedTier?.name}
                    </div>
                    <div>
                      <span className="float-right">
                        <AngleDownSelect />
                      </span>
                    </div>
                  </div>
                  {showDropSel && (
                    <div className="z-1 absolute shadow-lg w-full transition delay-150 duration-1000 ease-in-out rounded-b-md bg-white max-h-56 overflow-y-auto custom-scroll">
                      {systemTier &&
                        systemTier?.map((tier) => (
                          // remove active tier and below from the select list
                          <div
                            className="text-glade-black-800 bg-white px-2 py-3 hover:bg-gray-100 cursor-pointer border-b "
                            key={tier.key}
                            onClick={() => {
                              setSelectedTier(tier);
                              setShowDropSel(false);
                            }}
                          >
                            <div className="flex mb-2 text-base font-normal space-x-2">
                              <span>
                                <DisplayMedal tierName={tier?.name} />
                              </span>
                              <span>{tier.name}</span>
                            </div>
                            <div className="flex text-sm font-normal space-x-2">
                              <div>
                                Daily Limit: NGN
                                {formatNumber(tier.daily_amount)},
                              </div>
                              <span>
                                {tier.no_of_transactions} Transactions
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
            <button
              type="button"
              onClick={() => {
                reset();
                setModalStates({
                  ...modalStates,
                  showApproveRequestModal: false,
                });
              }}
              className="glade-normal-text-two glade-black text-center"
            >
              Cancel
            </button>
            <div>
              <Button.Green
                type="submit"
                title="Approve"
                className="block flex-grow-0"
                loading={isApprovingLimitRequest}
              />
            </div>
          </div>
        </form>
      }
    />
  );
};

export default ApproveLimitModal;

export const formatNumber = (num) => {
  return Number(num)
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

import axios from "axios";
import { toastError } from "../helpers/utils.toast";
import {
	logoutUserLocallyAction,
	refreshUserTokenLocallyAction,
} from "../redux/auth/unAuthActions";
import store from "../redux/store";

// Get user details
const userDetails = () => {
	let authState = store.getState().auth;
	return authState;
};

// Retry Counter
const retryAgain = async () => {
	// max count for retires
	let maxCount = 2;

	// get current retry count, if non assign zero
	let retryCount = (await localStorage.getItem("retryCount")) ?? 0;

	// check if current retry count is less that allowed max count
	let retry = parseInt(retryCount) < maxCount ? true : false;

	// store new retry count
	localStorage.setItem(
		"retryCount",
		retry ? (parseInt(retryCount) + 1).toString() : "0"
	);

	return retry;
};

// Refresh token
const refreshToken = async (token = null) => {
	// Actual path
	// return axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`,{},{headers:{Authorization:'Bearer '+token}})

	// Temporary path
	return axios
		.post(
			`${process.env.REACT_APP_CORE_BASE_URL}/aaa/authenticate/refresh/os6u3ZHOiJ`,
			{},
			{ headers: { Authorization: "Bearer " + token } }
		)
		.then((res) => {
			// Modify data structure for consistency
			res = {
				...res,
				data: {
					...res.data,
					data: { ...res.data.data, data: { token: res.data.data } },
				},
			};

			// Send new token to reducer
			store.dispatch(refreshUserTokenLocallyAction(res));
			return res;
		})
		.catch((error) => {
			return error.response;
		});
};

// Logout user
const logoutUser = async () => {
	store.dispatch(logoutUserLocallyAction());
	// return axios
	// 	.get(`${process.env.REACT_APP_API_URL}/auth/logout`)
	// 	.then((data) => {
	// 		// Send new token to reducer
	// 		store.dispatch(logoutUserLocallyAction());
	// 		return data;
	// 	})
	// 	.catch((error) => {
	// 		store.dispatch(logoutUserLocallyAction());
	// 		return error.response;
	// 	});
};

// Handle success response
const successResponseHandler = (response) => {
	// No content response (204)
	if (response.status === 204) {
		response.data = { data: {} };
	}

	// Log response
	if (process.env.REACT_APP_APP_ENV === "development") {
		console.log(response.data);
	}

	// Additional checks for API that does not utilize the HTTP status code properly
	if (response.data.status === false || response.data.status === "failed") {
		if (response.data.error && response.data.error.code === 401) {
			// Logout user
			logoutUser();
			return Promise.reject(response.data.error);
		}

		// Error message is retrieved from the JSON body.
		const error = new Error(response.data.message);

		// Attach the response instance, in case you decide to access it.
		error.response = response;

		throw error;
	}

	// Return processed response
	return response;
};

// Handle failure response
const failureResponseHandler = async (error) => {
	// Log error response
	if (process.env.REACT_APP_APP_ENV === "development") {
		console.log(error);
	}

	// Request Timeout
	if (error.message.includes("Network")) {
		// alert(`Could not connect to network`);
		toastError("Please check your internet connect and try again.");
		return Promise.reject(error);
	}

	// Request Timeout
	if (error.message.includes("timeout")) {
		// alert(`Could not connect to network`);
		toastError("Request took too long. Please try again.");
		return Promise.reject(error);
	}

	// No network response (ECONNABORTED)
	if (!error.response || error.code === "ECONNABORTED") {
		// alert(`Could not connect to network`);
		return Promise.reject(error);
	}

	// No authorization response (401)
	if (error.response && error.response.status === 401) {
		// Logout user
		logoutUser();
		return Promise.reject(error);
	}

	// Return unprocessed error
	return Promise.reject(error);
};

// Create an axios instance
const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 1200000,
	headers: {},
});

// Add a request interceptor
instance.interceptors.request.use((req) => {
	const { token } = userDetails();
	const { user } = userDetails();
	
	// Add user uuid to headers
	if (user?.user?.uuid) {
		req.headers["admin-uuid"] = user?.user?.uuid;
	}
	if (token) {
		req.headers.Authorization = `Bearer ${token}`;
		// if (isBusiness && !req.headers["business-uuid"] && user.business) {
		//   req.headers["business-uuid"] = user.business.business_uuid;
		// }
	}

	// Reattach the base url
	if (!req.baseURL) {
		req.url = process.env.REACT_APP_API_URL + "/" + req.url;
	}

	// Log requests
	if (process.env.REACT_APP_APP_ENV === "development") {
		console.log(req);
	}

	return req;
});

// Add a response interceptor
instance.interceptors.response.use(
	(response) => {
		return successResponseHandler(response);
	},
	(error) => {
		return failureResponseHandler(error);
	}
);

export default instance;

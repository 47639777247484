import {
	APPROVE_AGENT_REQUEST_FAILURE,
	APPROVE_AGENT_REQUEST_START,
	APPROVE_AGENT_REQUEST_SUCCESS,
	APPROVE_POS_REQUEST_FAILURE,
	APPROVE_POS_REQUEST_START,
	APPROVE_POS_REQUEST_SUCCESS,
	ASSIGN_POS_TERMINAL_FAILURE,
	ASSIGN_POS_TERMINAL_START,
	ASSIGN_POS_TERMINAL_SUCCESS,
	DECLINE_AGENT_REQUEST_FAILURE,
	DECLINE_AGENT_REQUEST_START,
	DECLINE_AGENT_REQUEST_SUCCESS,
	DECLINE_POS_REQUEST_FAILURE,
	DECLINE_POS_REQUEST_START,
	DECLINE_POS_REQUEST_SUCCESS,
	DISABLE_POS_TERMINAL_FAILURE,
	DISABLE_POS_TERMINAL_START,
	DISABLE_POS_TERMINAL_SUCCESS,
	ENABLE_POS_TERMINAL_FAILURE,
	ENABLE_POS_TERMINAL_START,
	ENABLE_POS_TERMINAL_SUCCESS,
	GET_ALL_AGENT_REQUESTS_FAILURE,
	GET_ALL_AGENT_REQUESTS_START,
	GET_ALL_AGENT_REQUESTS_SUCCESS,
	GET_ALL_POS_REQUESTS_FAILURE,
	GET_ALL_POS_REQUESTS_START,
	GET_ALL_POS_REQUESTS_SUCCESS,
	GET_ALL_POS_TERMINALS_FAILURE,
	GET_ALL_POS_TERMINALS_START,
	GET_ALL_POS_TERMINALS_SUCCESS,
	GET_ALL_USERS_FAILURE,
	GET_ALL_USERS_START,
	GET_ALL_USERS_SUCCESS,
	GET_BUSINESS_BY_UUID_FAILURE,
	GET_BUSINESS_BY_UUID_START,
	GET_BUSINESS_BY_UUID_SUCCESS,
	GET_SPECIFIC_AGENT_REQUEST_FAILURE,
	GET_SPECIFIC_AGENT_REQUEST_START,
	GET_SPECIFIC_AGENT_REQUEST_SUCCESS,
	GET_SPECIFIC_POS_REQUEST_FAILURE,
	GET_SPECIFIC_POS_REQUEST_START,
	GET_SPECIFIC_POS_REQUEST_SUCCESS,
	GET_SPECIFIC_POS_TERMINAL_FAILURE,
	GET_SPECIFIC_POS_TERMINAL_START,
	GET_SPECIFIC_POS_TERMINAL_SUCCESS,
	GET_USER_BY_UUID_FAILURE,
	GET_USER_BY_UUID_START,
	GET_USER_BY_UUID_SUCCESS,
	UNASSIGN_POS_TERMINAL_FAILURE,
	UNASSIGN_POS_TERMINAL_START,
	UNASSIGN_POS_TERMINAL_SUCCESS,
} from "./types";

const initialState = {
	isLoadingAllPosTerminals: true,
	isLoadingSpecificPosTerminal: true,
	isLoadingSearchedAccount: false,
	isLoadingAllPosRequests: true,
	isLoadingSpecificPosRequest: true,
	isLoadingAllAgentRequests: true,
	isLoadingSpecificAgentRequest: true,
	isLoadingUsers: false,
	isAssigningPosTerminal: false,
	isUnassigningPosTerminal: false,
	isDisablingPosTerminal: false,
	isEnablingPosTerminal: false,
	isApprovingPosRequest: false,
	isDecliningPosRequest: false,
	isApprovingAgentRequest: false,
	isDecliningAgentRequest: false,
	allUsers: [],
	allPosTerminals: [],
	allPosRequests: [],
	allAgentRequests: [],
	specificPosTerminal: {},
	specificPosRequest: {},
	specificAgentRequest: {},
	searchedAccount: {},
};

export default function posReducer(state = initialState, action) {
	switch (action.type) {
		// GET ALL USERS
		case GET_ALL_USERS_START:
			return {
				...state,
				isLoadingAllUsers: true,
			};

		case GET_ALL_USERS_SUCCESS:
			return {
				...state,
				isLoadingAllUsers: false,
				allUsers: action.payLoad,
			};

		case GET_ALL_USERS_FAILURE:
			return {
				...state,
				isLoadingAllUsers: false,
			};

		// GET USER BY UUID
		case GET_USER_BY_UUID_START:
			return {
				...state,
				isLoadingSearchedAccount: true,
			};

		case GET_USER_BY_UUID_SUCCESS:
			return {
				...state,
				isLoadingSearchedAccount: false,
				searchedAccount: action.payLoad,
			};

		case GET_USER_BY_UUID_FAILURE:
			return {
				...state,
				isLoadingSearchedAccount: false,
				searchedAccount: {},
			};

		// GET BUSINESS BY UUID
		case GET_BUSINESS_BY_UUID_START:
			return {
				...state,
				isLoadingSearchedAccount: true,
			};

		case GET_BUSINESS_BY_UUID_SUCCESS:
			return {
				...state,
				isLoadingSearchedAccount: false,
				searchedAccount: action.payLoad,
			};

		case GET_BUSINESS_BY_UUID_FAILURE:
			return {
				...state,
				isLoadingSearchedAccount: false,
				searchedAccount: {},
			};

		// GET ALL POS TERMINALS
		case GET_ALL_POS_TERMINALS_START:
			return {
				...state,
				isLoadingAllPosTerminals: true,
			};

		case GET_ALL_POS_TERMINALS_SUCCESS:
			return {
				...state,
				isLoadingAllPosTerminals: false,
				allPosTerminals: action.payLoad,
			};

		case GET_ALL_POS_TERMINALS_FAILURE:
			return {
				...state,
				isLoadingAllPosTerminals: false,
			};

		// Get specific pos terminal
		case GET_SPECIFIC_POS_TERMINAL_START:
			return {
				...state,
				isLoadingSpecificPosTerminal: true,
			};

		case GET_SPECIFIC_POS_TERMINAL_SUCCESS:
			return {
				...state,
				isLoadingSpecificPosTerminal: false,
				specificPosTerminal: action.payLoad,
			};

		case GET_SPECIFIC_POS_TERMINAL_FAILURE:
			return {
				...state,
				isLoadingSpecificPosTerminal: false,
			};

		// GET ALL POS REQUESTS
		case GET_ALL_POS_REQUESTS_START:
			return {
				...state,
				isLoadingAllPosRequests: true,
			};

		case GET_ALL_POS_REQUESTS_SUCCESS:
			return {
				...state,
				isLoadingAllPosRequests: false,
				allPosRequests: action.payLoad,
			};

		case GET_ALL_POS_REQUESTS_FAILURE:
			return {
				...state,
				isLoadingAllPosRequests: false,
			};

		// Get specific pos request
		case GET_SPECIFIC_POS_REQUEST_START:
			return {
				...state,
				isLoadingSpecificPosRequest: true,
			};

		case GET_SPECIFIC_POS_REQUEST_SUCCESS:
			return {
				...state,
				isLoadingSpecificPosRequest: false,
				specificPosRequest: action.payLoad,
			};

		case GET_SPECIFIC_POS_REQUEST_FAILURE:
			return {
				...state,
				isLoadingSpecificPosRequest: false,
			};

		// GET ALL Agent REQUESTS
		case GET_ALL_AGENT_REQUESTS_START:
			return {
				...state,
				isLoadingAllAgentRequests: true,
			};

		case GET_ALL_AGENT_REQUESTS_SUCCESS:
			return {
				...state,
				isLoadingAllAgentRequests: false,
				allAgentRequests: action.payLoad,
			};

		case GET_ALL_AGENT_REQUESTS_FAILURE:
			return {
				...state,
				isLoadingAllAgentRequests: false,
			};

		// Get specific agent request
		case GET_SPECIFIC_AGENT_REQUEST_START:
			return {
				...state,
				isLoadingSpecificAgentRequest: true,
			};

		case GET_SPECIFIC_AGENT_REQUEST_SUCCESS:
			return {
				...state,
				isLoadingSpecificAgentRequest: false,
				specificAgentRequest: action.payLoad,
			};

		case GET_SPECIFIC_AGENT_REQUEST_FAILURE:
			return {
				...state,
				isLoadingSpecificAgentRequest: false,
			};

		// approve agent request
		case APPROVE_AGENT_REQUEST_START:
			return {
				...state,
				isApprovingAgentRequest: true,
			};

		case APPROVE_AGENT_REQUEST_SUCCESS:
			return {
				...state,
				isApprovingAgentRequest: false,
			};

		case APPROVE_AGENT_REQUEST_FAILURE:
			return {
				...state,
				isApprovingAgentRequest: false,
			};

		// decline agent request
		case DECLINE_AGENT_REQUEST_START:
			return {
				...state,
				isDecliningAgentRequest: true,
			};

		case DECLINE_AGENT_REQUEST_SUCCESS:
			return {
				...state,
				isDecliningAgentRequest: false,
			};

		case DECLINE_AGENT_REQUEST_FAILURE:
			return {
				...state,
				isDecliningAgentRequest: false,
			};

		// approve pos request
		case APPROVE_POS_REQUEST_START:
			return {
				...state,
				isApprovingPosRequest: true,
			};

		case APPROVE_POS_REQUEST_SUCCESS:
			return {
				...state,
				isApprovingPosRequest: false,
			};

		case APPROVE_POS_REQUEST_FAILURE:
			return {
				...state,
				isApprovingPosRequest: false,
			};

		// decline pos request
		case DECLINE_POS_REQUEST_START:
			return {
				...state,
				isDecliningPosRequest: true,
			};

		case DECLINE_POS_REQUEST_SUCCESS:
			return {
				...state,
				isDecliningPosRequest: false,
			};

		case DECLINE_POS_REQUEST_FAILURE:
			return {
				...state,
				isDecliningPosRequest: false,
			};

		// enabling pos terminal
		case ENABLE_POS_TERMINAL_START:
			return {
				...state,
				isEnablingPosTerminal: true,
			};

		case ENABLE_POS_TERMINAL_SUCCESS:
			return {
				...state,
				isEnablingPosTerminal: false,
			};

		case ENABLE_POS_TERMINAL_FAILURE:
			return {
				...state,
				isEnablingPosTerminal: false,
			};

		// disabling pos terminal
		case DISABLE_POS_TERMINAL_START:
			return {
				...state,
				isDisablingPosTerminal: true,
			};

		case DISABLE_POS_TERMINAL_SUCCESS:
			return {
				...state,
				isDisablingPosTerminal: false,
			};

		case DISABLE_POS_TERMINAL_FAILURE:
			return {
				...state,
				isDisablingPosTerminal: false,
			};

		// assigning pos terminal
		case ASSIGN_POS_TERMINAL_START:
			return {
				...state,
				isAssigningPosTerminal: true,
			};

		case ASSIGN_POS_TERMINAL_SUCCESS:
			return {
				...state,
				isAssigningPosTerminal: false,
			};

		case ASSIGN_POS_TERMINAL_FAILURE:
			return {
				...state,
				isAssigningPosTerminal: false,
			};

		// unassigning pos terminal
		case UNASSIGN_POS_TERMINAL_START:
			return {
				...state,
				isUnassigningPosTerminal: true,
			};

		case UNASSIGN_POS_TERMINAL_SUCCESS:
			return {
				...state,
				isUnassigningPosTerminal: false,
			};

		case UNASSIGN_POS_TERMINAL_FAILURE:
			return {
				...state,
				isUnassigningPosTerminal: false,
			};

		default:
			return state;
	}
}

import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { useDispatch } from "react-redux";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { TextSkeleton } from "../../components/skeletons";

import useSettlements from "../../hooks/useSettlements";
import {
	getSpecificUnsettledSettlement,
	processSingleUnsettledNGNSettlement,
	processSingleUnsettledUSDSettlement,
} from "../../redux/settlements/settlementsActions";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import { FiCopy } from "react-icons/fi";
import { copyTextToClipboard, fixDateForAllBrowsers, truncateRef } from "../../helpers/helper";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import { Table } from "../../components/table/Table";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import DownloadAsCSVButton from "../../helpers/DownloadAsCSV";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { FormError } from "../../validation";

const ConfirmUSDSettlementModal = ({
	setShowProcessUSDSettlementModal,
	showProcessUSDSettlementModal,
	selectedSettlement,
	settlementDetails,
	totalSettlement,
	exchangeRate,
	isProcessingUnsettledSettlement,
}) => {
	const dispatch = useDispatch();

	const onProcessSettlements = () => {
		dispatch(
			processSingleUnsettledUSDSettlement({
				rate: exchangeRate,
				data: [{ ...selectedSettlement }],
			})
		);
	};

	return (
		<BasicModal
			visibility={showProcessUSDSettlementModal}
			onClose={() => setShowProcessUSDSettlementModal(false)}
			onOpen={() => setShowProcessUSDSettlementModal(true)}
			primaryButton=""
			modalTitle="Payout Confirmation"
			secondaryButton=""
			modelContentSection={
				<>
					<div className="py-4 px-0">
						<h4 className="text-base font-inter glade-black mb-6 text-center">
							Are you sure you want to settle{" "}
							<p className="font-bold text-lg">
								{settlementDetails?.account_name}
							</p>
						</h4>
						<h4 className="text-base font-inter glade-black mb-2 text-center">
							with
							<p className="font-bold text-lg">
								USD {Number(totalSettlement).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
							</p>
						</h4>
						<h4 className="text-base font-inter glade-black mb-6 text-center">
							as
							<p className="font-bold text-lg">
								USD{" "}
								{(
									Number(totalSettlement) * Number(exchangeRate)
								).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
							</p>
						</h4>

						<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
							<div>
								<Button.Gray
									type="button"
									title="Go Back"
									onClick={() => {
										setShowProcessUSDSettlementModal(false);
									}}
									className="glade-normal-text-two glade-black text-center block flex-grow-0"
								/>
							</div>
							<div>
								<Button.Dark
									type="submit"
									title="Payout"
									className="block flex-grow-0"
									onClick={onProcessSettlements}
									loading={isProcessingUnsettledSettlement}
								/>
							</div>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default function UnsettledSettlementDetail() {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(0);
	const selectedSettlement = JSON.parse(
		localStorage.getItem("glade_selected_settlement")
	);
	const [showSettleNGNModal, setShowSettleNGNModal] = useState(false);

	const [showSettleUSDModal, setShowSettleUSDModal] = useState(false);
	const [showProcessUSDSettlementModal, setShowProcessUSDSettlementModal] =
		useState(false);
	const [showCannotSettleModal, setShowCannotSettleModal] = useState(false);
	const [canSettle, setCanSettle] = useState(false);
	const [exchangeRate, setExchangeRate] = useState(null);
	const [totalSettlement, setTotalSettlement] = useState(0);

	const {
		isLoadingSpecificUnsettledSettlement: loading,
		specificUnsettledSettlement: details,
		isProcessingUnsettledSettlement,
	} = useSettlements();

	const info = details?.info;
	const transactions = details?.transactions;

	const filterInstance = usePaginationFilterParams({
		initialFilters: {
			search: "",
			date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
			date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
		},
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, search } = filters;

	const [, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_from, date_to, search]);

	React.useMemo(() => {
		dispatch(
			getSpecificUnsettledSettlement(selectedSettlement, {
				params: {
					...(search
						? { search }
						: {
								date_from,
								date_to,
								// paginate: 1,
								// page,
								// per_page,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		// page,
		// per_page,
		search,
	]);

	// // Clear query param on render
	// useEffect(() => {
	//   urlSearchParamsHistory.replace({});
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace({});
	};

	const onProcessSettlements = () => {
		dispatch(processSingleUnsettledNGNSettlement([{ ...selectedSettlement }]));
	};

	const checkIfAllSettlementsAreEligible = () => {
		// disabling check if settlement is NGN
		if (info?.[0]?.currency === "NGN") {
			setCanSettle(true);
		} else {
			if (
				dfn.isAfter(new Date(), dfn.addDays(new Date(info?.[0]?.txn_date), 7))
			) {
				setCanSettle(true);
			} else {
				setCanSettle(false);
			}
		}
		setTotalSettlement(info?.[0]?.amount_to_pay ?? 0);
	};

	useEffect(() => {
		if (info?.length > 0) {
			checkIfAllSettlementsAreEligible();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [info]);

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			rate: "",
		},
	});

	const onSubmitExchangeRate = (data) => {
		setExchangeRate(data.rate);
		setShowProcessUSDSettlementModal(true);
	};

	return (
		<div className="p-3 flex flex-col">
			<div className="mb-6">
				<BreadCrumbs title={"Settlements"} goBack />
			</div>

			<div className="flex flex-col bg-white rounded-sm p-8 mt-6 mb-8">
				<div className="py-3 border-b-2 border-solid border-gray-100">
					<p className="glade-black glade-normal-text-two">
						Settlement Details
					</p>
				</div>
				<div className="grid grid-cols-4 gap-4 py-3">
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Account Name
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{info?.[0]?.account_name ?? "Not available"}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">Fees</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{info?.[0]?.currency ?? "NGN"}{" "}
								{Number(info?.[0]?.fees ?? 0)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Total Amount
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{info?.[0]?.currency ?? "NGN"}{" "}
								{Number(info?.[0]?.total_amount ?? 0)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Amount to pay
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{info?.[0]?.currency ?? "NGN"}{" "}
								{Number(info?.[0]?.amount_to_pay ?? 0)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
							</h6>
						)}
					</div>
					{info?.[0]?.no_of_txns && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Number of Txns
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1">
									{info?.[0]?.no_of_txns}
								</h6>
							)}
						</div>
					)}
					{info?.[0]?.settlement_date && (
						<div className="flex flex-col">
							<h5 className="glade-normal-text-two glade-ash py-1">
								Settlement Date
							</h5>
							{loading ? (
								<TextSkeleton width="100px" />
							) : (
								<h6 className="glade-normal-text-three glade-black py-1 capitalize">
									{dfn.format(
										new Date(info?.[0]?.settlement_date),
										"MMM d, yyyy"
									)}
								</h6>
							)}
						</div>
					)}
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Payout Bank
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<h6 className="glade-normal-text-three glade-black py-1">
								{info?.[0]?.account_info?.bank_name ?? "Not available"}
							</h6>
						)}
					</div>
					<div className="flex flex-col">
						<h5 className="glade-normal-text-two glade-ash py-1">
							Payout Account
						</h5>
						{loading ? (
							<TextSkeleton width="100px" />
						) : (
							<>
								<h6 className="glade-normal-text-three glade-black py-1 uppercase break-words">
									{`${info?.[0]?.account_info?.account_name ?? ""} ${
										info?.[0]?.account_info?.account_number ?? ""
									}`}
								</h6>
							</>
						)}
					</div>
				</div>
			</div>

			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				filterFields={["date"]}
				filterCallback={handleChange}
				disabled={loading}
				title={`Transactions ${
					!loading && transactions?.length ? "- " + transactions?.length : ""
				}`}
				actionButton={
					<div className="flex items-center">
						<Button.Dark
							title={"Settle Transactions"}
							disabled={!selectedSettlement?.owner_uuid}
							onClick={() =>
								canSettle
									? info?.[0]?.currency === "NGN"
										? setShowSettleNGNModal(true)
										: setShowSettleUSDModal(true)
									: setShowCannotSettleModal(true)
							}
						/>
						<div>
							<DownloadAsCSVButton
								fileName="Unsettled Settlements"
								getWorksheets={({ xlsx }) => {
									return {
										data: xlsx.utils.json_to_sheet(
											transactions?.map((value, i) => ({
												"S/N": `${i + 1}`,
												"Account Name": value?.account_name,
												Currency: value?.currency,
												"Number of Txns": value?.no_of_txns,
												"Total Amount": value?.total_amount,
												Fees: value?.fees,
												"Amount to pay": value?.amount_to_pay,
												"Settlement Date": value?.settlement_date,
												"Transaction Date": value?.txn_date,
											}))
										),
									};
								}}
								disabled={loading}
								className="whitespace-pre ml-4"
							/>
						</div>
					</div>
				}
				searchBarPlaceholder={"Account Name or Payout Reference"}
			/>
			<Table
				columns={columns}
				data={
					typeof transactions === "object" && !Array.isArray(transactions)
						? transactions?.data
						: transactions
				}
				empty_message="No transactions"
				empty_sub_message=""
				current_page={currentPage}
				loading={loading}
				setCurrentPage={setCurrentPage}
			/>

			{/* Process NGN settlement modal */}
			<BasicModal
				visibility={showSettleNGNModal}
				onClose={() => setShowSettleNGNModal(false)}
				onOpen={() => setShowSettleNGNModal(true)}
				primaryButton=""
				modalTitle="Payout Confirmation"
				secondaryButton=""
				modelContentSection={
					<>
						<div className="py-4 px-0">
							<h4 className="text-base font-inter glade-black mb-6 text-center">
								Are you sure you want to settle{" "}
								<p className="font-bold text-lg">{info?.[0]?.account_name}</p>
							</h4>
							<h4 className="text-base font-inter glade-black mb-2 text-center">
								with
								<p className="font-bold text-lg">
									NGN {Number(totalSettlement).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
								</p>
							</h4>
							<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
								<div>
									<Button.Gray
										type="button"
										title="Cancel"
										onClick={() => setShowSettleNGNModal(false)}
										className="glade-normal-text-two glade-black text-center block flex-grow-0"
									/>
								</div>
								<div>
									<Button.Dark
										type="button"
										title="Payout"
										className="block flex-grow-0"
										onClick={onProcessSettlements}
										loading={isProcessingUnsettledSettlement}
									/>
								</div>
							</div>
						</div>
					</>
				}
			/>

			{/* Process USD modal */}
			<BasicModal
				visibility={showSettleUSDModal}
				onClose={() => setShowSettleUSDModal(false)}
				onOpen={() => setShowSettleUSDModal(true)}
				primaryButton=""
				modalTitle="Payout Confirmation"
				secondaryButton=""
				modelContentSection={
					<>
						<form onSubmit={handleSubmit(onSubmitExchangeRate)} className="">
							<div className="py-4 px-0">
								<h4 className="text-base font-inter glade-black mb-6 text-center">
									Are you sure you want to settle{" "}
									<p className="font-bold text-lg">{info?.[0]?.account_name}</p>
								</h4>
								<h4 className="text-base font-inter glade-black mb-6 text-center">
									with
									<p className="font-bold text-lg">
										USD {Number(totalSettlement).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
									</p>
								</h4>

								<p className="text-xs font-inter glade-black mb-4 text-center">
									Enter the conversion value of 1 USD to NGN for this merchant
									settlement
								</p>

								<div className="relative">
									<div
										className="absolute inset-y-0 left-0 px-3 py-2.5 flex items-center pointer-events-none"
										style={{
											background: "#FBFBFB",
											border: "1px solid #E4E4F3",
										}}
									>
										<span className="glade-black text-sm py-2 font-inter">
											1 USD =
										</span>
									</div>
									<Controller
										render={({ field: { onChange, value } }) => (
											<NumberFormat
												thousandSeparator={true}
												onValueChange={(v) => {
													onChange(Number(v.value));
												}}
												variant="outlined"
												placeholder="Enter Amount"
												defaultValue=""
												value={value}
												className="mt-1 block w-full pl-24 py-2 border-gray-300 rounded-sm glade-normal-text-two"
											/>
										)}
										rules={{
											required: "Enter an amount",
											min: {
												value: 1,
												message: "Minimum amount is USD 1",
											},
										}}
										name="rate"
										control={control}
									/>
								</div>
								<FormError errors={errors} name="rate" />

								<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
									<button
										type="button"
										onClick={() => {
											reset();
											setShowSettleUSDModal(false);
										}}
										className="glade-normal-text-two glade-black text-center"
									>
										Cancel
									</button>
									<div>
										<Button.Dark
											type="submit"
											title="Continue"
											className="block flex-grow-0"
											// onClick={onProcessSettlements}
											// loading={isProcessingUnsettledSettlement}
										/>
									</div>
								</div>
							</div>
						</form>

						{/* Confirm Process Modal */}
						<ConfirmUSDSettlementModal
							setShowProcessUSDSettlementModal={
								setShowProcessUSDSettlementModal
							}
							settlementDetails={info?.[0]}
							showProcessUSDSettlementModal={showProcessUSDSettlementModal}
							selectedSettlement={selectedSettlement}
							totalSettlement={totalSettlement}
							exchangeRate={exchangeRate}
							isProcessingUnsettledSettlement={isProcessingUnsettledSettlement}
						/>
					</>
				}
			/>

			{/* Can't payout modal */}
			<BasicModal
				visibility={showCannotSettleModal}
				onClose={() => setShowCannotSettleModal(false)}
				onOpen={() => setShowCannotSettleModal(true)}
				primaryButton=""
				modalTitle="Payout Warning"
				secondaryButton=""
				modalStyle={{
					width: "357px",
				}}
				modelContentSection={
					<div
						className="p-2.5"
						style={{
							background: "#F9C9001F",
							border: "1px solid #F9C900",
						}}
					>
						<h3
							className="text-sm font-bold font-inter text-center mb-2.5"
							style={{
								color: "#F9C900",
							}}
						>
							Not up to a week
						</h3>
						<p className="glade-black text-sm font-inter text-center">
							Sorry, you can’t settle this transaction as transactions can only
							be settled after 7 days.
						</p>
					</div>
				}
			/>
		</div>
	);
}

const columns = [
	{
		accessor: "txn_ref",
		Header: "Transaction Reference",
		Cell: ({ value }) => (
			<div className="flex items-center">
				<span className="glade-normal-text-two">{truncateRef(value)}</span>
				<FiCopy
					size={16}
					color="#AFAFAF"
					className="ml-3 cursor-pointer"
					onClick={() => copyTextToClipboard(value)}
				/>
			</div>
		),
	},
	{
		accessor: "total_amount",
		Header: "Total Amount",
		Cell: ({ value, row }) => (
			<span className="glade-normal-text-two">
				{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
			</span>
		),
	},
	{
		accessor: "fee",
		Header: "Fees",
		Cell: ({ value, row }) => (
			<span className="glade-normal-text-two">
				{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
			</span>
		),
	},
	{
		accessor: "amount_to_pay",
		Header: "Amount to pay",
		Cell: ({ value, row }) => (
			<span className="glade-normal-text-two">
				{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
			</span>
		),
	},
	{
		accessor: "transaction_date",
		Header: "Transaction Date",
		Cell: ({ value }) => (
			<span className="glade-normal-text-two">
				{dfn.format(new Date(fixDateForAllBrowsers(value)), "MMM d, yyyy hh:mm:ss a")}
			</span>
		),
	},
];

import {
  CREATE_DRAFT_NOTIFICATION_FAILURE,
  CREATE_DRAFT_NOTIFICATION_START,
  CREATE_DRAFT_NOTIFICATION_SUCCESS,
  DELETE_SPECIFIC_DRAFT_NOTIFICATION_FAILURE,
  DELETE_SPECIFIC_DRAFT_NOTIFICATION_START,
  DELETE_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS,
  EDIT_SPECIFIC_DRAFT_NOTIFICATION_FAILURE,
  EDIT_SPECIFIC_DRAFT_NOTIFICATION_START,
  EDIT_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS,
  GET_ALL_DRAFT_NOTIFICATIONS_FAILURE,
  GET_ALL_DRAFT_NOTIFICATIONS_START,
  GET_ALL_DRAFT_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATION_CATEGORIES_FAILURE,
  GET_ALL_NOTIFICATION_CATEGORIES_START,
  GET_ALL_NOTIFICATION_CATEGORIES_SUCCESS,
  GET_ALL_SENT_NOTIFICATIONS_FAILURE,
  GET_ALL_SENT_NOTIFICATIONS_START,
  GET_ALL_SENT_NOTIFICATIONS_SUCCESS,
  GET_SPECIFIC_DRAFT_NOTIFICATION_FAILURE,
  GET_SPECIFIC_DRAFT_NOTIFICATION_START,
  GET_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS,
  GET_SPECIFIC_SENT_NOTIFICATION_FAILURE,
  GET_SPECIFIC_SENT_NOTIFICATION_START,
  GET_SPECIFIC_SENT_NOTIFICATION_SUCCESS,
  SENDING_MULTIPLE_NOTIFICATIONS_FAILURE,
  SENDING_MULTIPLE_NOTIFICATIONS_START,
  SENDING_MULTIPLE_NOTIFICATIONS_SUCCESS,
  SENDING_NOTIFICATION_FAILURE,
  SENDING_NOTIFICATION_START,
  SENDING_NOTIFICATION_SUCCESS,
} from "./types";

const initialState = {
  isLoadingAllSentNotifications: true,
  isLoadingAllNotificationCategories: true,
  isLoadingAllDraftNotifications: true,
  isLoadingSpecificSentNotification: true,
  isLoadingSpecificDraftNotification: true,
  isSendingNotification: false,
  isSendingMultipleNotifications: false,
  isCreatingDraftNotification: false,
  isEditingDraftNotification: false,
  isDeletingDraftNotification: false,
  allNotificationCategories: [],
  allSentNotifications: [],
  allDraftNotifications: [],
  specificSentNotification: {},
  specificDraftNotification: {},
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    // GET ALL NOTIFICATION CATEGORIES
    case GET_ALL_NOTIFICATION_CATEGORIES_START:
      return {
        ...state,
        isLoadingAllNotificationCategories: true,
      };

    case GET_ALL_NOTIFICATION_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoadingAllNotificationCategories: false,
        allNotificationCategories: action.payLoad,
      };

    case GET_ALL_NOTIFICATION_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoadingAllNotificationCategories: false,
      };

    // GET ALL SENT NOTIFICATIONS
    case GET_ALL_SENT_NOTIFICATIONS_START:
      return {
        ...state,
        isLoadingAllSentNotifications: true,
      };

    case GET_ALL_SENT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoadingAllSentNotifications: false,
        allSentNotifications: action.payLoad,
      };

    case GET_ALL_SENT_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoadingAllSentNotifications: false,
      };

    // GET SPECIFIC SENT NOTIFICATIONS
    case GET_SPECIFIC_SENT_NOTIFICATION_START:
      return {
        ...state,
        isLoadingSpecificSentNotification: true,
      };

    case GET_SPECIFIC_SENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoadingSpecificSentNotification: false,
        specificSentNotification: action.payLoad,
      };

    case GET_SPECIFIC_SENT_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoadingSpecificSentNotification: false,
      };

    // SEND NOTIFICATION
    case SENDING_NOTIFICATION_START:
      return {
        ...state,
        isSendingNotification: true,
      };

    case SENDING_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isSendingNotification: false,
      };

    case SENDING_NOTIFICATION_FAILURE:
      return {
        ...state,
        isSendingNotification: false,
      };

    // SEND NOTIFICATION
    case SENDING_MULTIPLE_NOTIFICATIONS_START:
      return {
        ...state,
        isSendingMultipleNotifications: true,
      };

    case SENDING_MULTIPLE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isSendingMultipleNotifications: false,
      };

    case SENDING_MULTIPLE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isSendingMultipleNotifications: false,
      };

    // GET ALL DRAFT NOTIFICATIONS
    case GET_ALL_DRAFT_NOTIFICATIONS_START:
      return {
        ...state,
        isLoadingAllDraftNotifications: true,
      };

    case GET_ALL_DRAFT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoadingAllDraftNotifications: false,
        allDraftNotifications: action.payLoad,
      };

    case GET_ALL_DRAFT_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoadingAllDraftNotifications: false,
      };

    // GET SPECIFIC DRAFT NOTIFICATIONS
    case GET_SPECIFIC_DRAFT_NOTIFICATION_START:
      return {
        ...state,
        isLoadingSpecificDraftNotification: true,
      };

    case GET_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoadingSpecificDraftNotification: false,
        specificDraftNotification: action.payLoad,
      };

    case GET_SPECIFIC_DRAFT_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoadingSpecificDraftNotification: false,
      };

    // EDIT SPECIFIC DRAFT NOTIFICATIONS
    case EDIT_SPECIFIC_DRAFT_NOTIFICATION_START:
      return {
        ...state,
        isEditingDraftNotification: true,
      };

    case EDIT_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isEditingDraftNotification: false,
      };

    case EDIT_SPECIFIC_DRAFT_NOTIFICATION_FAILURE:
      return {
        ...state,
        isEditingDraftNotification: false,
      };

    // DELETE SPECIFIC DRAFT NOTIFICATIONS
    case DELETE_SPECIFIC_DRAFT_NOTIFICATION_START:
      return {
        ...state,
        isDeletingDraftNotification: true,
      };

    case DELETE_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isDeletingDraftNotification: false,
      };

    case DELETE_SPECIFIC_DRAFT_NOTIFICATION_FAILURE:
      return {
        ...state,
        isDeletingDraftNotification: false,
      };

    // CREATE SPECIFIC DRAFT NOTIFICATIONS
    case CREATE_DRAFT_NOTIFICATION_START:
      return {
        ...state,
        isCreatingDraftNotification: true,
      };

    case CREATE_DRAFT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isCreatingDraftNotification: false,
      };

    case CREATE_DRAFT_NOTIFICATION_FAILURE:
      return {
        ...state,
        isCreatingDraftNotification: false,
      };

    default:
      return state;
  }
}

import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as dfn from "date-fns";

import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import { updateFee } from "../../redux/fees/feesActions";
import useFees from "../../hooks/useFees";

const EditFeeModal = ({
	modalStates,
	setModalStates,
	showEditFeeModal,
	selectedFee,
	setSelectedFee,
}) => {
	const dispatch = useDispatch();

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm();

	const { isUpdatingFee } = useFees();

	const onUpdateFee = (data) => {
		const payload = {
			...selectedFee,
			fee_percentage: data.percent ?? selectedFee?.fee_percentage,
			fee_fixed: data?.amount ?? selectedFee?.fee_fixed,
		};

		dispatch(updateFee(selectedFee.id, payload));
	};

	return (
		<BasicModal
			visibility={showEditFeeModal}
			onClose={() => {
				reset();
				setModalStates({ ...modalStates, showEditFeeModal: false });
				setSelectedFee({});
			}}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditFeeModal: true,
				})
			}
			primaryButton=""
			modalTitle={`Edit ${selectedFee?.display_name ?? selectedFee?.fee_code?.replace("_", " ")
				} fee`}
			secondaryButton=""
			modelContentSection={
				<>
					<form onSubmit={handleSubmit(onUpdateFee)} className="">
						<div className="mb-4">
							{/* Fixed amount */}
							{selectedFee?.fee_fixed ? (
								<>
									<label
										htmlFor="amount"
										className="block glade-small-text-two capitalize"
									>
										Fixed Fee
									</label>
									<div className="mt-2">
										<div className="">
											<div className="relative">
												<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
													<span className="glade-black sm:text-sm py-2">
														NGN
													</span>
												</div>
												<input
													type="number"
													id="amount"
													name="amount"
													{...register("amount", {
														required: "Enter a valid amount",
														min: {
															value: 0,
															message: "Enter a valid amount",
														},
													})}
													defaultValue={
														Number(selectedFee?.fee_fixed).toFixed(2) ?? 0
													}
													placeholder="Enter a amount"
													className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
												/>
											</div>
										</div>
									</div>
								</>
							) : null}

							{/* Percent */}
							{selectedFee?.fee_percentage ? (
								<>
									<label
										htmlFor="percent"
										className="block glade-small-text-two capitalize mt-4"
									>
										Percentage Fee
									</label>
									<div className="mt-2">
										<div className="">
											<div className="relative">
												<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
													<span className="glade-black sm:text-sm py-2">%</span>
												</div>
												<input
													type="number"
													id="percent"
													name="percent"
													{...register("percent", {
														required: "Enter a valid percent",
														min: {
															value: 0.0,
															message: "Enter a valid percent",
														},
														max: {
															value: 100,
															message: "Enter a valid percent",
														},
													})}
													step="0.1"
													defaultValue={selectedFee?.fee_percentage ?? 0}
													placeholder="Enter a percentage"
													className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
												/>
											</div>
										</div>
									</div>
								</>
							) : null}

							<div className="flex items-center">
								<div className="w-1/2 pr-2">
									<FormError errors={errors} name="percent" />
								</div>
								<div className="w-1/2 pl-2">
									<FormError errors={errors} name="amount" />
								</div>
							</div>
						</div>

						<div className="flex items-center justify-between -mt-2 pb-8 border-b border-solid border-gray-200">
							<h6 className="glade-small-text-one opacity-50 ml-1">
								{selectedFee?.updated_at && (
									<>
										Last changed on{" "}
										{dfn.format(
											new Date(selectedFee?.updated_at),
											"MMM d, yyyy"
										)}
									</>
								)}
							</h6>
							{/* <h6
								className="glade-small-text-two glade-blue-normal cursor-pointer"
								tabIndex={0}
								onClick={revertFeeToDefault}
							>
								Revert to default
							</h6> */}
						</div>

						<div className="flex -mb-8 items-center justify-between p-4  -mx-5">
							<button
								type="button"
								onClick={() => {
									reset();
									setModalStates({
										...modalStates,
										showEditFeeModal: false,
									});
									setSelectedFee({});
								}}
								className="glade-normal-text-two glade-black text-center"
							>
								Cancel
							</button>
							<div>
								<Button.Dark
									type="submit"
									title="Update"
									className="block flex-grow-0"
									loading={isUpdatingFee}
								/>
							</div>
						</div>
					</form>
				</>
			}
		/>
	);
};

export default EditFeeModal;

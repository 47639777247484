import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useForm } from "react-hook-form";
import { FormWizard, FormWizardSection } from '../../components/form/FormWizard';
import IndividualInput from '../../components/inputs/IndividualInput';
import {BsChevronLeft} from 'react-icons/bs';
// import { email_required, FormError, password } from '../../validation';
// import { registerUserAction } from '../../redux/auth/authActions';
import { Redirect } from 'react-router-dom';
import CarouselAuth from '../../components/carousel/CarouselAuth';

export default function Register() {

	const formWizardRef = React.useRef(null);
    const [formWizardCurrentStep, setFormWizardCurrentStep] = React.useState(0);
	// eslint-disable-next-line no-unused-vars
    const dispatch = useDispatch();
	const user = useSelector(state => state?.auth?.user);

    return (
		<React.Fragment>
			{user.token ? <Redirect to="/dashboard/home" push /> : null}
			<div className="min-h-screen bg-white flex">
				<div className="hidden lg:block relative">
					<CarouselAuth />
				</div>
				<div className="w-2/3 flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<FormWizard ref={formWizardRef} step={formWizardCurrentStep} setStep={setFormWizardCurrentStep}>

						{/* BVN Section */}
						<FormWizardSection position={0} currentStep={formWizardCurrentStep}>
							<div className="mx-auto w-full max-w-sm lg:w-96">
								<div>
									<h2 className="mt-6 glade-heading-one glade-black">Get Started</h2>
									<p className="mt-2 glade-subtitle-two glade-black">Set up your account in a few minutes.</p>
								</div>
								<div className="mt-8">
									<div className="mt-6">
										<div className="space-y-6">
											<div>
												<label htmlFor="bvn" className="block glade-small-text-two glade-black">BVN</label>
												<div className="mt-1">
													<input
														id="bvn"
														name="bvn"
														type="number"
														min="0"
														placeholder="Enter BVN"
														autoComplete="bvn"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
													/>
												</div>
												<div className="flex text-sm mt-1">
													<span className="glade-small-text-one glade-black mr-1">Forgot your BVN? Dial</span>
													<span className="glade-small-text-one glade-orange">*565*0#</span>
												</div>
											</div>

											<div>
												<button
													onClick={()=>{ formWizardRef.current.next(); }}
													type="button"
													className="glade-normal-text-three glade-button-blue w-full flex justify-center py-2 px-4 border border-transparent rounded-sm text-white">
													Continue
												</button>
											</div>

											<div>
												<p className="mt-2 flex justify-center glade-normal-text-two">
													<span className="mr-1 glade-black">Already have an account?</span>
													<a href="/auth/login" className="glade-normal-text-two glade-orange">Sign In</a>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</FormWizardSection>

						{/* Email Section */}
						<FormWizardSection position={1} currentStep={formWizardCurrentStep}>
							<div className="mx-auto w-full max-w-sm lg:w-96">
								<div>
									<button
										onClick={()=>formWizardRef.current.prev()}
										type="button"
										className="glade-black">
										<BsChevronLeft />
									</button>
									<h2 className="mt-6 glade-heading-one glade-black">Get Started</h2>
									<p className="mt-2 glade-subtitle-two glade-black">Set up your account in a few minutes.</p>
								</div>
								<div className="mt-8">
									<div className="mt-6">
										<div className="space-y-6">
											<div>
												<label htmlFor="first_name" className="block glade-small-text-two glade-black">First Name</label>
												<div className="mt-1">
													<input
														id="first_name"
														name="first_name"
														type="text"
														placeholder="Enter First Name"
														autoComplete="first_name"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
													/>
												</div>
											</div>

											<div className="space-y-1">
												<label htmlFor="last_name" className="block glade-small-text-two glade-black">Last Name</label>
												<div className="mt-1">
													<input
														id="last_name"
														name="last_name"
														type="text"
														placeholder="Enter Last Name"
														autoComplete="last_name"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
													/>
												</div>
											</div>

											<div className="space-y-1">
												<label htmlFor="phone_number" className="block glade-small-text-two glade-black">Phone Number</label>
												<div className="mt-1">
													<input
														id="phone_number"
														name="phone_number"
														type="tel"
														placeholder="+234"
														autoComplete="phone_number"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
													/>
												</div>
											</div>

											<div className="space-y-1">
												<label htmlFor="email" className="block glade-small-text-two glade-black">Email address</label>
												<div className="mt-1">
													<input
														id="email"
														name="email"
														type="email"
														placeholder="Enter Email Address"
														autoComplete="email"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
													/>
												</div>
											</div>

											<div>
												<button
													onClick={()=>{ formWizardRef.current.next(); }}
													type="button"
													className="glade-normal-text-three glade-button-blue w-full flex justify-center py-2 px-4 border border-transparent rounded-sm text-white">
													Continue
												</button>
											</div>

											<div>
												<p className="mt-2 flex justify-center glade-normal-text-two">
													<span className="mr-1 glade-black">Already have an account?</span>
													<a href="/auth/login" className="glade-normal-text-two glade-orange">Sign In</a>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</FormWizardSection>

						{/* Password Section */}
						<FormWizardSection position={2} currentStep={formWizardCurrentStep}>
							<div className="mx-auto w-full max-w-sm lg:w-96">
								<div>
									<button
										onClick={()=>formWizardRef.current.prev()}
										type="button"
										className="glade-black">
										<BsChevronLeft />
									</button>
									<h2 className="mt-6 glade-heading-one glade-black">Create Password</h2>
									<p className="mt-2 glade-subtitle-two glade-black">Set up your account in a few minutes.</p>
								</div>
								<div className="mt-8">
									<div className="mt-6">
										<div className="space-y-6">
											<div className="">
												<label htmlFor="password" className="block glade-small-text-two glade-black">Password</label>
												<div className="mt-1">
													<input
														id="password"
														name="password"
														type="password"
														placeholder="Create Password"
														autoComplete="password"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
													/>
												</div>
											</div>

											<div className="space-y-1">
												<label htmlFor="confirm_password" className="block glade-small-text-two glade-black">Confirm Password</label>
												<div className="mt-1">
													<input
														id="confirm_password"
														name="confirm_password"
														type="confirm_password"
														placeholder="Confirm Password"
														autoComplete="confirm_password"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
													/>
												</div>
											</div>

											<div>
												<button
													onClick={()=>{ formWizardRef.current.next(); }}
													type="button"
													className="glade-normal-text-three glade-button-blue w-full flex justify-center py-2 px-4 border border-transparent rounded-sm text-white">
													Continue
												</button>
											</div>

											<div>
												<p className="mt-2 flex justify-center glade-normal-text-two">
													<span className="mr-1 glade-black">Already have an account?</span>
													<a href="/auth/login" className="glade-normal-text-two glade-orange">Sign In</a>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</FormWizardSection>

						{/* OTP Section */}
						<FormWizardSection position={3} currentStep={formWizardCurrentStep}>
							<div className="mx-auto w-full max-w-sm lg:w-96">
								<div>
									<h2 className="flex justify-center mt-6 glade-heading-one glade-black">Enter OTP</h2>
									<p className="flex justify-center text-center mt-2 glade-subtitle-two glade-black">A confirmation code has been sent to li*****e@gmail.com. Enter the code to login.</p>
								</div>
								<div className="mt-8">
									<div className="mt-6">
										<div className="space-y-6">
											<div>
												<div className="mt-1 flex justify-center">
													<IndividualInput returnInput={()=>{}} />
												</div>
											</div>

											<div>
												<button
													onClick={()=>{}}
													type="button"
													className="glade-normal-text-three glade-button-blue w-full flex justify-center py-2 px-4 border border-transparent rounded-sm text-white">
													Create Account
												</button>
											</div>

											<div>
												<p className="mt-2 flex justify-center glade-normal-text-two">
													<span className="mr-1 glade-black">Didn’t get OTP?</span>
													<a href="/auth/login" className="glade-normal-text-two glade-orange">Resend</a>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</FormWizardSection>

					</FormWizard>
				</div>
			</div>
		</React.Fragment>
	)
}

import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import * as dfn from "date-fns";

import Button from "../../components/buttons/Button";
import { PopoverButton, PopoverMain } from "../../components/popovers/Popover";
import { FormError } from "../../validation";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import { useDispatch } from "react-redux";
import { getActiveUsersReport, getMostUsedServices, getTransactionStatus, getTransactionSummary } from "../../redux/home/homeActions";
import { MdArrowDropDown } from "react-icons/md";

const HomeFilter = () => {
    const dispatch = useDispatch();
    const [transactionFilter, setTransactionFilter] = useState("last-7-days");
    const closeModal = useRef('');
    const filterInstance = usePaginationFilterParams({
        initialFilters: {
            transaction_filter: transactionFilter,
        },
    });

    const { filters, urlSearchParamsHistory } = filterInstance;

    const { transaction_filter } = filters;

    const filterData = {};

    useEffect(() => {
        let filterData = {};
        dispatch(
            getTransactionSummary({
                params: {
                    ...{
                        date_from:
                            filterData.date_from ||
                            dfn.lightFormat(dfn.subWeeks(new Date(), 1), "yyyy-MM-dd"),
                        date_to:
                            filterData.date_to || dfn.lightFormat(new Date(), "yyyy-MM-dd"),
                    },
                },
            }),
        );
        dispatch(getActiveUsersReport({
            params: {
                ...{
                    date_from:
                        filterData.date_from ||
                        dfn.lightFormat(dfn.subWeeks(new Date(), 1), "yyyy-MM-dd"),
                    date_to:
                        filterData.date_to || dfn.lightFormat(new Date(), "yyyy-MM-dd"),
                },
            },
        }))
        dispatch(getTransactionStatus({
            params: {
                ...{
                    date_from:
                        filterData.date_from ||
                        dfn.lightFormat(dfn.subWeeks(new Date(), 1), "yyyy-MM-dd"),
                    date_to:
                        filterData.date_to || dfn.lightFormat(new Date(), "yyyy-MM-dd"),
                },
            },
        }))
        dispatch(getMostUsedServices({
            params: {
                ...{
                    date_from:
                        filterData.date_from ||
                        dfn.lightFormat(dfn.subWeeks(new Date(), 1), "yyyy-MM-dd"),
                    date_to:
                        filterData.date_to || dfn.lightFormat(new Date(), "yyyy-MM-dd"),
                },
            },
        }))
    }, [])
    const getDateRange = (filter) => {
        // setDateSelect('');
        switch (filter.date) {
            case "last-7-days":
                filterData.date_from = dfn.lightFormat(
                    dfn.subWeeks(new Date(), 1),
                    "yyyy-MM-dd",
                );
                filterData.date_to = dfn.lightFormat(new Date(), "yyyy-MM-dd");
                break;

            case "last-30-days":
                filterData.date_from = dfn.lightFormat(
                    dfn.subDays(new Date(), 30),
                    "yyyy-MM-dd",
                );
                filterData.date_to = dfn.lightFormat(new Date(), "yyyy-MM-dd");
                break;

            case "all-time":
                filterData.date_from = dfn.lightFormat(
                    dfn.subWeeks(new Date(), 104),
                    "yyyy-MM-dd",
                );
                filterData.date_to = dfn.lightFormat(new Date(), "yyyy-MM-dd");
                break;

            case "today":
                filterData.date_from = dfn.lightFormat(new Date(), "yyyy-MM-dd");
                filterData.date_to = dfn.lightFormat(new Date(), "yyyy-MM-dd");
                break;

            case "custom date":
                filterData.date_from = filter.from;
                filterData.date_to = filter.to;
                break;

            default:
                filterData.date_from = dfn.lightFormat(
                    dfn.subWeeks(new Date(), 1),
                    "yyyy-MM-dd",
                );
                filterData.date_to = dfn.lightFormat(new Date(), "yyyy-MM-dd");
                break;
        }

        urlSearchParamsHistory.merge({
            transaction_filter: filter.date,
        });
        dispatch(
            getTransactionSummary({
                params: {
                    ...{
                        date_from:
                            filterData.date_from ||
                            dfn.lightFormat(dfn.subWeeks(new Date(), 1), "yyyy-MM-dd"),
                        date_to:
                            filterData.date_to || dfn.lightFormat(new Date(), "yyyy-MM-dd"),
                    },
                },
            }),
        );
        dispatch(getActiveUsersReport({
            params: {
                ...{
                    date_from:
                        filterData.date_from ||
                        dfn.lightFormat(dfn.subWeeks(new Date(), 1), "yyyy-MM-dd"),
                    date_to:
                        filterData.date_to || dfn.lightFormat(new Date(), "yyyy-MM-dd"),
                },
            },
        }))
        dispatch(getTransactionStatus({
            params: {
                ...{
                    date_from:
                        filterData.date_from ||
                        dfn.lightFormat(dfn.subWeeks(new Date(), 1), "yyyy-MM-dd"),
                    date_to:
                        filterData.date_to || dfn.lightFormat(new Date(), "yyyy-MM-dd"),
                },
            },
        }))
        dispatch(getMostUsedServices({
            params: {
                ...{
                    date_from:
                        filterData.date_from ||
                        dfn.lightFormat(dfn.subWeeks(new Date(), 1), "yyyy-MM-dd"),
                    date_to:
                        filterData.date_to || dfn.lightFormat(new Date(), "yyyy-MM-dd"),
                },
            },
        }))
        closeModal.current.click();
    };

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const dateSelect = watch("date");

    const onFilter = (data) => {
        setTransactionFilter(data.date);
        getDateRange(data);
    };

    // const resetFilter = () => {
    //   history.replace(location.pathname);
    // };

    return (
        <PopoverMain
            button={
                <>
                    <span className="flex items-center glade-normal-text-two glade-ash cursor-pointer capitalize" ref={closeModal}>
                        {transactionFilter !== 'custom date' ? transactionFilter.split('-').join(' ') : transactionFilter}
                        {/* {dfn.lightFormat(new Date(filterValue?.date_from), "MMM d, yyyy")} */}
                        <MdArrowDropDown className="inline-block text-2xl" />
                    </span>
                </>
            }
            popoverClassName="right-0"
        >
            <form
                onSubmit={handleSubmit(onFilter)}
                className="flex flex-col p-6 bg-white shadow rounded-sm w-72 right-0"
            >
                <div className="w-full my-3">
                    <label htmlFor="date" className="block glade-small-text-two">
                        Date
                    </label>
                    <select
                        id="date"
                        name="date"
                        defaultValue={transaction_filter}
                        {...register("date")}
                        className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
                    >
                        <option value="today">Today</option>
                        <option value="last-7-days">Last 7 days</option>
                        <option value="last-30-days">Last 30 days</option>
                        <option value="all-time">All Time</option>
                        <option value="custom date">Custom Date</option>
                    </select>
                    <FormError errors={errors} name="date" />
                </div>
                {dateSelect === "custom date" && (
                    <div className="grid grid-cols-2 gap-x-3">
                        <div className="">
                            <label htmlFor="from" className="block glade-small-text-two">
                                From
                            </label>
                            <input
                                type="date"
                                id="from"
                                name="from"
                                {...register("from", {
                                    required: "Select a start date",
                                })}
                                className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                            />
                            <FormError errors={errors} name="from" />
                        </div>
                        <div className="">
                            <label htmlFor="to" className="block glade-small-text-two">
                                To
                            </label>
                            <input
                                type="date"
                                id="to"
                                name="to"
                                {...register("to", {
                                    required: "Select an end date",
                                })}
                                className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
                            />
                            <FormError errors={errors} name="to" />
                        </div>
                    </div>
                )}
                <div className="flex flex-row items-center justify-between mt-9">
                    {/* {transactionFilter ? ( */}
                    <PopoverButton>
                        <span className="glade-ash mx-1 cursor-pointer">Cancel</span>
                    </PopoverButton>
                    <div className="mx-2">
                        <Button.Dark type="submit" title={"Filter"} />
                    </div>
                </div>
            </form>
        </PopoverMain>
    );
};

export default HomeFilter;

import React, { Fragment, useContext, useEffect, useState } from "react";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import MultiSelectInput from "../../components/multiselect/MultiSelectInput";
import { FormError } from "../../validation";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { MultiSelectContext } from "../../context/MultiSelectContext";
import { CardPlain, CardPlainSection } from "../../components/cards/CardPlain";
import { editDiscount, getSingleDiscount, clearSingleDiscount } from "../../redux/discounts/discountActions";
import useDiscounts from "../../hooks/useDiscounts";
import { RiLoader5Fill } from "react-icons/ri";
import { useHistory } from "react-router-dom";

const EditTransferDiscount = () => {
  const dispatch = useDispatch();
  const {
    selectOptions,
    setIsPersonal,
    setSelectOptions
  } = useContext(MultiSelectContext);
  const [recipientError, setRecipientError] = useState({});

  const { location, push } = useHistory();
  const path = location.pathname.split('/'),
    id = path[path.length - 1];

  const {
    singleDiscount,
    isLoadingSingleDiscount,
    isEditingDiscount
  } = useDiscounts();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    criteriaMode: "all",
    shouldUseNativeValidation: true
  });

  const typeInput = watch("account_type");

  function onEditTransferDiscount({ discount_name, account_type, no_of_free_transfers }) {
    if (selectOptions.length === 0) {
      setRecipientError({
        discount_recipients: { message: "Select at least one recipient" }
      });
      return;
    }
    const formData = {
      discount_type: "transfer_discount",
      tag: discount_name,
      account_type: account_type,
      number_of_transfer: no_of_free_transfers,
      account_uuid: selectOptions.map(option => account_type === 'business' ? (option.business_uuid || option.account_uuid) : (option.user_uuid || option.account_uuid))
    };
    dispatch(editDiscount(id, formData));
    push("/dashboard/discounts");
  }

  useEffect(() => {
    dispatch(getSingleDiscount(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      setSelectOptions([]);
      dispatch(clearSingleDiscount());
    };
  }, [setSelectOptions, dispatch]);

  useEffect(() => {
    if (!isLoadingSingleDiscount) {
      setSelectOptions(singleDiscount?.discount_details);
    }
  }, [
    isLoadingSingleDiscount,
    setSelectOptions,
    singleDiscount.discount_details,
  ]);

  function resetRecipients() {
    setSelectOptions([]);
  }

  useEffect(() => {
    if (typeInput === 'personal')
      setIsPersonal(true);
    else
      setIsPersonal(false);
  }, [typeInput, setSelectOptions, setIsPersonal]);

  if (isLoadingSingleDiscount && selectOptions?.length !== 0) {
    return (
      <Fragment>
        <RiLoader5Fill size={24} className="animate-spin ml-4" />
      </Fragment>
    );
  }

  return (
    <div className="p-3 flex flex-col">
      <div className="mb-5">
        <BreadCrumbs title={"Discounts"} goBack />
      </div>
      <form onSubmit={handleSubmit(onEditTransferDiscount)}>
        <CardPlain type="single">
          <CardPlainSection
            title={"Edit Transfer Discount"}
            type="single"
          >
            <section className="pt-10 px-16 pb-10">
              <div className="mb-4">
                {
                  singleDiscount?.discount_info?.tag ? (
                    <Fragment>
                      <label
                        className="block glade-small-text-two"
                        htmlFor="discount_name"
                      >
                        Discount Name
                      </label>
                      <input
                        type="text"
                        name="discount_name"
                        {...register("discount_name", {
                          required: "Type a discount name"
                        })}
                        id="discount_name"
                        defaultValue={singleDiscount?.discount_info.tag}
                        placeholder="Enter Discount Name"
                        className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                      />
                      <FormError errors={errors} name="discount_name" />
                    </Fragment>
                  ) : null}
              </div>
              <div className="mb-4">
                {singleDiscount?.discount_info?.no_of_free_transfers ? (
                  <Fragment>
                    <label
                      htmlFor="account_type"
                      className="block glade-small-text-two"
                    >
                      Account Type
                    </label>
                    <select
                      className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
                      name="account_type"
                      id="type"
                      {...register("account_type", {
                        required: "Enter an account type"
                      })}
                      onChange={resetRecipients}
                    >
                      <option disabled value="">Select account type</option>
                      <option
                        value="personal"
                        selected={singleDiscount.discount_info.account_type === "personal"}
                      >
                        Personal
                      </option>
                      <option
                        value="business"
                        selected={singleDiscount.discount_info.account_type === "business"}
                      >
                        Business
                      </option>
                    </select>

                    <FormError errors={errors} name="account_type" />
                  </Fragment>
                ) : null}
              </div>
              <div className="mb-8">
                {singleDiscount?.discount_info?.no_of_free_transfers ? (
                  <Fragment>
                    <label
                      className="block glade-small-text-two"
                      htmlFor="no_of_free_transfers"
                    >
                      Number of Free Transfers
                    </label>
                    <input
                      type="number"
                      name="no_of_free_transfers"
                      id="no_of_free_transfers"
                      placeholder="Enter Number of Free Transfers"
                      defaultValue={Number(singleDiscount?.discount_info?.no_of_free_transfers) ?? 0}
                      className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                      {...register("no_of_free_transfers", {
                        required: "Enter amount of free transfers"
                      })}
                    />
                    <FormError errors={errors} name="no_of_free_transfers" />
                  </Fragment>
                ) : null}
              </div>
              <div className="mb-4">
                <label
                  className="block glade-small-text-two mb-1"
                  htmlFor="discount_recipients"
                >
                  Discount Recipients
                </label>
                <MultiSelectInput />
                <FormError errors={recipientError} name={"discount_recipients"} />
              </div>
            </section>
          </CardPlainSection>
        </CardPlain>
        <div className="flex justify-center mt-6 space-x-4">
          <Button.Dark
            type="submit"
            title={"Update Discount"}
            loading={isEditingDiscount}
            style={{ width: "fit-content" }}
          />
        </div>
      </form >
    </div >
  );
};

export default EditTransferDiscount;

import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastSuccess } from "../../helpers/utils.toast";
import {
  GET_SUPPORTED_TRANSFER_CURRENCIES_FAILURE,
  GET_SUPPORTED_TRANSFER_CURRENCIES_SUCCESS,
  GET_SUPPORTED_TRANSFER_CURRENCIES_START,
  UPDATE_TRANSFER_CHARGE_START,
  UPDATE_TRANSFER_CHARGE_SUCCESS,
  UPDATE_TRANSFER_CHARGE_FAILURE,
  LIVE_RATE_FAILURE,
  LIVE_RATE_START,
  LIVE_RATE_SUCCESS,
  SUPPORTED_CURRENCIES_FAILURE,
  SUPPORTED_CURRENCIES_RATE_FAILURE,
  SUPPORTED_CURRENCIES_RATE_START,
  SUPPORTED_CURRENCIES_RATE_SUCCESS,
  SUPPORTED_CURRENCIES_START,
  SUPPORTED_CURRENCIES_SUCCESS,
  UPDATE_CURRENCY_RATE_FAILURE,
  UPDATE_CURRENCY_RATE_START,
  UPDATE_CURRENCY_RATE_SUCCESS,
  COUNTRIES_BY_CONTINENT_FAILURE,
  COUNTRIES_BY_CONTINENT_SUCCESS,
  COUNTRIES_BY_CONTINENT_START,
  UPDATE_CURRENCY_STATUS_START,
  UPDATE_CURRENCY_STATUS_SUCCESS,
  UPDATE_CURRENCY_STATUS_FAILURE
} from "./types";

export const getSupportedTransferCurrencies = (params, callback = () => { }) => {
  return dispatch => {
    dispatch({ type: GET_SUPPORTED_TRANSFER_CURRENCIES_START });
    Axios.get(`cross-border/currencies-charges`, params)
      .then(res => {
        dispatch({
          type: GET_SUPPORTED_TRANSFER_CURRENCIES_SUCCESS,
          payLoad: res.data.data
        });
        callback(res.data.data);
      })
      .catch(error => {
        dispatch({
          type: GET_SUPPORTED_TRANSFER_CURRENCIES_FAILURE,
          payLoad: error
        });
        handler(error);
      });
  };
};

export const updateTransferCharge = (payLoad, callback = () => { }) => {
  return dispatch => {
    dispatch({ type: UPDATE_TRANSFER_CHARGE_START });
    Axios.put(`/cross-border/charge`, payLoad)
      .then(res => {
        dispatch({ type: UPDATE_TRANSFER_CHARGE_SUCCESS });
        toastSuccess(res.data.message);
        callback();
        // window.location.reload();
      })
      .catch(error => {
        dispatch({
          type: UPDATE_TRANSFER_CHARGE_FAILURE,
          payLoad: error
        });
        handler(error, true);
      });
  };
};

export const getSupportedCurrencies = () => {
  return (dispatch) => {
    dispatch({ type: SUPPORTED_CURRENCIES_START });
    Axios.get(`/cross-border/supported-currencies`)
      .then((res) => {
        dispatch({ type: SUPPORTED_CURRENCIES_SUCCESS, payLoad: res.data });
      })
      .catch((error) => {
        dispatch({
          type: SUPPORTED_CURRENCIES_FAILURE,
          payLoad: error,
        });
        handler(error);
      });
  };
};

export const getCurrencyRates = (source_currency, currentPage, search) => {
  return (dispatch) => {
    dispatch({ type: SUPPORTED_CURRENCIES_RATE_START });
    Axios.get(`/cross-border/currency-rates?source_currency=${source_currency}&page=${currentPage}&per_page=10&paginate=1&search=${search}`)
      .then((res) => {
        dispatch({ type: SUPPORTED_CURRENCIES_RATE_SUCCESS, payLoad: res.data });
      })
      .catch((error) => {
        dispatch({
          type: SUPPORTED_CURRENCIES_RATE_FAILURE,
          payLoad: error,
        });
        handler(error);
      });
  };
};

export const getCountriesByContinent = (currentPage, continent, search) => {
  return (dispatch) => {
    dispatch({ type: COUNTRIES_BY_CONTINENT_START });
    Axios.get(`/currency/all?per_page=10&current_page=${currentPage}&continent=${continent}&search=${search}`)
      .then((res) => {
        dispatch({ type: COUNTRIES_BY_CONTINENT_SUCCESS, payLoad: res.data });
      })
      .catch((error) => {
        dispatch({
          type: COUNTRIES_BY_CONTINENT_FAILURE,
          payLoad: error,
        });
        handler(error);
      });
  };
};

export const getSingleLiveRate = (from, to) => {
  return (dispatch) => {
    dispatch({ type: LIVE_RATE_START });
    Axios.get(`/cross-border/live-rates?from=${from}&to=${to}`)
      .then((res) => {
        dispatch({ type: LIVE_RATE_SUCCESS, payLoad: res.data });
      })
      .catch((error) => {
        dispatch({
          type: LIVE_RATE_FAILURE,
          payLoad: error,
        });
        handler(error);
      });
  };
};


export const updateCurrencyRate = (post, page, onSuccess) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_CURRENCY_RATE_START });
    Axios.put(`/cross-border/rate`, post)
      .then((res) => {
        dispatch({ type: UPDATE_CURRENCY_RATE_SUCCESS, payLoad: res.data });
        toastSuccess(res.data.message);
        onSuccess();
        // window.location.reload();
        dispatch(getCurrencyRates(post?.source_currency, page, ''));
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_CURRENCY_RATE_FAILURE,
          payLoad: error,
        });
        handler(error, true);
      });
  };
};

export const updateCurrencyStatus = (params, onClose=()=>{}) => {
  return (dispatch) => {
      dispatch({ type: UPDATE_CURRENCY_STATUS_START });
      Axios.put(`/currency/${params?.status}/${params?.id}`)
          .then((res) => {
              dispatch({
                  type: UPDATE_CURRENCY_STATUS_SUCCESS,
                  payLoad: res?.data?.data,
              });
              toastSuccess(res.data.message);
              onClose();
          })
          .catch((error) => {
              dispatch({
                  type: UPDATE_CURRENCY_STATUS_FAILURE,
                  payLoad: error,
              });
              handler(error, true);
          });
  };
};

import XLSX from 'xlsx';
import Button from '../components/buttons/Button';

const DownloadAsCSVWithMultipleSheet = ({ fileName, callback, buttonTitle, disabled }) => {
    const downloadFile = (sheets) => {
        let mockSheet = [];
        const workbook = XLSX.utils.book_new();
        sheets.map((sheet, key) => {
            mockSheet.push(XLSX.utils.json_to_sheet(sheet.data));
            XLSX.utils.book_append_sheet(workbook, mockSheet[key], sheet.name);
        })
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }
    return (
        <Button.Dark
            title={buttonTitle}
            onClick={() => callback(downloadFile)}
            disabled={disabled}
        />
    );
};

export default DownloadAsCSVWithMultipleSheet;
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useForm } from "react-hook-form";
// import { email_required, FormError, password} from '../../validation';
import { Redirect } from 'react-router-dom';
import CarouselAuth from '../../components/carousel/CarouselAuth';

export default function RestPassword() {
	// eslint-disable-next-line no-unused-vars
    const dispatch = useDispatch();
	const user = useSelector(state => state?.auth?.user);

    return (
        <div className="min-h-screen bg-white flex">
			{user.token ? <Redirect to="/dashboard/home" push /> : null}
			<div className="hidden lg:block relative">
				<CarouselAuth />
			</div>
			<div className="w-2/3 flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
				<div className="mx-auto w-full max-w-sm lg:w-96">
					<div>
						<h2 className="mt-6 glade-heading-one glade-black">Reset Password?</h2>
						<p className="mt-2 glade-subtitle-two glade-black">Enter a new password</p>
					</div>
					<div className="mt-8">
						<div className="mt-6">
							<form onSubmit={()=>{}} className="space-y-6">
                                <div>
									<label htmlFor="password" className="block glade-small-text-two glade-black">Password</label>
									<div className="mt-1">
										<input
											id="password"
											name="password"
											type="password"
											autoComplete="current-password"
											placeholder="Enter Password"
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
										/>
									</div>
								</div>

                                <div className="space-y-1">
                                    <label htmlFor="confirm_password" className="block glade-small-text-two glade-black">Confirm Password</label>
                                    <div className="mt-1">
                                        <input
                                            id="confirm_password"
                                            name="confirm_password"
                                            type="confirm_password"
                                            placeholder="Confirm Password"
                                            autoComplete="confirm_password"
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

								<div>
									<button
										type="submit"
										className="glade-normal-text-three glade-button-blue w-full flex justify-center py-2 px-4 border border-transparent rounded-sm text-white">
										Reset
									</button>
								</div>
								<div>
									<p className="mt-2 flex justify-center glade-normal-text-two">
										<span className="mr-1 glade-black">Already have an account?</span>
										<a href="/auth/login" className="glade-normal-text-two glade-orange">Sign In</a>
									</p>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

import React, { useState } from "react";
import * as dfn from "date-fns";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { TextSkeleton } from "../../components/skeletons";

import { FiCopy } from "react-icons/fi";
import { copyTextToClipboard, fixDateForAllBrowsers, statusEnum, truncateRef } from "../../helpers/helper";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import { Table } from "../../components/table/Table";
import usePos from "../../hooks/usePos";
import PillIndicator from "../../components/indicators/PillIndicator";
import {
  disablePosTerminal,
  enablePosTerminal,
  getSpecificPosTerminal,
  unAssignPosTerminal,
} from "../../redux/pos/posActions";
import BasicModal from "../../components/modals/BasicModal";
import Button from "../../components/buttons/Button";

export default function PosTerminalDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showEnableModal, setShowEnableModal] = useState(false);
  const [showUnassignModal, setShowUnassignModal] = useState(false);

  const {
    isLoadingSpecificPosTerminal: loading,
    specificPosTerminal: details,
    isDisablingPosTerminal,
    isEnablingPosTerminal,
    isUnassigningPosTerminal,
  } = usePos();

  React.useMemo(() => {
    dispatch(getSpecificPosTerminal(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onDisableTerminal = () => {
    dispatch(disablePosTerminal(id));
  };

  const onEnableTerminal = () => {
    dispatch(enablePosTerminal(id));
  };

  const onUnassignTerminal = () => {
    dispatch(unAssignPosTerminal(details?.terminal_id));
  };

  return (
    <div className="p-3 flex flex-col">
      <div className="mb-6">
        <BreadCrumbs title={"POS"} goBack />
      </div>

      <div className="flex items-center justify-between">
        <PillIndicator
          className="px-5 py-1 capitalize"
          type={statusEnum[details?.status]}
          title={details?.status ? "Active" : "Disabled"}
        />

        <div className="flex items-center">
          {details?.status ? (
            <Button.Danger
              onClick={() => setShowDisableModal(true)}
              disabled={isDisablingPosTerminal || loading}
              title="Disable"
            />
          ) : (
            <Button
              onClick={() => setShowEnableModal(true)}
              disabled={isEnablingPosTerminal || loading}
              title="Enable"
            />
          )}
          <div className="ml-4">
            <Button.Dark
              onClick={() => setShowUnassignModal(true)}
              disabled={isUnassigningPosTerminal || loading}
              title="Unassign Terminal"
              className="whitespace-nowrap"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-white rounded-sm p-8 pt-5 mt-4 mb-8">
        <div className="py-3 border-b-2 border-solid border-gray-100">
          <p className="glade-black glade-normal-text-two">Terminal Details</p>
        </div>
        <div className="grid grid-cols-4 gap-4 py-3">
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Account Name</h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.account_name}
              </h6>
            )}
          </div>
          {details?.terminal_id && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Terminal ID</h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <div className="flex items-center">
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {truncateRef(details?.terminal_id)}
                  </h6>
                  <FiCopy
                    size={16}
                    color="#AFAFAF"
                    className="ml-3 cursor-pointer"
                    onClick={() => copyTextToClipboard(details?.terminal_id)}
                  />
                </div>
              )}
            </div>
          )}
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Account Type</h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                {details?.owner_type}
              </h6>
            )}
          </div>
          {details?.date_assigned && (
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">Date Assigned</h5>
              {loading ? (
                <TextSkeleton width="100px" />
              ) : (
                <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                  {dfn.format(new Date(details?.date_assigned), "MMM d, yyyy")}
                </h6>
              )}
            </div>
          )}
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Revenue</h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.currency ?? "NGN"}{" "}
                {Number(details?.revenue ?? 0)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
              </h6>
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Number of Transactions
            </h5>
            {loading ? (
              <TextSkeleton width="100px" />
            ) : (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.total_transactions}
              </h6>
            )}
          </div>
        </div>
      </div>

      <CardTableHeader
        filterButton=""
        title={`Recent Transactions ${
          !loading && details?.recent_transactions?.length
            ? "- " + details?.recent_transactions?.length
            : ""
        }`}
        noSearch
      />
      <Table
        columns={columns}
        data={
          typeof details?.recent_transactions === "object" &&
          !Array.isArray(details?.recent_transactions)
            ? details?.recent_transactions?.data
            : details?.recent_transactions
        }
        empty_message="No transactions"
        empty_sub_message=""
        current_page={currentPage}
        loading={loading}
        setCurrentPage={setCurrentPage}
      />

      {/* Enable modal */}
      <BasicModal
        visibility={showEnableModal}
        onClose={() => setShowEnableModal(false)}
        onOpen={() => setShowEnableModal(true)}
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        modelContentSection={
          <div className="p-6 px-0">
            <h3 className="glade-black text-center mb-8">
              Are you sure you want to enable this terminal?
            </h3>
            <Button
              onClick={onEnableTerminal}
              loading={isEnablingPosTerminal}
              title="Enable"
            >
              {isEnablingPosTerminal ? "Enabling" : "Enable"}
            </Button>
            <button
              onClick={() => setShowEnableModal(false)}
              className="glade-normal-text-three glade-grey-dark w-full mx-auto text-center mt-6"
            >
              Cancel
            </button>
          </div>
        }
      />

      {/* Disable modal */}
      <BasicModal
        visibility={showDisableModal}
        onClose={() => setShowDisableModal(false)}
        onOpen={() => setShowDisableModal(true)}
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        modelContentSection={
          <div className="p-6 px-0">
            <h3 className="glade-black text-center mb-8">
              Are you sure you want to disable this terminal?
            </h3>
            <Button.Danger
              onClick={onDisableTerminal}
              loading={isDisablingPosTerminal}
              title="Disable"
            >
              {isDisablingPosTerminal ? "Disabling" : "Disable"}
            </Button.Danger>
            <button
              onClick={() => setShowDisableModal(false)}
              className="glade-normal-text-three glade-grey-dark w-full mx-auto text-center mt-6"
            >
              Cancel
            </button>
          </div>
        }
      />

      {/* Unassign modal */}
      <BasicModal
        visibility={showUnassignModal}
        onClose={() => setShowUnassignModal(false)}
        onOpen={() => setShowUnassignModal(true)}
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        modelContentSection={
          <div className="p-6 px-0">
            <h3 className="glade-black text-center mb-8">
              Are you sure you want to unassign this terminal?
            </h3>
            <Button.Dark
              onClick={onUnassignTerminal}
              loading={isUnassigningPosTerminal}
              title="Unassign"
            >
              {isUnassigningPosTerminal ? "Unassigning" : "Unassign"}
            </Button.Dark>
            <button
              onClick={() => setShowUnassignModal(false)}
              className="glade-normal-text-three glade-grey-dark w-full mx-auto text-center mt-6"
            >
              Cancel
            </button>
          </div>
        }
      />
    </div>
  );
}

const columns = [
  {
    accessor: "txn_status",
    Header: "Status",
    Cell: ({ value, row }) => (
      <PillIndicator
        className="px-5 py-1 capitalize"
        type={statusEnum[value]}
        title={value ? "Active" : "Disabled"}
      />
    ),
  },
  {
    accessor: "rrn",
    Header: "RRN",
    Cell: ({ value, row }) => (
      <div className="flex items-center">
        <span className="glade-normal-text-two">{truncateRef(value)}</span>
        <FiCopy
          size={16}
          color="#AFAFAF"
          className="ml-3 cursor-pointer"
          onClick={() => copyTextToClipboard(value)}
        />
      </div>
    ),
  },
  {
    accessor: "value",
    Header: "Amount",
    Cell: ({ value, row }) => (
      <span className="glade-normal-text-two">
        {row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
      </span>
    ),
  },
  {
    accessor: "created_at",
    Header: "Date",
    Cell: ({ value, row }) => (
      <span className="glade-normal-text-two">
        {dfn.format(new Date(fixDateForAllBrowsers(value)), "MMM d, yyyy hh:mm:ss a")}
      </span>
    ),
  },
];

import {
  GET_WALLETS_FAILURE, GET_WALLETS_START, GET_WALLETS_SUCCESS, GET_WALLET_DETAILS_FAILURE, GET_WALLET_DETAILS_START, GET_WALLET_DETAILS_SUCCESS, UPDATE_WALLET_STATUS_FAILURE, UPDATE_WALLET_STATUS_START, UPDATE_WALLET_STATUS_SUCCESS, GET_ACCOUNT_DETAILS_START,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_FAILURE,
  GET_ACCOUNTS_START,
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_SUCCESS,
} from "./types";

const initialState = {
  isLoadingWalletRequests: true,
  isLoadingWalletDetails: true,
  walletRequestsData: [],
  walletDetailsData: {},
  isLoadingWalletStatusUpdate: false,
  accountRequestsData: [],
  accountDetailsData: {},
  isLoadingAccountRequests: false,
  isLoadingAccountDetails: false,
}

export default function walletsReducer(state = initialState, action) {
  switch (action.type) {
    // GET ALL REQUESTS FOR WALLETS
    case GET_WALLETS_START:
      return {
        ...state,
        isLoadingWalletRequests: true,
      };
    case GET_WALLETS_SUCCESS:
      return {
        ...state,
        isLoadingWalletRequests: false,
        walletRequestsData: action.payLoad
      };
    case GET_WALLETS_FAILURE:
      return {
        ...state,
        isLoadingWalletRequests: false,
        walletRequestsData: []
      };
    // GET WALLET DETAILS
    case GET_WALLET_DETAILS_START:
      return {
        ...state,
        isLoadingWalletDetails: true,
        walletRequestsData: {}
      };
    case GET_WALLET_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingWalletDetails: false,
        walletDetailsData: action?.payLoad
      };
    case GET_WALLET_DETAILS_FAILURE:
      return {
        ...state,
        isLoadingWalletDetails: false,
        walletDetailsData: {}
      };

      case GET_ACCOUNTS_START:
        return {
          ...state,
          isLoadingAccountRequests: true,
        };
      case GET_ACCOUNTS_SUCCESS:
        return {
          ...state,
          isLoadingAccountRequests: false,
          accountRequestsData: action.payLoad
        };
      case GET_ACCOUNTS_FAILURE:
        return {
          ...state,
          isLoadingAccountRequests: false,
          accountRequestsData: []
        };
      // GET Account DETAILS
      case GET_ACCOUNT_DETAILS_START:
        return {
          ...state,
          isLoadingAccountDetails: true,
          accountDetailsData: {}
        };
      case GET_ACCOUNT_DETAILS_SUCCESS:
        return {
          ...state,
          isLoadingAccountDetails: false,
          accountDetailsData: action?.payLoad
        };
      case GET_ACCOUNT_DETAILS_FAILURE:
        return {
          ...state,
          isLoadingAccountDetails: false,
          accountDetailsData: {}
        };

    
    case UPDATE_WALLET_STATUS_START:
      return {
        ...state,
        isLoadingWalletStatusUpdate: true
      }
    case UPDATE_WALLET_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingWalletStatusUpdate: false
      }

    case UPDATE_WALLET_STATUS_FAILURE:
      return {
        ...state,
        isLoadingWalletStatusUpdate: false
      }
    default:
      return state;

  }
}
import {
  CROSS_BORDER_MANUAL_TRANSFERS_FAILURE,
  CROSS_BORDER_MANUAL_TRANSFERS_START,
  CROSS_BORDER_MANUAL_TRANSFERS_SUCCESS,
  GET_ALL_BILLS_TRANSACTIONS_FAILURE,
  GET_ALL_BILLS_TRANSACTIONS_START,
  GET_ALL_BILLS_TRANSACTIONS_SUCCESS,
  GET_ALL_PAYROLL_TRANSACTIONS_FAILURE,
  GET_ALL_PAYROLL_TRANSACTIONS_START,
  GET_ALL_PAYROLL_TRANSACTIONS_SUCCESS,
  GET_PAYROLL_SALARY_ADVANCE_START,
  GET_PAYROLL_SALARY_ADVANCE_SUCCESS,
  GET_PAYROLL_SALARY_ADVANCE_FAILURE,
  GET_ALL_TRANSACTIONS_FAILURE,
  GET_ALL_TRANSACTIONS_START,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ADVANCE_PAYROLL_DETAIL_START,
  GET_ADVANCE_PAYROLL_DETAIL_SUCCESS,
  GET_ADVANCE_PAYROLL_DETAIL_FAILURE,
  REJECT_ADVANCE_DETAIL_START,
  REJECT_ADVANCE_DETAIL_SUCCESS,
  REJECT_ADVANCE_DETAIL_FAILURE,
  APPROVED_ADVANCE_DETAIL_START,
  APPROVED_ADVANCE_DETAIL_SUCCESS,
  APPROVED_ADVANCE_DETAIL_FAILURE,
  GET_ALL_TRANSFER_TRANSACTIONS_FAILURE,
  GET_ALL_TRANSFER_TRANSACTIONS_START,
  GET_ALL_TRANSFER_TRANSACTIONS_SUCCESS,
  GET_ALL_USER_TRANSACTIONS_FAILURE,
  GET_ALL_USER_TRANSACTIONS_START,
  GET_ALL_USER_TRANSACTIONS_SUCCESS,
  GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_FAILURE,
  GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_START,
  GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_SUCCESS,
  GET_SPECIFIC_BILLS_TRANSACTIONS_FAILURE,
  GET_SPECIFIC_BILLS_TRANSACTIONS_START,
  GET_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS,
  GET_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE,
  GET_SPECIFIC_PAYROLL_TRANSACTIONS_START,
  GET_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS,
  GET_SPECIFIC_TRANSACTIONS_FAILURE,
  GET_SPECIFIC_TRANSACTIONS_START,
  GET_SPECIFIC_TRANSACTIONS_SUCCESS,
  GET_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE,
  GET_SPECIFIC_TRANSFER_TRANSACTIONS_START,
  GET_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS,
  REVERT_SPECIFIC_BILLS_TRANSACTIONS_FAILURE,
  REVERT_SPECIFIC_BILLS_TRANSACTIONS_START,
  REVERT_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS,
  REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE,
  REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_START,
  REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS,
  REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE,
  REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_START,
  REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS,
  UPDATE_MANUAL_TRANSFERS_FAILURE,
  UPDATE_MANUAL_TRANSFERS_START,
  UPDATE_MANUAL_TRANSFERS_SUCCESS,
  VERIFY_SPECIFIC_BILLS_TRANSACTIONS_FAILURE,
  VERIFY_SPECIFIC_BILLS_TRANSACTIONS_START,
  VERIFY_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS,
  VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE,
  VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_START,
  VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS,
  VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE,
  VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_START,
  VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS,
} from "./types";

const initialState = {
  isLoadingAllTransactions: true,
  isLoadingAllTransferTransactions: true,
  isLoadingAllBillTransactions: true,
  isLoadingAllPayrollTransactions: true,
  isLoadingAllUserTransactions: true,
  isLoadingSpecificTransaction: true,
  isLoadingAdvanceDetails: false,
  isUpdatingSpecificAdvanceDetails: false,
  isLoadingSpecificTransferTransactions: true,
  isLoadingSpecificBillTransactions: true,
  isLoadingSpecificPayrollTransaction: true,
  isLoadingSpecificUserTransaction: true,
  isVerifyingSpecificTransferTransactions: false,
  isVerifyingSpecificBillTransactions: false,
  isVerifyingSpecificPayrollTransactions: false,
  isRevertingSpecificTransferTransactions: false,
  isRevertingSpecificBillTransactions: false,
  isRevertingSpecificPayrollTransactions: false,
  isLoadingCrossBorderManualTransfer: true,
  isUpdatingSingleManualTransfer: false,
  isLoadingPayrollSalaryAdvance: true,
  allTransactions: [],
  allTransferTransactions: [],
  allBillTransactions: [],
  allPayrollTransactions: [],
  payrollSalaryAdvance: [],
  allUserTransactions: [],
  specificTransaction: {},
  specificTransferTransactions: {},
  specificBillTransactions: {},
  specificPayrollTransactions: {},
  specificAdvanceDetails: {},
  specificUserTransactions: {},
  crossBorderManualTransfer: [],
  internationalTransfersTransactions: [],
};

export default function transactionsReducer(state = initialState, action) {
  switch (action.type) {
    // GET ALL TRANSACTIONS
    case GET_ALL_TRANSACTIONS_START:
      return {
        ...state,
        isLoadingAllTransactions: true,
      };

    case GET_ALL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingAllTransactions: false,
        allTransactions: action.payLoad,
      };

    case GET_ALL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingAllTransactions: false,
      };

    // GET ALL TRANSFER TRANSACTIONS
    case GET_ALL_TRANSFER_TRANSACTIONS_START:
      return {
        ...state,
        isLoadingAllTransferTransactions: true,
      };

    case GET_ALL_TRANSFER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingAllTransferTransactions: false,
        allTransferTransactions: action.payLoad,
      };

    case GET_ALL_TRANSFER_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingAllTransferTransactions: false,
      };

    // GET ALL BILL TRANSACTIONS
    case GET_ALL_BILLS_TRANSACTIONS_START:
      return {
        ...state,
        isLoadingAllBillTransactions: true,
      };

    case GET_ALL_BILLS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingAllBillTransactions: false,
        allBillTransactions: action.payLoad,
      };

    case GET_ALL_BILLS_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingAllBillTransactions: false,
      };

    // GET ALL PAYROLL TRANSACTIONS
    case GET_ALL_PAYROLL_TRANSACTIONS_START:
      return {
        ...state,
        isLoadingAllPayrollTransactions: true,
      };

    case GET_ALL_PAYROLL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingAllPayrollTransactions: false,
        allPayrollTransactions: action.payLoad,
      };

    case GET_ALL_PAYROLL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingAllPayrollTransactions: false,
      };

    // GET ALL PAYROLL SALARY ADVANCE
    case GET_PAYROLL_SALARY_ADVANCE_START:
      return {
        ...state,
        isLoadingPayrollSalaryAdvance: true,
      };

    case GET_PAYROLL_SALARY_ADVANCE_SUCCESS:
      return {
        ...state,
        isLoadingPayrollSalaryAdvance: false,
        payrollSalaryAdvance: action.payLoad,
      };

    case GET_PAYROLL_SALARY_ADVANCE_FAILURE:
      return {
        ...state,
        isLoadingPayrollSalaryAdvance: false,
      };

    // GET ALL User TRANSACTIONS
    case GET_ALL_USER_TRANSACTIONS_START:
      return {
        ...state,
        isLoadingAllUserTransactions: true,
      };

    case GET_ALL_USER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingAllUserTransactions: false,
        allUserTransactions: action.payLoad,
      };

    case GET_ALL_USER_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingAllUserTransactions: false,
      };

    // Get specific transaction
    case GET_SPECIFIC_TRANSACTIONS_START:
      return {
        ...state,
        isLoadingSpecificTransaction: true,
      };

    case GET_SPECIFIC_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingSpecificTransaction: false,
        specificTransaction: action.payLoad,
      };

    case GET_SPECIFIC_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingSpecificTransaction: false,
        specificTransaction: {},
      };

    // Get specific transfer transaction
    case GET_SPECIFIC_TRANSFER_TRANSACTIONS_START:
      return {
        ...state,
        isLoadingSpecificTransferTransactions: true,
      };

    case GET_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingSpecificTransferTransactions: false,
        specificTransferTransactions: action.payLoad,
      };

    case GET_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingSpecificTransferTransactions: false,
      };

    case GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_START:
      return {
        ...state,
        isLoadingSpecificTransferTransactions: true,
        internationalTransfersTransactions: [],
      };
    case GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingSpecificTransferTransactions: false,
        internationalTransfersTransactions: action.payLoad,
      };
    case GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingSpecificTransferTransactions: false,
        internationalTransfersTransactions: [],
      };
    // Get specific bill transaction
    case GET_SPECIFIC_BILLS_TRANSACTIONS_START:
      return {
        ...state,
        isLoadingSpecificBillTransactions: true,
      };

    case GET_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingSpecificBillTransactions: false,
        specificBillTransactions: action.payLoad,
      };

    case GET_SPECIFIC_BILLS_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingSpecificBillTransactions: false,
      };

    // Get specific bill transaction
    case GET_SPECIFIC_PAYROLL_TRANSACTIONS_START:
      return {
        ...state,
        isLoadingSpecificPayrollTransaction: true,
      };

    case GET_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingSpecificPayrollTransaction: false,
        specificPayrollTransactions: action.payLoad,
      };

    case GET_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoadingSpecificPayrollTransaction: false,
        specificPayrollTransactions: {},
      };

    case GET_ADVANCE_PAYROLL_DETAIL_START:
      return {
        ...state,
        isLoadingAdvanceDetails: true,
      };

    case GET_ADVANCE_PAYROLL_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingAdvanceDetails: false,
        specificAdvanceDetails: action.payLoad,
      };

    case GET_ADVANCE_PAYROLL_DETAIL_FAILURE:
      return {
        ...state,
        isLoadingAdvanceDetails: false,
      };

    case REJECT_ADVANCE_DETAIL_START:
      return {
        ...state,
        isUpdatingSpecificAdvanceDetails: true,
      };

    case REJECT_ADVANCE_DETAIL_SUCCESS:
      return {
        ...state,
        isUpdatingSpecificAdvanceDetails: false,
      };

    case REJECT_ADVANCE_DETAIL_FAILURE:
      return {
        ...state,
        isUpdatingSpecificAdvanceDetails: false,
      };

    case APPROVED_ADVANCE_DETAIL_START:
      return {
        ...state,
        isUpdatingSpecificAdvanceDetails: true,
      };

    case APPROVED_ADVANCE_DETAIL_SUCCESS:
      return {
        ...state,
        isUpdatingSpecificAdvanceDetails: false,
      };

    case APPROVED_ADVANCE_DETAIL_FAILURE:
      return {
        ...state,
        isUpdatingSpecificAdvanceDetails: false,
      };
    // verify specific transfer transaction
    case VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_START:
      return {
        ...state,
        isVerifyingSpecificTransferTransactions: true,
      };

    case VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isVerifyingSpecificTransferTransactions: false,
      };

    case VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isVerifyingSpecificTransferTransactions: false,
      };

    // verify specific bill transaction
    case VERIFY_SPECIFIC_BILLS_TRANSACTIONS_START:
      return {
        ...state,
        isVerifyingSpecificBillTransactions: true,
      };

    case VERIFY_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isVerifyingSpecificBillTransactions: false,
      };

    case VERIFY_SPECIFIC_BILLS_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isVerifyingSpecificBillTransactions: false,
      };

    // verify specific payroll transaction
    case VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_START:
      return {
        ...state,
        isVerifyingSpecificPayrollTransactions: true,
      };

    case VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isVerifyingSpecificPayrollTransactions: false,
      };

    case VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isVerifyingSpecificPayrollTransactions: false,
      };

    // Revert transfer transaction
    case REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_START:
      return {
        ...state,
        isRevertingSpecificTransferTransactions: true,
      };

    case REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isRevertingSpecificTransferTransactions: false,
      };

    case REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isRevertingSpecificTransferTransactions: false,
      };

    // Revert transfer transaction
    case REVERT_SPECIFIC_BILLS_TRANSACTIONS_START:
      return {
        ...state,
        isRevertingSpecificBillTransactions: true,
      };

    case REVERT_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isRevertingSpecificBillTransactions: false,
      };

    case REVERT_SPECIFIC_BILLS_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isRevertingSpecificBillTransactions: false,
      };

    // Revert transfer transaction
    case REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_START:
      return {
        ...state,
        isRevertingSpecificPayrollTransactions: true,
      };

    case REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isRevertingSpecificPayrollTransactions: false,
      };

    case REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isRevertingSpecificPayrollTransactions: false,
      };

    case CROSS_BORDER_MANUAL_TRANSFERS_START:
      return {
        ...state,
        isLoadingCrossBorderManualTransfer: true,
      };

    case CROSS_BORDER_MANUAL_TRANSFERS_SUCCESS:
      return {
        ...state,
        isLoadingCrossBorderManualTransfer: false,
        crossBorderManualTransfer: action.payLoad,
      };

    case CROSS_BORDER_MANUAL_TRANSFERS_FAILURE:
      return {
        ...state,
        isLoadingCrossBorderManualTransfer: false,
        crossBorderManualTransfer: [],
      };

    case UPDATE_MANUAL_TRANSFERS_START:
      return {
        ...state,
        isUpdatingSingleManualTransfer: true,
      };

    case UPDATE_MANUAL_TRANSFERS_SUCCESS:
      return {
        ...state,
        isUpdatingSingleManualTransfer: false,
      };

    case UPDATE_MANUAL_TRANSFERS_FAILURE:
      return {
        ...state,
        isUpdatingSingleManualTransfer: false,
      };

    default:
      return state;
  }
}

import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import { rejectLimitRequest } from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";

const RejectLimitModal = ({
  modalStates,
  setModalStates,
  showRejectRequestModal,
  requestedLimit: amount_requested,
}) => {
  const dispatch = useDispatch();
  const { otherId } = useParams();

  const { isRejectingLimitRequest } = useAccounts();

  const onRejectRequest = ({ reason }) => {
    dispatch(
      rejectLimitRequest(otherId, { reason }, () => {
        reset();
        setModalStates({
          ...modalStates,
          showRejectRequestModal: false,
        });
      })
    );
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  return (
    <BasicModal
      visibility={showRejectRequestModal}
      onClose={() =>
        setModalStates({ ...modalStates, showRejectRequestModal: false })
      }
      onOpen={() =>
        setModalStates({
          ...modalStates,
          showRejectRequestModal: true,
        })
      }
      primaryButton=""
      modalTitle="Decline Limit Request"
      secondaryButton=""
      modelContentSection={
        <form onSubmit={handleSubmit(onRejectRequest)} className="">
          <div className="">
            <label htmlFor="reason" className="block glade-small-text-two">
              Reason
            </label>
            <textarea
              id="reason"
              name="reason"
              {...register("reason", {
                required: "Enter a reason",
              })}
              placeholder="Enter a reason"
              rows={4}
              className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
            />
            <FormError errors={errors} name="reason" />
          </div>

          <div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
            <button
              type="button"
              onClick={() => {
                reset();
                setModalStates({
                  ...modalStates,
                  showRejectRequestModal: false,
                });
              }}
              className="glade-normal-text-two glade-black text-center"
            >
              Cancel
            </button>
            <div>
              <Button.Danger
                type="submit"
                title="Decline"
                className="block flex-grow-0"
                loading={isRejectingLimitRequest}
              />
            </div>
          </div>
        </form>
      }
    />
  );
};

export default RejectLimitModal;

import React, { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import Sidebar from "../components/sidebars/Sidebar.js";
import FooterDashboard from "../components/footers/FooterDashboard";

// pages
import Home from "../pages/home/Home";

// User Authorization Handler
import { setAuthFromCache } from "../redux/auth/authActions";
import useUser from "../hooks/useUser";
import BusinessCompliance from "../pages/compliance/Business";
import PersonalCompliance from "../pages/compliance/Personal";
import ComplianceDetail from "../pages/compliance/Details";
import TransferTransactions from "../pages/transactions/Transfers";
import BillsTransactions from "../pages/transactions/Bills";
import PayrollTransactions from "../pages/transactions/Payroll";
import BillTransactionDetail from "../pages/transactions/BillTransactionDetails";
import TransfersTransactionDetail from "../pages/transactions/TransfersTransactionDetails";
import SentNotifications from "../pages/notifications/SentNotifications";
import { getAllNotificationCategories } from "../redux/notifications/notificationsActions.js";
import DraftNotifications from "../pages/notifications/DraftNotifications.js";
import NewNotification from "../pages/notifications/NewNotification.js";
import EditNotification from "../pages/notifications/EditNotification.js";
import NGNSettledSettlements from "../pages/settlements/NGNSettledSettlements.js";
import SettledSettlementDetail from "../pages/settlements/SettledSettlementDetails.js";
import NGNUnsettledSettlements from "../pages/settlements/NGNUnsettledSettlements.js";
import UnsettledSettlementDetail from "../pages/settlements/UnsettledSettlementDetails.js";
import PosTerminals from "../pages/pos/PosTerminals.js";
import PosTerminalDetails from "../pages/pos/PosTerminalDetails.js";
import PosRequests from "../pages/pos/PosRequests.js";
import PosRequestDetails from "../pages/pos/PosRequestDetails.js";
import AgentRequests from "../pages/pos/AgentRequests.js";
import AgentRequestDetails from "../pages/pos/AgentRequestDetails.js";
import { getAllPosAccounts } from "../redux/pos/posActions.js";
import PersonalAccounts from "../pages/accounts/PersonalAccounts.js";
import PersonalAccountDetails from "../pages/accounts/PersonalDetail.js";
import BusinessAccounts from "../pages/accounts/BusinessAccounts.js";
import BusinessAccountDetails from "../pages/accounts/BusinessDetails.js";
import AgentAccounts from "../pages/accounts/AgentAccounts.js";
import AllPosInflows from "../pages/inflow/AllPosInflows.js";
import AllCheckoutInflows from "../pages/inflow/AllCheckoutInflows.js";
import AllTransferInflows from "../pages/inflow/AllTransferInflows.js";
import PosInflowDetails from "../pages/inflow/PosInflowDetails.js";
import CheckoutInflowDetails from "../pages/inflow/CheckoutInflowDetails.js";
import TransferInflowDetails from "../pages/inflow/TransferInflowDetails.js";
import PayrollTransactionDetail from "../pages/transactions/PayrollTransactionDetails.js";
import UserTransactions from "../pages/transactions/UserTransactions.js";
import USDUnsettledSettlements from "../pages/settlements/USDUnsettledSettlements.js";
import USDSettledSettlements from "../pages/settlements/USDSettledSettlements.js";
import AllTransactions from "../pages/allTransactions/AllTransactions.js";
import SpecificBillTransactionDetail from "../pages/allTransactions/SpecificBillTransactionDetails.js";
import SpecificTransfersTransactionDetail from "../pages/allTransactions/SpecificTransfersTransactionDetails.js";
import SpecificPosTransactionDetail from "../pages/allTransactions/SpecifcPosTransactionDetails.js";
import useShared from "../hooks/useShared.js";
import MemoizedLoadingModal from "../components/modals/LoadingModal.js";
import LimitRequests from "../pages/accounts/LimitRequests.js";
import LimitRequestDetails from "../pages/accounts/LimitRequestDetails.js";
import AllFees from "../pages/fees/AllFees.js";
import TransferProviders from "../pages/providers/TransferProviders.js";
import TransferProvidersCategories from "../pages/providers/TransferProvidersCategories.js";
import SettlementsProviders from "../pages/providers/SettlementsProviders.js";
import BillsProviders from "../pages/providers/BillsProviders.js";
import PosProviders from "../pages/providers/PosProviders.js";
import VirtualCardProviders from "../pages/providers/VirtualCardProviders.js";
import BvnProviders from "../pages/providers/BvnProviders.js";
import PaymentProviders from "../pages/providers/PaymentProviders.js";
import SettlementsProvidersCategories from "../pages/providers/SettlementsProvidersCategories.js";
import BillsProvidersCategories from "../pages/providers/BillsProvidersCategories.js";
import PosProvidersCategories from "../pages/providers/PosProvidersCategories.js";
import VirtualCardProvidersCategories from "../pages/providers/VirtualCardProvidersCategories.js";
import BvnProvidersCategories from "../pages/providers/BvnProvidersCategories.js";
import Logs from "../pages/audit/Logs.js";
import PaymentProvidersCategories from "../pages/providers/PaymentProvidersCategories.js";
import Discounts from "../pages/discounts/Discounts.js";
import NewFreeTransfer from "../pages/discounts/NewFreeTransfer.js";
import NewTransferDiscount from "../pages/discounts/NewTransferDiscount.js";
import EditTransferDiscount from "../pages/discounts/EditTransferDiscount.js";
import EditFreeTransfer from "../pages/discounts/EditFreeTransfer.js";
import DiscountDetails from "../pages/discounts/DiscountDetails.js";
import CrossBorderCharges from "../pages/cross-border/CrossBorderCharges.js";
import CrossBorderRates from "../pages/cross-border/CrossBorderRates.js";
import VirtualCardSettings from "../pages/settings/VirtualCardSettings.js";
import InternationalTransfers from "../pages/transactions/InternationalTransfers.js";
import InternationalTransfersDetails from "../pages/transactions/InternationalTransfersDetails";
import MultiCurrencyCharges from "../pages/multi-currency/MultiCurrrencyCharges.js";
import AllReferrals from "../pages/referrals/AllReferrals";
import ReferralSetup from "../pages/referrals/ReferralSetup";
import AllWalletRequests from "../pages/wallets/AllWalletRequests.js";
import WalletRequestDetails from "../pages/wallets/WalletRequestDetails.js";
import Currencies from "../pages/cross-border/Currencies.js";
import CacRegistration from "../pages/cac-registration/CacRegistrations.js";
import CacRegistrationDetails from "../pages/cac-registration/CacRegistrationDetails.js";
import AllAccountRequests from "../pages/wallets/AllAccountRequests.js";
import AccountRequestDetails from "../pages/wallets/AccountRequestDetails.js";
import PayrollLoanDetails from "../pages/transactions/PayrollLoanDetails.js";
import PayrollLoanRequests from "../pages/transactions/PayrollLoanRequests.js";
import CheckoutFees from "../pages/fees/CheckoutFees.js";

export default function DashboardLayout() {
	// Restore user details from cache
	const dispatch = useDispatch();
	const user = useUser();
	const { isLoading } = useShared();

	React.useLayoutEffect(() => {
		dispatch(setAuthFromCache());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (user && user.token) {
			dispatch(getAllPosAccounts());
			dispatch(getAllNotificationCategories());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.token]);

	// React.useEffect(() => {
	// 	if (!user.token) {
	// 		history.push("/auth/logout");
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [user.token]);
	let location = useLocation();

	return (
		<React.Fragment>
			{!user.token ? (
				<Redirect
					to={{ pathname: "/auth/logout", state: { from: location } }}
					push
				/>
			) : null}

			{/* Sidebar */}
			<Sidebar />

			<main className="relative md:ml-52 glade-bg-accent-light min-h-screen">
				{/* Navbar */}
				{/* <NavbarDashboard /> */}

				{/* Body */}
				<section className="p-6">
					<Switch>
						<Route
							exact
							path="/dashboard/fees"
							component={AllFees}
						/>
						<Route
							path="/dashboard/virtual-card/settings"
							component={VirtualCardSettings}
						/>
						<Route
							exact
							path="/dashboard/discounts/edit-free-transfer/:id"
							component={EditFreeTransfer}
						/>
						<Route
							exact
							path="/dashboard/discounts/edit-transfer-discount/:id"
							component={EditTransferDiscount}
						/>
						<Route
							exact
							path="/dashboard/discounts/new-transfer-discount"
							component={NewTransferDiscount}
						/>
						<Route
							exact
							path="/dashboard/discounts/new-free-transfer"
							component={NewFreeTransfer}
						/>
						<Route
							exact
							path="/dashboard/discounts/:id"
							component={DiscountDetails}
						/>
						<Route
							exact
							path="/dashboard/discounts"
							component={Discounts}
						/>
						<Route
							exact
							path="/dashboard/fees/checkout"
							component={CheckoutFees}
						/>
						<Route
							exact
							path="/dashboard/inflow/transfer/detail/:txn_ref"
							component={TransferInflowDetails}
						/>
						<Route
							exact
							path="/dashboard/inflow/transfers"
							component={AllTransferInflows}
						/>
						<Route
							exact
							path="/dashboard/inflow/checkout/detail/:txn_ref"
							component={CheckoutInflowDetails}
						/>
						<Route
							exact
							path="/dashboard/inflow/checkout"
							component={AllCheckoutInflows}
						/>
						<Route
							exact
							path="/dashboard/inflow/pos/detail/:txn_ref"
							component={PosInflowDetails}
						/>
						<Route exact path="/dashboard/inflow" component={AllPosInflows} />
						<Route
							exact
							path="/dashboard/accounts/transaction/history/:id"
							component={UserTransactions}
						/>
						<Route
							exact
							path="/dashboard/accounts/business/detail/:id/:user_uuid"
							component={BusinessAccountDetails}
						/>
						<Route
							exact
							path="/dashboard/accounts/business"
							component={BusinessAccounts}
						/>
						<Route
							exact
							path="/dashboard/accounts/personal/detail/:id"
							component={PersonalAccountDetails}
						/>
						<Route
							exact
							path="/dashboard/accounts"
							component={PersonalAccounts}
						/>
						<Route
							exact
							path="/dashboard/accountrequests/wallets"
							component={AllWalletRequests}
            />
            <Route
							exact
							path="/dashboard/accountrequests/accounts"
							component={AllAccountRequests}
            />
            <Route
							exact
							path="/dashboard/account-request/details"
							component={AccountRequestDetails}
						/>
						<Route
							exact
							path="/dashboard/wallet-request/details"
							component={WalletRequestDetails}
						/>
						<Route
							exact
							path="/dashboard/accounts/agents"
							component={AgentAccounts}
						/>
						<Route
							exact
							path="/dashboard/accounts/limit/detail/:id/:otherId"
							component={LimitRequestDetails}
						/>
						<Route
							exact
							path="/dashboard/accounts/limit"
							component={LimitRequests}
						/>
						<Route
							exact
							path="/dashboard/pos/agent-requests/detail/:id"
							component={AgentRequestDetails}
						/>
						<Route
							exact
							path="/dashboard/pos/agent-requests"
							component={AgentRequests}
						/>
						<Route
							exact
							path="/dashboard/pos/requests/detail/:id"
							component={PosRequestDetails}
						/>
						<Route
							exact
							path="/dashboard/pos/requests"
							component={PosRequests}
						/>
						<Route
							exact
							path="/dashboard/pos/terminal/detail/:id"
							component={PosTerminalDetails}
						/>
						<Route exact path="/dashboard/pos" component={PosTerminals} />
						<Route
							exact
							path="/dashboard/settlements/unsettled/detail"
							component={UnsettledSettlementDetail}
						/>
						<Route
							exact
							path={"/dashboard/audit-logs"}
							component={Logs}
						/>
						<Route
							exact
							path="/dashboard/settlements/settled/detail/:id"
							component={SettledSettlementDetail}
						/>
						<Route
							exact
							path="/dashboard/settlements/usd/unsettled"
							component={USDUnsettledSettlements}
						/>
						<Route
							exact
							path="/dashboard/settlements/usd"
							component={USDSettledSettlements}
						/>
						<Route
							exact
							path="/dashboard/settlements/ngn/unsettled"
							component={NGNUnsettledSettlements}
						/>
						<Route
							exact
							path="/dashboard/settlements/ngn"
							component={NGNSettledSettlements}
						/>
						<Route
							exact
							path="/dashboard/notifications/new"
							component={NewNotification}
						/>
						<Route
							exact
							path="/dashboard/notifications/edit"
							component={EditNotification}
						/>
						<Route
							exact
							path="/dashboard/notifications/drafts"
							component={DraftNotifications}
						/>
						<Route
							exact
							path="/dashboard/notifications/"
							component={SentNotifications}
						/>
						<Route
							exact
							path="/dashboard/outflows/bills/detail/:id"
							component={BillTransactionDetail}
						/>
						<Route
							exact
							path="/dashboard/outflows/transfers/detail/:id"
							component={TransfersTransactionDetail}
						/>
						<Route
							exact
							path="/dashboard/outflows/international-transfers/detail/:id"
							component={InternationalTransfersDetails}
						/>
						<Route
							exact
							path="/dashboard/outflows/payroll/detail/:id"
							component={PayrollTransactionDetail}
						/>
						<Route
							exact
							path="/dashboard/outflows/payroll"
							component={PayrollTransactions}
						/>
							<Route
							exact
							path="/dashboard/payroll/loan/requests/detail/:id"
							component={PayrollLoanDetails}
						/>
						<Route
							exact
							path="/dashboard/payroll/loan/requests"
							component={PayrollLoanRequests}
						/>
						<Route
							exact
							path="/dashboard/outflows/bills"
							component={BillsTransactions}
						/>
						<Route
							exact
							path="/dashboard/outflows/international-transfers"
							component={InternationalTransfers}
						/>
						<Route
							exact
							path="/dashboard/outflows/"
							component={TransferTransactions}
						/>
						<Route
							exact
							path="/dashboard/all-transactions/bills/details/:id"
							component={SpecificBillTransactionDetail}
						/>
						{/* <Route
							exact
							path="/dashboard/all-transactions/checkout/details/:id"
							component={AllTransactions}
						/> */}
						<Route
							exact
							path="/dashboard/all-transactions/pos/details/:id"
							component={SpecificPosTransactionDetail}
						/>
						<Route
							exact
							path="/dashboard/all-transactions/transfer/details/:id"
							component={SpecificTransfersTransactionDetail}
						/>
						<Route
							exact
							path="/dashboard/all-transactions/"
							component={AllTransactions}
						/>
						<Route
							exact
							path="/dashboard/compliance/:type/detail/:id"
							component={ComplianceDetail}
						/>
						<Route
							exact
							path="/dashboard/compliance/business"
							component={BusinessCompliance}
						/>
						<Route
							exact
							path="/dashboard/compliance/"
							component={PersonalCompliance}
						/>
						{/* /dashboard/cross-border/rates */}
						<Route
							exact
							path="/dashboard/cross-border/rates"
							component={CrossBorderRates}
						/>
						<Route
							exact
							path="/dashboard/cross-border/charges"
							component={CrossBorderCharges}
						/>
						<Route
							exact
							path="/dashboard/currencies"
							component={Currencies}
						/>
						<Route
							exact
							path="/dashboard/multi-currency"
							component={MultiCurrencyCharges}
						/>
						<Route
							exact
							path="/dashboard/providers/transfer/categories"
							component={TransferProvidersCategories}
						/>
						<Route
							exact
							path="/dashboard/providers/transfer/categories/:category"
							component={TransferProviders}
						/>

						<Route
							exact
							path="/dashboard/providers/settlement/categories"
							component={SettlementsProvidersCategories}
						/>
						<Route
							exact
							path="/dashboard/providers/settlement/categories/:category"
							component={SettlementsProviders}
						/>
						<Route
							exact
							path="/dashboard/providers/bills/categories"
							component={BillsProvidersCategories}
						/>
						<Route
							exact
							path="/dashboard/providers/bills/categories/:category"
							component={BillsProviders}
						/>
						<Route
							exact
							path="/dashboard/providers/pos/categories"
							component={PosProvidersCategories}
						/>
						<Route
							exact
							path="/dashboard/providers/pos/categories/:category"
							component={PosProviders}
						/>
						<Route
							exact
							path="/dashboard/providers/virtual_card/categories"
							component={VirtualCardProvidersCategories}
						/>
						<Route
							exact
							path="/dashboard/providers/virtual_card/categories/:category"
							component={VirtualCardProviders}
						/>
						<Route
							exact
							path="/dashboard/providers/bvn/categories"
							component={BvnProvidersCategories}
						/>
						<Route
							exact
							path="/dashboard/providers/bvn/categories/:category"
							component={BvnProviders}
						/>
						<Route
							exact
							path="/dashboard/providers/payment/categories"
							component={PaymentProvidersCategories}
						/>
						<Route
							exact
							path="/dashboard/providers/payment/categories/:category"
							component={PaymentProviders}
            />
            <Route
							exact
							path="/dashboard/referrals"
							component={AllReferrals}
            />
             <Route
							exact
							path="/dashboard/referrals/setup"
							component={ReferralSetup}
						/>
						<Route
							exact
							path="/dashboard/cac/registrations"
							component={CacRegistration}
						/>
						<Route
							exact
							path="/dashboard/cac/registrations/details"
							component={CacRegistrationDetails}
						/>
						<Route exact path="/dashboard/home" component={Home} />
						<Redirect from="/dashboard" to="/dashboard/home" />
					</Switch>
				</section>

				{/* Footer */}
				<FooterDashboard />
			</main>

			{/* Loading Modal */}
			<MemoizedLoadingModal
				primaryButton=""
				modalTitleSection=""
				secondaryButton=""
				visibility={isLoading}
			/>
		</React.Fragment>
	);
}

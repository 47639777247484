import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TextSkeleton } from "../../components/skeletons";
import { isEmptyArray } from "../../helpers/helper";
import useAccounts from "../../hooks/useAccounts";
import { getBusinessAccountCompliance } from "../../redux/accounts/accountsActions";
import EditBusinessComplianceModal from "./EditBusinessComplianceModal";
import EditBusinessDescriptionModal from "./EditBusinessDescriptionModal";

const BusinessComplianceCard = ({ id, details }) => {

	const [selectedCompliance, setSelectedCompliance] = useState({});
	const dispatch = useDispatch();
	const { specificBusinessAccountCompliance, isLoadingBusinessMerchantCompliance } = useAccounts();

	const [modalStates, setModalStates] = useState({
		showEditBusinessBvnModal: false,
		showEditBusinessNameModal: false, //ok
		showEditBusinessEmailModal: false,
		showEditBusinessPhoneModal: false,
		showEditBusinessAddressModal: false,
		showEditBusinessServicesModal: false,
		showEditBusinessComplianceModal: false,
		showEditBusinessSettlementAccountModal: false,
		showEditBusinessSettlementTypeModal: false,
		showEditBusinessDescriptionModal: false,
		showFundAccountModal: false,
	});

	useEffect(() => {
		dispatch(getBusinessAccountCompliance(id));
	}, [id]);

	return (
		<div className="">
			<div className="flex flex-col bg-white shadow rounded-md p-8 pt-4 h-fit-content">
				<div className="py-3 border-b-2 border-solid border-gray-100 mb-5">
					<p className="glade-black glade-normal-text-two">Compliance</p>
				</div>

				{/* Compliance Array */}
				{isLoadingBusinessMerchantCompliance ? (
					<TextSkeleton />
				) : (
					<>
						{details && !isEmptyArray(details) && details[0]?.account_name && (
							<div className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4">
								<div className="flex flex-col justify-between">
									<h5 className="glade-normal-text-two glade-ash py-1">
										Business Name
									</h5>
									<h6 className="glade-normal-text-three glade-black py-1 capitalize">
										{details[0]?.account_name}
									</h6>
								</div>
								<h6
									className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer flex-shrink-0"
									onClick={() => {
										setModalStates({
											...modalStates,
											showEditBusinessNameModal: true,
										});
									}}
								>
									Edit
								</h6>
							</div>
						)}
						{specificBusinessAccountCompliance?.business_details?.[0]
							?.business_description && (
								<div className="flex justify-between rounded p-3 border border-gray-300 mb-4 max-w-full">
									<div className="flex flex-col justify-between">
										<h5 className="glade-normal-text-two glade-ash py-1">
											Business Description
										</h5>
										<h6 className="glade-normal-text-three glade-black py-1 capitalize pr-1.5 break-all">
											{
												specificBusinessAccountCompliance?.business_details?.[0]
													?.business_description
											}
										</h6>
									</div>
									<h6
										className="glade-normal-text-three glade-blue-darker mt-6 py-1 capitalize cursor-pointer flex-shrink-0"
										onClick={() => {
											setModalStates({
												...modalStates,
												showEditBusinessDescriptionModal: true,
											});
										}}
									>
										Edit
									</h6>
								</div>
							)}

						{details?.business_details?.business?.contact_phone && (
							<div className="flex justify-between rounded p-3 border border-gray-300 mb-4">
								<div className="flex flex-col justify-between">
									<h5 className="glade-normal-text-two glade-ash py-1">
										Business Phone
									</h5>
									<h6 className="glade-normal-text-three glade-black py-1">
										{details?.business_details?.business?.contact_phone}
									</h6>
								</div>
								<h6
									className="glade-normal-text-three glade-blue-darker mt-6 py-1 capitalize cursor-pointer flex-shrink-0"
									onClick={() => {
										setModalStates({
											...modalStates,
											showEditBusinessPhoneModal: true,
										});
									}}
								>
									Edit
								</h6>
							</div>
						)}

						{details?.business_details?.business?.business_address && (
							<div className="flex justify-between rounded p-3 border border-gray-300 mb-4">
								<div className="flex flex-col justify-between">
									<h5 className="glade-normal-text-two glade-ash py-1">
										Business Address
									</h5>
									<h6 className="glade-normal-text-three glade-black py-1 capitalize break-all pr-2">
										{details?.business_details?.business?.business_address}
									</h6>
								</div>
								<h6
									className="glade-normal-text-three glade-blue-darker mt-6 py-1 capitalize cursor-pointer flex-shrink-0"
									onClick={() => {
										setModalStates({
											...modalStates,
											showEditBusinessAddressModal: true,
										});
									}}
								>
									Edit
								</h6>
							</div>
						)}

						{specificBusinessAccountCompliance?.compliance_details?.map(
							(detail, idx) => (
								<div
									key={idx}
									className="flex items-center justify-between rounded p-3 border border-gray-300 mb-4"
								>
									<div className="flex flex-col justify-between">
										<h5 className="glade-normal-text-two glade-ash py-1 capitalize">
											{detail?.title?.split("_").join(" ")}
										</h5>
										{detail.compliance_data || detail.file_url ? (
											<>
												{detail.type === "file" ? (
													<>
														<a
															href={detail.file_url}
															target="_blank"
															rel="noreferrer noopener"
														>
															<h6
																className="glade-normal-text-three glade-blue py-1 cursor-pointer capitalize"
															// onClick={() =>
															// 	previewCompliance(detail.file_url)
															// }
															>
																view document
															</h6>
														</a>
													</>
												) : (
													<h6 className="glade-normal-text-three glade-black py-1 capitalize">
														{detail?.compliance_data}
													</h6>
												)}
											</>
										) : (
											<TextSkeleton />
										)}
									</div>
									<h6
										className="glade-normal-text-three glade-blue-darker py-1 capitalize cursor-pointer flex-shrink-0"
										onClick={() => {
											setSelectedCompliance(detail);
											setModalStates({
												...modalStates,
												showEditBusinessComplianceModal: true,
											});
										}}
									>
										Edit
									</h6>
								</div>
							)
						)}
					</>
				)}
			</div>

			{/* Modals */}
			<EditBusinessDescriptionModal
				modalStates={modalStates}
				setModalStates={setModalStates}
				showEditBusinessDescriptionModal={
					modalStates?.showEditBusinessDescriptionModal
				}
			/>

			<EditBusinessComplianceModal
				modalStates={modalStates}
				setModalStates={setModalStates}
				selectedCompliance={selectedCompliance}
				setSelectedCompliance={setSelectedCompliance}
				showEditBusinessComplianceModal={
					modalStates?.showEditBusinessComplianceModal
				}
			/>
		</div>
	)
}

export default BusinessComplianceCard;

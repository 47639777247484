import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastSuccess } from "../../helpers/utils.toast";
import {
  CREATE_DRAFT_NOTIFICATION_FAILURE,
  CREATE_DRAFT_NOTIFICATION_START,
  CREATE_DRAFT_NOTIFICATION_SUCCESS,
  DELETE_SPECIFIC_DRAFT_NOTIFICATION_FAILURE,
  DELETE_SPECIFIC_DRAFT_NOTIFICATION_START,
  DELETE_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS,
  EDIT_SPECIFIC_DRAFT_NOTIFICATION_FAILURE,
  EDIT_SPECIFIC_DRAFT_NOTIFICATION_START,
  EDIT_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS,
  GET_ALL_DRAFT_NOTIFICATIONS_FAILURE,
  GET_ALL_DRAFT_NOTIFICATIONS_START,
  GET_ALL_DRAFT_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATION_CATEGORIES_FAILURE,
  GET_ALL_NOTIFICATION_CATEGORIES_START,
  GET_ALL_NOTIFICATION_CATEGORIES_SUCCESS,
  GET_ALL_SENT_NOTIFICATIONS_FAILURE,
  GET_ALL_SENT_NOTIFICATIONS_START,
  GET_ALL_SENT_NOTIFICATIONS_SUCCESS,
  GET_SPECIFIC_DRAFT_NOTIFICATION_FAILURE,
  GET_SPECIFIC_DRAFT_NOTIFICATION_START,
  GET_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS,
  SENDING_MULTIPLE_NOTIFICATIONS_FAILURE,
  SENDING_MULTIPLE_NOTIFICATIONS_START,
  SENDING_MULTIPLE_NOTIFICATIONS_SUCCESS,
  SENDING_NOTIFICATION_FAILURE,
  SENDING_NOTIFICATION_START,
  SENDING_NOTIFICATION_SUCCESS,
} from "./types";

export const getAllNotificationCategories = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_ALL_NOTIFICATION_CATEGORIES_START });
    Axios.get(`notifications/notification-category`, params)
      .then((res) => {
        dispatch({
          type: GET_ALL_NOTIFICATION_CATEGORIES_SUCCESS,
          payLoad: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_ALL_NOTIFICATION_CATEGORIES_FAILURE, payLoad: error });
        handler(error);
      });
  };
};

export const getAllSentNotifications = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_ALL_SENT_NOTIFICATIONS_START });
    Axios.get(`notifications/sent-notifications`, params)
      .then((res) => {
        dispatch({
          type: GET_ALL_SENT_NOTIFICATIONS_SUCCESS,
          payLoad: res.data.data?.reverse(),
        });
      })
      .catch((error) => {
        dispatch({ type: GET_ALL_SENT_NOTIFICATIONS_FAILURE, payLoad: error });
        handler(error);
      });
  };
};

export const getAllDraftNotifications = (params) => {
  return (dispatch) => {
    dispatch({ type: GET_ALL_DRAFT_NOTIFICATIONS_START });
    Axios.get(`notifications/drafts`, params)
      .then((res) => {
        dispatch({
          type: GET_ALL_DRAFT_NOTIFICATIONS_SUCCESS,
          payLoad: res.data.data?.reverse(),
        });
      })
      .catch((error) => {
        dispatch({ type: GET_ALL_DRAFT_NOTIFICATIONS_FAILURE, payLoad: error });
        handler(error);
      });
  };
};

// export const getSpecificSentNotification = (id) => {
//   return (dispatch) => {
//     dispatch({ type: GET_SPECIFIC_TRANSFER_TRANSACTIONS_START });
//     Axios.get(`transactions/transfer/${id}`)
//       .then((res) => {
//         dispatch({
//           type: GET_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS,
//           payLoad: res.data.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: GET_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE,
//           payLoad: error,
//         });
//         handler(error);
//       });
//   };
// };

export const getSpecificDraftNotification = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_SPECIFIC_DRAFT_NOTIFICATION_START });
    Axios.get(`notifications/drafts/${id}/detail`)
      .then((res) => {
        dispatch({
          type: GET_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS,
          payLoad: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SPECIFIC_DRAFT_NOTIFICATION_FAILURE,
          payLoad: error,
        });
        handler(error);
      });
  };
};

export const sendNotification = (data) => {
  return (dispatch) => {
    dispatch({ type: SENDING_NOTIFICATION_START });
    Axios.post(`notifications/send-notification`, data)
      .then((res) => {
        dispatch({
          type: SENDING_NOTIFICATION_SUCCESS,
          payLoad: res.data.data,
        });
        toastSuccess(res.data.message);
        window.history.back();
        // window.location.reload();
        // getSpecificSentNotification(id);
      })
      .catch((error) => {
        dispatch({
          type: SENDING_NOTIFICATION_FAILURE,
          payLoad: error,
        });
        handler(error, true);
      });
  };
};

export const resendNotification = (data) => {
  return (dispatch) => {
    dispatch({ type: SENDING_NOTIFICATION_START });
    Axios.post(`notifications/send-notification`, data)
      .then((res) => {
        dispatch({
          type: SENDING_NOTIFICATION_SUCCESS,
          payLoad: res.data.data,
        });
        toastSuccess(res.data.message);
        // window.history.back();
        window.location.reload();
        // getSpecificSentNotification(id);
      })
      .catch((error) => {
        dispatch({
          type: SENDING_NOTIFICATION_FAILURE,
          payLoad: error,
        });
        handler(error, true);
      });
  };
};

export const createDraftNotification = (data) => {
  return (dispatch) => {
    dispatch({ type: CREATE_DRAFT_NOTIFICATION_START });
    Axios.post(`notifications/drafts/create`, data)
      .then((res) => {
        dispatch({
          type: CREATE_DRAFT_NOTIFICATION_SUCCESS,
          payLoad: res.data.data,
        });
        toastSuccess(res.data.message);
        window.history.back();
        // window.location.reload();
        // getSpecificSentNotification(id);
      })
      .catch((error) => {
        dispatch({
          type: CREATE_DRAFT_NOTIFICATION_FAILURE,
          payLoad: error,
        });
        handler(error, true);
      });
  };
};

export const editSpecificDraftNotification = (id, data) => {
  return (dispatch) => {
    dispatch({ type: EDIT_SPECIFIC_DRAFT_NOTIFICATION_START });
    Axios.post(`notifications/drafts/${id}/update`, data)
      .then((res) => {
        dispatch({
          type: EDIT_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS,
          payLoad: res.data.data,
        });
        toastSuccess(res.data.message);
        window.history.back();
        // getSpecificSentNotification(id);
      })
      .catch((error) => {
        dispatch({
          type: EDIT_SPECIFIC_DRAFT_NOTIFICATION_FAILURE,
          payLoad: error,
        });
        handler(error, true);
      });
  };
};

export const deleteSpecificDraftNotification = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_SPECIFIC_DRAFT_NOTIFICATION_START });
    Axios.get(`notifications/drafts/${id}/delete`)
      .then((res) => {
        dispatch({
          type: DELETE_SPECIFIC_DRAFT_NOTIFICATION_SUCCESS,
          payLoad: res.data.data,
        });
        toastSuccess(res.data.message);
        window.location.reload();
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SPECIFIC_DRAFT_NOTIFICATION_FAILURE,
          payLoad: error,
        });
        handler(error, true);
      });
  };
};

export const sendMultipleNotifications = (data) => {
  return (dispatch) => {
    dispatch({ type: SENDING_MULTIPLE_NOTIFICATIONS_START });
    Axios.post(`notifications/send-multiple-notifications`, data)
      .then((res) => {
        dispatch({
          type: SENDING_MULTIPLE_NOTIFICATIONS_SUCCESS,
          payLoad: res.data.data,
        });
        toastSuccess(res.data.message);
        // window.history.back();
        window.location.reload();
        // getSpecificSentNotification(id);
      })
      .catch((error) => {
        dispatch({
          type: SENDING_MULTIPLE_NOTIFICATIONS_FAILURE,
          payLoad: error,
        });
        handler(error, true);
      });
  };
};

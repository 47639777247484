import { useSelector } from "react-redux";

const useUser = () => {
  const user = useSelector((state) => state.auth);

  const {
    isLoading,
    has_passcode,
    is_bvn_matched,
    is_email_verified,
    isBusiness,
    token,
  } = user;

  return {
    isLoading,
    userInfo: user?.user?.user,
    roleInfo: user?.user?.role,
    permissions: user?.user?.permissions,
    has_passcode,
    is_bvn_matched,
    is_email_verified,
    isBusiness,
    token,
  };
};

export default useUser;

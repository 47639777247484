import { useSelector } from "react-redux";

const useCompliance = () => {
  const compliance = useSelector((state) => state.compliance);

  const {
    isLoadingAllBusinessCompliance,
    isLoadingAllPersonalCompliance,
    isLoadingSpecificPersonalCompliance,
    isLoadingSpecificBusinessCompliance,
    isUpdatingSpecificPersonalCompliance,
    isUpdatingSpecificBusinessCompliance,
    allBusinessCompliance,
    allPersonalCompliance,
    specificPersonalCompliance,
    specificBusinessCompliance,
    businessDirectors,
    businessPrimaryOwner
  } = compliance;

  return {
    isLoadingAllBusinessCompliance,
    isLoadingAllPersonalCompliance,
    isLoadingSpecificPersonalCompliance,
    isLoadingSpecificBusinessCompliance,
    isUpdatingSpecificPersonalCompliance,
    isUpdatingSpecificBusinessCompliance,
    allBusinessCompliance,
    allPersonalCompliance,
    specificPersonalCompliance,
    specificBusinessCompliance,
    businessDirectors,
    businessPrimaryOwner
  };
};

export default useCompliance;

import React from 'react'
import { Control } from './styled'
import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai'

const AccordionControl = ({ isOpen, toggleAccordion, firstName = "-", lastName = "-" }) => {
  return (
    <Control isOpen={isOpen}>
      <p
        className='font-bold py-2 title flex items-center gap-1 cursor-pointer hover:text-blue'
        onClick={toggleAccordion}
      >
        <span>{isOpen ? <AiFillCaretDown /> : <AiFillCaretRight />}</span>
        {/* {extractFullName(contents)} */}
        <span className='capitalize'>{`${firstName} ${lastName}`}</span>
      </p>
    </Control>
  )
}

export default AccordionControl
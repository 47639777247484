import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { Table } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import TabNav from "../../components/navbars/TabNav";
import {
	deleteSpecificDraftNotification,
	getAllDraftNotifications,
	sendMultipleNotifications,
} from "../../redux/notifications/notificationsActions";
import useNotifications from "../../hooks/useNotifications";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import { fixDateForAllBrowsers } from "../../helpers/helper";

export default function DraftNotifications() {
	const dispatch = useDispatch();
	let history = useHistory();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);
	const [notificationToDelete, setNotificationToDelete] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSendModal, setShowSendModal] = useState(false);
	const [selectedDrafts, setSelectedDrafts] = useState([]);

	const {
		isLoadingAllDraftNotifications,
		allDraftNotifications,
		isDeletingDraftNotification,
		isSendingMultipleNotifications,

		allNotificationCategories,
	} = useNotifications();

	const defaultFilterParams = {
		search: "",
		page: currentPage,
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 150), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, search, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_from, date_to, search]);

	React.useMemo(() => {
		dispatch(
			getAllDraftNotifications({
				params: {
					...(search
						? { search, paginate: 1, page, per_page: 10 }
						: {
								start_date: date_from,
								end_date: date_to,
								paginate: 1,
								page,
								per_page: 10,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		page,
		// per_page,
		search,
	]);

	// // Clear query param on render
	// useEffect(() => {
	//   urlSearchParamsHistory.replace({});
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	React.useMemo(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	//  Send notification
	const toSendNotifications = ({ category, type, date, time }) => {
		let send_time = null;

		if (type === "scheduled") {
			send_time = date + " " + time;
		}

		dispatch(
			sendMultipleNotifications({
				notification_id: selectedDrafts,
				notification_type: type,
				notification_category: category,
				start_time: send_time,
			})
		);
	};

	// Update selected draft notifications
	const updateSelectedDrafts = (id) => {
		if (selectedDrafts.includes(id)) {
			const newList = selectedDrafts.filter((_id) => _id !== id);
			setSelectedDrafts(newList);
		} else {
			const oldList = [...selectedDrafts];
			oldList.push(id);
			setSelectedDrafts(oldList);
		}
	};

	// Open Delete Notification Modal
	const openDeleteModal = (id) => {
		setNotificationToDelete(id);
		setShowDeleteModal(true);
	};

	// Delete notification
	const deleteNotification = () => {
		dispatch(deleteSpecificDraftNotification(notificationToDelete));
	};

	const onClickEdit = (draft) => {
		history.push({ pathname: "/dashboard/notifications/edit", state: draft });
	};

	const columns = [
		{
			accessor: "id",
			Header: "",
			Cell: ({ value }) => (
				<span className="glade-normal-text-two capitalize">
					<input
						type="checkbox"
						checked={selectedDrafts.includes(value)}
						onChange={() => updateSelectedDrafts(value)}
					/>
				</span>
			),
		},
		{
			accessor: "title",
			Header: "Notification Title",
			Cell: ({ value, row }) => (
				// <Link to={`/dashboard/outflows/bills/detail/${row.original.id}`}>
				<span className="glade-normal-text-two capitalize">{value}</span>
				// </Link>
			),
		},
		{
			accessor: "body",
			Header: "Message",
			Cell: ({ value, row }) => (
				// <Link to={`/dashboard/outflows/bills/detail/${row.original.id}`}>
				<p className="glade-normal-text-two w-64 whitespace-normal">{value}</p>
				// </Link>
			),
		},
		{
			accessor: "created_at",
			Header: "Date Added",
			Cell: ({ value }) => (
				<span className="glade-normal-text-two">
					{dfn.format(new Date(fixDateForAllBrowsers(value)), "MMM d, yyyy hh:mm:ss a")}
				</span>
			),
		},
		{
			accessor: "is_draft",
			Header: "",
			Cell: ({ value, row }) => (
				<div className="flex items-center justify-center">
					<span
						className="glade-blue glade-normal-text-two cursor-pointer"
						onClick={() => onClickEdit(row.original)}
					>
						Edit
					</span>
					<div className="border-gray-500 border-r h-4 w-1 mx-2" />
					<span
						className="glade-red glade-normal-text-two cursor-pointer"
						onClick={() => openDeleteModal(row.original.id)}
					>
						Delete
					</span>
				</div>
			),
		},
	];

	const {
		handleSubmit,
		register,
		formState: { errors },
		watch,
	} = useForm();

	const notificationType = watch("type");

	return (
		<div className="p-3">
			<TabNav
				tabs={[
					{ title: "Sent Notifications", path: "/dashboard/notifications" },
					{ title: "Drafts", path: "/dashboard/notifications/drafts" },
				]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				filterFields={["date"]}
				filterCallback={handleChange}
				disabled={isLoadingAllDraftNotifications}
				title={`Notifications ${
					!isLoadingAllDraftNotifications && allDraftNotifications?.length
						? "- " + allDraftNotifications?.length
						: ""
				}`}
				actionButton={
					<div className="flex items-center">
						<Button
							title={"Send Notification"}
							disabled={selectedDrafts?.length === 0}
							onClick={() => setShowSendModal(true)}
						/>
						<Link
							to="/dashboard/notifications/new"
							className="ml-4 flex-shrink-0"
						>
							<Button.Dark title={"+ New Notification"} />
						</Link>
					</div>
				}
				searchBarPlaceholder={"Notification Title"}
			/>
			<Table
				columns={columns}
				data={
					typeof allDraftNotifications === "object" &&
					!Array.isArray(allDraftNotifications)
						? allDraftNotifications?.data
						: allDraftNotifications
				}
				empty_message="No notifications"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllDraftNotifications}
				setCurrentPage={setCurrentPage}
			/>

			{/* Send modal */}
			<BasicModal
				visibility={showSendModal}
				onClose={() => setShowSendModal(false)}
				onOpen={() => setShowSendModal(true)}
				primaryButton=""
				// modalTitleSection=""
				modalTitle="Send Notification"
				secondaryButton=""
				modelContentSection={
					<form
						onSubmit={handleSubmit(toSendNotifications)}
						className="p-6 pb-0 px-0"
					>
						<div className="w-full my-3">
							<label htmlFor="category" className="block glade-small-text-two">
								Select Category
							</label>
							<select
								id="category"
								name="category"
								{...register("category", {
									required: "Select a category",
								})}
								className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
							>
								{allNotificationCategories?.map((category) => (
									<option
										key={category.id}
										value={category.id}
										className="capitalize"
									>
										{category.name}
									</option>
								))}
							</select>
							<FormError errors={errors} name="category" />
						</div>

						<div className="w-full my-3">
							<label htmlFor="type" className="block glade-small-text-two">
								Select Notification Type
							</label>
							<select
								id="type"
								name="type"
								{...register("type", {
									required: "Select a type",
								})}
								className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
							>
								<option value="instant">Instant</option>
								<option value="scheduled">Scheduled</option>
							</select>
							<FormError errors={errors} name="type" />
						</div>

						{notificationType === "scheduled" && (
							<>
								<div className="">
									<label htmlFor="date" className="block glade-small-text-two">
										Date
									</label>
									<input
										type="date"
										id="date"
										name="date"
										{...register("date", {
											required: "Select date",
											validate: (value) => {
												if (value === "") {
													return "Select a valid date";
												} else if (
													dfn.isBefore(
														new Date(fixDateForAllBrowsers(value)),
														dfn.subDays(new Date(), 1)
													)
												) {
													return "Date should be today or later";
												}
											},
										})}
										className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
									/>
									<FormError errors={errors} name="date" />
								</div>
								<div className="mt-4">
									<label htmlFor="time" className="block glade-small-text-two">
										Time
									</label>
									<input
										type="time"
										id="time"
										name="time"
										{...register("time", {
											required: "Select an end date",
										})}
										className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
									/>
									<FormError errors={errors} name="time" />
								</div>
							</>
						)}
						<div className="flex items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
							<button
								type="button"
								onClick={() => setShowSendModal(false)}
								className="glade-normal-text-two glade-black text-center"
							>
								Cancel
							</button>
							<div>
								<Button.Dark
									type="submit"
									title="Send Notification"
									className="block flex-grow-0"
									loading={isSendingMultipleNotifications}
								/>
							</div>
						</div>
					</form>
				}
			/>

			{/* Delete modal */}
			<BasicModal
				visibility={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				onOpen={() => setShowDeleteModal(true)}
				primaryButton=""
				modalTitleSection=""
				secondaryButton=""
				modelContentSection={
					<div className="p-6 px-0">
						<h3 className="glade-black text-center mb-8">
							Are you sure you want to delete this notification?
						</h3>
						<Button.Danger
							onClick={deleteNotification}
							loading={isDeletingDraftNotification}
							title="Delete"
							// className={`glade-normal-text-three glade-bg-blue ${
							//   isDeletingDraftNotification
							//     ? "hover:bg-blue-300 cursor-not-allowed"
							//     : "hover:bg-blue-500"
							// } text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
						>
							{isDeletingDraftNotification ? "Sending" : "Send again"}
							{/* {isDeletingDraftNotification && (
                <RiLoader5Fill size={24} className="animate-spin ml-4" />
              )} */}
						</Button.Danger>
						<button
							onClick={() => setShowDeleteModal(false)}
							className="glade-normal-text-three glade-grey-dark w-full mx-auto text-center mt-6"
						>
							Cancel
						</button>
					</div>
				}
			/>
		</div>
	);
}

import styled from "styled-components";
import React, { useContext } from "react";
import MultiSelectItem from "./MultiSelectItem";
import { MultiSelectContext } from "../../context/MultiSelectContext";

const SMultiSelectItems = styled.div`
  display: flex; 
  flex-flow: row wrap;
  gap: .5rem;
  padding-bottom: .5rem;
`;

const MultiSelectItems = () => {
  const { selectOptions, setSelectOptions } = useContext(MultiSelectContext);
  function removeRecipient(uuid) {
    const newRecipients = selectOptions.filter(option => option.user_uuid !== uuid);
    setSelectOptions(newRecipients);
  }
  return (
    <SMultiSelectItems>
      {selectOptions.map(option =>
        <MultiSelectItem
          removeRecipient={removeRecipient}
          option={option}
        />)
      }
    </SMultiSelectItems>
  );
};

export default MultiSelectItems;
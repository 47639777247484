import { GET_ALL_PROVIDERS_FAILURE, GET_ALL_PROVIDERS_SUCCESS, GET_ALL_PROVIDERS_START, UPDATE_PROVIDER_STATUS_SUCCESS, UPDATE_PROVIDER_STATUS_START, SEARCH_PROVIDER_STATUS_START, SEARCH_PROVIDER_STATUS_SUCCESS, SEARCH_PROVIDER_STATUS_FAILURE, GET_PROVIDERS_CATEGORY_START, GET_PROVIDERS_CATEGORY_SUCCESS, GET_PROVIDERS_CATEGORY_FAILURE, SEARCH_PROVIDERS_CATEGORY_START, SEARCH_PROVIDERS_CATEGORY_SUCCESS, SEARCH_PROVIDERS_CATEGORY_FAILURE } from "./types"

const initialState = {
    isLoadingAllProvidersList: false,
    isLoadingProviderCategory: false,
    allProviders: [],
    allCategory: []
}

export default function providersReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PROVIDERS_CATEGORY_START:
            return {
                ...state,
                isLoadingProviderCategory: true,
            };
        case GET_PROVIDERS_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoadingProviderCategory: false,
                allCategory: action.payload
            };
        case GET_PROVIDERS_CATEGORY_FAILURE:
            return {
                ...state,
                isLoadingProviderCategory: false,
            };
        case SEARCH_PROVIDERS_CATEGORY_START:
            return {
                ...state,
                isLoadingProviderCategory: true
            }
        case SEARCH_PROVIDERS_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoadingProviderCategory: false,
                allCategory: action.payload
            }
        case SEARCH_PROVIDERS_CATEGORY_FAILURE:
            return {
                ...state,
                isLoadingProviderCategory: false,
            }
        case GET_ALL_PROVIDERS_START:
            return {
                ...state,
                isLoadingAllProvidersList: true,
            };

        case GET_ALL_PROVIDERS_SUCCESS:
            return {
                ...state,
                isLoadingAllProvidersList: false,
                allProviders: action.payLoad,
            };
        case GET_ALL_PROVIDERS_FAILURE:
            return {
                ...state,
                isLoadingAllProvidersList: false,
            }
        case UPDATE_PROVIDER_STATUS_START:
            return {
                ...state,
                isLoadingAllProvidersList: true,
            }
        case UPDATE_PROVIDER_STATUS_SUCCESS:
            return {
                ...state,
                isLoadingAllProvidersList: false,
                allProviders: action.payLoad
            }
        case SEARCH_PROVIDER_STATUS_START:
            return {
                ...state,
                isLoadingAllProvidersList: true,
            }
        case SEARCH_PROVIDER_STATUS_SUCCESS:
            if (action?.category) {
                let category = {};
                category[`${action.category}`] = action.payLoad;
                state[`allProviders`] = { ...category }
                return {
                    ...state,
                    isLoadingAllProvidersList: false,
                };
            } else {
                return {
                    ...state,
                    allProviders: action.payLoad,
                    isLoadingAllProvidersList: false,
                };
            }

        case SEARCH_PROVIDER_STATUS_FAILURE:
            return {
                ...state,
                isLoadingAllProvidersList: false,
            };
        default:
            return state;
    }
}
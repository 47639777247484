import { toast } from "react-toastify";

/**
 * Check environment before console logging data
 * @param  {...any} data
 * @param {string} environment
 * @returns {boolean}
 */
const handlerDump = (...data) => {
  if (process.env.REACT_APP_APP_ENV === "development") {
    console.log(...data);
    return true;
  }
  return false;
};

/**
 * Determine response of handler
 * @param {string} alternateMessage
 * @param {object} data
 * @returns {string}
 */
const handlerResponse = (alternateMessage, data = {}) => {
  if (data.data && data.data.message) {
    return data.data.message;
  }
  return alternateMessage;
};

/**
 * Parse error response from server
 * @param {*} errorObject
 * @param {boolean} shouldDispatchAlert
 * @returns {string}
 */
const handler = (errorObject, shouldDispatchAlert = false) => {
  try {
    if (errorObject.response && errorObject.response.data) {
      let errors = errorObject?.response?.data?.error;
      let message = errorObject?.response?.data?.message;
      let developerMessage = errorObject?.response?.data?.developer_message?.error;

      if (errors && errors.length > 0) {
        // Request made and server responded with an error
        handlerDump(
          "Error Response",
          errorObject?.response?.headers,
          errorObject?.response?.data,
          errorObject?.response?.status,
        );

        if (typeof errors === "string") {
          return (
            shouldDispatchAlert &&
            toast.warning(errors, { position: toast.POSITION.TOP_RIGHT })
          );
        } else {
          // Map through errors
          Object.values(errors).map((item, key) => {
            // Send found errors to alert
            return (
              shouldDispatchAlert &&
              toast.warning(item.toString(), { position: toast.POSITION.TOP_RIGHT })
            );
          });
        }
      } else {
        // Request made and server responded with an error
        handlerDump("Error Response", errorObject.response);

        // Send generic error message to alert
        shouldDispatchAlert &&
          toast.warning(developerMessage ?? message, {
            position: toast.POSITION.TOP_RIGHT,
          });
      }

      // Return a response
      return handlerResponse("Something went wrong!", errorObject.response);
    } else if (errorObject.request) {
      // The request was made but no response was received
      handlerDump("Unknown Response", errorObject.request);

      // No network connection
      if (errorObject.message === "Network Error") {
        // Send found errors to alert
        shouldDispatchAlert &&
          toast.error("Network is Unavailable", {
            position: toast.POSITION.TOP_RIGHT,
          });
      } else {
        // Send generic error message to alert
        shouldDispatchAlert &&
          toast.error("Server is Unavailable", {
            position: toast.POSITION.TOP_RIGHT,
          });
      }

      // Return a response
      return handlerResponse("Something went wrong!", errorObject);
    } else {
      // Something happened in setting up the request that triggered an Error
      handlerDump("Unknown Error", errorObject);

      // Send generic info message to alert
      shouldDispatchAlert &&
        toast.error("Request time out, please try again", {
          position: toast.POSITION.TOP_RIGHT,
        });

      // Return a response
      return handlerResponse("Something went wrong!", errorObject);
    }
  } catch (error) {
    handlerDump(
      "Error Handler System Failure - Error Experienced In Processing Error Object",
      error,
    );
    handlerDump(
      "Error Handler System Failure - Error Object Passed In For Processing",
      errorObject,
    );
  }
};

export default handler;

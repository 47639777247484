import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import { TextSkeleton } from "../../components/skeletons";
import { fixDateForAllBrowsers } from "../../helpers/helper";
import useWallets from "../../hooks/useWallets";
import * as dfn from "date-fns";
import { getAccountRequestDetails, getCountries } from "../../redux/wallets/walletsActions";
import CountryFlag from "./CountryFlag";

export default function AccountRequestDetails() {
  const dispatch = useDispatch();

  const { isLoadingAccountDetails, accountDetailsData } = useWallets();

  const [countries, setCountries] = useState([]);


  const location = useLocation();
  const id = location?.search?.split('=')[1];

  useEffect(() => {
    dispatch(getAccountRequestDetails(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    countries?.length < 1 && dispatch(getCountries((res) => setCountries(res)));
  }, [])


  const findCountryCode = (country) => {
    return countries.find(x => x.country_name?.toLowerCase() === country.toLowerCase())?.country_code;
  }

  const findCountryName = (country) => {
    return countries.find(x => x.country_code?.toLowerCase() === country.toLowerCase())?.country_name;
  }

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs
            title={"Account Requests"}
            path={"/dashboard/accountrequests/accounts"}
          />
        </div>

        <div>
          <div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
            <div className="py-3 border-b-2 border-solid border-gray-100">
              <p className="glade-black">Account Details</p>
            </div>
            <div className="grid grid-cols-4 gap-4 py-3">
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  First Name
                </h5>
                {!isLoadingAccountDetails ? (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {accountDetailsData?.first_name}
                  </h6>
                ) : (
                  <TextSkeleton />
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Last Name
                </h5>
                {!isLoadingAccountDetails ? (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {accountDetailsData?.last_name}
                  </h6>
                ) : (
                  <TextSkeleton />
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Business Email
                </h5>
                {!isLoadingAccountDetails ? (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {accountDetailsData?.business_email}
                  </h6>
                ) : (
                  <TextSkeleton />
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Business Name
                </h5>
                {!isLoadingAccountDetails ? (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {accountDetailsData?.business_name}
                  </h6>
                ) : (
                  <TextSkeleton />
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Country of Corporation
                </h5>
                {!isLoadingAccountDetails ? (
                  <h6 className="glade-normal-text-three glade-black py-1 capitalize flex gap-3 items-center">
                    {<CountryFlag flagCode={accountDetailsData?.country?.length === 2 ?accountDetailsData?.country : findCountryCode(accountDetailsData?.country) } />}  {accountDetailsData?.country?.length === 2 ? findCountryName(accountDetailsData?.country) : accountDetailsData?.country }
                  </h6>
                ) : (
                  <TextSkeleton />
                )}
              </div>
              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Business Description
                </h5>
                {!isLoadingAccountDetails ? (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {accountDetailsData?.description ?? 'N/A'}
                  </h6>
                ) : (
                  <TextSkeleton />
                )}
              </div>

              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Volume Processed Monthly ($)
                </h5>
                {!isLoadingAccountDetails ? (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {accountDetailsData?.volume_processed ?? "N/A"}
                  </h6>
                ) : (
                  <TextSkeleton />
                )}
              </div>

              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Business Website
                </h5>
                {!isLoadingAccountDetails ? (
                  <h6 className="glade-normal-text-three glade-black py-1">
                    {accountDetailsData?.business_website ?? "Not available"}
                  </h6>
                ) : (
                  <TextSkeleton />
                )}
              </div>
              {accountDetailsData?.business_industry &&
                <div className="flex flex-col">
                  <h5 className="glade-normal-text-two glade-ash py-1">
                    Business Industry
                  </h5>
                  {!isLoadingAccountDetails ? (
                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                      {accountDetailsData?.business_industry}
                    </h6>
                  ) : (
                    <TextSkeleton />
                  )}
                </div>}


              <div className="flex flex-col">
                <h5 className="glade-normal-text-two glade-ash py-1">
                  Date Opened
                </h5>
                {!isLoadingAccountDetails && accountDetailsData?.date_requested ? (
                  <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                    {dfn.format(
                      new Date(fixDateForAllBrowsers(accountDetailsData?.date_requested)),
                      "MMM d, yyyy"
                    )}
                  </h6>
                ) : (
                  <TextSkeleton />
                )}
              </div>
            </div>
          </div>
        </div>
        {(
          <div className="flex flex-col bg-white rounded-sm px-8 py-4 mb-6">
            <div className="py-3 border-b-2 border-solid border-gray-100">
              <p className="glade-black">Request Details</p>
            </div>
            {isLoadingAccountDetails ? (
              <TextSkeleton width="300px" className="mt-4" />
            ) : (
              <>
                <div>
                  <h5 className="glade-normal-text-two glade-ash mt-6">
                    What will you need a Business Account for
                  </h5>
                  <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                    {accountDetailsData?.reason_for_account}
                  </h6>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div >
  );
}

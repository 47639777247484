import React from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import NumberFormat from "react-number-format";

const AddConditionModal = ({
	setShowAddConditionModal,
	showAddConditionModal,
	addCondition,
}) => {
	const { specificPersonalAccount } = useAccounts();

	const {
		handleSubmit,
		register,
		reset,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			limit: "",
		},
	});

	const onSubmit = (data) => {
		addCondition(data);
		setShowAddConditionModal(false);
		reset();
	};

	return (
		<BasicModal
			visibility={showAddConditionModal}
			onClose={() => {
				reset();
				setShowAddConditionModal(false);
			}}
			onOpen={() => setShowAddConditionModal(true)}
			primaryButton=""
			modalTitle={"Add Custom Fee"}
			secondaryButton=""
			overlayStyle={{
				opacity: 0.6,
			}}
			modelContentSection={
				<form onSubmit={handleSubmit(onSubmit)} className="">
					{/* Limit */}
					<div className="mb-6">
						<label
							htmlFor="limit"
							className="block glade-small-text-two capitalize"
						>
							Limit Amount
						</label>
						<div className="relative">
							<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
								<span className="glade-black sm:text-sm py-2">
									{specificPersonalAccount?.bank_detail?.currency ?? "NGN"}
								</span>
							</div>
							<Controller
								render={({ field: { onChange, value, ref } }) => (
									<NumberFormat
										thousandSeparator={true}
										onValueChange={(v) => {
											onChange(Number(v.value));
										}}
										variant="outlined"
										defaultValue=""
										value={value}
										className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
									/>
								)}
								rules={{
									required: "Enter an amount to fund",
									min: {
										value: 1,
										message: "Minimum amount is NGN 1",
									},
								}}
								name="limit"
								control={control}
							/>
						</div>
						<FormError errors={errors} name="fee_fixed" />
					</div>

					<div className="">
						<label
							htmlFor="fee_percentage"
							className="block glade-small-text-two capitalize"
						>
							Fees
						</label>
						<div className="flex items-center mt-2">
							{/* Percent */}
							<div className="w-1/2 pr-2">
								<div className="relative">
									<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
										<span className="glade-black sm:text-sm py-2">%</span>
									</div>
									<input
										type="number"
										id="fee_percentage"
										name="fee_percentage"
										{...register("fee_percentage", {
											required: "Enter a valid percent",
											min: {
												value: 0.0,
												message: "Enter a valid percent",
											},
											max: {
												value: 100,
												message: "Enter a valid percent",
											},
										})}
										step="0.1"
										placeholder="Enter a percentage"
										className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
									/>
								</div>
							</div>
							{/* Fixed amount */}
							<div className="w-1/2 pl-2">
								<div className="relative">
									<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
										<span className="glade-black sm:text-sm py-2">NGN</span>
									</div>
									<input
										type="number"
										id="fee_fixed"
										name="fee_fixed"
										{...register("fee_fixed", {
											required: "Enter a valid amount",
											min: {
												value: 0,
												message: "Enter a valid amount",
											},
										})}
										placeholder="Enter a amount"
										className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
									/>
								</div>
							</div>
						</div>
						<div className="flex items-center">
							<div className="w-1/2 pr-2">
								<FormError errors={errors} name="fee_percentage" />
							</div>
							<div className="w-1/2 pl-2">
								<FormError errors={errors} name="fee_fixed" />
							</div>
						</div>
					</div>

					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={() => {
								reset();
								setShowAddConditionModal(false);
							}}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Add Fee"
								className="block flex-grow-0"
							/>
						</div>
					</div>
				</form>
			}
		/>
	);
};

export default AddConditionModal;

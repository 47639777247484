import React from 'react'
import SingleAccordion from './SingleAccordion'

const MultiAccordion = ({ contents }) => {
  return (
    contents.map(content => (
      <SingleAccordion
        contents={content}
      />
    ))
  )
}

export default MultiAccordion
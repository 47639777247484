import { useSelector } from 'react-redux';

const useCrossBorder = () => {
  const { crossBorder } = useSelector(state => state);
  const {
    isLoadingSupportedCurrencies,
    supportedCurrencies,
    isUpdatingTransferCharge,
    isLoadingSupportedCurrenciesRates,
    isLoadingLiveRate,
    isUpdatingCurrencyRate,
    isRateUpdated,
    supportedCurrenciesRates,
    supportedCurrenciesTransfer,
    liveRate,
    isLoadingCountries,
    isLoadingCurrencyStatusUpdate,
    countriesByContinent
  } = crossBorder;
  return {
    isUpdatingTransferCharge,
    isLoadingSupportedCurrencies,
    supportedCurrencies,
    isLoadingSupportedCurrenciesRates,
    isLoadingLiveRate,
    isUpdatingCurrencyRate,
    isRateUpdated,
    supportedCurrenciesRates,
    supportedCurrenciesTransfer,
    liveRate,
    isLoadingCountries,
    countriesByContinent,
    isLoadingCurrencyStatusUpdate
  };
};

export default useCrossBorder;
import Axios from '../../connection/defaultClient';
import handler from '../../handlers/errorHandler';
import { toastSuccess } from '../../helpers/utils.toast';
import {
  GET_MULTI_CURRENCY_CHARGES_FAILURE,
  GET_MULTI_CURRENCY_CHARGES_START,
  GET_MULTI_CURRENCY_CHARGES_SUCCESS,
  UPDATE_MULTI_CURRENCY_CHARGE_FAILURE,
  UPDATE_MULTI_CURRENCY_CHARGE_START,
  UPDATE_MULTI_CURRENCY_CHARGE_SUCCESS
} from './types';

export const getMultiCurrencyCharges = (params, callback = () => { }) => {
  return dispatch => {
    dispatch({ type: GET_MULTI_CURRENCY_CHARGES_START });
    Axios.get(`cross-border/multicurrency-charges`, params)
      .then(res => {
        dispatch({
          type: GET_MULTI_CURRENCY_CHARGES_SUCCESS,
          payLoad: res.data.data
        });
        callback(res.data.data);
      })
      .catch(error => {
        dispatch({ type: GET_MULTI_CURRENCY_CHARGES_FAILURE });
        handler(error);
      });
  };
};

export const updateMultiCurrencyCharge = (data, callback = () => { }) => {
  return dispatch => {
    dispatch({ type: UPDATE_MULTI_CURRENCY_CHARGE_START });
    Axios.put(`cross-border/multicurrency-charge`, data)
      .then(res => {
        dispatch({
          type: UPDATE_MULTI_CURRENCY_CHARGE_SUCCESS,
          payLoad: res.data.data
        });
        toastSuccess('Updated charge');
        callback();
      })
      .catch(error => {
        dispatch({ type: UPDATE_MULTI_CURRENCY_CHARGE_FAILURE });
        handler(error);
      });
  };
};
import {
  APPROVE_SPECIFIC_LIMIT_REQUEST_FAILURE,
  APPROVE_SPECIFIC_LIMIT_REQUEST_START,
  APPROVE_SPECIFIC_LIMIT_REQUEST_SUCCESS,
  BLACKLIST_BUSINESS_ACCOUNT_FAILURE,
  BLACKLIST_BUSINESS_ACCOUNT_START,
  BLACKLIST_BUSINESS_ACCOUNT_SUCCESS,
  BLACKLIST_PERSONAL_ACCOUNT_FAILURE,
  BLACKLIST_PERSONAL_ACCOUNT_START,
  BLACKLIST_PERSONAL_ACCOUNT_SUCCESS,
  FUND_BUSINESS_ACCOUNT_FAILURE,
  FUND_BUSINESS_ACCOUNT_START,
  FUND_BUSINESS_ACCOUNT_SUCCESS,
  FUND_PERSONAL_ACCOUNT_FAILURE,
  FUND_PERSONAL_ACCOUNT_START,
  FUND_PERSONAL_ACCOUNT_SUCCESS,
  GET_ALL_AGENTS_ACCOUNTS_FAILURE,
  GET_ALL_AGENTS_ACCOUNTS_START,
  GET_ALL_AGENTS_ACCOUNTS_SUCCESS,
  GET_ALL_BLACKLISTED_ACCOUNTS_FAILURE,
  GET_ALL_BLACKLISTED_ACCOUNTS_START,
  GET_ALL_BLACKLISTED_ACCOUNTS_SUCCESS,
  GET_ALL_BUSINESS_ACCOUNTS_FAILURE,
  GET_ALL_BUSINESS_ACCOUNTS_START,
  GET_ALL_BUSINESS_ACCOUNTS_SUCCESS,
  GET_ALL_LIMIT_REQUESTS_FAILURE,
  GET_ALL_LIMIT_REQUESTS_START,
  GET_ALL_LIMIT_REQUESTS_SUCCESS,
  GET_ALL_PERSONAL_ACCOUNTS_FAILURE,
  GET_ALL_PERSONAL_ACCOUNTS_START,
  GET_ALL_PERSONAL_ACCOUNTS_SUCCESS,
  GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE,
  GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START,
  GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS,
  GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_FAILURE,
  GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_START,
  GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_SUCCESS,
  GET_BUSINESS_ACCOUNT_MERCHANT_FEES_FAILURE,
  GET_BUSINESS_ACCOUNT_MERCHANT_FEES_START,
  GET_BUSINESS_ACCOUNT_MERCHANT_FEES_SUCCESS,
  GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_FAILURE,
  GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_START,
  GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_SUCCESS,
  GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE,
  GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START,
  GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
  GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE,
  GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START,
  GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS,
  GET_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE,
  GET_PERSONAL_ACCOUNT_COMPLIANCE_START,
  GET_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS,
  GET_PERSONAL_ACCOUNT_MERCHANT_FEES_FAILURE,
  GET_PERSONAL_ACCOUNT_MERCHANT_FEES_START,
  GET_PERSONAL_ACCOUNT_MERCHANT_FEES_SUCCESS,
  GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE,
  GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START,
  GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
  GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_FAILURE,
  GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_START,
  GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_SUCCESS,
  GET_SPECIFIC_BUSINESS_ACCOUNT_FAILURE,
  GET_SPECIFIC_BUSINESS_ACCOUNT_START,
  GET_SPECIFIC_BUSINESS_ACCOUNT_SUCCESS,
  GET_SPECIFIC_LIMIT_REQUEST_FAILURE,
  GET_SPECIFIC_LIMIT_REQUEST_START,
  GET_SPECIFIC_LIMIT_REQUEST_SUCCESS,
  GET_SPECIFIC_PERSONAL_ACCOUNT_FAILURE,
  GET_SPECIFIC_PERSONAL_ACCOUNT_START,
  GET_SPECIFIC_PERSONAL_ACCOUNT_SUCCESS,
  REJECT_SPECIFIC_LIMIT_REQUEST_FAILURE,
  REJECT_SPECIFIC_LIMIT_REQUEST_START,
  REJECT_SPECIFIC_LIMIT_REQUEST_SUCCESS,
  REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE,
  REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_START,
  REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS,
  REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE,
  REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_START,
  REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS,
  REVERT_ACCOUNT_MERCHANT_FEE_START,
  REVERT_ACCOUNT_MERCHANT_FEE_SUCCESS,
  REVERT_ACCOUNT_MERCHANT_FEE_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_ADDRESS_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_ADDRESS_START,
  UPDATE_BUSINESS_ACCOUNT_ADDRESS_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_BVN_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_BVN_START,
  UPDATE_BUSINESS_ACCOUNT_BVN_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_START,
  UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_DETAILS_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_DETAILS_START,
  UPDATE_BUSINESS_ACCOUNT_DETAILS_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_EMAIL_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_EMAIL_START,
  UPDATE_BUSINESS_ACCOUNT_EMAIL_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START,
  UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_START,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_START,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS,
  UPDATE_ACCOUNT_MERCHANT_FEE_FAILURE,
  UPDATE_ACCOUNT_MERCHANT_FEE_START,
  UPDATE_ACCOUNT_MERCHANT_FEE_SUCCESS,
  GET_SYSTEM_LIMITS_FAILURE,
  GET_SYSTEM_LIMITS_START,
  GET_SYSTEM_LIMITS_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_START,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START,
  UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_NAME_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_NAME_START,
  UPDATE_BUSINESS_ACCOUNT_NAME_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_PHONE_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_PHONE_START,
  UPDATE_BUSINESS_ACCOUNT_PHONE_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_START,
  UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_SUCCESS,
  UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE,
  UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START,
  UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS,
  UPDATE_PERSONAL_ACCOUNT_ADDRESS_FAILURE,
  UPDATE_PERSONAL_ACCOUNT_ADDRESS_START,
  UPDATE_PERSONAL_ACCOUNT_ADDRESS_SUCCESS,
  UPDATE_PERSONAL_ACCOUNT_BVN_FAILURE,
  UPDATE_PERSONAL_ACCOUNT_BVN_START,
  UPDATE_PERSONAL_ACCOUNT_BVN_SUCCESS,
  UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE,
  UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_START,
  UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_FAILURE,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_START,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_SUCCESS,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_START,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_START,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START,
  UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS,
  UPDATE_TRANSFER_LIMIT_FAILURE,
  UPDATE_TRANSFER_LIMIT_START,
  UPDATE_TRANSFER_LIMIT_SUCCESS,
  GET_MULTI_CURRENCIES_START,
  GET_MULTI_CURRENCIES_SUCCESS,
  GET_MULTI_CURRENCIES_FAILURE,
} from "./types";

const initialState = {
  isLoadingMultiCurrencies: false,
  isLoadingAllPersonalAccounts: true,
  isLoadingAllBusinessAccounts: true,
  isLoadingAllAgentAccounts: true,
  isLoadingAllLimitRequests: true,
  isLoadingAllBlacklistedAccounts: true,
  isLoadingSpecificPersonalAccount: true,
  isLoadingSpecificBusinessAccounts: true,
  isLoadingSpecificLimitRequest: true,
  isLoadingPersonalMerchantServices: true,
  isLoadingBusinessMerchantServices: true,
  isLoadingPersonalMerchantCompliance: true,
  isLoadingBusinessMerchantCompliance: true,
  isLoadingPersonalMerchantFees: true,
  isLoadingBusinessMerchantFees: true,
  isLoadingPersonalSecuritySettings: true,
  isLoadingBusinessSecuritySettings: true,
  isLoadingBusinessAccountInstantSettlement: true,
  isLoadingBusinessAccountSettlementType: true,
  isBlacklistingPersonalAccount: false,
  isBlacklistingBusinessAccount: false,
  isUpdatingPersonalAccountMerchantFees: false,
  isUpdatingBusinessAccountMerchantFees: false,
  isUpdatingAccountMerchantFees: false,
  isRevertingPersonalAccountMerchantFees: false,
  isRevertingAccountMerchantFees: false,
  isRevertingBusinessAccountMerchantFees: false,
  isUpdatingPersonalAccountMerchantServices: false,
  isUpdatingBusinessAccountMerchantServices: false,
  isUpdatingPersonalAccountBVN: false,
  isUpdatingBusinessAccountBVN: false,
  isUpdatingPersonalAccountAddress: false,
  isUpdatingBusinessAccountAddress: false,
  isUpdatingBusinessAccountName: false,
  isUpdatingBusinessAccountEmail: false,
  isUpdatingBusinessAccountPhone: false,
  isUpdatingPersonalAccount2FA: false,
  isUpdatingBusinessAccount2FA: false,
  isUpdatingPersonalAccountIP: false,
  isUpdatingBusinessAccountIP: false,
  isUpdatingBusinessAccountInstantSettlement: false,
  isUpdatingBusinessSettlementAccount: false,
  isUpdatingBusinessSettlementType: false,
  isUpdatingPersonalCompliance: false,
  isUpdatingBusinessCompliance: false,
  isUpdatingBusinessDescription: false,
  isUpdatingTransferLimit: false,
  isFundingPersonalAccount: false,
  isFundingBusinessAccount: false,
  isApprovingLimitRequest: false,
  isRejectingLimitRequest: false,
  allPersonalAccounts: [],
  allAgentAccounts: [],
  allBlacklistedAccounts: [],
  allPersonalMerchantServices: [],
  allBusinessMerchantServices: [],
  allPersonalMerchantFees: [],
  allBusinessMerchantFees: [],
  allBusinessAccounts: [],
  allLimitRequests: [],
  systemLimits: [],

  multiCurrencies: {},
  specificPersonalAccount: {},
  specificBusinessAccounts: {},
  specificLimitRequest: {},
  specificPersonalAccountCompliance: {},
  specificBusinessAccountCompliance: {},
  specificPersonalAccountSecuritySettings: {},
  specificBusinessAccountSecuritySettings: {},
  specificBusinessSettlementAccount: {},
  specificBusinessSettlementType: {},
  isPosFeeUpdated: false,
  isOtherFeesUpdated: false,
};

export default function accountsReducer(state = initialState, action) {
  switch (action.type) {
    // GET ALL PERSONAL ACCOUNTS
    case GET_ALL_PERSONAL_ACCOUNTS_START:
      return {
        ...state,
        isLoadingAllPersonalAccounts: true,
      };

    case GET_ALL_PERSONAL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isLoadingAllPersonalAccounts: false,
        allPersonalAccounts: action.payLoad,
      };

    case GET_ALL_PERSONAL_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoadingAllPersonalAccounts: false,
      };

    // GET SPECIFIC PERSONAL ACCOUNT
    case GET_SPECIFIC_PERSONAL_ACCOUNT_START:
      return {
        ...state,
        isLoadingSpecificPersonalAccount: true,
      };

    case GET_SPECIFIC_PERSONAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoadingSpecificPersonalAccount: false,
        specificPersonalAccount: action.payLoad,
      };

    case GET_SPECIFIC_PERSONAL_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoadingSpecificPersonalAccount: false,
        specificPersonalAccount: {},
      };

    // GET PERSONAL ACCOUNT SERVICES
    case GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START:
      return {
        ...state,
        isLoadingPersonalMerchantServices: true,
      };

    case GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS:
      return {
        ...state,
        isLoadingPersonalMerchantServices: false,
        allPersonalMerchantServices: action.payLoad,
      };

    case GET_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE:
      return {
        ...state,
        isLoadingPersonalMerchantServices: false,
        allPersonalMerchantServices: [],
      };

    // GET PERSONAL ACCOUNT SERVICES
    case GET_PERSONAL_ACCOUNT_MERCHANT_FEES_START:
      return {
        ...state,
        isLoadingPersonalMerchantFees: true,
      };

    case GET_PERSONAL_ACCOUNT_MERCHANT_FEES_SUCCESS:
      return {
        ...state,
        isLoadingPersonalMerchantFees: false,
        allPersonalMerchantFees: action.payLoad,
      };

    case GET_PERSONAL_ACCOUNT_MERCHANT_FEES_FAILURE:
      return {
        ...state,
        isLoadingPersonalMerchantFees: false,
        allPersonalMerchantFees: [],
      };

    // GET PERSONAL ACCOUNT SECURITY SETTINGS
    case GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_START:
      return {
        ...state,
        isLoadingPersonalSecuritySettings: true,
      };

    case GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoadingPersonalSecuritySettings: false,
        specificPersonalAccountSecuritySettings: action.payLoad,
      };

    case GET_PERSONAL_ACCOUNT_SECURITY_SETTINGS_FAILURE:
      return {
        ...state,
        isLoadingPersonalSecuritySettings: false,
        specificPersonalAccountSecuritySettings: {},
      };

    // GET PERSONAL ACCOUNT COMPLIANCE
    case GET_PERSONAL_ACCOUNT_COMPLIANCE_START:
      return {
        ...state,
        isLoadingPersonalMerchantCompliance: true,
      };

    case GET_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isLoadingPersonalMerchantCompliance: false,
        specificPersonalAccountCompliance: action.payLoad,
      };

    case GET_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE:
      return {
        ...state,
        isLoadingPersonalMerchantCompliance: false,
        specificPersonalAccountCompliance: {},
      };

    // BLACKLIST PERSONAL ACCOUNT
    case BLACKLIST_PERSONAL_ACCOUNT_START:
      return {
        ...state,
        isBlacklistingPersonalAccount: true,
      };

    case BLACKLIST_PERSONAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        isBlacklistingPersonalAccount: false,
      };

    case BLACKLIST_PERSONAL_ACCOUNT_FAILURE:
      return {
        ...state,
        isBlacklistingPersonalAccount: false,
      };

    // REVERT PERSONAL ACCOUNT MERCHANT FEES
    case REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_START:
      return {
        ...state,
        isRevertingPersonalAccountMerchantFees: true,
      };

    case REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS:
      return {
        ...state,
        isRevertingPersonalAccountMerchantFees: false,
      };

    case REVERT_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE:
      return {
        ...state,
        isRevertingPersonalAccountMerchantFees: false,
      };

    // REVERT ACCOUNT MERCHANT FEES
    case REVERT_ACCOUNT_MERCHANT_FEE_START:
      return {
        ...state,
        isRevertingAccountMerchantFees: true,
      };

    case REVERT_ACCOUNT_MERCHANT_FEE_SUCCESS:
      return {
        ...state,
        isRevertingAccountMerchantFees: false,
      };

    case REVERT_ACCOUNT_MERCHANT_FEE_FAILURE:
      return {
        ...state,
        isRevertingAccountMerchantFees: false,
      };

    // UPDATE PERSONAL ACCOUNT MERCHANT FEES
    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_START:
      return {
        ...state,
        isUpdatingPersonalAccountMerchantFees: true,
      };

    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_SUCCESS:
      return {
        ...state,
        isUpdatingPersonalAccountMerchantFees: false,
      };

    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_FEE_FAILURE:
      return {
        ...state,
        isUpdatingPersonalAccountMerchantFees: false,
      };

    // UPDATE PERSONAL ACCOUNT MERCHANT SERVICES
    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_START:
      return {
        ...state,
        isUpdatingPersonalAccountMerchantServices: true,
      };

    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_SUCCESS:
      return {
        ...state,
        isUpdatingPersonalAccountMerchantServices: false,
      };

    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_SERVICES_FAILURE:
      return {
        ...state,
        isUpdatingPersonalAccountMerchantServices: false,
      };

    // UPDATE PERSONAL ACCOUNT COMPLIANCE
    case UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_START:
      return {
        ...state,
        isUpdatingPersonalCompliance: true,
      };

    case UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isUpdatingPersonalCompliance: false,
      };

    case UPDATE_PERSONAL_ACCOUNT_COMPLIANCE_FAILURE:
      return {
        ...state,
        isUpdatingPersonalCompliance: false,
      };

    // UPDATE PERSONAL ACCOUNT BVN
    case UPDATE_PERSONAL_ACCOUNT_BVN_START:
      return {
        ...state,
        isUpdatingPersonalAccountBVN: true,
      };

    case UPDATE_PERSONAL_ACCOUNT_BVN_SUCCESS:
      return {
        ...state,
        isUpdatingPersonalAccountBVN: false,
      };

    case UPDATE_PERSONAL_ACCOUNT_BVN_FAILURE:
      return {
        ...state,
        isUpdatingPersonalAccountBVN: false,
      };

    // UPDATE PERSONAL ACCOUNT ADDRESS
    case UPDATE_PERSONAL_ACCOUNT_ADDRESS_START:
      return {
        ...state,
        isUpdatingPersonalAccountAddress: true,
      };

    case UPDATE_PERSONAL_ACCOUNT_ADDRESS_SUCCESS:
      return {
        ...state,
        isUpdatingPersonalAccountAddress: false,
      };

    case UPDATE_PERSONAL_ACCOUNT_ADDRESS_FAILURE:
      return {
        ...state,
        isUpdatingPersonalAccountAddress: false,
      };

    // UPDATE PERSONAL ACCOUNT 2FA
    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_START:
      return {
        ...state,
        isUpdatingPersonalAccount2FA: true,
      };

    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_SUCCESS:
      return {
        ...state,
        isUpdatingPersonalAccount2FA: false,
      };

    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_2FA_FAILURE:
      return {
        ...state,
        isUpdatingPersonalAccount2FA: false,
      };

    // UPDATE PERSONAL ACCOUNT IP PROTECTION
    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_START:
      return {
        ...state,
        isUpdatingPersonalAccountIP: true,
      };

    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS:
      return {
        ...state,
        isUpdatingPersonalAccountIP: false,
      };

    case UPDATE_PERSONAL_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE:
      return {
        ...state,
        isUpdatingPersonalAccountIP: false,
      };

    // FUND PERSONAL ACCOUNT
    case FUND_PERSONAL_ACCOUNT_START:
      return {
        ...state,
        isFundingPersonalAccount: true,
      };

    case FUND_PERSONAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        isFundingPersonalAccount: false,
      };

    case FUND_PERSONAL_ACCOUNT_FAILURE:
      return {
        ...state,
        isFundingPersonalAccount: false,
      };

    // GET ALL AGENTS ACCOUNTS
    case GET_ALL_AGENTS_ACCOUNTS_START:
      return {
        ...state,
        isLoadingAllAgentAccounts: true,
      };

    case GET_ALL_AGENTS_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isLoadingAllAgentAccounts: false,
        allAgentAccounts: action.payLoad,
      };

    case GET_ALL_AGENTS_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoadingAllAgentAccounts: false,
      };

    // GET ALL BLACKLISTED ACCOUNTS
    case GET_ALL_BLACKLISTED_ACCOUNTS_START:
      return {
        ...state,
        isLoadingAllBlacklistedAccounts: true,
      };

    case GET_ALL_BLACKLISTED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isLoadingAllBlacklistedAccounts: false,
        allBlacklistedAccounts: action.payLoad,
      };

    case GET_ALL_BLACKLISTED_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoadingAllBlacklistedAccounts: false,
      };

    // GET ALL LIMIT REQUESTSS
    case GET_ALL_LIMIT_REQUESTS_START:
      return {
        ...state,
        isLoadingAllLimitRequests: true,
      };

    case GET_ALL_LIMIT_REQUESTS_SUCCESS:
      return {
        ...state,
        isLoadingAllLimitRequests: false,
        allLimitRequests: action.payLoad,
      };

    case GET_ALL_LIMIT_REQUESTS_FAILURE:
      return {
        ...state,
        isLoadingAllLimitRequests: false,
      };

    // GET SYSTEM LIMITS
    case GET_SYSTEM_LIMITS_START:
      return {
        ...state,
        isLoadingSystemLimits: true,
      };

    case GET_SYSTEM_LIMITS_SUCCESS:
      return {
        ...state,
        isLoadingSystemLimts: false,
        systemLimits: action.payLoad,
      };

    case GET_SYSTEM_LIMITS_FAILURE:
      return {
        ...state,
        isLoadingSystemLimits: false,
        specificLimitRequest: [],
      };

    // GET SPECIFIC LIMIT REQUESTS
    case GET_SPECIFIC_LIMIT_REQUEST_START:
      return {
        ...state,
        isLoadingSpecificLimitRequest: true,
      };

    case GET_SPECIFIC_LIMIT_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingSpecificLimitRequest: false,
        specificLimitRequest: action.payLoad,
      };

    case GET_SPECIFIC_LIMIT_REQUEST_FAILURE:
      return {
        ...state,
        isLoadingSpecificLimitRequest: false,
        specificLimitRequest: {},
      };

    // APPROVE LIMIT REQUESTS
    case APPROVE_SPECIFIC_LIMIT_REQUEST_START:
      return {
        ...state,
        isApprovingLimitRequest: true,
      };

    case APPROVE_SPECIFIC_LIMIT_REQUEST_SUCCESS:
      return {
        ...state,
        isApprovingLimitRequest: false,
      };

    case APPROVE_SPECIFIC_LIMIT_REQUEST_FAILURE:
      return {
        ...state,
        isApprovingLimitRequest: false,
      };

    // REJECT LIMIT REQUESTS
    case REJECT_SPECIFIC_LIMIT_REQUEST_START:
      return {
        ...state,
        isRejectingLimitRequest: true,
      };

    case REJECT_SPECIFIC_LIMIT_REQUEST_SUCCESS:
      return {
        ...state,
        isRejectingLimitRequest: false,
      };

    case REJECT_SPECIFIC_LIMIT_REQUEST_FAILURE:
      return {
        ...state,
        isRejectingLimitRequest: false,
      };

    // REJECT LIMIT REQUESTS
    case UPDATE_TRANSFER_LIMIT_START:
      return {
        ...state,
        isUpdatingTransferLimit: true,
      };

    case UPDATE_TRANSFER_LIMIT_SUCCESS:
      return {
        ...state,
        isUpdatingTransferLimit: false,
      };

    case UPDATE_TRANSFER_LIMIT_FAILURE:
      return {
        ...state,
        isUpdatingTransferLimit: false,
      };

    // GET ALL BUSINESS ACCOUNTS
    case GET_ALL_BUSINESS_ACCOUNTS_START:
      return {
        ...state,
        isLoadingAllBusinessAccounts: true,
      };

    case GET_ALL_BUSINESS_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isLoadingAllBusinessAccounts: false,
        allBusinessAccounts: action.payLoad,
      };

    case GET_ALL_BUSINESS_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoadingAllBusinessAccounts: false,
      };

    // GET SPECIFIC BUSINESS ACCOUNT
    case GET_SPECIFIC_BUSINESS_ACCOUNT_START:
      return {
        ...state,
        isLoadingSpecificBusinessAccounts: true,
      };

    case GET_SPECIFIC_BUSINESS_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoadingSpecificBusinessAccounts: false,
        specificBusinessAccounts: action.payLoad,
      };

    case GET_SPECIFIC_BUSINESS_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoadingSpecificBusinessAccounts: false,
        specificBusinessAccounts: {},
      };

    // GET BUSINESS ACCOUNT SERVICES
    case GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START:
      return {
        ...state,
        isLoadingBusinessMerchantServices: true,
      };

    case GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS:
      return {
        ...state,
        isLoadingBusinessMerchantServices: false,
        allBusinessMerchantServices: action.payLoad,
      };

    case GET_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE:
      return {
        ...state,
        isLoadingBusinessMerchantServices: false,
        allBusinessMerchantServices: [],
      };

    // GET BUSINESS ACCOUNT FEES
    case GET_BUSINESS_ACCOUNT_MERCHANT_FEES_START:
      return {
        ...state,
        isLoadingBusinessMerchantFees: true,
      };

    case GET_BUSINESS_ACCOUNT_MERCHANT_FEES_SUCCESS:
      return {
        ...state,
        isLoadingBusinessMerchantFees: false,
        allBusinessMerchantFees: action.payLoad,
      };

    case GET_BUSINESS_ACCOUNT_MERCHANT_FEES_FAILURE:
      return {
        ...state,
        isLoadingBusinessMerchantFees: false,
        allBusinessMerchantFees: {},
      };

    // GET BUSINESS ACCOUNT SECURITY SETTINGS
    case GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_START:
      return {
        ...state,
        isLoadingBusinessSecuritySettings: true,
      };

    case GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoadingBusinessSecuritySettings: false,
        specificBusinessAccountSecuritySettings: action.payLoad,
      };

    case GET_BUSINESS_ACCOUNT_MERCHANT_SECURITY_SETTINGS_FAILURE:
      return {
        ...state,
        isLoadingBusinessSecuritySettings: false,
      };

    // GET BUSINESS ACCOUNT COMPLIANCE
    case GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_START:
      return {
        ...state,
        isLoadingBusinessMerchantCompliance: true,
      };

    case GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isLoadingBusinessMerchantCompliance: false,
        specificBusinessAccountCompliance: action.payLoad,
      };

    case GET_BUSINESS_ACCOUNT_MERCHANT_COMPLIANCE_FAILURE:
      return {
        ...state,
        isLoadingBusinessMerchantCompliance: false,
        specificBusinessAccountCompliance: {},
      };

    // GET BUSINESS SETTLEMENT TYPE
    case GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START:
      return {
        ...state,
        isLoadingBusinessAccountSettlementType: true,
      };

    case GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS:
      return {
        ...state,
        isLoadingBusinessAccountSettlementType: false,
        specificBusinessSettlementType: action.payLoad,
      };

    case GET_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE:
      return {
        ...state,
        isLoadingBusinessAccountSettlementType: false,
        specificBusinessSettlementType: {},
      };

    // GET BUSINESS SETTLEMENT ACCOUNT
    case GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START:
      return {
        ...state,
        isLoadingBusinessAccountInstantSettlement: true,
      };

    case GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS:
      return {
        ...state,
        isLoadingBusinessAccountInstantSettlement: false,
        specificBusinessSettlementAccount: action.payLoad,
      };

    case GET_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE:
      return {
        ...state,
        isLoadingBusinessAccountInstantSettlement: false,
        specificBusinessSettlementAccount: {},
      };

    // BLACKLIST BUSINESS ACCOUNT
    case BLACKLIST_BUSINESS_ACCOUNT_START:
      return {
        ...state,
        isBlacklistingBusinessAccount: true,
      };

    case BLACKLIST_BUSINESS_ACCOUNT_SUCCESS:
      return {
        ...state,
        isBlacklistingBusinessAccount: false,
      };

    case BLACKLIST_BUSINESS_ACCOUNT_FAILURE:
      return {
        ...state,
        isBlacklistingBusinessAccount: false,
      };

    // REVERT BUSINESS ACCOUNT MERCHANT FEES
    case REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_START:
      return {
        ...state,
        isRevertingBusinessAccountMerchantFees: true,
      };

    case REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS:
      return {
        ...state,
        isRevertingBusinessAccountMerchantFees: false,
      };

    case REVERT_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE:
      return {
        ...state,
        isRevertingBusinessAccountMerchantFees: false,
      };

    // UPDATE BUSINESS ACCOUNT MERCHANT FEES
    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_START:
      return {
        ...state,
        isUpdatingBusinessAccountMerchantFees: true,
      };
    case UPDATE_ACCOUNT_MERCHANT_FEE_START:
      return {
        ...state,
        isUpdatingAccountMerchantFees: true,
        isPosFeeUpdated: false
      };

    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_SUCCESS:
      return {
        ...state,
        isOtherFeesUpdated: true,
        isUpdatingBusinessAccountMerchantFees: false,
      };
    case UPDATE_ACCOUNT_MERCHANT_FEE_SUCCESS:
      return {
        ...state,
        isPosFeeUpdated: true,
        isOtherFeesUpdated: true,
        isUpdatingAccountMerchantFees: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_FEE_FAILURE:
      return {
        ...state,
        isUpdatingBusinessAccountMerchantFees: false,
      };
    case UPDATE_ACCOUNT_MERCHANT_FEE_FAILURE:
      return {
        ...state,
        isUpdatingAccountMerchantFees: false,
      };

    // UPDATE BUSINESS ACCOUNT MERCHANT SERVICES
    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_START:
      return {
        ...state,
        isUpdatingBusinessAccountMerchantServices: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessAccountMerchantServices: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_SERVICES_FAILURE:
      return {
        ...state,
        isUpdatingBusinessAccountMerchantServices: false,
      };

    // UPDATE BUSINESS ACCOUNT BVN
    case UPDATE_BUSINESS_ACCOUNT_BVN_START:
      return {
        ...state,
        isUpdatingBusinessAccountBVN: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_BVN_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessAccountBVN: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_BVN_FAILURE:
      return {
        ...state,
        isUpdatingBusinessAccountBVN: false,
      };

    // UPDATE BUSINESS ACCOUNT NAME
    case UPDATE_BUSINESS_ACCOUNT_NAME_START:
      return {
        ...state,
        isUpdatingBusinessAccountName: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_NAME_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessAccountName: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_NAME_FAILURE:
      return {
        ...state,
        isUpdatingBusinessAccountName: false,
      };

    // UPDATE BUSINESS ACCOUNT EMAIL
    case UPDATE_BUSINESS_ACCOUNT_EMAIL_START:
      return {
        ...state,
        isUpdatingBusinessAccountEmail: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_EMAIL_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessAccountEmail: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_EMAIL_FAILURE:
      return {
        ...state,
        isUpdatingBusinessAccountEmail: false,
      };

    // UPDATE BUSINESS ACCOUNT PHONE
    case UPDATE_BUSINESS_ACCOUNT_PHONE_START:
      return {
        ...state,
        isUpdatingBusinessAccountPhone: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_PHONE_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessAccountPhone: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_PHONE_FAILURE:
      return {
        ...state,
        isUpdatingBusinessAccountPhone: false,
      };

    // UPDATE BUSINESS ACCOUNT ADDRESS
    case UPDATE_BUSINESS_ACCOUNT_ADDRESS_START:
      return {
        ...state,
        isUpdatingBusinessAccountAddress: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_ADDRESS_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessAccountAddress: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_ADDRESS_FAILURE:
      return {
        ...state,
        isUpdatingBusinessAccountAddress: false,
      };

    // UPDATE BUSINESS ACCOUNT ADDRESS
    case UPDATE_BUSINESS_ACCOUNT_DETAILS_START:
      return {
        ...state,
        isUpdatingBusinessDescription: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessDescription: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        isUpdatingBusinessDescription: false,
      };

    // UPDATE BUSINESS ACCOUNT COMPLIANCE
    case UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_START:
      return {
        ...state,
        isUpdatingBusinessCompliance: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessCompliance: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_COMPLIANCE_DOCUMENT_FAILURE:
      return {
        ...state,
        isUpdatingBusinessCompliance: false,
      };

    // UPDATE BUSINESS SETTLEMENT ACCOUNT
    case UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_START:
      return {
        ...state,
        isUpdatingBusinessSettlementAccount: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessSettlementAccount: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_ACCOUNT_FAILURE:
      return {
        ...state,
        isUpdatingBusinessSettlementAccount: false,
      };

    // UPDATE BUSINESS SETTLEMENT TYPE
    case UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_START:
      return {
        ...state,
        isUpdatingBusinessSettlementType: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessSettlementType: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_SETTLEMENT_TYPE_FAILURE:
      return {
        ...state,
        isUpdatingBusinessSettlementType: false,
      };

    // UPDATE BUSINESS ACCOUNT 2FA
    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_START:
      return {
        ...state,
        isUpdatingBusinessAccount2FA: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessAccount2FA: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_2FA_FAILURE:
      return {
        ...state,
        isUpdatingBusinessAccount2FA: false,
      };

    // UPDATE BUSINESS ACCOUNT IP PROTECTION
    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_START:
      return {
        ...state,
        isUpdatingBusinessAccountIP: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessAccountIP: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_MERCHANT_IP_PROTECTION_FAILURE:
      return {
        ...state,
        isUpdatingBusinessAccountIP: false,
      };

    // UPDATE BUSINESS ACCOUNT IP PROTECTION
    case UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_START:
      return {
        ...state,
        isUpdatingBusinessAccountInstantSettlement: true,
      };

    case UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_SUCCESS:
      return {
        ...state,
        isUpdatingBusinessAccountInstantSettlement: false,
      };

    case UPDATE_BUSINESS_ACCOUNT_INSTANT_SETTLEMENT_FAILURE:
      return {
        ...state,
        isUpdatingBusinessAccountInstantSettlement: false,
      };

    // FUND BUSINESS ACCOUNT
    case FUND_BUSINESS_ACCOUNT_START:
      return {
        ...state,
        isFundingBusinessAccount: true,
      };

    case FUND_BUSINESS_ACCOUNT_SUCCESS:
      return {
        ...state,
        isFundingBusinessAccount: false,
      };

    case FUND_BUSINESS_ACCOUNT_FAILURE:
      return {
        ...state,
        isFundingBusinessAccount: false,
      };

    // MULTI-CURRENCIES
    case GET_MULTI_CURRENCIES_START:
      return {
        ...state,
        isLoadingMultiCurrencies: true
      }

    case GET_MULTI_CURRENCIES_SUCCESS:
      return {
        ...state,
        isLoadingMultiCurrencies: false,
        multiCurrencies: action.payLoad
      }
    case GET_MULTI_CURRENCIES_FAILURE:
      return {
        ...state,
        isLoadingMultiCurrencies: false
      }

    default:
      return state;
  }
}

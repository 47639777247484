import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { FiCopy } from "react-icons/fi";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import {
	copyTextToClipboard,
	fixDateForAllBrowsers,
	statusEnum,
	transactionChannelEnum,
	truncateRef,
} from "../../helpers/helper";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import useTransactions from "../../hooks/useTransactions";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import {
	getAllTransactions,
	getAllTransactionsForDownload,
} from "../../redux/transactions/transactionsActions";
import CheckoutInflowSearchInput from "../../components/inputs/CheckoutInflowSearchInput";

export default function AllTransactions() {
	const dispatch = useDispatch();
	const location = useLocation();
	const { id } = useParams();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const { isLoadingAllTransactions, allTransactions } = useTransactions();

	const defaultFilterParams = {
		search: "",
		amount: "",
		account_name: "",
		txn_ref: "",
		type: "",
		channel: "",
		status: qs.parse(location.search)?.status ?? "",
		page: currentPage,
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 4), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const {
		date_from,
		date_to,
		status,
		search,
		page,
		amount,
		account_name,
		txn_ref,
		type,
		channel,
	} = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		date_from,
		date_to,
		// isReferenceSearch,
		search,
		status,
		amount,
		account_name,
		txn_ref,
		type,
		channel,
	]);

	React.useMemo(() => {
		dispatch(
			getAllTransactions(id, {
				params: {
					...(txn_ref || amount || account_name
						? { txn_ref, amount, account_name, paginate: 1, page, per_page: 10 }
						: {
								// currency,
								date_from,
								date_to,
								paginate: 1,
								page,
								per_page: 10,
								status,
								amount,
								account_name,
								txn_ref,
								type,
								channel,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		// isReferenceSearch,
		page,
		// per_page,
		search,
		status,
		amount,
		account_name,
		txn_ref,
		type,
		channel,
	]);

	React.useMemo(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	return (
		<div className="p-3 flex flex-col">
			{/* <div className="mb-5">
				<BreadCrumbs title={"Back"} goBack />
			</div> */}
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				filterFields={[
					"statusWithAll",
					"transactionChannel",
					"transactionType",
					"date",
				]}
				filterCallback={handleChange}
				disabled={isLoadingAllTransactions}
				fileName="Transactions History"
				downloadAsCSV
				CSVCallback={(handleDownload) =>
					dispatch(
						getAllTransactionsForDownload(
							{
								params: {
									...(txn_ref || amount || account_name
										? {
												txn_ref,
												amount,
												account_name,
												paginate: 0,
												page,
												per_page: 10,
										  }
										: {
												// currency,
												date_from,
												date_to,
												paginate: 0,
												page,
												per_page: 10,
												status,
												amount,
												account_name,
												txn_ref,
												type,
												channel,
										  }),
								},
							},
							(data) =>
								handleDownload({
									data,
									fileName: "Transactions History",
									format: (value, i) => ({
										"S/N": `${i + 1}`,
										"Transaction Status": value?.status,
										"Transaction Ref": value?.txn_ref,
										Amount: value?.amount,
										Channel: value?.channel,
										"Transaction Date": value?.transaction_date,
										Details: value?.details,
									}),
								})
						)
					)
				}
				getWorksheets={({ xlsx }) => {
					return {
						data: xlsx.utils.json_to_sheet(
							allTransactions?.data?.map((value, i) => ({
								"S/N": `${i + 1}`,
								"Transaction Status": value?.status,
								"Transaction Ref": value?.txn_ref,
								Amount: value?.amount,
								Channel: value?.channel,
								"Transaction Date": value?.transaction_date,
								Details: value?.details,
							}))
						),
					};
				}}
				noSearch
				customSearchInput={
					<CheckoutInflowSearchInput
						onChange={handleChange}
						defaultFilterParams={defaultFilterParams}
					/>
				}
				title={`Transactions ${
					!isLoadingAllTransactions && allTransactions?.total
						? "- " + allTransactions?.total
						: ""
				}`}
				searchBarPlaceholder={"Search"}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof allTransactions === "object" && !Array.isArray(allTransactions)
						? allTransactions?.data
						: allTransactions?.data?.reverse()
				}
				empty_message="No transactions"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllTransactions}
				setCurrentPage={setCurrentPage}
				totalPages={allTransactions?.total}
			/>
		</div>
	);
}

const columns = [
	{
		accessor: "status",
		Header: "",
		style: {
			paddingLeft: 0,
			paddingRight: "10px",
			width: "50px",
		},
		Cell: ({ value }) => (
			<React.Fragment>
				<CircleIndicator
					className="py-1 mx-auto capitalize"
					type={statusEnum[value]}
					style={{
						marginLeft: "auto",
						marginRight: "auto",
						display: "block",
					}}
				/>
			</React.Fragment>
		),
	},
	{
		accessor: "account_name",
		Header: "Account Name",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/all-transactions/${
					transactionChannelEnum[row.original.channel]
				}/details/${row.original.id}`}
			>
				<span className="glade-normal-text-two">{value}</span>
			</Link>
		),
	},
	{
		accessor: "amount",
		Header: "Amount",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/all-transactions/${
					transactionChannelEnum[row.original.channel]
				}/details/${row.original.id}`}
			>
				<span className="glade-normal-text-two">
					{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			</Link>
		),
	},
	{
		accessor: "fee_value",
		Header: "Fee",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/all-transactions/${
					transactionChannelEnum[row.original.channel]
				}/details/${row.original.id}`}
			>
				<span className="glade-normal-text-two">
					{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			</Link>
		),
	},
	{
		accessor: "txn_ref",
		Header: "Transaction Reference",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/all-transactions/${
					transactionChannelEnum[row.original.channel]
				}/details/${row.original.id}`}
			>
				<div className="flex items-center">
					<span className="glade-normal-text-two">{truncateRef(value)}</span>
					<FiCopy
						size={16}
						color="#AFAFAF"
						className="ml-3 cursor-pointer"
						onClick={() => copyTextToClipboard(value)}
					/>
				</div>
			</Link>
		),
	},
	{
		accessor: "type",
		Header: "Transaction Type",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/all-transactions/${
					transactionChannelEnum[row.original.channel]
				}/details/${row.original.id}`}
			>
				<span className="glade-normal-text-two capitalize">
					{value ?? "Not available"}
				</span>
			</Link>
		),
	},
	{
		accessor: "balance_after",
		Header: "Balance After",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/all-transactions/${
					transactionChannelEnum[row.original.channel]
				}/details/${row.original.id}`}
			>
				<span className="glade-normal-text-two">
					{row.original.currency ?? "NGN"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			</Link>
		),
	},
	{
		accessor: "channel",
		Header: "Channel",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/all-transactions/${
					transactionChannelEnum[row.original.channel]
				}/details/${row.original.id}`}
			>
				<span className="glade-normal-text-two capitalize">
					{value ?? "Not available"}
				</span>
			</Link>
		),
	},
	{
		accessor: "transaction_date",
		Header: "Transaction Date/Time",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/all-transactions/${
					transactionChannelEnum[row.original.channel]
				}/details/${row.original.id}`}
			>
				<>
					<span className="glade-normal-text-two">
						{dfn.format(new Date(fixDateForAllBrowsers(value)), "dd-MM-yy") + " "}
					</span>
					<span className="glade-normal-text-two"> 
						{dfn.format(new Date(fixDateForAllBrowsers(value)), "hh:mm:ss a").toLowerCase()}
					</span>
				</>
			</Link>
		),
	},
	{
		accessor: "details",
		Header: "Details",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/all-transactions/${
					transactionChannelEnum[row.original.channel]
				}/details/${row.original.id}`}
			>
				<div className="glade-normal-text-two block w-28 whitespace-normal">
					{value ?? "Not available"}
				</div>
			</Link>
		),
	},
];

import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import NumberFormat from "react-number-format";
import * as dfn from "date-fns";

import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import {
	revertTransferLimit,
	updateTransferLimit,
} from "../../redux/accounts/accountsActions";

const EditTransferLimitModal = ({
	modalStates,
	setModalStates,
	showEditDailyTransferLimit,
	owner_type,
	owner_uuid,
}) => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { isUpdatingTransferLimit, specificLimitRequest } = useAccounts();

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			amount: "",
		},
	});

	const closeModal = () => {
		reset();
		setModalStates({ ...modalStates, showEditDailyTransferLimit: false });
	};

	const onFundPersonalAccount = (data) => {
		const payload = {
			transfer_limit: data.amount,
			owner_type,
			owner_uuid,
		};

		dispatch(updateTransferLimit(id, payload, closeModal));
	};

	const revertLimitToDefault = () => {
		dispatch(revertTransferLimit(id, closeModal));
	};

	return (
		<BasicModal
			visibility={showEditDailyTransferLimit}
			onClose={() => {
				reset();
				setModalStates({ ...modalStates, showEditDailyTransferLimit: false });
			}}
			onOpen={() =>
				setModalStates({
					...modalStates,
					showEditDailyTransferLimit: true,
				})
			}
			primaryButton=""
			modalTitle="Edit Transaction Limit"
			secondaryButton=""
			modelContentSection={
				<form onSubmit={handleSubmit(onFundPersonalAccount)} className="">
					<div className="mt-8">
						<label htmlFor="bvn" className="block glade-small-text-two">
							Transaction Limit
						</label>
						<div className="relative">
							<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
								<span className="glade-black sm:text-sm py-2">NGN</span>
							</div>
							<Controller
								render={({ field: { onChange, value } }) => (
									<NumberFormat
										thousandSeparator={true}
										onValueChange={(v) => {
											onChange(Number(v.value));
										}}
										variant="outlined"
										// defaultValue={specificLimitRequest?.transfer_limit}
										value={value}
										className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
									/>
								)}
								rules={{
									required: "Enter an amount to fund",
									min: {
										value: 1,
										message: "Minimum amount is NGN 1",
									},
								}}
								name="amount"
								control={control}
							/>
						</div>
						<FormError errors={errors} name="amount" />
					</div>

					{specificLimitRequest?.updated_at && (
						<div className="flex items-center justify-between mt-2">
							<h6 className="glade-small-text-one opacity-50 ml-1">
								{specificLimitRequest?.updated_at && (
									<>
										Last changed on{" "}
										{dfn.format(
											new Date(specificLimitRequest?.updated_at),
											"MMM d, yyyy"
										)}
									</>
								)}
							</h6>
							<h6
								className="glade-small-text-two glade-blue-normal cursor-pointer"
								tabIndex={0}
								onClick={revertLimitToDefault}
							>
								Revert to default
							</h6>
						</div>
					)}

					<div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
						<button
							type="button"
							onClick={() => {
								reset();
								setModalStates({
									...modalStates,
									showEditDailyTransferLimit: false,
								});
							}}
							className="glade-normal-text-two glade-black text-center"
						>
							Cancel
						</button>
						<div>
							<Button.Dark
								type="submit"
								title="Save"
								className="block flex-grow-0"
								loading={isUpdatingTransferLimit}
							/>
						</div>
					</div>
				</form>
			}
		/>
	);
};

export default EditTransferLimitModal;

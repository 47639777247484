import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import TabNav from "../../components/navbars/TabNav";
import { PaginatedTable } from "../../components/table/Table";
import { getAllProviders, updateProviderStatus, searchProviderWithCategory } from "../../redux/providers/providersActions";

const PosProviders = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [disableProviderUpdateButton, setDisableProviderUpdateButton] = useState(true);
    const { isLoadingAllProvidersList, allProviders } = useSelector(state => state?.providers);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedProvider, setSelectedProvier] = useState('');
    const [resetPage, setResetPage] = useState(false);
    const [category, setCategory] = useState('');
    const [type, setType] = useState('');

    useEffect(() => {
        let urlType = location?.pathname?.split('/')[3];
        let urlCategory = location?.pathname?.split('/')[5];
        setCategory(urlCategory);
        setType(urlType);
        dispatch(getAllProviders(urlType));
        setResetPage(false);
    }, [dispatch, resetPage]);

    const handleSearchChange = (event) => {
        dispatch(searchProviderWithCategory(event.search));
    }
    const columns = [
        {
            accessor: "checkbox",
            Header: "",
            Cell: ({ value, row }) => {
                const handleCheckboxClick = (e)=>{
                    const value = e.target.value;
                    return selectedProvider !== value? setSelectedProvier(value)&setDisableProviderUpdateButton(false) : setSelectedProvier('')&setDisableProviderUpdateButton(true);
                }
                return (
                <div className="flex items-center">
                    <span className="glade-normal-text-two">
                        {row.original.status === 1? 'Default' : <input type="checkbox" value={row.original.id} checked={Number(selectedProvider) === Number(row.original.id)} onChange={handleCheckboxClick} className=" focus:ring-0 focus:shadow-none" />}
                    </span>
                </div>
            )},
        },
        {
            accessor: "provider",
            Header: "Provider",
            Cell: ({ value, row }) => (
                <div className="flex items-center">
                    <span className="glade-normal-text-two">
                        {row.original.provider_name}
                    </span>
                </div>
            ),
        },
        {
            accessor: "Code",
            Header: "Code",
            Cell: ({ value, row }) => (
                <div className="flex items-center">
                    <span className="glade-normal-text-two">
                        {row.original.provider_code}
                    </span>
                </div>
            ),
        },
    ];
    return (
        <div className="p-3">
            <div className="mb-5">
                <h6>
                <BreadCrumbs title={`${type} Categories`} goBack />
                </h6>
            </div>
            <CardTableHeader
                title={`${category} Providers`}
                filterButton={false}
                searchBarPlaceholder={"Search Provider"}
                onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                        handleSearchChange({ search: value.trim() });
                    }else{
                        setResetPage(true);
                    }
                }}
                defaultFilterParams={{ search: "" }}
                actionButton={
                    <div className="flex items-center">
                        <Button.Dark
                            title={"Update Provider"}
                            disabled={disableProviderUpdateButton}
                            onClick={() => dispatch(updateProviderStatus(location?.pathname?.split('/')[3], selectedProvider))}
                        />
                    </div>
                }
            />
            <PaginatedTable
                columns={columns}
                data={
                    typeof allProviders?.all === "object" && !Array.isArray(allProviders?.all)
                        ? allProviders?.all
                        : allProviders?.all
                }
                empty_message="No Providers to display"
                empty_sub_message=""
                current_page={currentPage}
                loading={isLoadingAllProvidersList}
                setCurrentPage={setCurrentPage}
                totalPages={allProviders?.all?.length}
            />
        </div>
    )
}

export default PosProviders;
export const GET_ALL_FEES_START = "GET_ALL_FEES_START";
export const GET_ALL_FEES_SUCCESS = "GET_ALL_FEES_SUCCESS";
export const GET_ALL_FEES_FAILURE = "GET_ALL_FEES_FAILURE";

export const GET_CHECKOUT_FEES_START = "GET_CHECKOUT_FEES_START";
export const GET_CHECKOUT_FEES_SUCCESS = "GET_CHECKOUT_FEES_SUCCESS";
export const GET_CHECKOUT_FEES_FAILURE = "GET_CHECKOUT_FEES_FAILURE";

export const UPDATE_FEE_START = "UPDATE_FEE_START";
export const UPDATE_FEE_SUCCESS = "UPDATE_FEE_SUCCESS";
export const UPDATE_FEE_FAILURE = "UPDATE_FEE_FAILURE";

export const START_CURRENCIES_LIST = "START_CURRENCIES_LIST"
export const SUCCESS_CURRENCIES_LIST = "SUCCESS_CURRENCIES_LIST"
export const FAILURE_CURRENCIES_LIST = "FAILURE_CURRENCIES_LIST"

import { useForm } from "react-hook-form";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import { CardPlain, CardPlainSection } from "../../components/cards/CardPlain";
import useDiscounts from "../../hooks/useDiscounts";
import { FormError } from "../../validation";
import { useDispatch } from "react-redux";
import { newDiscount } from "../../redux/discounts/discountActions";

const NewFreeTransfer = () => {
  const dispatch = useDispatch();
  const { isCreatingNewDiscount } = useDiscounts();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    criteriaMode: "all",
    shouldUseNativeValidation: true
  });

  const onNewTransfer = ({ account_type, number_of_transfer }) => {
    const formData = {
      discount_type: "free_transfer",
      account_type,
      number_of_transfer
    };
    dispatch(newDiscount(formData));
  };

  return (
    <div className="p-3 flex flex-col">
      <div className="mb-5">
        <BreadCrumbs title={"Discounts"} goBack />
      </div>

      <form onSubmit={handleSubmit(onNewTransfer)}>
        <CardPlain type="single">
          <CardPlainSection
            title={"New Free Transfer"}
            type="single"
          >
            <section className="pt-10 px-16 pb-10">
              <div className="mb-4">
                <label
                  htmlFor="account_type"
                  className="block glade-small-text-two"
                >
                  Account Type
                </label>
                <select
                  className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
                  name="account_type"
                  id="account_type"
                  {...register("account_type", {
                    required: "Select an account type"
                  })}
                >
                  <option value="" selected>Select Account Type</option>
                  <option value="personal">Personal</option>
                  <option value="business">Business</option>
                </select>
                <FormError errors={errors} name="account_type" />
              </div>
              <div className="mb-8">
                <label
                  className="block glade-small-text-two"
                  htmlFor="number_of_transfer"
                >
                  Number of Free Transfers
                </label>
                <input
                  type="number"
                  name="number_of_transfer"
                  {...register("number_of_transfer", {
                    required: "Pick amount of free transfers"
                  })}
                  id="number_of_transfer"
                  placeholder="Enter Number of Free Transfers"
                  className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                />
                <FormError errors={errors} name="number_of_transfer" />
              </div>
            </section>
          </CardPlainSection>
        </CardPlain>
        <div className="flex justify-center mt-6 space-x-4">
          <Button.Dark
            type="submit"
            title={"Add Discount"}
            loading={isCreatingNewDiscount}
            style={{ width: "fit-content" }}
          />
        </div>
      </form>
    </div>
  );
};

export default NewFreeTransfer;
import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FiCopy } from "react-icons/fi";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import {
  copyTextToClipboard,
  fixDateForAllBrowsers,
  statusEnum,
  truncateRef,
} from "../../helpers/helper";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import TabNav from "../../components/navbars/TabNav";
import useTransactions from "../../hooks/useTransactions";
import CircleIndicator from "../../components/indicators/CircleIndicator";
import {
  getAllTransferTransactions,
  getAllTransferTransactionsForDownload,
} from "../../redux/transactions/transactionsActions";

export default function TransferTransactions() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page ?? 1
  );

  const { isLoadingAllTransferTransactions, allTransferTransactions } =
    useTransactions();

  const defaultFilterParams = {
    search: "",
    status: qs.parse(location.search)?.status ?? "",
    page: currentPage,
    date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
    date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
  };

  const filterInstance = usePaginationFilterParams({
    initialFilters: defaultFilterParams,
  });

  const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

  const { date_from, date_to, status, search, page } = filters;

  const [filterValue, setFilterValue] = React.useState(filters);

  useEffect(() => {
    setCurrentPage(1);
    setFilterValue(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date_from,
    date_to,
    // isReferenceSearch,
    search,
    status,
  ]);

  React.useMemo(() => {
    dispatch(
      getAllTransferTransactions({
        params: {
          ...(search
            ? { search, paginate: 1, page, per_page: 10 }
            : {
                // currency,
                date_from,
                date_to,
                paginate: 1,
                page,
                per_page: 10,
                status,
              }),
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // currency,
    date_from,
    date_to,
    // isReferenceSearch,
    page,
    // per_page,
    search,
    status,
  ]);

  React.useMemo(() => {
    handleChange({ ...filterValue, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const resetReferenceSearch = (event) => {
    if (typeof event?.preventDefault === "function") {
      event?.preventDefault();
    }
    // setIsReferenceSearch(false);
    urlSearchParamsHistory.replace(defaultFilterParams);
  };

	return (
		<div className="p-3">
			<TabNav
				tabs={[
					{ title: "Transfers", path: "/dashboard/outflows" },
					{ title: "Bills", path: "/dashboard/outflows/bills" },
					{ title: "Payroll", path: "/dashboard/outflows/payroll" },
					{ title: "International Transfers", path: "/dashboard/outflows/international-transfers" },
				]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				filterFields={["statusWithAll", "date"]}
				filterCallback={handleChange}
				disabled={isLoadingAllTransferTransactions}
				fileName="Transfer Transactions"
				downloadAsCSV
				CSVCallback={(handleDownload) =>
					dispatch(
						getAllTransferTransactionsForDownload(
							{
								params: {
									...(search
										? { search, paginate: 0, page, per_page: 10 }
										: {
												// currency,
												date_from,
												date_to,
												paginate: 0,
												page,
												per_page: 10,
												status,
										  }),
								},
							},
							(data) =>
								handleDownload({
									data,
									fileName: "Transfer Transactions History",
									format: (value, i) => ({
										"S/N": `${i + 1}`,
										"Transaction Status": value?.status,
										"Account Name": value?.account_name,
										"Account Type": value?.account_type,
										"Transaction Ref": value?.txn_ref,
										Amount: value?.value,
										"Recipient Account": value?.beneficiary_account,
										"Recipient Bank": value?.beneficiary_bank_name,
										"Transaction Type": value?.transfer_category,
										"Transaction Date": value?.created_at,
									}),
								})
						)
					)
				}
				title={`Transactions ${
					!isLoadingAllTransferTransactions && allTransferTransactions?.total
						? "- " + allTransferTransactions?.total
						: ""
				}`}
				searchBarPlaceholder={search || "Account Name or Transaction Reference"}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof allTransferTransactions === "object" &&
					!Array.isArray(allTransferTransactions)
						? allTransferTransactions?.data
						: allTransferTransactions?.data?.reverse()
				}
				empty_message="No transactions"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllTransferTransactions}
				setCurrentPage={setCurrentPage}
				totalPages={allTransferTransactions?.total}
			/>
		</div>
	);
}

const columns = [
  {
    accessor: "status",
    Header: "",
    style: {
      paddingLeft: 0,
      paddingRight: "10px",
      width: "50px",
    },
    Cell: ({ value }) => (
      <React.Fragment>
        <CircleIndicator
          className="py-1 mx-auto capitalize"
          type={statusEnum[value]}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
        />
      </React.Fragment>
    ),
  },
  {
    accessor: "account_name",
    Header: "Account Name",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
        <span className="glade-normal-text-two capitalize">{value}</span>
      </Link>
    ),
  },
  {
    accessor: "account_type",
    Header: "Account Type",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
        <span className="glade-normal-text-two capitalize">{value}</span>
      </Link>
    ),
  },
  {
    accessor: "txn_ref",
    Header: "Transaction Reference",
    Cell: ({ value }) => (
      <div className="flex items-center">
        <span className="glade-normal-text-two">{truncateRef(value)}</span>
        <FiCopy
          size={16}
          color="#AFAFAF"
          className="ml-3 cursor-pointer"
          onClick={() => copyTextToClipboard(value)}
        />
      </div>
    ),
  },
  {
    accessor: "actual_value",
    Header: "Amount",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
        <span className="glade-normal-text-two">
          {row.original.currency}{" "}
          {Number(value)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </Link>
    ),
  },
  {
    accessor: "beneficiary_account",
    Header: "Recipient",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
        <span className="glade-normal-text-two block">
          {row.original.beneficiary_name}
        </span>
        <span className="glade-normal-text-two block">{value}</span>
        <span className="glade-normal-text-two block">
          {row.original.beneficiary_bank_name}
        </span>
      </Link>
    ),
  },
  {
    accessor: "calculatedfee",
    Header: "Fee",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
        <span className="glade-normal-text-two">
          {row.original.currency}{" "}
          {Number(value)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </Link>
    ),
  },
  {
    accessor: "transfer_category",
    Header: "Transfer Type",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
        <span className="glade-normal-text-two">{value}</span>
      </Link>
    ),
  },
  {
    accessor: "created_at",
    Header: "Transaction Date",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/outflows/transfers/detail/${row.original.id}`}>
        <span className="glade-normal-text-two">
          {dfn.format(
            new Date(fixDateForAllBrowsers(value)),
            "MMM d, yyyy hh:mm:ss a"
          )}
        </span>
      </Link>
    ),
  },
];

import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { FormError } from "../../validation";
import useAccounts from "../../hooks/useAccounts";
import { fundPersonalAccount } from "../../redux/accounts/accountsActions";
import { useParams } from "react-router";
import NumberFormat from "react-number-format";

const FundPersonalAccountModal = ({
  modalStates,
  setModalStates,
  showFundAccountModal,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { isFundingPersonalAccount, specificPersonalAccount } = useAccounts();

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: "",
    },
  });

  const closeModal = () => {
    reset();
    setModalStates({ ...modalStates, showFundAccountModal: false });
  };

  const onFundPersonalAccount = (data) => {
    const payload = {
      value: data.amount,
      fund_type: data.fund_type,
      remark: data.remark,
      currency: specificPersonalAccount?.bank_detail[0]?.currency,
      owner_type: specificPersonalAccount?.bank_detail[0]?.owner_type,
      owner_uuid: specificPersonalAccount?.user?.user_uuid,
    };

    dispatch(fundPersonalAccount(id, payload, closeModal));
  };

  return (
    <BasicModal
      visibility={showFundAccountModal}
      onClose={() => {
        reset();
        setModalStates({ ...modalStates, showFundAccountModal: false });
      }}
      onOpen={() =>
        setModalStates({
          ...modalStates,
          showFundAccountModal: true,
        })
      }
      primaryButton=""
      modalTitle="Fund Account"
      secondaryButton=""
      modelContentSection={
        <form onSubmit={handleSubmit(onFundPersonalAccount)} className="">
          <h4 className="glade-normal-text-two glade-black capitalize mb-3">
            Account Balance
          </h4>
          {specificPersonalAccount?.bank_detail && (
            <p className="glade-blue-normal glade-heading-four">
              {specificPersonalAccount?.bank_detail[0]?.currency ?? "NGN"}{" "}
              {Number(
                specificPersonalAccount?.bank_detail[0]?.balance ?? 0
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          )}

          <div className="mt-8">
            <label htmlFor="bvn" className="block glade-small-text-two">
              Amount
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="glade-black sm:text-sm py-2">
                  {specificPersonalAccount?.bank_detail[0]?.currency}
                </span>
              </div>
              <Controller
                render={({ field: { onChange, value, ref } }) => (
                  <NumberFormat
                    thousandSeparator={true}
                    onValueChange={(v) => {
                      onChange(Number(v.value));
                    }}
                    variant="outlined"
                    defaultValue=""
                    value={value}
                    className="mt-1 block w-full pl-12 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                  />
                )}
                rules={{
                  required: "Enter an amount to fund",
                  min: {
                    value: 1,
                    message: "Minimum amount is NGN 1",
                  },
                }}
                name="amount"
                control={control}
              />
            </div>
            <FormError errors={errors} name="amount" />
          </div>

          <div className="w-full my-3">
            <label htmlFor="category" className="block glade-small-text-two">
              Transaction Type
            </label>
            <select
              id="fund_type"
              name="fund_type"
              {...register("fund_type")}
              className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
            >
              <option value="credit" className="capitalize">
                Credit
              </option>
              <option value="debit" className="capitalize">
                Debit
              </option>
            </select>
            <FormError errors={errors} name="fund_type" />
          </div>

          <div className="">
            <label htmlFor="remark" className="block glade-small-text-two">
              Remarks
            </label>
            <textarea
              type="text"
              id="remark"
              name="remark"
              {...register("remark", {
                // required: "Enter a valid remark",
                minLength: {
                  value: 2,
                  message: "Enter a valid remark",
                },
              })}
              rows={4}
              placeholder=""
              className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
            />
            <FormError errors={errors} name="remark" />
          </div>

          <div className="flex -mb-8 items-center justify-between mt-10 p-4 border-t border-solid border-gray-200 -mx-5">
            <button
              type="button"
              onClick={() => {
                reset();
                setModalStates({
                  ...modalStates,
                  showFundAccountModal: false,
                });
              }}
              className="glade-normal-text-two glade-black text-center"
            >
              Cancel
            </button>
            <div>
              <Button.Dark
                type="submit"
                title="Fund"
                className="block flex-grow-0"
                loading={isFundingPersonalAccount}
              />
            </div>
          </div>
        </form>
      }
    />
  );
};

export default FundPersonalAccountModal;

import { useSelector } from "react-redux";

const useFees = () => {
  const fees = useSelector((state) => state.fees);

  const {
    isLoadingAllFees,
    isLoadingCheckoutFees,
    isUpdatingFee,
	isLoadingCheckoutCurrencies,
    allFees,
    allCheckoutFees,
	allCheckoutCurrencies

  } = fees;

  return {
    isLoadingAllFees,
    isUpdatingFee,
    isLoadingCheckoutFees,
	isLoadingCheckoutCurrencies,
    allCheckoutFees,
    allFees,
	allCheckoutCurrencies
  };
};

export default useFees;

import React, { useEffect, useMemo, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import DownloadAsCSVButton from "../../helpers/DownloadAsCSV";
import { arrayEquals, fixDateForAllBrowsers, statusEnum } from "../../helpers/helper";
import BasicIndicatorWithOptions from "../../components/indicators/BasicIndicatorWithOptions";
import { getAllCACRegistration, getSingleCACRegistration } from "../../redux/cacRegisstration/cacRegistrationActions";
import useCACRegistration from "../../hooks/useCACRegstration";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import MarkCACAsDeliveredModal from "./MarkCACAsDeliveredModal";
import DownloadAsCSVWithMultipleSheet from "../../helpers/DownloadAsCSVWithMultipleSheet";

const CacRegistration = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [selectedCAC, setSelectedCAC] = useState({});
  const [selectAllCAC, setSelectAllCAC] = useState(false);
  const [selectedRequestsForDownload, setSelectedRequestsForDownload] = useState([]);
  const [status, setStatus] = useState('');

  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page ?? 1
  );

  const { isLoadingCACRegistration, cacRequestsData } = useCACRegistration();

  const defaultFilterParams = {
    search: "",
    page: currentPage,
    date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
    date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
  };

  const filterInstance = usePaginationFilterParams({
    initialFilters: defaultFilterParams,
  });

  const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

  const { date_from, date_to, search, page } = filters;

  const [filterValue, setFilterValue] = React.useState(filters);

  useEffect(() => {
    setCurrentPage(1);
    setFilterValue(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_from, date_to, search]);

  useEffect(() => {
    dispatch(
      getAllCACRegistration({
        params: {
          ...(search
            ? { search, paginate: 1, current_page: page, per_page: 10 }
            : {
              date_from,
              date_to,
              paginate: 1,
              current_page: page,
              per_page: 10,
            }),
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // currency,
    date_from,
    date_to,
    page,
    // per_page,
    search,
  ]);

  useEffect(() => {
    handleChange({ ...filterValue, page: currentPage });
    setSelectAllCAC(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const resetReferenceSearch = (event) => {
    if (typeof event?.preventDefault === "function") {
      event?.preventDefault();
    }
    urlSearchParamsHistory.replace(defaultFilterParams);
  };

  const handleAction = (data, actionType) => {
    setShowModal(true);
    setSelectedCAC(data);
    setStatus(actionType);
  };

  const updateSelectedRequestsForDownload = (event, id) => {
    const cacData = typeof cacRequestsData === "object" && !Array.isArray(cacRequestsData)
      ? cacRequestsData?.data
      : cacRequestsData
    if (event.target.checked) {
      setSelectedRequestsForDownload((prevSelectedItems) => [...prevSelectedItems, id]);
      const oldList = [...selectedRequestsForDownload];
      oldList.push(id);
      oldList.sort(function (a, b) { return b - a })

      let cacDataIds = cacData?.map((cac) => cac?.id).sort(function (a, b) { return b - a })

      if (arrayEquals(cacDataIds, oldList)) {
        setSelectAllCAC(true)
      } else {
        setSelectAllCAC(false)
      }
    } else {
      setSelectedRequestsForDownload((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== id)
      );
      setSelectAllCAC(false)
    }
  };

  const handleSelectAllCAC = (event) => {
    const cacData = typeof cacRequestsData === "object" && !Array.isArray(cacRequestsData)
      ? cacRequestsData?.data
      : cacRequestsData
    if (event.target.checked) {
      setSelectedRequestsForDownload(cacData?.map((cac) => cac?.id));
      setSelectAllCAC(true);
    } else {
      setSelectedRequestsForDownload([]);
      setSelectAllCAC(false);
    }
  };

  const handleCSVDownload = (fxn) => {

    let companyData = [];
    let directorsData = [];
    selectedRequestsForDownload.forEach(id => dispatch(
      getSingleCACRegistration(id, (data) => {
        companyData = [{
          ...data, ...data.company, directors: data?.directors?.length
        }];
        directorsData = [
          ...(data && data.directors || [])
        ]
        fxn([{ name: data?.company?.proposed_name_one || 'company data', data: companyData }, { name: 'directors data', data: directorsData }]);
      }))
    )
  }

const columns = [
  {
    accessor: "id",
    Header: () => (
      <span className="glade-normal-text-two capitalize">
        <input
          type="checkbox"
          checked={selectAllCAC}
          onChange={handleSelectAllCAC}
        />
      </span>
    ),
    Cell: ({ value }) => (
      <span className="glade-normal-text-two capitalize">
        <input
          type="checkbox"
          checked={selectedRequestsForDownload.includes(value)}
          onChange={(event) => updateSelectedRequestsForDownload(event, value)}
        />
      </span>
    ),
  },
  {
    accessor: "status",
    Header: "Status",
    Cell: ({ value, row }) => (
      <div className="flex items-center">
        {value === "draft" || value === "deleted" || value === "completed" ?
          <BasicIndicator
            className="px-5 py-2 border-4 capitalize"
            type={value === 'completed' ? 'neutral' : statusEnum[value]}
            title={value === 'completed' ? 'delivered' : value}
          />
          :
          <BasicIndicatorWithOptions
            className="px-5 py-1 capitalize"
            type={value === 'created' ? 'not-delivered' : value === 'completed' ? 'delivered' : value}
            title={value === 'created' ? 'Pending' : value === 'completed' ? 'delivered' : value}
            data={row.original}
            test={handleAction}
          />
        }
      </div>
    ),
  },
  {
    accessor: "registration_type",
    Header: "Registration Type",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/cac/registrations/details?id=${row.original.id}`}>
        <span className="glade-normal-text-two capitalize">{value?.replaceAll("-", " ")}</span>
      </Link>
    ),
  },
  {
    accessor: "account_name",
    Header: "Account Name",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/cac/registrations/details?id=${row.original.id}`}>
        <span className="glade-normal-text-two capitalize">{value}</span>
      </Link>
    ),
  },
  {
    accessor: "account_email",
    Header: "Email",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/cac/registrations/details?id=${row.original.id}`}>
        <span className="glade-normal-text-two capitalize">{value}</span>
      </Link>
    ),
  },
  {
    accessor: "submission_date",
    Header: "Date Submitted",
    Cell: ({ value, row }) => (
      <Link to={`/dashboard/cac/registrations/details?id=${row.original.id}`}>
        <span className="glade-normal-text-two">
          {value ? (
            <>{dfn.format(new Date(fixDateForAllBrowsers(value)), "MMM d, yyyy")}</>
          ) : (
            "Not Available"
          )}
        </span>
      </Link>
    ),
  },
];

return (
  <div className="p-3">
    <CardTableHeader
      onChange={(e) => {
        const value = e.target.value;
        if (value) {
          handleChange({ search: value.trim() });
        } else {
          resetReferenceSearch();
        }
      }}
      defaultFilterParams={defaultFilterParams}
      filterFields={["date"]}
      filterCallback={handleChange}
      disabled={isLoadingCACRegistration}
      title={`Entries - ${cacRequestsData?.total ? cacRequestsData?.total : 0}`}
      actionButton={
        <div className="flex items-center">
          <div>
            <DownloadAsCSVWithMultipleSheet
              callback={handleCSVDownload}
              fileName="CAC Incorporation"
              buttonTitle="Download as CSV"
              disabled={!selectedRequestsForDownload?.length}
            />
          </div>
        </div>
      }
      searchBarPlaceholder={"Account Name"}
    />
    <PaginatedTable
      columns={columns}
      data={
        typeof cacRequestsData === "object" && !Array.isArray(cacRequestsData)
          ? cacRequestsData?.data
          : cacRequestsData
      }
      empty_message="No Incorporation Available"
      empty_sub_message=""
      current_page={currentPage}
      loading={isLoadingCACRegistration}
      setCurrentPage={setCurrentPage}
      totalPages={cacRequestsData?.total}
    />

    {showModal &&
      <MarkCACAsDeliveredModal
        selectedCAC={selectedCAC}
        setSelectedCAC={setSelectedCAC}
        showModal={showModal}
        setShowModal={setShowModal}
        currentParams={{
          ...(search
            ? { search, paginate: 1, current_page: page, per_page: 10 }
            : {
              date_from,
              date_to,
              paginate: 1,
              current_page: page,
              per_page: 10,
            })
        }}
        status={status}
      />}
  </div>
);
};



export default CacRegistration;
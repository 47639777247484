export const GET_ALL_USER_REFERRAL_START = "GET_ALL_USER_REFERRAL_START";
export const GET_ALL_USER_REFERRAL_SUCCESS = "GET_ALL_USER_REFERRAL_SUCCESS";
export const GET_ALL_USER_REFERRAL_FAILURE = "GET_ALL_USER_REFERRAL_FAILURE";
export const GET_REFERRAL_CONFIGURATION_START = "GET_REFERRAL_CONFIGURATION_START";
export const GET_REFERRAL_CONFIGURATION_SUCCESS = "GET_REFERRAL_CONFIGURATION_SUCCESS";
export const GET_REFERRAL_CONFIGURATION_FAILURE = "GET_REFERRAL_CONFIGURATION_FAILURE";
export const SUBMIT_REFERRAL_CONFIG_START = "SUBMIT_REFERRAL_CONFIG_START";
export const SUBMIT_REFERRAL_CONFIG_SUCCESS = "SUBMIT_REFERRAL_CONFIG_SUCCESS";
export const SUBMIT_REFERRAL_CONFIG_FAILURE = "SUBMIT_REFERRAL_CONFIG_FAILURE";
  

import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import TabNav from "../../components/navbars/TabNav";
import { ImSpinner2 } from 'react-icons/im'
import {
  getReferralConfiguration, submitReferralConfiguration
} from "../../redux/referrals/referralActions";

import useReferral from "../../hooks/useReferral";
export default function ReferralSetup() {
  const dispatch = useDispatch();

  const { isLoadingReferralConfiguration, referralConfiguration, isLoadingSubmitRegConfig } = useReferral();

  const [input, setInput] = useState({
    amount: "",
    disbursement: "monthly"
  })

  useMemo(() => {
    dispatch(getReferralConfiguration());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSubmitRegConfig])

  useMemo(() => {
    setInput({ ...input, amount: referralConfiguration[0]?.reward_amount, disbursement: referralConfiguration[0]?.rewards_disbursment_interval })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralConfiguration[0]])

  const handleSubmit = () => {
    dispatch(submitReferralConfiguration({
      reward_amount: input?.amount,
      disbursment_interval: input?.disbursement
    }))
  }

  return (
    <div className="p-3">
      {isLoadingReferralConfiguration ?
        (
          <div className='flex flex-col items-center px-6 py-12 w-full bg-white h-full'>
            <div>
              <div className='mt-6'>
                <span className='glade-heading-three glade-home-dark'>Loading Referral Configuration</span>
              </div>
              <div className='flex justify-center'><ImSpinner2 className='mt-6 spinner text-center' size={70} color='#0B7AE0' /></div>
            </div>
          </div>
        ) :
        (!isLoadingSubmitRegConfig ?
          (
            <React.Fragment>
              <div className="flex justify-between items-center">
                <TabNav
                  tabs={[
                    { title: "Referrals", path: "/dashboard/referrals" },
                    { title: "Referral Settings", path: "/dashboard/referrals/setup" },

                  ]}
                />
              </div>
              <div className={"col-start-3 col-span-4 flex flex-col "}>
                <div className="bg-white py-4 overflow-hidden rounded-sm shadow-lg">
                  <div className="flex justify-between items-center px-9 my-4 pb-9 border-b-2 border-solid border-gray-200">
                    <h4 className="glade-heading-three glade-black">Settings</h4>
                    <button
                      onClick={handleSubmit}
                      disabled={input?.amount?.length === 0}
                      className={"glade-normal-text-three glade-button-dark py-2 px-4 flex justify-center items-center text-white text-center rounded-sm "}>
                      {"Save Changes"}
                    </button>
                  </div>
                  <div className="px-6 my-3">
                    <section className="pt-10 px-16 pb-10">
                      <div className="mb-4">
                        <label htmlFor="title" className="block glade-small-text-two">
                          Reward Per Referral
                        </label>
                        <div className="rounded-md mt-2 w-full md:w-1/2 border hover:bg-transparent border-slate-100 flex items-center pl-2 outline-slate-200">
                          <span className="glade-grey-input">
                            {"NGN"}
                          </span>
                          <input
                            type="text"
                            // placeholder={placeholder}
                            className="rounded-md ml-4 w-full border-transparent bg-transparent focus:border-transparent outline:border-transparent"
                            value={String(input.amount)}
                            onChange={(e) => setInput({ ...input, amount: e.target.value })}
                          />
                        </div>
                        {/* <FormError errors={errors} name="title" /> */}
                      </div>

                      <div className="w-full my-3">
                        <label htmlFor="type" className="block glade-small-text-two">
                          Rewards Disbursment Interval
                        </label>
                        <select
                          id="type"
                          name="type"
                          className="mt-1 block w-full md:w-1/2 pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
                          value={input.disbursement}
                          onChange={(e) => setInput({ ...input, disbursement: e.target.value })}
                        >
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                        </select>
                        {/* <FormError errors={errors} name="type" /> */}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) :
          (
            <div className='flex flex-col items-center px-6 py-12 w-full bg-white h-full'>
              <div>
                <div className='mt-6'>
                  <span className='glade-heading-three glade-home-dark'>Updating Referral Configuration</span>
                </div>
                <div className='flex justify-center'><ImSpinner2 className='mt-6 spinner text-center' size={70} color='#0B7AE0' /></div>
              </div>
            </div>
          )
        )
      }
    </div>
  );
}

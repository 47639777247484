import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FiCopy } from "react-icons/fi";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import TabNav from "../../components/navbars/TabNav";
import useSettlements from "../../hooks/useSettlements";
import {
	getAllSettledUSDSettlements,
	getAllSettledUSDSettlementsForDownload,
} from "../../redux/settlements/settlementsActions";
import {
	copyTextToClipboard,
	fixDateForAllBrowsers,
	truncateRef,
} from "../../helpers/helper";

const USDSettledSettlements = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const { isLoadingAllSettledUSDSettlements, allSettledUSDSettlements } =
		useSettlements();

	const defaultFilterParams = {
		search: "",
		page: currentPage,
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, search, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_from, date_to, search]);

	React.useMemo(() => {
		dispatch(
			getAllSettledUSDSettlements({
				params: {
					...(search
						? { search, paginate: 1, current_page: page, per_page: 10 }
						: {
								date_from,
								date_to,
								paginate: 1,
								current_page: page,
								per_page: 10,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		page,
		// per_page,
		search,
	]);

	// // Clear query param on render
	// useEffect(() => {
	//   urlSearchParamsHistory.replace({});
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	React.useMemo(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	return (
		<div className="p-3">
			<TabNav
				tabs={[
					{ title: "Settled", path: "/dashboard/settlements/usd" },
					{ title: "Unsettled", path: "/dashboard/settlements/usd/unsettled" },
				]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				filterFields={["date"]}
				filterCallback={handleChange}
				disabled={isLoadingAllSettledUSDSettlements}
				title={`Settlements ${
					!isLoadingAllSettledUSDSettlements && allSettledUSDSettlements?.total
						? "- " + allSettledUSDSettlements?.total
						: ""
				}`}
				fileName="Settled Settlements"
				downloadAsCSV
				CSVCallback={(handleDownload) =>
					dispatch(
						getAllSettledUSDSettlementsForDownload(
							{
								params: {
									...(search
										? { search, paginate: 0, current_page: page, per_page: 10 }
										: {
												date_from,
												date_to,
												paginate: 0,
												current_page: page,
												per_page: 10,
										  }),
								},
							},
							(data) =>
								handleDownload({
									data,
									fileName: "USD Settled Settlements",
									format: (value, i) => ({
										"S/N": `${i + 1}`,
										"Account Name": value?.account_name,
										"Payout Ref": value?.payout_reference,
										Amount: value?.total_amount,
										"Number of Txns": value?.no_of_txns,
										"Settlement Date": value?.settlement_date,
									}),
								})
						)
					)
				}
				searchBarPlaceholder={"Account Name or Payout Reference"}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof allSettledUSDSettlements?.data === "object" &&
					!Array.isArray(allSettledUSDSettlements?.data)
						? allSettledUSDSettlements?.data?.data
						: allSettledUSDSettlements?.data
				}
				empty_message="No settlements"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllSettledUSDSettlements}
				setCurrentPage={setCurrentPage}
				totalPages={allSettledUSDSettlements?.total}
			/>
		</div>
	);
};

const columns = [
	{
		accessor: "account_name",
		Header: "Account Name",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/settlements/settled/detail/${row.original.payout_reference}`}
			>
				<span className="glade-normal-text-two capitalize">{value}</span>
			</Link>
		),
	},
	{
		accessor: "payout_reference",
		Header: "Payout Reference",
		Cell: ({ value, row }) => (
			<div className="flex items-center">
				<Link
					to={`/dashboard/settlements/settled/detail/${row.original.payout_reference}`}
				>
					<span className="glade-normal-text-two">{truncateRef(value)}</span>
				</Link>
				<FiCopy
					size={16}
					color="#AFAFAF"
					className="ml-3 cursor-pointer"
					onClick={() => copyTextToClipboard(value)}
				/>
			</div>
		),
	},
	{
		accessor: "total_amount",
		Header: "Amount",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/settlements/settled/detail/${row.original.payout_reference}`}
			>
				<span className="glade-normal-text-two">
					{row.original.currency ?? "USD"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			</Link>
		),
	},
	{
		accessor: "fees",
		Header: "Fees",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/settlements/settled/detail/${row.original.payout_reference}`}
			>
				<span className="glade-normal-text-two">
					{value ? (
						<>
							{row.original.currency ?? "USD"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</>
					) : (
						"Not Available"
					)}
				</span>
			</Link>
		),
	},
	{
		accessor: "amount_paid",
		Header: "Amount Paid Out",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/settlements/settled/detail/${row.original.payout_reference}`}
			>
				<span className="glade-normal-text-two">
					{row.original.currency ?? "USD"} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			</Link>
		),
	},
	{
		accessor: "no_of_txns",
		Header: "No of Txns",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/settlements/settled/detail/${row.original.payout_reference}`}
			>
				<span className="glade-normal-text-two capitalize">{value ?? "-"}</span>
			</Link>
		),
	},
	{
		accessor: "settlement_date",
		Header: "Settlement Date",
		Cell: ({ value, row }) => (
			<Link
				to={`/dashboard/settlements/settled/detail/${row.original.payout_reference}`}
			>
				<span className="glade-normal-text-two">
					{dfn.format(
						new Date(fixDateForAllBrowsers(value)),
						"MMM d, yyyy hh:mm:ss a"
					)}
				</span>
			</Link>
		),
	},
];

export default USDSettledSettlements;

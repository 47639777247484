import React from 'react';

export default function NumericInput({
    label, 
    id,
    name, 
    type='number',
    value, 
    min='0',
    max='999999999999999999999',
    onChange=()=>{}, 
    placeholder, 
    autoComplete,
    disabled=false, 
    className, 
    leftSection, 
    rightSection,
    errorState
}) {
    return (
        <div className="space-y-1 col-span-6 sm:col-span-3">
            <label htmlFor={name??'name'} className="block glade-small-text-two glade-black">
                {label??''}
            </label>
            <div className="mt-1 flex rounded-sm shadow-sm">
                {leftSection??<React.Fragment></React.Fragment>}
                <input
                    // type={type??"number"}
                    type={"number"} // defaulting to text input because it is the only input type that safari supports
                    name={name??'name'}
                    id={id??name??'id'}
                    value={value??''}
                    min={min}
                    max={max}
                    pattern={type==='number'?"[0-9]":"\\d*"}
                    // onChange={onChange}
                    onChange={onChange}
                    disabled={disabled}
                    className={className + `glade-normal-text-two flex-1 block w-full rounded-sm sm:text-sm placeholder-gray-400 ${!errorState? 'border-gray-300' : ' border-2 border-red-500'}`}
                    placeholder={placeholder??''}
                    autoComplete={autoComplete??''}
                />
                {rightSection??<React.Fragment></React.Fragment>}
            </div>
        </div>
    );
}

import React from "react";
import useUser from "../../hooks/useUser";

export default function SidebarCard() {
  const { roleInfo, userInfo } = useUser();
  return (
    <div className="mb-6">
      <div className="glade-bg-black-lighter flex justify-between p-2 px-5 rounded">
        <div className="">
          <div className="mb-0">
            <p className="text-white text-left">{userInfo?.name}</p>
            <div className="text-white opacity-60">
              <p className="text-left text-xs">{roleInfo?.role_title}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import * as dfn from "date-fns";
import qs from "query-string";
import { FiCopy } from 'react-icons/fi';
import { copyTextToClipboard, fixDateForAllBrowsers, isArray, statusEnum, truncateRef } from '../../helpers/helper';
import { PaginatedTable } from '../../components/table/Table';
import CircleIndicator from '../../components/indicators/CircleIndicator';
import TabNav from '../../components/navbars/TabNav';
import { CardTableHeader } from '../../components/cards/CardTableHeader';
import usePaginationFilterParams from '../../hooks/usePaginationFilterParams';
import { getAllTransferTransactionsForDownload, updateManualInternationalTransfer, getCrossBorderManualTransfers, getCrossBorderManualTransfersForDownload } from '../../redux/transactions/transactionsActions';
import useTransactions from '../../hooks/useTransactions';
import BasicIndicator from '../../components/indicators/BasicIndicator';
import BasicIndicatorWithOptions from '../../components/indicators/BasicIndicatorWithOptions';
import BasicModal from '../../components/modals/BasicModal';
import Button from '../../components/buttons/Button';
import { RiLoader5Fill } from 'react-icons/ri';
import { toast } from "react-toastify";

export default function TransferTransactions() {

    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedVerb, setSelectedVerb] = useState('');
    const [selectedData, setSelectedData] = useState();
    const [currentPage, setCurrentPage] = useState(
        qs.parse(location.search)?.page ?? 1
    );

    const { isLoadingCrossBorderManualTransfer, isUpdatingSingleManualTransfer, crossBorderManualTransfer } =
        useTransactions();

    const defaultFilterParams = {
        search: "",
        status: qs.parse(location.search)?.status ?? "",
        page: currentPage,
        type: "",
        date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 70), "yyyy-MM-dd"),
        date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
    };

    const filterInstance = usePaginationFilterParams({
        initialFilters: defaultFilterParams,
    });

    const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

    const { date_from, date_to, status, type, search, page } = filters;

    const [filterValue, setFilterValue] = React.useState(filters);

    useEffect(() => {
        setCurrentPage(1);
        setFilterValue(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        date_from,
        date_to,
        // isReferenceSearch,
        search,
        status,
        type
    ]);

    React.useEffect(() => {
        dispatch(getCrossBorderManualTransfers({
            params: {
                ...(search
                    ? { search, paginate: 1, page, per_page: 10, account_type: type }
                    : {
                        // currency,
                        date_from,
                        date_to,
                        paginate: 1,
                        page,
                        per_page: 10,
                        status,
                        account_type: type
                    })
            }
        }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // currency,
        date_from,
        date_to,
        // isReferenceSearch,
        page,
        // per_page,
        search,
        type,
        status,
    ]);

    React.useEffect(() => {
        handleChange({ ...filterValue, page: currentPage });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const handleAction = (data, actionType) => {
        setSelectedData(data);
        setSelectedVerb(actionType);
    };

    const onSuccess = () => {
        dispatch(getCrossBorderManualTransfers({
            params: {
                ...(search
                    ? { search, paginate: 1, page, per_page: 10, type }
                    : {
                        // currency,
                        date_from,
                        date_to,
                        paginate: 1,
                        page,
                        per_page: 10,
                        status,
                        account_type: type
                    })
            }
        }));
        setSelectedData();
        setSelectedVerb('');
    }
    const resetReferenceSearch = (event) => {
        if (typeof event?.preventDefault === "function") {
            event?.preventDefault();
        }
        // setIsReferenceSearch(false);
        urlSearchParamsHistory.replace(defaultFilterParams);
    };
    const columns = [
        {
            accessor: "account_name",
            Header: "Account Name",
            Cell: ({ value, row }) => (
                <Link to={`/dashboard/outflows/international-transfers/detail/${row.original.id}`}>
                    <span className="glade-normal-text-two capitalize">{value}</span>
                </Link>
            ),
        },
        {
            accessor: "currency",
            Header: "Currency",
            Cell: ({ value, row }) => (
                <Link to={`/dashboard/outflows/international-transfers/detail/${row.original.id}`}>
                    <span className="glade-normal-text-two capitalize">{value}</span>
                </Link>
            ),
        },
        {
            accessor: "owner_type",
            Header: "Account Type",
            Cell: ({ value, row }) => (
                <Link to={`/dashboard/outflows/international-transfers/detail/${row.original.id}`}>
                    <span className="glade-normal-text-two capitalize">{value}</span>
                </Link>
            ),
        },
        {
            accessor: "txn_ref",
            Header: "Transaction Reference",
            Cell: ({ value }) => (
                <div className="flex items-center">
                    <span className="glade-normal-text-two">{value && truncateRef(value)}</span>
                    {value && <FiCopy
                        size={16}
                        color="#AFAFAF"
                        className="ml-3 cursor-pointer"
                        onClick={() => copyTextToClipboard(value)}
                    />}
                </div>
            ),
        },
        {
            accessor: "beneficary_value",
            Header: "Amount",
            Cell: ({ value, row }) => (
                <Link to={`/dashboard/outflows/international-transfers/detail/${row.original.id}`}>
                    <span className="glade-normal-text-two">
                        {row.original.beneficiary_currency_code} {value && Number(value)?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </span>
                </Link>
            ),
        },
        {
            accessor: "beneficiary_account",
            Header: "Recipient",
            Cell: ({ value, row }) => (
                <Link to={`/dashboard/outflows/international-transfers/detail/${row.original.id}`}>
                    <span className="glade-normal-text-two block">
                        <span className='font-bold'>{row.original.beneficiary_name}</span>
                    </span>
                    {/* <span className="glade-normal-text-two block">{value}</span> */}
                    <span className="glade-normal-text-two block">
                        {row.original.beneficiary_bank_name}
                    </span>
                </Link>
            ),
        },
        {
            accessor: "created_at",
            Header: "Transaction Date",
            Cell: ({ value, row }) => (
                <Link to={`/dashboard/outflows/international-transfers/detail/${row.original.id}`}>
                    <span className="glade-normal-text-two">
                        {value && dfn.format(
                            new Date(fixDateForAllBrowsers(value)),
                            "dd/MM/yyyy"
                        )}
                    </span>
                </Link>
            ),
        },
        {
            accessor: "status",
            Header: "Status",
            Cell: ({ value, row }) => (
                <div className="flex items-center">
                    <BasicIndicatorWithOptions
                        className="px-5 py-1 capitalize"
                        type={statusEnum[value]}
                        title={value?.toLocaleLowerCase() !== 'pending' ? value : 'Processing'}
                        data={row.original}
                        test={handleAction}
                    />
                </div>
            ),
        },
    ];


    return (
        <div className="p-3">
            <TabNav
                tabs={[
                    { title: "Transfers", path: "/dashboard/outflows" },
                    { title: "Bills", path: "/dashboard/outflows/bills" },
                    { title: "Payroll", path: "/dashboard/outflows/payroll" },
                    { title: "Interntional Transfers", path: "/dashboard/outflows/international-transfers" },
                ]}
            />
            <CardTableHeader
                onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                        handleChange({ search: value.trim() });
                    } else {
                        resetReferenceSearch();
                    }
                }}
                defaultFilterParams={defaultFilterParams}
                filterFields={["statusWithAll", "accountType", "date"]}
                filterCallback={handleChange}
                disabled={isLoadingCrossBorderManualTransfer}
                fileName="Transfer Transactions"
                downloadAsCSV
                CSVCallback={(handleDownload) =>
                    dispatch(
                        getCrossBorderManualTransfersForDownload(
                            {
                                params: {
                                    ...(search
                                        ? { search, paginate: 0, page, per_page: 10 }
                                        : {
                                            // currency,
                                            date_from,
                                            date_to,
                                            paginate: 0,
                                            page,
                                            per_page: 10,
                                            status,
                                        }),
                                },
                            },
                            (data) => {
                                handleDownload({
                                    data,
                                    fileName: "International Transfer Transactions History",
                                    format: (value, i) => ({
                                        "S/N": `${i + 1}`,
                                        "Transaction Status": value?.status,
                                        "Account Name": value?.account_name,
                                        "Account Type": value?.owner_type,
                                        "Transaction Ref": value?.txn_ref,
                                        "Amount": value?.beneficiary_currency_code + " " +
                                            Number(value.beneficary_value),
                                        "Recipient Account": value?.beneficiary_account,
                                        "Recipient Bank": value?.beneficiary_bank_name,
                                        "Transaction Date": value?.created_at,
                                    }),
                                });
                                toast.success("Download Successful!", { position: toast.POSITION.TOP_RIGHT, theme: "colored" });
                            }
                        )
                    )
                }
                title={`Transactions ${!isLoadingCrossBorderManualTransfer && crossBorderManualTransfer?.total
                    ? "- " + crossBorderManualTransfer?.total
                    : ""
                    }`}
                searchBarPlaceholder={search || "Search Transaction Reference"}
            />
            <PaginatedTable
                columns={columns}
                data={
                    isArray(crossBorderManualTransfer?.data) && crossBorderManualTransfer?.data?.length > 0
                        ? crossBorderManualTransfer?.data?.length < 10? [...crossBorderManualTransfer?.data, ...new Array(10 - crossBorderManualTransfer?.data?.length).fill({})] : crossBorderManualTransfer?.data
                        : crossBorderManualTransfer?.data?.reverse()
                }
                empty_message="No transactions"
                empty_sub_message=""
                current_page={currentPage}
                loading={isLoadingCrossBorderManualTransfer}
                setCurrentPage={setCurrentPage}
                totalPages={crossBorderManualTransfer?.total}
            />
            {
                selectedVerb && selectedData &&
                <BasicModal
                    visibility={true}
                    onClose={() => { setSelectedVerb(''); setSelectedData() }}
                    modalTitleSection=""
                    secondaryButton=""
                    modelContentSection={
                        <div className="py-8 px-8 ">
                            <p className=" glade-black text-center mb-8">
                                Are you sure you want to update this status to {selectedVerb}?
                            </p>
                            <Button.Dark
                                onClick={() => {
                                    dispatch(updateManualInternationalTransfer({ ids: [selectedData?.id] }, selectedVerb, onSuccess))
                                }}
                                disabled={isUpdatingSingleManualTransfer}
                                title="Yes"
                                className={`glade-normal-text-three glade-button-blue ${isUpdatingSingleManualTransfer
                                    ? " cursor-not-allowed"
                                    : ""
                                    } text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
                            >
                                {isUpdatingSingleManualTransfer &&
                                    <RiLoader5Fill
                                        size={24}
                                        className="animate-spin ml-4"
                                    />
                                }
                            </Button.Dark>
                            <p className="glade-black mx-1 mt-8 cursor-pointer text-center" onClick={() => { setSelectedVerb(''); setSelectedData() }}>
                                Cancel
                            </p>
                        </div>
                    }
                />
            }
        </div>
    )
}


import React from "react";
import styled from "styled-components";

const GlassButtonStyle = styled.button`
    cursor: ${(props) => props.disabled && "not-allowed"}
`

export default function GlassButton({
  title,
  style,
  className,
  onClick = () => {},
  disabled,
}) {
  return (
    <GlassButtonStyle
      type="button"
      style={style}
      onClick={() => onClick()}
      disabled={disabled}
      className={
        "w-full  py-2 px-4 bg-transparent hover:bg-gray-100 text-center rounded-sm disabled:opacity-50 disabled:cursor-not-allowed " +
        className
      }
    >
      <span className="glade-ash">{title ?? "Title"}</span>
    </GlassButtonStyle>
  );
}

import React, { useEffect, useState } from "react";
import * as dfn from "date-fns";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FiCopy } from "react-icons/fi";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import {
	copyTextToClipboard,
	fixDateForAllBrowsers,
	statusEnum,
	truncateRef,
} from "../../helpers/helper";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import TabNav from "../../components/navbars/TabNav";
import useInflow from "../../hooks/useInflow";
import {
	getAllPosInflows,
	getAllPosInflowsForDownload,
} from "../../redux/inflow/inflowActions";
import PillIndicator from "../../components/indicators/PillIndicator";
import POSInflowSearchInput from "../../components/inputs/PosInflowSearchInput";

export default function AllPosInflows() {
	const dispatch = useDispatch();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const { isLoadingAllPosInflows, allPosInflow } = useInflow();

	const defaultFilterParams = {
		search: "",
		page: currentPage,
		// status: qs.parse(location.search)?.search ?? "",
		date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 12), "yyyy-MM-dd"),
		date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd"),
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { date_from, date_to, status, search, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_from, date_to, search, status]);

	React.useEffect(() => {
		dispatch(
			getAllPosInflows({
				params: {
					...(search
						? { search, paginate: 1, page, per_page: 10 }
						: {
								date_from,
								date_to,
								paginate: 1,
								page,
								per_page: 10,
								// status,
						  }),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// currency,
		date_from,
		date_to,
		page,
		// per_page,
		search,
		// status,
	]);

	// Clear query param on render
	// useEffect(() => {
	//   urlSearchParamsHistory.replace({});
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	React.useEffect(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	return (
		<div className="p-3">
			<TabNav
				tabs={[
					{ title: "POS", path: "/dashboard/inflow" },
					{ title: "Checkout", path: "/dashboard/inflow/checkout" },
					{ title: "Bank Transfers", path: "/dashboard/inflow/transfers" },
				]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				defaultFilterParams={defaultFilterParams}
				filterFields={["date"]}
				filterCallback={handleChange}
				disabled={isLoadingAllPosInflows}
				fileName="Pos Inflows"
				downloadAsCSV
				CSVCallback={(handleDownload) =>
					dispatch(
						getAllPosInflowsForDownload(
							{
								params: {
									...(search
										? { search, paginate: 0, page, per_page: 10 }
										: {
												date_from,
												date_to,
												paginate: 0,
												page,
												per_page: 10,
												// status,
										  }),
								},
							},
							(data) =>
								handleDownload({
									data,
									fileName: "POS Inflows",
									format: (value, i) => ({
										"S/N": `${i + 1}`,
										"Transaction Status": value?.txn_status,
										"Account Name": value?.account_name,
										"Terminal ID": value?.terminal_id,
										RRN: value?.rrn,
										Amount: value?.value,
										"Transaction Date": value?.created_at,
									}),
								})
						)
					)
				}
				noSearch
				customSearchInput={
					<POSInflowSearchInput
						onChange={handleChange}
						defaultFilterParams={defaultFilterParams}
					/>
				}
				title={`Transactions ${
					!isLoadingAllPosInflows && allPosInflow?.total
						? "- " + allPosInflow?.total
						: ""
				}`}
				searchBarPlaceholder={"Account Name or RRN"}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof allPosInflow === "object" && !Array.isArray(allPosInflow)
						? allPosInflow?.data
						: allPosInflow?.data?.reverse()
				}
				empty_message="No inflow"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllPosInflows}
				setCurrentPage={setCurrentPage}
				totalPages={allPosInflow?.total}
			/>
		</div>
	);
}

const columns = [
	{
		accessor: "txn_status",
		Header: "Status",
		style: {
			paddingLeft: 0,
			paddingRight: "10px",
			width: "50px",
		},
		Cell: ({ value }) => (
			<React.Fragment>
				<PillIndicator
					className="py-1 mx-auto capitalize"
					type={statusEnum[value]}
					title={value}
				/>
			</React.Fragment>
		),
	},
	{
		accessor: "account_name",
		Header: "Account Name",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/inflow/pos/detail/${row.original.txn_ref}`}>
				<span className="glade-normal-text-two capitalize">{value}</span>
			</Link>
		),
	},
	{
		accessor: "terminal_id",
		Header: "Terminal ID",
		Cell: ({ value, row }) => (
			<>
				{value ? (
					<Link to={`/dashboard/inflow/pos/detail/${row.original.txn_ref}`}>
						<span className="glade-normal-text-two capitalize">{value}</span>
					</Link>
				) : (
					<Link to={`/dashboard/inflow/pos/detail/${row.original.txn_ref}`}>
						<span className="glade-normal-text-two text-center">
							Not available
						</span>
					</Link>
				)}
			</>
		),
	},
	{
		accessor: "txn_ref",
		Header: "Transaction Reference",
		Cell: ({ value, row }) => (
			<>
				{value ? (
					<div className="flex items-center">
						<Link to={`/dashboard/inflow/pos/detail/${row.original.txn_ref}`}>
							<span className="glade-normal-text-two">
								{truncateRef(value ?? "")}
							</span>
						</Link>
						<FiCopy
							size={16}
							color="#AFAFAF"
							className="ml-3 cursor-pointer"
							onClick={() => copyTextToClipboard(value)}
						/>
					</div>
				) : (
					<Link to={`/dashboard/inflow/pos/detail/${row.original.txn_ref}`}>
						<span className="glade-normal-text-two text-center">
							Not available
						</span>
					</Link>
				)}
			</>
		),
	},
	{
		accessor: "rrn",
		Header: "RRN",
		Cell: ({ value, row }) => (
			<>
				{value ? (
					<div className="flex items-center">
						<Link to={`/dashboard/inflow/pos/detail/${row.original.txn_ref}`}>
							<span className="glade-normal-text-two">
								{truncateRef(value ?? "")}
							</span>
						</Link>
						<FiCopy
							size={16}
							color="#AFAFAF"
							className="ml-3 cursor-pointer"
							onClick={() => copyTextToClipboard(value)}
						/>
					</div>
				) : (
					<Link to={`/dashboard/inflow/pos/detail/${row.original.txn_ref}`}>
						<span className="glade-normal-text-two text-center">
							Not available
						</span>
					</Link>
				)}
			</>
		),
	},
	{
		accessor: "value",
		Header: "Amount",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/inflow/pos/detail/${row.original.txn_ref}`}>
				<span className="glade-normal-text-two">
					{row.original.currency} {Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
				</span>
			</Link>
		),
	},
	{
		accessor: "created_at",
		Header: "Transaction Date",
		Cell: ({ value, row }) => (
			<Link to={`/dashboard/inflow/pos/detail/${row.original.txn_ref}`}>
				<span className="glade-normal-text-two">
					{dfn.format(
						new Date(fixDateForAllBrowsers(value)),
						"MMM d, yyyy hh:mm:ss a"
					)}
				</span>
			</Link>
		),
	},
];

import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdOutlineClose } from "react-icons/md";

export default React.forwardRef(function BasicModal(
	{
		visibility = false,
		primaryButton,
		primaryButtonStyle,
		primaryButtonClassName,
		primaryButtonTitle,
		secondaryButton,
		secondaryButtonStyle,
		secondaryButtonClassName,
		secondaryButtonTitle,
		modalTitle,
		modalTitleSection,
		modelContent,
		modelContentSection,
		modalStyle,
		onClose,
		onOpen,
		titleClass = false,
		overlayStyle,
	},
	ref
) {
	// Ref handler
	React.useImperativeHandle(ref, () => ({
		open: () => openModal(),
		close: () => closeModal(),
		toggle: () => toggleModal(),
		// anotherMethodOfChoice : () => {}
	}));

	let [isOpen, setIsOpen] = React.useState(visibility);

	function closeModal() {
		setIsOpen(false);
		onClose()
	}

	function openModal() {
		setIsOpen(true);
		onOpen()
	}

	function toggleModal() {
		setIsOpen(!visibility);
	}

	return (
		<React.Fragment>
			<div className="">
				{primaryButton ?? (
					<button
						type="button"
						style={primaryButtonStyle}
						onClick={openModal}
						className={
							"glade-normal-text-three glade-button-blue w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
							primaryButtonClassName
						}
					>
						{primaryButtonTitle ?? "Title"}
					</button>
				)}
			</div>

			<Transition appear show={visibility || isOpen} as={React.Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closeModal}
				>
					<Dialog.Overlay
						className="fixed inset-0 bg-black opacity-30"
						style={overlayStyle}
					/>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div
								className="inline-block w-full max-w-md px-6 py-8 text-left align-middle transition-all transform bg-white shadow-xl rounded"
								style={modalStyle}
							>
								{modalTitleSection ?? (
									<div className="flex items-center justify-between mb-4 pb-4 px-4 border-b border-solid border-gray-200 -mx-5">
										<Dialog.Title
											as="h4"
											className={!titleClass ? "glade-normal-text-two glade-black glade-bold capitalize " : "font-bold text-glade-black-800"}
										>
											{modalTitle ?? "Modal Title"}
										</Dialog.Title>
										<MdOutlineClose
											color="#AFAFAF"
											onClick={closeModal}
											className="cursor-pointer"
										/>
									</div>
								)}

								{modelContentSection ?? (
									<div className="mt-2">
										<p className="text-sm text-gray-500">
											<Dialog.Description>
												{modelContent ?? "Modal Content"}
											</Dialog.Description>
										</p>
									</div>
								)}

								{secondaryButton ?? (
									<div className="mt-4">
										<button
											type="button"
											style={secondaryButtonStyle}
											onClick={onClose || closeModal}
											className={
												"glade-normal-text-three glade-button-blue w-full py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
												secondaryButtonClassName
											}
										>
											{secondaryButtonTitle ?? "Got it, thanks!"}
										</button>
									</div>
								)}
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</React.Fragment>
	);
});

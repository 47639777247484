import React from 'react';

export default function PillIndicator({ type, title, className, style = {}, onClick }) {
    return (
        <div onClick={onClick}>
            {type === 'success' ?
                <span
                    className={"px-6 inline-flex justify-center rounded-md glade-normal-text-two glade-bg-green-100 glade-green " + className}
                    style={style}>
                    {title ?? 'Title'}
                </span>
                : null}
            {type === 'warning' ?
                <span
                    className={"px-2 inline-flex justify-center rounded-md glade-normal-text-two glade-bg-yellow-100 glade-yellow " + className}
                    style={style}>
                    {title ?? 'Title'}
                </span>
                : null}
            {type === 'neutral' ?
                <span
                    className={"px-2 inline-flex justify-center rounded-full glade-normal-text-two glade-bg-black-100 glade-black " + className}
                    style={style}>
                    {title ?? 'Title'}
                </span>
                : null}
            {type === 'inactive' ?
                <span
                    className={"px-4 inline-flex justify-center rounded-full glade-normal-text-two glade-bg-black-100 glade-black " + className}
                    style={style}>
                    {title ?? 'Title'}
                </span>
                : null}
            {type === 'danger' ?
                <span
                    className={"px-2 inline-flex justify-center rounded-md glade-normal-text-two glade-bg-red-100 glade-red " + className}
                    style={style}>
                    {title ?? 'Title'}
                </span>
                : null}
        </div>
    );
}

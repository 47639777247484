import React, { useEffect, useState } from "react";
import { PaginatedTable } from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "query-string";

import { CardTableHeader } from "../../components/cards/CardTableHeader";
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import { getAllFees } from "../../redux/fees/feesActions";
import useFees from "../../hooks/useFees";
import EditFeeModal from "./EditFeeModal";
import TabNav from "../../components/navbars/TabNav";

const AllFees = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const [selectedFee, setSelectedFee] = useState(null);
	const [modalStates, setModalStates] = useState({
		showEditFeeModal: false,
	});

	const [currentPage, setCurrentPage] = useState(
		qs.parse(location.search)?.page ?? 1
	);

	const { isLoadingAllFees, allFees } = useFees();

	const defaultFilterParams = {
		search: "",
		page: currentPage,
	};

	const filterInstance = usePaginationFilterParams({
		initialFilters: defaultFilterParams,
	});

	const { filters, handleChange, urlSearchParamsHistory } = filterInstance;

	const { search, page } = filters;

	const [filterValue, setFilterValue] = React.useState(filters);

	useEffect(() => {
		setCurrentPage(1);
		setFilterValue(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	React.useMemo(() => {
		dispatch(
			getAllFees({
				params: {
					...(search
						? { search, paginate: 1, current_page: page, per_page: 10 }
						: {
							paginate: 1,
							current_page: page,
							per_page: 10,
						}),
				},
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, search]);

	// // Clear query param on render
	// useEffect(() => {
	//   urlSearchParamsHistory.replace({});
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	React.useMemo(() => {
		handleChange({ ...filterValue, page: currentPage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const resetReferenceSearch = (event) => {
		if (typeof event?.preventDefault === "function") {
			event?.preventDefault();
		}
		urlSearchParamsHistory.replace(defaultFilterParams);
	};

	// Open Edit Modal
	const openEditModal = (fee) => {
		setSelectedFee(fee);
		setModalStates({
			...modalStates,
			showEditFeeModal: true,
		});
	};

	const columns = [
		{
			accessor: "display_name",
			Header: "Name",
			Cell: ({ value, row }) => (
				<span className="glade-normal-text-two capitalize">
					{value}
				</span>
			),
		},
		{
			accessor: "fee_fixed",
			Header: "Fixed Fee",
			Cell: ({ value, row }) => (
				<div className="flex items-center">
					{value ? (
						<span className="glade-normal-text-two">
							{row.original.currency ?? "NGN"}{" "}
							{Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					) : (
						<span className="glade-normal-text-two">-</span>
					)}
				</div>
			),
		},
		{
			accessor: "fee_percentage",
			Header: "Percentage Fee",
			Cell: ({ value, row }) => (
				<div className="flex items-center">
					{value ? (
						<span className="glade-normal-text-two">
							{Number(value)?.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
					) : (
						<span className="glade-normal-text-two">-</span>
					)}
				</div>
			),
		},
		{
			accessor: "id",
			Header: "",
			Cell: ({ value, row }) => (
				<div className="flex items-center justify-center">
					<span
						className="glade-blue-normal glade-normal-text-two cursor-pointer"
						onClick={() => openEditModal(row.original)}
					>
						Edit
					</span>
				</div>
			),
		},
	];

	return (
		<div className="p-3">
			<TabNav tabs={[
				{
					title: "Fees",
					path: "/dashboard/fees"
				},
				{
					title: "Discounts ",
					path: "/dashboard/discounts"
				},
				{
					title: "Virtual Card Settings",
					path: "/dashboard/virtual-card/settings",
				},
				{
					title: "Checkout",
					path: "/dashboard/fees/checkout",
				}
			]}
			/>
			<CardTableHeader
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						handleChange({ search: value.trim() });
					} else {
						resetReferenceSearch();
					}
				}}
				filterButton=""
				defaultFilterParams={defaultFilterParams}
				filterCallback={handleChange}
				disabled={isLoadingAllFees}
				title="Fees"
				searchBarPlaceholder={"Fee Name"}
			/>
			<PaginatedTable
				columns={columns}
				data={
					typeof allFees?.data === "object" && !Array.isArray(allFees?.data)
						? allFees?.data?.data
						: allFees?.data
				}
				empty_message="No fees"
				empty_sub_message=""
				current_page={currentPage}
				loading={isLoadingAllFees}
				setCurrentPage={setCurrentPage}
				totalPages={allFees?.total}
			/>

			<EditFeeModal
				modalStates={modalStates}
				setModalStates={setModalStates}
				selectedFee={selectedFee}
				setSelectedFee={setSelectedFee}
				showEditFeeModal={modalStates?.showEditFeeModal}
			/>
		</div>
	);
};

export default AllFees;

import {
  GET_ALL_BUSINESS_COMPLIANCE_FAILURE,
  GET_ALL_BUSINESS_COMPLIANCE_START,
  GET_ALL_BUSINESS_COMPLIANCE_SUCCESS,
  GET_ALL_PERSONAL_COMPLIANCE_FAILURE,
  GET_ALL_PERSONAL_COMPLIANCE_START,
  GET_ALL_PERSONAL_COMPLIANCE_SUCCESS,
  GET_BUSINESS_DIRECTORS_FAILURE,
  GET_BUSINESS_DIRECTORS_START,
  GET_BUSINESS_DIRECTORS_SUCCESS,
  GET_BUSINESS_PRIMARY_OWNER_FAILURE,
  GET_BUSINESS_PRIMARY_OWNER_START,
  GET_BUSINESS_PRIMARY_OWNER_SUCCESS,
  GET_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE,
  GET_SPECIFIC_BUSINESS_COMPLIANCE_START,
  GET_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS,
  GET_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE,
  GET_SPECIFIC_PERSONAL_COMPLIANCE_START,
  GET_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS,
  UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE,
  UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_START,
  UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS,
  UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE,
  UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_START,
  UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS,
} from "./types";

const initialState = {
  isLoadingAllBusinessCompliance: true,
  isLoadingAllPersonalCompliance: true,
  isLoadingBusinessDirectors: false,
  isLoadingPrimaryBusinessOwner: false,
  isLoadingSpecificPersonalCompliance: false,
  isLoadingSpecificBusinessCompliance: false,
  isUpdatingSpecificPersonalCompliance: false,
  isUpdatingSpecificBusinessCompliance: false,
  allBusinessCompliance: [],
  allPersonalCompliance: [],
  specificPersonalCompliance: {},
  specificBusinessCompliance: {},
  businessDirectors: [],
  businessPrimaryOwner: []
};

export default function complianceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BUSINESS_DIRECTORS_START:
      return {
        ...state,
        isLoadingBusinessDirectors: true
      }

    case GET_BUSINESS_DIRECTORS_SUCCESS:
      return {
        ...state,
        isLoadingBusinessDirectors: false,
        businessDirectors: action.payLoad
      }

    case GET_BUSINESS_DIRECTORS_FAILURE:
      return {
        ...state,
        isLoadingBusinessDirectors: false,
      }

    case GET_BUSINESS_PRIMARY_OWNER_START:
      return {
        ...state,
        isLoadingPrimaryBusinessOwner: true,
      }

    case GET_BUSINESS_PRIMARY_OWNER_SUCCESS:
      return {
        ...state,
        isLoadingPrimaryBusinessOwner: false,
        businessPrimaryOwner: action.payLoad
      }

    case GET_BUSINESS_PRIMARY_OWNER_FAILURE:
      return {
        ...state,
        isLoadingPrimaryBusinessOwner: false,
      }

    case GET_ALL_BUSINESS_COMPLIANCE_START:
      return {
        ...state,
        isLoadingAllBusinessCompliance: true,
      };

    case GET_ALL_BUSINESS_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isLoadingAllBusinessCompliance: false,
        allBusinessCompliance: action.payLoad,
      };

    case GET_ALL_BUSINESS_COMPLIANCE_FAILURE:
      return {
        ...state,
        isLoadingAllBusinessCompliance: false,
      };

    case GET_ALL_PERSONAL_COMPLIANCE_START:
      return {
        ...state,
        isLoadingAllPersonalCompliance: true,
      };

    case GET_ALL_PERSONAL_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isLoadingAllPersonalCompliance: false,
        allPersonalCompliance: action.payLoad,
      };

    case GET_ALL_PERSONAL_COMPLIANCE_FAILURE:
      return {
        ...state,
        isLoadingAllPersonalCompliance: false,
      };

    case GET_SPECIFIC_PERSONAL_COMPLIANCE_START:
      return {
        ...state,
        isLoadingSpecificPersonalCompliance: true,
      };

    case GET_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isLoadingSpecificPersonalCompliance: false,
        specificPersonalCompliance: action.payLoad,
      };

    case GET_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE:
      return {
        ...state,
        isLoadingSpecificPersonalCompliance: false,
      };

    case GET_SPECIFIC_BUSINESS_COMPLIANCE_START:
      return {
        ...state,
        isLoadingSpecificBusinessCompliance: true,
      };

    case GET_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isLoadingSpecificBusinessCompliance: false,
        specificBusinessCompliance: action.payLoad,
      };

    case GET_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE:
      return {
        ...state,
        isLoadingSpecificBusinessCompliance: false,
      };

    case UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_START:
      return {
        ...state,
        isUpdatingSpecificPersonalCompliance: true,
      };

    case UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isUpdatingSpecificPersonalCompliance: false,
      };

    case UPDATE_SPECIFIC_PERSONAL_COMPLIANCE_FAILURE:
      return {
        ...state,
        isUpdatingSpecificPersonalCompliance: false,
      };

    case UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_START:
      return {
        ...state,
        isUpdatingSpecificBusinessCompliance: true,
      };

    case UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isUpdatingSpecificBusinessCompliance: false,
      };

    case UPDATE_SPECIFIC_BUSINESS_COMPLIANCE_FAILURE:
      return {
        ...state,
        isUpdatingSpecificBusinessCompliance: false,
      };

    default:
      return state;
  }
}

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import {currencySymbolEnum} from "../../helpers/helper";
import {
  addCheckoutCurrency,
  getCheckoutFees,
} from "../../redux/fees/feesActions";
import useFees from "../../hooks/useFees";
import SelectInput from "../../components/inputs/SelectInput";
import TextInput from "../../components/inputs/TextInput";
import NumericInput from "../../components/inputs/NumericInput";

const AddCheckoutModal = ({
  modalStates,
  setModalStates,
  setSelectedFee,
  currency_code,
  currency_name,
}) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState({
    currency_name: currency_name,
    currency_code: currency_code,
    checkout_option: "",
    percentage: "",
    cap: "",
    added: ""
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { isUpdatingFee } = useFees();

  const onUpdateFee = () => {
    dispatch(
      addCheckoutCurrency(
        { ...input, amount: input?.amount?.replaceAll(",", "") },
        () => {
          dispatch(getCheckoutFees(currency_code));
          reset();
          setModalStates({ ...modalStates, showAddCurrency: false });
          setSelectedFee({});
        }
      )
    );
  };
  const onChange = (e) => {
    if (e.target.name === "amount") {
      setInput({
        ...input,
        [e.target.name]: Number(e.target.value).toLocaleString("en-US"),
      });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };
  const validateInput = () => {
    let inValidFields = [];
    Object.keys(input).forEach((val) => {
      if (!input[val] || input[val].trim() === "") {
        inValidFields.push(val);
      }
    });
    if (inValidFields?.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <BasicModal
      visibility={modalStates.showAddCurrency}
      onClose={() => {
        reset();
        setModalStates({ ...modalStates, showAddCurrency: false });
        setSelectedFee({});
      }}
      onOpen={() =>
        setModalStates({
          ...modalStates,
          showAddCurrency: true,
        })
      }
      primaryButton=""
      modalTitle={`Configure Fees`}
      secondaryButton=""
      modelContentSection={
        <>
          <form onSubmit={handleSubmit(onUpdateFee)} className="">
            <div className="mb-4 flex flex-col gap-4">
              <TextInput
                label={"Currency Name"}
                name={"currency_name"}
                id={"currency_name"}
                type={"text"}
                placeholder="Enter Currency Name"
                value={input?.currency_name}
                onChange={onChange}
                disabled
                className="glade-bg-off-white"
              />
              <TextInput
                label={"Currency Code"}
                name={"currency_code"}
                id={"currency_code"}
                type={"text"}
                placeholder="Enter Currency Code"
                value={input?.currency_code}
                onChange={onChange}
                disabled
                className="glade-bg-off-white"
              />
              <SelectInput
                label={"Select Checkout Option"}
                name={"checkout_option"}
                id={"checkout_option"}
                value={input?.checkout_option}
                onChange={onChange}
              >
                <option>Select Checkout Option</option>
                <option value={"card"}>Card</option>
                <option value={"bank"}>Bank</option>
                <option value={"bank_transfer"}>Bank Transfer</option>
                <option value={"mobile_money"}>Mobile Money</option>
                <option value={"ussd"}>ussd</option>
              </SelectInput>
              <NumericInput
                label={"Fee Types"}
                name={"percentage"}
                id={"percentage"}
                type={"number"}
                value={input?.percentage}
                onChange={onChange}
                className={"relative"}
                leftSection ={
                  <div className="border round-md p-1 glade-bg-off-white">Percentage Fee</div>
                }
                rightSection={<div className="absolute right-8 self-center">%</div>}
              />
              <NumericInput
                name={"cap"}
                id={"cap"}
                type={"number"}
                value={input?.cap}
                onChange={onChange}
                className={"relative"}
                leftSection ={
                  <div className="border round-md p-1 glade-bg-off-white">Capped Fee</div>
                }
                rightSection={<div className="absolute right-8 self-center glade-black-800">{currencySymbolEnum[input?.currency_code]}</div>}
              />
              <NumericInput
                name={"added"}
                id={"added"}
                type={"number"}
                value={input?.added}
                onChange={onChange}
                className={"relative"}
                leftSection ={
                  <div className="border round-md p-1 glade-bg-off-white">Added Fee</div>
                }
                rightSection={<div className="absolute right-8 self-center">{currencySymbolEnum[input?.currency_code]}</div>}
              />
            </div>

            <div className="flex -mb-8 items-center justify-between p-4 border-t -mx-5">
              <button
                type="button"
                onClick={() => {
                  reset();
                  setModalStates({
                    ...modalStates,
                    showAddCurrency: false,
                  });
                  setSelectedFee({});
                }}
                className="glade-normal-text-two glade-black text-center"
              >
                Cancel
              </button>
              <div>
                <Button.Dark
                  type="submit"
                  title="Configure Fees"
                  className="block flex-grow-0"
                  loading={isUpdatingFee}
                  disabled={validateInput()}
                  onClick={onUpdateFee}
                />
              </div>
            </div>
          </form>
        </>
      }
    />
  );
};

export default AddCheckoutModal;

import React, { useMemo, useState } from "react";
import * as dfn from "date-fns";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

import { TextSkeleton } from "../../components/skeletons";
import useHome from "../../hooks/useHome";
import styled from "styled-components";
// import ActiveUsersFilter from "./ActiveUsersFilter";

const TooltipStyle = styled.div`
  background: #717485;
  border: 1px solid #717485;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;

  .label {
    color: #fbfbfb60;
  }

  .value {
    color: #f3f6f7;
  }
`;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipStyle>
        <p className="glade-small-text-one value text-center">
          {payload[0].payload.date}
        </p>
        <p className="glade-small-text-one label text-center capitalize">
          Active Users
        </p>
        <p className="glade-small-text-one value text-center">
          {payload[0].payload.value}
        </p>
      </TooltipStyle>
    );
  }
  return null;
};

const ActiveUsersReport = () => {
  const { isLoadingActiveUsersReport, activeUsersReport } = useHome();
  const { report } = activeUsersReport;

  const [allActiveUsers, setActiveUsers] = useState([]);

  useMemo(() => {
    let filtered_data = [];

    if (report) {
      report.forEach((item) => {
        let date = item?.date;
        let group = {};

        const parsedDate = dfn.parse(date, "yyyy-MM-dd", new Date());
        group.date = dfn.format(parsedDate, "MMM d");
        group.value = item.active_users;
        filtered_data.push(group);
      });
    }
    setActiveUsers(filtered_data);
  }, [report]);

  return (
    <div className="flex flex-col p-5 bg-white rounded pb-10">
      <div className="flex row justify-between mb-6 pb-1 border-b">
        <h6 className="glade-normal-text-two glade-black">Active Users</h6>

        {/* <ActiveUsersFilter /> */}
      </div>

      <div className="h-96 w-full pt-5">
        {isLoadingActiveUsersReport ? (
          <TextSkeleton className="h-full" />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={300}
              data={allActiveUsers}
              margin={{
                top: 40,
                right: 15,
                left: -5,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#4E55FD" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#148CE300" stopOpacity={0} />
                </linearGradient>
                {/* <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient> */}
              </defs>
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                stroke="#9CA3AF"
                fontSize={12}
                dy={10}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                stroke="#9CA3AF"
                dx={-10}
                domain={[0, "dataMax + 2"]}
                fontSize={5}
                tick={{ fontSize: 5 }}
              />
              {/* <Tooltip /> */}
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                stroke="#4E55FD"
                dataKey="value"
                fillOpacity={1}
                fill="url(#colorUv)"
                dot={false}
                strokeWidth={2}
                activeDot={{
                  r: 5,
                  fill: "#ffffff",
                  stroke: "#4E55FD",
                  strokeWidth: 2,
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default ActiveUsersReport;

import {
	GET_ALL_CHECKOUT_INFLOW_FAILURE,
	GET_ALL_CHECKOUT_INFLOW_START,
	GET_ALL_CHECKOUT_INFLOW_SUCCESS,
	GET_ALL_POS_INFLOW_FAILURE,
	GET_ALL_POS_INFLOW_START,
	GET_ALL_POS_INFLOW_SUCCESS,
	GET_ALL_TRANSFER_INFLOW_FAILURE,
	GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_FAILURE,
	GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_START,
	GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_SUCCESS,
	GET_ALL_TRANSFER_INFLOW_START,
	GET_ALL_TRANSFER_INFLOW_SUCCESS,
	GET_SPECIFIC_CHECKOUT_INFLOW_FAILURE,
	GET_SPECIFIC_CHECKOUT_INFLOW_START,
	GET_SPECIFIC_CHECKOUT_INFLOW_SUCCESS,
	GET_SPECIFIC_POS_INFLOW_FAILURE,
	GET_SPECIFIC_POS_INFLOW_START,
	GET_SPECIFIC_POS_INFLOW_SUCCESS,
	GET_SPECIFIC_TRANSFER_INFLOW_FAILURE,
	GET_SPECIFIC_TRANSFER_INFLOW_START,
	GET_SPECIFIC_TRANSFER_INFLOW_SUCCESS,
} from "./types";

const initialState = {
	isLoadingAllPosInflows: true,
	isLoadingSpecificPosInflow: true,
	isLoadingAllCheckoutInflows: true,
	isLoadingSpecificCheckoutInflow: true,
	isLoadingAllTransfersInflows: true,
	isLoadingAllTransfersInflowsForDownload: true,
	isLoadingSpecificTransferInflow: true,
	allPosInflow: [],
	allCheckoutInflows: [],
	allTransfersInflows: [],
	allTransfersInflowsForDownload: [],
	specificPosInflow: {},
	specificCheckoutInflow: {},
	specificTransferInflow: {},
};

export default function inflowReducer(state = initialState, action) {
	switch (action.type) {
		// GET ALL POS INFLOW
		case GET_ALL_POS_INFLOW_START:
			return {
				...state,
				isLoadingAllPosInflows: true,
			};

		case GET_ALL_POS_INFLOW_SUCCESS:
			return {
				...state,
				isLoadingAllPosInflows: false,
				allPosInflow: action.payLoad,
			};

		case GET_ALL_POS_INFLOW_FAILURE:
			return {
				...state,
				isLoadingAllPosInflows: false,
			};

		// Get specific pos inflow
		case GET_SPECIFIC_POS_INFLOW_START:
			return {
				...state,
				isLoadingSpecificPosInflow: true,
			};

		case GET_SPECIFIC_POS_INFLOW_SUCCESS:
			return {
				...state,
				isLoadingSpecificPosInflow: false,
				specificPosInflow: action.payLoad,
			};

		case GET_SPECIFIC_POS_INFLOW_FAILURE:
			return {
				...state,
				isLoadingSpecificPosInflow: false,
			};

		// GET ALL CHECKOUT INFLOWS
		case GET_ALL_CHECKOUT_INFLOW_START:
			return {
				...state,
				isLoadingAllCheckoutInflows: true,
			};

		case GET_ALL_CHECKOUT_INFLOW_SUCCESS:
			return {
				...state,
				isLoadingAllCheckoutInflows: false,
				allCheckoutInflows: action.payLoad,
			};

		case GET_ALL_CHECKOUT_INFLOW_FAILURE:
			return {
				...state,
				isLoadingAllCheckoutInflows: false,
			};

		// Get specific checkout inflow
		case GET_SPECIFIC_CHECKOUT_INFLOW_START:
			return {
				...state,
				isLoadingSpecificCheckoutInflow: true,
			};

		case GET_SPECIFIC_CHECKOUT_INFLOW_SUCCESS:
			return {
				...state,
				isLoadingSpecificCheckoutInflow: false,
				specificCheckoutInflow: action.payLoad,
			};

		case GET_SPECIFIC_CHECKOUT_INFLOW_FAILURE:
			return {
				...state,
				isLoadingSpecificCheckoutInflow: false,
				specificCheckoutInflow: {},
			};

		// GET ALL TRANSFERS INFLOWS
		case GET_ALL_TRANSFER_INFLOW_START:
			return {
				...state,
				isLoadingAllTransfersInflows: true,
			};

		case GET_ALL_TRANSFER_INFLOW_SUCCESS:
			return {
				...state,
				isLoadingAllTransfersInflows: false,
				allTransfersInflows: action.payLoad,
			};

		case GET_ALL_TRANSFER_INFLOW_FAILURE:
			return {
				...state,
				isLoadingAllTransfersInflows: false,
			};

		// GET ALL TRANSFERS INFLOWS FOR DOWNLOAD
		case GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_START:
			return {
				...state,
				isLoadingAllTransfersInflowsForDownload: true,
			};

		case GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_SUCCESS:
			return {
				...state,
				isLoadingAllTransfersInflowsForDownload: false,
				allTransfersInflowsForDownload: action.payLoad,
			};

		case GET_ALL_TRANSFER_INFLOW_FOR_DOWNLOAD_FAILURE:
			return {
				...state,
				isLoadingAllTransfersInflowsForDownload: false,
			};

		// Get specific transfer inflow
		case GET_SPECIFIC_TRANSFER_INFLOW_START:
			return {
				...state,
				isLoadingSpecificTransferInflow: true,
			};

		case GET_SPECIFIC_TRANSFER_INFLOW_SUCCESS:
			return {
				...state,
				isLoadingSpecificTransferInflow: false,
				specificTransferInflow: action.payLoad,
			};

		case GET_SPECIFIC_TRANSFER_INFLOW_FAILURE:
			return {
				...state,
				isLoadingSpecificTransferInflow: false,
			};

		default:
			return state;
	}
}

import React, { useContext } from "react";
import { SettingsContext } from "../../context/SettingsContext";
import useSettings from "../../hooks/useSettings";
import { TextSkeleton } from "../skeletons";


const InputGroup = ({ placeholder, inputValue, setting, setSettings, currency, children }) => {
  const { isLoadingVirtualCardSettings } = useSettings();

  const { setFieldUpdated } = useContext(SettingsContext);

  function updateInputVal(e) {
    if (!setting.title) {
      setSettings(({ ...setting, fee_fixed2: Number(e.target.value.split(",").join("")).toLocaleString('en-US'), fee_fixed: Number(e.target.value.split(",").join("")) }));
    } else {
      setSettings(({ ...setting, value2: Number(e.target.value.split(",").join("")).toLocaleString('en-US'), value: Number(e.target.value.split(",").join("")) }));
    }
    setFieldUpdated(false);
  };

  return (
    <div className="flex flex-col mb-6">
      {children}
      {isLoadingVirtualCardSettings ? (
        <TextSkeleton />
      ) : (
        <div className="rounded-md mt-2 border hover:bg-transparent border-slate-100 flex items-center pl-2 outline-slate-200">
          <span>
            {currency === "dollar" ? "USD" : "NGN"}
          </span>
          <input
            type="text"
            placeholder={placeholder}
            className="rounded-md ml-4 w-full border-transparent bg-transparent"
            value={String(inputValue)}
            onChange={updateInputVal}
          />
        </div>
      )}
    </div>
  );
};

export default InputGroup;
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useDispatch } from "react-redux";
import Button from '../../components/buttons/Button';
import { SettingsTableHeaderPlain } from '../../components/cards/CardTableHeader';
import InputGroup from '../../components/inputs/InputGroup';
import TabNav from '../../components/navbars/TabNav';
import useSettings from '../../hooks/useSettings';
import { SettingsContext } from "../../context/SettingsContext";
import { getAllSettings, updateVirtualCardSettings, updateVirtualCardMonthlyFee } from '../../redux/settings/settingsActions';
import { RiLoader5Fill } from 'react-icons/ri';
import { isEmptyArray } from '../../helpers/helper';

const VirtualCardSettings = () => {
  const [exchangeRate, setExchangeRate] = useState(0); // Ensure if kobo is .00 it shows
  const [minimumFundingAmountUSD, setMinimumFundingAmountUSD] = useState(null);
  const [maximumFundingAmountUSD, setMaximumFundingAmountUSD] = useState(0);
  const [minimumFundingAmountNGN, setMinimumFundingAmountNGN] = useState(0);
  const [maximumFundingAmountNGN, setMaximumFundingAmountNGN] = useState(0);
  const [cardCreationRate, setCardCreationRate] = useState(0);
  const [cardCreationFeeUSD, setCardCreationFeeUSD] = useState(0);
  const [cardCreationFeeNGN, setCardCreationFeeNGN] = useState(0);
  const [cardTerminationRate, setCardTerminationRate] = useState(0);
  const [cardTerminationFeeUSD, setCardTerminationFeeUSD] = useState(0);
  const [cardTerminationFeeNGN, setCardTerminationFeeNGN] = useState(0);
  const [monthlyFeeActiveCardUSD, setMonthlyFeeActiveCardUSD] = useState(0);
  const [monthlyFeeActiveCardNGN, setMonthlyFeeActiveCardNGN] = useState(0);
  const dispatch = useDispatch();
  const {
    virtualCardSettings,
    isUpdatingVirtualCardSettings,
    isLoadingVirtualCardSettings
  } = useSettings();
  const { fieldUpdated } = useContext(SettingsContext);

  useMemo(() => {
    const { flutterwaveRate, configs, fees } = virtualCardSettings;
    setExchangeRate(flutterwaveRate);
    if (!isLoadingVirtualCardSettings && !isEmptyArray(virtualCardSettings?.configs) && virtualCardSettings?.configs) {
      setMinimumFundingAmountUSD({ ...configs[2], value2: parseValue(configs[2].value) });
      setMaximumFundingAmountUSD({ ...configs[3], value2: parseValue(configs[3].value) });
      setMinimumFundingAmountNGN({ ...configs[0], value2: parseValue(configs[0].value) });
      setMaximumFundingAmountNGN({ ...configs[1], value2: parseValue(configs[1].value) });
      setCardCreationRate({ ...configs[4], value2: parseValue(configs[4].value) });
      setCardCreationFeeUSD({ ...fees[0], fee_fixed2: parseValue(fees[0].fee_fixed) });
      setCardCreationFeeNGN({ ...fees[2], fee_fixed2: parseValue(fees[2].fee_fixed) });
      setCardTerminationRate({ ...configs[5], value2: parseValue(configs[5].value) });
      setCardTerminationFeeUSD({ ...fees[1], fee_fixed2: parseValue(fees[1].fee_fixed) });
      setCardTerminationFeeNGN({ ...fees[3], fee_fixed2: parseValue(fees[3].fee_fixed) });
      setMonthlyFeeActiveCardUSD({ ...configs[6], value2: parseValue(configs[6].value) });
      setMonthlyFeeActiveCardNGN({ ...configs[7], value2: parseValue(configs[7].value) });
    }

  }, [virtualCardSettings, isLoadingVirtualCardSettings]);

  useEffect(() => {
    dispatch(getAllSettings());
  }, []);

  function parseValue(value) {
    return Number(value.split(",").join("")).toLocaleString('en-US');
  }

  function onSaveSettings() {
    const updatedSettings = {
      configs: [
        (minimumFundingAmountUSD),
        (maximumFundingAmountNGN),
        (maximumFundingAmountUSD),
        (minimumFundingAmountNGN),
        (cardCreationRate),
        (cardTerminationRate),
      ],
      fees: [
        (cardCreationFeeUSD),
        (cardCreationFeeNGN),
        (cardTerminationFeeUSD),
        (cardTerminationFeeNGN),
      ]
    };
    const updatedMonthlyFee = {
      configs: [
        (monthlyFeeActiveCardUSD),
        (monthlyFeeActiveCardNGN),
      ]
    };
    dispatch(updateVirtualCardSettings(updatedSettings));
    dispatch(updateVirtualCardMonthlyFee(updatedMonthlyFee));
  }

  if (isLoadingVirtualCardSettings) {
    return (
      <React.Fragment>
        <RiLoader5Fill size={24} className="animate-spin ml-4" />
      </React.Fragment>
    );
  }

  return (
    <div className='p-3'>
      <TabNav tabs={[
        {
          title: "Fees",
          path: "/dashboard/fees"
        },
        {
          title: "Discounts ",
          path: "/dashboard/discounts"
        },
        {
          title: "Virtual Card Settings",
          path: "/dashboard/virtual-card/settings",
        },
				{
					title: "Checkout",
					path: "/dashboard/fees/checkout",
				}
      ]} />
      <SettingsTableHeaderPlain
        title={
          <React.Fragment>
            <h1>Provider's Rate <strong>1 USD = NGN {exchangeRate}</strong></h1>
          </React.Fragment>}
        noShadow={true}
        rightButton={<Button.Dark
          title={"Save Changes"}
          loading={isUpdatingVirtualCardSettings}
          disabled={fieldUpdated}
          onClick={onSaveSettings}
          className="p-6 w-80 right-0 bg-white shadow-md top-12 rounded"
        />}
      />
      <div className='m-0 bg-white flex gap-8 justify-center px-24 py-6'>
        <div className='w-full'>
          <InputGroup
            label={"minimumFundingAmountDollar"}
            setting={minimumFundingAmountUSD}
            currency={minimumFundingAmountUSD?.type}
            inputValue={minimumFundingAmountUSD?.value2}
            setSettings={setMinimumFundingAmountUSD}
          >
            <label htmlFor="minimumFundingAmountDollar">
              Minimum Funding Amount <strong>[Dollar]</strong>
            </label>
          </InputGroup>

          <InputGroup
            label={"maximumFundingAmountDollar"}
            setting={maximumFundingAmountUSD}
            currency={maximumFundingAmountUSD?.type}
            inputValue={maximumFundingAmountUSD?.value2}
            setSettings={setMaximumFundingAmountUSD}
          >
            <label htmlFor="maximumFundingAmountDollar">
              Maximum Funding Amount <strong>[Dollar]</strong>
            </label>
          </InputGroup>

          <InputGroup
            label={"cardCreationRateUSD"}
            setting={cardCreationRate}
            currency={cardCreationRate?.type}
            inputValue={cardCreationRate?.value2}
            setSettings={setCardCreationRate}
          >
            <label htmlFor="cardCreationRateUSD">
              Card Creation USD Rate per naira
            </label>
          </InputGroup>

          <InputGroup
            label={"cardCreationFeeUSD"}
            setting={cardCreationFeeUSD}
            currency={cardCreationFeeUSD?.type}
            inputValue={cardCreationFeeUSD?.fee_fixed2}
            setSettings={setCardCreationFeeUSD}
          >
            <label htmlFor="cardCreationFeeUSD">Card Creation Fee<strong>[Dollar]</strong></label>
          </InputGroup>


          <InputGroup
            label={"cardTerminationRateUSD"}
            setting={cardTerminationRate}
            currency={cardTerminationRate?.type}
            inputValue={cardTerminationRate?.value2}
            setSettings={setCardTerminationRate}
          >
            <label htmlFor="cardTerminationRateUSD">
              Card Termination USD Rate per naira
            </label>
          </InputGroup>

          <InputGroup
            setting={cardTerminationFeeUSD}
            currency={cardTerminationFeeUSD?.type}
            inputValue={cardTerminationFeeUSD?.fee_fixed2}
            setSettings={setCardTerminationFeeUSD}
          >
            <label htmlFor="cardTerminationFee">
              Card Termination Fee<strong>[Dollar]</strong>
            </label>
          </InputGroup>

          <InputGroup
            setting={monthlyFeeActiveCardUSD}
            currency={monthlyFeeActiveCardUSD?.type}
            inputValue={monthlyFeeActiveCardUSD?.value2}
            setSettings={setMonthlyFeeActiveCardUSD}
          >
            <label htmlFor="monthlyFeeActiveCardUSD">
              Monthly Fee per active card <strong>[Dollar]</strong>
            </label>
          </InputGroup>
        </div>

        {/* Second Half of screen */}
        <div className='w-full'>
          <InputGroup
            setting={minimumFundingAmountNGN}
            currency={minimumFundingAmountNGN?.type}
            inputValue={minimumFundingAmountNGN?.value2}
            setSettings={setMinimumFundingAmountNGN}
          >
            <label htmlFor="minimumFundingAmountNGN">
              Minimum Funding Amount<strong>[Naira]</strong>
            </label>
          </InputGroup>

          <InputGroup
            setting={maximumFundingAmountNGN}
            currency={maximumFundingAmountNGN?.type}
            inputValue={maximumFundingAmountNGN?.value2}
            setSettings={setMaximumFundingAmountNGN}
          >
            <label htmlFor="maximumFundingAmountNGN">
              Maximum Funding Amount<strong>[Naira]</strong>
            </label>
          </InputGroup>

          <InputGroup
            setting={cardCreationFeeNGN}
            currency={cardCreationFeeNGN?.type}
            inputValue={cardCreationFeeNGN?.fee_fixed2}
            setSettings={setCardCreationFeeNGN}
          >
            <label htmlFor="cardCreationFeeNGN">
              Card Creation Fee<strong>[Naira]</strong>
            </label>
          </InputGroup>

          <InputGroup
            setting={cardTerminationFeeNGN}
            currency={cardTerminationFeeNGN?.type}
            inputValue={cardTerminationFeeNGN?.fee_fixed2}
            setSettings={setCardTerminationFeeNGN}
          >
            <label htmlFor="cardTerminationFeeNGN">
              Card Termination Fee<strong>[Naira]</strong>
            </label>
          </InputGroup>

          <InputGroup
            setting={monthlyFeeActiveCardNGN}
            currency={monthlyFeeActiveCardNGN?.type}
            inputValue={monthlyFeeActiveCardNGN?.value2}
            setSettings={setMonthlyFeeActiveCardNGN}
          >
            <label htmlFor="monthlyFeeActiveCardNGN">
              Monthly Fee per active card <strong>[Naira]</strong>
            </label>
          </InputGroup>
        </div>

      </div>
    </div>
  );
};

export default VirtualCardSettings;
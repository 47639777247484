import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import {
    GET_ALL_PROVIDERS_FAILURE,
    GET_ALL_PROVIDERS_SUCCESS,
    GET_ALL_PROVIDERS_START,

    UPDATE_PROVIDER_STATUS_START,
    UPDATE_PROVIDER_STATUS_SUCCESS,
    UPDATE_PROVIDER_STATUS_FAILURE,

    SEARCH_PROVIDER_STATUS_START,
    SEARCH_PROVIDER_STATUS_SUCCESS,
    SEARCH_PROVIDER_STATUS_FAILURE,

    GET_PROVIDERS_CATEGORY_SUCCESS,
    GET_PROVIDERS_CATEGORY_START,
    GET_PROVIDERS_CATEGORY_FAILURE,

    SEARCH_PROVIDERS_CATEGORY_SUCCESS,
    SEARCH_PROVIDERS_CATEGORY_START,
    SEARCH_PROVIDERS_CATEGORY_FAILURE
} from './types';

export const getProvidersCategories = (type) => {
    return (dispatch) => {
        dispatch({ type: GET_PROVIDERS_CATEGORY_START });

        Axios.get(`/settings/providers/types/${type}/category`)
            .then((res) => {
                dispatch({
                    type: GET_PROVIDERS_CATEGORY_SUCCESS,
                    payload: res.data.data,
                });
                console.log(res);
            })
            .catch((error) => {
                dispatch({ type: GET_PROVIDERS_CATEGORY_FAILURE, payload: error });
                handler(error, true);
            });
    };
};

export const searchProvidersCategories = (type, searchTerm) => {
    return (dispatch) => {
        dispatch({ type: SEARCH_PROVIDERS_CATEGORY_START });

        Axios.get(`/settings/providers/types/${type}/category?search=${searchTerm}`)
            .then((res) => {
                dispatch({
                    type: SEARCH_PROVIDERS_CATEGORY_SUCCESS,
                    payload: res.data.data,
                });
                console.log(res);
            })
            .catch((error) => {
                dispatch({ type: SEARCH_PROVIDERS_CATEGORY_FAILURE, payload: error });
                handler(error, true);
            });
    };
};

export const getAllProviders = (type) => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_PROVIDERS_START });

        Axios.get(`/settings/providers/${type}`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_PROVIDERS_SUCCESS,
                    payLoad: res.data.data,
                });
            })
            .catch((error) => {
                dispatch({ type: GET_ALL_PROVIDERS_FAILURE, payLoad: error });
                handler(error, true);
            });
    };
};

export const updateProviderStatus = (type, id) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_PROVIDER_STATUS_START });

        Axios.get(`/settings/providers/${type}/${id}/status`)
            .then((res) => {
                dispatch({
                    type: UPDATE_PROVIDER_STATUS_SUCCESS,
                    payLoad: res.data.data,
                });
            })
            .catch((error) => {
                dispatch({ type: UPDATE_PROVIDER_STATUS_FAILURE, payLoad: error });
                handler(error, true);
            });
    };
};

export const searchProviderWithCategory = (type, category, searchTerm) => {
    return (dispatch) => {
        dispatch({ type: SEARCH_PROVIDER_STATUS_START });

        Axios.get(`/settings/providers/${type}/${category}?search=${searchTerm}`)
            .then((res) => {
                dispatch({
                    type: SEARCH_PROVIDER_STATUS_SUCCESS,
                    payLoad: res.data.data,
                    category: category 
                });
            })
            .catch((error) => {
                dispatch({ type: SEARCH_PROVIDER_STATUS_FAILURE, payLoad: error });
                handler(error, true);
            });
    };
};

export const searchProviderWithoutCategory = (type, searchTerm) => {
    return (dispatch) => {
        dispatch({ type: SEARCH_PROVIDER_STATUS_START });

        Axios.get(`/settings/providers/${type}?search=${searchTerm}`)
            .then((res) => {
                dispatch({
                    type: SEARCH_PROVIDER_STATUS_SUCCESS,
                    payLoad: res.data.data,
                });
            })
            .catch((error) => {
                dispatch({ type: SEARCH_PROVIDER_STATUS_FAILURE, payLoad: error });
                handler(error, true);
            });
    };
};
import { useMemo, useEffect, useState } from "react";
import { PaginatedTable } from "../../components/table/Table";
import { CardTableHeader } from "../../components/cards/CardTableHeader";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllAuditLogs, getSpecificAuditLog } from "../../redux/audit/auditActions";
import { getAllAdminUsers } from "../../redux/filters/filterActions";
import * as dfn from 'date-fns';
import qs from 'query-string';
import usePaginationFilterParams from "../../hooks/usePaginationFilterParams";
import useAuditLogs from "../../hooks/useAuditLogs";
import { fixDateForAllBrowsers } from "../../helpers/helper";

export default function Logs() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page ?? 1
  );

  const defaultFilterParams = {
    search: "",
    page: currentPage,
    user: "all",
    date_from: dfn.lightFormat(dfn.subWeeks(new Date(), 150), "yyyy-MM-dd"),
    date_to: dfn.lightFormat(new Date(), "yyyy-MM-dd")
  };

  const filterInstance = usePaginationFilterParams({
    initialFilters: defaultFilterParams
  });

  const { allAuditLogs, isLoadingAuditLogs } = useAuditLogs();

  const { filters, handleChange } = filterInstance;

  const { date_from, date_to, user, search, page } = filters;

  const [filterValue, setFilterValue] = useState(filters);

  useEffect(() => {
    setCurrentPage(1);
    setFilterValue(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date_from, date_to, user, search]);
  // }, [])

  // To change location to be only called from Filter, 
  // ....what if user opens audit logs but does not filter content
  useEffect(() => {
    dispatch(getAllAdminUsers());
  }, []);

  useMemo(() => {
    const params = {
      params: {
        ...(search
          ? {
            uuid: user,
            date_from,
            date_to,
            paginate: 1,
            page,
            per_page: 10,
          }
          : {
            uuid: user,
            date_from,
            date_to,
            paginate: 1,
            page,
            per_page: 10,
          }),
      }
    };

    dispatch(
      user !== 'all' ? getSpecificAuditLog(user, params)
        :
        getAllAuditLogs({
          params: {
            ...(search
              ? {
                date_from,
                date_to,
                paginate: 1,
                page,
                per_page: 10,
              }
              : {
                date_from,
                date_to,
                paginate: 1,
                page,
                per_page: 10,
              }),
          },
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date_from,
    date_to,
    user,
    page,
    search
  ]);

  useMemo(() => {
    handleChange({ ...filterValue, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Table Headers should be Time, Admin, Action 
  const columns = [
    {
      accessor: "time",
      Header: "Time",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two capitalize">
          {dfn.format(
            new Date(fixDateForAllBrowsers(value)),
            "MMM d, yyy hh:mm:ss a"
          )}
        </span>
      )
    },
    {
      accessor: "admin",
      Header: "Admin",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two capitalize">{value}</span>
      )
    },
    {
      accessor: "action",
      Header: "Action",
      Cell: ({ value, row }) => (
        <span className="glade-normal-text-two capitalize">{value}</span>
      )
    }
  ];
  return (
    <div className="p-3">
      <CardTableHeader
        filterCallback={handleChange}
        defaultFilterParams={defaultFilterParams}
        title={"Audit Logs"}
        filterFields={["user", "date"]}
        noSearch
      />
      <PaginatedTable
        columns={columns}
        data={
          typeof allAuditLogs === 'object' && !Array.isArray(allAuditLogs)
            ? allAuditLogs?.data
            : allAuditLogs?.data?.data
        }
        empty_message="No audit logs"
        current_page={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={allAuditLogs?.total}
        loading={isLoadingAuditLogs}
      />
    </div>
  );
}
import React from 'react';
import {GLADE_LOGO} from '../../assets/images/images';

export default function GladeLogo () {
    return (
        <div className="inline-flex">
            <img src={GLADE_LOGO} alt="logo" />
        </div>
    )
}

import Axios from "../../connection/defaultClient";
import handler from "../../handlers/errorHandler";
import { toastSuccess, toastWarning, toastError } from "../../helpers/utils.toast";
import {
	TOGGLE_LOADIDNG_STATE_OFF,
	TOGGLE_LOADIDNG_STATE_ON,
} from "../shared/types";
import {
	CROSS_BORDER_MANUAL_TRANSFERS_FAILURE,
	CROSS_BORDER_MANUAL_TRANSFERS_START,
	CROSS_BORDER_MANUAL_TRANSFERS_SUCCESS,
	GET_ALL_BILLS_TRANSACTIONS_FAILURE,
	GET_ALL_BILLS_TRANSACTIONS_START,
	GET_ALL_BILLS_TRANSACTIONS_SUCCESS,
	GET_ALL_PAYROLL_TRANSACTIONS_FAILURE,
	GET_ALL_PAYROLL_TRANSACTIONS_START,
	GET_ALL_PAYROLL_TRANSACTIONS_SUCCESS,
	GET_PAYROLL_SALARY_ADVANCE_START,
	GET_PAYROLL_SALARY_ADVANCE_SUCCESS,
	GET_PAYROLL_SALARY_ADVANCE_FAILURE,
	GET_ALL_TRANSACTIONS_FAILURE,
	GET_ALL_TRANSACTIONS_START,
	GET_ALL_TRANSACTIONS_SUCCESS,
	GET_ALL_TRANSFER_TRANSACTIONS_FAILURE,
	GET_ALL_TRANSFER_TRANSACTIONS_START,
	GET_ALL_TRANSFER_TRANSACTIONS_SUCCESS,
	GET_ALL_USER_TRANSACTIONS_FAILURE,
	GET_ALL_USER_TRANSACTIONS_START,
	GET_ALL_USER_TRANSACTIONS_SUCCESS,
	GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_FAILURE,
	GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_START,
	GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_SUCCESS,
	GET_SPECIFIC_BILLS_TRANSACTIONS_FAILURE,
	GET_SPECIFIC_BILLS_TRANSACTIONS_START,
	GET_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS,
	GET_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE,
	GET_SPECIFIC_PAYROLL_TRANSACTIONS_START,
	GET_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS,
	GET_SPECIFIC_TRANSACTIONS_FAILURE,
	GET_SPECIFIC_TRANSACTIONS_START,
	GET_SPECIFIC_TRANSACTIONS_SUCCESS,
	GET_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE,
	GET_SPECIFIC_TRANSFER_TRANSACTIONS_START,
	GET_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS,
	REVERT_SPECIFIC_BILLS_TRANSACTIONS_FAILURE,
	REVERT_SPECIFIC_BILLS_TRANSACTIONS_START,
	REVERT_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS,
	REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE,
	REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_START,
	REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS,
	UPDATE_MANUAL_TRANSFERS_FAILURE,
	UPDATE_MANUAL_TRANSFERS_START,
	UPDATE_MANUAL_TRANSFERS_SUCCESS,
	REJECT_ADVANCE_DETAIL_START,
	REJECT_ADVANCE_DETAIL_SUCCESS,
	REJECT_ADVANCE_DETAIL_FAILURE,
	APPROVED_ADVANCE_DETAIL_START,
	APPROVED_ADVANCE_DETAIL_SUCCESS,
	APPROVED_ADVANCE_DETAIL_FAILURE,
	GET_ADVANCE_PAYROLL_DETAIL_START,
	GET_ADVANCE_PAYROLL_DETAIL_SUCCESS,
	GET_ADVANCE_PAYROLL_DETAIL_FAILURE,
	VERIFY_SPECIFIC_BILLS_TRANSACTIONS_FAILURE,
	VERIFY_SPECIFIC_BILLS_TRANSACTIONS_START,
	VERIFY_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS,
	VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE,
	VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_START,
	VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS,
	VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE,
	VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_START,
	VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS,
} from "./types";

export const getAllTransactions = (owner_uuid, params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_TRANSACTIONS_START });
		Axios.get(`merchants/transactions/all`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ALL_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getAllTransactionsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`merchants/transactions/all`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getAllTransferTransactions = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_TRANSFER_TRANSACTIONS_START });
		Axios.get(`transactions/transfer`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_TRANSFER_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ALL_TRANSFER_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getCrossBorderManualTransfers = (params) => {
	return (dispatch) => {
		dispatch({ type: CROSS_BORDER_MANUAL_TRANSFERS_START });

		Axios.get(`/cross-border/manual-transfers`, params)
			.then((res) => {
				// callback(res.data.data);
				dispatch({
					type: CROSS_BORDER_MANUAL_TRANSFERS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: CROSS_BORDER_MANUAL_TRANSFERS_FAILURE });
				handler(error, true);
			});
	};
};
export const getCrossBorderManualTransfersForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`/cross-border/manual-transfers`, params)
		.then((res) => {
			callback(res.data.data);
			dispatch({
				type: TOGGLE_LOADIDNG_STATE_OFF,
				payLoad: res.data.data,
			});
		})
		.catch((error) => {
			dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
			handler(error, true);
		});
	};
};

export const updateManualInternationalTransfer = (ids, verb, onCallBack) => {
	return (dispatch) => {
		dispatch({type: UPDATE_MANUAL_TRANSFERS_START});
		Axios.put(`/cross-border/mark-${verb}-manual-transfers`, ids)
		.then((res)=> {
			dispatch({
				type: UPDATE_MANUAL_TRANSFERS_SUCCESS, 
				payLoad: res.data
			});
			toastSuccess(res.data.message);
			onCallBack();
		})
		.catch((error) => {
			dispatch({
				type: UPDATE_MANUAL_TRANSFERS_FAILURE,
				payLoad:error
			})
			onCallBack();
			handler(error, true);
		})
	}
}

export const getAllTransferTransactionsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`transactions/transfer`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getAllBillTransactions = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_BILLS_TRANSACTIONS_START });
		Axios.get(`transactions/bill`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_BILLS_TRANSACTIONS_SUCCESS,
					payLoad: res.data?.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_BILLS_TRANSACTIONS_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getAllBillTransactionsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`transactions/bill`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getAllPayrollTransactions = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_PAYROLL_TRANSACTIONS_START });
		Axios.get(`transactions/payroll`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_PAYROLL_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ALL_PAYROLL_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getPayrollSalaryAdvance = (params) => {
	return (dispatch) => {
		dispatch({ type: GET_PAYROLL_SALARY_ADVANCE_START });
		Axios.get(`salary-advance`, params)
			.then((res) => {
				dispatch({
					type: GET_PAYROLL_SALARY_ADVANCE_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_PAYROLL_SALARY_ADVANCE_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getPayrollSalaryAdvanceForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });
		Axios.get(`salary-advance`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getAllPayrollTransactionsForDownload = (params, callback) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`transactions/payroll`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getAllUserTransactions = (owner_uuid, params) => {
	return (dispatch) => {
		dispatch({ type: GET_ALL_USER_TRANSACTIONS_START });
		Axios.get(`merchants/transactions/${owner_uuid}/all`, params)
			.then((res) => {
				dispatch({
					type: GET_ALL_USER_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: GET_ALL_USER_TRANSACTIONS_FAILURE, payLoad: error });
				handler(error);
			});
	};
};

export const getAllUserTransactionsForDownload = (
	owner_uuid,
	params,
	callback
) => {
	return (dispatch) => {
		dispatch({ type: TOGGLE_LOADIDNG_STATE_ON });

		Axios.get(`merchants/transactions/${owner_uuid}/all`, params)
			.then((res) => {
				callback(res.data.data);
				dispatch({
					type: TOGGLE_LOADIDNG_STATE_OFF,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({ type: TOGGLE_LOADIDNG_STATE_OFF });
				handler(error, true);
			});
	};
};

export const getSpecificTransaction = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_TRANSACTIONS_START });
		Axios.get(`merchants/transactions/details/${id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const getSpecificTransferTransactions = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_TRANSFER_TRANSACTIONS_START });
		Axios.get(`transactions/transfer/${id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};


export const getAdvancePayrollDetail = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_ADVANCE_PAYROLL_DETAIL_START });
		Axios.get(`/salary-advance/${id}`)
			.then((res) => {
				dispatch({
					type: GET_ADVANCE_PAYROLL_DETAIL_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_ADVANCE_PAYROLL_DETAIL_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const rejectAdvanceDetails = (id, data) => {
	return (dispatch) => {
		dispatch({ type: REJECT_ADVANCE_DETAIL_START });
		Axios.get(`/salary-advance/${id}/reject`, data)
			.then((res) => {
				dispatch({
					type: REJECT_ADVANCE_DETAIL_SUCCESS,
					payLoad: res.data.data,
				});
				if (res?.data?.message?.includes("approved")) {
					toastSuccess(res.data.message);
				} else {
					toastError(res.data.message);
				}
				window.history.back();
				getAdvancePayrollDetail(id);
			})
			.catch((error) => {
				dispatch({
					type: REJECT_ADVANCE_DETAIL_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const approveAdvanceDetails = (id, data) => {
	return (dispatch) => {
		dispatch({ type: APPROVED_ADVANCE_DETAIL_START });
		Axios.get(`/salary-advance/${id}/approve`, data)
			.then((res) => {
				dispatch({
					type: APPROVED_ADVANCE_DETAIL_SUCCESS,
					payLoad: res.data.data,
				});
				if (res?.data?.message?.includes("approved")) {
					toastSuccess(res.data.message);
				} else {
					toastError(res.data.message);
				}
				window.history.back();
				getAdvancePayrollDetail(id);
			})
			.catch((error) => {
				dispatch({
					type: APPROVED_ADVANCE_DETAIL_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};


export const getSingleTransferTransactions = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_START });
		Axios.get(`/cross-border/manual-transfer-details?txn_ref=${id}`)
			.then((res) => {
				dispatch({
					type: GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_INTERNATIONAL_TRANSFER_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};
export const getSpecificBillTransactions = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_BILLS_TRANSACTIONS_START });
		Axios.get(`transactions/bill/${id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_BILLS_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error);
			});
	};
};

export const getSpecificPayrollTransactions = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_SPECIFIC_PAYROLL_TRANSACTIONS_START });
		Axios.get(`transactions/payroll/${id}`)
			.then((res) => {
				dispatch({
					type: GET_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: GET_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const verifySpecificTransferTransactions = (txn_ref, data) => {
	return (dispatch) => {
		dispatch({ type: VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_START });
		Axios.get(`transactions/transfer/verify/${txn_ref}`, data)
			.then((res) => {
				dispatch({
					type: VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				// window.history.back();
				window.location.reload();
				// getSpecificTransferTransactions(id);
			})
			.catch((error) => {
				dispatch({
					type: VERIFY_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const verifySpecificBillTransactions = (txn_ref, data) => {
	return (dispatch) => {
		dispatch({ type: VERIFY_SPECIFIC_BILLS_TRANSACTIONS_START });
		Axios.get(`bills/transaction/verify/${txn_ref}`, data)
			.then((res) => {
				dispatch({
					type: VERIFY_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess("Requeried");
				window.location.reload();
			})
			.catch((error) => {
				dispatch({
					type: VERIFY_SPECIFIC_BILLS_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const verifySpecificPayrollTransactions = (txn_ref, data) => {
	return (dispatch) => {
		dispatch({ type: VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_START });
		Axios.get(`transactions/payroll/verify/${txn_ref}`, data)
			.then((res) => {
				dispatch({
					type: VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				// window.history.back();
				window.location.reload();
				// getSpecificTransferTransactions(id);
			})
			.catch((error) => {
				dispatch({
					type: VERIFY_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const revertSpecificTransferTransactions = (txn_ref, data) => {
	return (dispatch) => {
		dispatch({ type: REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_START });
		Axios.get(`transactions/transfer/revert/${txn_ref}`, data)
			.then((res) => {
				dispatch({
					type: REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				// window.history.back();
				window.location.reload();
				// getSpecificTransferTransactions(id);
			})
			.catch((error) => {
				dispatch({
					type: REVERT_SPECIFIC_TRANSFER_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				handler(error, true);
			});
	};
};

export const revertSpecificBillTransactions = (txn_ref, data) => {
	return (dispatch) => {
		dispatch({ type: REVERT_SPECIFIC_BILLS_TRANSACTIONS_START });
		Axios.get(`transactions/bill/revert/${txn_ref}`, data)
			.then((res) => {
				dispatch({
					type: REVERT_SPECIFIC_BILLS_TRANSACTIONS_SUCCESS,
					payLoad: res.data.data,
				});
				toastSuccess(res.data.message);
				// window.history.back();
				window.location.reload();
				// getSpecificTransferTransactions(id);
			})
			.catch((error) => {
				dispatch({
					type: REVERT_SPECIFIC_BILLS_TRANSACTIONS_FAILURE,
					payLoad: error,
				});
				if (error?.response?.data?.error?.response) {
					toastWarning(error?.response?.data?.error?.response);
				} else {
					handler(error, true);
				}
			});
	};
};

// export const revertSpecificPayrollTransactions = (id, data) => {
//   return (dispatch) => {
//     dispatch({ type: REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_START });
//     Axios.get(`transactions/bill/revert/${id}`, data)
//       .then((res) => {
//         dispatch({
//           type: REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_SUCCESS,
//           payLoad: res.data.data,
//         });
//         toastSuccess(res.data.message);
//         // window.history.back();
//         window.location.reload();
//         // getSpecificTransferTransactions(id);
//       })
//       .catch((error) => {
//         dispatch({
//           type: REVERT_SPECIFIC_PAYROLL_TRANSACTIONS_FAILURE,
//           payLoad: error,
//         });
//         handler(error, true);
//       });
//   };
// };

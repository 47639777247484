import React, {  useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { RiLoader5Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import BasicModal from "../../components/modals/BasicModal";
import { TextSkeleton } from "../../components/skeletons";
import { statusEnum } from "../../helpers/helper";
import { FormError } from "../../validation";
import useTransactions from "../../hooks/useTransactions";
import PayrollAdvanceDetailsCard from "./PayrollAdvanceDetailsCard";
import { approveAdvanceDetails, getAdvancePayrollDetail, rejectAdvanceDetails } from "../../redux/transactions/transactionsActions";
import SalaryAdvanceDetailsCard from "./SalaryAdvanceDetailsCard";

export default function PayrollLoanDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    isLoadingAdvanceDetails,
    specificAdvanceDetails,
    isUpdatingSpecificAdvanceDetails,
  } = useTransactions();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoadingAdvanceDetails) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoadingAdvanceDetails]);

  const [details, setDetails] = useState({});
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showAdvanceRequestModal, setShowAdvanceRequestModal] = useState(false);
  const [dataToShow, setDataToShow] = useState("");

  const closePreviewAdvanceRequest = () => {
    setShowAdvanceRequestModal(false);
    setDataToShow("");
  };

  useMemo(() => {
    setDetails(specificAdvanceDetails);
  }, [specificAdvanceDetails]);
 

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });

  React.useEffect(() => {
    dispatch(getAdvancePayrollDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
 

  const onRejectAdvanceRequest = ({ reason }) => {
    dispatch(
		rejectAdvanceDetails(id, {
        status: "rejected",
        comment: reason,
      })
    );
  };
 

  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="mb-6">
          <BreadCrumbs title={"Payroll Advance Request"} path={"/dashboard/payroll/loan/requests"} />
        </div>

        <div className="flex justify-between mt-2">
          {!loading && details?.status ? (
            <div className="flex items-center justify-between w-full">
              <BasicIndicator
                className="px-5 py-1 capitalize"
                type={statusEnum[details?.status]}
                title={details?.status}
              />
              {/* HEADER ACTION */}
			  {details?.status === 'pending' && (
                <div className="flex justify-end mt-2">
                  <div className="flex items-center space-x-3">
                  
                        <Button.Green
                          onClick={() => {
                            dispatch(
							 approveAdvanceDetails(id, {
                                status: "approved",
                              })
                            );
                          }}
                          disabled={isUpdatingSpecificAdvanceDetails}
                          title="Approve"
                          className={`glade-normal-text-three  ${
                            isUpdatingSpecificAdvanceDetails
                              ? " cursor-not-allowed"
                              : ""
                          } `}
                        >
                          {isUpdatingSpecificAdvanceDetails
                            ? "Approving"
                            : "Approve"}
                          {isUpdatingSpecificAdvanceDetails && (
                            <RiLoader5Fill
                              size={24}
                              className="animate-spin ml-4"
                            />
                          )}
                        </Button.Green>
                        
                        
                     <Button.Danger
                      title="Reject"
                      disabled={loading}
                      onClick={() => setShowRejectModal(true)}
                    />
                  </div>
                </div>
                )}
            </div>
          ) : (
            <TextSkeleton width="100px" />
          )}
        </div>

        {/* ACCOUNT DETAILS */}
        <SalaryAdvanceDetailsCard details={details} loading={loading} />

        {/* Salary advance DOCUMENT */}

        <PayrollAdvanceDetailsCard details={details} loading={loading} />
      </div>

      {/* Reject advance request Modal */}
      <BasicModal
        visibility={showRejectModal}
        onClose={() => setShowRejectModal(false)}
        onOpen={() => setShowRejectModal(true)}
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        modelContentSection={
          <form onSubmit={handleSubmit(onRejectAdvanceRequest)}>
            <div className="-mx-6 -mt-2 flex justify-between px-5 pb-3 border-b-2 border-solid border-gray-200 mb-8">
              <h4 className="glade-normal-text-two glade-black">
                Reject Salary Advance request
              </h4>
              <MdOutlineClose
                size={16}
                className="glade-black cursor-pointer"
                onClick={() => setShowRejectModal(false)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="reason" className="block glade-small-text-two">
                Reason
              </label>
              <textarea
                rows={4}
                id="reason"
                name="reason"
                {...register("reason", {
                  required: "Enter a reason",
                })}
                // required
                placeholder="Enter a reason"
                className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two resize-none"
              />
              <div className="flex items-center justify-between flex-wrap">
                <FormError errors={errors} name="reason" />
              </div>
            </div>

            <div className="-mx-6 -mb-6 flex justify-between p-5 border-t-2 border-solid border-gray-200 mt-10">
              <button
                onClick={() => setShowRejectModal(false)}
                className="glade-normal-text-three glade-black text-center"
              >
                Cancel
              </button>
              <button
                disabled={isUpdatingSpecificAdvanceDetails}
                className={`glade-normal-text-three glade-button-red ${
                  isUpdatingSpecificAdvanceDetails ? "cursor-not-allowed" : ""
                } text-white py-2 px-4 flex justify-center items-center text-center rounded-sm`}
              >
                {isUpdatingSpecificAdvanceDetails ? "Rejecting" : "Reject"}
                {isUpdatingSpecificAdvanceDetails && (
                  <RiLoader5Fill size={24} className="animate-spin ml-4" />
                )}
              </button>
            </div>
          </form>
        }
      />
      {/* Show advance request Modal */}
      <BasicModal
        visibility={showAdvanceRequestModal}
        onClose={closePreviewAdvanceRequest}
        onOpen={() => setShowAdvanceRequestModal(true)}
        primaryButton=""
        modalTitleSection=""
        secondaryButton=""
        modelContentSection={
          <div>
            <div className="-mx-6 -mt-2 flex justify-between px-5 pb-3 border-b-2 border-solid border-gray-200 mb-4">
              <h4 className="glade-normal-text-two glade-black">Document</h4>
              <MdOutlineClose
                size={16}
                className="glade-black cursor-pointer"
                onClick={closePreviewAdvanceRequest}
              />
            </div>
            <img src={dataToShow} className="h-96 w-full" alt="" />
          </div>
        }
      />
    </div>
  );
}

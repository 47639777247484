import React from "react";
import styled from "styled-components";

/**
 * Render a placeholder loading skeleton for texts
 * @param {string} width
 * @param {string} className
 * @returns {node}
 */

const TextSkeletonStyle = styled.div`
  width: ${(props) => props.width || "100%"};
`;

export const TextSkeleton = ({ className = "", width }) => {
  return (
    <TextSkeletonStyle
      width={width}
      className={
        "glade-subtitle-two glade-black w-3/5 bg-gray-300 animate-pulse transform rounded-md h-6 " +
        className
      }
    />
  );
};

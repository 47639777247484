import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { email_required, FormError, password } from "../../validation";
import { loginUserAction } from "../../redux/auth/authActions";
import { Redirect, useLocation } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { RiLoader5Fill } from "react-icons/ri";
import Button from "../../components/buttons/Button";

export default function Login() {
  const dispatch = useDispatch();
  let location = useLocation();
  const user = useSelector((state) => state?.auth?.user);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });

  const { isLoading } = useUser();

  const onSubmit = (data) => {
    dispatch(loginUserAction(data));
  };

  let { from } = location?.state || { from: { pathname: "/dashboard/home" } };

  return (
    <div className="min-h-screen bg-white flex w-screen">
      {user?.token ? <Redirect to={from} push /> : null}
      <div className="w-full flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-6 glade-heading-one glade-black">Hello Admin,</h2>
            <p className="mt-2 glade-subtitle-two glade-black">
              Sign in to your account.
            </p>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block glade-small-text-two glade-black"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="Enter Email Address"
                      {...register("email", email_required)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    <FormError errors={errors} name="email" />
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block glade-small-text-two glade-black"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      placeholder="Enter Password"
                      {...register("password", password)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    <FormError errors={errors} name="password" />
                  </div>
                  <div className="mt-3 flex justify-end">
                    <a
                      href="/auth/reset-password"
                      className="glade-normal-text-two glade-orange"
                    >
                      Reset Password
                    </a>
                  </div>
                </div>

                <div>
                  <Button.Dark
                    loading={isLoading}
                    type="submit"
                    title="Sign in"
                    className="glade-normal-text-three glade-button-blue w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-sm text-white"
                  >
                    Sign in
                    {isLoading && (
                      <RiLoader5Fill size={24} className="animate-spin ml-4" />
                    )}
                  </Button.Dark>
                </div>
                {/* <div>
                  <p className="mt-2 flex justify-center glade-normal-text-two">
                    <span className="mr-1 glade-black">Don’t have an account?</span>
                    <a
                      href="/auth/register"
                      className="glade-normal-text-two glade-orange"
                    >
                      Create Account
                    </a>
                  </p>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useSelector } from "react-redux";

const useAccounts = () => {
	const accounts = useSelector((state) => state.accounts);

	const {
		isLoadingMultiCurrencies,
		isLoadingAllPersonalAccounts,
		isLoadingAllBusinessAccounts,
		isLoadingAllAgentAccounts,
		isLoadingAllLimitRequests,
		isLoadingAllBlacklistedAccounts,
		isLoadingSpecificPersonalAccount,
		isLoadingSpecificBusinessAccounts,
		isLoadingSpecificLimitRequest,
		isLoadingPersonalMerchantServices,
		isLoadingBusinessMerchantServices,
		isLoadingPersonalMerchantCompliance,
		isLoadingBusinessMerchantCompliance,
		isLoadingPersonalMerchantFees,
		isLoadingBusinessMerchantFees,
		isLoadingPersonalSecuritySettings,
		isLoadingBusinessSecuritySettings,
		isLoadingBusinessAccountInstantSettlement,
		isLoadingBusinessAccountSettlementType,
		isBlacklistingPersonalAccount,
		isBlacklistingBusinessAccount,
		isUpdatingPersonalAccountMerchantFees,
		isUpdatingBusinessAccountMerchantFees,
		isUpdatingAccountMerchantFees,
		isRevertingAccountMerchantFees,
		isRevertingPersonalAccountMerchantFees,
		isRevertingBusinessAccountMerchantFees,
		isUpdatingPersonalAccountMerchantServices,
		isUpdatingBusinessAccountMerchantServices,
		isUpdatingPersonalAccountBVN,
		isUpdatingBusinessAccountBVN,
		isUpdatingPersonalAccountAddress,
		isUpdatingBusinessAccountAddress,
		isUpdatingBusinessAccountName,
		isUpdatingBusinessAccountEmail,
		isUpdatingBusinessAccountPhone,
		isUpdatingPersonalAccount2FA,
		isUpdatingBusinessAccount2FA,
		isUpdatingPersonalAccountIP,
		isUpdatingBusinessAccountIP,
		isUpdatingBusinessAccountInstantSettlement,
		isUpdatingBusinessSettlementAccount,
		isUpdatingBusinessSettlementType,
		isUpdatingPersonalCompliance,
		isUpdatingBusinessCompliance,
		isUpdatingBusinessDescription,
		isUpdatingTransferLimit,
		isFundingPersonalAccount,
		isFundingBusinessAccount,
		isApprovingLimitRequest,
		isRejectingLimitRequest,
		allPersonalAccounts,
		allAgentAccounts,
		allBlacklistedAccounts,
		allPersonalMerchantServices,
		allBusinessMerchantServices,
		allPersonalMerchantFees,
		allBusinessMerchantFees,
		allBusinessAccounts,
		allLimitRequests,
		specificPersonalAccount,
		specificBusinessAccounts,
		specificLimitRequest,
		specificPersonalAccountCompliance,
		specificBusinessAccountCompliance,
		specificPersonalAccountSecuritySettings,
		specificBusinessAccountSecuritySettings,
		specificBusinessSettlementAccount,
		specificBusinessSettlementType,
		isPosFeeUpdated,
		isOtherFeesUpdated,
		systemLimits,
		multiCurrencies
	} = accounts;

	return {
		isLoadingMultiCurrencies,
		isLoadingAllPersonalAccounts,
		isLoadingAllBusinessAccounts,
		isLoadingAllAgentAccounts,
		isLoadingAllLimitRequests,
		isLoadingAllBlacklistedAccounts,
		isLoadingSpecificPersonalAccount,
		isLoadingSpecificBusinessAccounts,
		isLoadingSpecificLimitRequest,
		isLoadingPersonalMerchantServices,
		isLoadingBusinessMerchantServices,
		isLoadingPersonalMerchantCompliance,
		isLoadingBusinessMerchantCompliance,
		isLoadingPersonalMerchantFees,
		isLoadingBusinessMerchantFees,
		isLoadingPersonalSecuritySettings,
		isLoadingBusinessSecuritySettings,
		isLoadingBusinessAccountInstantSettlement,
		isLoadingBusinessAccountSettlementType,
		isBlacklistingPersonalAccount,
		isBlacklistingBusinessAccount,
		isUpdatingPersonalAccountMerchantFees,
		isUpdatingBusinessAccountMerchantFees,
		isUpdatingAccountMerchantFees,
		isRevertingAccountMerchantFees,
		isRevertingPersonalAccountMerchantFees,
		isRevertingBusinessAccountMerchantFees,
		isUpdatingPersonalAccountMerchantServices,
		isUpdatingBusinessAccountMerchantServices,
		isUpdatingPersonalAccountBVN,
		isUpdatingBusinessAccountBVN,
		isUpdatingPersonalAccountAddress,
		isUpdatingBusinessAccountAddress,
		isUpdatingBusinessAccountName,
		isUpdatingBusinessAccountEmail,
		isUpdatingBusinessAccountPhone,
		isUpdatingPersonalAccount2FA,
		isUpdatingBusinessAccount2FA,
		isUpdatingPersonalAccountIP,
		isUpdatingBusinessAccountIP,
		isUpdatingBusinessAccountInstantSettlement,
		isUpdatingBusinessSettlementAccount,
		isUpdatingBusinessSettlementType,
		isUpdatingPersonalCompliance,
		isUpdatingBusinessCompliance,
		isUpdatingBusinessDescription,
		isUpdatingTransferLimit,
		isFundingPersonalAccount,
		isFundingBusinessAccount,
		isApprovingLimitRequest,
		isRejectingLimitRequest,
		allPersonalAccounts,
		allAgentAccounts,
		allBlacklistedAccounts,
		allPersonalMerchantServices,
		allBusinessMerchantServices,
		allPersonalMerchantFees,
		allBusinessMerchantFees,
		allBusinessAccounts,
		allLimitRequests,
		specificPersonalAccount,
		specificBusinessAccounts,
		specificLimitRequest,
		specificPersonalAccountCompliance,
		specificBusinessAccountCompliance,
		specificPersonalAccountSecuritySettings,
		specificBusinessAccountSecuritySettings,
		specificBusinessSettlementAccount,
		specificBusinessSettlementType,
		isPosFeeUpdated,
		isOtherFeesUpdated,
		systemLimits,
		multiCurrencies
	};
};

export default useAccounts;

export const GET_TRANSACTION_SUMMARY_START = "GET_TRANSACTION_SUMMARY_START";
export const GET_TRANSACTION_SUMMARY_SUCCESS = "GET_TRANSACTION_SUMMARY_SUCCESS";
export const GET_TRANSACTION_SUMMARY_FAILURE = "GET_TRANSACTION_SUMMARY_FAILURE";

export const GET_TRANSACTION_STATUS_START = "GET_TRANSACTION_STATUS_START";
export const GET_TRANSACTION_STATUS_SUCCESS = "GET_TRANSACTION_STATUS_SUCCESS";
export const GET_TRANSACTION_STATUS_FAILURE = "GET_TRANSACTION_STATUS_FAILURE";

export const GET_ACTIVE_USERS_REPORT_START = "GET_ACTIVE_USERS_REPORT_START";
export const GET_ACTIVE_USERS_REPORT_SUCCESS = "GET_ACTIVE_USERS_REPORT_SUCCESS";
export const GET_ACTIVE_USERS_REPORT_FAILURE = "GET_ACTIVE_USERS_REPORT_FAILURE";

export const GET_MOST_USED_SERVICES_START = "GET_MOST_USED_SERVICES_START";
export const GET_MOST_USED_SERVICES_SUCCESS = "GET_MOST_USED_SERVICES_SUCCESS";
export const GET_MOST_USED_SERVICES_FAILURE = "GET_MOST_USED_SERVICES_FAILURE";

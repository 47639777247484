import React from "react";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

const TabNav = ({ tabs = [], className }) => {
  const pageLink = window.location.pathname;
  const location = useLocation();
  const activeTab = qs.parse(location.search)?.source && pageLink === '/dashboard/cross-border/rates' ?
    qs.parse(location.search)?.source.toLocaleUpperCase() : '';
  const chargesTab = qs.parse(location.search)?.method && pageLink === '/dashboard/cross-border/charges' ?
    qs.parse(location.search)?.method : '';
  const currencyTab = qs.parse(location.search)?.continent && pageLink === '/dashboard/currencies' ?
    qs.parse(location.search)?.continent : '';

  return (
    <nav className="flex px-10 space-x-2 rounded-sm" >
      {
        tabs.map((tab, key) => (
          <Link key={key} to={tab.path}>
            <li
              className={
                pageLink === tab.path || tab.title === activeTab || chargesTab === tab.code || tab.key === currencyTab
                  ? "py-2.5 px-6 glade-subtitle-one capitalize glade-blue-darker border-b-2 focus:border-blue-500 border-blue-800 list-none text-center cursor-pointer" +
                  className
                  : "py-2.5 px-6 glade-subtitle-one capitalize glade-grey-dark border-b-2 border-transparent list-none text-center cursor-pointer " +
                  className
              }
            >
              {tab.title} {tab?.tierNo ? <span className="text-sm text-white px-2 ml-1 bg-glade-red-200 rounded-3xl">{tab.tierNo}</span> : <></>}
            </li>
          </Link>
        ))
      }
    </nav >
  );
};

export default TabNav;

import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

const usePos = () => {
	const pos = useSelector((state) => state.pos);
	const [arrangedUserOptions, setArrangedUserOptions] = useState([]);
	const [assignedPos, setAssignedPos] = useState([]);

	const {
		isLoadingAllPosTerminals,
		isLoadingSpecificPosTerminal,
		isLoadingAllPosRequests,
		isLoadingSpecificPosRequest,
		isLoadingAllAgentRequests,
		isLoadingSpecificAgentRequest,
		isLoadingSearchedAccount,
		isLoadingUsers,
		isAssigningPosTerminal,
		isUnassigningPosTerminal,
		isDisablingPosTerminal,
		isEnablingPosTerminal,
		isApprovingPosRequest,
		isDecliningPosRequest,
		isApprovingAgentRequest,
		isDecliningAgentRequest,
		allUsers,
		allPosTerminals,
		allPosRequests,
		allAgentRequests,
		specificPosTerminal,
		specificPosRequest,
		specificAgentRequest,
		searchedAccount,
	} = pos;

	const arrangeUserOptions = () => {
		let _users = [];
		allUsers.forEach((_user) => {
			_users.push({
				label: _user.name,
				value: _user.owner_uuid,
				owner_uuid: _user.owner_uuid,
				owner_type: _user.owner_type,
				name: _user.name,
				email: _user.email,
			});
		});
		return _users;
	};

	const arrangeUserOptionsById = () => {
		let _users = [];
		allUsers.forEach((_user) => {
			_users.push({
				label: _user.owner_uuid,
				value: _user.owner_uuid,
				owner_uuid: _user.owner_uuid,
				owner_type: _user.owner_type,
				name: _user.name,
				email: _user.email,
			});
		});
		return _users;
	};

	useMemo(
		() => {
			setArrangedUserOptions(arrangeUserOptions());
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[allUsers]
	);

	const getAssignedPOS = () => {
		let assigned = [];
		assigned = allPosTerminals?.data?.filter((pos) => pos.assigned);
		return assigned;
	};

	useMemo(
		() => {
			setAssignedPos(getAssignedPOS());
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[allPosTerminals]
	);

	return {
		isLoadingAllPosTerminals,
		isLoadingSpecificPosTerminal,
		isLoadingAllPosRequests,
		isLoadingSpecificPosRequest,
		isLoadingAllAgentRequests,
		isLoadingSpecificAgentRequest,
		isLoadingSearchedAccount,
		isLoadingUsers,
		isAssigningPosTerminal,
		isUnassigningPosTerminal,
		isDisablingPosTerminal,
		isEnablingPosTerminal,
		isApprovingPosRequest,
		isDecliningPosRequest,
		isApprovingAgentRequest,
		isDecliningAgentRequest,
		allUsers: arrangedUserOptions,
		arrangeUserOptionsById,
		allPosTerminals,
		assignedPos,
		allPosRequests,
		allAgentRequests,
		specificPosTerminal,
		specificPosRequest,
		specificAgentRequest,
		searchedAccount,
	};
};

export default usePos;

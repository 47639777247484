import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import logger from "redux-logger";

// Available Reducers
import authReducer from "./auth/authReducer";
import alertReducer from "./alert/alertReducer";
import complianceReducer from "./compliance/complianceReducer";
import homeReducer from "./home/homeReducer";
import transactionsReducer from "./transactions/transactionsReducer";
import notificationsReducer from "./notifications/notificationsReducer";
import settlementsReducer from "./settlements/settlementsReducer";
import posReducer from "./pos/posReducer";
import accountsReducer from "./accounts/accountsReducer";
import inflowReducer from "./inflow/inflowReducer";
import sharedReducer from "./shared/sharedReducer";
import feesReducer from "./fees/feesReducer";
import providersReducer from "./providers/providersReducer";
import auditLogsReducer from "./audit/auditReducer";
import filtersReducer from "./filters/filterReducer";
import discountsReducer from "./discounts/discountReducer";
import settingsReducer from "./settings/settingsReducer";
import crossBorderReducer from "./cross-border/crossBorderReducer";
import multiCurrencyReducer from "./multi-currency/multiCurrencyReducer";
import referralsReducer from "./referrals/referralsReducer";
import walletsReducer from "./wallets/walletsReducer";
import cacRegistrationReducer from "./cacRegisstration/cacRegistrationReducer";


const rootReducer = combineReducers({
	alert: alertReducer,
	auth: authReducer,
	compliance: complianceReducer,
	home: homeReducer,
	auditLogs: auditLogsReducer,
	transactions: transactionsReducer,
	notifications: notificationsReducer,
	settlements: settlementsReducer,
	pos: posReducer,
	accounts: accountsReducer,
	inflow: inflowReducer,
	shared: sharedReducer,
	fees: feesReducer,
	providers: providersReducer,
	filters: filtersReducer,
	discounts: discountsReducer,
	settings: settingsReducer,
	crossBorder: crossBorderReducer,
	multiCurrency: multiCurrencyReducer,
	referrals: referralsReducer,
	wallets: walletsReducer,
	cacRegistration: cacRegistrationReducer
});

const _reducerName = process.env.REACT_APP_SLUG + "_reducer";

const loadState = () => {
	try {
		const serializedState = localStorage.getItem(_reducerName);
		return serializedState === null ? {} : JSON.parse(serializedState);
	} catch (err) {
		return {};
	}
};

const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		return localStorage.setItem(_reducerName, serializedState);
	} catch (err) {
		// Ignore write errors
	}
};

// const initialState = {};
const initialState = loadState();
const middleware =
	process.env.REACT_APP_APP_ENV === "development" ? [thunk, logger] : [thunk];
const store = createStore(
	rootReducer,
	initialState,
	applyMiddleware(...middleware)
);

store.subscribe(() => saveState(store.getState()));

export default store;

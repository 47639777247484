import { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import { CardPlain, CardPlainSection } from "../../components/cards/CardPlain";
import { FormError } from "../../validation";
import { clearSingleDiscount, editDiscount, getSingleDiscount } from "../../redux/discounts/discountActions";
import { useDispatch } from "react-redux";
import useDiscounts from "../../hooks/useDiscounts";
import { useHistory } from "react-router-dom";
import { RiLoader5Fill } from "react-icons/ri";

const EditFreeTransfer = () => {
  const dispatch = useDispatch();
  const { location } = useHistory();
  const path = location.pathname.split('/'),
    id = path[path.length - 1];

  const {
    isEditingDiscount,
    singleDiscount,
    isLoadingSingleDiscount,
  } = useDiscounts();

  useEffect(() => {
    dispatch(getSingleDiscount(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    return () => {
      dispatch(clearSingleDiscount());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    criteriaMode: "all",
    shouldUseNativeValidation: true
  });

  function onEditTransfer({ account_type, number_of_transfer }) {
    const formData = {
      discount_type: "free_transfer",
      account_type,
      number_of_transfer
    };
    dispatch(editDiscount(id, formData));
  };

  if (isLoadingSingleDiscount) {
    return (
      <Fragment>
        <RiLoader5Fill size={24} className="animate-spin ml-4" />
      </Fragment>
    );
  }

  return (
    <div className="p-3 flex flex-col" >
      <div className="mb-5">
        <BreadCrumbs title={"Discounts"} goBack />
      </div>

      <form onSubmit={handleSubmit(onEditTransfer)}>
        <CardPlain type="single">
          <CardPlainSection
            title={"Edit Free Transfer"}
            type="single"
          >
            <section className="pt-10 px-16 pb-10">
              <div className="mb-4">
                {singleDiscount?.discount_info?.account_type ? (
                  <Fragment>
                    <label
                      htmlFor="account_type"
                      className="block glade-small-text-two"
                    >
                      Account Type
                    </label>
                    <select
                      className="mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 rounded-sm glade-button glade-normal-text-two"
                      name="type"
                      id="type"
                      {...register("account_type", {
                        required: "Select an account type"
                      })}
                    >
                      <option
                        selected={singleDiscount?.discount_info?.account_type === "personal"}
                        value="personal"
                      >
                        Personal
                      </option>
                      <option
                        selected={singleDiscount?.discount_info?.account_type === "business"}
                        value="business"
                      >
                        Business
                      </option>
                    </select>
                    <FormError errors={errors} name="type" />
                  </Fragment>
                ) : null}
              </div>
              <div className="mb-8">
                {singleDiscount?.discount_info?.no_of_free_transfers ? (
                  <Fragment>
                    <label
                      className="block glade-small-text-two"
                      htmlFor="number_of_transfer"
                    >
                      Number of Free Transfers
                    </label>
                    <input
                      type="number"
                      name="number_of_transfer"
                      {...register("number_of_transfer", {
                        required: "Pick amount of free transfers"
                      })}
                      id="number_of_transfer"
                      defaultValue={singleDiscount?.discount_info?.no_of_free_transfers ?? 1}
                      placeholder="Enter Number of Free Transfers"
                      className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two"
                    />
                    <FormError errors={errors} name="number_of_transfer" />
                  </Fragment>
                ) : null}
              </div>
            </section>
          </CardPlainSection>
        </CardPlain>
        <div className="flex justify-center mt-6 space-x-4">
          <Button.Dark
            type="submit"
            title={"Update Discount"}
            loading={isEditingDiscount}
            style={{ width: "fit-content" }}
          />
        </div>
      </form>
    </div>
  );
};

export default EditFreeTransfer;
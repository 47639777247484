import React from 'react';
import {FaTimes} from 'react-icons/fa';

export default function SideBarCloseButton({onClick=()=>{}}) {
    return (
        <button
            type="button"
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            onClick={() => onClick()}>
            <FaTimes/>
        </button>
    )
}

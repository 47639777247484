import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { RiLoader5Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import BasicModal from "../../components/modals/BasicModal";
import { TextSkeleton } from "../../components/skeletons";
import { fixDateForAllBrowsers } from "../../helpers/helper";
import useWallets from "../../hooks/useWallets";
import * as dfn from "date-fns";
import { getWalletDetails, updateWalletRequestStatus } from "../../redux/wallets/walletsActions";
import { FormError } from "../../validation";
import CountryFlag from "./CountryFlag";

export default function WalletRequestDetails() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoadingWalletDetails, isLoadingWalletStatusUpdate, walletDetailsData } = useWallets();

    const [showRejectModal, setShowRejectModal] = useState(false);
    const [showAproveModal, setShowApproveModal] = useState(false);
    const location = useLocation();
    const id = location?.search?.split('=')[1];

    useEffect(() => {
        dispatch(getWalletDetails(id));
    }, [id])

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        criteriaMode: "all",
    });

    const onSubmitOfWalletStatus = ({ reason }, type) => {
        const onClose = () => {
            setShowApproveModal(false);
            setShowRejectModal(false);
            //Goto Wallet requests list
            history.push('/dashboard/wallets');
        }
        dispatch(updateWalletRequestStatus({ id: id, type: type, reason }, onClose));
    };

    return (
        <div className="p-3">
            <div className="flex flex-col">
                <div className="mb-6">
                    <BreadCrumbs
                        title={"Wallet Requests"}
                        path={"/dashboard/accountrequests/wallets"}
                    />
                </div>

                <div className="flex justify-between mt-2">
                    {!isLoadingWalletDetails ? (
                        <div className="flex items-center w-full">
                            {/* HEADER ACTION */}
                            {walletDetailsData?.status === 'pending' && (
                                <div className="w-full flex justify-end gap-3 mt-2">
                                    <Button.Green
                                        title="Approve"
                                        onClick={() => setShowApproveModal(true)}
                                        disabled={isLoadingWalletDetails}
                                    />
                                    <div className="flex items-center space-x-3">
                                        <Button.Danger
                                            title="Decline"
                                            disabled={isLoadingWalletDetails}
                                            onClick={() => setShowRejectModal(true)}
                                        />
                                    </div>
                                </div>
                            )}

                        </div>
                    ) : (
                        <div className="flex w-full justify-between">
                            <p></p>
                            <div className="flex gap-3">
                                <TextSkeleton width="60px" />
                                <TextSkeleton width="60px" />
                            </div>
                        </div>
                    )}
                    <div className="flex items-center space-x-3">
                    </div>
                </div>

                <div>
                    <div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
                        <div className="py-3 border-b-2 border-solid border-gray-100">
                            <p className="glade-black">Account Details</p>
                        </div>
                        <div className="grid grid-cols-4 gap-4 py-3">
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                    Account Name
                                </h5>
                                {!isLoadingWalletDetails ? (
                                    <h6 className="glade-normal-text-three glade-black py-1">
                                        {walletDetailsData?.business_name}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                    Account Email
                                </h5>
                                {!isLoadingWalletDetails ? (
                                    <h6 className="glade-normal-text-three glade-black py-1">
                                        {walletDetailsData?.business_email}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                    Phone Number
                                </h5>
                                {!isLoadingWalletDetails ? (
                                    <h6 className="glade-normal-text-three glade-black py-1">
                                        {walletDetailsData?.phone ?? 'N/A'}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>

                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                    Account Type
                                </h5>
                                {!isLoadingWalletDetails ? (
                                    <h6 className="glade-normal-text-three glade-black py-1">
                                        {walletDetailsData?.owner_type ?? "Not available"}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            {walletDetailsData?.country &&
                                <div className="flex flex-col">
                                    <h5 className="glade-normal-text-two glade-ash py-1">
                                        Country
                                    </h5>
                                    {!isLoadingWalletDetails ? (
                                        <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                            {walletDetailsData?.country}
                                        </h6>
                                    ) : (
                                        <TextSkeleton />
                                    )}
                                </div>}

                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                    Currency Requested
                                </h5>
                                {!isLoadingWalletDetails ? (
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize flex gap-3 items-center">
                                        {<CountryFlag flagCode={walletDetailsData?.currency?.slice(0, -1)} />}  {walletDetailsData?.currency}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                            <div className="flex flex-col">
                                <h5 className="glade-normal-text-two glade-ash py-1">
                                    Date Opened
                                </h5>
                                {!isLoadingWalletDetails && walletDetailsData?.date_opened ? (
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {dfn.format(
                                            new Date(fixDateForAllBrowsers(walletDetailsData?.date_opened)),
                                            "MMM d, yyyy"
                                        )}
                                    </h6>
                                ) : (
                                    <TextSkeleton />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {(
                    <div className="flex flex-col bg-white rounded-sm px-8 py-4 mb-6">
                        <div className="py-3 border-b-2 border-solid border-gray-100">
                            <p className="glade-black">Request Details</p>
                        </div>
                        {isLoadingWalletDetails ? (
                            <TextSkeleton width="300px" className="mt-4" />
                        ) : (
                            <>
                                <div>
                                    <h5 className="glade-normal-text-two glade-ash mt-6">
                                        Reason for Wallet Request
                                    </h5>
                                    <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                        {walletDetailsData?.reason}
                                    </h6>
                                </div>
                                {(
                                    <div className="grid grid-cols-4 gap-4 py-3">
                                        <div className="flex flex-col">
                                            <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                                                Business Registration
                                            </h5>
                                            <a
                                                href={walletDetailsData?.business_registration}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                <h6 className="glade-normal-text-three glade-blue py-1 cursor-pointer capitalize">
                                                    view document
                                                </h6>
                                            </a>
                                        </div>
                                        <div className="flex flex-col">
                                            <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                                                Operating License
                                            </h5>
                                            <a
                                                href={walletDetailsData?.operating_licence}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                <h6 className="glade-normal-text-three glade-blue py-1 cursor-pointer capitalize">
                                                    view document
                                                </h6>
                                            </a>
                                        </div>
                                        <div className="flex flex-col">
                                            <h5 className="glade-normal-text-two glade-ash py-1 capitalize">
                                                Date requested
                                            </h5>
                                            <h6 className="glade-normal-text-three glade-black py-1 capitalize">
                                                {walletDetailsData?.date_requested && dfn.format(
                                                    new Date(fixDateForAllBrowsers(walletDetailsData?.date_requested)),
                                                    "MMM d, yyyy"
                                                )}
                                            </h6>
                                        </div>
                                    </div>
                                )
                                }
                            </>
                        )}
                    </div>
                )}
            </div>

            {/* Reject Wallet Modal */}
            <BasicModal
                visibility={showRejectModal}
                onClose={() => setShowRejectModal(false)}
                onOpen={() => setShowRejectModal(true)}
                primaryButton=""
                modalTitleSection=""
                secondaryButton=""
                modelContentSection={
                    <form onSubmit={handleSubmit((e) => onSubmitOfWalletStatus(e, 'reject'))}>
                        <div className="-mx-6 -mt-2 flex justify-between px-5 pb-3 border-b-2 border-solid border-gray-200 mb-8">
                            <h4 className="glade-normal-text-two glade-black">
                                Reject Compliance
                            </h4>
                            <MdOutlineClose
                                size={16}
                                className="glade-black cursor-pointer"
                                onClick={() => setShowRejectModal(false)}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="reason" className="block glade-small-text-two">
                                Reason
                            </label>
                            <textarea
                                rows={4}
                                id="reason"
                                name="reason"
                                {...register("reason", {
                                    required: "Enter a reason",
                                })}
                                // required
                                placeholder="Enter a reason"
                                className="mt-1 block w-full pl-3 py-2 border-gray-300 rounded-sm glade-normal-text-two resize-none"
                            />
                            <div className="flex items-center justify-between flex-wrap">
                                <FormError errors={errors} name="reason" />
                            </div>
                        </div>

                        <div className="-mx-6 -mb-6 flex justify-between p-5 border-t-2 border-solid border-gray-200 mt-10">
                            <button
                                onClick={() => setShowRejectModal(false)}
                                className="glade-normal-text-three glade-black text-center"
                                disabled={isLoadingWalletStatusUpdate}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={isLoadingWalletStatusUpdate}
                                className={`glade-normal-text-three glade-button-red ${isLoadingWalletStatusUpdate
                                    ? "cursor-not-allowed"
                                    : ""
                                    } text-white py-2 px-4 flex justify-center items-center text-center rounded-sm`}
                            >
                                {isLoadingWalletStatusUpdate
                                    ? "Rejecting"
                                    : "Reject"}
                                {isLoadingWalletStatusUpdate && (
                                    <RiLoader5Fill size={24} className="animate-spin ml-4" />
                                )}
                            </button>
                        </div>
                    </form>
                }
            />
            {/*Accept Wallet Modal */}
            <BasicModal
                visibility={showAproveModal}
                onClose={() => setShowApproveModal(false)}
                onOpen={() => setShowApproveModal(true)}
                primaryButton=""
                modalTitleSection=""
                secondaryButton=""
                modelContentSection={
                    <div className="flex items-center justify-center space-x-3">
                        <div className="py-4 px-1">
                            <div className="w-full flex justify-center">
                                <p className="w-2/3 glade-black flex items-center text-center mb-8">
                                    Are you sure you want to approve this wallet request?
                                </p>
                            </div>
                            <div>
                                <Button.Dark
                                    onClick={() => {
                                        onSubmitOfWalletStatus('', 'approve');
                                    }}
                                    disabled={isLoadingWalletStatusUpdate}
                                    title="Approve"
                                    className={`glade-normal-text-three glade-button-blue ${isLoadingWalletStatusUpdate
                                        ? " cursor-not-allowed"
                                        : ""
                                        } text-white w-full py-2 px-4 flex justify-center items-center text-center rounded-sm mb-4`}
                                >
                                    {isLoadingWalletStatusUpdate
                                        ? "Approving"
                                        : "Approve"}
                                    {isLoadingWalletStatusUpdate && (
                                        <RiLoader5Fill
                                            size={24}
                                            className="animate-spin ml-4"
                                        />
                                    )}
                                </Button.Dark>
                            </div>
                            <div>
                                <Button.Gray
                                    className="glade-black mx-1 cursor-pointer text-center"
                                    title="Cancel"
                                    onClick={() => setShowApproveModal(false)}
                                    disabled={isLoadingWalletStatusUpdate}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        </div >
    );
}

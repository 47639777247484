const GLADE_LOGO = require('./glade_logo.png').default;
const GLADE_PDF_LOGO = require('./glade_pdf_logo.png').default;
const GLADE_PDF_BACKGROUND = require('./pdf_background.jpeg').default;
const GLADE_PDF_STAMP = require('./pdf_stamp.jpeg').default;
const GLADE_LOGO_ONE = require('./glade_logo_1.png').default;
const GLADE_INFO_GRAPHICS_ONE = require('./1of4.png').default;
const GLADE_INFO_GRAPHICS_TWO = require('./2of4.png').default;
const GLADE_INFO_GRAPHICS_THREE = require('./3of4.png').default;
const GLADE_INFO_GRAPHICS_FOUR = require('./4of4.png').default;
const GLADE_EMPTY_FOLDER_ICON = require('./empty-icon.svg').default;
const GLADE_VIRTUAL_CARD = require('../images/glade_virtual_card.png').default;
const MASTERCARD_LOGO = require('../images/mastercard_logo.png').default;

export {
    GLADE_LOGO,
    GLADE_LOGO_ONE,
    GLADE_PDF_LOGO,
    GLADE_PDF_BACKGROUND,
    GLADE_PDF_STAMP,
    GLADE_INFO_GRAPHICS_ONE,
    GLADE_INFO_GRAPHICS_TWO,
    GLADE_INFO_GRAPHICS_THREE,
    GLADE_INFO_GRAPHICS_FOUR,
    GLADE_EMPTY_FOLDER_ICON,
    GLADE_VIRTUAL_CARD,
    MASTERCARD_LOGO
}
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useURLSearchParamsHistory() {
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  function concatQuestionMark(params) {
    return `?${params.toString()}`;
  }
  /**
   *
   * @param {ConstructorParameters<typeof URLSearchParams>} params
   */
  function buildSearchParams(params) {
    return concatQuestionMark(new URLSearchParams(params));
  }

  /**
   *
   * @param {ConstructorParameters<typeof URLSearchParams>} params
   */
  function push(params) {
    if (params) {
      history.push({
        search: buildSearchParams([
          ...searchParams.entries(),
          ...new URLSearchParams(params).entries(),
        ]),
      });
    }
  }

  /**
   *
   * @param {ConstructorParameters<typeof URLSearchParams>} params
   */
  function merge(params) {
    if (params) {
      history.push({
        search: buildSearchParams(
          Object.fromEntries([
            ...searchParams.entries(),
            ...new URLSearchParams(params).entries(),
          ]),
        ),
      });
    }
  }

  function remove(...args) {
    const newParam = new URLSearchParams(searchParams);
    function removeRecursive(...args) {
      for (const arg of args) {
        if (typeof args === "string") {
          searchParams.delete(args);
        } else if (Array.isArray(arg)) {
          removeRecursive(...arg);
        }
      }
    }
    removeRecursive(...args);
    history.push({ search: concatQuestionMark(newParam) });
  }

  /**
   *
   * @param {ConstructorParameters<typeof URLSearchParams>} params
   */
  function replace(params) {
    if (params) {
      history.push({ search: buildSearchParams(params) });
    }
  }

  return { searchParams, push, merge, replace, remove };
}

export default useURLSearchParamsHistory;

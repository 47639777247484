import { useSelector } from "react-redux";

const useInflow = () => {
	const inflow = useSelector((state) => state.inflow);

	const {
		isLoadingAllPosInflows,
		isLoadingSpecificPosInflow,
		isLoadingAllCheckoutInflows,
		isLoadingSpecificCheckoutInflow,
		isLoadingAllTransfersInflows,
		isLoadingAllTransfersInflowsForDownload,
		isLoadingSpecificTransferInflow,
		allPosInflow,
		allCheckoutInflows,
		allTransfersInflows,
		allTransfersInflowsForDownload,
		specificPosInflow,
		specificCheckoutInflow,
		specificTransferInflow,
	} = inflow;

	return {
		isLoadingAllPosInflows,
		isLoadingSpecificPosInflow,
		isLoadingAllCheckoutInflows,
		isLoadingSpecificCheckoutInflow,
		isLoadingAllTransfersInflows,
		isLoadingAllTransfersInflowsForDownload,
		isLoadingSpecificTransferInflow,
		allPosInflow,
		allCheckoutInflows,
		allTransfersInflows,
		allTransfersInflowsForDownload,
		specificPosInflow,
		specificCheckoutInflow,
		specificTransferInflow,
	};
};

export default useInflow;

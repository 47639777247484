import React from "react";
import { TextSkeleton } from "../skeletons";

const AccountDetailsCard = ({ type, details, loading }) => {
  return (
    <div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
      <div className="py-3 border-b-2 border-solid border-gray-100">
        <p className="glade-black font-bold">Account Details</p>
      </div>
      <div className="mt-2">
        <div className="border-b border-glade-gray-50 py-2">
          <h3 className="text-glade-black-300 font-bold">Account 1</h3>
        </div>

        <div className="grid grid-cols-4 gap-4 py-3">
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Account Name
            </h5>
            {!loading && details?.submission?.id ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.submission?.business_name ||
                  details?.submission?.firstname +
                    " " +
                    details?.submission?.lastname}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Account Number
            </h5>
            {!loading && details?.submission?.id ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.submission?.bank_account_number1}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Bank Name</h5>
            {!loading && details?.submission?.id ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.submission?.bank_name1}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Account Email
            </h5>
            {!loading && details?.submission?.id ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.submission?.business_email ||
                  details?.submission?.contact_email}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
        </div>
      </div>
      {/* Account 2 */}
      <div className="border-b border-glade-gray-50 py-3 mb-2">
        <div className="border-b border-glade-gray-50 py-2">
          <h3 className="text-glade-black-300 font-bold">Account 2</h3>
        </div>

        <div className="grid grid-cols-4 gap-4 py-3">
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Account Name
            </h5>
            {!loading && details?.submission?.id ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.submission?.business_name ||
                  details?.submission?.firstname +
                    " " +
                    details?.submission?.lastname}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Account Number
            </h5>
            {!loading && details?.submission?.id ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.submission?.bank_account_number2}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">Bank Name</h5>
            {!loading && details?.submission?.id ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.submission?.bank_name2}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
          <div className="flex flex-col">
            <h5 className="glade-normal-text-two glade-ash py-1">
              Account Email
            </h5>
            {!loading && details?.submission?.id ? (
              <h6 className="glade-normal-text-three glade-black py-1">
                {details?.submission?.business_email ||
                  details?.submission?.contact_email}
              </h6>
            ) : (
              <TextSkeleton />
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 py-3">
        <div className="flex flex-col">
          <h5 className="glade-normal-text-two glade-ash py-1">Phone Number</h5>
          {!loading && details?.submission?.id ? (
            <h6 className="glade-normal-text-three glade-black py-1">
              {details?.submission?.phone ||
                details?.submission?.contact_phone ||
                "Not available"}
            </h6>
          ) : (
            <TextSkeleton />
          )}
        </div>

        <div className="flex flex-col">
          <h5 className="glade-normal-text-two glade-ash py-1">Country</h5>
          {!loading && details?.submission?.id ? (
            <h6 className="glade-normal-text-three glade-black py-1 capitalize">
              {details?.submission?.country_name ?? "N/A"}
            </h6>
          ) : (
            <TextSkeleton />
          )}
        </div>

        <div className="flex flex-col">
          <h5 className="glade-normal-text-two glade-ash py-1">Account Type</h5>
          {!loading && details?.submission?.id ? (
            <h6 className="glade-normal-text-three glade-black py-1 capitalize">
              {type}
            </h6>
          ) : (
            <TextSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountDetailsCard;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import Button from "../../components/buttons/Button";
import * as dfn from "date-fns";
import BasicIndicator from "../../components/indicators/BasicIndicator";
import { PopoverMain } from "../../components/popovers/Popover";
import { TextSkeleton } from "../../components/skeletons";
import { fixDateForAllBrowsers, statusEnum } from "../../helpers/helper";
import useDiscounts from "../../hooks/useDiscounts";
import {
  getSingleDiscount,
  terminateDiscount,
  activateDiscount,
  deleteDiscount,
  clearSingleDiscount
} from "../../redux/discounts/discountActions";
import { PaginatedTable } from "../../components/table/Table";
import qs from "query-string";

const DiscountDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});

  const { isLoadingSingleDiscount, singleDiscount } = useDiscounts();
  const [currentPage, setCurrentPage] = useState(
    qs.parse(location.search)?.page ?? 1
  );


  const { id } = useParams();

  useEffect(() => {
    dispatch(getSingleDiscount(id));
    return () => {
      dispatch(clearSingleDiscount());
    };
  }, []);

  useMemo(() => {
    setDetails(singleDiscount);
  }, [singleDiscount]);

  useEffect(() => {
    if (isLoadingSingleDiscount)
      setLoading(true);
    else
      setLoading(false);
  }, [isLoadingSingleDiscount]);

  const columns = [
    {
      accessor: "account_name",
      Header: "Account Name",
      style: {
        paddingLeft: 0,
        paddingRight: "10px",
        width: "50px"
      },
      Cell: ({ value }) => (
        <span className="glade-capitalize glade-normal-text-two">{value.split("_").join(" ")}</span>
      )
    },
    {
      accessor: "account_type",
      Header: "Account Type",
      style: {
        paddingLeft: 25,
        paddingRight: 0,
        width: "50px"
      },
      Cell: ({ value }) => (
        <span className="glade-capitalize glade-normal-text-two">{value}</span>
      )
    }
  ];

  return (
    <div className="p-3 flex flex-col">
      <div className="mb-5">
        <BreadCrumbs title={"Discounts"} goBack />
      </div>

      <div className="flex align-center justify-between mt-10">
        {!loading && details?.discount_info?.status ? (
          <BasicIndicator
            className="px-5 py-1 capitalize"
            type={statusEnum[details?.discount_info?.status]}
            title={details?.discount_info?.status}
          />
        ) : (
          <TextSkeleton width={"100px"} />
        )}
        <PopoverMain
          button={<Button.Dark
            title={"Actions"}
            className="p-6 w-80 right-0 bg-white shadow-md top-12 rounded"
          />}
          popoverClassName="right-0"
        >
          <ul className="px-2 mt-5 py-5 bg-white shadow rounded-md w-56">
            <Link to={`/dashboard/discounts/edit-transfer-discount/${details?.discount_info?.id}`}>
              <li
                className={"p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"}
              >
                Edit
              </li>
            </Link>
            {details?.discount_info?.status === "active"
              ? (
                <li
                  className={"p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"}
                  onClick={() => dispatch(terminateDiscount(details?.discount_info?.id))}
                >
                  Deactivate
                </li>
              )
              : (
                <li
                  className={"p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"}
                  onClick={() => dispatch(activateDiscount(details?.discount_info?.id))}
                >
                  Reactivate
                </li>
              )}
            <li
              className={"p-3 glade-normal-text-two glade-hover-bg-blue-darker glade-black cursor-pointer"}
              onClick={() => dispatch(deleteDiscount(details?.discount_info?.id))}
            >
              Delete
            </li>
          </ul>
        </PopoverMain>
      </div>

      <div>
        <div className="flex flex-col bg-white rounded-sm px-8 py-4 my-6">
          {/* Start of Table Header */}
          <div className="py-3 border-b-2 border-solid border-gray-100">
            <p className="glade-black glade-bold">Discount Details</p>
          </div>
          {/* End of Table Header */}

          {/* Start of Table Summary */}
          <div className="grid grid-cols-4 gap-4 py-3">
            {/* Start of Account Name */}
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Discount Name
              </h5>
              {!loading && details?.discount_info?.id ? (
                <h6 className="glade-capitalize glade-normal-text-three glade-black py-1">
                  {details?.discount_info?.tag}
                </h6>
              ) : (
                <TextSkeleton />
              )}
            </div>
            {/* End of Account Name */}

            {/* Start of Discount type */}
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Discount Type
              </h5>
              {!loading && details?.discount_info?.id ? (
                <h6 className="glade-capitalize glade-normal-text-three glade-black py-1">
                  {details?.discount_info?.discount_type.split("_").join(" ")}
                </h6>
              ) : (
                <TextSkeleton />
              )}
            </div>
            {/* End of Discount type */}

            {/* Start of Account Name */}
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Accounts Name
              </h5>
              {!loading && details?.discount_info?.id ? (
                <h6 className="glade-capitalize glade-normal-text-three glade-black py-1">
                  {details?.discount_info?.account_type}
                </h6>
              ) : (
                <TextSkeleton />
              )}
            </div>
            {/* End of Account Name */}

            {/* Start of Account selected */}
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Account Selected
              </h5>
              {!loading && details?.discount_info?.id ? (
                <h6 className="glade-capitalize glade-normal-text-three glade-black py-1">
                  {details?.discount_info?.no_of_accounts}
                </h6>
              ) : (
                <TextSkeleton />
              )}
            </div>
            {/* End of Account selected */}

            {/* Start of Number of transfers */}
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                No. of Transfers
              </h5>
              {!loading && details?.discount_info?.id ? (
                <h6 className="glade-capitalize glade-normal-text-three glade-black py-1">
                  {details?.discount_info?.no_of_free_transfers}
                </h6>
              ) : (
                <TextSkeleton />
              )}
            </div>
            {/* End of Number of free transfers*/}

            {/* Start of Date created */}
            <div className="flex flex-col">
              <h5 className="glade-normal-text-two glade-ash py-1">
                Date Created
              </h5>
              {!loading && details?.discount_info?.id ? (
                <h6 className="glade-capitalize glade-normal-text-three glade-black py-1">
                  {dfn.format(
                    new Date(fixDateForAllBrowsers(details?.discount_info?.date_added)),
                    "MMM d, yyy"
                  )}
                </h6>
              ) : (
                <TextSkeleton />
              )}
            </div>
            {/* End of Date created */}
          </div>
          {/* End of Table Summary */}


        </div>

        {/* Selected Accounts Table */}
        {!loading && details?.discount_details && (
          <React.Fragment>
            <div className="flex flex-col bg-white rounded-sm px-8 py-4">
              <div className="py-3">
                <p className="glade-black glade-bold">Selected Accounts</p>
              </div>
            </div>
            <PaginatedTable
              columns={columns}
              data={details?.discount_details}
              loading={isLoadingSingleDiscount}
              empty_message="No Selected"
              current_page={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </React.Fragment>
        )}
        {/*End Selected Accounts Table */}
      </div>

    </div>
  );
};

export default DiscountDetails;